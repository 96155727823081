<template>
  <AuraDialogWrapper
    ref="dialogWrapper"
    :headerText="SubscriptionProductName + '. ' + ManageSeatsCountCaption"
    @closed="DialogWrapperClosed"
    @dismissed="DialogWrapperClosed"
  >
    <div :class="['content thin-scroll mb-1', isLoading ? 'disabled' : '']" style="max-height: 50vh; overflow: auto">
      <quantity-text-box
        class="mb-3 align-self-center"
        :quantity.sync="newQuantity"
        @add-quantity="AddQuantity"
        @remove-quantity="SubtractQuantity"
      >
      </quantity-text-box>

      <div class="current-seat-label mx-1 mb-2">
        <span>{{ CurrentSeatCountIsCaption }} {{ CurrentSeatCount }}</span>
      </div>

      <div>
        <list-item-v-3
          v-if="DefaultPaymentMethod != null"
          class="mb-2"
          :items="PaymentMethodItems(DefaultPaymentMethod)"
          footerIconName="fa-pen"
          footerIconType="far"
          :footerIconFontSize="14"
          :bigMargin="false"
          @footer-icon-clicked="ChangeDefaultPaymentMethod"
        />
      </div>

      <div v-if="lastChanges !== null" class="next-charge-label mt-2 mx-1">
        <span v-if="lastChanges.chargeNow >= 0">
          {{ ChargeToday }}<span v-if="TaxRateAvailable">, including tax {{ ChargeTodayTax }}</span>
        </span>
        <span v-else>
          {{ AddToBalance }}<span v-if="TaxRateAvailable">, including tax {{ AddToBalanceTax }} </span>
        </span>
        <br />
        <span>
          {{ NextPaymentWillBeCaption }}
          {{ NextInvoiceSum }}<span v-if="TaxRateAvailable">, including tax {{ NextInvoiceSumTax }}</span>
          {{ OnCaption }}
          {{ NextInvoiceDate }}
        </span>
      </div>

      <button :class="['mt-3', newQuantity == CurrentSeatCount ? 'disabled' : '']" @click="UpdateSubscription">
        {{ SubmitCaption }}
      </button>

      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </div>
  </AuraDialogWrapper>
</template>

<script lang="ts">
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { create, DialogComponent } from 'vue-modal-dialogs';
import { PaymentMethod, PreviewSubscriptionChanges } from '@/models/Entities';

import SortableHeader from '@/components/presentation/SortableHeader.vue';
import ListItemV3 from '@/components/presentation/ListItemV3.vue';
import ComponentHelper, { ItemData } from '@/util/ComponentHelper';
import { PaymentMethodModule } from '@/store/modules/billing/paymentMethodModule';
import SelectDefaultPaymentMethodDialog from '../SelectDefaultPaymentMethodDialog.vue';
import { PurchasedSubscriptionModule } from '@/store/modules/billing/purchasedSubscriptionModule';
import { AccountModule } from '@/store/modules/billing/accountModule';
import en from '@/localization/en';
import dateFormat from 'dateformat';
import { toast } from '@/main';
import { POSITION } from 'vue-toastification';
import { debounce } from 'lodash';
import QuantityTextBox from '@/components/inputs/QuantityTextBox.vue';
import { PurchasedItemFullGroup } from '@/store/modules/billing/purchasedItemModule';
import AuraDialogWrapper from '@/views/dialogs/base/AuraDialogWrapper.vue';

const SelectDefaultPaymentMethod = create<PaymentMethod[] | null, PaymentMethod | null>(
  SelectDefaultPaymentMethodDialog,
  'paymentMethods',
);

@Component({
  components: {
    SortableHeader: SortableHeader,
    ListItemV3: ListItemV3,
    QuantityTextBox: QuantityTextBox,
    AuraDialogWrapper: AuraDialogWrapper,
  },
})
export default class ChangeSubscriptionSeatsDialog extends DialogComponent<boolean | null> {
  @Prop() private subscriptionItemsGroup!: PurchasedItemFullGroup;
  @Ref() dialogWrapper!: AuraDialogWrapper;

  private newQuantity: number = -1;
  private isLoading: boolean = false;
  private lastChanges: PreviewSubscriptionChanges | null = null;
  private debounceLoadChanges: any;

  async created() {
    // await this.LoadChanges();

    this.debounceLoadChanges = debounce(async () => {
      await this.LoadChanges();
    }, 1000);
  }

  @Watch('subscriptionItemsGroup', { immediate: true })
  private async OnSubscriptionItemsGroupChanges(newVal: PurchasedItemFullGroup) {
    this.newQuantity = newVal.group.length;
  }

  private get TaxRateAvailable() {
    return AccountModule.AccountTaxRate != null;
  }

  private get SubscriptionUnchanged() {
    if (this.newQuantity == this.subscriptionItemsGroup.group.length) {
      return true;
    }

    return false;
  }

  private get MinSeatCount() {
    return this.subscriptionItemsGroup.group.filter(a => a.baseItem !== null).length;
  }

  private get NewQuantity() {
    return this.newQuantity;
  }

  private get DefaultPaymentMethod(): PaymentMethod | null {
    return PaymentMethodModule.PaymentMethods.firstOrDefault(a => a.isDefault);
  }

  private get SubscriptionProductName() {
    return this.subscriptionItemsGroup.group[0].product.name;
  }

  private get CurrentSeatCount() {
    return this.subscriptionItemsGroup.group.length;
  }

  private get ChargeToday() {
    if (this.lastChanges == null) {
      return 'N/A';
    }

    const currSymbol = en.CurrencySymbols[this.lastChanges.currency.toUpperCase()];

    return en.youWillBeChargedXForToday.replace(
      '[AMOUNT]',
      currSymbol + ((this.lastChanges.chargeNow + this.lastChanges.chargeNowTax) / 100).toFixed(2),
    );
  }

  private get ChargeTodayTax() {
    if (this.lastChanges == null) {
      return 'N/A';
    }

    const currSymbol = en.CurrencySymbols[this.lastChanges.currency.toUpperCase()];

    return currSymbol + (this.lastChanges.chargeNowTax / 100).toFixed(2);
  }

  private get AddToBalance() {
    if (this.lastChanges == null) {
      return 'N/A';
    }

    const currSymbol = en.CurrencySymbols[this.lastChanges.currency.toUpperCase()];

    return en.xWillBeAddeToYourCreditToday.replace(
      '[AMOUNT]',
      currSymbol + ((this.lastChanges.chargeNow + this.lastChanges.chargeNowTax) / -100).toFixed(2),
    );
  }

  private get AddToBalanceTax() {
    if (this.lastChanges == null) {
      return 'N/A';
    }

    const currSymbol = en.CurrencySymbols[this.lastChanges.currency.toUpperCase()];

    return currSymbol + (this.lastChanges.chargeNowTax / -100).toFixed(2);
  }

  private get NextInvoiceSum() {
    if (this.lastChanges == null) {
      return 'N/A';
    }

    const currSymbol = en.CurrencySymbols[this.lastChanges.currency.toUpperCase()];

    const previewTotalLimit =
      this.lastChanges.previewTotal < 0 ? 0 : this.lastChanges.previewTotal + this.lastChanges.previewTotalTax;

    return currSymbol + (previewTotalLimit / 100).toFixed(2);
  }

  private get NextInvoiceSumTax() {
    if (this.lastChanges == null) {
      return 'N/A';
    }

    const currSymbol = en.CurrencySymbols[this.lastChanges.currency.toUpperCase()];

    const previewTotalLimit = this.lastChanges.previewTotal < 0 ? 0 : this.lastChanges.previewTotalTax;

    return currSymbol + (previewTotalLimit / 100).toFixed(2);
  }

  private get NextInvoiceDate() {
    if (this.lastChanges == null || this.lastChanges.nextAttempt == null) {
      return 'N/A';
    }

    return dateFormat(this.lastChanges.nextAttempt!, 'mmmm dS yyyy');
  }

  private PaymentMethodItems(value: PaymentMethod): ItemData[] {
    let captionItem: ItemData = new ItemData('Caption', `${en.paymentMethod.titleCase()}:`, 'grow-1');
    let last4DigitsItem: ItemData = new ItemData(
      'Last4Digits',
      ComponentHelper.GetReadablePaymentMethodKind(value.methodType) + '*' + value.last4Digits,
      'grow-1',
    );
    let editItem: ItemData = new ItemData(en.edit.titleCase(), '', 'grow-1');

    let items: ItemData[] = [];
    items.push(captionItem);
    items.push(last4DigitsItem);
    items.push(editItem);

    return items;
  }

  private async ChangeDefaultPaymentMethod() {
    const result = await SelectDefaultPaymentMethod(PaymentMethodModule.PaymentMethods.filter(a => !a.isDefault));

    if (result === null) {
      return;
    }

    this.isLoading = true;

    await PaymentMethodModule.MakeDefault(result.id);

    this.isLoading = false;
  }

  private async SubtractQuantity() {
    if (this.newQuantity - 1 < this.MinSeatCount || this.newQuantity - 1 < 1) {
      return;
    }

    --this.newQuantity;

    if (this.SubscriptionUnchanged) {
      this.isLoading = false;
      this.lastChanges = null;
      return;
    }

    await this.debounceLoadChanges();
  }

  private async AddQuantity() {
    ++this.newQuantity;

    if (this.SubscriptionUnchanged) {
      this.isLoading = false;
      this.lastChanges = null;
      return;
    }

    await this.debounceLoadChanges();
  }

  private async LoadChanges() {
    this.isLoading = true;

    if (AccountModule.Account === null) {
      // No billing account... probably inform user?
      this.isLoading = false;
      return;
    }

    const res = await PurchasedSubscriptionModule.PreviewPriceChanges({
      accountId: AccountModule.Account.id,
      subId: this.subscriptionItemsGroup.id,
      updateData: {
        newProductPriceId: this.subscriptionItemsGroup.group[0].price.id,
        newQuantity: this.newQuantity,
      },
      prorationDate: null,
    });

    if (res !== null) {
      this.lastChanges = res;
    }

    this.isLoading = false;
  }

  private async UpdateSubscription() {
    this.isLoading = true;

    if (AccountModule.Account === null) {
      // No billing account... probably inform user?
      this.isLoading = false;
      return;
    }

    const res = await PurchasedSubscriptionModule.Update({
      accountId: AccountModule.Account.id,
      subId: this.subscriptionItemsGroup.id,
      updateData: {
        newProductPriceId: this.subscriptionItemsGroup.group[0].price.id,
        newQuantity: this.newQuantity,
      },
      prorationDate: this.lastChanges == null ? null : this.lastChanges!.prorationDate,
    });

    if (res) {
      toast.success(en.changedSubscriptionSuccessfully, {
        position: POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error(en.couldNotChangeSubcripton, {
        position: POSITION.BOTTOM_RIGHT,
      });
    }

    this.dialogWrapper.CloseDialog();
    this.$close(res);

    this.isLoading = false;
  }

  private get ManageSeatsCountCaption() {
    return en.manageSeatsCount;
  }

  private get CurrentSeatCountIsCaption() {
    return en.currentSeatCountIs;
  }

  private get NextPaymentWillBeCaption() {
    return en.nextPaymentWillBe;
  }

  private get OnCaption() {
    return en.on;
  }

  private get SubmitCaption() {
    return en.submit.titleCase();
  }

  private DialogWrapperClosed() {
    this.$close(null);
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 400px;

  button {
    border: none;
    background-color: var(--account-view-border);
    color: black;
    border-radius: 6px;
    height: 32px;
    font-size: 16px;
    transition: opacity 150ms ease-out;
    font-size: 13px;
  }
}

.current-seat-label,
.next-charge-label {
  font-size: 12px;
}
</style>
