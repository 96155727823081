import {
  ResponseConnectionType,
  ResponseTelemetryItem,
  ResponseTelemetry,
  ResponseError,
  ResponseErrors,
  ResponseConnectorPrinterModel,
} from '@/models/responses/ResponseConnector';
import {
  ConnectionType,
  TelemetryItem,
  Telemetry,
  PrinterErrors,
  PrinterError,
  PrintHead,
  Temperatures,
  Temperature,
  Sensor,
  ConnectorPrinterModel,
} from '@/models/Entities';
import { Guid } from 'guid-typescript';
import { TypeHelper } from '@/util/TypeHelper';

declare module './ResponseConnector' {
  interface ResponseConnectionType {
    Map(): ConnectionType;
  }
  interface ResponseTelemetryItem {
    Map(): TelemetryItem;
  }
  interface ResponseTelemetry {
    Map(): Telemetry;
  }
  interface ResponseError {
    Map(): PrinterError;
  }
  interface ResponseErrors {
    Map(): PrinterErrors;
  }
  interface ResponseConnectorPrinterModel {
    Map(): ConnectorPrinterModel;
  }
}
ResponseConnectorPrinterModel.prototype.Map = function (): ConnectorPrinterModel {
  const model = new ConnectorPrinterModel();
  model.Id = Guid.parse(this.id.toString());
  model.BPSensorNames = this.bpSensorNames;
  model.CHSensorNames = this.chSensorNames;
  model.ExtruderNames = this.extruderNames;
  model.FanNames = this.fanNames;
  model.PHNames = this.phNames;
  model.PHSensorNames = this.phSensorNames;
  return model;
};
ResponseConnectionType.prototype.Map = function (): ConnectionType {
  const connectionType = new ConnectionType();
  connectionType.Id = Guid.parse(this.id.toString());
  connectionType.Name = this.name;
  return connectionType;
};
ResponseTelemetryItem.prototype.Map = function (): TelemetryItem {
  const telemetryItem = new TelemetryItem();
  telemetryItem.Time = TypeHelper.StringToDate(this.time);
  telemetryItem.State = this.status;
  const printHead = new PrintHead();
  printHead.extruderPositions = [];
  for (const item of this.extruderPositions) {
    printHead.extruderPositions.push(item);
  }
  printHead.xPosition = this.xPosition;
  printHead.yPosition = this.yPosition;
  printHead.zPosition = this.zPosition;
  telemetryItem.PrintHead = printHead;
  telemetryItem.CurrentTool = this.currentTool;

  const temperatureBuildplate = new Temperature();
  temperatureBuildplate.target = this.bpTarget;
  temperatureBuildplate.index = 'BP';
  temperatureBuildplate.currents = [];
  for (let i = 0; i < this.bpCurrents.length; i++) {
    const sensor = new Sensor();
    sensor.value = this.bpCurrents[i];
    sensor.name = this.bpSensorNames[i];
    temperatureBuildplate.currents.push(sensor);
  }

  const temperatureChamber = new Temperature();
  temperatureChamber.target = this.chTarget;
  temperatureChamber.index = 'CH';
  temperatureChamber.currents = [];
  for (let i = 0; i < this.chCurrents.length; i++) {
    const sensor = new Sensor();
    sensor.value = this.chCurrents[i];
    sensor.name = this.chSensorNames[i];
    temperatureChamber.currents.push(sensor);
  }

  const temperaturePrintHeads: Temperature[] = [];
  for (let i = 0; i < this.phTargets.length; i++) {
    const temperaturePrintHead = new Temperature();
    temperaturePrintHead.target = this.phTargets[i];
    temperaturePrintHead.index = `PH-${i}`;
    temperaturePrintHead.currents = [];
    for (let j = 0; j < this.phCurrents[i].length; j++) {
      const sensor = new Sensor();
      sensor.value = this.phCurrents[i][j];
      sensor.name = this.phSensorNames[i][j];
      temperaturePrintHead.currents.push(sensor);
    }
    temperaturePrintHeads.push(temperaturePrintHead);
  }

  const temperatures = new Temperatures();
  temperatures.buildplate = temperatureBuildplate;
  temperatures.chamber = temperatureChamber;
  temperatures.printheads = temperaturePrintHeads;
  telemetryItem.Temperatures = temperatures;
  telemetryItem.FanSpeeds = this.fanSpeeds;
  telemetryItem.CurrentLayer = this.currentLayer;
  telemetryItem.PrintProgress = this.printProgress;
  return telemetryItem;
};
ResponseTelemetry.prototype.Map = function (): Telemetry {
  const telemetry = new Telemetry();
  telemetry.Items = [];
  for (const item of this.items) {
    telemetry.Items.push(item.Map());
  }
  telemetry.PrinterId = this.printerId;
  telemetry.CompanyId = this.companyId;
  return telemetry;
};
ResponseError.prototype.Map = function (): PrinterError {
  const printerErrorItem = new PrinterError();
  printerErrorItem.Time = TypeHelper.StringToDate(this.time);
  printerErrorItem.ErrorType = this.errorType;
  printerErrorItem.Message = this.message;
  printerErrorItem.Acknowledged = this.acknowledged;
  printerErrorItem.Id = Guid.parse(this.id.toString());
  return printerErrorItem;
};
ResponseErrors.prototype.Map = function (): PrinterErrors {
  const printerError = new PrinterErrors();
  printerError.Errors = [];
  for (const item of this.items) {
    printerError.Errors.push(item.Map());
  }
  printerError.PrinterId = this.printerId;
  printerError.CompanyId = this.companyId;
  return printerError;
};
