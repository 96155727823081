<template>
  <div :class="[mainClass, 'sortable-header d-flex', 'flex-row', 'w-100 pl-2 pr-2']">
    <a
      v-for="item of headerItems"
      :key="item.caption"
      :class="[
        item.itemClass,
        'd-flex',
        'flex-row',
        'align-items-center',
        'item',
        item.isSortable ? 'cur-pointer' : '',
        isSelected(item) ? 'isSelected' : '',
      ]"
      :data-testid="getTestId('caption')"
      :style="getMaxWidthStyle(item)"
      @click="onClick(item)"
    >
      <div class="flc mr-1">{{ item.caption }}</div>
      <div class="icon">
        <LongArrowUp v-if="isAscShown(item)" />
        <LongArrowDown v-if="isDescShown(item)" />
      </div>
    </a>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, PropSync, Emit } from 'vue-property-decorator';
import LongArrowUp from '@/components/icons/LongArrowUp.vue';
import LongArrowDown from '@/components/icons/LongArrowDown.vue';
import { HeaderItem, SortMode } from '@/util/ComponentHelper';
import { createTestId } from '@/util/tests';

@Component({
  components: {
    LongArrowUp,
    LongArrowDown,
  },
})
export default class SortableHeader extends Vue {
  @Prop() private headerItems!: HeaderItem[];
  @Prop({ default: undefined }) mainClass?: string;
  @Prop({ default: undefined }) testId?: string;

  @PropSync('by', { default: null })
  private sortHeader!: HeaderItem | null;

  @PropSync('mode', { default: null })
  private sortMode!: SortMode | null;

  @Emit('sortBy')
  private async onClick(item: HeaderItem) {
    if (item.isSortable === undefined || !item.isSortable) {
      return [this.sortHeader, this.sortMode];
    }

    if (this.sortHeader === item) {
      if (this.sortMode == null) {
        this.sortMode = SortMode.ASC;
      } else if (this.sortMode === SortMode.ASC) {
        this.sortMode = SortMode.DESC;
      } else if (this.sortMode === SortMode.DESC) {
        this.sortMode = null;
        this.sortHeader = null;
      }
    } else {
      this.sortMode = SortMode.ASC;
      this.sortHeader = item;
    }

    return [this.sortHeader, this.sortMode];
  }

  isAscShown(item: HeaderItem) {
    return item.isSortable && ((this.isSelected(item) && this.sortMode === SortMode.ASC) || item !== this.sortHeader);
  }

  isDescShown(item: HeaderItem) {
    return item.isSortable && this.isSelected(item) && this.sortMode === SortMode.DESC;
  }

  isSelected(item: HeaderItem) {
    return item === this.sortHeader;
  }

  getMaxWidthStyle(item: HeaderItem) {
    if (item.width == undefined) {
      return '';
    }
    return `min-width: ${item.width}px`;
  }

  getTestId(elementId: string) {
    return createTestId(this.testId, `sortableHeader.${elementId}`);
  }
}
</script>

<style lang="scss" scoped>
.sortable-header {
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  padding: 7px 0;
  color: var(--list-header-text);
}

.item {
  white-space: nowrap;
  &.isSelected,
  &:hover {
    .icon {
      color: var(--main-orange);
    }
  }
  &:hover:not(.isSelected) {
    .icon {
      opacity: 0.8;
    }
  }
}

.icon {
  transform: translateY(-1px);
}
</style>
