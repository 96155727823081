<template>
  <div class="shop">
    <div class="left-panel thin-scroll">
      <div class="products mb-3 overflow-auto thin-scroll">
        <shop-product
          v-for="(item, indx) in ProductsFull"
          :key="item.product.id"
          :class="[indx == ProductsFull.length - 1 ? '' : 'mb-3']"
          :productFull="item"
          :purchasableData="GetPurchasableData(item)"
        >
        </shop-product>
      </div>

      <action-card class="already-purchased overflow-auto" :headerText="AlreadyPurchasedCaption" :collapsible="false">
        <sortable-header :header-items="PurchasedItemHeader" />
        <div class="overflow-overlay thin-scroll">
          <list-item-v-3
            v-for="item in PurchasedItemsGroups"
            :id="item.id"
            :key="item.id.toString()"
            :items="PurchasedItemData(item)"
            mainClass="cur-pointer"
            @openItem="OpenGroup(item)"
          />
        </div>

        <div :class="['spinner-container', IsLoadingBillingData ? '' : 'hidden']">
          <div :class="['spinner', IsLoadingBillingData ? '' : 'hidden']"></div>
        </div>
      </action-card>
    </div>

    <div ref="verticalResizer" class="vertical-resizer"></div>

    <div class="right-panel thin-scroll">
      <shop-cart
        class="w-100"
        :cartItems="CartItems"
        @add-payment-method="AddPaymentMethod"
        @edit-account="EditAccount"
      />
    </div>
  </div>
</template>

<script lang="ts">
import ActionCard from '@/components/presentation/ActionCard.vue';
import ListItemV3 from '@/components/presentation/ListItemV3.vue';
import ShopCart from '@/components/presentation/ShopCart.vue';
import { Component, Emit, Vue } from 'vue-property-decorator';
import {
  PurchasedItemFull,
  PurchasedItemFullGroup,
  PurchasedItemModule,
} from '@/store/modules/billing/purchasedItemModule';
import { ProductFull, ProductModule } from '@/store/modules/billing/productModule';
import ComponentHelper, { HeaderItem, ItemData } from '@/util/ComponentHelper';
import { CartModule, CartItem } from '@/store/modules/billing/cartModule';
import SortableHeader from '@/components/presentation/SortableHeader.vue';
import ShopProduct, { PurchasableData } from '@/components/presentation/ShopProduct.vue';
import dateformat from 'dateformat';
import { LicenseType } from '@/models/enums/LicenseType';
import { PurchaseStatus } from '@/models/enums/PurchaseStatus';
import en from '@/localization/en';
import { GlobalDataModule } from '@/store/modules/globalDataModule';

const OCCUPIED_TOTAL_WIDTH = 150;
const NEXT_PAYMENT_WIDTH = 150;

@Component({
  name: 'shop',
  components: {
    ShopCart: ShopCart,
    ActionCard: ActionCard,
    ListItemV3: ListItemV3,
    SortableHeader: SortableHeader,
    ShopProduct: ShopProduct,
  },
})
export default class Shop extends Vue {
  private get CartItems(): CartItem[] {
    return CartModule.CartItems;
  }

  private get CartHasSubscription(): boolean {
    return CartModule.HasSubscription;
  }

  private get PurchasedItemsFull(): PurchasedItemFull[] {
    return PurchasedItemModule.PurchasedItemsFull;
  }

  private get PurchasedItemsGroups(): PurchasedItemFullGroup[] {
    return PurchasedItemModule.PurchasedItemsGroups.filter(a => this.AddonGroupShowableInUI(a));
  }

  private get ProductsFull(): ProductFull[] {
    return ProductModule.ProductsFull;
  }

  private get IsLoadingBillingData() {
    return GlobalDataModule.IsLoadingBillingData;
  }

  //#region LOGIC
  private AddonGroupShowableInUI(group: PurchasedItemFullGroup) {
    const groupSub = group.group[0].subscription;

    if (groupSub == null) return true;

    const subPeriodEnd = groupSub.currentPeriodEnd;

    if (subPeriodEnd == null || subPeriodEnd! < new Date()) return false;

    if (groupSub.status === PurchaseStatus.Canceled) return false;

    return true;
  }

  private GetPurchasableData(val: ProductFull): PurchasableData {
    const purchasedSubscriptions = this.PurchasedItemsFull.filter(
      a =>
        a.subscription !== null &&
        (a.subscription.status === PurchaseStatus.Active || a.subscription.status === PurchaseStatus.PastDue),
    );

    if (purchasedSubscriptions.firstOrDefault(a => a.product.id.toString() === val.product.id.toString())) {
      return {
        isPurchasable: false,
        reason: 'You already have this subscription',
      };
    }

    if (this.CartHasSubscription && val.prices[0].recurringInterval === null) {
      return {
        isPurchasable: false,
        reason: 'Your order can only contain either subscriptions or one-time payment products',
      };
    } else if (this.CartItems.length > 0 && !this.CartHasSubscription && val.prices[0].recurringInterval !== null) {
      return {
        isPurchasable: false,
        reason: 'Your order can only contain either subscriptions or one-time payment products',
      };
    }

    return {
      isPurchasable: true,
      reason: '',
    };
  }
  //#endregion

  //#region HEADERS AND ITEMS
  private get PurchasedItemHeader(): HeaderItem[] {
    let header1: HeaderItem = {
      caption: '#',
      itemClass: ComponentHelper.GetWidthClass('grow-1'),
    };
    let header2: HeaderItem = {
      caption: `${en.occupied.titleCase()}/${en.total.titleCase()}`,
      itemClass: ComponentHelper.GetWidthClass(OCCUPIED_TOTAL_WIDTH),
    };
    let header3: HeaderItem = {
      caption: en.nextPayment.titleCase(),
      itemClass: ComponentHelper.GetWidthClass(NEXT_PAYMENT_WIDTH),
    };
    let headerStatus: HeaderItem = {
      caption: en.status.titleCase(),
      itemClass: ComponentHelper.GetWidthClass(100),
    };

    let headerItems: HeaderItem[] = [];
    headerItems.push(header1);
    headerItems.push(header2);
    headerItems.push(header3);
    headerItems.push(headerStatus);

    return headerItems;
  }

  private PurchasedItemData(group: PurchasedItemFullGroup): ItemData[] {
    let currentPeriodEnd: Date | null = null;

    let value = group.group[0];

    if (value.subscription !== null && value.subscription.currentPeriodEnd !== null) {
      currentPeriodEnd = value.subscription!.currentPeriodEnd!;
    }

    const total = group.group.length;
    const activated: number = group.group.filter(a => a.item.activated).length;
    const attached: number = group.group.filter(a => a.item.baseItemId !== null).length;
    const occupied = value.product.licenseType === LicenseType.CryptlexKey ? activated : attached;

    let itemName: ItemData = new ItemData('Name', value.product.name, 'grow-1');
    let itemOccupiedTotal: ItemData = new ItemData(
      'OccupiedTotalCount',
      occupied.toString() + '/' + total.toString(),
      OCCUPIED_TOTAL_WIDTH,
    );
    let itemNextPayment: ItemData = new ItemData(
      'NextPayment',
      currentPeriodEnd === null ? '-' : dateformat(currentPeriodEnd!, 'dd/mm/yyyy'),
      NEXT_PAYMENT_WIDTH,
    );
    let itemStatus: ItemData = new ItemData(
      'Status',
      ComponentHelper.GetReadablePurchaseStatus(value.item.status),
      100,
    );

    let items: ItemData[] = [];
    items.push(itemName);
    items.push(itemOccupiedTotal);
    items.push(itemNextPayment);
    items.push(itemStatus);

    return items;
  }
  //#endregion

  //#region HOOKS
  async activated() {}

  async deactivated() {}
  //#endregion

  //#region EVENTS
  @Emit('group-selected')
  private OpenGroup(val: PurchasedItemFullGroup) {
    return val;
  }

  @Emit('add-payment-method')
  private AddPaymentMethod() {}

  @Emit('edit-account')
  private EditAccount() {}
  //#endregion

  //#region TRANSLATIONS
  private get AlreadyPurchasedCaption() {
    return en.alreadyPurchased;
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.shop {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;

  .left-panel {
    width: 40%;
    max-height: 100%;
    display: grid;
    flex-direction: column;
    grid-template-rows: 1fr auto;
    overflow: auto;
    min-width: 500px;
  }

  .right-panel {
    width: 60%;
    overflow: auto;
  }

  .already-purchased {
    min-height: 150px;
    :last-child {
      margin-bottom: 0;
    }
  }

  .vertical-resizer {
    cursor: col-resize;
    width: 10px;
    margin: 0 3px;
  }

  .spinner-container {
    min-height: 40px;
    margin-top: 20px;
    position: relative;
    opacity: 1;

    &.hidden {
      opacity: 0;
      min-height: 0px;
      margin-top: 0px;
    }

    transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);
  }
}
</style>
