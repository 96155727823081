<template>
  <div data-testid="jobCard" :class="['job-card', selected ? 'selected' : '']" @click="Clicked">
    <div class="progress-underlay" :style="ProgressUnderlayStyle"></div>

    <div class="status-ribbon mr-2" :style="{ 'background-color': RibbonColor }"></div>

    <div class="content px-2 py-2">
      <div class="header">
        <div class="source-name-container mr-3">
          <span class="source-name mb-2">{{ Source }}</span>
        </div>

        <div class="controls mb-2">
          <div class="buttons">
            <i
              v-if="!IsStarted && CanStart"
              data-testid="jobCard.startButton"
              class="fas fa-play mr-2"
              @click.stop="StartButtonClicked"
            ></i>
            <i
              v-if="IsPaused && CanResume"
              data-testid="jobCard.resumeButton"
              class="fas fa-play mr-2"
              @click.stop="ResumeButtonClicked"
            ></i>
            <i
              v-else-if="(IsStarted || IsResumed) && CanPause"
              class="fas fa-pause mr-2"
              data-testid="jobCard.pauseButton"
              @click.stop="PauseButtonsClicked"
            ></i>
            <i
              v-if="(IsStarted || IsPaused || IsResumed) && CanStop"
              class="fas fa-stop mr-2"
              data-testid="jobCard.stopButton"
              @click.stop="StopButtonClicked"
            ></i>
          </div>
          <span class="status mx-2">{{ Status }}</span>
        </div>
      </div>

      <div class="info">
        <TextInput class="info-item" label="Printer" :value="Printer" :readonly="true" />
        <TextInput v-if="!IsStarted" class="info-item" label="Duration" :value="Duration" :readonly="true" />
        <TextInput class="info-item" label="Order" :value="Order" :readonly="true" />
        <TextInput v-if="IsStarted" class="info-item" label="Progress" :value="Progress" :readonly="true" />
        <TextInput v-if="IsStarted" class="info-item" label="Estimated" :value="Estimated" :readonly="true" />
      </div>

      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </div>
  </div>
</template>

<script lang="ts">
import en from '@/localization/en';
import { FullJob } from '@/models/CompositeEntities';
import { JobState, PrinterState } from '@/models/Entities';
import { JobModule } from '@/store/modules/jobModule';
import ComponentHelper from '@/util/ComponentHelper';
import AuraMessageBoxDialog, { Result } from '@/views/dialogs/AuraMessageBoxDialog.vue';
import moment from 'moment';
import { create } from 'vue-modal-dialogs';
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import TextInput from '@/components/inputs/TextInput.vue';

const StopJobSure = create<Result, String, String, Result>(AuraMessageBoxDialog, 'results', 'header', 'mainText');

@Component({
  components: {
    TextInput: TextInput,
  },
})
export default class JobCard extends Vue {
  @Prop() fullJob!: FullJob;
  @Prop({ default: false }) selected!: boolean;

  //#region STATE
  isLoading: boolean = false;

  private get IsStarted() {
    return this.fullJob.job.JobState == JobState.START;
  }

  private get IsResumed() {
    return this.fullJob.job.JobState == JobState.RESUME;
  }

  private get IsPaused() {
    return this.fullJob.job.JobState == JobState.PAUSE;
  }

  private get IsInProgress() {
    return this.fullJob.job.Order == null;
  }

  private get CanStart() {
    return this.fullJob.printer.PrinterState == PrinterState.IDLE;
  }

  private get CanResume() {
    return this.fullJob.printer.PrinterState == PrinterState.PAUSED;
  }

  private get CanPause() {
    return this.fullJob.printer.PrinterState == PrinterState.PRINTING;
  }

  private get CanStop() {
    return (
      this.fullJob.printer.PrinterState == PrinterState.PRINTING ||
      this.fullJob.printer.PrinterState == PrinterState.PAUSED
    );
  }

  private get Source() {
    if (this.fullJob.job.LocalFilename != null) {
      return this.fullJob.job.LocalFilename;
    }

    if (this.fullJob.source == null) {
      return '—';
    }

    return this.fullJob.source.CodeFileName;
  }

  private get Printer() {
    return this.fullJob.printer.Name;
  }

  private get Progress() {
    if (this.fullJob.job.Progress == null) {
      return '—';
    }

    return this.fullJob.job.Progress.toFixed(2) + '%';
  }

  private get Estimated() {
    if (
      this.fullJob.source == null ||
      this.fullJob.job.ActualStartTime == null ||
      this.fullJob.source.PrintDuration == null
    ) {
      return '—';
    }

    const startTime = this.fullJob.job.ActualStartTime;
    let endDate = moment(startTime).add(this.fullJob.source.PrintDuration).toDate();

    return ComponentHelper.GetDateTime(endDate);
  }

  private get Duration() {
    if (this.fullJob.source == null || this.fullJob.source.PrintDuration == null) {
      return '—';
    }

    return `${this.fullJob.source.PrintDuration.totalHours.toFixed(0)} hours ${
      this.fullJob.source.PrintDuration.minutes
    } minutes`;
  }

  private get Order() {
    if (this.fullJob.job.Order == null) {
      return en.current.growFirst();
    }

    return this.fullJob.job.Order.toString();
  }

  private get IsTransferingFile() {
    return (
      this.fullJob.printer.PrinterState == PrinterState.BUSY &&
      this.fullJob.job.StartFileTransferProgress > 0 &&
      this.fullJob.job.StartFileTransferProgress < 1
    );
  }

  private get Status() {
    if (this.IsTransferingFile) {
      return `Uploading file (${(this.fullJob.job.StartFileTransferProgress * 100).toFixed(2)}%)`;
    }

    switch (this.fullJob.job.JobState) {
      case JobState.NOT_STARTED:
        return this.NotStartedCaption;
      case JobState.START:
      case JobState.RESUME:
        return `${this.PrintingCaption.growFirst()} (${this.fullJob.job.Progress?.toFixed(2)}%)`;
      case JobState.CANCEL:
        return `${this.CancelledCaption.growFirst()} (${this.fullJob.job.Progress?.toFixed(2)}%)`;
      case JobState.PAUSE:
        return `${this.PausedCaption.growFirst()} (${this.fullJob.job.Progress?.toFixed(2)}%)`;
      case JobState.FINISH:
        return `${this.FinishedCaption.growFirst()} (100%)`;
      default:
        return this.NotStartedCaption;
    }
  }

  private get RibbonColor() {
    if (this.IsInProgress) {
      return '#00F654';
    }

    return '#A4A4A4';
  }

  private get ProgressUnderlayStyle() {
    if (this.IsTransferingFile) {
      return {
        width: (this.fullJob.job.StartFileTransferProgress * 100).toFixed(2) + '%',
      };
    } else if (this.IsInProgress && this.fullJob.job.Progress != null) {
      return {
        width: this.fullJob.job.Progress.toFixed(2) + '%',
      };
    }

    return {
      width: '0%',
    };
  }
  //#endregion

  //#region LOGIC
  private async StartButtonClicked() {
    this.isLoading = true;

    await JobModule.ForceStart(this.fullJob.job.Id);

    this.isLoading = false;
  }

  private async ResumeButtonClicked() {
    this.isLoading = true;

    await JobModule.UpdateJobStatus([this.fullJob.job, JobState.RESUME]);

    this.isLoading = false;
  }

  private async PauseButtonsClicked() {
    this.isLoading = true;

    await JobModule.UpdateJobStatus([this.fullJob.job, JobState.PAUSE]);

    this.isLoading = false;
  }

  private async StopButtonClicked() {
    const userConfirmation = await StopJobSure(
      Result.Yes | Result.No,
      'Cancel job',
      'Are you sure you want to cancel this job?',
    );

    if (userConfirmation == Result.No || userConfirmation == Result.Cancel) {
      return;
    }

    this.isLoading = true;

    await JobModule.UpdateJobStatus([this.fullJob.job, JobState.CANCEL]);

    this.isLoading = false;
  }
  //#endregion

  //#region EVENTS
  @Emit('clicked')
  Clicked() {}
  //#endregion

  //#region TRANSLATIONS
  private get StatusCaption(): string {
    return en.status;
  }
  private get NotStartedCaption(): string {
    return en.notStarted.growFirst();
  }
  private get PrintingCaption(): string {
    return en.printing.growFirst();
  }
  private get CancelledCaption(): string {
    return en.cancelled.growFirst();
  }
  private get PausedCaption(): string {
    return en.paused.growFirst();
  }
  private get FinishedCaption(): string {
    return en.finished.growFirst();
  }
  private get UnknownCaption(): string {
    return en.unknown.growFirst();
  }
  private get SuccessCaption(): string {
    return en.success.growFirst();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.job-card {
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;

  .progress-underlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    opacity: 0.25;
    background-color: var(--main-orange);
    transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);
  }

  // todo: replace colors with variables

  background: var(--job-card-background);
  border: var(--job-card-border);
  border-radius: 6px;

  &.selected {
    border: var(--job-card-border-selected);
  }

  &:not(.selected) {
    cursor: pointer;
    user-select: none;
  }

  &:hover {
    background: var(--job-card-background-hover);
  }

  .status-ribbon {
    width: 8px;
    min-width: 8px;
    max-width: 8px;
    position: relative;
    height: 100%;
  }

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;

    .header {
      display: flex;
      justify-content: space-between;

      .source-name-container {
        text-overflow: ellipsis;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        flex: 1;

        .source-name {
          font-size: 16px;
          color: var(--job-card-source-name-text);
          text-align: left;
          text-overflow: ellipsis;
          display: block;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .controls {
        display: flex;
        justify-content: flex-end;
        font-size: 14px;

        .buttons {
          color: var(--job-card-control-color);

          i {
            cursor: pointer;

            &:hover {
              opacity: 0.75;
            }
          }
        }

        .status {
          color: var(--job-card-status-color);
        }
      }
    }

    .info {
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      .info-item {
        width: 50%;
      }
    }
  }
}
</style>
