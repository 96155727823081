<template>
  <div class="user-info-container px-3 py-3">
    <div class="header mb-3">
      <span class="full-name ml-2">{{ UserFullName }}</span>
    </div>
    <div class="user-info">
      <div class="left-panel thin-scroll">
        <ActionCard
          class="user-data mb-3"
          :headerText="InfoCaption"
          :headerButtonDatas="InfoButtonDatas"
          :initiallyCollapsed="InfoCollapsed"
          test-id="user.info"
          @edit-header-button-clicked="EditingUser = true"
          @change-password-header-button-clicked="ChangingUserPassword = true"
          @collapse-toggled="InfoCollapseToggled"
        >
          <TextInput
            :readonly="true"
            :label="EmailCaption"
            :labelWidth="100"
            :value="UserEmail"
            :fontSize="13"
            labelColor="#949494"
            valueColor="#cecece"
          />

          <TextInput
            :readonly="true"
            :label="FirstNameCaption"
            :labelWidth="100"
            :value="UserFirstName"
            :fontSize="13"
            labelColor="#949494"
            valueColor="#cecece"
          />

          <TextInput
            :readonly="true"
            :label="SecondNameCaption"
            :labelWidth="100"
            :value="UserSecondName"
            :fontSize="13"
            labelColor="#949494"
            valueColor="#cecece"
          />

          <TextInput
            :readonly="true"
            :label="PhoneCaption"
            :labelWidth="100"
            :value="UserPhone"
            :fontSize="13"
            labelColor="#949494"
            valueColor="#cecece"
          />

          <div :class="['spinner', LoadingUser ? '' : 'hidden']"></div>
        </ActionCard>

        <ActionCard
          v-if="IsConnectUser"
          class="user-stats-container"
          :initiallyCollapsed="StatsCollapsed"
          :noPadding="true"
          :headerText="StatsCaption"
          test-id="user.stats"
          @collapse-toggled="StatsCollapseToggled"
        >
          <div class="user-stats">
            <StatPanel
              v-for="item in StatModels"
              :key="item.dimension + item.status"
              :class="[isLoadingUserStats ? 'disabled' : '']"
              :value="item.value"
              :dimension="item.dimension"
              :status="item.status"
            />

            <div :class="['spinner', isLoadingUserStats ? '' : 'hidden']"></div>
          </div>
        </ActionCard>
      </div>

      <div class="vertical-resizer"></div>

      <div class="right-panel thin-scroll">
        <ChangePassword
          v-if="ChangingUserPassword"
          :user="user"
          class="mb-3"
          @close-clicked="ChangingUserPassword = false"
          @apply-clicked="OnPasswordApplyClicked"
        >
        </ChangePassword>

        <UserEditor
          v-if="EditingUser"
          :user="user"
          class="mb-3"
          @close-clicked="EditingUser = false"
          @apply-clicked="OnUserEditorApplyClicked"
        >
        </UserEditor>

        <ActionCard
          class="active-devices mb-3"
          :headerText="ActiveDevicesCaption"
          :headerButtonDatas="ActiveDevicesButtonDatas"
          :initiallyCollapsed="ActiveDevicesCollapsed"
          test-id="user.activeDevices"
          @terminate-other-sessions-header-button-clicked="TerminateAllSessions"
          @collapse-toggled="ActiveDevicesCollapseToggled"
        >
          <SortableHeader :header-items="SessionsHeader" />
          <div class="thin-scroll overflow-overlay">
            <ListItemV3
              v-for="item in SessionsInfo"
              :id="item.id"
              :key="item.id.toString()"
              :items="SessionListItems(item)"
              test-id="user.activeDevices"
            />
          </div>

          <div :class="['spinner-container', LoadingUser ? '' : 'hidden']">
            <div :class="['spinner', LoadingUser ? '' : 'hidden']"></div>
          </div>
        </ActionCard>

        <ActionCard
          v-if="IsConnectUser"
          class="jobs mb-3"
          :headerText="CurrentJobsCaption"
          :initiallyCollapsed="CurrentJobsCollapsed"
          test-id="user.currentJobs"
          @collapse-toggled="CurrentJobsCollapseToggled"
        >
          <SortableHeader
            :header-items="PrintJobHeaders"
            :by.sync="SortByJobHeader"
            :mode.sync="JobSortMode"
            test-id="user.currentJobs"
            @sortBy="OnJobSortBy"
          />
          <div :class="['thin-scroll overflow-overlay']" @scroll="OnJobsScrolled">
            <ListItemV3
              v-for="item in UserPrintJobs"
              :id="item.Id"
              :key="item.Id.toString()"
              class="cur-pointer"
              test-id="user.currentJobs"
              :items="PrintJobItems(item)"
              @openItem="OpenJob(item)"
            />
          </div>

          <div :class="['spinner-container', isLoadingJobs ? '' : 'hidden']">
            <div :class="['spinner', isLoadingJobs ? '' : 'hidden']"></div>
          </div>
        </ActionCard>

        <ActionCard
          v-if="IsConnectUser"
          class="archive-jobs"
          :headerText="ArchieveJobsCaption"
          :initiallyCollapsed="ArchiveJobsCollapsed"
          test-id="user.archiveJobs"
          @collapse-toggled="ArchiveJobsCollapseToggled"
        >
          <SortableHeader
            :header-items="ArchieveJobHeaders"
            :by.sync="SortByJobArchieveHeader"
            :mode.sync="JobArchieveSortMode"
            test-id="user.archiveJobs"
            @sortBy="OnJobArchievesSortBy"
          />
          <div class="thin-scroll overflow-overlay" @scroll="OnJobArchievesScrolled">
            <ListItemV3
              v-for="item in FullJobArchieves"
              :id="item.jobArchieve.Id"
              :key="item.jobArchieve.Id.toString()"
              class="cur-pointer"
              test-id="user.archiveJobs"
              :items="ArchieveJobItems(item)"
              @openItem="OpenArchieveJob(item)"
            />
          </div>

          <div :class="['spinner-container', isLoadingJobArchives ? '' : 'hidden']">
            <div :class="['spinner', isLoadingJobArchives ? '' : 'hidden']"></div>
          </div>
        </ActionCard>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { AsyncBatchQueueSignalR } from '@/store/util/Globals';
import StatPanel from '@/components/presentation/StatPanel.vue';
import ActionCard, { HeaderButtonData } from '@/components/presentation/ActionCard.vue';
import ChangePassword from '@/components/forms/ChangePassword.vue';
import ListItemV3 from '@/components/presentation/ListItemV3.vue';
import SortableHeader from '@/components/presentation/SortableHeader.vue';
import UserEditor from '@/components/forms/UserEditor.vue';
import en from '@/localization/en';
import { toast } from '@/main';
import { Job, JobArchieve, LoginSession, User, UserJob, UserStat } from '@/models/Entities';
import { GlobalDataModule } from '@/store/modules/globalDataModule';
import jobModule, { ArchieveJobQueryAdditions, JobModule, JobQueryAdditions } from '@/store/modules/jobModule';
import { LoginModule } from '@/store/modules/loginModule';
import { LoginSessionModule } from '@/store/modules/loginSessionModule';
import { PrinterModule } from '@/store/modules/printerModule';
import { SourceModule } from '@/store/modules/sourceModule';
import { StatModule } from '@/store/modules/statModule';
import { UserModule } from '@/store/modules/userModule';
import ComponentHelper, { HeaderItem, ItemData, SortMode, StatItemModel } from '@/util/ComponentHelper';
import { GuidHelper } from '@/util/GuidHelper';
import { Guid } from 'guid-typescript';
import { delay } from 'lodash';
import { create } from 'vue-modal-dialogs';
import { Component, Vue, Watch, Emit } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { POSITION } from 'vue-toastification';
import AuraMessageBoxDialog, { Result } from './dialogs/AuraMessageBoxDialog.vue';
import { FullJobArchieve } from '@/models/CompositeEntities';
import { ArchieveJobSortBy, JobSortBy } from '@/models/requests/RequestMonolith';
import {
  SortFullJobArchieveByFileDESC,
  SortFullJobArchieveByFileASC,
  SortFullJobArchieveByPrinterDESC,
  SortFullJobArchieveByPrinterASC,
  SortFullJobArchieveDateEndDESC,
  SortFullJobArchieveDateEndASC,
  SortFullJobArchieveByStatusDESC,
  SortFullJobArchieveByStatusASC,
  SortFullJobArchieveByIdDESC,
} from '@/models/util/JobArchieveSortings';
import {
  SortUserJobByFileDESC,
  SortUserJobByFileASC,
  SortUserJobByPrinterDESC,
  SortUserJobByPrinterASC,
  SortUserJobByDateStartDESC,
  SortUserJobByDateStartASC,
  SortUserJobByProgressDESC,
  SortUserJobByProgressASC,
  SortUserJobByIdDESC,
} from '@/models/util/UserJobSortings';
import TextInput from '@/components/inputs/TextInput.vue';

const TerminateAllSessionsSure = create<Result, String, String, Result>(
  AuraMessageBoxDialog,
  'results',
  'header',
  'mainText',
);

export interface LoginSessionInfoModel {
  id: string;
  ip: string;
  lastSeen: string;
}

@Component({
  components: {
    ActionCard: ActionCard,
    SortableHeader: SortableHeader,
    ListItemV3: ListItemV3,
    StatPanel: StatPanel,
    UserEditor: UserEditor,
    ChangePassword: ChangePassword,
    TextInput: TextInput,
  },
  name: 'user-info-new',
})
export default class UserInfoNew extends Vue {
  // @Prop() private user!: User | undefined;

  //#region WATCHERS
  @Watch('$route', { immediate: true, deep: true })
  private async OnRouteChanged(newVal?: Route, oldVal?: Route) {
    const userId = this.$route.query.userId;

    if (userId !== undefined && Guid.isGuid(userId)) {
      this.UserId = Guid.parse(userId as string);
    } else {
      this.UserId = undefined;
    }

    if (oldVal !== undefined) {
      const oldUserId = oldVal.query.userId;

      if (oldUserId !== undefined && Guid.isGuid(oldUserId)) {
        const oldId = Guid.parse(oldUserId as string);
        await this.UnsubscribeFromRequiredGroups(oldId);
      }
    }

    await this.LoadUser();
    await this.LoadUserRelatedData();

    if (this.UserId != undefined) {
      await this.SubscribeToRequiredGroups(this.UserId);
    } else {
      await this.SubscribeToRequiredGroups(LoginModule.UserId);
    }
  }
  //#endregion

  //#region STATE

  private _componentId!: Guid;

  private isLoadingUserStats: boolean = false;
  private isLoadingJobs: boolean = false;
  private isLoadingJobArchives: boolean = false;

  //#region VIEW STATE
  private get InfoCollapsed(): boolean {
    return GlobalDataModule.UserInfoViewState.infoCollapsed;
  }

  private get StatsCollapsed(): boolean {
    return GlobalDataModule.UserInfoViewState.statsCollapsed;
  }

  private get ActiveDevicesCollapsed(): boolean {
    return GlobalDataModule.UserInfoViewState.activeDevicesCollapsed;
  }

  private get CurrentJobsCollapsed(): boolean {
    return GlobalDataModule.UserInfoViewState.currentJobsCollapsed;
  }

  private get ArchiveJobsCollapsed(): boolean {
    return GlobalDataModule.UserInfoViewState.archiveJobsCollapsed;
  }
  //#endregion

  //#region USER
  private UserId!: Guid | undefined;
  private user: User = new User(); // WTF!!! IF WE REMOVE new User() FROM HERE EVERYTHING BREAKS???
  private EditingUser: boolean = false;
  private ChangingUserPassword: boolean = false;

  private get LoadingUser(): boolean {
    return this.user.Id === undefined;
  }

  private get IsSuperAdmin(): boolean {
    return LoginModule.IsSuperAdmin;
  }

  private get IsConnectUser(): boolean {
    return LoginModule.IsConnectUser;
  }

  private get InfoButtonDatas(): HeaderButtonData[] {
    const result: HeaderButtonData[] = [];

    if (this.IsMe || LoginModule.IsSuperAdmin) {
      result.push({
        iconType: 'far',
        iconName: 'fa-pen',
        text: 'Edit',
        name: 'edit',
        testId: 'editButton',
      });

      result.push({
        iconType: 'far',
        iconName: 'fa-key',
        text: 'Change password',
        name: 'change-password',
        testId: 'changePasswordButton',
      });
    }

    return result;
  }

  private get ActiveDevicesButtonDatas(): HeaderButtonData[] {
    const result: HeaderButtonData[] = [];

    if (this.IsMe || LoginModule.IsSuperAdmin) {
      result.push({
        iconType: 'far',
        iconName: 'fa-hand-paper',
        text: 'Terminate other',
        name: 'terminate-other-sessions',
        testId: 'terminateOtherSessionsButton',
      });
    }

    return result;
  }

  private get User(): User {
    return this.user;
  }

  private get IsMe(): boolean {
    return this.user.Id != undefined && this.user.Id.equals(LoginModule.UserId);
  }

  private get UserFullName() {
    return this.UserFirstName + ' ' + this.UserSecondName;
  }

  private get UserEmail() {
    if (this.User === undefined || !this.User.Email) return '—';
    return this.User.Email;
  }

  private get UserFirstName() {
    if (this.User === undefined || !this.User.FirstName) return '—';
    return this.User.FirstName;
  }

  private get UserSecondName() {
    if (this.User === undefined || !this.User.SecondName) return '—';
    return this.User.SecondName;
  }

  private get UserPhone() {
    if (this.User === undefined || !this.User.Phone) return '—';
    return this.User.Phone;
  }
  //#endregion

  //#region SESSIONS
  private get Sessions(): LoginSession[] {
    if (this.LoadingUser) return [];

    let userId = this.user.Id;

    let sessions = LoginSessionModule.LoginSessions.filter(a => a.UserId.equals(userId)).sort((a, b) =>
      a.LastSeen.getTime() > b.LastSeen.getTime() ? -1 : 1,
    );

    return sessions;
  }

  private get SessionsInfo(): LoginSessionInfoModel[] {
    if (this.LoadingUser) return [];

    let infos: LoginSessionInfoModel[] = [];

    for (let item of this.Sessions) {
      let info: LoginSessionInfoModel = {
        ip: item.IP,
        lastSeen: ComponentHelper.GetReadableDateTime(item.LastSeen),
        id: item.Id.toString(),
      };

      infos.push(info);
    }

    return infos;
  }

  //#region HEADERS & ITEMS
  private get SessionsHeader(): HeaderItem[] {
    let result: HeaderItem[] = [];

    result.push({
      caption: 'Devices',
      itemClass: ComponentHelper.GetWidthClass(),
    });

    result.push({
      caption: 'Status',
      itemClass: ComponentHelper.GetWidthClass(),
    });

    return result;
  }

  private SessionListItems(value: LoginSessionInfoModel): ItemData[] {
    let result: ItemData[] = [];

    const itemDevice: ItemData = new ItemData('Device', value.ip, 'grow-1');
    const itemStatus: ItemData = new ItemData('Status', value.lastSeen, 'grow-1');

    result.push(itemDevice);
    result.push(itemStatus);

    return result;
  }
  //#endregion
  //#endregion

  //#region JOBS
  private sortByJobHeader: HeaderItem | null = null;
  private jobSortMode: SortMode | null = null;
  private jobPage: number = 0;
  private canScrollLoadJobs: boolean = true;

  private get SortByJobHeader(): HeaderItem | null {
    return this.sortByJobHeader;
  }
  private set SortByJobHeader(value: HeaderItem | null) {
    this.sortByJobHeader = value;
  }
  private get JobSortMode(): SortMode | null {
    return this.jobSortMode;
  }
  private set JobSortMode(value: SortMode | null) {
    this.jobSortMode = value;
  }

  private get Jobs(): Job[] {
    return JobModule.Jobs.filter(a => a.UserId != null && a.UserId?.toString() == this.user?.Id?.toString()).map(
      a => a,
    );
  }

  private get UserPrintJobs(): UserJob[] {
    let userPrintJobs: UserJob[] = [];

    for (let printJob of this.Jobs) {
      let userPrintJob = new UserJob();
      userPrintJob.Job = printJob;
      userPrintJob.Id = printJob.Id;
      userPrintJob.Progress = printJob.Progress;
      userPrintJob.Status = printJob.JobState;
      userPrintJob.StartTime = printJob.ActualStartTime == null ? printJob.CreationDateTime : printJob.ActualStartTime;
      let printer = PrinterModule.Printers.singleOrDefault(a => a.Id.equals(printJob.PrinterId));
      if (printer == null) continue;
      userPrintJob.Printer = printer;
      let printJobSource = SourceModule.Sources.singleOrDefault(a =>
        GuidHelper.equalsWithNull(a.Id, printJob.SourceId),
      );
      if (printJobSource == null) continue;
      userPrintJob.Source = printJobSource;
      userPrintJobs.push(userPrintJob);
    }

    userPrintJobs = this.SortJobs(userPrintJobs);

    const maxCount = (this.jobPage + 1) * 25;

    const toDisplay = userPrintJobs.splice(0, maxCount);
    const toLoose = userPrintJobs.slice(maxCount);

    JobModule.LooseJobs([toLoose.map(a => a.Job), this._componentId]);
    JobModule.OccupyJobs([toDisplay.map(a => a.Job), this._componentId]);

    SourceModule.LooseSources([toLoose.filter(a => a.Source != null).map(a => a.Source!), this._componentId]);
    SourceModule.OccupySources([toDisplay.filter(a => a.Source != null).map(a => a.Source!), this._componentId]);

    if (toLoose.length > 0) {
      JobModule.CollectJobs();
      SourceModule.CollectSources();
    }

    return toDisplay;
  }

  //#region HEADERS & ITEMS
  private jobHeaderFile: HeaderItem = {
    caption: 'File',
    itemClass: ComponentHelper.GetWidthClass('grow-2'),
    isSortable: true,
  };
  private jobHeaderPrinter: HeaderItem = {
    caption: 'Printer',
    itemClass: ComponentHelper.GetWidthClass('grow-1'),
    isSortable: true,
  };
  private jobHeaderStarted: HeaderItem = {
    caption: 'Started',
    itemClass: ComponentHelper.GetWidthClass(165),
    isSortable: true,
    width: 165,
  };
  private jobHeaderProgress: HeaderItem = {
    caption: 'Progress',
    itemClass: ComponentHelper.GetWidthClass(100),
    isSortable: true,
  };
  private get PrintJobHeaders(): HeaderItem[] {
    const result: HeaderItem[] = [];

    result.push(this.jobHeaderFile);
    result.push(this.jobHeaderPrinter);
    result.push(this.jobHeaderStarted);
    result.push(this.jobHeaderProgress);

    return result;
  }

  private PrintJobItems(value: UserJob): ItemData[] {
    const result: ItemData[] = [];

    let itemFile = new ItemData(
      'File',
      value.Job.LocalFilename != null
        ? value.Job.LocalFilename
        : value.Source == null
          ? '-'
          : value.Source.CodeFileName,
      'grow-2',
    );
    let itemPrinter = new ItemData('Printer', value.Printer.Name, 'grow-1');
    let itemStarted = new ItemData(
      'Started',
      value.Job.ActualStartTime == null ? '—' : ComponentHelper.GetReadableDateTime(value.Job.ActualStartTime, true),
      165,
    );
    let itemProgress = new ItemData('Progress', value.Progress == null ? '—' : value.Progress.toFixed(2) + '%', 100);

    result.push(itemFile);
    result.push(itemPrinter);
    result.push(itemStarted);
    result.push(itemProgress);

    return result;
  }
  //#endregion
  //#endregion

  //#region ARCHIEVE JOBS
  private sortByJobArchieveHeader: HeaderItem | null = null;
  private jobArchieveSortMode: SortMode | null = null;
  private jobArchievePage: number = 0;
  private canScrollLoadArchives: boolean = true;

  private get SortByJobArchieveHeader(): HeaderItem | null {
    return this.sortByJobArchieveHeader;
  }
  private set SortByJobArchieveHeader(value: HeaderItem | null) {
    this.sortByJobArchieveHeader = value;
  }
  private get JobArchieveSortMode(): SortMode | null {
    return this.jobArchieveSortMode;
  }
  private set JobArchieveSortMode(value: SortMode | null) {
    this.jobArchieveSortMode = value;
  }

  private get JobArchieves(): JobArchieve[] {
    let jobArchieves = JobModule.JobArchieves.filter(
      a => a.UserId != null && a.UserId?.toString() == this.user?.Id?.toString(),
    ).map(a => a);

    return jobArchieves;
  }

  private get FullJobArchieves(): FullJobArchieve[] {
    let fullJobArchieves: FullJobArchieve[] = [];

    for (let archieve of this.JobArchieves) {
      let printer = PrinterModule.Printers.singleOrDefault(a => a.Id.toString() === archieve.PrinterId.toString());
      if (printer == null) continue;
      let source = SourceModule.Sources.singleOrDefault(a => GuidHelper.equalsWithNull(a.Id, archieve.SourceId));
      let fullJob: FullJobArchieve = {
        jobArchieve: archieve,
        printer: printer,
        source: source,
        owner: this.user,
      };
      fullJobArchieves.push(fullJob);
    }

    fullJobArchieves = this.SortFullJobArchieves(fullJobArchieves);

    const maxCount = (this.jobArchievePage + 1) * 25;

    const toDisplay = fullJobArchieves.splice(0, maxCount);
    const toLoose = fullJobArchieves.slice(maxCount);

    JobModule.LooseJobArchieves([toLoose.map(a => a.jobArchieve), this._componentId]);
    JobModule.OccupyJobArchieves([toDisplay.map(a => a.jobArchieve), this._componentId]);

    if (toLoose.length > 0) {
      JobModule.CollectJobArchieves();
    }

    return toDisplay;
  }

  //#region HEADERS & ITEMS
  private jobArchHeaderFile: HeaderItem = {
    caption: 'File',
    itemClass: ComponentHelper.GetWidthClass('grow-2'),
    isSortable: true,
  };
  private jobArchHeaderPrinter: HeaderItem = {
    caption: 'Printer',
    itemClass: ComponentHelper.GetWidthClass('grow-1'),
    isSortable: true,
  };
  private jobArchHeaderFinished: HeaderItem = {
    caption: 'Finished',
    itemClass: ComponentHelper.GetWidthClass(165),
    isSortable: true,
    width: 165,
  };
  private jobArchHeaderStatus: HeaderItem = {
    caption: 'Status',
    itemClass: ComponentHelper.GetWidthClass(100),
    isSortable: true,
  };
  private get ArchieveJobHeaders(): HeaderItem[] {
    const result: HeaderItem[] = [];

    result.push(this.jobArchHeaderFile);
    result.push(this.jobArchHeaderPrinter);
    result.push(this.jobArchHeaderFinished);
    result.push(this.jobArchHeaderStatus);

    return result;
  }

  private ArchieveJobItems(value: FullJobArchieve): ItemData[] {
    const result: ItemData[] = [];

    let itemFile = new ItemData('File', value.source == null ? '—' : value.source.CodeFileName, 'grow-2');
    let itemPrinter = new ItemData('Printer', value.printer.Name, 'grow-1');
    let itemFinished = new ItemData(
      'Finished',
      value.jobArchieve.EndTime == null ? '—' : ComponentHelper.GetReadableDateTime(value.jobArchieve.EndTime, true),
      165,
    );
    let itemStatus = new ItemData(
      'Status',
      value.jobArchieve.EndStatus == null ? '—' : ComponentHelper.GetReadableEndStatus(value.jobArchieve.EndStatus),
      100,
    );

    result.push(itemFile);
    result.push(itemPrinter);
    result.push(itemFinished);
    result.push(itemStatus);

    return result;
  }
  //#endregion
  //#endregion

  //#region STATS
  private get Stat(): UserStat | undefined {
    if (this.LoadingUser) return undefined;

    let userId = this.user.Id;
    let stat = StatModule.UserStats.find(a => a.userId.equals(userId));

    return stat;
  }

  private get StatModels(): StatItemModel[] {
    if (!this.Stat) return [];

    let jobCountWeek: StatItemModel = {
      value: this.Stat.jobCountWeek,
      dimension: this.JobsCaption,
      status: this.WeekCaption,
    };
    let jobCountMonth: StatItemModel = {
      value: this.Stat.jobCountMonth,
      dimension: this.JobsCaption,
      status: this.MonthCaption,
    };
    let jobCountWhole: StatItemModel = {
      value: this.Stat.jobCountWhole,
      dimension: this.JobsCaption,
      status: this.WholeCaption,
    };
    let printTimeWeekH: StatItemModel = {
      value: ComponentHelper.GetH(this.Stat.printTimeWeekS),
      dimension: this.PrintHoursCaption,
      status: this.WeekCaption,
    };
    let printTimeMonthH: StatItemModel = {
      value: ComponentHelper.GetH(this.Stat.printTimeMonthS),
      dimension: this.PrintHoursCaption,
      status: this.MonthCaption,
    };
    let printTimeWholeH: StatItemModel = {
      value: ComponentHelper.GetH(this.Stat.printTimeWholeS),
      dimension: this.PrintHoursCaption,
      status: this.WholeCaption,
    };
    let sourceCount: StatItemModel = {
      value: this.Stat.sourceCount,
      dimension: this.FilesCaption,
      status: this.WholeCaption,
    };
    let plasticConsumedGr: StatItemModel = {
      value: this.Stat.plasticConsumedGr * 1e6,
      dimension: `${this.plasticCaption}(${this.CmCubedCaption})`,
      status: this.WholeCaption,
    };
    let compositeConsumedM: StatItemModel = {
      value: this.Stat.compositeConsumedM,
      dimension: `${this.compositeCaption}(${this.MCaption})`,
      status: this.WholeCaption,
    };

    let statModels: StatItemModel[] = [];

    statModels.push(jobCountWeek);
    statModels.push(jobCountMonth);
    statModels.push(jobCountWhole);
    statModels.push(printTimeWeekH);
    statModels.push(printTimeMonthH);
    statModels.push(printTimeWholeH);
    statModels.push(sourceCount);
    statModels.push(plasticConsumedGr);
    statModels.push(compositeConsumedM);

    return statModels;
  }
  //#endregion

  //#endregion

  //#region LOGIC
  async LoadUser() {
    if (this.UserId === undefined) {
      this.user = (await UserModule.LoadUser(LoginModule.UserId)) as User;
    } else {
      this.user = (await UserModule.LoadUser(this.UserId)) as User;
    }
  }

  async LoadUserRelatedData() {
    if (this.IsConnectUser) {
      const loadStats = async () => {
        this.isLoadingUserStats = true;
        await StatModule.LoadUserStat(this.User.Id);
        this.isLoadingUserStats = false;
      };

      await JobModule.LooseJobs([this.Jobs, this._componentId]);
      await JobModule.CollectJobs();
      this.jobPage = 0;

      await JobModule.LooseJobArchieves([this.JobArchieves, this._componentId!]);
      await JobModule.CollectJobArchieves();
      this.jobArchievePage = 0;

      await Promise.all([loadStats(), this.LoadMoreJobs(), this.LoadMoreJobArchieves()]);
    }
  }

  async SubscribeToRequiredGroups(userId: Guid) {
    AsyncBatchQueueSignalR.Queue({
      Batch: async () => {
        await UserModule.SubscribeToUserGroup(userId);

        if (this.IsConnectUser) {
          await SourceModule.SubscribeToCompanySourcesGroup();
          await PrinterModule.SubscribeToCompanyPrintersGroup();
          await JobModule.SubscribeToCompanyJobsGroup();
          await StatModule.SubscribeToUserGroup(userId);
        }
      },
    });
  }

  async UnsubscribeFromRequiredGroups(userId: Guid) {
    AsyncBatchQueueSignalR.Queue({
      Batch: async () => {
        await UserModule.DeleteFromUserGroup(userId);

        if (this.IsConnectUser) {
          await SourceModule.DeleteFromCompanySourcesGroup();
          await PrinterModule.DeleteFromCompanyPrintersGroup();
          await JobModule.DeleteFromCompanyJobsGroup();
          await StatModule.DeleteFromUserGroup(userId);
        }
      },
    });
  }

  async TerminateAllSessions() {
    const result = await TerminateAllSessionsSure(
      Result.Yes | Result.No,
      'Terminate all sessions',
      'Are you sure you want to termiate all other sessions?',
    );

    if (result == Result.No || result == Result.Cancel) {
      return;
    }

    let sessionsToTerminate = this.Sessions;

    if (this.IsMe) {
      sessionsToTerminate = sessionsToTerminate.filter(a => !a.Id.equals(LoginModule.LoginSessionId));
    }

    await LoginSessionModule.TerminateAll(sessionsToTerminate);
  }

  async OnPasswordApplyClicked(success: boolean | null) {
    if (success === null) {
      return;
    }

    if (success) {
      this.ChangingUserPassword = false;

      toast.success('Password changed successfuly', {
        position: POSITION.BOTTOM_RIGHT,
      });
    } else {
      this.ChangingUserPassword = true;

      toast.error('Could not change password', {
        position: POSITION.BOTTOM_RIGHT,
      });
    }
  }

  async OnUserEditorApplyClicked(success: boolean | null) {
    if (success === null) {
      return;
    }

    if (success) {
      this.EditingUser = false;

      toast.success('User information changed successfuly', {
        position: POSITION.BOTTOM_RIGHT,
      });
    } else {
      this.EditingUser = true;

      toast.error('Could not change user information', {
        position: POSITION.BOTTOM_RIGHT,
      });
    }
  }

  //#region JOB RELATED
  async OnJobSortBy() {
    await JobModule.LooseJobs([this.Jobs, this._componentId]);
    await JobModule.CollectJobs();

    this.jobPage = 0;
    await this.LoadMoreJobs();
  }

  private GetJobSortBy(headerItem: HeaderItem | null): JobSortBy | null {
    if (headerItem === this.jobHeaderFile) return JobSortBy.BY_FILE;
    else if (headerItem === this.jobHeaderPrinter) return JobSortBy.BY_PRINTER;
    else if (headerItem === this.jobHeaderStarted) return JobSortBy.BY_DATE;
    else if (headerItem === this.jobHeaderProgress) return JobSortBy.BY_PROGRESS;

    return null;
  }

  async LoadMoreJobs() {
    this.isLoadingJobs = true;

    let jobsAdditions = new JobQueryAdditions();
    jobsAdditions.pageSize = 25;
    jobsAdditions.page = this.jobArchievePage;
    jobsAdditions.sortBy = this.GetJobSortBy(this.SortByJobHeader);
    jobsAdditions.sortMode = this.JobSortMode;

    await JobModule.LoadMoreForUser([jobsAdditions, this.User.Id]);

    const userJobs = JobModule.Jobs.filter(a => a.UserId?.toString() == this.User.Id.toString());

    if (userJobs.any()) {
      let printerIds = GuidHelper.distinct(userJobs.map(a => a.PrinterId));
      let sourceIds = jobModule.GetSourceIdsByJobs(userJobs);
      let userIds = jobModule.GetUserIdsByJobs(userJobs);

      await Promise.all([
        PrinterModule.LoadPrinters(printerIds),
        SourceModule.LoadSources(sourceIds),
        UserModule.LoadUsers(userIds),
      ]);
    }

    this.isLoadingJobs = false;
  }

  async OnJobsScrolled(e: any) {
    const currScroll = e.target.scrollTop;
    const endScroll = e.target.scrollHeight - e.target.clientHeight;

    if (currScroll === endScroll && currScroll > 0 && this.canScrollLoadJobs) {
      this.canScrollLoadJobs = false;

      ++this.jobPage;
      await this.LoadMoreJobs();

      delay(() => {
        this.canScrollLoadJobs = true;
      }, 500);
    }
  }

  SortJobs(jobs: UserJob[]): UserJob[] {
    let sortBy = this.SortByJobHeader == null ? undefined : this.GetJobSortBy(this.SortByJobHeader);

    if (
      (this.JobSortMode == null && sortBy !== undefined) ||
      (this.JobSortMode != null && sortBy === undefined) ||
      jobs.length == 0
    )
      return jobs;

    if (sortBy === JobSortBy.BY_FILE) {
      if (this.JobSortMode === SortMode.ASC) return jobs.sort(SortUserJobByIdDESC).sort(SortUserJobByFileASC);
      if (this.JobSortMode === SortMode.DESC) return jobs.sort(SortUserJobByIdDESC).sort(SortUserJobByFileDESC);
    } else if (sortBy === JobSortBy.BY_PRINTER) {
      if (this.JobSortMode === SortMode.ASC) return jobs.sort(SortUserJobByIdDESC).sort(SortUserJobByPrinterASC);
      if (this.JobSortMode === SortMode.DESC) return jobs.sort(SortUserJobByIdDESC).sort(SortUserJobByPrinterDESC);
    } else if (sortBy === JobSortBy.BY_DATE) {
      if (this.JobSortMode === SortMode.ASC) return jobs.sort(SortUserJobByIdDESC).sort(SortUserJobByDateStartASC);
      if (this.JobSortMode === SortMode.DESC) return jobs.sort(SortUserJobByIdDESC).sort(SortUserJobByDateStartDESC);
    } else if (sortBy === JobSortBy.BY_PROGRESS) {
      if (this.JobSortMode === SortMode.ASC) return jobs.sort(SortUserJobByIdDESC).sort(SortUserJobByProgressASC);
      if (this.JobSortMode === SortMode.DESC) return jobs.sort(SortUserJobByIdDESC).sort(SortUserJobByProgressDESC);
    }

    return jobs;
  }
  //#endregion

  //#region JOB ARCHIEVE RELATED
  async OnJobArchievesSortBy() {
    JobModule.LooseJobArchieves([this.JobArchieves, this._componentId!]);
    JobModule.CollectJobArchieves();

    this.jobArchievePage = 0;
    await this.LoadMoreJobArchieves();
  }

  private GetJobArchieveSortBy(headerItem: HeaderItem | null): ArchieveJobSortBy | null {
    if (headerItem === this.jobArchHeaderFile) return ArchieveJobSortBy.BY_FILE;
    else if (headerItem === this.jobArchHeaderPrinter) return ArchieveJobSortBy.BY_PRINTER;
    else if (headerItem === this.jobArchHeaderFinished) return ArchieveJobSortBy.BY_DATE_END;
    else if (headerItem === this.jobArchHeaderStatus) return ArchieveJobSortBy.BY_STATUS;

    return null;
  }

  async LoadMoreJobArchieves() {
    this.isLoadingJobArchives = true;

    let archJobAdditions = new ArchieveJobQueryAdditions();
    archJobAdditions.pageSize = 25;
    archJobAdditions.page = this.jobArchievePage;
    archJobAdditions.sortBy = this.GetJobArchieveSortBy(this.SortByJobArchieveHeader);
    archJobAdditions.sortMode = this.JobArchieveSortMode;

    await JobModule.LoadMoreArchieveForUser([archJobAdditions, this.User.Id]);

    this.isLoadingJobArchives = false;
  }

  async OnJobArchievesScrolled(e: any) {
    const currScroll = e.target.scrollTop;
    const endScroll = e.target.scrollHeight - e.target.clientHeight;

    if (currScroll === endScroll && currScroll > 0 && this.canScrollLoadArchives) {
      this.canScrollLoadArchives = false;

      ++this.jobArchievePage;
      await this.LoadMoreJobArchieves();

      delay(() => {
        this.canScrollLoadArchives = true;
      }, 500);
    }
  }

  SortFullJobArchieves(jobArchs: FullJobArchieve[]): FullJobArchieve[] {
    let sortBy =
      this.SortByJobArchieveHeader == null ? undefined : this.GetJobArchieveSortBy(this.SortByJobArchieveHeader);

    if (
      (this.JobArchieveSortMode == null && sortBy !== undefined) ||
      (this.JobArchieveSortMode != null && sortBy === undefined) ||
      jobArchs.length == 0
    )
      return jobArchs;

    if (sortBy === ArchieveJobSortBy.BY_FILE) {
      if (this.JobArchieveSortMode === SortMode.ASC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveByFileASC);
      if (this.JobArchieveSortMode === SortMode.DESC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveByFileDESC);
    } else if (sortBy === ArchieveJobSortBy.BY_PRINTER) {
      if (this.JobArchieveSortMode === SortMode.ASC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveByPrinterASC);
      if (this.JobArchieveSortMode === SortMode.DESC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveByPrinterDESC);
    } else if (sortBy === ArchieveJobSortBy.BY_DATE_END) {
      if (this.JobArchieveSortMode === SortMode.ASC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveDateEndASC);
      if (this.JobArchieveSortMode === SortMode.DESC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveDateEndDESC);
    } else if (sortBy === ArchieveJobSortBy.BY_STATUS) {
      if (this.JobArchieveSortMode === SortMode.ASC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveByStatusASC);
      if (this.JobArchieveSortMode === SortMode.DESC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveByStatusDESC);
    }

    return jobArchs;
  }
  //#endregion

  //#region VIEW STATE
  private async InfoCollapseToggled(isCollapsed: boolean) {
    GlobalDataModule.ChangeUserInfoViewInfoCollapsed(isCollapsed);
  }

  private async StatsCollapseToggled(isCollapsed: boolean) {
    GlobalDataModule.ChangeUserInfoViewStatsCollapsed(isCollapsed);
  }

  private async ActiveDevicesCollapseToggled(isCollapsed: boolean) {
    GlobalDataModule.ChangeUserInfoViewActiveDevicesCollapsed(isCollapsed);
  }

  private async CurrentJobsCollapseToggled(isCollapsed: boolean) {
    GlobalDataModule.ChangeUserInfoViewCurrentJobsCollapsed(isCollapsed);
  }

  private async ArchiveJobsCollapseToggled(isCollapsed: boolean) {
    GlobalDataModule.ChangeUserInfoViewArchiveJobsCollapsed(isCollapsed);
  }
  //#endregion

  //#endregion

  //#region HOOKS
  async beforeCreate() {
    this._componentId = Guid.create();
  }

  async beforeDestroy() {
    if (this.UserId != undefined) {
      await this.UnsubscribeFromRequiredGroups(this.UserId);
    } else {
      await this.UnsubscribeFromRequiredGroups(LoginModule.UserId);
    }

    await SourceModule.LooseSources([
      this.UserPrintJobs.filter(a => a.Source != null).map(a => a.Source!),
      this._componentId,
    ]);
    await SourceModule.LooseSources([
      this.FullJobArchieves.filter(a => a.source != null).map(a => a.source!),
      this._componentId,
    ]);
    await SourceModule.CollectSources();

    await JobModule.LooseJobArchieves([this.JobArchieves, this._componentId!]);
    await JobModule.CollectJobArchieves();

    await JobModule.LooseJobs([this.Jobs, this._componentId!]);
    await JobModule.CollectJobs();
  }

  async created() {
    this.JobArchieveSortMode = SortMode.DESC;
    this.SortByJobArchieveHeader = this.jobArchHeaderFinished;
  }
  //#endregion

  //#region EVENTS
  @Emit('openJob')
  private OpenJob(job: UserJob) {
    return job.Id;
  }

  @Emit('openArchive')
  private OpenArchieveJob(archieveJob: FullJobArchieve) {
    return archieveJob.jobArchieve.Id;
  }
  //#endregion

  //#region TRANSLATIONS
  private get InfoCaption() {
    return en.info.growFirst();
  }

  private get ActiveDevicesCaption() {
    return en.activeDevices.growFirst();
  }

  private get CurrentJobsCaption() {
    return en.currentJobs.growFirst();
  }

  private get ArchieveJobsCaption() {
    return en.archieveJobs.growFirst();
  }

  private get EmailCaption() {
    return en.email.growFirst();
  }

  private get FirstNameCaption() {
    return en.firstName.growFirst();
  }

  private get SecondNameCaption() {
    return en.secondName.growFirst();
  }

  private get PhoneCaption() {
    return en.phone.growFirst();
  }

  private get plasticCaption(): string {
    return en.plastic;
  }
  private get compositeCaption(): string {
    return en.composite;
  }
  private get CmCubedCaption(): string {
    return en.cmCubed;
  }
  private get MCaption(): string {
    return en.meter;
  }
  private get WeekCaption(): string {
    return en.week;
  }
  private get MonthCaption(): string {
    return en.month;
  }
  private get WholeCaption(): string {
    return en.total;
  }
  private get JobsCaption(): string {
    return en.jobs;
  }
  private get PrintHoursCaption(): string {
    return en.printHours;
  }
  private get FilesCaption(): string {
    return en.files;
  }
  private get StatsCaption(): string {
    return en.stats.growFirst();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.user-info-container {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;

  .full-name {
    color: var(--main-text);
    font-size: 20px;
  }
}

.user-info {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: auto;

  .left-panel {
    width: 55%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .right-panel {
    overflow: auto;
    width: 45%;
    display: flex;
    flex-direction: column;
    min-width: 650px;
  }

  .vertical-resizer {
    cursor: col-resize;
    width: 10px;
    margin: 0 3px;
  }

  .user-info-line {
    display: flex;
    font-size: 13px;
    margin-bottom: 0.75rem;
    &:last-child {
      margin-bottom: 0;
    }

    margin-left: 8px;
    .field {
      color: #949494;
      width: 138px;
      text-align: start;
    }

    .value {
      color: #cecece;
    }
  }

  .user-data {
    position: relative;
  }

  .user-stats-container {
    background: none;
    border: solid transparent 1px;
  }

  .user-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    row-gap: 16px;
    column-gap: 16px;
    position: relative;
    min-height: 60px;
  }

  .active-devices {
    max-height: 40%;
  }

  .jobs {
    max-height: 40%;
  }

  .archive-jobs {
    max-height: 40%;
  }

  .spinner-container {
    min-height: 40px;
    margin-top: 20px;
    position: relative;
    opacity: 1;

    &.hidden {
      opacity: 0;
      min-height: 0px;
      margin-top: 0px;
    }

    transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);
  }
}
</style>
