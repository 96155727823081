<template>
  <div class="file-library-container px-3 py-3">
    <div class="file-library">
      <div class="left-panel thin-scroll">
        <ActionCard
          class="mb-3 overflow-auto categories"
          :headerText="CategoriesCaption"
          :headerButtonDatas="GroupsButtonDatas"
          :initiallyCollapsed="CategoriesCollapsed"
          test-id="library.categories"
          @collapse-toggled="CategoriesCollapseToggled"
          @add-new-category-header-button-clicked="isAddingSourceGroup = true"
        >
          <SortableHeader :header-items="GroupsHeader" test-id="library.categories" />
          <div class="thin-scroll overflow-overlay">
            <ListItemV3
              v-for="item in FullGroups"
              :id="item.group.Id"
              :key="item.group.Id.toString()"
              class="cur-pointer"
              :items="FullGroupListItems(item)"
              :selected="item.group == selectedGroup"
              :footerIconFontSize="13"
              :footerIconName="EditableGroup(item.group) ? 'fa-pen' : undefined"
              footerIconType="far"
              test-id="library.categories"
              @openItem="OpenFullSourceGroup(item)"
              @footer-icon-clicked="selectedGroupToEdit = item.group"
            />
          </div>

          <div :class="['spinner-container', isLoadingSourceGroups ? '' : 'hidden']">
            <div :class="['spinner', isLoadingSourceGroups ? '' : 'hidden']"></div>
          </div>
        </ActionCard>

        <div class="source-search-container">
          <search-input test-id="library.files" class="source-search mb-2 mt-2" @search-changed="OnSourceSearchChanged">
          </search-input>
        </div>

        <ActionCard
          class="overflow-auto files"
          test-id="library.files"
          :headerText="FilesCaption"
          :headerButtonDatas="FilesButtonDatas"
          :initiallyCollapsed="FilesCollapsed"
          @add-new-file-header-button-clicked="ShowAddNewFile"
          @collapse-toggled="FilesCollapseToggled"
        >
          <SortableHeader
            :header-items="FullSourcesHeader"
            :by.sync="SortByFullSourceHeader"
            :mode.sync="FullSourceSortMode"
            test-id="library.files"
            @sortBy="OnFullSourcesSortBy"
          />
          <div class="thin-scroll overflow-overlay" @scroll="OnSourcesScrolled">
            <ListItemV3
              v-for="item in FullSources"
              :id="item.file.Id"
              :key="item.file.Id.toString()"
              class="cur-pointer"
              :items="FullSourceItems(item)"
              :selected="IsSourceSelected(item)"
              :footerIconFontSize="13"
              :footerIconName="['fa-trash']"
              footerIconType="far"
              test-id="library.files"
              @openItem="OpenFullSource(item)"
              @footer-icon-clicked="RemoveFullSource(item)"
            />
          </div>

          <div :class="['spinner-container', isLoadingSources ? '' : 'hidden']">
            <div :class="['spinner', isLoadingSources ? '' : 'hidden']"></div>
          </div>

          <div :class="['spinner', isDeletingSource ? '' : 'hidden']"></div>
        </ActionCard>
      </div>

      <div class="vertical-resizer"></div>

      <div class="right-panel thin-scroll">
        <AddSourceCategory
          v-if="isAddingSourceGroup"
          class="mb-3"
          this.
          @close-clicked="isAddingSourceGroup = false"
          @category-added="CategoryAdded"
        >
        </AddSourceCategory>

        <SourceCategoryEditor
          v-if="EditableGroup(selectedGroupToEdit)"
          class="mb-3"
          :sourceGroup="selectedGroupToEdit"
          @close-clicked="selectedGroupToEdit = null"
          @delete-clicked="SourceGroupEditorDeleteClicked"
          @apply-clicked="SourceGroupApplyClicked"
        >
        </SourceCategoryEditor>

        <AddNewFile
          v-if="AddingNewFile"
          class="mb-3"
          :groups="GroupsExeptAll"
          :initialGroup="selectedGroup"
          @close-button-clicked="HideAddNewFile"
          @upload-successful="UploadSuccessful"
        >
        </AddNewFile>

        <SourceViewer
          v-if="selectedSource != null"
          :source="selectedSource"
          :groups="GroupsExeptAll"
          @close-cliked="CloseSourceClicked"
          @owner-clicked="OnSourceOwnerClicked"
          @delete-clicked="OnSourceDeleteClicked"
          @category-clicked="OnCategoryClicked"
          @job-archive-clicked="OnJobArchiveClicked"
        >
        </SourceViewer>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Guid } from 'guid-typescript';
import { Component, Vue, Watch, Emit } from 'vue-property-decorator';
import { create } from 'vue-modal-dialogs';
import { debounce } from 'lodash';
import { POSITION } from 'vue-toastification';

import { toast } from '@/main';
import ActionCard, { HeaderButtonData } from '@/components/presentation/ActionCard.vue';
import AddNewFile from '@/components/forms/AddNewFile.vue';
import ListItemV3 from '@/components/presentation/ListItemV3.vue';
import SearchInput from '@/components/inputs/SearchInput.vue';
import SortableHeader from '@/components/presentation/SortableHeader.vue';
import SourceViewer from '@/components/forms/SourceViewer.vue';
import en from '@/localization/en';
import { JobArchieve, Source, SourceGroup, User } from '@/models/Entities';
import { SourceGroupModule } from '@/store/modules/sourceGroupModule';
import { SourceModule } from '@/store/modules/sourceModule';
import { UserModule } from '@/store/modules/userModule';
import ComponentHelper, { HeaderItem, ItemData, SortMode } from '@/util/ComponentHelper';
import { GuidHelper } from '@/util/GuidHelper';
import AuraMessageBoxDialog, { Result } from './dialogs/AuraMessageBoxDialog.vue';
import { Routes } from '@/router/routes';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { GlobalDataModule } from '@/store/modules/globalDataModule';
import { SourceSortBy } from '@/models/requests/RequestMonolith';
import { FullSource } from '@/models/CompositeEntities';

import SourceCategoryEditor from '@/components/forms/SourceCategoryEditor.vue';
import AddSourceCategory from '@/components/forms/AddSourceCategory.vue';
import { JobModule } from '@/store/modules/jobModule';
import { AsyncBatchQueueSignalR } from '@/store/util/Globals';
import { loadMoreOnScroll } from '@/util/loadMoreOnScroll';

const DeleteSourceSure = create<Result, String, String, Result>(AuraMessageBoxDialog, 'results', 'header', 'mainText');

const PAGE_SIZE = 25;

export interface FullSourceGroup {
  group: SourceGroup;
  author: User | null;
}

@Component({
  components: {
    ActionCard: ActionCard,
    ListItemV3: ListItemV3,
    SortableHeader: SortableHeader,
    SearchInput: SearchInput,
    SourceViewer: SourceViewer,
    AddNewFile: AddNewFile,
    SourceCategoryEditor: SourceCategoryEditor,
    AddSourceCategory: AddSourceCategory,
  },
  name: 'file-library-new',
})
export default class FileLibraryNew extends Vue {
  //#region WATCHERS
  @Watch('$route', { immediate: true, deep: true })
  private async OnRouteChanged() {
    const companyId = this.$route.query.companyId;
    const sourceId = this.$route.query.sourceId;

    const oldCompanyId = this.CompanyId == undefined ? undefined : Guid.parse(this.CompanyId.toString());
    // const oldSourceId = this.SourceId == undefined ? undefined : Guid.parse(this.SourceId.toString());

    if (companyId !== undefined && Guid.isGuid(companyId)) {
      this.CompanyId = Guid.parse(companyId as string);
    } else {
      this.CompanyId = undefined;
    }

    if (sourceId !== undefined && Guid.isGuid(sourceId)) {
      this.SourceId = Guid.parse(sourceId as string);

      const loaded = this.FullSources.firstOrDefault(a => a.file.Id.equals(this.SourceId!));

      this.selectedSource = loaded;
    } else {
      this.SourceId = undefined;
      this.selectedSource = null;
    }

    if (
      !this.subscribedToGroups ||
      (this.CompanyId !== undefined && oldCompanyId !== undefined && !oldCompanyId.equals(this.CompanyId))
    ) {
      // Todo: this should be able to subscribe to different companies
      await this.SubscribeToSourceGroups();
      await this.LoadGroups();
    }
  }
  //#endregion

  //#region STATE
  private isLoadingSourceGroups = false;
  private isLoadingSources = false;
  private isDeletingSource = false;

  private componentId!: Guid;
  private subscribedToGroups!: boolean;
  private AddingNewFile: boolean = false;

  private debouncePerformSearch: any;

  //#region VIEW STATE
  private get CategoriesCollapsed(): boolean {
    return GlobalDataModule.FileLibraryViewState.categoriesCollapsed;
  }

  private get FilesCollapsed(): boolean {
    return GlobalDataModule.FileLibraryViewState.filesCollapsed;
  }
  //#endregion

  //#region GROUPS
  private isAddingSourceGroup: boolean = false;

  private EditableGroup(group: SourceGroup): boolean {
    return group != null && !group.IsVoid && group != this.everythingGroup;
  }

  private selectedGroupToEdit: SourceGroup | null = null;
  private selectedGroup: SourceGroup | null = null;
  @Watch('selectedGroup', { immediate: true })
  private async OnSelectedGroupChanged() {
    await SourceModule.LooseSources([this.Sources, this.componentId]);
    await SourceModule.CollectSources();
    this.fullSourcePage = 0;
    await this.LoadMoreSources();
  }

  private everythingGroup!: SourceGroup;
  private get EverythingGroup(): SourceGroup {
    this.everythingGroup.FileCount = 0;

    for (const group of this.GroupsExeptAll) {
      this.everythingGroup.FileCount += group.FileCount;
    }

    this.everythingGroup.LastUpdated = this.GroupsExeptAll.map(a => a.LastUpdated).sort()[0];

    return this.everythingGroup;
  }

  private get Groups(): SourceGroup[] {
    const result = SourceGroupModule.SourceGroups.map(a => a);

    SourceGroupModule.OccupySourceGroups([result, this.componentId]);

    result.unshift(this.EverythingGroup);
    return result;
  }

  private get GroupsExeptAll(): SourceGroup[] {
    const result = SourceGroupModule.SourceGroups.map(a => a);

    SourceGroupModule.OccupySourceGroups([result, this.componentId]);

    return result;
  }

  private get FullGroups(): FullSourceGroup[] {
    let fullSources: FullSourceGroup[] = [];

    for (let file of this.Groups) {
      let user: User | null = null;

      if (file.UserId != null) {
        user = UserModule.Users.singleOrDefault(a => a.Id.equals(file.UserId!));
      }

      let fullSource: FullSourceGroup = {
        group: file,
        author: user,
      };

      fullSources.push(fullSource);
    }

    return fullSources;
  }

  private get GroupsButtonDatas(): HeaderButtonData[] {
    const result: HeaderButtonData[] = [];

    result.push({
      iconType: 'fas',
      iconName: 'fa-plus',
      text: 'Add new category',
      name: 'add-new-category',
      testId: 'addNewCategoryButton',
    });

    return result;
  }

  //#region HEADERS & ITEMS
  private get GroupsHeader(): HeaderItem[] {
    let result: HeaderItem[] = [];

    result.push({
      caption: 'Name',
      itemClass: ComponentHelper.GetWidthClass(),
    });

    result.push({
      caption: 'Author',
      itemClass: ComponentHelper.GetWidthClass(),
    });

    result.push({
      caption: 'Last update',
      itemClass: ComponentHelper.GetWidthClass(),
    });

    result.push({
      caption: 'Files',
      itemClass: ComponentHelper.GetWidthClass(100),
    });

    return result;
  }

  private FullGroupListItems(value: FullSourceGroup): ItemData[] {
    let result: ItemData[] = [];

    const itemName: ItemData = new ItemData('Name', value.group.Name, 'grow-1');
    const itemAuthor: ItemData = new ItemData(
      'Author',
      value.author == undefined ? '—' : ComponentHelper.GetFullname(value.author),
      'grow-1',
    );
    const itemLastUpdate: ItemData = new ItemData(
      'LastUpdated',
      value.group.LastUpdated == null ? '—' : ComponentHelper.GetReadableDateTime(value.group.LastUpdated, true),
      'grow-1',
    );
    const itemFiles: ItemData = new ItemData('Files', value.group.FileCount.toString(), 100);

    result.push(itemName);
    result.push(itemAuthor);
    result.push(itemLastUpdate);
    result.push(itemFiles);

    return result;
  }
  //#endregion

  //#endregion

  //#region SOURCES
  private sourceSearch: string | undefined = undefined;
  private selectedSource: FullSource | null = null;

  private sortByFullSourceHeader: HeaderItem | null = null;
  private fullSourceSortMode: SortMode | null = null;
  private fullSourcePage = 0;
  private loadingFullSource = false;
  private hasMoreSource = true;

  private get SortByFullSourceHeader(): HeaderItem | null {
    return this.sortByFullSourceHeader;
  }
  private set SortByFullSourceHeader(value: HeaderItem | null) {
    this.sortByFullSourceHeader = value;
  }
  private get FullSourceSortMode(): SortMode | null {
    return this.fullSourceSortMode;
  }
  private set FullSourceSortMode(value: SortMode | null) {
    this.fullSourceSortMode = value;
  }

  private get Sources(): Source[] {
    const sources = SourceModule.Sources.map(a => a);

    SourceModule.OccupySources([sources, this.componentId]);

    return sources;
  }

  private get SourcesByCategory() {
    this.InitializeSelectedGroup();

    if (this.selectedGroup == this.EverythingGroup) {
      return this.Sources;
    }

    return this.Sources.filter(a => a.GroupId != null && a.GroupId.equals(this.selectedGroup!.Id));
  }

  private SourcesWithSearch(): Source[] {
    let result = this.SourcesByCategory.map(a => a);

    if (this.sourceSearch != undefined && this.sourceSearch != '') {
      result = result.filter(a => a.CodeFileName.toLowerCase().includes(this.sourceSearch!.toLowerCase()));
    }

    return result;
  }

  private get FullSources(): FullSource[] {
    let fullSources: FullSource[] = [];

    for (let file of this.SourcesWithSearch()) {
      let group: SourceGroup | null = null;

      if (file.GroupId != null) {
        group = this.Groups.singleOrDefault(a => a.Id.equals(file.GroupId!));
      }

      const user = UserModule.Users.singleOrDefault(a => a.Id.equals(file.UserId));

      const fullSource: FullSource = {
        file: file,
        group: group,
        author: user,
      };

      fullSources.push(fullSource);
    }

    if (this.SourceId != undefined) {
      this.selectedSource = fullSources.firstOrDefault(a => a.file.Id.equals(this.SourceId!));
    }

    const maxCount = (this.fullSourcePage + 1) * PAGE_SIZE;

    const toDisplay = fullSources.splice(0, maxCount);
    const toLoose = fullSources.slice(maxCount);
    const toLooseFiltered = this.Sources.filter(
      a => fullSources.firstOrDefault(b => b.file.Id.toString() == a.Id.toString()) == null,
    );

    SourceModule.LooseSources([toLoose.map(a => a.file), this.componentId]);
    SourceModule.LooseSources([toLooseFiltered, this.componentId]);
    SourceModule.OccupySources([toDisplay.map(a => a.file), this.componentId]);

    if (toLoose.length > 0) {
      SourceModule.CollectSources();
    }

    return toDisplay;
  }

  //#region HEADERS & ITEMS

  private fullSourceHeaderName: HeaderItem = {
    caption: 'Name',
    itemClass: ComponentHelper.GetWidthClass('grow-2'),
    isSortable: true,
  };
  private fullSourceHeaderAuthor: HeaderItem = {
    caption: 'Author',
    itemClass: ComponentHelper.GetWidthClass(),
    isSortable: true,
  };
  private fullSourceHeaderLastPrint: HeaderItem = {
    caption: 'Last print',
    itemClass: ComponentHelper.GetWidthClass(),
    isSortable: true,
  };
  private fullSourceHeaderSize: HeaderItem = {
    caption: 'Size',
    itemClass: ComponentHelper.GetWidthClass(125),
    isSortable: true,
  };
  private fullSourceHeaderPrintCount: HeaderItem = {
    caption: 'Print count',
    itemClass: ComponentHelper.GetWidthClass(100),
    isSortable: true,
  };
  private get FullSourcesHeader(): HeaderItem[] {
    let result: HeaderItem[] = [];

    result.push(this.fullSourceHeaderName);
    result.push(this.fullSourceHeaderAuthor);
    result.push(this.fullSourceHeaderLastPrint);
    result.push(this.fullSourceHeaderSize);
    result.push(this.fullSourceHeaderPrintCount);

    return result;
  }

  private FullSourceItems(value: FullSource): ItemData[] {
    let result: ItemData[] = [];

    const itemName: ItemData = new ItemData('Name', value.file.CodeFileName, 'grow-2');
    const itemAuthor: ItemData = new ItemData(
      'Author',
      value.author == undefined ? '—' : ComponentHelper.GetFullname(value.author),
      'grow-1',
    );
    const itemLastUpdate: ItemData = new ItemData(
      'LastPrint',
      value.file.LastPrint == null ? '—' : ComponentHelper.GetReadableDateTime(value.file.LastPrint!, true),
      'grow-1',
    );
    const itemFiles: ItemData = new ItemData('Files', value.file.PrintCount.toString(), 100);
    const itemSize: ItemData = new ItemData('Size', ComponentHelper.GetReadbaleBytesSize(value.file.CodeFileSize), 125);

    result.push(itemName);
    result.push(itemAuthor);
    result.push(itemLastUpdate);
    result.push(itemSize);
    result.push(itemFiles);

    return result;
  }
  //#endregion

  //#endregion

  // Todo: for some reason watchers don't work here
  private CompanyId: Guid | undefined = undefined;
  private SourceId: Guid | undefined = undefined;

  private get FilesButtonDatas(): HeaderButtonData[] {
    const result: HeaderButtonData[] = [];

    result.push({
      iconType: 'fas',
      iconName: 'fa-plus',
      text: 'Add new file',
      name: 'add-new-file',
      testId: 'addNewFileButton',
    });

    return result;
  }
  //#endregion

  //#region LOGIC
  private async LoadGroups() {
    await SourceGroupModule.LooseSourceGroups([this.Groups, this.componentId]);
    await SourceGroupModule.CollectSourceGroups();

    this.isLoadingSourceGroups = true;
    await SourceGroupModule.LoadAllGroups();
    this.isLoadingSourceGroups = false;

    this.InitializeSelectedGroup();
  }

  private SourceGroupApplyClicked() {}

  private SourceGroupEditorDeleteClicked(result: boolean) {
    if (result) {
      this.selectedGroupToEdit = null;
    }
  }

  private async InitializeSelectedGroup() {
    if (this.selectedGroup == null) {
      this.selectedGroup = this.EverythingGroup;
    }
  }

  private GetFullSourceSortBy(): SourceSortBy | null {
    if (this.SortByFullSourceHeader == this.fullSourceHeaderName) {
      return SourceSortBy.BY_FILENAME;
    } else if (this.SortByFullSourceHeader == this.fullSourceHeaderAuthor) {
      return SourceSortBy.BY_AUTHOR;
    } else if (this.SortByFullSourceHeader == this.fullSourceHeaderLastPrint) {
      return SourceSortBy.BY_DATE;
    } else if (this.SortByFullSourceHeader == this.fullSourceHeaderSize) {
      return SourceSortBy.BY_CODE_SIZE;
    } else if (this.SortByFullSourceHeader == this.fullSourceHeaderPrintCount) {
      return SourceSortBy.BY_PRINT_COUNT;
    }

    return null;
  }

  private async LoadMoreSources() {
    // Todo: replace this with another implementation which takes search into count
    // SourceModule.ClearSources();
    let sources: Source[] = [];

    this.InitializeSelectedGroup();

    this.isLoadingSources = true;

    if (this.selectedGroup == this.EverythingGroup) {
      sources = await SourceModule.JustLoadSources([
        this.fullSourcePage,
        PAGE_SIZE,
        this.GetFullSourceSortBy(),
        this.FullSourceSortMode,
        this.sourceSearch,
      ]);
    } else {
      sources = await SourceModule.JustLoadSourcesByGroupId([
        this.selectedGroup!.Id,
        this.fullSourcePage,
        PAGE_SIZE,
        this.GetFullSourceSortBy(),
        this.FullSourceSortMode,
        this.sourceSearch,
      ]);
    }

    this.isLoadingSources = false;
    this.hasMoreSource = sources.length >= PAGE_SIZE;

    let sourceUserIds = sources.map(a => a.UserId);
    let authorsIds = GuidHelper.distinct(sourceUserIds);
    await UserModule.LoadUsers(authorsIds);
    for (let source of sources) {
      SourceModule.AddSourceToModule(source);
    }

    if (this.SourceId != undefined) {
      this.selectedSource = this.FullSources.firstOrDefault(a => a.file.Id.equals(this.SourceId!));
    }
  }

  async OnFullSourcesSortBy() {
    await SourceModule.LooseSources([this.Sources, this.componentId]);
    await SourceModule.CollectSources();
    this.fullSourcePage = 0;
    this.LoadMoreSources();
  }

  async OnSourcesScrolled(event: Event) {
    return loadMoreOnScroll({
      target: event.target as HTMLElement,
      isLoading: this.loadingFullSource,
      loadMore: this.LoadMoreSources,
      hasMore: this.hasMoreSource,
      setLoading: loading => (this.loadingFullSource = loading),
      nextPage: () => this.fullSourcePage++,
    });
  }

  private async UnsubscribeFromSourceGroups() {
    AsyncBatchQueueSignalR.Queue({
      Batch: async () => {
        await SourceModule.DeleteFromCompanySourcesGroup();
        await JobModule.DeleteFromCompanyJobsGroup();
      },
    });
    this.subscribedToGroups = false;
  }

  private async SubscribeToSourceGroups() {
    AsyncBatchQueueSignalR.Queue({
      Batch: async () => {
        await SourceModule.SubscribeToCompanySourcesGroup();
        await JobModule.SubscribeToCompanyJobsGroup();
      },
    });
    this.subscribedToGroups = true;
  }

  private async OnSourceSearchChanged(search: string) {
    if (search.length == 0) {
      this.sourceSearch = undefined;
    } else {
      this.sourceSearch = search;
    }

    this.debouncePerformSearch();
  }

  private async OpenFullSource(fullSource: FullSource) {
    if (this.SourceId?.equals(fullSource.file.Id)) {
      return;
    }

    this.$router.push({
      name: Routes.FILE_LIBRARY,
      query: { sourceId: fullSource.file.Id.toString() },
    });
  }

  private async OpenFullSourceGroup(fullSourceGroup: FullSourceGroup) {
    this.selectedGroup = fullSourceGroup.group;
  }

  @Emit('openUser')
  OnSourceOwnerClicked(owner: User) {
    return owner.Id;
  }

  @Emit('openArchive')
  OnJobArchiveClicked(arch: JobArchieve) {
    return arch.Id;
  }

  OnSourceDeleteClicked(source: FullSource) {
    this.RemoveFullSource(source);
  }

  private OnCategoryClicked(category: SourceGroup | null) {
    this.selectedGroup = category;
  }

  CloseSourceClicked() {
    this.$router.push({
      name: Routes.FILE_LIBRARY,
      query: { sourceId: undefined },
    });
  }

  private IsSourceSelected(item: FullSource) {
    return item.file.Id.toString() == this.selectedSource?.file.Id.toString();
  }

  private async RemoveFullSource(item: FullSource) {
    const userConfirmation = await DeleteSourceSure(
      Result.Yes | Result.No,
      'Delete file',
      'Deleting a file will also delete all queued jobs associated with it. Are you sure you want to delete this file?',
    );

    if (userConfirmation == Result.No || userConfirmation == Result.Cancel) {
      return;
    }

    this.isDeletingSource = true;

    const result = await SourceModule.DeleteSource(item.file);

    this.isDeletingSource = false;

    if (result[0] === RequestStatus.OK) {
      if (this.selectedSource?.file.Id.equals(item.file.Id)) {
        this.selectedSource = null;
      }
    } else {
      toast.error(result[1], {
        position: POSITION.BOTTOM_RIGHT,
      });
    }
  }

  private ShowAddNewFile() {
    if (this.AddingNewFile) return;

    this.AddingNewFile = true;
  }

  private HideAddNewFile() {
    if (!this.AddingNewFile) return;

    this.AddingNewFile = false;
  }

  private UploadSuccessful() {
    this.HideAddNewFile();
  }

  private CategoryAdded() {
    this.isAddingSourceGroup = false;
  }

  //#region VIEW STATE
  private async CategoriesCollapseToggled(isCollapsed: boolean) {
    GlobalDataModule.ChangeFileLibraryViewCategoriesCollapsed(isCollapsed);
  }

  private async FilesCollapseToggled(isCollapsed: boolean) {
    GlobalDataModule.ChangeFileLibraryViewFilesCollapsed(isCollapsed);
  }
  //#endregion

  //#endregion

  //#region HOOKS
  async beforeCreate() {
    this.componentId = Guid.create();
    this.everythingGroup = new SourceGroup();
    this.everythingGroup.Id = Guid.createEmpty();
    this.everythingGroup.FileCount = 0;
    this.everythingGroup.Name = '# All';
  }

  async created() {
    this.debouncePerformSearch = debounce(async () => {
      await SourceModule.LooseSources([this.Sources, this.componentId]);
      await SourceModule.CollectSources();
      this.fullSourcePage = 0;
      await this.LoadMoreSources();
    }, 500);

    await SourceModule.LooseSources([this.Sources, this.componentId]);
    await SourceModule.CollectSources();
  }

  async beforeDestroy() {
    await this.UnsubscribeFromSourceGroups();

    await SourceModule.LooseSources([this.Sources, this.componentId]);
    await SourceModule.CollectSources();

    await SourceGroupModule.LooseSourceGroups([this.Groups, this.componentId]);
    await SourceGroupModule.CollectSourceGroups();
  }
  //#endregion

  //#region TRANSLATIONS
  private get FilesCaption() {
    return en.files.growFirst();
  }
  private get CategoriesCaption() {
    return en.categories.growFirst();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.file-library-container {
  flex-grow: 1;
  height: 100%;
}

.file-library {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: auto;

  .left-panel {
    width: 55%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    min-width: 400px;
  }

  .right-panel {
    width: 45%;
    display: flex;
    flex-direction: column;
    min-width: 400px;
    overflow: auto;
  }

  .vertical-resizer {
    cursor: col-resize;
    width: 10px;
    margin: 0 3px;
  }

  .source-search-container {
    display: flex;
    justify-content: flex-end;

    .source-search {
      width: 300px;
    }
  }

  .categories {
    flex-shrink: 0;
    max-height: 35%;

    &.collapsed {
      min-height: 42px;
    }
  }

  .files {
    &.collapsed {
      min-height: 42px;
    }
  }

  .spinner-container {
    min-height: 40px;
    margin-top: 20px;
    position: relative;
    opacity: 1;

    &.hidden {
      opacity: 0;
      min-height: 0;
      margin-top: 0;
    }

    transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);
  }
}
</style>
