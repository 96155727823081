<template>
  <div class="pending-subscription-update px-3 py-3">
    <div class="summary w-100 mr-2">
      <v-popover trigger="hover" :disabled="ApplyDateString === null">
        <i class="far fa-clock" style="transform: translateY(2px)"></i>

        <template slot="popover"> Scheduled on {{ ApplyDateString }} </template>
      </v-popover>

      <div class="description ml-3 w-100">
        <span>{{ PendingUpdateChangeText }}</span>
        <button v-if="InvoiceReady" class="ml-2" @click="PayInvoiceClicked">Pay invoice</button>
        <!-- <span class="ml-2" v-if="InvoiceReady"><a @click="PayInvoiceClicked">Pay</a> invoice</span> -->
      </div>
    </div>
    <div class="controls">
      <button class="ml-2" @click="CancelPendingUpdate">Cancel</button>
    </div>
  </div>
</template>

<script lang="ts">
import { PendingSubscriptionUpdate } from '@/models/Entities';
import { ProductPriceModule } from '@/store/modules/billing/productPriceModule';
import { PurchasedItemFullGroup } from '@/store/modules/billing/purchasedItemModule';
import ComponentHelper from '@/util/ComponentHelper';
import dateformat from 'dateformat';
import { VPopover } from 'v-tooltip';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component({
  name: 'pending-subscription-update-info',
  components: {
    VPopover: VPopover,
  },
})
export default class PendingSubscriptionUpdateInfo extends Vue {
  @Prop() private PSU!: PendingSubscriptionUpdate;
  @Prop() private purchasedItemGroup!: PurchasedItemFullGroup;

  private get ApplyDateString(): string | null {
    if (this.PSU.applyDate == null) return null;

    return dateformat(this.PSU.applyDate!, 'mmm dS yyyy, HH:MM');
  }

  private get PendingUpdateChangeText(): string {
    let resultText = '';

    if (this.PSU.newQuantity !== null) {
      // Quantity change
      resultText = `Changing seat count from ${this.purchasedItemGroup.group.length} to ${this.PSU.newQuantity}`;
    } else if (this.PSU.newProductPriceId !== null) {
      // Plan/Level change
      resultText = `Switching plan ${ComponentHelper.GetReadableRecurringInterval(
        this.CurrentPrice.recurringInterval!,
      )} to ${ComponentHelper.GetReadableRecurringInterval(
        this.PendingPrice!.recurringInterval!,
      )} on ${this.ApplyDateString}`;
    }

    return resultText;
  }

  private get InvoiceReady(): boolean {
    return this.PSU.invoiceId !== null;
  }

  private get CurrentPrice() {
    return this.purchasedItemGroup.group[0].price;
  }

  private get PendingPrice() {
    return ProductPriceModule.ProductPrices.firstOrDefault(
      a => a.id.toString() === this.PSU.newProductPriceId!.toString(),
    );
  }

  private get PurcahsedItemFull() {
    return this.purchasedItemGroup.group[0];
  }

  private get Subscription() {
    return this.PurcahsedItemFull.subscription!;
  }

  @Emit('cancel-clicked')
  private CancelPendingUpdate() {}

  @Emit('pay-invoice-clicked')
  private PayInvoiceClicked() {
    return this.PSU.invoiceId;
  }
}
</script>

<style lang="scss" scoped>
.pending-subscription-update {
  border-radius: 5px;
  border: 1px solid var(--action-card-border);

  display: flex;
  justify-content: space-between;
  align-items: center;

  i {
    color: var(--main-orange);
    font-size: 18px;
  }

  .summary {
    display: flex;
    align-items: center;
    color: var(--editor-field-value);
    font-size: 13px;

    .description {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .controls {
    display: flex;
  }

  span a {
    text-decoration: underline !important;
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  }

  button {
    border: 1px solid var(--editor-field-value);
    border-radius: 6px;
    font-size: 13px;
    color: var(--editor-field-value);
    background: transparent;
    width: 150px;
    height: 26px;
  }
}
</style>
