import { Printer } from '@/models/Entities';

export const SortPrinterByPrinterNameDESC: (a: Printer, b: Printer) => 1 | -1 = (a: Printer, b: Printer) => {
  return a.Name! > b.Name! ? -1 : 1;
};
export const SortPrinterByPrinterNameASC: (a: Printer, b: Printer) => 1 | -1 = (a: Printer, b: Printer) => {
  return a.Name! > b.Name! ? 1 : -1;
};

export const SortPrinterByPrinterModelDESC: (a: Printer, b: Printer) => 1 | -1 = (a: Printer, b: Printer) => {
  return a.PrinterModelId > b.PrinterModelId ? -1 : 1;
};
export const SortPrinterByPrinterModelASC: (a: Printer, b: Printer) => 1 | -1 = (a: Printer, b: Printer) => {
  return a.PrinterModelId > b.PrinterModelId ? 1 : -1;
};

export const SortPrinterByStatusDESC: (a: Printer, b: Printer) => 1 | -1 = (a: Printer, b: Printer) => {
  return a.PrinterState > b.PrinterState ? -1 : 1;
};
export const SortPrinterByStatusASC: (a: Printer, b: Printer) => 1 | -1 = (a: Printer, b: Printer) => {
  return a.PrinterState > b.PrinterState ? 1 : -1;
};

export const SortPrinterByJobCountDESC: (a: Printer, b: Printer) => 1 | -1 = (a: Printer, b: Printer) => {
  return a.JobCount > b.JobCount ? -1 : 1;
};
export const SortPrinterByJobCountASC: (a: Printer, b: Printer) => 1 | -1 = (a: Printer, b: Printer) => {
  return a.JobCount > b.JobCount ? 1 : -1;
};

export const SortPrinterByQueuedJobCountDESC: (a: Printer, b: Printer) => 1 | -1 = (a: Printer, b: Printer) => {
  return a.QueuedJobCount > b.QueuedJobCount ? -1 : 1;
};
export const SortPrinterByQueuedJobCountASC: (a: Printer, b: Printer) => 1 | -1 = (a: Printer, b: Printer) => {
  return a.QueuedJobCount > b.QueuedJobCount ? 1 : -1;
};

export const SortPrinterByIdDESC: (a: Printer, b: Printer) => 1 | -1 = (a: Printer, b: Printer) => {
  return a.Id.toString() > b.Id.toString() ? -1 : 1;
};
export const SortPrinterByIdASC: (a: Printer, b: Printer) => 1 | -1 = (a: Printer, b: Printer) => {
  return a.Id.toString() > b.Id.toString() ? 1 : -1;
};
