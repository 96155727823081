<template>
  <div :class="['shop-cart', isLoading ? 'disabled' : '']">
    <div class="header mb-3 px-2">
      {{ CartCaption }}
    </div>

    <div class="cart-items mb-3">
      <div class="cart-items-header mb-2">
        <div class="name">#</div>

        <div class="count">
          {{ QuantityCaption }}
        </div>

        <div class="sum">
          {{ AmountCaption }}
        </div>

        <div class="delete"></div>
      </div>
      <div v-for="item in cartItems" :key="item.id.toString()">
        <cart-list-item
          :cartItem="item"
          @add-quantity="AddQuantity"
          @remove-quantity="RemoveQuantity"
          @delete="Delete"
        />
      </div>
    </div>

    <div v-if="cartItems.length > 0 && TaxRateAvailable" class="sub-total-header mb-1">
      <div class="name">
        <span>{{ SubtotalCaption }}</span>
      </div>

      <div class="count"></div>

      <div class="sum">
        <span>{{ Subtotal }}</span>
      </div>

      <div class="delete"></div>
    </div>

    <div v-if="cartItems.length > 0 && TaxRateAvailable" class="tax-header mb-2">
      <div class="name">
        <span>{{ TaxCaption }}</span>
      </div>

      <div class="count"></div>

      <div class="sum">
        <span>{{ Tax }}</span>
      </div>

      <div class="delete"></div>
    </div>

    <div v-if="cartItems.length > 0" class="total-header mb-4">
      <div class="name">
        <span>{{ TotalCaption }}</span>
      </div>

      <div class="count"></div>

      <div class="sum">
        <span>{{ Total }}</span>
      </div>

      <div class="delete"></div>
    </div>

    <div v-if="CreditBalanceSufficentToPay && cartItems.length > 0">
      <button
        :class="['w-100 mb-2', purchaseInProgress || cartItems.length == 0 ? 'disabled' : null]"
        @click="PurchaseItems"
      >
        {{ BuyWithBalanceCaption }}
      </button>
    </div>

    <div v-if="DefaultPaymentMethod != null && !AccountNotActivated" class="d-flex flex-column">
      <list-item-v-3
        class="mb-3"
        :items="PaymentMethodItems(DefaultPaymentMethod)"
        footerIconName="fa-pen"
        footerIconType="far"
        :footerIconFontSize="14"
        :headerIconName="cardIconName(DefaultPaymentMethod)"
        headerIconType="fab"
        :headerIconScale="2"
        @footer-icon-clicked="ChangeDefaultPaymentMethod"
      />
      <button :class="['mb-2', purchaseInProgress || cartItems.length == 0 ? 'disabled' : null]" @click="PurchaseItems">
        {{ BuyCaption }}
      </button>
    </div>

    <div v-else-if="AccountNotActivated" class="account-issue mb-2 px-3">
      <span class="mb-3">
        Your account is not activated, please fill in remaining billing details to purchase products
      </span>

      <button @click="EditAccount">Edit</button>
    </div>

    <div v-else-if="NoPaymentMethods && !CreditBalanceSufficentToPay" class="payment-method-issue mb-2 px-3">
      <span class="mb-3">
        {{ NoPaymentMethodsCaption }}
      </span>

      <button @click="AddPaymentMethod">
        {{ AddCaption }}
      </button>
    </div>

    <div v-else-if="!CreditBalanceSufficentToPay" class="payment-method-issue mb-2 px-3">
      <span class="mb-3">
        {{ NoDefaultPaymentMethodCaption }}
      </span>

      <button @click="ChangeDefaultPaymentMethod">
        {{ SelectCaption }}
      </button>
    </div>

    <div :class="['spinner', purchaseInProgress || isLoading ? '' : 'hidden']"></div>
  </div>
</template>

<script lang="ts">
import en from '@/localization/en';
import { AccountModule } from '@/store/modules/billing/accountModule';
import { CartItem, CartModule } from '@/store/modules/billing/cartModule';
import { PaymentMethodModule } from '@/store/modules/billing/paymentMethodModule';
import { PurchasedItemModule, PurchaseItemsData } from '@/store/modules/billing/purchasedItemModule';
import { PaymentMethod } from '@/models/Entities';
import { create } from 'vue-modal-dialogs';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import SelectDefaultPaymentMethodDialog from '@/views/dialogs/SelectDefaultPaymentMethodDialog.vue';
import ComponentHelper, { ItemData } from '@/util/ComponentHelper';
import ActionCard from '@/components/presentation/ActionCard.vue';
import CartListItem from '@/components/presentation/CartListItem.vue';
import ListItemV3 from '@/components/presentation/ListItemV3.vue';
import { PurchasedSubscriptionModule } from '@/store/modules/billing/purchasedSubscriptionModule';

import { POSITION } from 'vue-toastification';
import { toast } from '@/main';

const SelectDefaultPaymentMethod = create<PaymentMethod[] | null, PaymentMethod | null>(
  SelectDefaultPaymentMethodDialog,
  'paymentMethods',
);

@Component({
  name: 'shop-cart',
  components: {
    ActionCard: ActionCard,
    ListItemV3: ListItemV3,
    CartListItem: CartListItem,
  },
})
export default class ShopCart extends Vue {
  @Prop({ default: () => [] }) private cartItems!: CartItem[];

  private purchaseInProgress: boolean = false;
  private isLoading: boolean = false;

  private get NoPaymentMethods(): boolean {
    return PaymentMethodModule.PaymentMethods.length == 0;
  }

  private get CreditBalanceSufficentToPay(): boolean {
    return this.TotalAmount <= -AccountModule.Account!.stripeBalance;
  }

  private get DefaultPaymentMethod(): PaymentMethod | null {
    return PaymentMethodModule.PaymentMethods.firstOrDefault(a => a.isDefault);
  }

  private get AccountNotActivated(): boolean {
    return !AccountModule.Account?.activated;
  }

  private get TaxRateAvailable(): boolean {
    return AccountModule.AccountTaxRate !== null;
  }

  private get SubtotalAmount(): number {
    let total = 0;

    for (const item of this.cartItems) {
      total += item.price.price * item.quantity;
    }

    return total;
  }

  private get TaxAmount(): number {
    if (!this.TaxRateAvailable) return 0;

    return this.SubtotalAmount * (AccountModule.AccountTaxRate!.percentage / 100);
  }

  private get TotalAmount(): number {
    return this.SubtotalAmount + this.TaxAmount;
  }

  private get Currency(): string {
    if (this.cartItems.length == 0) {
      return '';
    }

    const cartItemsCurrency = this.cartItems[0].price.currency;
    return en.CurrencySymbols[cartItemsCurrency.toUpperCase()];
  }

  private get Subtotal(): string {
    let amount = this.SubtotalAmount;
    return this.Currency + (amount / 100).toFixed(2);
  }

  private get Tax(): string {
    let amount = this.TaxAmount;

    return this.Currency + (amount / 100).toFixed(2);
  }

  private get Total(): string {
    let amount = this.TotalAmount;

    return this.Currency + (amount / 100).toFixed(2);
  }

  private PaymentMethodItems(value: PaymentMethod): ItemData[] {
    let captionItem: ItemData = new ItemData('Caption', 'Payment method:', 'grow-1');
    let last4DigitsItem: ItemData = new ItemData(
      'Last4Digits',
      ComponentHelper.GetReadablePaymentMethodKind(value.methodType) + '*' + value.last4Digits,
      'grow-1',
    );
    let editItem: ItemData = new ItemData('Edit', '', 100);

    let items: ItemData[] = [];
    items.push(captionItem);
    items.push(last4DigitsItem);
    items.push(editItem);

    return items;
  }

  private RemoveQuantity(cartItem: CartItem) {
    CartModule.QuantityRemoved(cartItem.price);
  }

  private AddQuantity(cartItem: CartItem) {
    CartModule.QuantityAdded(cartItem.price);
  }

  private Delete(cartItem: CartItem) {
    CartModule.RemoveFromCart(cartItem);
  }

  private async ChangeDefaultPaymentMethod() {
    const result = await SelectDefaultPaymentMethod(PaymentMethodModule.PaymentMethods.filter(a => !a.isDefault));

    if (result === null) {
      return;
    }

    this.isLoading = true;

    await PaymentMethodModule.MakeDefault(result.id);

    this.isLoading = false;
  }

  @Emit('add-payment-method')
  private async AddPaymentMethod() {}

  @Emit('edit-account')
  private async EditAccount() {}

  private async PurchaseItems() {
    if (AccountModule.Account === null) {
      return;
    }

    let data: PurchaseItemsData = {
      accountId: AccountModule.Account.id,
      items: [],
    };

    for (const item of this.cartItems) {
      data.items.push({
        productPriceId: item.price.id,
        quantity: item.quantity,
      });
    }

    this.purchaseInProgress = true;

    if (CartModule.HasSubscription) {
      // Subscriptions
      const result = await PurchasedSubscriptionModule.Subscribe(data);

      if (result.length !== data.items.length) {
        toast.warning(en.someSubsCouldNotBePurchased, {
          position: POSITION.BOTTOM_RIGHT,
        });
      } else {
        toast.success(`${data.items.length} ${en.xSubsPurchasedSuccessfully}`, {
          position: POSITION.BOTTOM_RIGHT,
        });
      }
    } else {
      // One time items
      const result = await PurchasedItemModule.Purchase(data);

      if (result) {
        toast.success(en.purchaseSuccessful, {
          position: POSITION.BOTTOM_RIGHT,
        });
      } else {
        toast.error(en.purchaseFailed, {
          position: POSITION.BOTTOM_RIGHT,
        });
      }
    }

    this.purchaseInProgress = false;

    CartModule.ClearCart();
  }

  private cardIconName(pm: PaymentMethod): string {
    return ComponentHelper.cardIconName(pm);
  }

  private get NoPaymentMethodsCaption(): string {
    return en.noPaymentMethods;
  }

  private get AddCaption(): string {
    return en.add.titleCase();
  }

  private get SelectCaption(): string {
    return en.select;
  }

  private get NoDefaultPaymentMethodCaption(): string {
    return en.noDefaultPaymentMethod;
  }

  private get CartCaption() {
    return en.myCart;
  }

  private get BuyCaption() {
    if (CartModule.HasSubscription) {
      return en.subscribe;
    }
    return en.buy;
  }

  private get BuyWithBalanceCaption() {
    if (CartModule.HasSubscription) {
      return en.subscribe + ` (from balance)`;
    }
    return en.buy + ` (from balance)`;
  }

  private get SubtotalCaption() {
    return 'Subtotal';
  }

  private get TaxCaption() {
    if (!this.TaxRateAvailable) return '';

    const taxRate = AccountModule.AccountTaxRate!;

    return `${taxRate.name} (${taxRate.percentage}%)`;
  }

  private get TotalCaption() {
    return en.total.titleCase();
  }

  private get AmountCaption() {
    return 'Amount';
  }

  private get QuantityCaption() {
    return en.quantity;
  }
}
</script>

<style lang="scss" scoped>
.shop-cart {
  width: 100%;
  padding: 8px 12px;
  border: 3px solid var(--main-orange);
  border-radius: 6px;
  position: relative;
  text-align: center;

  .header {
    color: var(--action-card-header-text);
    text-align: left;
  }

  display: flex;
  flex-direction: column;

  .payment-method-issue,
  .account-issue {
    color: var(--main-text);
    display: flex;
    flex-direction: column;
    border: 1px solid var(--action-card-border);
    border-radius: 6px;
    padding: 12px;
  }

  .cart-items {
    display: flex;
    flex-direction: column;
  }

  .cart-items-header,
  .total-header,
  .tax-header,
  .sub-total-header {
    color: var(--list-item-text);
    margin: 0 14px;
    font-size: 13px;
    display: flex;
    flex-direction: row;

    .name {
      flex: 2;
      text-align: left;
    }

    .count {
      width: 115px;
    }

    .sum {
      flex: 1;
    }

    .delete {
      width: 12px;
    }
  }

  .tax-header {
    border-bottom: 1px solid #ffffff4f;
    padding-bottom: 0.5rem;
  }

  .total {
    display: flex;
    padding: 0 14px;
    color: var(--main-text);
    font-size: 14px;
    text-align: left;
  }

  button {
    border: none;
    background-color: var(--main-orange);
    color: white;
    border-radius: 6px;
    height: 32px;
    font-size: 16px;
    transition: opacity 150ms ease-out;

    &:hover {
      opacity: 0.85;
    }
  }
}
</style>
