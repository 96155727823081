//to

export const UPDATE_TOKEN = 'UpdateToken';
export const ADD_TO_COMPANY_USERS_GROUP = 'AddToCompanyUsersGroup';
export const DELETE_FROM_COMPANY_USERS_GROUP = 'DeleteFromCompanyUsersGroup';
export const ADD_TO_USER_GROUP = 'AddToCompanyUserGroup';
export const DELETE_FROM_USER_GROUP = 'DeleteFromCompanyUserGroup';
export const ADD_TO_COMPANY_JOB_SOURCES_GROUP = 'AddToCompanyJobSourcesGroup';
export const DELETE_FROM_COMPANY_JOB_SOURCES_GROUP = 'DeleteFromCompanyJobSourcesGroup';
export const ADD_TO_COMPANY_JOBS_GROUP = 'AddToCompanyJobsGroup';
export const DELETE_FROM_COMPANY_JOBS_GROUP = 'DeleteFromCompanyJobsGroup';
export const ADD_TO_COMPANY_PRINTERS_GROUP = 'AddToCompanyPrintersGroup';
export const DELETE_FROM_COMPANY_PRINTERS_GROUP = 'DeleteFromCompanyPrintersGroup';
export const ADD_TO_COMPANY_PRINTER_JOBS_GROUP = 'AddToCompanyPrinterJobsGroup';
export const DELETE_FROW_COMPANY_PRINTER_JOBS_GROUP = 'DeleteFromCompanyPrinterJobsGroup';
export const ADD_TO_MONOLITH_USER_GROUP = 'AddToUserGroup';
export const DELETE_FROM_MONOLITH_USER_GROUP = 'DeleteFromUserGroup';
export const ADD_TO_PRINTER_INFO_GROUP = 'AddToPrinterInfoGroup';
export const DELETE_FROM_PRINTER_INFO_GROUP = 'DeleteFromPrinterInfoGroup';
export const ADD_TO_PRINTER_ERROR_GROUP = 'AddToPrinterErrorGroup';
export const DELETE_FROM_PRINTER_ERROR_GROUP = 'DeleteFromPrinterErrorGroup';
export const ADD_TO_PRINTER_TELEMETRY_GROUP = 'AddToPrinterTelemetryGroup';
export const DELETE_FROM_PRINTER_TELEMETRY_GROUP = 'DeleteFromPrinterTelemetryGroup';
export const ADD_TO_AURA_ADMIN_USER_GROUP = 'AddToAuraAdminUserGroup';
export const DELETE_FROM_AURA_ADMIN_USER_GROUP = 'DeleteFromAuraAdminUserGroup';
export const ADD_TO_MONOLITH_COMPANY_STAT_GROUP = 'AddToCompanyStatGroup';
export const DELETE_FROM_MONOLITH_COMPANY_STAT_GROUP = 'DeleteFromCompanyStatGroup';

export const ADD_TO_COMPANY_GROUP = 'AddToCompanyGroup';
export const DELETE_FROM_COMPANY_GROUP = 'DeleteFromCompanyGroup';

// billing
export const ADD_TO_ACCOUNT_ADMIN_GROUP = 'AddToAccountAdminsGroup';
export const DELETE_FROM_ACCOUNT_ADMIN_GROUP = 'DeleteFromAccountAdminsGroup';

export const ADD_TO_ACCOUNT_GROUP = 'AddToAccountGroup';
export const DELETE_FROM_ACCOUNT_GROUP = 'DeleteFromAccountGroup';
export const ADD_TO_SPECIFIC_ACCOUNT_GROUP = 'AddToSpecificAccountGroup';
export const DELETE_FROM_SPECIFIC_ACCOUNT_GROUP = 'DeleteFromSpecificAccountGroup';

export const ADD_TO_INVOICE_GROUP = 'AddToInvoiceGroup';
export const DELETE_FROM_INVOICE_GROUP = 'DeleteFromInvoiceGroup';
export const ADD_TO_SPECIFIC_INVOICE_GROUP = 'AddToSpecificInvoiceGroup';
export const DELETE_FROM_SPECIFIC_INVOICE_GROUP = 'DeleteFromSpecificInvoiceGroup';

export const ADD_TO_PAYMENT_METHOD_GROUP = 'AddToPaymentMethodGroup';
export const DELETE_FROM_PAYMENT_METHOD_GROUP = 'DeleteFromPaymentMethodGroup';
export const ADD_TO_SPECIFIC_PAYMENT_METHOD_GROUP = 'AddToSpecificPaymentMethodGroup';
export const DELETE_FROM_SPECIFIC_PAYMENT_METHOD_GROUP = 'DeleteFromSpecificPaymentMethodGroup';

export const ADD_TO_PENDING_SUBSCRIPTION_GROUP = 'AddToPendingSubscriptionGroup';
export const DELETE_FROM_PENDING_SUBSCRIPTION_GROUP = 'DeleteFromPendingSubscriptionGroup';
export const ADD_TO_SPECIFIC_PENDING_SUBSCRIPTION_GROUP = 'AddToSpecificPendingSubscriptionGroup';
export const DELETE_FROM_SPECIFIC_PENDING_SUBSCRIPTION_GROUP = 'DeleteFromSpecificPendingSubscriptionGroup';

export const ADD_TO_PRODUCT_PRICE_GROUP = 'AddToProductPriceGroup';
export const DELETE_FROM_PRODUCT_PRICE_GROUP = 'DeleteFromProductPriceGroup';

export const ADD_TO_PRODUCT_GROUP = 'AddToProductGroup';
export const DELETE_FROM_PRODUCT_GROUP = 'DeleteFromProductGroup';

export const ADD_TO_PURCHASED_ITEM_GROUP = 'AddToPurchasedItemGroup';
export const DELETE_FROM_PURCHASED_ITEM_GROUP = 'DeleteFromPurchasedItemGroup';
export const ADD_TO_SPECIFIC_PURCHASED_ITEM_GROUP = 'AddToSpecificPurchasedItemGroup';
export const DELETE_FROM_SPECIFIC_PURCHASED_ITEM_GROUP = 'DeleteFromSpecificPurchasedItemGroup';

export const ADD_TO_PURCHASED_SUBSCRIPTION_GROUP = 'AddToPurchasedSubscriptionGroup';
export const DELETE_FROM_PURCHASED_SUBSCRIPTION_GROUP = 'DeleteFromPurchasedSubscriptionGroup';
export const ADD_TO_SPECIFIC_PURCHASED_SUBSCRIPTION_GROUP = 'AddToSpecificPurchasedSubscriptionGroup';
export const DELETE_FROM_SPECIFIC_PURCHASED_SUBSCRIPTION_GROUP = 'DeleteFromSpecificPurchasedSubscriptionGroup';

//from

//identity
//user
export const RECEIVE_DELETE_LOGIN_SESSION = 'ReceiveDeleteLoginSession';
export const RECEIVE_NEW_SIGNIN = 'ReceiveNewSignIn';
export const RECEIVE_UPDATE_LOGIN_SESSION = 'ReceiveUpdateLoginSession';
export const RECEIVE_ADD_USER_ROLE = 'ReceiveAddUserRole';
export const RECEIVE_DELETE_USER_ROLE = 'ReceiveDeleteUserRole';
export const RECEIVE_ADD_USER = 'ReceiveAddUser';
export const RECEIVE_DELETE_USER = 'ReceiveDeleteUser';
export const RECEIVE_UPDATE_USER_EMAIL = 'ReceiveUpdateUserEmail';
export const RECEIVE_UPDATE_INFO_USER = 'ReceiveUpdateInfoUser';
export const RECEIVE_DISCONNECT_USER = 'ReceiveDisconnectUser';

//company
export const RECEIVE_UPDATE_COMPANY = 'ReceiveUpdateCompany';
export const RECEIVE_DELETE_COMPANY = 'ReceiveDeleteCompany';
export const RECEIVE_CREATE_COMPANY = 'ReceiveCreateCompany';

//monolith
//printer
export const RECEIVE_CREATE_PRINTER = 'ReceiveCreatePrinter';
export const RECEIVE_UPDATE_PRINTER = 'ReceiveUpdatePrinter';
export const RECEIVE_UPDATE_PRINTER_STATUS = 'ReceiveUpdatePrinterStatus';
export const RECEIVE_DELETE_PRINTER = 'ReceiveDeletePrinter';
export const RECEIVE_UPDATE_PRINTER_ERROR_COUNT = 'ReceiveUpdatePrinterErrorCount';
export const RECEIVE_UPDATE_PRINTER_LAST_ERROR = 'ReceiveUpdatePrinterLastError';
export const RECEIVE_UPDATE_PRINTER_LAST_ARCHIVE = 'ReceiveUpdatePrinterLastArchive';
export const RECEIVE_UPDATE_PRINTER_CURRENT_JOB = 'ReceiveUpdatePrinterCurrentJob';
//print job source
export const RECEIVE_CREATE_SOURCE = 'ReceiveCreateSource';
export const RECEIVE_UPDATE_SOURCE = 'ReceiveUpdateSource';
export const RECEIVE_DELETE_SOURCE = 'ReceiveDeleteSource';
//print job source group
export const RECEIVE_CREATE_SOURCE_GROUP = 'ReceiveCreateSourceGroup';
export const RECEIVE_DELETE_SOURCE_GROUP = 'ReceiveDeleteSourceGroup';
export const RECEIVE_UPDATE_SOURCE_GROUP = 'ReceiveUpdateSourceGroup';
//print job
export const RECEIVE_CREATE_JOB = 'ReceiveCreateJob';
export const RECEIVE_DELETE_JOB = 'ReceiveDeleteJob';
export const RECEIVE_UPDATE_JOB = 'ReceiveUpdateJob';
export const RECEIVE_UPDATE_JOB_STATUS = 'ReceiveUpdateJobStatus';
export const RECEIVE_UPDATE_JOB_PROGRESS = 'ReceiveUpdateJobProgress';
export const RECEIVE_UPDATE_JOB_START_PROGRESS = 'ReceiveUpdateJobStartFileTransferProgress';
export const RECEIVE_UPDATE_JOB_START_TIME = 'ReceiveUpdateJobStartTime';
export const RECEIVE_UPDATE_JOB_INVOKED = 'ReceiveUpdateJobInvoked';
export const RECEIVE_UPDATE_JOB_ERROR_COUNT = 'ReceiveUpdateJobErrorCount';
export const RECEIVE_UPDATE_JOB_LAST_ERROR = 'ReceiveUpdateJobLastError';
//job-archieve
export const RECEIVE_CREATE_JOB_ARCHIEVE = 'ReceiveCreateJobArchieve';
//user-stat
export const RECEIVE_UPDATE_USER_STAT = 'ReceiveUpdateUserStat';
export const RECEIVE_DELETE_USER_STAT = 'ReceiveDeleteUserStat';
//company-stat
export const RECEIVE_UPDATE_COMPANY_STAT = 'ReceiveUpdateCompanyStat';
export const RECEIVE_DELETE_COMPANY_STAT = 'ReceiveDeleteCompanyStat';
//printer-stat
export const RECEIVE_UPDATE_PRINTER_STAT = 'ReceiveUpdatePrinterStat';
export const RECEIVE_DELETE_PRINTER_STAT = 'ReceiveDeletePrinterStat';

// meterial consumption company stat
export const RECEIVE_MATERIALCONSUMPTIONCOMPANYSTAT_CREATE = 'ReceiveMaterialConsumptionCompanyStatCreate';
export const RECEIVE_MATERIALCONSUMPTIONCOMPANYSTAT_UPDATE = 'ReceiveMaterialConsumptionCompanyStatUpdate';
export const RECEIVE_MATERIALCONSUMPTIONCOMPANYSTAT_DELETE = 'ReceiveMaterialConsumptionCompanyStatDelete';

// meterial consumption pritner stat
export const RECEIVE_MATERIALCONSUMPTIONPRINTERSTAT_CREATE = 'ReceiveMaterialConsumptionPrinterStatCreate';
export const RECEIVE_MATERIALCONSUMPTIONPRINTERSTAT_UPDATE = 'ReceiveMaterialConsumptionPrinterStatUpdate';
export const RECEIVE_MATERIALCONSUMPTIONPRINTERSTAT_DELETE = 'ReceiveMaterialConsumptionPrinterStatDelete';

// total print time by weekday company stat
export const RECEIVE_TOTALPRINTTIMEBYWEEKDAYCOMPANYSTAT_CREATE = 'ReceiveTotalPrintTimeByWeekDayCompanyStatCreate';
export const RECEIVE_TOTALPRINTTIMEBYWEEKDAYCOMPANYSTAT_UPDATE = 'ReceiveTotalPrintTimeByWeekDayCompanyStatUpdate';
export const RECEIVE_TOTALPRINTTIMEBYWEEKDAYCOMPANYSTAT_DELETE = 'ReceiveTotalPrintTimeByWeekDayCompanyStatDelete';

//connector
export const RECEIVE_RAW = 'ReceiveRaw';
export const RECEIVE_ERROR = 'ReceiveError';
export const RECEIVE_ACKNOWLEDGE_ERRORS = 'ReceiveAcknowledgeErrors';
export const RECEIVE_TELEMETRY = 'ReceiveTelemetry';

//global notifications
export const RECEIVE_CREATE_GLOBAL_NOTIFICATION = 'ReceiveCreateGlobalNotification';
export const RECEIVE_UPDATE_GLOBAL_NOTIFICATION = 'ReceiveUpdateGlobalNotification';
export const RECEIVE_DELETE_GLOBAL_NOTIFICATION = 'ReceiveDeleteGlobalNotification';
export const RECEIVE_BECAME_RELEVANT_GLOBAL_NOTIFICATION = 'ReceiveBecameRelevantGlobalNotification';

//billing
//account admin
export const RECEIVE_CREATE_ACCOUNT_ADMIN = 'ReceiveCreateAccountAdmin';
export const RECEIVE_DELETE_ACCOUNT_ADMIN = 'ReceiveDeleteAccountAdmin';
export const RECEIVE_UPDATE_ACCOUNT_ADMIN = 'ReceiveUpdateAccountAdmin';
//account
export const RECEIVE_CREATE_ACCOUNT = 'ReceiveCreateAccount';
export const RECEIVE_DELETE_ACCOUNT = 'ReceiveSoftDeleteAccount';
export const RECEIVE_UPDATE_ACCOUNT = 'ReceiveUpdateAccount';
//invoice
export const RECEIVE_CREATE_INVOICE = 'ReceiveCreateInvoice';
export const RECEIVE_UPDATE_INVOICE = 'ReceiveUpdateInvoice';
export const RECEIVE_INVOICE_PAYMENT_ACTION_REQUIRED = 'ReceiveInvoicePaymentActionRequired';
export const RECEIVE_INVOICE_PAYMENT_FAILED = 'ReceiveInvoicePaymentFailed';
//payment method
export const RECEIVE_CREATE_PAYMENT_METHOD = 'ReceiveCreatePaymentMethod';
export const RECEIVE_DELETE_PAYMENT_METHOD = 'ReceiveDeletePaymentMethod';
export const RECEIVE_UPDATE_PAYMENT_METHOD = 'ReceiveUpdatePaymentMethod';
//pending subscription
export const RECEIVE_CREATE_PENDING_SUBSCRIPTION = 'ReceiveCreatePendingSubscription';
export const RECEIVE_DELETE_PENDING_SUBSCRIPTION = 'ReceiveDeletePendingSubscription';
export const RECEIVE_UPDATE_PENDING_SUBSCRIPTION = 'ReceiveUpdatePendingSubscription';
//product price
export const RECEIVE_CREATE_PRODUCT_PRICE = 'ReceiveCreateProductPrice';
export const RECEIVE_UPDATE_PRODUCT_PRICE = 'ReceiveUpdateProductPrice';
//product
export const RECEIVE_CREATE_PRODUCT = 'ReceiveCreateProduct';
export const RECEIVE_UPDATE_PRODUCT = 'ReceiveUpdateProduct';
//purchased item
export const RECEIVE_CREATE_PURCHASED_ITEM = 'ReceiveCreatePurchasedItem';
export const RECEIVE_UPDATE_PURCHASED_ITEM = 'ReceiveUpdatePurchasedItem';
export const RECEIVE_ATTACHED_PURCHASED_ITEM = 'ReceiveAttachPurchasedItem';
export const RECEIVE_DETACHED_PURCHASED_ITEM = 'ReceiveDetachPurchasedItem';
export const RECEIVE_SOFT_DELETE_PURCHASED_ITEM = 'ReceiveSoftDeletePurchasedItem';
//purchased subscription
export const RECEIVE_CREATE_PURCHASED_SUBSCRIPTION = 'ReceiveCreatePurchasedSubscription';
export const RECEIVE_CANCEL_PURCHASED_SUBSCRIPTION = 'ReceiveCancelPurchasedSubscription';
export const RECEIVE_UPDATE_PURCHASED_SUBSCRIPTION = 'ReceiveUpdatePurchasedSubscription';
