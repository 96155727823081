<template>
  <div class="main-view-container">
    <component :is="InnerComponent" v-bind="ComponentProperties" />

    <div class="theme-switcher" @click="ChangeTheme">
      <span>{{ CurrentTheme }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Login from '../views/Login.vue';
import WorkField from '../views/WorkField.vue';
import { LoginModule } from '../store/modules/loginModule';
import { GlobalDataModule, Theme } from '@/store/modules/globalDataModule';
import { Routes } from '@/router/routes';
import { StoreHelper } from '@/store/util/storeHelpers';

enum ChildComponentType {
  LOGIN,
  MAIN,
}
type ChildComponent = Record<ChildComponentType, string>;

@Component({
  components: {
    login: Login,
    workField: WorkField,
  },
})
export default class MainView extends Vue {
  @Prop() pageParam!: any;

  hasCheckedRedirectToAura = false;

  //#region WATCHERS
  @Watch('$route', { immediate: true, deep: true })
  private async OnRouteChanged() {
    const currSearch = window.location.search;

    // Clear sensitive data after Stripe redirect
    if (
      currSearch.includes('setup_intent') &&
      currSearch.includes('setup_intent_client_secret') &&
      currSearch.includes('redirect_status')
    ) {
      var action = this.$route.query.action;

      if (action !== null) {
        this.$router.replace({
          name: Routes.COMPANY,
          query: { tab: 'billing' },
        });
      }
    }
  }

  @Watch('IsAuthorized', { immediate: true })
  private async OnIsAuthorizedChanged(newValue: boolean, oldValue?: boolean) {
    await this.redirectToAuraAppIfNeeded();

    if (oldValue !== undefined && oldValue === false && newValue === true) {
      // Default page for Connect User is Dashboard and for other account types it's Profile
      if (LoginModule.IsConnectUser) {
        this.$router.replace({ path: Routes.DASHBOARD });
      } else {
        this.$router.replace({ path: Routes.MY_ACCOUNT });
      }
    }
  }

  private async redirectToAuraAppIfNeeded() {
    if (!this.IsAuthorized) {
      return;
    }

    const { returnPath } = this.$router.currentRoute.query ?? {};
    if (typeof returnPath === 'string') {
      const queryParams = await LoginModule.getAuthQueryParams();
      return StoreHelper.redirectToAuraApp(returnPath + queryParams);
    }

    this.hasCheckedRedirectToAura = true;
  }

  private innerComponents: ChildComponent = {
    [ChildComponentType.LOGIN]: 'login',
    [ChildComponentType.MAIN]: 'work-field',
  };

  private get ComponentProperties() {
    if (this.InnerComponent === this.innerComponents[ChildComponentType.LOGIN]) {
      return {};
    }
    if (this.InnerComponent === this.innerComponents[ChildComponentType.MAIN]) {
      return {
        pageParam: this.pageParam,
      };
    }
    return {};
  }

  private get InnerComponent(): string {
    if (!this.IsAuthorized || !this.hasCheckedRedirectToAura) {
      return this.innerComponents[ChildComponentType.LOGIN];
    }

    return this.innerComponents[ChildComponentType.MAIN];
  }

  private get IsAuthorized() {
    return LoginModule.IsAuthorized;
  }

  private get CurrentTheme(): string {
    if (GlobalDataModule.ThemeSetting === Theme.Dark) {
      return 'Dark';
    }

    return 'Regular';
  }
  //#endregion

  //#region LOGIC
  private ChangeTheme() {
    if (GlobalDataModule.ThemeSetting === Theme.Dark) {
      GlobalDataModule.SetThemeSetting(Theme.Regular);
    } else {
      GlobalDataModule.SetThemeSetting(Theme.Dark);
    }
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.main-view-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.theme-switcher {
  display: none; // Switcher is hidden for now
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1.5rem 2rem;
  color: white;
  user-select: none;
  cursor: pointer;
}

.dark-theme {
  .theme-switcher {
    color: black;
  }
}
</style>
