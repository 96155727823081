<template>
  <div class="company-view-container px-3 py-3">
    <div class="company-view">
      <div class="header">
        <h1 :class="[ShowShop ? 'shop-title' : '']">{{ HeaderTitle }}</h1>

        <div class="tabs" data-testid="company.tabs">
          <div
            v-for="tab in availableTabs"
            :key="tab"
            :class="['tab', IsSelectedTab(tab)]"
            :data-testid="`company.tab.${tab}`"
            @click="IsSelectedTab(tab) ? undefined : SwitchToTab(tab)"
          >
            <span>{{ ReadableTabString(tab) }}</span>
          </div>
        </div>
      </div>
      <div class="selected-tab-container thin-scroll">
        <KeepAlive>
          <Shop
            v-if="ShowShop"
            @group-selected="PurcahsaedItemsGroupSelected"
            @add-payment-method="AddPaymentMethod"
            @edit-account="EditAccount"
          />
        </KeepAlive>
        <KeepAlive>
          <CompanyBilling v-if="ShowBilling" :Company="Company" @openUser="OpenUser" />
        </KeepAlive>
        <KeepAlive>
          <CompanyInfo v-if="ShowInfo" :Company="Company" @openUser="OpenUser" />
        </KeepAlive>
        <KeepAlive>
          <CompanyProducts
            v-if="ShowProducts"
            :selectedGroupProp.sync="selectedGroup"
            @connect-clicked="currentTab = 'shop'"
            @selectedGroupChanged="OnSelectedProductsGroupChanged"
            @pay-pending-subscription-invoice="OnPayPendingSubscriptionInvoice"
          />
        </KeepAlive>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import { Guid } from 'guid-typescript';

import { Company, Invoice } from '@/models/Entities';

import { CompanyModule } from '@/store/modules/companyModule';
import { UserModule } from '@/store/modules/userModule';

import CompanyInfo from './tabs/CompanyInfo.vue';
import CompanyProducts from './tabs/CompanyProducts.vue';
import CompanyBilling from './tabs/CompanyBilling.vue';
import { AccountModule } from '@/store/modules/billing/accountModule';
import { ProductPriceModule } from '@/store/modules/billing/productPriceModule';
import { PurchasedItemFullGroup, PurchasedItemModule } from '@/store/modules/billing/purchasedItemModule';
import { PurchasedSubscriptionModule } from '@/store/modules/billing/purchasedSubscriptionModule';
import { PendingSubscriptionUpdateModule } from '@/store/modules/billing/pendingSubscriptionUpdateModule';
import { PaymentMethodModule } from '@/store/modules/billing/paymentMethodModule';
import { InvoiceModule } from '@/store/modules/billing/invoiceModule';
import { GlobalDataModule } from '@/store/modules/globalDataModule';
import { LoginModule } from '@/store/modules/loginModule';
import Shop from './tabs/Shop.vue';
import ComponentHelper from '@/util/ComponentHelper';
import en from '@/localization/en';
import { POSITION } from 'vue-toastification';
import { toast } from '@/main';
import { CompanyRouteTab, Routes } from '@/router/routes';
import { AsyncBatchQueueSignalR } from '@/store/util/Globals';

@Component({
  components: {
    CompanyInfo: CompanyInfo,
    CompanyProducts: CompanyProducts,
    CompanyBilling: CompanyBilling,
    Shop: Shop,
  },
})
export default class CompanyView extends Vue {
  @Watch('$route')
  private async OnRouteChanged() {
    await this.ChangeTabToQueryParam();
  }

  @Watch('IsBillingAccountAdmin', { immediate: true })
  private async OnBillingAccountAdminChanged(newValue: boolean | undefined, oldValue: boolean | undefined) {
    this.FillAvailableTabs();

    if (!this.IsBillingAccountAdmin) {
      if (GlobalDataModule.CompanyViewState.selectedTab != CompanyRouteTab.INFO) {
        this.SwitchToTab(CompanyRouteTab.INFO);
      }

      if (oldValue != null) {
        await this.UnsubscribeFromBillingGroups();
      }
    } else {
      GlobalDataModule.SetIsLoadingBillingData(true);

      if (oldValue != null) {
        await LoginModule.LoadBillingData();
      }

      await this.InitializeBillingData();

      GlobalDataModule.SetIsLoadingBillingData(false);
    }
  }

  @Prop() private companyId!: Guid;
  private componentId: Guid = Guid.create();
  private selectedGroup: PurchasedItemFullGroup | null = null;

  private availableTabs: CompanyRouteTab[] = [];

  //#region STATE

  private get currentTab(): CompanyRouteTab {
    return GlobalDataModule.CompanyViewState.selectedTab;
  }

  private get CompanyTitle(): string {
    return this.Company == null ? '' : this.Company.Name;
  }

  private get HeaderTitle(): string {
    if (this.currentTab === CompanyRouteTab.STORE) {
      return en.weHaveProductsForEveryone;
    }

    return this.CompanyTitle;
  }

  private get IsBillingAccountAdmin(): boolean {
    return LoginModule.IsBillingAccountAdmin;
  }
  //#endregion

  //#region LOGIC

  private async ChangeTabToQueryParam() {
    var tabFromRoute = this.$route.query.tab as CompanyRouteTab;

    if (
      tabFromRoute !== undefined &&
      tabFromRoute !== this.currentTab &&
      this.availableTabs.firstOrDefault(a => a === tabFromRoute) !== null
    ) {
      await this.SwitchToTab(tabFromRoute);
    } else if (tabFromRoute === undefined) {
      await this.SwitchToTab(this.currentTab);
    }
  }

  private async SwitchToTab(tab: CompanyRouteTab, params: Record<string, string> | undefined = undefined) {
    await GlobalDataModule.ChangeCompanyViewTab(tab);

    if (this.$route.query.tab == null) {
      this.$router
        .replace({
          name: Routes.COMPANY,
          query: { tab: tab, ...params },
        })
        .catch(() => {});
    } else {
      this.$router
        .push({
          name: Routes.COMPANY,
          query: { tab: tab, ...params },
        })
        .catch(() => {});
    }
  }

  private ReadableTabString(tab: CompanyRouteTab) {
    return ComponentHelper.GetReadableCompanyViewTab(tab);
  }

  private IsSelectedTab(tab: CompanyRouteTab): string {
    return this.currentTab === tab ? 'selected' : '';
  }

  private get ShowShop(): boolean {
    return this.currentTab === 'store';
  }
  private get ShowProducts(): boolean {
    return this.currentTab === 'products';
  }
  private get ShowInfo(): boolean {
    return this.currentTab === 'info';
  }
  private get ShowBilling(): boolean {
    return this.currentTab === 'billing';
  }

  private async InitializeBillingData() {
    if (AccountModule.Account === null) {
      console.error('No billing account loaded when initializing billing data');
      return;
    }

    await this.SubscribeToBillingGroups();

    // Possibly put this in watch to later view other company data as an aura admin
    await PaymentMethodModule.LoadPaymentMethods(AccountModule.Account.id);
    await PurchasedItemModule.LoadPurchasedItems(AccountModule.Account.id);
    await PurchasedSubscriptionModule.LoadPurchasedSubscriptions(AccountModule.Account.id);
    await PendingSubscriptionUpdateModule.LoadPendingSubscriptionUpdates(AccountModule.Account.id);
  }

  private async SubscribeToBillingGroups() {
    AsyncBatchQueueSignalR.Queue({
      Batch: async () => {
        await AccountModule.AddToAccountGroup();
        await InvoiceModule.AddToInvoiceGroup();
        await PaymentMethodModule.AddToPaymentMethodGroup();
        await ProductPriceModule.AddToProductPriceGroup();
        await PurchasedItemModule.AddToPurchasedItemGroup();
        await PurchasedSubscriptionModule.AddToPurchasedSubscriptionGroup();
        await PendingSubscriptionUpdateModule.AddToPendingSubscriptionUpdateGroup();
      },
    });
  }

  private async UnsubscribeFromBillingGroups() {
    AsyncBatchQueueSignalR.Queue({
      Batch: async () => {
        await AccountModule.DeleteFromAccountGroup();
        await InvoiceModule.DeleteFromInvoiceGroup();
        await PaymentMethodModule.DeleteFromPaymentMethodGroup();
        await ProductPriceModule.DeleteFromProductPriceGroup();
        await PurchasedItemModule.DeleteFromPurchasedItemGroup();
        await PurchasedSubscriptionModule.DeleteFromPurchasedSubscriptionGroup();
        await PendingSubscriptionUpdateModule.DeleteFromPendingSubscriptionUpdateGroup();
      },
    });
  }

  //#region SHOP -> PRODUCTS navigation basically
  private async PurcahsaedItemsGroupSelected(group: PurchasedItemFullGroup) {
    // It's not an addon
    if (group.group.firstOrDefault(a => a.product.baseProductId == null) != null) {
      return;
    }
    this.selectedGroup = group;
    this.SwitchToTab(CompanyRouteTab.PRODUCTS);
  }

  private async AddPaymentMethod() {
    this.SwitchToTab(CompanyRouteTab.BILLING, {
      action: 'add-payment-method',
    });
  }

  private async EditAccount() {
    this.SwitchToTab(CompanyRouteTab.BILLING, {
      action: 'edit-account',
    });
  }

  private OnSelectedProductsGroupChanged(group: PurchasedItemFullGroup) {
    this.selectedGroup = group;
  }

  private FillAvailableTabs() {
    this.availableTabs = [];

    if (LoginModule.IsBillingAccountAdmin) {
      this.availableTabs.push(CompanyRouteTab.STORE);
      this.availableTabs.push(CompanyRouteTab.PRODUCTS);
      this.availableTabs.push(CompanyRouteTab.INFO);
      this.availableTabs.push(CompanyRouteTab.BILLING);
    } else {
      this.availableTabs.push(CompanyRouteTab.INFO);
    }
  }

  private HandleInvoicePaymentActionRequired(invoice: Invoice) {
    toast.info(en.invoiceNeedsPaymentConfirmation.replace('[0]', '#' + invoice.invoiceNumber), {
      position: POSITION.BOTTOM_RIGHT,
      hideProgressBar: true,
      timeout: 30 * 1000,
      onClick: () => this.HandleConfirmInvoicePayment(invoice.id),
    });
  }

  private HandleConfirmInvoicePayment(id: Guid) {
    this.SwitchToTab(CompanyRouteTab.BILLING, {
      action: 'confirm-invoice-payment',
      invoiceId: id.toString(),
    });
  }

  private OnPayPendingSubscriptionInvoice(id: Guid) {
    this.HandleConfirmInvoicePayment(id);
  }
  //#endregion

  //#endregion

  //#region COMPANY

  private company: Company | null = null;
  private get Company(): Company | null {
    if (!this.company) return null;
    CompanyModule.CompaniesSync([[this.company], this.componentId]);
    return this.company;
  }

  @Watch('companyId', { immediate: true, deep: false })
  private async onCompanyChanged(newValue: Guid, oldValue: Guid) {
    if (oldValue) {
      await CompanyModule.LooseCompanies([[oldValue], this.componentId]);
    }
    if (newValue && oldValue !== null) {
      this.company = await CompanyModule.GetCompany(newValue);
    }
  }

  //#endregion

  //#region HOOKS

  async created() {
    await this.ChangeTabToQueryParam();
    InvoiceModule.OnInvoicePaymentActionRequired.subscribe(this.HandleInvoicePaymentActionRequired);
  }

  async beforeDestroy() {
    if (!this.companyId) return;

    await UserModule.DeleteFromCompanyUsersGroup();
    await CompanyModule.LooseCompanies([[this.companyId], this.componentId]);

    if (this.IsBillingAccountAdmin) {
      await this.UnsubscribeFromBillingGroups();
    }

    InvoiceModule.OnInvoicePaymentActionRequired.unsubscribe(this.HandleInvoicePaymentActionRequired);
  }

  //#endregion

  //#region OPEN USER

  @Emit('openUser')
  private OpenUser() {}

  //#endregion

  //#endregion
}
</script>

<style scoped lang="scss">
.company-view-container {
  flex-grow: 1;
  height: 100%;
  overflow: auto;
}

.company-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .header {
    color: #e1e1e1;
    height: fit-content;

    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 1rem;

    h1 {
      font-size: 20px;
      padding: 0;
      margin: 0;
      margin-left: 0.65rem;

      &.shop-title {
        font-size: 24px;
        letter-spacing: 2px;
      }
    }

    .tabs {
      display: flex;
      flex-direction: row;
      user-select: none;
      height: fit-content;

      .tab {
        font-size: 16px;
        margin: 0 4px;
        padding: 3px 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 6px;
        outline: 1px solid;
        outline-color: transparent;
        outline-offset: -1px;

        &.selected {
          background-color: var(--main-orange);
        }

        &:hover:not(.selected) {
          outline-color: var(--main-orange);
        }

        span {
          padding: 0;
          margin: 0;
        }

        transition:
          background-color 150ms ease-in,
          outline-color 150ms ease-in;
      }
    }
  }

  .selected-tab-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: auto;
    display: flex;
  }
}
</style>
