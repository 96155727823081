import { TimeSpan } from './TimeSpan';

export class TypeHelper {
  static StringToDate(value: string): Date {
    return Number.isNaN(Date.parse(value)) ? new Date(0) : new Date(value);
  }
  static RandomInRange(min: number, max: number): number {
    return Math.random() * (max - min) + min;
  }
  static isToday(someDate: Date): boolean {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  }
  static SliceIntoChunks<T>(arr: T[], chunkSize: number) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }
  static GetTimeStatus(value: Date): TimeStatus {
    const now = new Date();
    const fiveMinutes = TimeSpan.fromMinutes(5).totalMilliseconds;
    const valueTime = value.getTime();
    const nowTime = now.getTime();
    if (valueTime > nowTime - fiveMinutes) return TimeStatus.NOW;
    if (this.isToday(value)) return TimeStatus.TODAY;
    const week = TimeSpan.fromDays(7).totalMilliseconds;
    if (valueTime > nowTime - week) return TimeStatus.WEEK;
    return TimeStatus.LONG_AGO;
  }
  static MinutesToTicks(minutes: number): number {
    return minutes * 600000000;
  }
  static SecondsToTicks(seconds: number): number {
    return seconds * 10000000;
  }
  static DateToTicks(date: Date): number {
    return date.getTime() * 10000 + 621355968000000000;
  }
  static DeepCopy<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj)) as T;
  }
  // Эта штука создает из сырого объекта инстанс класса
  // Например, когда мы сделали JSON.Parse() и хотим исопльзовать на объекте ф-ции какого-класса
  // Второй аргумент - к-тор какого-то типа
  // Работает только на один уровень, к сожалению. Объекты классов внутри останутся без ф-ций :(
  // TODO: найти способ внутренние тоже сделать нормальными инстансами классов
  static DeepCopyFrom<T>(from: T, type: new () => T): T {
    const item = new type();
    for (const key in from) {
      item[key] = from[key];
    }
    return item;
  }
}

export enum TimeStatus {
  NOW,
  TODAY,
  WEEK,
  LONG_AGO,
}

export const nameof = <T>(name: keyof T) => name;

declare global {
  export interface Array<T> {
    exceptByKey(clip: T[], key: keyof T): T[];
    except(clip: T[]): T[];
    firstOrDefault(filterBy: (value: T, index: number, array: T[]) => unknown): T | null;
    singleOrDefault(condition: (item: T) => boolean): T | null;
    single(condition: (item: T) => boolean): T;
    delete(item: T): void;
    distinct(key?: keyof T): T[];
    groupBy<T2>(keyGetter: (item: T) => T2): Map<T2, T[]>;
    sortByDesc(key: keyof T): void;
    max(): number;
    min(): number;
    empty(): boolean;
    any(): boolean;
    innerJoin<TInner, TKey, TResult>(
      inner: Array<TInner>,
      outerKeySelector: (item: T) => TKey,
      innerKeySelector: (item: TInner) => TKey,
      resultSelector: (item1: T, item2: TInner) => TResult,
    ): TResult[];
  }
  export interface String {
    growFirst(): string;
    titleCase(onlyFirst?: boolean): string;
    empty(): boolean;
    any(): boolean;
  }
  export interface Date {
    differenceInDays(other: Date): number;
  }
  interface CanvasRenderingContext2D {
    roundRect(x: number, y: number, width: number, h: number, r: number): CanvasRenderingContext2D;

    fitText(str: string, maxWidth: number): string;
  }
}
Array.prototype.exceptByKey = function <T>(clip: T[], key: keyof T): T[] {
  if (this === undefined || this.length === 0 || clip === undefined || clip.length === 0) return this;
  return this.filter(sourceItem => clip.filter(clipItem => (<any>clipItem[key]).equals(sourceItem[key])).length === 0);
};
Array.prototype.except = function <T>(clip: T[]): T[] {
  if (this === undefined || this.length === 0 || clip === undefined || clip.length === 0) return this;
  return this.filter(x => !clip.includes(x));
};
Array.prototype.firstOrDefault = function <T>(filterBy: (value: T, index: number, array: T[]) => unknown): T | null {
  if (this.length === 0) return null;
  const values = this.filter(filterBy);
  if (values.length === 0) return null;
  return values[0];
};
Array.prototype.singleOrDefault = function <T>(condition: (item: T) => boolean): T | null {
  if (this.length === 0) return null;
  for (const i of this) {
    if (condition(i)) return i;
  }
  return null;
};
Array.prototype.single = function <T>(condition: (item: T) => boolean): T {
  if (this.length === 0) throw new Error('List has no elements');
  for (const i of this) {
    if (condition(i)) return i;
  }
  throw new Error('No element was found');
};
Array.prototype.delete = function <T>(item: T): void {
  if (this.length === 0) return;
  const indexOf = this.indexOf(item);
  if (indexOf === -1) return;
  this.splice(indexOf, 1);
};
Array.prototype.distinct = function <T>(key?: keyof T): T[] {
  if (key === undefined)
    return this.filter((thing, i, arr) => {
      return arr.indexOf(arr.find(t => t === thing)) === i;
    });
  return this.filter((thing, i, arr) => {
    return arr.indexOf(arr.find(t => t[key] === thing[key])) === i;
  });
};
Array.prototype.groupBy = function <T>(keyGetter: (item: T) => any): Map<any, T[]> {
  const map = new Map();
  this.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

Array.prototype.max = function (): number {
  const values = this;
  return Math.max(...values);
};

Array.prototype.min = function (): number {
  const values = this;
  return Math.min(...values);
};

Array.prototype.sortByDesc = function <T>(key: keyof T): void {
  let temp: T;
  for (let i = 0; i < this.length - 1; i++) {
    for (let j = i + 1; j < this.length; j++) {
      if (this[i][key] < this[j][key]) {
        temp = this[i];
        this[i] = this[j];
        this[j] = temp;
      }
    }
  }
};
Array.prototype.empty = function (): boolean {
  return this.length === 0;
};
Array.prototype.any = function (): boolean {
  return this.length > 0;
};
Array.prototype.innerJoin = function <TOuter, TInner, TKey, TResult>(
  inner: Array<TInner>,
  outerKeySelector: (item: TOuter) => TKey,
  innerKeySelector: (item: TInner) => TKey,
  resultSelector: (item1: TOuter, item2: TInner) => TResult,
): TResult[] {
  const mapped: TResult[] = [];
  for (let i = 0; i < this.length; i++) {
    const innerElements = inner.filter(a => outerKeySelector(this[i]) === innerKeySelector(a));
    for (const innerElement of innerElements) {
      mapped.push(resultSelector(this[i], innerElement));
    }
  }
  return mapped;
};
String.prototype.growFirst = function (): string {
  return `${this[0].toUpperCase()}${this.substr(1)}`;
};
String.prototype.titleCase = function (onlyFirst: boolean): string {
  if (onlyFirst) return this.toLowerCase().charAt(0).toUpperCase() + this.toLowerCase().slice(1);

  return this.toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
String.prototype.empty = function (): boolean {
  return this.length === 0;
};
String.prototype.any = function (): boolean {
  return this.length > 0;
};
const _MS_PER_DAY = 1000 * 60 * 60 * 24;
Date.prototype.differenceInDays = function (other: Date): number {
  const utc1 = Date.UTC(
    this.getFullYear(),
    this.getMonth(),
    this.getDate(),
    this.getHours(),
    this.getMinutes(),
    this.getSeconds(),
    this.getMilliseconds(),
  );

  const utc2 = Date.UTC(
    other.getFullYear(),
    other.getMonth(),
    other.getDate(),
    other.getHours(),
    other.getMinutes(),
    other.getSeconds(),
    other.getMilliseconds(),
  );

  const result = (utc2 - utc1) / _MS_PER_DAY;
  // console.log(result);
  return result;
};

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

CanvasRenderingContext2D.prototype.roundRect = function (
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number,
): CanvasRenderingContext2D {
  if (width < 0) width = 0;
  if (height < 0) height = 0;

  if (width < 2 * radius) radius = width / 2;
  if (height < 2 * radius) radius = height / 2;
  this.beginPath();
  this.moveTo(x + radius, y);
  this.arcTo(x + width, y, x + width, y + height, radius);
  this.arcTo(x + width, y + height, x, y + height, radius);
  this.arcTo(x, y + height, x, y, radius);
  this.arcTo(x, y, x + width, y, radius);
  this.closePath();
  return this;
};

CanvasRenderingContext2D.prototype.fitText = function (str: string, maxWidth: number) {
  let width = this.measureText(str).width;
  const ellipsis = '…';
  const ellipsisWidth = this.measureText(ellipsis).width;
  if (width <= maxWidth || width <= ellipsisWidth) {
    return str;
  } else {
    let len = str.length;
    while (width >= maxWidth - ellipsisWidth && len-- > 0) {
      str = str.substring(0, len);
      width = this.measureText(str).width;
    }
    if (str == '') return '';
    return str + ellipsis;
  }
};

export type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${'' extends P ? '' : '.'}${P}`
    : never
  : never;

export type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]];

export type Paths<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
    ? { [K in keyof T]-?: K extends string | number ? `${K}` | Join<K, Paths<T[K], Prev[D]>> : never }[keyof T]
    : '';

export type Leaves<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
    ? { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T]
    : '';
