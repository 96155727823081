<template>
  <div class="add-new-file">
    <editor-header>
      <template slot="header">
        <span>
          {{ AddNewFileCaption }}
        </span>
      </template>
      <template slot="buttons">
        <header-icon-button test-id="addNewFile.closeButton" iconName="fa-times" @clicked="closeButtonClicked" />
      </template>
    </editor-header>

    <editor-content>
      <file-input
        class="mb-2"
        :label="GCodeCaption"
        :invalid="gCodeFileValidationFailed"
        :disabled="isLoading"
        :labelWidth="labelWidth"
        :readonly="false"
        :downloadable="false"
        extension=".gcode"
        :file-name.sync="gCodeName"
        :enableFileDrag="true"
        test-id="addNewFile.gcode"
        @file-changed="OnGCodeFileChanged"
        @reset-file="ResetGCodeFile"
      />

      <file-input
        class="mb-2"
        :label="AuprojCaption"
        :invalid="projectFileValidationFailed"
        :disabled="isLoading"
        :labelWidth="labelWidth"
        :readonly="false"
        :downloadable="false"
        extension=".auprojx"
        :file-name.sync="auprojName"
        :enableFileDrag="true"
        test-id="addNewFile.auprojx"
        @file-changed="OnAuprojxFileChanged"
        @reset-file="ResetAuprojxFile"
      />

      <select-input
        test-id="addNewFile.categoriesSelect"
        :label="CategoryCaption"
        :options.sync="groups"
        :disabled="isLoading"
        :labelWidth="labelWidth"
        :readonly="false"
        :selected-option.sync="selectedGroup"
        :GetId="a => a.Id.toString()"
        :GetName="a => a.Name"
      >
      </select-input>

      <fade-transition :maxHeight="28">
        <div v-if="validationErrors.length != 0" class="errors-container">
          <span class="mt-2">{{ validationErrors[0] }}</span>
        </div>
      </fade-transition>

      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </editor-content>

    <editor-footer>
      <template slot="buttons">
        <IconButton
          class="mr-3"
          :disabled="isLoading || !loadedGCode"
          iconName="fa-arrow-up"
          :text="UploadCaption"
          test-id="addNewFile.uploadButton"
          @clicked="UploadButtonClicked"
        />
        <IconButton
          :disabled="isLoading"
          iconName="fa-times"
          :iconSize="15"
          :text="CancelCaption"
          test-id="addNewFile.cancelButton"
          @clicked="closeButtonClicked"
        />
      </template>
    </editor-footer>
  </div>
</template>

<script lang="ts">
import en from '@/localization/en';
import { toast } from '@/main';
import { SourceGroup } from '@/models/Entities';
import { RequestStatus } from '@/models/enums/RequestStatus';
import {
  FILE_SIZE_LIMIT_IN_MB,
  GCODE_FORM_KEY,
  GROUP_ID_FORM_KEY,
  PROJECT_FORM_KEY,
  SourceModule,
} from '@/store/modules/sourceModule';
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import { POSITION } from 'vue-toastification';
import FadeTransition from '../presentation/FadeTransition.vue';
import EditorContent from '@/components/forms/base/EditorContent.vue';
import EditorFooter from '@/components/forms/base/EditorFooter.vue';
import EditorHeader from '@/components/forms/base/EditorHeader.vue';
import IconButton from '../buttons/IconButton.vue';
import FileInput from '@/components/inputs/FileInput.vue';
import SelectInput from '@/components/inputs/SelectInput.vue';
import HeaderIconButton from '@/components/buttons/HeaderIconButton.vue';

@Component({
  name: 'add-new-file',
  components: {
    HeaderIconButton,
    FadeTransition: FadeTransition,
    IconButton: IconButton,
    EditorHeader: EditorHeader,
    EditorContent: EditorContent,
    EditorFooter: EditorFooter,
    FileInput: FileInput,
    SelectInput: SelectInput,
  },
})
export default class AddNewFile extends Vue {
  labelWidth: number = 100;

  @Prop() groups!: SourceGroup[];
  @Prop() initialGroup?: SourceGroup;

  //#region STATE
  private formData: FormData = new FormData();
  private isLoading: boolean = false;

  private get loadedGCode() {
    return this.gCodeName != '';
  }

  private gCodeName: string = '';
  private auprojName: string = '';

  private selectedGroup: SourceGroup | null = null;
  @Watch('selectedGroup')
  private OnSelectedGroupChanged(newValue: SourceGroup | null) {
    if (newValue == null) {
      this.formData.delete(GROUP_ID_FORM_KEY);
      return;
    }

    this.formData.set(GROUP_ID_FORM_KEY, newValue!.Id.toString());
  }

  private validationTimeoutHandle: number = -1;
  private gCodeFileValidationFailed: boolean = false;
  private projectFileValidationFailed: boolean = false;
  private validationErrors: string[] = [];
  //#endregion

  //#region LOGIC
  private OnGCodeFileChanged(files: File[]) {
    const file = files[0];

    this.gCodeName = file.name;

    this.ChangeFormFile(file, GCODE_FORM_KEY);
  }

  private OnAuprojxFileChanged(files: File[]) {
    const file = files[0];

    this.auprojName = file.name;

    this.ChangeFormFile(file, PROJECT_FORM_KEY);
  }

  private ResetAuprojxFile() {
    let entry = this.formData.get(PROJECT_FORM_KEY);
    if (entry != null) {
      this.formData.delete(PROJECT_FORM_KEY);
      this.auprojName = '';
    }
  }

  private ResetGCodeFile() {
    let entry = this.formData.get(GCODE_FORM_KEY);
    if (entry != null) {
      this.formData.delete(GCODE_FORM_KEY);
      this.gCodeName = '';
    }
  }

  private ChangeFormFile(file: File, key: string) {
    let entry = this.formData.get(key);

    if (entry != null) this.formData.delete(key);

    this.formData.append(key, file, file.name);
  }

  private UploadGCodeFile() {
    (this.$refs.inputGCodeFile as HTMLElement).click();
  }

  private UploadAuprojxFile() {
    (this.$refs.inputAuprojxFile as HTMLElement).click();
  }

  async UploadButtonClicked() {
    this.isLoading = true;

    if (!(await this.validate())) {
      this.isLoading = false;
      return;
    }

    let [result, message] = await SourceModule.AddNewSource(this.formData);

    this.isLoading = false;

    if (result !== RequestStatus.OK) {
      toast.error('Could not upload files. ' + message, {
        position: POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.success('Upload successful', {
        position: POSITION.BOTTOM_RIGHT,
      });

      this.uploadSuccessful();
    }
  }

  private clearValidationErrors() {
    if (this.validationTimeoutHandle !== -1) {
      clearTimeout(this.validationTimeoutHandle);
    }

    this.validationErrors = [];
    this.gCodeFileValidationFailed = false;
    this.projectFileValidationFailed = false;
    this.validationTimeoutHandle = -1;
  }

  private async validate() {
    this.clearValidationErrors();

    // Validate
    let limitInBytes = FILE_SIZE_LIMIT_IN_MB * 1024 * 1024;

    let gCodeSize = 0;
    let projectSize = 0;

    if (this.formData.get(GCODE_FORM_KEY) != null) {
      gCodeSize = (this.formData.get(GCODE_FORM_KEY) as File).size;
    }

    if (this.formData.get(PROJECT_FORM_KEY) != null) {
      projectSize = (this.formData.get(PROJECT_FORM_KEY) as File).size;
    }

    let limit = await SourceModule.RequestSourceSizeLimit();
    let total = await SourceModule.RequestSourceTotalSize();

    if (total + gCodeSize + projectSize > limit) {
      this.validationErrors.push(en.fileLimitExceeds.growFirst());
      this.gCodeFileValidationFailed = true;
      this.projectFileValidationFailed = true;
    } else if (gCodeSize > limitInBytes) {
      this.validationErrors.push('GCode file exceeds 50 MB');
      this.gCodeFileValidationFailed = true;
    } else if (projectSize > limitInBytes) {
      this.validationErrors.push('Aura project file exceeds 50MB');
      this.projectFileValidationFailed = true;
    }

    const failed = this.validationErrors.length != 0;

    if (failed) {
      this.validationTimeoutHandle = window.setTimeout(() => {
        this.clearValidationErrors();
      }, 3500);
    }

    return !failed;
  }
  //#endregion

  //#region HOOKS
  async created() {
    if (this.initialGroup !== undefined && this.groups.firstOrDefault(a => a == this.initialGroup) != null) {
      this.selectedGroup = this.initialGroup;
    } else {
      this.selectedGroup = this.groups[this.groups.length - 1];
    }
  }
  //#endregion

  @Emit('close-button-clicked')
  closeButtonClicked() {}

  @Emit('upload-successful')
  uploadSuccessful() {}

  //#region TRANSLATIONS
  private get CancelCaption() {
    return en.cancel.growFirst();
  }

  private get UploadCaption() {
    return en.upload.growFirst();
  }
  private get GCodeCaption(): string {
    return en.gcode;
  }

  private get AuprojCaption(): string {
    return en.auproj;
  }

  private get CategoryCaption(): string {
    return en.category.growFirst();
  }

  private get AddNewFileCaption(): string {
    return en.addNewFile.growFirst();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.add-new-file {
  background: var(--editor-background);
  border-radius: 6px;
  .errors-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      color: var(--editor-error-text);
      font-size: 14px;
    }
  }
}
</style>
