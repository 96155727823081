import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import './assets/css/colors.css';
import './assets/css/aura-globals.css';
import { GlobalNotificationsModule } from './store/modules/globalNotificationsModule';
import { GlobalDataModule } from './store/modules/globalDataModule';
import * as ModalDialogs from 'vue-modal-dialogs';
import { StripeModule } from './store/modules/billing/stripeModule';
import Toast, { createToastInterface, POSITION } from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';
import VueTooltip from 'v-tooltip';
import './assets/css/v-tooltip.css';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(ModalDialogs);
Vue.use(VueTooltip);
Vue.use(Toast);

configureVueTooltip();

new Vue({
  router,
  store,
  async beforeCreate() {
    await GlobalDataModule.Initialize();
    await GlobalNotificationsModule.ReadGlobalNotifications();

    // No need to await this. UI will show that it's loading
    StripeModule.Initialize();
  },
  async mounted() {},
  render: h => h(App),
}).$mount('#app');

export const toast = createToastInterface({
  position: POSITION.BOTTOM_RIGHT,
});

function configureVueTooltip() {
  VueTooltip.options!.defaultTemplate =
    '<div class="tooltip-vue" role="tooltip"><div class="tooltip-vue-arrow"></div><div class="tooltip-vue-inner"></div></div>';
  VueTooltip.options!.popover!.defaultBaseClass = 'tooltip-vue popover-vue';
  VueTooltip.options!.popover!.defaultInnerClass = 'tooltip-vue-inner popover-vue-inner';
  VueTooltip.options!.popover!.defaultArrowClass = 'tooltip-vue-arrow popover-vue-arrow';
  VueTooltip.options!.defaultArrowSelector = '.tooltip-vue-arrow, .tooltip-vue__arrow';
  VueTooltip.options!.defaultInnerSelector = '.tooltip-vue-inner, .tooltip-vue__inner';
  VueTooltip.options!.defaultBoundariesElement = document.body;
}
