<template>
  <div ref="flowLayoutContainer" class="flow-layout">
    <div v-if="isEditing" class="grid-underlay">
      <div class="cells-contianer">
        <div v-for="cell of CellsArray" :key="CellsArray.indexOf(cell)" class="cell" :style="GetCellStyle(cell)"></div>
      </div>
    </div>

    <div ref="childContainer" class="child-container" @dragover.prevent="DragOver">
      <slot />
    </div>

    <div class="resize-preview-container">
      <div class="resize-preview-ghost-container">
        <div ref="previewGhost" class="resize-preview-ghost" :style="PreviewGhostStyle"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { MaxPoint, MinPoint, Point, Rectangle, RectangleContainsPoint } from '@/models/util/Geometry';
import { IEventManagement } from 'strongly-typed-events';
import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import FlowLayoutChild, {
  FlowLayoutChildDragEvent,
  FlowLayoutChildDragStartedEvent,
  FlowLayoutChildDragStoppedEvent,
  FlowLayoutChildResizeEvent,
  FlowLayoutChildResizeStartedEvent,
  FlowLayoutChildResizeStoppedEvent,
} from './FlowLayoutChild.vue';
import { CellRange } from '@/models/util/FlowLayout';

// Position refers to position in layout cell matrix
interface Cell {
  position: Point;
  rectangle: Rectangle;
  takenBy: FlowLayoutChild[];
}

export interface FlowLayoutChildCellRangeChangedEvent {
  child: FlowLayoutChild;
  oldCellRange: CellRange;
  newCellRange: CellRange;
}

export interface FlowLayoutChildPositionPreview {
  child: FlowLayoutChild;
  originalCellRange: CellRange;
  previewCellRange: CellRange;
}

@Component({
  components: {},
  name: 'flow-layout',
})
export default class FlowLayout extends Vue {
  @Prop({ default: 0.1 }) cellWidthFraction!: number;
  @Prop({ default: 0.1 }) cellHeightFraction!: number;

  @Prop({ default: false }) isEditing!: boolean;

  @Ref('flowLayoutContainer')
  flowLayoutContainer!: HTMLDivElement;

  @Ref('childContainer')
  childContainer!: HTMLDivElement;

  //#region WATCHERS
  @Watch('isEditing', { immediate: true })
  OnIsEditingChanged(newValue: boolean) {
    if (newValue) {
      this.EnableEditing();
    } else {
      this.DisabledEditing();
    }
  }
  //#endregion

  //#region STATE
  private IsBrowserFirefox = false;

  private cellGap: number = 10;

  private actualCellWidthSize: number = 100;
  private actualCellHeightSize: number = 100;

  private cells: Cell[][] = [];

  public layoutChildren: FlowLayoutChild[] = [];

  private resizeObserver!: ResizeObserver;
  private mutationObserver!: MutationObserver;

  private LayoutWidth() {
    return this.$el.clientWidth;
  }

  private LayoutHeight() {
    return this.$el.clientHeight;
  }

  private get CellWidth() {
    return this.cells[0][0].rectangle.width;
  }

  private get CellHeight() {
    return this.cells[0][0].rectangle.height;
  }

  private get CellsArray() {
    const cells: Cell[] = [];

    for (const cellRow of this.cells) {
      for (const cell of cellRow) {
        cells.push(cell);
      }
    }

    return cells;
  }

  //#region RESIZING & DRAGGING
  @Ref('previewGhost')
  private previewGhost!: HTMLDivElement;

  private previewGhostRect: Rectangle = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  };
  private previewGhostCellRange: CellRange | null = null;
  private previewGhostCellRangeValid: boolean = true;

  private get PreviewGhostStyle() {
    return {
      transition: 'outline 0.3s cubic-bezier(0.2, 0, 0, 1)',
      left: this.previewGhostRect.x + 'px',
      top: this.previewGhostRect.y + 'px',
      width: this.previewGhostRect.width + 'px',
      height: this.previewGhostRect.height + 'px',
      outline: this.previewGhostCellRangeValid ? 'none' : 'solid 1px #bf0820',
    };
  }

  private isResizing: boolean = false;
  private resizingChild: FlowLayoutChild | null = null;

  private isDragging: boolean = false;
  private draggingChild: FlowLayoutChild | null = null;

  private childrenPositionPreview: FlowLayoutChildPositionPreview[] = [];

  //#region DRAGGING PREVIEW
  //#endregion

  //#endregion

  //#region LOGIC
  DragOver(e: DragEvent) {
    if (this.IsBrowserFirefox) {
      this.draggingChild?.$el.dispatchEvent(new MouseEvent('drag', e));
    }
  }

  CalculateCells() {
    this.cells = [];

    const width = this.LayoutWidth();
    const height = this.LayoutHeight();

    let cellWidth = this.cellWidthFraction * width;
    let cellHeight = this.cellHeightFraction * height;

    const cellColumnCount = Math.ceil(width / cellWidth);
    const cellRowCount = Math.ceil(height / cellHeight);

    const gappedWidth = width - this.cellGap * (cellColumnCount + 1);
    const gappedHeigh = height - this.cellGap * (cellRowCount + 1);

    cellWidth = this.cellWidthFraction * gappedWidth;
    cellHeight = this.cellHeightFraction * gappedHeigh;

    for (let y = 0; y < cellRowCount; ++y) {
      const cellRow: Cell[] = [];

      for (let x = 0; x < cellColumnCount; ++x) {
        cellRow.push({
          position: {
            x: x,
            y: y,
          },
          rectangle: {
            x: this.cellGap + x * cellWidth + x * this.cellGap,
            y: this.cellGap + y * cellHeight + y * this.cellGap,
            width: cellWidth,
            height: cellHeight,
          },
          takenBy: [],
        });
      }

      this.cells.push(cellRow);
    }
  }

  public DrawFlowLayoutChildren() {
    // Before we draw we need to clear cells taken by array
    for (let y = 0; y < this.cells.length; ++y) {
      for (let x = 0; x < this.cells[y].length; ++x) {
        this.cells[y][x].takenBy = [];
      }
    }

    for (const child of this.layoutChildren) {
      const cellRange = child.cellRange;

      const rect = this.GetCellRangeRectangle(cellRange);

      for (let y = cellRange.startPosition.y; y <= cellRange.endPosition.y; ++y) {
        for (let x = cellRange.startPosition.x; x <= cellRange.endPosition.x; ++x) {
          this.cells[y][x].takenBy.push(child);
        }
      }

      child.SetPosition(rect.x, rect.y);
      child.SetSize(rect.width, rect.height);
    }
  }

  InitializeFlowLayoutChildren() {
    let currCellX = 0;
    let currCellY = 0;

    const initialCellColumnCount = 1;
    const initialCellRowCount = 1;

    for (let i = 0; i < this.layoutChildren.length; ++i) {
      const cellRange = CellRange.FromPointCoordinates(
        currCellX,
        currCellY,
        currCellX + initialCellColumnCount,
        currCellY + initialCellRowCount,
      );

      this.layoutChildren[i].cellRange = cellRange;

      currCellX += initialCellColumnCount + 1;

      if (currCellX + initialCellColumnCount > this.cells[0].length) {
        currCellX = 0;
        currCellY += initialCellRowCount + 1;
      }
    }
  }

  GetCellStyle(cell: Cell) {
    return {
      left: cell.rectangle.x + 'px',
      top: cell.rectangle.y + 'px',
      width: cell.rectangle.width + 'px',
      height: cell.rectangle.height + 'px',
    };
  }

  GetCellAt(point: Point) {
    for (let y = 0; y < this.cells.length; ++y) {
      const row = this.cells[y];

      for (let x = 0; x < row.length; ++x) {
        const cell = row[x];

        const rectGapped: Rectangle = {
          x: cell.rectangle.x - this.cellGap,
          y: cell.rectangle.y - this.cellGap,
          width: cell.rectangle.width + this.cellGap,
          height: cell.rectangle.height + this.cellGap,
        };

        if (RectangleContainsPoint(rectGapped, point)) {
          return cell;
        }
      }
    }

    return null;
  }

  GetchildRectangle(child: FlowLayoutChild): Rectangle {
    const cellRange = child.cellRange;
    return this.GetCellRangeRectangle(cellRange);
  }

  GetCellRangeRectangle(cellRange: CellRange): Rectangle {
    const minPoint = MinPoint([cellRange.startPosition, cellRange.endPosition]);
    const maxPoint = MaxPoint([cellRange.startPosition, cellRange.endPosition]);

    const startRect = this.cells[minPoint.y][minPoint.x].rectangle;

    const dx = maxPoint.x - minPoint.x;
    const dy = maxPoint.y - minPoint.y;

    const rectX = startRect.x;
    const rectY = startRect.y;

    let totalWidth = startRect.width * (dx + 1) + this.cellGap * (dx + 1 - 1);
    let totalHeight = startRect.height * (dy + 1) + this.cellGap * (dy - 1 + 1);

    return {
      x: rectX,
      y: rectY,
      width: totalWidth,
      height: totalHeight,
    };
  }

  GetRectangleCellRange(rectangle: Rectangle): CellRange | null {
    const rectTopLeft: Point = {
      x: rectangle.x,
      y: rectangle.y,
    };

    const rectBottomRight: Point = {
      x: rectangle.x + rectangle.width,
      y: rectangle.y + rectangle.height,
    };

    let minCell = this.GetCellAt(rectTopLeft);
    let maxCell = this.GetCellAt(rectBottomRight);

    if (minCell == null || maxCell == null) {
      return null;
    }

    return CellRange.FromPoints(minCell.position, maxCell.position);
  }

  FitRectangleInCells(rectangle: Rectangle, rectangleCellRange: CellRange): CellRange | null {
    const cellWidth = rectangleCellRange.Width;
    const cellHeight = rectangleCellRange.Height;

    const cellRow = this.cells[0];
    const maxCellStartX = cellRow.length - cellWidth - 1;
    const maxCellStartY = this.cells.length - cellHeight - 1;

    const rectTopLeft: Point = {
      x: rectangle.x,
      y: rectangle.y,
    };

    const startCell = this.GetCellAt(rectTopLeft);

    if (startCell == null) {
      return null;
    }

    const startCellPosition: Point = {
      x: startCell.position.x,
      y: startCell.position.y,
    };

    const endCellPosition: Point = {
      x: startCell.position.x + cellWidth,
      y: startCell.position.y + cellHeight,
    };

    const startCellCenter: Point = {
      x: startCell.rectangle.x + startCell.rectangle.width / 2,
      y: startCell.rectangle.y + startCell.rectangle.height / 2,
    };

    if (rectTopLeft.x > startCellCenter.x) {
      ++startCellPosition.x;
      ++endCellPosition.x;
    }

    if (rectTopLeft.y > startCellCenter.y) {
      ++startCellPosition.y;
      ++endCellPosition.y;
    }

    if (startCellPosition.x > maxCellStartX) {
      const delta = startCellPosition.x - maxCellStartX;

      startCellPosition.x -= delta;
      endCellPosition.x -= delta;
    }

    if (startCellPosition.y > maxCellStartY) {
      const delta = startCellPosition.y - maxCellStartY;

      startCellPosition.y -= delta;
      endCellPosition.y -= delta;
    }

    const result = CellRange.FromPoints(startCellCenter, endCellPosition);

    result.startPosition = startCellPosition;
    result.endPosition = endCellPosition;

    return result;
  }

  FlowLayoutContainerResized() {
    this.CalculateCells();
    this.DrawFlowLayoutChildren();
  }

  //#region CHILD RESIZING
  HandleFlowLayoutChildResizeStarted(args: FlowLayoutChildResizeStartedEvent) {
    const child = this.layoutChildren.firstOrDefault(a => a == args.sender);

    if (child == null) return;

    this.InitializeChildrenPositionPreview();

    this.previewGhostRect = this.GetchildRectangle(child);

    this.isResizing = true;
    this.resizingChild = args.sender;
  }

  HandleFlowLayoutChildResize(args: FlowLayoutChildResizeEvent, event: IEventManagement) {
    const child = this.layoutChildren.firstOrDefault(a => a == args.sender);

    if (child == null) {
      this.previewGhostCellRange = null;
      return;
    }

    const rect = this.GetchildRectangle(child);

    const cellRange = this.GetRectangleCellRange({
      x: rect.x,
      y: rect.y,
      width: args.newWidth,
      height: args.newHeight,
    });

    if (cellRange == null) {
      event.stopPropagation();
      this.previewGhostCellRange = null;
      return;
    }

    if (cellRange.Width < child.minSize[0] - 1 || cellRange.Height < child.minSize[1] - 1) {
      return;
    }

    this.previewGhostRect = this.GetCellRangeRectangle(cellRange);
    this.previewGhostCellRange = cellRange;

    this.previewGhostCellRangeValid = this.CalculateChildrenPositionPreview(child);
  }

  HandleFlowLayoutChildResizeStopped(args: FlowLayoutChildResizeStoppedEvent) {
    const child = this.layoutChildren.firstOrDefault(a => a == args.sender);

    if (child == null) return;

    if (this.previewGhostCellRange != null && this.previewGhostCellRangeValid) {
      this.ChangeChildCellRange(child, this.previewGhostCellRange);
      this.ApplyPositionPreview(child);
      this.PlaceChildOverOtherChildren(child);
      this.DrawFlowLayoutChildren();
    }

    this.StopResizing();
  }

  StopResizing() {
    this.previewGhostRect = {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    };
    this.previewGhostCellRange = null;

    this.isResizing = false;
    this.resizingChild = null;
  }
  //#endregion

  //#region CHILD DRAG
  HandleFlowLayoutChildDragStarted(args: FlowLayoutChildDragStartedEvent) {
    const child = this.layoutChildren.firstOrDefault(a => a == args.sender);

    if (child == null) return;

    this.InitializeChildrenPositionPreview();
    this.isDragging = true;
    this.draggingChild = args.sender;
  }

  HandleFlowLayoutChildDrag(args: FlowLayoutChildDragEvent, event: IEventManagement) {
    const child = this.layoutChildren.firstOrDefault(a => a == args.sender);

    if (child == null) {
      this.previewGhostCellRange = null;
      return;
    }

    const rect = this.GetchildRectangle(child);

    const dx = args.toX - args.fromX;
    const dy = args.toY - args.fromY;

    const rectDragged: Rectangle = {
      x: rect.x + dx,
      y: rect.y + dy,
      width: rect.width,
      height: rect.height,
    };

    if (rectDragged.x < this.cellGap) {
      const delta = this.cellGap - rectDragged.x;

      rectDragged.x += delta;
      rectDragged.width += delta;
    }

    if (rectDragged.y < this.cellGap) {
      const delta = this.cellGap - rectDragged.y;

      rectDragged.y += delta;
      rectDragged.height += delta;
    }

    const cellRange = this.FitRectangleInCells(rectDragged, child.cellRange);

    if (cellRange == null) {
      event.stopPropagation();
      this.previewGhostCellRange = null;
      return;
    }

    this.previewGhostRect = this.GetCellRangeRectangle(cellRange);
    this.previewGhostCellRange = cellRange;

    this.previewGhostCellRangeValid = this.CalculateChildrenPositionPreview(child);
  }

  HandleFlowLayoutChildDragStopped(args: FlowLayoutChildDragStoppedEvent) {
    const child = this.layoutChildren.firstOrDefault(a => a == args.sender);

    if (child == null) return;

    if (this.previewGhostCellRange != null && this.previewGhostCellRangeValid) {
      this.ChangeChildCellRange(child, this.previewGhostCellRange);
      this.ApplyPositionPreview(child);
      this.PlaceChildOverOtherChildren(child);
      this.DrawFlowLayoutChildren();
    }

    this.StopDragging();
  }

  StopDragging() {
    this.previewGhostRect = {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    };
    this.previewGhostCellRange = null;

    this.isDragging = false;
    this.draggingChild = null;
  }
  //#endregion

  @Emit('child-cell-range-changed')
  ChangeChildCellRange(child: FlowLayoutChild, newRange: CellRange): FlowLayoutChildCellRangeChangedEvent {
    const oldRange = child.cellRange;
    const eventArgs: FlowLayoutChildCellRangeChangedEvent = {
      child: child,
      newCellRange: newRange,
      oldCellRange: oldRange,
    };

    child.cellRange = newRange;

    return eventArgs;
  }

  PlaceChildOverOtherChildren(child: FlowLayoutChild) {
    for (const chld of this.layoutChildren) {
      chld.zIndex = 0;
    }

    child.zIndex = 1;
  }

  InitializeChildrenPositionPreview() {
    this.childrenPositionPreview = [];

    for (const child of this.layoutChildren) {
      this.childrenPositionPreview.push({
        child: child,
        originalCellRange: CellRange.DeepCopyFrom(child.cellRange),
        previewCellRange: CellRange.DeepCopyFrom(child.cellRange),
      });
    }
  }

  // This function assumes that you're dragging or resizing accordingTo child and
  // therefore it's logical to grab preview cell range from previewGhost
  CalculateChildrenPositionPreview(accordingTo: FlowLayoutChild): boolean {
    if (this.previewGhostCellRange == null) return false;

    this.InitializeChildrenPositionPreview();

    const overlappingChildren = this.GetCellRangeChildren(this.previewGhostCellRange, this.cells);

    if (overlappingChildren.indexOf(accordingTo) != -1) {
      // Can't overlap self :)
      overlappingChildren.delete(accordingTo);
    }

    if (overlappingChildren.length == 0) {
      return true;
    }

    // Todo: make this a little more complex and smart someday

    if (overlappingChildren.length == 1) {
      if (this.previewGhostCellRange.SameAs(overlappingChildren[0].cellRange)) {
        // Just swap them
        const overlapping = this.childrenPositionPreview.firstOrDefault(a => a.child == overlappingChildren[0])!;
        const current = this.childrenPositionPreview.firstOrDefault(a => a.child == accordingTo)!;

        overlapping.previewCellRange = CellRange.DeepCopyFrom(current.originalCellRange);
        current.previewCellRange = CellRange.DeepCopyFrom(overlapping.originalCellRange);

        return true;
      }
    }

    // Try to displace each overlapping child in every direction once, if we fail - oh well
    return false;
  }

  GetCellRangeChildren(cellRange: CellRange, cells: Cell[][]) {
    const from = cellRange.startPosition;
    const to = cellRange.endPosition;

    const overlappingChildren: FlowLayoutChild[] = [];

    for (let y = from.y; y <= to.y; ++y) {
      for (let x = from.x; x <= to.x; ++x) {
        const cellTakenBy = cells[y][x].takenBy;

        if (cellTakenBy.length != 0) {
          for (const takenBy of cellTakenBy) {
            if (overlappingChildren.indexOf(takenBy) == -1) {
              overlappingChildren.push(takenBy);
            }
          }
        }
      }
    }

    return overlappingChildren;
  }

  ApplyPositionPreview(except: FlowLayoutChild) {
    for (const preview of this.childrenPositionPreview) {
      if (preview.child == except) {
        continue;
      }

      if (!preview.child.cellRange.SameAs(preview.previewCellRange)) {
        this.ChangeChildCellRange(preview.child, preview.previewCellRange);
      }
    }
  }

  FindFreeCellRangeForLayoutChild(child: FlowLayoutChild): CellRange | null {
    // It fits in its current cell range
    if (this.GetCellRangeChildren(child.cellRange, this.cells).length == 0) {
      return CellRange.DeepCopyFrom(child.cellRange);
    }

    // We need to find emptry cell range for it, or place it in the half of the largest child
    const cellRangeWidth = child.cellRange.Width;
    const cellRangeHeight = child.cellRange.Height;

    for (let y = 0; y < this.cells.length - cellRangeHeight; ++y) {
      const row = this.cells[y];

      for (let x = 0; x < row.length - cellRangeWidth; ++x) {
        const cellRangeToCheck = CellRange.FromPointCoordinates(x, y, x + cellRangeWidth, y + cellRangeHeight);

        const childCount = this.GetCellRangeChildren(cellRangeToCheck, this.cells).length;

        if (childCount == 0) {
          return cellRangeToCheck;
        }
      }
    }

    return null;
  }

  LargestLayoutChild() {
    let maxArea = -1;
    let maxChild = null;

    for (const child of this.layoutChildren) {
      const area = child.cellRange.Width * child.cellRange.Height;

      if (area > maxArea) {
        maxChild = child;
        maxArea = area;
      }
    }

    return maxChild;
  }

  FitLayoutChildInCurrentLargestLayoutChild(child: FlowLayoutChild) {
    const maxAreaChild = this.LargestLayoutChild();
    if (maxAreaChild == null) {
      return;
    }

    let newMaxAreaChildCellRange = CellRange.DeepCopyFrom(maxAreaChild.cellRange);
    let childCellRange = CellRange.DeepCopyFrom(child.cellRange);

    let newMaxAreaChildWidth = Math.ceil(newMaxAreaChildCellRange.Width / 2);
    let newMaxAreaChildHeight = Math.ceil(newMaxAreaChildCellRange.Height / 2);

    let newChildWidth = newMaxAreaChildCellRange.Width - newMaxAreaChildWidth;
    let newChildHeight = newMaxAreaChildCellRange.Height - newMaxAreaChildHeight;

    if (maxAreaChild.cellRange.Width > maxAreaChild.cellRange.Height) {
      newMaxAreaChildCellRange.endPosition = {
        x: newMaxAreaChildCellRange.startPosition.x + newMaxAreaChildWidth - 1,
        y: newMaxAreaChildCellRange.endPosition.y,
      };

      childCellRange.startPosition = {
        x: newMaxAreaChildCellRange.endPosition.x + 1,
        y: newMaxAreaChildCellRange.startPosition.y,
      };
      newChildHeight = newMaxAreaChildCellRange.Height;
    } else {
      newMaxAreaChildCellRange.endPosition = {
        x: newMaxAreaChildCellRange.endPosition.x,
        y: newMaxAreaChildCellRange.startPosition.y + newMaxAreaChildHeight - 1,
      };

      childCellRange.startPosition = {
        x: newMaxAreaChildCellRange.startPosition.x,
        y: newMaxAreaChildCellRange.endPosition.y + 1,
      };
      newChildWidth = newMaxAreaChildCellRange.Width;
    }

    childCellRange.endPosition = {
      x: childCellRange.startPosition.x + newChildWidth,
      y: childCellRange.startPosition.y + newChildHeight,
    };

    this.ChangeChildCellRange(maxAreaChild, newMaxAreaChildCellRange);
    this.ChangeChildCellRange(child, childCellRange);
  }

  AddLayoutChild(child: FlowLayoutChild) {
    // Todo: find empty space to place child if it doesn't fit
    const freeCellRange = this.FindFreeCellRangeForLayoutChild(child);

    if (freeCellRange == null) {
      this.FitLayoutChildInCurrentLargestLayoutChild(child);
    } else if (!freeCellRange.SameAs(child.cellRange)) {
      this.ChangeChildCellRange(child, freeCellRange);
    }

    child.editable = this.isEditing;

    child.OnResizeStarted.subscribe(this.HandleFlowLayoutChildResizeStarted);
    child.OnResize.subscribe(this.HandleFlowLayoutChildResize);
    child.OnResizeStopped.subscribe(this.HandleFlowLayoutChildResizeStopped);

    child.OnDragStarted.subscribe(this.HandleFlowLayoutChildDragStarted);
    child.OnDrag.subscribe(this.HandleFlowLayoutChildDrag);
    child.OnDragStopped.subscribe(this.HandleFlowLayoutChildDragStopped);

    this.layoutChildren.push(child);
  }

  RemoveLayoutChild(child: FlowLayoutChild) {
    const found = this.layoutChildren.firstOrDefault(a => a.id == child.id);

    if (found == null) return;

    child.OnResizeStarted.unsubscribe(this.HandleFlowLayoutChildResizeStarted);
    child.OnResize.unsubscribe(this.HandleFlowLayoutChildResize);
    child.OnResizeStopped.unsubscribe(this.HandleFlowLayoutChildResizeStopped);

    child.OnDragStarted.unsubscribe(this.HandleFlowLayoutChildDragStarted);
    child.OnDrag.unsubscribe(this.HandleFlowLayoutChildDrag);
    child.OnDragStopped.unsubscribe(this.HandleFlowLayoutChildDragStopped);

    if (this.draggingChild == found) {
      this.StopDragging();
    }

    if (this.resizingChild == found) {
      this.StopResizing();
    }

    const previewFound = this.childrenPositionPreview.firstOrDefault(a => a.child == child);
    if (previewFound != null) {
      this.childrenPositionPreview.delete(previewFound);
    }

    this.layoutChildren.delete(child);

    const foundStart = found.cellRange.startPosition;
    const foundEnd = found.cellRange.endPosition;

    for (let y = foundStart.y; y <= foundEnd.y; ++y) {
      for (let x = foundStart.x; x <= foundEnd.x; ++x) {
        this.cells[y][x].takenBy.delete(found);
      }
    }
  }

  GetFlowLayoutChildren(): FlowLayoutChild[] {
    const result: FlowLayoutChild[] = [];

    if (this.$slots.default != undefined) {
      for (const node of this.$slots.default) {
        if (node.componentInstance == undefined) continue;

        const child = node.componentInstance as FlowLayoutChild;

        if (child == undefined) continue;

        result.push(child);
      }
    }

    return result;
  }

  OnFlowLayoutChildrenChanged(newVal: FlowLayoutChild[]) {
    const added: FlowLayoutChild[] = [];
    const removed: FlowLayoutChild[] = [];

    for (const toCheck of newVal) {
      const isNew = this.layoutChildren.firstOrDefault(a => a.id == toCheck.id) == null;

      if (isNew) {
        added.push(toCheck);
      }
    }

    for (const toCheck of this.layoutChildren) {
      const isDeleted = newVal.firstOrDefault(a => a.id == toCheck.id) == null;

      if (isDeleted) {
        removed.push(toCheck);
      }
    }

    for (const child of added) {
      this.AddLayoutChild(child);
      this.DrawFlowLayoutChildren();
    }

    for (const child of removed) {
      this.RemoveLayoutChild(child);
      this.DrawFlowLayoutChildren();
    }
  }

  EnableEditing() {
    for (const child of this.layoutChildren) {
      child.editable = true;
    }
  }

  DisabledEditing() {
    for (const child of this.layoutChildren) {
      child.editable = false;
    }
  }
  //#endregion

  //#region HOOKS
  private async beforeCreate() {
    this.resizeObserver = new ResizeObserver(() => {
      this.FlowLayoutContainerResized();
    });

    this.mutationObserver = new MutationObserver(entries => {
      for (const entry of entries) {
        if (entry.type == 'childList') {
          for (const addedNode of entry.addedNodes) {
            if (addedNode.nodeType == Node.COMMENT_NODE) continue;

            this.OnFlowLayoutChildrenChanged(this.GetFlowLayoutChildren());
          }

          for (const removedNode of entry.removedNodes) {
            if (removedNode.nodeType == Node.COMMENT_NODE) continue;

            this.OnFlowLayoutChildrenChanged(this.GetFlowLayoutChildren());
          }
        }
      }
    });
  }

  private async mounted() {
    this.IsBrowserFirefox = window.navigator.userAgent.toLowerCase().includes('firefox');

    this.CalculateCells();

    const layoutChildren = this.GetFlowLayoutChildren();

    for (const child of layoutChildren) {
      this.AddLayoutChild(child);
    }

    this.resizeObserver.observe(this.flowLayoutContainer);
    this.mutationObserver.observe(this.childContainer, {
      childList: true,
    });

    this.FlowLayoutContainerResized();
    // this.InitializeFlowLayoutChildren();
  }

  private async beforeDestroy() {
    this.resizeObserver.unobserve(this.flowLayoutContainer);
    this.resizeObserver.disconnect();

    this.mutationObserver.disconnect();
  }

  private async updated() {}
  //#endregion

  //#region EVENTS
  //#endregion
}
</script>

<style lang="scss" scoped>
.flow-layout {
  position: relative;
  // overflow: hidden;
  width: 100%;
  height: 100%;

  .grid-underlay {
    // background: rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .cells-contianer {
      position: relative;
      width: 100%;
      height: 100%;

      .cell {
        position: absolute;
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .resize-preview-container {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;

    .resize-preview-ghost-container {
      position: relative;
      width: 100%;
      height: 100%;

      .resize-preview-ghost {
        position: absolute;
        background: rgba(0, 0, 0, 0.25);
      }
    }
  }

  .child-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
</style>
