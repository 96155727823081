import {
  ResponseJob,
  ResponsePrinter,
  ResponseSource,
  ResponseMaterialConsumption,
  ResponseSourceGroup,
  ResponsePrinterModel,
  ResponseJobArchieve,
  ResponseUserStat,
  ResponseCompanyStat,
  ResponsePrinterStat,
  ResponseGlobalNotification,
  ResponseMaterialConsumptionPrinterStat,
  ResponseMaterialConsumptionCompanyStat,
  ResponseTotalPrintTimeByWeekDayCompanyStat,
  ResponseMaterialConsumptionArchive,
} from './ResponseMonolith';
import {
  Job,
  Printer,
  Source,
  MaterialConsumption,
  SourceGroup,
  PrinterModel,
  JobArchieve,
  UserStat,
  CompanyStat,
  PrinterStat,
  GlobalNotification,
  MaterialConsumptionPrinterStat,
  MaterialConsumptionCompanyStat,
  TotalPrintTimeByWeekDayCompanyStat,
  MaterialConsumptionArchive,
} from '../Entities';
import { TypeHelper } from '@/util/TypeHelper';
import { Guid } from 'guid-typescript';
import { timeToTimeSpan } from '@/util/TimeSpan';

declare module './ResponseMonolith' {
  interface ResponseJob {
    Map(): Job;
  }
  interface ResponsePrinter {
    Map(): Printer;
  }
  interface ResponseSource {
    Map(): Source;
  }
  interface ResponseMaterialConsumption {
    Map(): MaterialConsumption;
  }
  interface ResponseSourceGroup {
    Map(): SourceGroup;
  }
  interface ResponsePrinterModel {
    Map(): PrinterModel;
  }
  interface ResponseJobArchieve {
    Map(): JobArchieve;
  }
  interface ResponseUserStat {
    Map(): UserStat;
  }
  interface ResponseCompanyStat {
    Map(): CompanyStat;
  }
  interface ResponsePrinterStat {
    Map(): PrinterStat;
  }
  interface ResponseMaterialConsumptionPrinterStat {
    Map(): MaterialConsumptionPrinterStat;
  }
  interface ResponseMaterialConsumptionCompanyStat {
    Map(): MaterialConsumptionCompanyStat;
  }
  interface ResponseTotalPrintTimeByWeekDayCompanyStat {
    Map(): TotalPrintTimeByWeekDayCompanyStat;
  }
  interface ResponseGlobalNotification {
    Map(): GlobalNotification;
  }
  interface ResponseMaterialConsumptionArchive {
    Map(): MaterialConsumptionArchive;
  }
}

ResponseJob.prototype.Map = function (): Job {
  const job = new Job();
  job.Id = Guid.parse(this.id.toString());
  job.CompanyId = Guid.parse(this.companyId.toString());
  job.PrinterId = Guid.parse(this.printerId.toString());
  job.SourceId = this.sourceId == null ? null : Guid.parse(this.sourceId.toString());
  job.UserId = this.userId == null ? null : Guid.parse(this.userId.toString());
  job.LocalFilename = this.localFilename;
  job.CreationDateTime = TypeHelper.StringToDate(this.creationDateTime);
  job.Order = this.order;
  job.JobState = this.jobState;
  job.Progress = this.progress;
  job.IsInvoked = this.isInvoked;
  job.ErrorCount = this.errorCount;
  job.LastError = this.lastError;
  if (this.actualStartTime != null) job.ActualStartTime = TypeHelper.StringToDate(this.actualStartTime);
  return job;
};

ResponseJobArchieve.prototype.Map = function (): JobArchieve {
  const jobArchieve = new JobArchieve();
  jobArchieve.Id = Guid.parse(this.id.toString());
  jobArchieve.CompanyId = Guid.parse(this.companyId.toString());
  jobArchieve.SourceId = this.sourceId == null ? null : Guid.parse(this.sourceId.toString());
  jobArchieve.UserId = this.userId == null ? null : Guid.parse(this.userId.toString());
  jobArchieve.LocalFilename = this.localFilename;
  jobArchieve.PrinterId = Guid.parse(this.printerId.toString());
  jobArchieve.CreationDateTime = TypeHelper.StringToDate(this.creationDateTime);
  jobArchieve.ActualStartTime = TypeHelper.StringToDate(this.actualStartTime);
  jobArchieve.EndTime = this.endTime == null ? null : TypeHelper.StringToDate(this.endTime);
  jobArchieve.EndStatus = this.endStatus;
  jobArchieve.ErrorCount = this.errorCount;
  return jobArchieve;
};

ResponsePrinter.prototype.Map = function (): Printer {
  const printer = new Printer();
  printer.Id = Guid.parse(this.id.toString());
  printer.CompanyId = Guid.parse(this.companyId.toString());
  printer.UserId = Guid.parse(this.creatorId.toString());
  printer.PrinterModelId = Guid.parse(this.printerModelId.toString());
  printer.PrinterState = this.printerState;
  printer.Name = this.name;
  printer.Description = this.description;
  printer.SerialNumber = this.serialNumber;
  printer.HardwareVersion = this.hardwareVersion;
  printer.FirmwareVersion = this.firmwareVersion;
  printer.FirmwareType = this.firmwareType;
  printer.CreationDate = TypeHelper.StringToDate(this.creationDate);
  printer.JobCount = this.jobCount;
  printer.QueuedJobCount = this.queuedJobCount;
  printer.ErrorCount = this.errorCount;
  printer.LastError = this.lastError;
  printer.LastArchiveId = this.lastArchiveId == null ? null : Guid.parse(this.lastArchiveId.toString());
  printer.CurrentJobId = this.currentJobId == null ? null : Guid.parse(this.currentJobId.toString());
  printer.LogsReady = this.logsReady;
  return printer;
};

ResponseSource.prototype.Map = function (): Source {
  const printJobSource = new Source();
  printJobSource.Id = Guid.parse(this.id.toString());
  printJobSource.CompanyId = Guid.parse(this.companyId.toString());
  printJobSource.UserId = Guid.parse(this.userId.toString());
  printJobSource.GroupId = this.groupId != null ? Guid.parse(this.groupId.toString()) : null;
  printJobSource.CreationDateTime = TypeHelper.StringToDate(this.loadTime);
  printJobSource.CodePath = this.codePath;
  printJobSource.CodeFileName = this.codeFileName;
  printJobSource.CodeFileSize = this.codeFileSize;
  printJobSource.ProjectPath = this.projectPath;
  printJobSource.ProjectFileName = this.projectFileName;
  printJobSource.ProjectFileSize = this.projectFileSize;
  printJobSource.PrintDuration = timeToTimeSpan(this.printDuration);
  printJobSource.MaterialConsumptions = this.materialConsumptions.map(a => a.Map());
  printJobSource.PrinterModel = this.printerModel;
  printJobSource.LastPrint = this.lastPrint == null ? null : TypeHelper.StringToDate(this.lastPrint);
  printJobSource.PrintCount = this.printCount;
  return printJobSource;
};

ResponseMaterialConsumption.prototype.Map = function (): MaterialConsumption {
  const materialConsumption = new MaterialConsumption();
  materialConsumption.Id = Guid.parse(this.id.toString());
  materialConsumption.MaterialName = this.materialName;
  materialConsumption.ExtruderIndex = this.extruderIndex;
  materialConsumption.Count = this.count;
  materialConsumption.MaterialType = this.materialType;
  return materialConsumption;
};

ResponseSourceGroup.prototype.Map = function (): SourceGroup {
  const printJobSourceGroup = new SourceGroup();
  printJobSourceGroup.Id = Guid.parse(this.id.toString());
  printJobSourceGroup.CompanyId = Guid.parse(this.companyId.toString());
  printJobSourceGroup.UserId = this.userId == null ? null : Guid.parse(this.userId.toString());
  printJobSourceGroup.Name = this.name;
  printJobSourceGroup.Description = this.description;
  printJobSourceGroup.LastUpdated = this.lastUpdated == null ? null : TypeHelper.StringToDate(this.lastUpdated);
  printJobSourceGroup.FileCount = this.fileCount;
  printJobSourceGroup.IsVoid = this.isVoid;
  return printJobSourceGroup;
};

ResponsePrinterModel.prototype.Map = function (): PrinterModel {
  const printerModel = new PrinterModel();
  printerModel.Id = Guid.parse(this.id.toString());
  printerModel.Name = this.name;
  return printerModel;
};

ResponseUserStat.prototype.Map = function (): UserStat {
  const userStat = new UserStat();
  userStat.id = Guid.parse(this.id.toString());
  userStat.userId = Guid.parse(this.userId.toString());
  userStat.jobCountWeek = this.jobCountWeek;
  userStat.jobCountMonth = this.jobCountMonth;
  userStat.jobCountYear = this.jobCountYear;
  userStat.jobCountWhole = this.jobCountWhole;
  userStat.sourceCount = this.sourceCount;
  userStat.printTimeWeekS = this.printTimeWeekS;
  userStat.printTimeMonthS = this.printTimeMonthS;
  userStat.printTimeYearS = this.printTimeYearS;
  userStat.printTimeWholeS = this.printTimeWholeS;
  userStat.plasticConsumedGr = this.plasticConsumedGr;
  userStat.compositeConsumedM = this.compositeConsumedM;
  return userStat;
};

ResponseCompanyStat.prototype.Map = function (): CompanyStat {
  const companyStat = new CompanyStat();
  companyStat.id = Guid.parse(this.id.toString());
  companyStat.companyId = Guid.parse(this.companyId.toString());
  companyStat.jobCountWeek = this.jobCountWeek;
  companyStat.jobCountMonth = this.jobCountMonth;
  companyStat.jobCountYear = this.jobCountYear;
  companyStat.jobCountWhole = this.jobCountWhole;
  companyStat.sourceCount = this.sourceCount;
  companyStat.printTimeWeekS = this.printTimeWeekS;
  companyStat.printTimeMonthS = this.printTimeMonthS;
  companyStat.printTimeYearS = this.printTimeYearS;
  companyStat.printTimeWholeS = this.printTimeWholeS;
  companyStat.plasticConsumedGr = this.plasticConsumedGr;
  companyStat.compositeConsumedM = this.compositeConsumedM;
  companyStat.workloadAvg = this.workloadAvg;
  companyStat.usersCount = this.usersCount;
  companyStat.printersCount = this.printersCount;
  return companyStat;
};

ResponsePrinterStat.prototype.Map = function (): PrinterStat {
  const printerStat = new PrinterStat();
  printerStat.id = Guid.parse(this.id.toString());
  printerStat.printerId = Guid.parse(this.printerId.toString());
  printerStat.companyId = Guid.parse(this.companyId.toString());
  printerStat.jobCountWeek = this.jobCountWeek;
  printerStat.jobCountMonth = this.jobCountMonth;
  printerStat.jobCountYear = this.jobCountYear;
  printerStat.jobCountWhole = this.jobCountWhole;
  printerStat.printTimeWeekS = this.printTimeWeekS;
  printerStat.printTimeMonthS = this.printTimeMonthS;
  printerStat.printTimeYearS = this.printTimeYearS;
  printerStat.printTimeWholeS = this.printTimeWholeS;
  printerStat.plasticConsumedGr = this.plasticConsumedGr;
  printerStat.compositeConsumedM = this.compositeConsumedM;
  printerStat.creationDate = TypeHelper.StringToDate(this.creationDate);
  printerStat.workloadAvg = this.workloadAvg;
  return printerStat;
};

ResponseMaterialConsumptionPrinterStat.prototype.Map = function (): MaterialConsumptionPrinterStat {
  const r = new MaterialConsumptionPrinterStat();
  r.Id = Guid.parse(this.id.toString());
  r.PrinterId = Guid.parse(this.printerId.toString());
  r.CompanyId = Guid.parse(this.companyId.toString());
  r.MaterialName = this.materialName;
  r.MaterialType = this.materialType;
  r.ConsumedWeek = this.consumedWeek;
  r.ConsumedMonth = this.consumedMonth;
  r.ConsumedYear = this.consumedYear;
  r.ConsumedWhole = this.consumedWhole;
  return r;
};

ResponseMaterialConsumptionCompanyStat.prototype.Map = function (): MaterialConsumptionCompanyStat {
  const r = new MaterialConsumptionCompanyStat();
  r.Id = Guid.parse(this.id.toString());
  r.CompanyId = Guid.parse(this.companyId.toString());
  r.MaterialName = this.materialName;
  r.MaterialType = this.materialType;
  r.ConsumedWeek = this.consumedWeek;
  r.ConsumedMonth = this.consumedMonth;
  r.ConsumedYear = this.consumedYear;
  r.ConsumedWhole = this.consumedWhole;
  return r;
};

ResponseTotalPrintTimeByWeekDayCompanyStat.prototype.Map = function (): TotalPrintTimeByWeekDayCompanyStat {
  const r = new TotalPrintTimeByWeekDayCompanyStat();
  r.Id = Guid.parse(this.id.toString());
  r.CompanyId = Guid.parse(this.companyId.toString());
  r.MondayTimeS = this.mondayTimeS;
  r.TuesdayTimeS = this.tuesdayTimeS;
  r.WednesdayTimeS = this.wednesdayTimeS;
  r.ThursdayTimeS = this.thursdayTimeS;
  r.FridayTimeS = this.fridayTimeS;
  r.SaturdayTimeS = this.saturdayTimeS;
  r.SundayTimeS = this.sundayTimeS;
  return r;
};

ResponseMaterialConsumptionPrinterStat.prototype.Map = function (): MaterialConsumptionPrinterStat {
  const r = new MaterialConsumptionPrinterStat();
  r.Id = Guid.parse(this.id.toString());
  r.PrinterId = Guid.parse(this.printerId.toString());
  r.CompanyId = Guid.parse(this.companyId.toString());
  r.MaterialName = this.materialName;
  r.MaterialType = this.materialType;
  r.ConsumedWeek = this.consumedWeek;
  r.ConsumedMonth = this.consumedMonth;
  r.ConsumedYear = this.consumedYear;
  r.ConsumedWhole = this.consumedWhole;
  return r;
};

ResponseGlobalNotification.prototype.Map = function (): GlobalNotification {
  const gn = new GlobalNotification();
  gn.id = Guid.parse(this.id.toString());
  gn.endShowing = this.endShowing;
  gn.startShowing = this.startShowing;
  gn.message = this.message;
  gn.userId = Guid.parse(this.userId.toString());
  return gn;
};

ResponseMaterialConsumptionArchive.prototype.Map = function (): MaterialConsumptionArchive {
  const mca = new MaterialConsumptionArchive();
  mca.Consumed = this.consumed;
  mca.MaterialName = this.materialName;
  mca.MaterialType = this.materialType;
  return mca;
};
