<template>
  <div class="one-time-addon-editor">
    <editor-header>
      <template slot="header">
        <span>
          {{ Name }}
        </span>
      </template>

      <template slot="buttons">
        <header-icon-button iconName="fa-times" @clicked="closeButtonClicked" />
      </template>
    </editor-header>

    <editor-content>
      <div class="d-flex">
        <text-input
          class="mb-2 mr-3"
          :label="FreeCaption + ' / ' + TotalCaption"
          :value="Free + '/' + Total"
          :labelWidth="labelWidth"
          :disabled="isLoading"
          :readonly="true"
        />

        <button @click="AddToCart()">{{ AddCaption }}</button>
      </div>

      <action-card
        v-if="BaseItems.length > 0"
        class="aura-keys w-100 mb-2 overflow-auto thin-scroll"
        :headerText="AuraKeysCaption"
        :initiallyCollapsed="true"
      >
        <sortable-header :header-items="AuraKeysHeader" />
        <div class="overflow-overlay thin-scroll">
          <list-item-v-3
            v-for="item in BaseItems"
            :id="item.id"
            :key="item.id.toString()"
            :items="AuraKeyData(item)"
            @openItem="OpenAuraKey(item)"
          />
        </div>
      </action-card>
    </editor-content>
  </div>
</template>

<script lang="ts">
import { PurchasedItem } from '@/models/Entities';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ComponentHelper, { HeaderItem, ItemData } from '@/util/ComponentHelper';
import SortableHeader from '@/components/presentation/SortableHeader.vue';
import ActionCard from '../presentation/ActionCard.vue';
import ListItemV3 from '@/components/presentation/ListItemV3.vue';

import { POSITION } from 'vue-toastification';
import { toast } from '@/main';
import { CartModule } from '@/store/modules/billing/cartModule';
import { PurchasedItemFullGroup } from '@/store/modules/billing/purchasedItemModule';
import en from '@/localization/en';
import EditorHeader from '@/components/forms/base/EditorHeader.vue';
import EditorContent from '@/components/forms/base/EditorContent.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import RegularButton from '../buttons/RegularButton.vue';
import HeaderIconButton from '@/components/buttons/HeaderIconButton.vue';

@Component({
  name: 'one-time-addon-editor',
  components: {
    HeaderIconButton,
    ActionCard: ActionCard,
    ListItemV3: ListItemV3,
    SortableHeader: SortableHeader,
    EditorHeader: EditorHeader,
    EditorContent: EditorContent,
    TextInput: TextInput,
    RegularButton: RegularButton,
  },
})
export default class OneTimeAddonEditor extends Vue {
  labelWidth: number = 120;
  @Prop() purchasedItemGroup!: PurchasedItemFullGroup;

  private get Item() {
    return this.purchasedItemGroup.group[0];
  }

  private get Name() {
    return this.Item.product.name;
  }

  private get Free() {
    return this.purchasedItemGroup.group.filter(a => a.baseItem === null).length.toString();
  }

  private get Total() {
    return this.purchasedItemGroup.group.length.toString();
  }

  private get BaseItems(): PurchasedItem[] {
    return this.purchasedItemGroup.group.filter(a => a.baseItem !== null).map(a => a.baseItem!);
  }

  private get AuraKeysHeader(): HeaderItem[] {
    let headerKey: HeaderItem = {
      caption: '#',
      itemClass: ComponentHelper.GetWidthClass('grow-1'),
    };
    let headerStatus: HeaderItem = {
      caption: en.status.titleCase(),
      itemClass: ComponentHelper.GetWidthClass(100),
    };
    let headerMachineId: HeaderItem = {
      caption: en.machineId.titleCase(),
      itemClass: ComponentHelper.GetWidthClass(150),
    };

    let headerItems: HeaderItem[] = [];

    headerItems.push(headerKey);
    headerItems.push(headerStatus);
    headerItems.push(headerMachineId);

    return headerItems;
  }

  private AuraKeyData(item: PurchasedItem): ItemData[] {
    let items: ItemData[] = [];

    let itemKey: ItemData = new ItemData(
      'Key',
      item.cryptlexKey === null ? '-' : item.cryptlexKey!,
      'grow-1',
      undefined,
      'fa-clone',
      'far',
      () => {
        navigator.clipboard.writeText(item.cryptlexKey!);
        toast.info(en.keyCopiedToClipboard, {
          position: POSITION.BOTTOM_RIGHT,
        });
      },
    );
    let itemStatus: ItemData = new ItemData(
      'Status',
      item.activated ? en.activated.titleCase() : en.free.titleCase(),
      100,
    );
    let itemMachineId: ItemData = new ItemData('MachineId', item.deviceName === null ? '-' : item.deviceName!, 150);

    items.push(itemKey);
    items.push(itemStatus);
    items.push(itemMachineId);

    return items;
  }

  private AddToCart() {
    if (CartModule.HasSubscription) {
      // Reset cart
      toast.warning(en.yourCartHasBeebCleared, {
        position: POSITION.BOTTOM_RIGHT,
      });
      CartModule.ClearCart();
    }

    CartModule.QuantityAdded(this.Item.price);
    toast.info(en.productXHasBeenAddedToCart.replace('[PRODUCT_NAME]', this.Item.product.name), {
      position: POSITION.BOTTOM_RIGHT,
    });
  }

  @Emit('open-base-item')
  private OpenAuraKey(item: PurchasedItem) {
    return item;
  }

  closeButtonClicked() {
    this.$emit('close-clicked');
  }

  private get FreeCaption() {
    return en.free.titleCase();
  }

  private get TotalCaption() {
    return en.total.titleCase();
  }

  private get AddCaption() {
    return en.add.titleCase();
  }

  private get AuraKeysCaption() {
    return en.auraKeys;
  }
}
</script>

<style lang="scss" scoped>
.one-time-addon-editor {
  background: var(--editor-background);
  border-radius: 6px;
  position: relative;

  .aura-keys {
    background: transparent;
  }

  button {
    border: 1px solid var(--editor-field-value);
    border-radius: 6px;
    font-size: 13px;
    color: var(--editor-field-value);
    background: transparent;
    width: 150px;
    height: 26px;
  }
}
</style>
