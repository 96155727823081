import { IPlainResponse } from '@/models/responses/BaseResponse';
import { Guid } from 'guid-typescript';
import { ConnectionTypeNames, ErrorType, PrinterState } from '@/models/Entities';
import { TypeHelper } from '@/util/TypeHelper';

export class Temperature {
  current!: number;
  target!: number | null;
}

export class PrintHeadTemperatureTelemetry {
  data!: {
    [key: string]: Temperature;
  };
  name!: string;
}

export class PrintHeadPositionTelemetry {
  x!: number;
  y!: number;
  z!: number;
}

export class FanSpeedTelemetry {
  speed!: number;
  name!: string;
}

export class ExtruderMaterialUsedTelemetry {
  used!: number;
  name!: string;
}

export class ChamberTemperatureTelemetry {
  data!: {
    [key: string]: Temperature;
  };
}

export class BuildPlateTemperatureTelemetry {
  data!: {
    [key: string]: Temperature;
  };
}

export class ResponseConnection implements IPlainResponse {
  id!: Guid;
  companyId!: Guid;
  printerId!: Guid;
  connectionTypeId!: Guid;
}

export class ResponseConnectionType implements IPlainResponse {
  id!: Guid;
  name!: ConnectionTypeNames;
}

export class ResponseConnectionTypes implements IPlainResponse {
  connectionTypes!: ResponseConnectionType[];
}

export class ResponseConnectionWithCode implements IPlainResponse {
  connectionId!: Guid;
  companyId!: Guid;
  printerId!: Guid;
  connectionTypeId!: Guid;
  code!: string;
}

export class ResponseRawCommand implements IPlainResponse {
  printerId!: Guid;
  command!: string;
  result!: string;
  success!: boolean;
}

export class ResponseRaw implements IPlainResponse {
  printerId!: Guid;
  raw!: string;
}

export class ResponseTelemetryItem implements IPlainResponse {
  time!: string;
  status!: PrinterState;
  extruderPositions!: number[];
  xPosition!: number;
  yPosition!: number;
  zPosition!: number;
  currentTool!: string;
  bpTarget!: number;
  bpCurrents!: number[];
  bpSensorNames!: string[];
  chTarget!: number;
  chCurrents!: number[];
  chSensorNames!: string[];
  phTargets!: number[];
  phCurrents!: number[][];
  phSensorNames!: string[][];
  fanSpeeds!: number[];
  currentLayer!: number;
  printProgress!: number;
}

export class ResponseTelemetryItemFiltered implements IPlainResponse {
  time!: Date;
  buildPlateTemperature!: BuildPlateTemperatureTelemetry | null;
  chamberTemperature!: ChamberTemperatureTelemetry | null;
  materialUsed!: ExtruderMaterialUsedTelemetry[] | null;
  fanSpeeds!: FanSpeedTelemetry[] | null;
  printHeadPosition!: PrintHeadPositionTelemetry | null;
  printHeadTemperatures!: PrintHeadTemperatureTelemetry[] | null;
  currentTool!: number | null;
}

export type TelemetryViewModel =
  | BuildPlateTemperatureTelemetry
  | ChamberTemperatureTelemetry
  | ExtruderMaterialUsedTelemetry
  | FanSpeedTelemetry
  | PrintHeadPositionTelemetry
  | PrintHeadTemperatureTelemetry;

export class ResponseTelemetryFiltered implements IPlainResponse {
  items!: ResponseTelemetryItemFiltered[];
  printerId!: Guid;
}

export class ResponseTelemetry implements IPlainResponse {
  printerId!: Guid;
  companyId!: Guid;
  items!: ResponseTelemetryItem[];
}

export class ResponseError implements IPlainResponse {
  errorType!: ErrorType;
  message!: string;
  time!: string;
  acknowledged!: boolean;
  id!: Guid;
}

export class ResponseErrors implements IPlainResponse {
  printerId!: Guid;
  companyId!: Guid;
  items!: ResponseError[];
}

export class ResponseErrorsPaged implements IPlainResponse {
  errors!: ResponseErrors;
  total!: number;
}

export class ResponseUnacknowledgedErrorsCount implements IPlainResponse {
  printerId!: Guid;
  count!: number;
}

export class ResponseConnectorPrinterModel implements IPlainResponse {
  id!: Guid;
  bpSensorNames!: string[];
  chSensorNames!: string[];
  phNames!: string[];
  phSensorNames!: string[][];
  fanNames!: string[];
  extruderNames!: string[];
}

export class ResponseConnectorPrinterModels implements IPlainResponse {
  models!: ResponseConnectorPrinterModel[];
}

export class ResponseUploadLogConfiguration implements IPlainResponse {
  success!: boolean;
  errorMessage!: string;
}

export class ResponseGetLogs implements IPlainResponse {
  success!: boolean;
}

export class ResponseConnectorHelper {
  static createRealUploadLogConfiguration(from: ResponseUploadLogConfiguration): ResponseUploadLogConfiguration {
    const r = new ResponseUploadLogConfiguration();

    r.errorMessage = from.errorMessage;
    r.success = from.success;

    return r;
  }
  static createRealConnection(from: ResponseConnection): ResponseConnection {
    const r = new ResponseConnection();
    r.id = Guid.parse(from.id.toString());
    r.companyId = Guid.parse(from.companyId.toString());
    r.printerId = Guid.parse(from.printerId.toString());
    r.connectionTypeId = Guid.parse(from.connectionTypeId.toString());
    return r;
  }
  static createRealConnectionType(from: ResponseConnectionType): ResponseConnectionType {
    const r = new ResponseConnectionType();
    r.id = Guid.parse(from.id.toString());
    r.name = from.name;
    return r;
  }
  static createRealConnectionTypes(from: ResponseConnectionTypes): ResponseConnectionTypes {
    const r = new ResponseConnectionTypes();
    r.connectionTypes = [];
    for (const item of from.connectionTypes) {
      r.connectionTypes.push(this.createRealConnectionType(item));
    }
    return r;
  }
  static createRealConnectionWithCode(from: ResponseConnectionWithCode): ResponseConnectionWithCode {
    const r = new ResponseConnectionWithCode();
    r.connectionId = Guid.parse(from.connectionId.toString());
    r.connectionTypeId = Guid.parse(from.connectionTypeId.toString());
    r.companyId = Guid.parse(from.companyId.toString());
    r.printerId = Guid.parse(from.printerId.toString());
    r.code = from.code;
    return r;
  }
  static createRealRawCommand(from: ResponseRawCommand): ResponseRawCommand {
    const r = new ResponseRawCommand();
    r.printerId = Guid.parse(from.printerId.toString());
    r.command = from.command;
    r.result = from.result;
    r.success = from.success;
    return r;
  }
  static createRealRaw(from: ResponseRaw): ResponseRaw {
    const r = new ResponseRaw();
    r.printerId = Guid.parse(from.printerId.toString());
    r.raw = from.raw;
    return r;
  }
  static createRealErrors(from: ResponseErrors): ResponseErrors {
    const r = new ResponseErrors();
    r.printerId = Guid.parse(from.printerId.toString());
    r.companyId = Guid.parse(from.companyId.toString());
    r.items = [];
    for (const item of from.items) {
      r.items.push(this.createRealError(item));
    }
    return r;
  }
  static createRealErrorsPaged(from: ResponseErrorsPaged): ResponseErrorsPaged {
    const r = new ResponseErrorsPaged();
    r.errors = this.createRealErrors(from.errors);
    r.total = from.total;
    return r;
  }
  static createRealUnacknowledgedErrorsCount(
    from: ResponseUnacknowledgedErrorsCount,
  ): ResponseUnacknowledgedErrorsCount {
    const r = new ResponseUnacknowledgedErrorsCount();
    r.printerId = Guid.parse(from.printerId.toString());
    r.count = from.count;

    return r;
  }
  static createRealError(from: ResponseError): ResponseError {
    const r = new ResponseError();
    r.time = from.time;
    r.message = from.message;
    r.errorType = from.errorType;
    r.acknowledged = from.acknowledged;
    r.id = Guid.parse(from.id.toString());
    return r;
  }
  static createRealConnectorPrinterModel(from: ResponseConnectorPrinterModel): ResponseConnectorPrinterModel {
    const r = new ResponseConnectorPrinterModel();

    r.id = Guid.parse(from.id.toString());
    r.bpSensorNames = from.bpSensorNames;
    r.chSensorNames = from.chSensorNames;
    r.phNames = from.phNames;
    r.phSensorNames = from.phSensorNames;
    r.fanNames = from.fanNames;
    r.extruderNames = from.extruderNames;

    return r;
  }
  static createRealConnectorPrinterModels(from: ResponseConnectorPrinterModels): ResponseConnectorPrinterModels {
    const r = new ResponseConnectorPrinterModels();
    r.models = [];

    for (const model of from.models) {
      r.models.push(this.createRealConnectorPrinterModel(model));
    }

    return r;
  }
  static createRealTelemetry(from: ResponseTelemetry): ResponseTelemetry {
    const r = new ResponseTelemetry();
    r.printerId = Guid.parse(from.printerId.toString());
    r.companyId = Guid.parse(from.companyId.toString());
    r.items = [];
    for (const item of from.items) {
      r.items.push(this.createRealTelemetryItem(item));
    }
    return r;
  }
  static createRealTelemetryFiltered(from: ResponseTelemetryFiltered): ResponseTelemetryFiltered {
    const r = new ResponseTelemetryFiltered();

    r.items = [];
    for (const item of from.items) {
      r.items.push(this.createRealTelemetryItemFiltered(item));
    }

    r.printerId = Guid.parse(from.printerId.toString());

    return r;
  }
  static createRealTelemetryItemFiltered(from: ResponseTelemetryItemFiltered): ResponseTelemetryItemFiltered {
    // console.log("???ASD?ASD?");

    const r = new ResponseTelemetryItemFiltered();
    r.time = TypeHelper.StringToDate(from.time.toString());

    if (from.buildPlateTemperature != null) {
      r.buildPlateTemperature = new BuildPlateTemperatureTelemetry();
      r.buildPlateTemperature.data = {};

      for (const item in from.buildPlateTemperature.data) {
        const temperature = from.buildPlateTemperature.data[item];

        const createdTemp = new Temperature();
        createdTemp.current = temperature.current;
        createdTemp.target = temperature.target;

        r.buildPlateTemperature.data[item] = createdTemp;
      }
    }

    if (from.chamberTemperature != null) {
      r.chamberTemperature = new ChamberTemperatureTelemetry();
      r.chamberTemperature.data = {};

      for (const item in from.chamberTemperature.data) {
        const temperature = from.chamberTemperature.data[item];

        const createdTemp = new Temperature();
        createdTemp.current = temperature.current;
        createdTemp.target = temperature.target;

        r.chamberTemperature.data[item] = createdTemp;
      }
    }

    if (from.fanSpeeds != null) {
      r.fanSpeeds = [];

      for (const item of from.fanSpeeds) {
        const temp = new FanSpeedTelemetry();

        temp.name = item.name;
        temp.speed = item.speed;

        r.fanSpeeds.push(temp);
      }
    }

    if (from.materialUsed != null) {
      r.materialUsed = [];

      for (const item of from.materialUsed) {
        const temp = new ExtruderMaterialUsedTelemetry();

        temp.name = item.name;
        temp.used = item.used;

        r.materialUsed.push(temp);
      }
    }

    if (from.printHeadPosition != null) {
      r.printHeadPosition = new PrintHeadPositionTelemetry();
      r.printHeadPosition.x = from.printHeadPosition.x;
      r.printHeadPosition.y = from.printHeadPosition.y;
      r.printHeadPosition.z = from.printHeadPosition.z;
    }

    if (from.printHeadTemperatures != null) {
      r.printHeadTemperatures = [];

      for (const item of from.printHeadTemperatures) {
        const phCreated = new PrintHeadTemperatureTelemetry();
        phCreated.name = item.name;
        phCreated.data = {};

        for (const subItem in item.data) {
          const temperature = item.data[subItem];

          const createdTemp = new Temperature();
          createdTemp.current = temperature.current;
          createdTemp.target = temperature.target;

          phCreated.data[subItem] = createdTemp;
        }

        r.printHeadTemperatures.push(phCreated);
      }
    }

    r.currentTool = from.currentTool;

    return r;
  }
  static createRealTelemetryItem(from: ResponseTelemetryItem): ResponseTelemetryItem {
    const r = new ResponseTelemetryItem();
    r.time = from.time;
    r.status = from.status;
    r.extruderPositions = [];
    for (const item of from.extruderPositions) {
      r.extruderPositions.push(item);
    }
    r.xPosition = from.xPosition;
    r.yPosition = from.yPosition;
    r.zPosition = from.zPosition;
    r.currentTool = from.currentTool;
    r.bpTarget = from.bpTarget;
    r.bpCurrents = [];
    for (const item of from.bpCurrents) {
      r.bpCurrents.push(item);
    }
    r.bpSensorNames = [];
    for (const item of from.bpSensorNames) {
      r.bpSensorNames.push(item);
    }
    r.chTarget = from.chTarget;
    r.chCurrents = [];
    for (const item of from.chCurrents) {
      r.chCurrents.push(item);
    }
    r.chSensorNames = [];
    for (const item of from.chSensorNames) {
      r.chSensorNames.push(item);
    }
    r.phTargets = [];
    for (const item of from.phTargets) {
      r.phTargets.push(item);
    }
    r.phCurrents = [];
    for (let i = 0; i < from.phCurrents.length; i++) {
      const phCurrentsInner: number[] = [];
      for (let j = 0; j < from.phCurrents[i].length; j++) {
        phCurrentsInner.push(from.phCurrents[i][j]);
      }
      r.phCurrents.push(phCurrentsInner);
    }
    r.phSensorNames = [];
    for (let i = 0; i < from.phSensorNames.length; i++) {
      const phSensorInner: string[] = [];
      for (let j = 0; j < from.phSensorNames[i].length; j++) {
        phSensorInner.push(from.phSensorNames[i][j]);
      }
      r.phSensorNames.push(phSensorInner);
    }
    r.fanSpeeds = from.fanSpeeds;
    r.currentLayer = from.currentLayer;
    r.printProgress = from.printProgress;
    return r;
  }
}
