import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import * as ips from '../../util/api/ips';
import * as routes from '../../util/api/routes';
import * as hubActions from '../../util/api/hubActions';
import { Guid } from 'guid-typescript';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { UtilModule } from './utilModule';
import store from '@/store';
import { ConnectionType, Printer, PrinterModel, PrinterState } from '@/models/Entities';
import '../../models/responses/ResponseIdentityExtensions';
import {
  ResponseMonolithHelper,
  ResponsePrinter,
  ResponsePrinterId,
  ResponsePrinterModels,
  ResponsePrinters,
  ResponsePrinterState,
  ResponsePrinterUpdateCurrentJob,
  ResponsePrinterUpdateErrorCount,
  ResponsePrinterUpdateLastArchive,
  ResponsePrinterUpdateLastError,
} from '../../models/responses/ResponseMonolith';
import {
  RequestCreatePrinter,
  RequestDeletePrinter,
  RequestReadPrintersByIds,
  RequestUpdatePrinterNaming,
  RequestReadPrinterById,
  PrinterSortBy,
  RequestReadPrinterPage,
} from '../../models/requests/RequestMonolith';
import { HubConnection } from '@microsoft/signalr';
import GC, { New_GC } from '../util/GC';
import { GuidHelper } from '@/util/GuidHelper';
import { ErrorModule } from '@/store/modules/errorsModule';
import { LoginModule } from '@/store/modules/loginModule';
import {
  RequestCreateConnection,
  RequestReadConnectionTypes,
  RequestUpdateConenction,
} from '@/models/requests/RequestConnector';
import {
  ResponseConnectionTypes,
  ResponseConnectionWithCode,
  ResponseConnectorHelper,
} from '@/models/responses/ResponseConnector';
import '../../models/responses/ResponseConnectorExtension';
import Vue from 'vue';
import { SortMode } from '@/util/ComponentHelper';
import { SimpleEventDispatcher } from 'strongly-typed-events';

export interface LoadPrinterPageData {
  companyId: Guid;
  sortBy: PrinterSortBy | null;
  sortMode: SortMode | null;
  page: number;
}

@Module({ dynamic: true, name: 'printers', store: store, namespaced: true })
export default class printerModule extends VuexModule {
  private printerModels: PrinterModel[] = [];
  private connectionTypes: ConnectionType[] = [];

  private printers: Printer[] = [];
  private _gc = new GC();
  private _gcNew = new New_GC();

  private onPrinterStatusUpdated = new SimpleEventDispatcher<Printer>();
  public get OnPrinterStatusUpdated() {
    return this.onPrinterStatusUpdated.asEvent();
  }

  get PrinterModels() {
    return this.printerModels;
  }

  get ConnectionTypes() {
    return this.connectionTypes;
  }

  get Printers() {
    return this.printers;
  }

  @Mutation
  AddPrinterModelToModule(model: PrinterModel) {
    if (this.printerModels.firstOrDefault(a => a.Id.toString() == model.Id.toString()) != null) return;

    this.printerModels.push(model);
  }

  @Mutation
  AddConnectionTypeToModule(type: ConnectionType) {
    if (this.connectionTypes.firstOrDefault(a => a.Id.toString() == type.Id.toString()) != null) return;

    this.connectionTypes.push(type);
  }

  @Mutation
  AddPrinterToModule(value: Printer) {
    if (this.printers.find(a => a.Id.equals(value.Id))) {
      this.printers = this.printers.map(a => {
        if (a.Id.toString() == value.Id.toString()) {
          return value;
        }

        return a;
      });

      return;
    }
    // this._gc.UpdateSubscription(value.Id);
    // this._gcNew.Add([value.Id]);
    this.printers.push(value);
  }
  @Mutation
  DeletePrinterFromModule(value: Printer) {
    // this._gc.DeleteSubscription(value.Id);
    // this._gcNew.Remove([value.Id]);
    this.printers.delete(value);

    console.log('Printers: ' + this.printers.length);
  }
  @Mutation
  UpdatePrinterName([printer, name]: [Printer, string]) {
    printer.Name = name;
  }
  @Mutation
  UpdatePrinterState([printer, status]: [Printer, PrinterState]) {
    printer.PrinterState = status;
  }
  @Mutation
  UpdatePrinterModel([printer, modelId]: [Printer, Guid]) {
    printer.PrinterModelId = modelId;
  }
  @Mutation
  UpdatePrinterJobCount([printer, newCount]: [Printer, number]) {
    printer.JobCount = newCount;
  }
  @Mutation
  UpdatePrinterQueuedJobCount([printer, newCount]: [Printer, number]) {
    printer.QueuedJobCount = newCount;
  }
  @Mutation
  UpdatePrinterLogsReady([printer, newVal]: [Printer, boolean]) {
    printer.LogsReady = newVal;
  }
  @Mutation
  UpdatePrinterCurrentJob([printer, newId]: [Printer, Guid | null]) {
    printer.CurrentJobId = newId;
  }
  @Mutation
  UpdatePrinterErrorCount([printer, newCount]: [Printer, number]) {
    printer.ErrorCount = newCount;
  }
  @Mutation
  UpdatePrinterLastError([printer, newCount]: [Printer, string]) {
    printer.LastError = newCount;
  }
  @Mutation
  UpdatePrinterLastArchive([printer, newId]: [Printer, Guid]) {
    printer.LastArchiveId = newId;
  }

  @Action({ rawError: true })
  CollectPrinters() {
    const removed = this._gcNew.Collect();

    console.log(`Removing ${removed.length} printers`);

    for (const printer of this.printers.filter(a => GuidHelper.includes(removed, a.Id))) {
      this.DeletePrinterFromModule(printer);
    }
  }

  @Action({ rawError: true })
  OccupyPrinters([printers, componentId]: [Printer[], Guid]) {
    console.log('Occupy ' + printers.length + ' printers by ' + componentId.toString());

    this._gcNew.Occupy(
      printers.map(a => a.Id),
      componentId,
    );
  }

  @Action({ rawError: true })
  LoosePrintersNew([printers, componentId]: [Printer[], Guid]) {
    console.log('Loose ' + printers.length + ' printers by ' + componentId.toString());

    this._gcNew.Loose(
      printers.map(a => a.Id),
      componentId,
    );
  }

  @Action({ rawError: true })
  async LoadPrinterPage(data: LoadPrinterPageData) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.PRINTER,
      routes.UriPrinter.READ_PAGED,
    );
    const request = new RequestReadPrinterPage();
    request.companyId = data.companyId.toString();
    request.page = data.page;
    request.pageSize = 25;
    request.sortBy = data.sortBy;
    request.sortMode = data.sortMode;

    this._gcNew.Lock();

    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    let printers: Printer[] = [];
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealPrinters(axiosResponse.data.response as ResponsePrinters);
      printers = response.printers.map(a => a.Map());

      for (const printer of printers) {
        this.AddPrinterToModule(printer);
      }
    } else {
      ErrorModule.ShowError(message);
    }

    Vue.nextTick(() => {
      this._gcNew.Unlock();
    });

    return printers;
  }
  @Action({ rawError: true })
  async GetPrinter(printerId: Guid): Promise<Printer | null> {
    const inLst = this.Printers.singleOrDefault(a => a.Id.equals(printerId));
    if (inLst) return inLst;
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.PRINTER,
      routes.UriPrinter.READ_BY_ID,
    );
    const request = new RequestReadPrinterById();
    request.id = printerId.toString();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealPrinter(axiosResponse.data.response as ResponsePrinter);
      const printer = response.Map();
      this.AddPrinterToModule(printer);
      return printer;
    } else {
      ErrorModule.ShowError(message);
    }
    return null;
  }
  @Action({ rawError: true })
  async LoadPrinters(ids: Guid[]): Promise<Printer[]> {
    const toAdd = GuidHelper.except(
      ids,
      this.Printers.map(a => a.Id),
    );
    if (toAdd.any()) {
      const printers = await this.JustLoadPrinters(ids);
      for (const item of printers) {
        this.AddPrinterToModule(item);
      }
    }
    return this.Printers.filter(a => GuidHelper.includes(ids, a.Id));
  }
  @Action({ rawError: true })
  async JustLoadPrinters(ids: Guid[]): Promise<Printer[]> {
    if (ids.empty()) return [];
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.PRINTER,
      routes.UriPrinter.READ_BY_IDS,
    );
    const request = new RequestReadPrintersByIds();
    request.ids = ids.map(a => a.toString());
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealPrinters(axiosResponse.data.response as ResponsePrinters);
      return response.printers.map(a => a.Map());
    } else ErrorModule.ShowError(message);
    return [];
  }
  @Action({ rawError: true })
  async LoadPrintersForCompanyAll(companyId?: Guid): Promise<Printer[]> {
    const printers = await this.JustLoadPrintersForCompanyAll(companyId);
    const responseIds = printers.map(a => a.Id);
    const inLst = this.Printers.filter(a => GuidHelper.includes(responseIds, a.Id));
    const inLstIds = inLst.map(a => a.Id);
    const toAdd = printers.filter(a => !GuidHelper.includes(inLstIds, a.Id));
    for (const item of toAdd) {
      this.AddPrinterToModule(item);
    }
    return this.Printers.filter(a => GuidHelper.includes(responseIds, a.Id));
  }
  @Action({ rawError: true })
  async JustLoadPrintersForCompanyAll(companyId?: Guid): Promise<Printer[]> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.PRINTER,
      routes.UriPrinter.READ_ALL_COMPANY_PRINTERS,
    );
    console.log('Loading printers for company id:', companyId);
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, null);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealPrinters(axiosResponse.data.response as ResponsePrinters);
      return response.printers.map(a => a.Map());
    } else ErrorModule.ShowError(message);
    return [];
  }
  @Action({ rawError: true })
  async AddNewPrinter([printerModelId, name, description]: [Guid, string, string?]): Promise<Printer | null> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.PRINTER,
      routes.UriPrinter.CREATE,
    );
    const request = new RequestCreatePrinter();
    request.printerModelId = printerModelId.toString();
    request.name = name;
    if (description !== undefined) request.description = description;
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealPrinter(axiosResponse.data.response as ResponsePrinter);
      const newPrinter = response.Map();
      this.AddPrinterToModule(newPrinter);
      return newPrinter;
    } else ErrorModule.ShowError(message);
    return null;
  }
  @Action({ rawError: true })
  async ReadAllConnectionTypes(): Promise<ConnectionType[]> {
    if (this.connectionTypes.length != 0) {
      return this.connectionTypes;
    }

    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.CONNECTOR,
      routes.UriController.CONNECTION,
      routes.UriConnection.READ_CONNECTION_TYPES,
    );
    const request = new RequestReadConnectionTypes();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseConnectorHelper.createRealConnectionTypes(
        axiosResponse.data.response as ResponseConnectionTypes,
      );
      const conTypes = response.connectionTypes.map(a => a.Map());

      for (const type of conTypes) {
        this.AddConnectionTypeToModule(type);
      }

      return conTypes;
    } else ErrorModule.ShowError(message);
    return [];
  }
  @Action({ rawError: true })
  async CreateConnection([connectionTypeId, printerId, clientId]: [Guid, Guid, string]): Promise<string | null> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.CONNECTOR,
      routes.UriController.CONNECTION,
      routes.UriConnection.CREATE,
    );
    const request = new RequestCreateConnection();
    request.connectionTypeId = connectionTypeId.toString();
    request.printerId = printerId.toString();
    request.clientId = clientId;
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseConnectorHelper.createRealConnectionWithCode(
        axiosResponse.data.response as ResponseConnectionWithCode,
      );
      return response.code;
    } else ErrorModule.ShowError(message);
    return null;
  }
  @Action({ rawError: true })
  async UpdateConnection(printerId: Guid): Promise<string> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.CONNECTOR,
      routes.UriController.CONNECTION,
      routes.UriConnection.UPDATE,
    );
    const request = new RequestUpdateConenction();
    request.printerId = printerId.toString();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseConnectorHelper.createRealConnectionWithCode(
        axiosResponse.data.response as ResponseConnectionWithCode,
      );
      return response.code;
    } else ErrorModule.ShowError(message);
    return '';
  }
  @Action({ rawError: true })
  async DeletePrinter(id: Guid): Promise<[RequestStatus, string]> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.PRINTER,
      routes.UriPrinter.DELETE,
    );
    const request = new RequestDeletePrinter();
    request.id = id.toString();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealPrinterId(axiosResponse.data.response as ResponsePrinterId);
      const printerInModule = this.Printers.singleOrDefault(a => a.Id.equals(response.id));
      if (printerInModule != null) this.DeletePrinterFromModule(printerInModule);
    }
    return [result, message];
  }
  @Action({ rawError: true })
  async UpdatePrinterNaming([id, name, printerModelId, description]: [
    Guid,
    string,
    Guid?,
    string?,
  ]): Promise<Printer | null> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.PRINTER,
      routes.UriPrinter.UPDATE_NAMING,
    );
    const request = new RequestUpdatePrinterNaming();
    request.id = id.toString();
    request.name = name;
    if (printerModelId !== undefined) request.printerModelId = printerModelId.toString();
    if (description !== undefined) request.description = description;
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealPrinter(axiosResponse.data.response as ResponsePrinter);
      const obj = response.Map();
      const printer = this.Printers.singleOrDefault(a => a.Id.equals(obj.Id));
      if (printer == null) this.AddPrinterToModule(obj);
      else {
        printer.Name = obj.Name;
        printer.Description = obj.Description;
        printer.PrinterModelId = obj.PrinterModelId;
      }
      return printer;
    } else ErrorModule.ShowError(message);
    return null;
  }
  @Action({ rawError: true })
  async LoadAllPrinterModels(): Promise<PrinterModel[]> {
    if (this.printerModels.length != 0) {
      return this.printerModels;
    }

    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.PRINTER,
      routes.UriPrinter.READ_MODEL_ALL,
    );
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, null);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealPrinterModels(
        axiosResponse.data.response as ResponsePrinterModels,
      );
      const printerModels = response.printerModels.map(a => a.Map());

      for (const model of printerModels) {
        this.AddPrinterModelToModule(model);
      }

      return printerModels;
    } else ErrorModule.ShowError(message);
    return [];
  }
  @Action({ rawError: true })
  PrintersSync([printers, componentId]: [Printer[], Guid]) {
    this._gc.Occupy(
      printers.map(a => a.Id),
      componentId,
    );
  }
  @Action({ rawError: true })
  async LoosePrinters([printerIds, componentId]: [Guid[], Guid]) {
    const toDeletePrinterIds = await this._gc.Loose(printerIds, componentId);
    const printersToDelete = this.Printers.filter(a => GuidHelper.includes(toDeletePrinterIds, a.Id));
    for (const printer of printersToDelete) {
      this.DeletePrinterFromModule(printer);
    }
  }
  @Action({ rawError: true })
  async PrinterSubscribe(hubConnection: HubConnection) {
    hubConnection?.on(hubActions.RECEIVE_CREATE_PRINTER, (response: ResponsePrinter) => {
      const realPrinter = ResponseMonolithHelper.createRealPrinter(response);
      if (this.Printers.find(a => a.Id.equals(realPrinter.id))) return;
      this.AddPrinterToModule(realPrinter.Map());
    });
    // Todo: RECEIVE UPDATE PRINTER ERROR COUNT
    hubConnection?.on(
      hubActions.RECEIVE_UPDATE_PRINTER_ERROR_COUNT,
      async (response: ResponsePrinterUpdateErrorCount) => {
        const realRes = ResponseMonolithHelper.createRealPrinterUpdateErrorCount(response);
        const printer = this.printers.firstOrDefault(a => a.Id.toString() == realRes.id.toString());
        if (printer == null || printer.ErrorCount == realRes.count) return;
        this.UpdatePrinterErrorCount([printer, realRes.count]);
      },
    );
    // Todo: RECEIVE UPDATE PRINTER LAST ERROR
    hubConnection?.on(
      hubActions.RECEIVE_UPDATE_PRINTER_LAST_ERROR,
      async (response: ResponsePrinterUpdateLastError) => {
        const realRes = ResponseMonolithHelper.createRealPrinterUpdateLastError(response);
        const printer = this.printers.firstOrDefault(a => a.Id.toString() == realRes.id.toString());
        if (printer == null || printer.LastError == realRes.lastError) return;
        this.UpdatePrinterLastError([printer, realRes.lastError]);
      },
    );
    hubConnection?.on(
      hubActions.RECEIVE_UPDATE_PRINTER_LAST_ARCHIVE,
      async (response: ResponsePrinterUpdateLastArchive) => {
        const realRes = ResponseMonolithHelper.createRealPrinterUpdateLastArchive(response);
        const printer = this.printers.firstOrDefault(a => a.Id.toString() == realRes.id.toString());
        if (printer == null || printer.LastArchiveId?.toString() == realRes.archiveId.toString()) return;
        this.UpdatePrinterLastArchive([printer, realRes.archiveId]);
      },
    );
    hubConnection?.on(
      hubActions.RECEIVE_UPDATE_PRINTER_CURRENT_JOB,
      async (response: ResponsePrinterUpdateCurrentJob) => {
        const realRes = ResponseMonolithHelper.createRealPrinterUpdateCurrentJob(response);
        const printer = this.printers.firstOrDefault(a => a.Id.toString() == realRes.id.toString());
        if (printer == null || printer.CurrentJobId?.toString() == realRes.currentJobId.toString()) return;
        this.UpdatePrinterCurrentJob([printer, realRes.currentJobId]);
      },
    );
    hubConnection?.on(hubActions.RECEIVE_UPDATE_PRINTER, (response: ResponsePrinter) => {
      const realPrinter = ResponseMonolithHelper.createRealPrinter(response);
      const printer = this.Printers.firstOrDefault(a => a.Id.equals(realPrinter.id));
      if (printer == null || !realPrinter.name) return;
      if (printer.Name !== realPrinter.name) this.UpdatePrinterName([printer, realPrinter.name]);
      if (
        (printer.UserId && !realPrinter.creatorId) ||
        (!printer.UserId && realPrinter.creatorId) ||
        (printer.UserId && realPrinter.creatorId && !printer.UserId.equals(realPrinter.creatorId))
      ) {
        Vue.set(printer, 'CreatorId', realPrinter.creatorId);
      }
      if (!printer.PrinterModelId.equals(realPrinter.printerModelId)) {
        this.UpdatePrinterModel([printer, realPrinter.printerModelId]);
      }
      if (printer.JobCount != realPrinter.jobCount) {
        this.UpdatePrinterJobCount([printer, realPrinter.jobCount]);
      }
      if (printer.QueuedJobCount != realPrinter.queuedJobCount) {
        this.UpdatePrinterQueuedJobCount([printer, realPrinter.queuedJobCount]);
      }
      if (printer.LogsReady != realPrinter.logsReady) {
        this.UpdatePrinterLogsReady([printer, realPrinter.logsReady]);
      }
    });
    hubConnection?.on(hubActions.RECEIVE_UPDATE_PRINTER_STATUS, (response: ResponsePrinterState) => {
      const realPrinterState = ResponseMonolithHelper.createRealPrinterState(response);
      const printer = this.Printers.firstOrDefault(a => a.Id.equals(realPrinterState.id));
      if (printer == null) return;
      if (printer.PrinterState !== realPrinterState.printerState)
        this.UpdatePrinterState([printer, realPrinterState.printerState]);
      this.onPrinterStatusUpdated.dispatch(printer);
    });
    hubConnection?.on(hubActions.RECEIVE_DELETE_PRINTER, (response: ResponsePrinterId) => {
      const realPrinterId = ResponseMonolithHelper.createRealPrinterId(response);
      const printer = this.Printers.firstOrDefault(a => a.Id.equals(realPrinterId.id));
      if (printer == null) return;
      this.DeletePrinterFromModule(printer);
    });
  }
  @Action({ rawError: true })
  async SubscribeToCompanyPrintersGroup() {
    if (LoginModule.MonolithConnection == null) return;
    await LoginModule.MonolithConnection.send(hubActions.ADD_TO_COMPANY_PRINTERS_GROUP);
  }
  @Action({ rawError: true })
  async DeleteFromCompanyPrintersGroup() {
    if (LoginModule.MonolithConnection == null) return;
    await LoginModule.MonolithConnection.send(hubActions.DELETE_FROM_COMPANY_PRINTERS_GROUP);
  }
}

export const PrinterModule = getModule(printerModule);
