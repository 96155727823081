export enum TelemetryType {
  // Температура стола
  BuildPlateTemperature,
  // Температура камеры
  ChamberTemperature,
  // Температура печатных головок
  PrintHeadTemperature,
  // Скорость вентиляторов
  FanSpeed,
  // Кол-во поданного материала (extruder positions)
  MaterialUsed,
  // Координаты печатной головки
  PrintHeadPosition,
  // Текущая печатная головка
  CurrentTool,
}
