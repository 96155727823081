<template>
  <div class="stp-main d-flex flex-even">
    <div class="d-flex stp-items flex-row w-100">
      <div :class="ValueClassName">
        <span>{{ ValueFormatted }}</span>
      </div>
      <div class="dimension-status d-flex flex-column flex-grow-1">
        <div class="w-100 stp-dimention">{{ dimension }}</div>
        <div class="w-100 stp-status">/{{ status }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class StatPanel extends Vue {
  @Prop({ type: Number, default: 0 }) value!: number | null;
  @Prop({ type: String, default: '' }) dimension!: string;
  @Prop({ type: String, default: '' }) status!: string;

  private get ValueFormatted(): string {
    if (this.value === null) return '';

    return parseFloat(this.value.toFixed(2)).toString();
  }

  private get ValueClassName(): string {
    let classname = 'stp-value ';
    if (this.value) {
      if (this.ValueFormatted.length <= 4) classname += 'stp-value-1';
      else {
        if (this.ValueFormatted.length <= 5) classname += 'stp-value-2';
        else {
          if (this.ValueFormatted.length <= 6) classname += 'stp-value-3';
        }
      }
    } else classname += 'stp-value-1';
    return classname;
  }
}
</script>

<style scoped>
div.stp-main {
  height: 80px;
  min-width: 235px;
  background-color: var(--action-card-background);
  border: var(--action-card-border) solid 1px;
  border-radius: 6px;
  overflow: hidden;
  font-family: 'Roboto';
}
.stp-items {
  margin: auto 20px auto 20px;
}
.stp-value {
  color: #dbdbdb;
  margin-right: 8px;
}
.stp-value-1 {
  line-height: 38px;
  font-size: 38px;
}
.stp-value-2 {
  line-height: 28px;
  font-size: 28px;
}
.stp-value-3 {
  line-height: 24px;
  font-size: 24px;
}
div.stp-dimention {
  margin-top: 2px;
  font-size: 13px;
  line-height: 13px;
  text-align: left;
  color: #bbbbbb;
}
div.stp-status {
  margin-top: 2px;
  font-size: 13px;
  line-height: 13px;
  font-weight: 600;
  text-align: left;
  color: #bbbbbb;
}
.dimension-status {
  justify-content: space-between;
  padding: 3px 0;
}
</style>
