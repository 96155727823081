import axios, { AxiosResponse } from 'axios';
import * as signalR from '@microsoft/signalr';
import { POSITION } from 'vue-toastification';

import BaseResponse from '@/models/responses/BaseResponse';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { getAuraUrl } from '@/util/env';
import * as ips from '@/util/api/ips';
import { toast } from '@/main';

export class StoreHelperWrapper {
  async ProcessPost(
    uri: string,
    request: any,
  ): Promise<{ result: RequestStatus; message: string; axiosResponse: AxiosResponse<BaseResponse> | null }> {
    return await StoreHelper.ProcessPost(uri, request);
  }
  async ProcessGet(
    uri: string,
    request: any,
  ): Promise<{ result: RequestStatus; message: string; axiosResponse: AxiosResponse<BaseResponse> | null }> {
    return await StoreHelper.ProcessGet(uri, request);
  }
  async ProcessPostFile(
    uri: string,
    formData: FormData,
  ): Promise<{ result: RequestStatus; message: string; axiosResponse: AxiosResponse<BaseResponse> | null }> {
    return await StoreHelper.ProcessPostFile(uri, formData);
  }
  async ProcessDownloadFile(uri: string, request: any, onProgress?: (loaded: number, total: number) => void) {
    return await StoreHelper.ProcessDownloadFile(uri, request, onProgress);
  }

  GetUri(protocol: string, ip: string, port: string, ...params: string[]): string {
    return StoreHelper.GetUri(protocol, ip, port, ...params);
  }

  getUrl(...params: string[]) {
    return StoreHelper.GetUri(ips.PROTOCOL(), ips.IP(), ips.PORT(), ...params);
  }

  AddTimeoutAction(window: Window & typeof globalThis, handler: TimerHandler, timeout: number): number {
    return StoreHelper.AddTimeoutAction(window, handler, timeout);
  }

  ClearTimeout(window: Window & typeof globalThis, timerId: number) {
    StoreHelper.ClearTimeout(window, timerId);
  }

  GetTimeoutToUpdateToken(validBefore: Date, secondOffset: number): number {
    return StoreHelper.GetTimeoutToUpdateToken(validBefore, secondOffset);
  }

  GetTimeoutToReconnect(penalties: Record<ReconnectType, number>, attempt: ReconnectType): number {
    return StoreHelper.GetTimeoutToReconnect(penalties, attempt);
  }

  CreateConnection(uri: string, token: string, logLevel?: signalR.LogLevel): signalR.HubConnection {
    return StoreHelper.CreateConnection(uri, token, logLevel);
  }

  ReplaceToken(oldUri: string, newToken: string): string {
    return StoreHelper.ReplaceToken(oldUri, newToken);
  }
}

export class StoreHelper {
  static GetUri(protocol: string, ip: string, port: string, ...params: string[]): string {
    let res = `${protocol}://${ip}:${port}/api`;
    for (const item of params) {
      res += '/' + item;
    }
    return res;
  }

  static ParseSuccessResponse(response: AxiosResponse<BaseResponse>): { result: RequestStatus; message: string } {
    if (response == null) return { result: RequestStatus.INTERNAL_SERVER_ERROR, message: '' };
    const statusResponse = this.GetError(response?.status);
    switch (statusResponse) {
      case ErrorCode.E204: {
        return { result: RequestStatus.EMPTY_RESPONSE, message: '' };
      }
    }
    if (response.data.success) {
      if (response.data.errorMessage === 'Operation cancelled')
        return { result: RequestStatus.CANCELLED, message: response.data.errorMessage };
      return { result: RequestStatus.OK, message: '' };
    } else {
      if (response.data.errorMessage === "Can't read field")
        return { result: RequestStatus.OPERATION_NOT_COMPLETE, message: response.data.errorMessage };
    }
    return { result: RequestStatus.INTERNAL_SERVER_ERROR, message: response.data.errorMessage };
  }

  static GetError(value: string | number): ErrorCode {
    if (value.toString() === '204') return ErrorCode.E204;
    if (value.toString() === '400') return ErrorCode.E400;
    if (value.toString() === '500') return ErrorCode.E500;
    return ErrorCode.OTHER;
  }

  static ParseErrorResponse(error: any): { result: RequestStatus; message: string } {
    let message = error.message;
    if ('response' in error && error.response !== undefined) {
      const errorCodeStr = <string>error.response?.status;
      const statusResponse = this.GetError(errorCodeStr);
      let result: RequestStatus;
      switch (statusResponse) {
        case ErrorCode.E400: {
          result = RequestStatus.BAD_REQUEST;
          if ('data' in error.response) {
            if (error.response?.data?.errors) {
              const key = Object.keys(error.response.data.errors)[0];
              if (key !== undefined) {
                message = error.response.data.errors[key][0];
              }
            }
          }
          break;
        }
        case ErrorCode.E500: {
          result = RequestStatus.INTERNAL_SERVER_ERROR;
          break;
        }
        default: {
          if (Number.parseInt(errorCodeStr) >= 300) result = RequestStatus.INTERNAL_SERVER_ERROR;
          else result = RequestStatus.OK;
          break;
        }
      }
      return { result: result, message: message };
    }
    return { result: RequestStatus.CANT_REACH_SERVER, message: message };
  }

  static async ProcessPost(
    uri: string,
    request: any,
  ): Promise<{ result: RequestStatus; message: string; axiosResponse: AxiosResponse<BaseResponse> | null }> {
    try {
      const response = await axios.post<BaseResponse>(uri, request);
      const { result, message } = this.ParseSuccessResponse(response);
      return { result: result, message: message, axiosResponse: response };
    } catch (error) {
      const { result, message } = this.ParseErrorResponse(error);
      return { result: result, message: message, axiosResponse: null };
    }
  }

  static async ProcessGet(
    uri: string,
    request: any,
  ): Promise<{ result: RequestStatus; message: string; axiosResponse: AxiosResponse<BaseResponse> | null }> {
    try {
      const response = await axios.get<BaseResponse>(uri, request);
      const { result, message } = this.ParseSuccessResponse(response);
      return { result: result, message: message, axiosResponse: response };
    } catch (error) {
      const { result, message } = this.ParseErrorResponse(error);
      return { result: result, message: message, axiosResponse: null };
    }
  }

  static async ProcessPostFile(
    uri: string,
    formData: FormData,
  ): Promise<{ result: RequestStatus; message: string; axiosResponse: AxiosResponse<BaseResponse> | null }> {
    try {
      const response = await axios.post<BaseResponse>(uri, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const { result, message } = this.ParseSuccessResponse(response);
      return { result: result, message: message, axiosResponse: response };
    } catch (error) {
      const { result, message } = this.ParseErrorResponse(error);
      return { result: result, message: message, axiosResponse: null };
    }
  }

  static async ProcessDownloadFile(uri: string, request: any, onProgress?: (loaded: number, total: number) => void) {
    try {
      const res = await axios.post(uri, request, {
        responseType: 'blob',
        onDownloadProgress: progress => {
          if (onProgress) {
            onProgress(progress.loaded, progress.total!);
          }
        },
      });

      const filenameRegex = '.*filename=(.*);';
      const dispositionHeader = res.headers['content-disposition'];

      const matches = dispositionHeader?.match(filenameRegex)!;

      let fileName = 'unknown.file';

      if (matches?.length > 0) {
        fileName = matches[1];
      }

      if (fileName.charAt(0) == '"' && fileName.charAt(fileName.length - 1) == '"') {
        fileName = fileName.substring(1, fileName.length - 1);
      }

      return { blob: new Blob([res.data]), fileName: fileName };
    } catch (error) {
      toast.error('An error occurred while downloading the file', {
        position: POSITION.BOTTOM_RIGHT,
      });
      console.error(error);
    }
  }

  static AddTimeoutAction(window: Window & typeof globalThis, handler: TimerHandler, timeout: number): number {
    return setTimeout(handler, timeout);
  }

  static ClearTimeout(window: Window & typeof globalThis, timerId: number) {
    if (!timerId || timerId === -1) return;
    window.clearTimeout(timerId);
  }

  static GetTimeoutToReconnect(penalties: Record<ReconnectType, number>, attempt: ReconnectType): number {
    const timeout = penalties[attempt];
    console.log(attempt);
    console.log(timeout);
    return timeout;
  }

  static GetTimeoutToUpdateToken(validBefore: Date, secondOffset: number): number {
    const thirtySeconds = secondOffset * 1000;
    return validBefore.getTime() - Date.now() - thirtySeconds;
  }

  static CreateConnection(uri: string, token: string, logLevel?: signalR.LogLevel): signalR.HubConnection {
    const fullUri = `${uri}`;
    return new signalR.HubConnectionBuilder()
      .withUrl(fullUri, {
        accessTokenFactory: () => token,
        transport: signalR.HttpTransportType.WebSockets,
        skipNegotiation: false,
      })
      .configureLogging(logLevel !== undefined ? logLevel : signalR.LogLevel.Error)
      .withAutomaticReconnect()
      .build();
  }

  static ReplaceToken(oldUri: string, newToken: string): string {
    const index = oldUri.indexOf('access_token');
    return `${oldUri.substring(0, index - 1)}access_token=${newToken}`;
  }

  static DownloadBlob(blob: Blob, fileName: string) {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  static redirectToAuraApp(path = '') {
    const auraUrl = getAuraUrl();
    if (auraUrl) {
      window.location.href = auraUrl + (path === '/' ? '' : path);
    }
  }
}

export type ReconnectType = '0' | '1' | '2' | '3' | 'more';

enum ErrorCode {
  E400,
  E500,
  E204,
  OTHER,
}
