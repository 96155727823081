<template>
  <AuraDialogWrapper
    ref="dialogWrapper"
    headerText="Select a printer"
    @closed="DialogWrapperClosed"
    @dismissed="DialogWrapperClosed"
  >
    <div class="content thin-scroll mb-2" style="max-height: 50vh; overflow: auto">
      <SortableHeader
        :header-items="PrinterHeaders"
        :by.sync="SortByPrinterHeader"
        :mode.sync="PrinterSortMode"
        test-id="addSourceToQueueDialog"
        @sortBy="OnPrinterSortBy"
      />
      <div class="overflow-auto thin-scroll">
        <ListItemV3
          v-for="item in Printers"
          :id="item.Id"
          :key="item.Id.toString()"
          mainClass="cur-pointer"
          test-id="addSourceToQueueDialog"
          :items="PrinterItems(item)"
          :selected="IsPrinterSelected(item)"
          @openItem="PrinterSelected(item)"
        />
      </div>
    </div>

    <button
      data-testid="addSourceToQueueDialog.confirmButton"
      :class="['mt-3 w-100', selectedPrinter == null ? 'disabled' : '']"
      @click="ConfirmClicked"
    >
      Confirm
    </button>
  </AuraDialogWrapper>
</template>

<script lang="ts">
import { Component, Prop, Ref } from 'vue-property-decorator';
import { DialogComponent } from 'vue-modal-dialogs';

import SortableHeader from '@/components/presentation/SortableHeader.vue';
import ListItemV3 from '@/components/presentation/ListItemV3.vue';

import ComponentHelper, { HeaderItem, ItemData, SortMode } from '@/util/ComponentHelper';

import { Printer, Source } from '@/models/Entities';
import { LoadPrinterPageData, PrinterModule } from '@/store/modules/printerModule';
import { Guid } from 'guid-typescript';
import { LoginModule } from '@/store/modules/loginModule';
import { PrinterSortBy } from '@/models/requests/RequestMonolith';
import { JobModule } from '@/store/modules/jobModule';
import { toast } from '@/main';
import { POSITION } from 'vue-toastification';
import AuraDialogWrapper from '@/views/dialogs/base/AuraDialogWrapper.vue';

import {
  SortPrinterByPrinterNameDESC,
  SortPrinterByPrinterNameASC,
  SortPrinterByPrinterModelDESC,
  SortPrinterByPrinterModelASC,
  SortPrinterByStatusDESC,
  SortPrinterByStatusASC,
  SortPrinterByJobCountDESC,
  SortPrinterByJobCountASC,
  SortPrinterByIdDESC,
  SortPrinterByQueuedJobCountASC,
  SortPrinterByQueuedJobCountDESC,
} from '@/models/util/PrinterSortings';

export const PrintersPageSize = 20;

@Component({
  components: {
    SortableHeader: SortableHeader,
    ListItemV3: ListItemV3,
    AuraDialogWrapper: AuraDialogWrapper,
  },
})
export default class AddSourceToQueueDialog extends DialogComponent<boolean | null> {
  @Ref() dialogWrapper!: AuraDialogWrapper;

  private componentId!: Guid;

  @Prop() private source!: Source;

  private selectedPrinter: Printer | null = null;

  private get Printers() {
    const currentPrinters = this.SortPrinters(PrinterModule.Printers.map(a => a));

    const maxCount = (this.printerPage + 1) * PrintersPageSize;

    const toDisplay = currentPrinters.slice(0, maxCount);
    const toLoose = currentPrinters.slice(maxCount);

    // InvoiceModule.OccupyInvoices([currentInvoices, this._componentId]);
    PrinterModule.LoosePrintersNew([toLoose, this.componentId]);
    PrinterModule.OccupyPrinters([toDisplay, this.componentId]);

    if (toLoose.length > 0) {
      PrinterModule.CollectPrinters();
    }

    return toDisplay;
  }

  private sortByPrinterHeader: HeaderItem | null = null;
  private printerSortMode: SortMode | null = null;
  private printerPage: number = 0;

  private isLoadingPrinters: boolean = false;

  private get SortByPrinterHeader(): HeaderItem | null {
    return this.sortByPrinterHeader;
  }
  private set SortByPrinterHeader(value: HeaderItem | null) {
    this.sortByPrinterHeader = value;
  }
  private get PrinterSortMode(): SortMode | null {
    return this.printerSortMode;
  }
  private set PrinterSortMode(value: SortMode | null) {
    this.printerSortMode = value;
  }

  private headerPrinterName: HeaderItem = {
    caption: 'Name',
    itemClass: ComponentHelper.GetWidthClass(),
    isSortable: true,
  };
  private headerPrinterJobCount: HeaderItem = {
    caption: 'Jobs queued',
    itemClass: ComponentHelper.GetWidthClass(125),
    isSortable: true,
  };
  private headerPrinterStatus: HeaderItem = {
    caption: 'Status',
    itemClass: ComponentHelper.GetWidthClass(125),
    isSortable: true,
  };

  private get PrinterHeaders(): HeaderItem[] {
    let headerItems: HeaderItem[] = [];
    headerItems.push(this.headerPrinterName);
    headerItems.push(this.headerPrinterJobCount);
    headerItems.push(this.headerPrinterStatus);
    return headerItems;
  }

  private PrinterItems(value: Printer): ItemData[] {
    let items: ItemData[] = [];
    let name: ItemData = new ItemData('Name', value.Name, 'grow-1');

    let jobCount: ItemData = new ItemData('JobsCount', value.QueuedJobCount.toString(), 125);

    let status: ItemData = new ItemData('Status', ComponentHelper.GetPrinterStateReadableText(value.PrinterState), 125);

    items.push(name);
    items.push(jobCount);
    items.push(status);
    return items;
  }

  private async PrinterSelected(printer: Printer) {
    this.selectedPrinter = printer;
  }

  private IsPrinterSelected(printer: Printer) {
    if (this.selectedPrinter == null) return false;

    return this.selectedPrinter.Id.toString() == printer.Id.toString();
  }

  private async OnPrinterSortBy() {
    PrinterModule.LoosePrintersNew([this.Printers, this.componentId]);
    PrinterModule.CollectPrinters();
    this.printerPage = 0;

    await this.LoadMorePrinters();
  }

  private async LoadMorePrinters() {
    await PrinterModule.LoadPrinterPage(this.GetPrinterLoadData());
  }

  private GetPrinterLoadData(): LoadPrinterPageData {
    return {
      companyId: LoginModule.Me!.CompanyId,
      sortBy: this.GetPrinterSortBy(this.SortByPrinterHeader),
      sortMode: this.PrinterSortMode,
      page: this.printerPage,
    };
  }

  private SortPrinters(printers: Printer[]): Printer[] {
    let sortBy = this.SortByPrinterHeader == null ? undefined : this.GetPrinterSortBy(this.SortByPrinterHeader);
    if (
      (this.PrinterSortMode == null && sortBy !== undefined) ||
      (this.PrinterSortMode != null && sortBy === undefined) ||
      printers.length == 0
    )
      return printers;

    if (sortBy === PrinterSortBy.BY_PRINTER_NAME) {
      if (this.PrinterSortMode === SortMode.ASC)
        return printers.sort(SortPrinterByIdDESC).sort(SortPrinterByPrinterNameASC);
      if (this.PrinterSortMode === SortMode.DESC)
        return printers.sort(SortPrinterByIdDESC).sort(SortPrinterByPrinterNameDESC);
    } else if (sortBy === PrinterSortBy.BY_PRINTER_MODEL) {
      if (this.PrinterSortMode === SortMode.ASC)
        return printers.sort(SortPrinterByIdDESC).sort(SortPrinterByPrinterModelASC);
      if (this.PrinterSortMode === SortMode.DESC)
        return printers.sort(SortPrinterByIdDESC).sort(SortPrinterByPrinterModelDESC);
    } else if (sortBy === PrinterSortBy.BY_STATUS) {
      if (this.PrinterSortMode === SortMode.ASC) return printers.sort(SortPrinterByIdDESC).sort(SortPrinterByStatusASC);
      if (this.PrinterSortMode === SortMode.DESC)
        return printers.sort(SortPrinterByIdDESC).sort(SortPrinterByStatusDESC);
    } else if (sortBy === PrinterSortBy.BY_JOB_COUNT) {
      if (this.PrinterSortMode === SortMode.ASC)
        return printers.sort(SortPrinterByIdDESC).sort(SortPrinterByJobCountASC);
      if (this.PrinterSortMode === SortMode.DESC)
        return printers.sort(SortPrinterByIdDESC).sort(SortPrinterByJobCountDESC);
    } else if (sortBy === PrinterSortBy.BY_QUEUED_JOB_COUNT) {
      if (this.PrinterSortMode === SortMode.ASC)
        return printers.sort(SortPrinterByIdDESC).sort(SortPrinterByQueuedJobCountASC);
      if (this.PrinterSortMode === SortMode.DESC)
        return printers.sort(SortPrinterByIdDESC).sort(SortPrinterByQueuedJobCountDESC);
    }

    return printers;
  }

  private GetPrinterSortBy(headerItem: HeaderItem | null): PrinterSortBy | null {
    if (headerItem === this.headerPrinterName) return PrinterSortBy.BY_PRINTER_NAME;
    else if (headerItem === this.headerPrinterStatus) return PrinterSortBy.BY_STATUS;
    else if (headerItem == this.headerPrinterJobCount) return PrinterSortBy.BY_JOB_COUNT;

    return null;
  }

  private async ConfirmClicked() {
    if (this.selectedPrinter == null) return;

    const result = await JobModule.StartNewJob([this.source.Id, this.selectedPrinter.Id]);

    if (result != null) {
      toast.success(`New job for file ${this.source.CodeFileName} has been successfully queued`, {
        position: POSITION.BOTTOM_RIGHT,
      });

      this.dialogWrapper.CloseDialog();
      this.$close(true);
    }
  }

  private DialogWrapperClosed() {
    this.$close(null);
  }

  async beforeCreate() {
    this.componentId = Guid.create();
  }

  async created() {
    this.PrinterSortMode = SortMode.DESC;
    this.SortByPrinterHeader = this.headerPrinterName;

    await this.LoadMorePrinters();
  }

  async beforeDestroy() {
    PrinterModule.LoosePrintersNew([this.Printers, this.componentId]);
    PrinterModule.CollectPrinters();
  }
}
</script>

<style lang="scss" scoped>
.content {
  min-width: 450px;
  max-width: 600px;
  width: 50vw;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

button {
  border: 1px solid var(--editor-field-value);
  border-radius: 6px;
  font-size: 13px;
  color: var(--editor-field-value);
  background: transparent;
  height: 26px;
}
</style>
