<template>
  <div class="addable-product">
    <HeaderIconButton
      iconName="fa-plus"
      :style="{ opacity: showAddButton ? 1 : 0 }"
      test-id="adminProductEditor.addButton"
      @clicked="showAddButton ? AddButtonClicked() : undefined"
    />

    <div class="product mr-2">
      <select v-model="selectedBaseProduct" data-testid="adminProductEditor.productsSelect">
        <option v-for="item in AvailbaleBaseProducts" :key="item.product.id.toString()" :value="item.product">
          {{ item.product.name }}
        </option>
      </select>

      <div class="addons">
        <div v-for="(item, index) in selectedAddons" :key="index" class="addon mt-2">
          <select v-model="selectedAddons[index]" data-testid="adminProductEditor.addonsSelect" @change="OnChangeAddon">
            <option v-for="addon in AvailableAddons" :key="addon.id.toString()" :value="addon">{{ addon.name }}</option>
          </select>
          <HeaderIconButton
            test-id="adminProductEditor.removeAddon"
            iconName="fa-trash"
            @clicked="RemoveAddonClicked(item)"
          />
        </div>

        <div v-if="GetAddableAddons().length > 0" style="justify-content: space-around" class="addon mt-2">
          <HeaderIconButton test-id="adminProductEditor.addAddon" iconName="fa-plus" @clicked="AddAddonClicked" />
        </div>
      </div>
    </div>

    <div class="aura-key ml-2">
      <input v-model="auraKey" type="text" placeholder="License key" />
    </div>
    <HeaderIconButton iconName="fa-trash" test-id="adminProductEditor.deleteButton" @clicked="DeleteButtonClicked" />
  </div>
</template>

<script lang="ts">
import { Product } from '@/models/Entities';
import { ProductModule, ProductModuleHelper } from '@/store/modules/billing/productModule';
import { PurchasedItemFull } from '@/store/modules/billing/purchasedItemModule';
import { AdminProduct } from '@/views/AdminView.vue';
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import HeaderIconButton from '@/components/buttons/HeaderIconButton.vue';

@Component({
  components: { HeaderIconButton },
  name: 'admin-product-editor',
})
export default class AdminProductEditor extends Vue {
  @Prop() showAddButton!: boolean;
  @Prop() purchasedItemFull!: PurchasedItemFull | null;
  @Prop() adminProduct!: AdminProduct;

  private selectedBaseProduct!: Product;
  private selectedAddons!: Product[];

  private auraKey: string = '';

  @Watch('purchasedItemFull', { deep: true, immediate: true })
  private async PurchasedItemFullChanged() {
    if (this.purchasedItemFull != null) {
      await this.SetStateFromPropPurchasedItem();
    } else {
      this.selectedBaseProduct = this.AvailbaleBaseProducts[0].product;
      this.selectedAddons = [];
      this.auraKey = '';

      this.adminProduct.product = this.BaseProduct;
      this.adminProduct.addons = this.selectedAddons.map(a => a);
      this.adminProduct.auraKey = this.auraKey;
    }
  }

  async updated() {
    this.adminProduct.product = this.BaseProduct;
    this.adminProduct.addons = this.selectedAddons.map(a => a);
    this.adminProduct.auraKey = this.auraKey;

    this.AdminProductUpdated();
  }

  async SetStateFromPropPurchasedItem() {
    if (this.purchasedItemFull) {
      this.selectedBaseProduct = this.purchasedItemFull.product;
      this.selectedAddons = this.purchasedItemFull.addons.map(a => ProductModuleHelper.ProductForPurchasedItem(a)!);
      if (this.purchasedItemFull.item.cryptlexKey != null) {
        this.auraKey = this.purchasedItemFull.item.cryptlexKey!;
      } else {
        this.auraKey = '';
      }
    }
  }

  private async AddAddonClicked() {
    if (this.GetAddableAddons().length == 0) return;

    this.selectedAddons.push(this.GetAddableAddons()[0]);
    this.$forceUpdate();
  }

  private RemoveAddonClicked(product: Product) {
    const found = this.selectedAddons.firstOrDefault(a => a.id.toString() == product.id.toString());

    if (found !== null) {
      this.selectedAddons.delete(found);
      this.$forceUpdate();
    }
  }

  private OnChangeAddon() {
    this.$forceUpdate();
  }

  private get BaseProduct() {
    return this.selectedBaseProduct;
  }

  private get Addons() {
    return this.selectedAddons;
  }

  private get AvailbaleBaseProducts() {
    return ProductModule.ProductsFull.filter(a => a.baseProduct == null);
  }

  private get AvailableAddons() {
    return ProductModule.ProductsFull.filter(
      a =>
        a.baseProduct?.id.toString() == this.selectedBaseProduct.id.toString() &&
        a.prices.firstOrDefault(a => a.recurringInterval !== null) === null,
    ).map(a => a.product);
  }

  private GetAddableAddons() {
    const available = this.AvailableAddons;
    const result: Product[] = [];

    for (const addon of available) {
      const found = this.selectedAddons.firstOrDefault(a => a.id.toString() === addon.id.toString());

      if (found) continue;

      result.push(addon);
    }

    return result;
  }

  @Emit('add-button-clicked')
  private AddButtonClicked() {}

  @Emit('delete-button-clicked')
  private DeleteButtonClicked() {}

  @Emit('admin-product-updated')
  private AdminProductUpdated() {
    return this.adminProduct;
  }
}
</script>

<style lang="scss" scoped>
.addable-product {
  color: white;
  width: 100%;

  i {
    height: fit-content;
  }

  .product {
    flex: 1;
  }

  .aura-key {
    flex: 1;
  }

  .addons {
    display: flex;
    flex-direction: column;

    .addon {
      display: flex;
      width: 100%;
      i {
        margin-top: 8px;
      }
    }
  }

  display: flex;
  width: 100%;

  i {
    margin-top: 8px;
    font-size: 13px;
  }

  select,
  input {
    height: 30px;
    width: 100%;
  }

  input {
    background: none;
    border-radius: 6px;
    border: 1px solid var(--editor-field-border);
    height: 28px;
    color: var(--editor-field-value);
    font-size: 14px;
    padding: 0 10px;
  }

  select {
    background: none;
    border-radius: 6px;
    border: 1px solid var(--editor-field-border);
    height: 28px;
    color: var(--editor-field-value);
    font-size: 14px;
    padding: 0 6px;

    option {
      height: 28px;
      color: var(--editor-field-value);
      background: var(--editor-header-background);
    }
  }
}

i {
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
}
</style>
