import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { HubConnection } from '@microsoft/signalr';
import * as ips from '../../util/api/ips';
import * as routes from '../../util/api/routes';
import { Guid } from 'guid-typescript';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { UtilModule } from './utilModule';
import store from '@/store';
import { Role } from '../../models/Entities';
import '../../models/responses/ResponseIdentityExtensions';
import { ResponseIdentityHelper, ResponseRoles } from '../../models/responses/ResponseIdentity';
import { GuidHelper } from '@/util/GuidHelper';
import { ErrorModule } from '@/store/modules/errorsModule';
@Module({ dynamic: true, name: 'roles', store: store })
export default class roleModule extends VuexModule {
  private roles: Role[] = [];
  get Roles() {
    return this.roles;
  }

  @Mutation
  AddRole(value: Role) {
    if (this.roles.find(a => a.Id === value.Id)) return;
    this.roles.push(value);
  }
  @Mutation
  RemoveRole(value: Role) {
    this.roles.delete(value);
  }
  @Action({ rawError: true })
  async GetRoles(ids: Guid[]): Promise<Role[]> {
    const toAdd = ids.except(this.Roles.map(a => a.Id));
    const roles = this.Roles.filter(a => GuidHelper.includes(ids, a.Id));
    if (toAdd.length > 0) {
      const uri = UtilModule.SHW.GetUri(
        ips.PROTOCOL(),
        ips.IP(),
        ips.PORT(),
        routes.UriServices.IDENTITY,
        routes.UriController.USER,
        routes.UriUser.READ_ALL_ROLES,
      );
      const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, null);
      if (result === RequestStatus.OK && axiosResponse != null) {
        const roles: Role[] = [];
        const response = ResponseIdentityHelper.createRealRoles(axiosResponse.data.response as ResponseRoles);
        for (const item of response.roles) {
          roles.push(item.Map());
        }
      } else ErrorModule.ShowError(message);
    }
    for (const role of roles) {
      this.AddRole(role);
    }
    return this.Roles.filter(a => GuidHelper.includes(ids, a.Id));
  }
  @Action({ rawError: true })
  async LooseRoles(roles: Role[]) {
    for (const role of roles) {
      this.RemoveRole(role);
    }
  }

  @Action({ rawError: true })
  async ReadAllRoles() {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.READ_ALL_ROLES,
    );
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, null);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const roles: Role[] = [];
      const response = ResponseIdentityHelper.createRealRoles(axiosResponse.data.response as ResponseRoles);
      for (const item of response.roles) {
        roles.push(item.Map());
      }
      for (const role of roles) {
        this.AddRole(role);
      }
    } else ErrorModule.ShowError(message);
  }
  @Action({ rawError: true })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  RolesSubscribe(hubConnection: HubConnection) {}
}

export const RoleModule = getModule(roleModule);
