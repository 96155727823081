<template>
  <div :class="['select-input', disabled ? 'disabled' : '']">
    <span
      v-if="label !== undefined"
      class="label mr-2"
      :style="labelWidth !== undefined ? { 'min-width': labelWidth + 'px' } : {}"
      >{{ label }}</span
    >
    <span
      v-if="readonly"
      :class="['value message-text', fillWidth ? 'flex-grow-1' : '', IsClickable ? 'clickable' : '']"
      @click="Clicked"
    >
      {{ GetName(selectedValue) }}
    </span>
    <select
      v-else
      v-model="selectedValue"
      :class="['value', fillWidth ? 'flex-grow-1' : '', invalid ? 'error-border' : '']"
      :data-testid="testId"
    >
      <option v-for="option in options" :key="GetId(option)" :value="option">
        {{ GetName(option) }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, PropSync, Emit } from 'vue-property-decorator';

@Component
export default class SelectInput extends Vue {
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: undefined }) label?: string;
  @Prop({ default: false }) invalid!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: undefined }) labelWidth?: number;
  @Prop({ default: true }) fillWidth!: boolean;
  @Prop({ default: undefined }) testId?: string;

  @Prop() GetId!: (option: any) => string;
  @Prop() GetName!: (option: any) => string;

  @Prop({ default: [] }) options!: any[];
  @PropSync('selectedOption', { default: undefined })
  selectedValue?: any;

  @Emit('clicked')
  Clicked() {}

  private get IsClickable() {
    return this.$listeners.clicked != undefined;
  }
}
</script>

<style lang="scss" scoped>
.select-input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 28px;

  span {
    display: flex;
    font-size: 14px;
    line-height: 16px;

    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    white-space: nowrap;
    color: var(--editor-field-name);

    &.value {
      color: var(--editor-field-value);
      margin-left: 11px;
    }

    &.clickable {
      color: var(--editor-field-value-clickable);
      cursor: pointer;
      &:hover {
        opacity: 0.75;
      }
    }
  }

  select {
    background: none;
    border-radius: 6px;
    border: 1px solid var(--editor-field-border);
    height: 28px;
    color: var(--editor-field-value);
    font-size: 14px;
    padding: 0 6px;

    option {
      height: 28px;
      color: var(--editor-field-value);
      background: var(--editor-header-background);
    }
  }

  .error-border {
    border: 1px solid var(--main-orange);
  }
}
</style>
