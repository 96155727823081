<template>
  <div class="change-password">
    <EditorHeader>
      <template slot="header">
        <span>
          {{ FullName }}
        </span>
        <span class="edit-text"> . {{ ChangePasswordCaption }} </span>
      </template>

      <template slot="buttons">
        <HeaderIconButton iconName="fa-times" test-id="changePassword.closeButton" @clicked="closeButtonClicked" />
      </template>
    </EditorHeader>

    <EditorContent>
      <TextInput
        v-if="IsMe"
        class="mb-2"
        :label="CurrentPasswordCaption"
        :value.sync="currentPassword"
        :labelWidth="labelWidth"
        :invalid="currentPasswordValidationFailed"
        :disabled="isLoading"
        type="password"
        placeholder="Enter current password..."
        test-id="changePassword.currentPasswordInput"
      />

      <TextInput
        class="mb-2"
        :label="EnterPasswordCaption"
        :value.sync="newPassword"
        :labelWidth="labelWidth"
        :invalid="passwordValidationFailed"
        :disabled="isLoading"
        type="password"
        placeholder="Enter new password..."
        test-id="changePassword.newPasswordInput"
      />

      <TextInput
        :label="ConfirmPasswordCaption"
        :value.sync="condfirmPassword"
        :labelWidth="labelWidth"
        :invalid="passwordValidationFailed"
        :disabled="isLoading"
        type="password"
        placeholder="Confirm password..."
        test-id="changePassword.confirmPasswordInput"
      />

      <fade-transition :maxHeight="28">
        <div v-if="validationErrors.length != 0" class="errors-container">
          <span class="mt-2">{{ validationErrors[0] }}</span>
        </div>
      </fade-transition>

      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </EditorContent>

    <EditorFooter>
      <template slot="buttons">
        <IconButton
          class="mr-3"
          :disabled="isLoading"
          iconName="fa-check"
          :text="ApplyCaption"
          test-id="changePassword.applyButton"
          @clicked="applyButtonClicked"
        />
        <IconButton
          :disabled="isLoading"
          iconName="fa-times"
          :iconSize="15"
          :text="CancelCaption"
          test-id="changePassword.cancelButton"
          @clicked="closeButtonClicked"
        />
      </template>
    </EditorFooter>
  </div>
</template>

<script lang="ts">
import en from '@/localization/en';
import { User } from '@/models/Entities';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { LoginModule } from '@/store/modules/loginModule';
import { UserModule } from '@/store/modules/userModule';
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import FadeTransition from '../presentation/FadeTransition.vue';
import EditorContent from '@/components/forms/base/EditorContent.vue';
import EditorFooter from '@/components/forms/base/EditorFooter.vue';
import EditorHeader from '@/components/forms/base/EditorHeader.vue';
import IconButton from '../buttons/IconButton.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import HeaderIconButton from '@/components/buttons/HeaderIconButton.vue';

@Component({
  name: 'change-password',
  components: {
    HeaderIconButton,
    FadeTransition: FadeTransition,
    IconButton: IconButton,
    EditorHeader: EditorHeader,
    EditorContent: EditorContent,
    EditorFooter: EditorFooter,
    TextInput: TextInput,
  },
})
export default class ChangePassword extends Vue {
  labelWidth: number = 150;

  @Prop()
  private user!: User;

  //#region STATE
  private currentPassword: string = '';
  private newPassword: string = '';
  private condfirmPassword: string = '';

  private get IsMe() {
    return this.user.Id.equals(LoginModule.UserId);
  }

  private isLoading: boolean = false;

  private validationTimeoutHandle: number = -1;
  private passwordValidationFailed: boolean = false;
  private currentPasswordValidationFailed: boolean = false;
  private validationErrors: string[] = [];

  private get FullName() {
    return this.user.FirstName + ' ' + this.user.SecondName;
  }
  //#endregion

  //#region LOGIC
  private clearValidationErrors() {
    if (this.validationTimeoutHandle !== -1) {
      clearTimeout(this.validationTimeoutHandle);
    }

    this.validationErrors = [];
    this.passwordValidationFailed = false;
    this.currentPasswordValidationFailed = false;
    this.validationTimeoutHandle = -1;
  }

  private validate(): boolean {
    this.clearValidationErrors();

    // Validate
    if (this.IsMe && (this.currentPassword == null || this.currentPassword.length == 0)) {
      this.validationErrors.push('Current password cannot be empty');
      this.currentPasswordValidationFailed = true;
    } else if (this.newPassword == null || this.newPassword.length == 0) {
      this.validationErrors.push('Password cannot be empty');
      this.passwordValidationFailed = true;
    } else if (this.newPassword != this.condfirmPassword) {
      this.validationErrors.push('Passwords must match');
      this.passwordValidationFailed = true;
    } else if (this.newPassword.length < 6) {
      this.validationErrors.push('Passwords must contain at least 6 characters');
      this.passwordValidationFailed = true;
    }

    const failed = this.validationErrors.length != 0;

    if (failed) {
      this.validationTimeoutHandle = window.setTimeout(() => {
        this.clearValidationErrors();
      }, 3500);
    }

    return !failed;
  }
  //#endregion

  //#region HOOKS
  //#endregion

  //#region EVENTS
  @Emit('close-clicked')
  private closeButtonClicked() {}

  @Emit('apply-clicked')
  private async applyButtonClicked() {
    if (!this.validate()) {
      return null;
    }

    this.isLoading = true;

    let result: [RequestStatus, string] = [0, ''];

    if (this.IsMe) {
      result = await UserModule.ChangeMyPassword([this.newPassword, this.currentPassword]);
    } else {
      result = await UserModule.ChangeUserPassword([this.user.Id, this.newPassword]);
    }

    this.isLoading = false;

    if (result[0] === RequestStatus.OK) {
      return true;
    }

    return false;
  }
  //#endregion

  //#region TRANSLATIONS
  private get ApplyCaption(): string {
    return en.apply.titleCase();
  }
  private get CancelCaption(): string {
    return en.cancel.titleCase();
  }
  private get ChangePasswordCaption(): string {
    return en.changePassword.toUpperCase();
  }
  private get EnterPasswordCaption(): string {
    return en.enterPassword.growFirst();
  }
  private get ConfirmPasswordCaption(): string {
    return en.confirmPassword.growFirst();
  }
  private get CurrentPasswordCaption(): string {
    return en.currentPassword.growFirst();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.change-password {
  border-radius: 6px;
  background: var(--editor-background);

  .edit-text {
    color: #f63e00;
  }

  .user-name {
    color: var(--editor-field-link);
  }

  .errors-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      color: var(--editor-error-text);
      font-size: 14px;
    }
  }
}
</style>
