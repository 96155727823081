<template>
  <div class="quantity">
    <span class="i" @click="RemoveQuantity"><i class="far fa-minus"> </i></span>
    <span class="mx-3">{{ quantity }}</span>
    <span class="i" @click="AddQuantity"><i class="far fa-plus"> </i></span>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'quantity-text-box',
  components: {},
})
export default class QuantityTextBox extends Vue {
  @Prop({ default: 0 }) quantity!: number;

  @Emit('remove-quantity')
  private RemoveQuantity() {
    return this.quantity + 1;
  }

  @Emit('add-quantity')
  private AddQuantity() {
    return this.quantity - 1;
  }
}
</script>

<style lang="scss" scoped>
.quantity {
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--editor-field-border);
    border-radius: 6px;
    padding: 2px 0;
  }

  .i {
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  }
}
</style>
