<template>
  <div class="basic-company-editor">
    <EditorHeader>
      <template slot="header">
        <span>
          {{ company.Name }}
        </span>
        <span class="editing-header-text"> . {{ EditCaption }} </span>
      </template>

      <template slot="buttons">
        <HeaderIconButton iconName="fa-times" test-id="companyEditor.closeButton" @clicked="closeButtonClicked" />
      </template>
    </EditorHeader>

    <EditorContent>
      <TextInput
        class="mb-2"
        :label="NameCaption"
        :value.sync="name"
        :labelWidth="labelWidth"
        :invalid="nameValidationFailed"
        :disabled="isLoading"
        placeholder="Enter company name..."
        testId="companyEditor.companyNameInput"
      />

      <SelectInput
        class="mb-2"
        :label="CountryCaption"
        :options.sync="countryItems"
        :disabled="isLoading"
        :labelWidth="labelWidth"
        :readonly="false"
        :selected-option.sync="country"
        :GetId="a => a.toString()"
        :GetName="a => GetCountryName(a)"
        test-id="companyEditor.countrySelect"
      >
      </SelectInput>

      <TextInput
        class="mb-2"
        :label="AddressCaption"
        :value.sync="address"
        :labelWidth="labelWidth"
        :invalid="addressValidationFailed"
        :disabled="isLoading"
        placeholder="Enter company address..."
        test-id="companyEditor.addressInput"
      />

      <TextInput
        :label="CompanyEmailCaption"
        :value.sync="email"
        :labelWidth="labelWidth"
        :invalid="emailValidationFailed"
        :disabled="isLoading"
        placeholder="Enter company email..."
        test-id="companyEditor.emailInput"
      />

      <FadeTransition :maxHeight="28">
        <div v-if="validationErrors.length != 0" class="errors-container">
          <span class="mt-2">{{ validationErrors[0] }}</span>
        </div>
      </FadeTransition>

      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </EditorContent>

    <EditorFooter>
      <template slot="buttons">
        <IconButton
          class="mr-3"
          :disabled="isLoading || !HasChanges"
          iconName="fa-check"
          :text="ApplyCaption"
          test-id="companyEditor.applyButton"
          @clicked="applyButtonClicked"
        />
        <IconButton
          class="mr-3"
          :disabled="isLoading"
          iconName="fa-sync-alt"
          :text="ResetCaption"
          test-id="companyEditor.resetButton"
          @clicked="resetButtonClicked"
        />
        <IconButton
          :disabled="isLoading"
          iconName="fa-times"
          :iconSize="15"
          :text="CancelCaption"
          test-id="companyEditor.cancelButton"
          @clicked="closeButtonClicked"
        />
      </template>
    </EditorFooter>
  </div>
</template>

<script lang="ts">
import en from '@/localization/en';
import { CompanyModule } from '@/store/modules/companyModule';
import { Countries } from '@/models/responses/Countries';
import { Company } from '@/models/Entities';
import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';
import FadeTransition from '@/components/presentation/FadeTransition.vue';
import * as EmailValidator from 'email-validator';
import IconButton from '../buttons/IconButton.vue';
import EditorFooter from '@/components/forms/base/EditorFooter.vue';
import EditorHeader from '@/components/forms/base/EditorHeader.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import EditorContent from '@/components/forms/base/EditorContent.vue';
import SelectInput from '@/components/inputs/SelectInput.vue';
import { RequestStatus } from '@/models/enums/RequestStatus';
import HeaderIconButton from '@/components/buttons/HeaderIconButton.vue';

@Component({
  name: 'basic-company-editor',
  components: {
    HeaderIconButton,
    FadeTransition: FadeTransition,
    IconButton: IconButton,
    EditorHeader: EditorHeader,
    EditorContent: EditorContent,
    EditorFooter: EditorFooter,
    TextInput: TextInput,
    SelectInput: SelectInput,
  },
})
export default class BasicCompanyEditor extends Vue {
  labelWidth: number = 100;

  //#region PROPS + WATCHERS
  @Prop()
  private company!: Company;

  @Watch('company', { immediate: true })
  OnCompanyChanged(newValue: Company) {
    this.setLocalStateFromCompany(newValue);
  }
  //#endregion

  //#region STATE
  private validationTimeoutHandle: number = -1;
  private nameValidationFailed: boolean = false;
  private descriptionValidationFailed: boolean = false;
  private countryValidationFailed: boolean = false;
  private addressValidationFailed: boolean = false;
  private emailValidationFailed: boolean = false;
  private validationErrors: string[] = [];

  private name: string = '';
  private description: string = '';
  private country: Countries = Countries.RussianFederation;
  private address: string = '';
  private email: string = '';

  private isLoading: boolean = false;

  private countryItems: Countries[] = [];
  private GetCountryName(country: Countries): string {
    return en.CountryNames[country];
  }

  private get HasChanges(): boolean {
    return (
      this.name != this.company.Name ||
      this.email != this.company.Email ||
      this.address != this.company.Address ||
      this.country != this.company.Country
    );
  }
  //#endregion

  //#region STATE LOGIC
  private resetButtonClicked() {
    this.name = this.company.Name;
    this.description = this.company.Description;
    this.address = this.company.Address;
    this.country = this.company.Country;
    this.email = this.company.Email;
  }

  private InitializeCountryItems() {
    for (let key in Countries) {
      if (!isNaN(Number(key))) continue;
      let country = Countries[key as keyof typeof Countries];
      this.countryItems.push(country);
    }
  }

  private async handleCompanyDeleted(company: Company) {
    if (company.Id.toString() === this.company.Id.toString()) {
      this.closeButtonClicked();
    }
  }

  private setLocalStateFromCompany(company: Company) {
    this.name = company.Name;
    this.description = company.Description;
    this.address = company.Address;
    this.country = company.Country;
    this.email = company.Email;
  }

  private clearValidationErrors() {
    if (this.validationTimeoutHandle !== -1) {
      clearTimeout(this.validationTimeoutHandle);
    }

    this.validationErrors = [];
    this.nameValidationFailed = false;
    this.descriptionValidationFailed = false;
    this.addressValidationFailed = false;
    this.emailValidationFailed = false;
    this.validationTimeoutHandle = -1;
  }

  private validate(): boolean {
    this.clearValidationErrors();

    // Validate
    if (this.name == null || this.name.length == 0) {
      this.validationErrors.push('Enter company name');
      this.nameValidationFailed = true;
    }
    // else if (this.description == null || this.description.length == 0) {
    //     this.validationErrors.push("Enter company description");
    //     this.descriptionValidationFailed = true;
    // }
    else if (this.address == null || this.address.length == 0) {
      this.validationErrors.push('Enter company address');
      this.addressValidationFailed = true;
    } else if (this.email == null || this.email.length == 0) {
      this.validationErrors.push('Enter company email');
      this.emailValidationFailed = true;
    } else if (!EmailValidator.validate(this.email)) {
      this.validationErrors.push('Enter correct company email');
      this.emailValidationFailed = true;
    }

    const failed = this.validationErrors.length != 0;

    if (failed) {
      this.validationTimeoutHandle = window.setTimeout(() => {
        this.clearValidationErrors();
      }, 3500);
    }

    return !failed;
  }
  //#endregion

  //#region EVENTS
  private closeButtonClicked() {
    this.$emit('close-clicked');
  }

  private deleteButtonClicked() {
    this.$emit('delete-clicked', this.company);
  }

  @Emit('apply-clicked')
  private async applyButtonClicked() {
    if (!this.validate()) {
      return;
    }

    this.isLoading = true;

    const result = await CompanyModule.ChangeCompanyInfo({
      companyId: this.company.Id,
      name: this.name,
      description: this.description,
      country: this.country,
      address: this.address,
      email: this.email,
    });

    this.isLoading = false;

    return result[0] === RequestStatus.OK;
  }

  async created() {
    CompanyModule.OnCompanyDeleted.subscribe(this.handleCompanyDeleted);
    this.InitializeCountryItems();
  }

  async beforeDestroy() {
    CompanyModule.OnCompanyDeleted.unsubscribe(this.handleCompanyDeleted);
  }
  //#endregion

  //#region TRANSLATIONS
  private get CompanyEmailCaption(): string {
    return en.email.titleCase();
  }
  private get CompanyCaption(): string {
    return en.company.titleCase();
  }
  private get EditCaption(): string {
    return en.edit.toUpperCase();
  }
  private get AddressCaption(): string {
    return en.address.titleCase();
  }
  private get NameCaption(): string {
    return en.nameCaption.titleCase();
  }
  private get DescriptionCaption(): string {
    return en.description.titleCase();
  }
  private get CountryCaption(): string {
    return en.country.titleCase();
  }
  private get ApplyCaption(): string {
    return en.apply.titleCase();
  }
  private get ResetCaption(): string {
    return en.reset.titleCase();
  }
  private get CancelCaption(): string {
    return en.cancel.titleCase();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.basic-company-editor {
  background: var(--editor-background);
  border-radius: 6px;

  span.editing-header-text {
    color: #f63e00;
  }

  .errors-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      color: var(--editor-error-text);
      font-size: 14px;
    }
  }

  .error-border {
    border: 1px solid var(--main-orange);
  }
}
</style>
