<template>
  <div class="machine-error-line-group">
    <div v-if="collapsed">
      <div class="machine-error-line-container">
        <hr class="upper-border" />
        <div class="machine-error-line">
          <div :class="['type mr-2', TypeClass(FirstError)]">
            <span> [{{ Type(FirstError) }}] </span>
          </div>
          <div class="info">
            <span>
              <span class="time mr-1">{{ Time(FirstError) }}</span>
              <span v-if="HasUnacknowledged" class="unacknowledged mr-1">
                <i class="fas fa-circle"></i>
              </span>
              <span class="message">{{ Message(FirstError) }}</span>
              <span v-if="MoreThanOneErrorInGroup" class="amount"> (x{{ group.errors.length }})</span>
              <span class="copy-icon" @click="CopyErrorToClipboard(FirstError)"><i class="far fa-clone"></i></span>
            </span>
          </div>
        </div>
        <hr class="lower-border" />
      </div>
    </div>
    <div v-for="error in group.errors" v-else :key="error.Id.toString()" class="machine-error-line-container">
      <hr class="upper-border" />
      <div class="machine-error-line">
        <div :class="['type mr-2', TypeClass(error)]">
          <span> [{{ Type(error) }}] </span>
        </div>
        <div class="info">
          <span>
            <span class="time mr-1">{{ Time(error) }}</span>
            <span v-if="IsUnacknowledged(error)" class="unacknowledged mr-1">
              <i class="fas fa-circle"></i>
            </span>
            <span class="message">{{ Message(error) }}</span>
            <span class="copy-icon" @click="CopyErrorToClipboard(error)"><i class="far fa-clone"></i></span>
          </span>
        </div>
      </div>
      <hr class="lower-border" />
    </div>
  </div>
</template>

<script lang="ts">
import en from '@/localization/en';
import { toast } from '@/main';
import { ErrorType, PrinterError } from '@/models/Entities';
import dateFormat from 'dateformat';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { POSITION } from 'vue-toastification';
import { PrinterErrorGroup } from './MachineErrors.vue';

@Component({})
export default class MachineErrorGroupLine extends Vue {
  @Prop() group!: PrinterErrorGroup;
  @Prop({ default: false }) collapsed!: boolean;

  //#region STATE
  private get FirstError() {
    return this.group.errors[0];
  }

  private get MoreThanOneErrorInGroup() {
    return this.group.errors.length > 1;
  }

  private get HasUnacknowledged() {
    return this.group.errors.firstOrDefault(a => !a.Acknowledged) != null;
  }

  private IsUnacknowledged(error: PrinterError) {
    return !error.Acknowledged;
  }

  private TypeClass(error: PrinterError) {
    if (error.ErrorType == ErrorType.INFO) {
      return 'info';
    } else if (error.ErrorType == ErrorType.WARNING) {
      return 'warning';
    } else if (error.ErrorType == ErrorType.ERROR) {
      return 'error';
    }

    return '';
  }

  private Type(error: PrinterError) {
    if (error.ErrorType == ErrorType.INFO) {
      return en.info.toUpperCase();
    } else if (error.ErrorType == ErrorType.WARNING) {
      return en.warning.toUpperCase();
    } else if (error.ErrorType == ErrorType.ERROR) {
      return en.error.toUpperCase();
    }
  }

  private Time(error: PrinterError) {
    return dateFormat(error.Time, 'yyyy/mm/dd HH:MM:ss');
  }

  private Message(error: PrinterError) {
    return error.Message;
  }

  private CopyErrorToClipboard(error: PrinterError) {
    navigator.clipboard.writeText(error.Message);

    toast.success('Error copied to clipboard', {
      position: POSITION.BOTTOM_RIGHT,
    });
  }
  //#endregion

  //#region LOGIC
  //#endregion

  //#region TRANSLATIONS
  //#endregion
}
</script>

<style lang="scss" scoped>
.machine-error-line-container {
  display: flex;
  flex-direction: column;
}

.machine-error-line {
  display: flex;
  font-size: 13px;
  padding: 4px 6px;

  .type {
    display: flex;
    flex-direction: row;

    &.error {
      color: var(--machine-errors-error);
    }

    &.warning {
      color: var(--machine-errors-warning);
    }

    &.info {
      color: var(--machine-errors-info);
    }
  }

  .info {
    display: inline-block;
    text-align: left;

    .time {
      color: var(--machine-errors-time);
    }

    .message {
      color: var(--machine-errors-message);
    }

    .amount {
      color: var(--machine-errors-amount);
      font-size: 11px;
    }

    &:hover {
      .copy-icon {
        display: unset;
      }
    }

    .copy-icon {
      margin-left: 6px;
      color: var(--machine-errors-copy-icon);
      display: none;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .unacknowledged {
    color: var(--machine-errors-unacknowledged-indicator);
    margin: 0 2px;
    font-size: 10px;
  }
}

hr {
  height: 1px;
  width: 100%;
  background-image: linear-gradient(to right, gray 15%, rgba(129, 129, 129, 0) 0%);
  background-position: top;
  background-size: 6px 1px;
  background-repeat: repeat-x;
  border: none;
  margin: 0 0 0 0;
}

.machine-error-line-container:last-child {
  hr.lower-border {
    display: unset;
  }
}
.machine-error-line-container:first-child {
  hr.upper-border {
    display: unset;
  }
}
.machine-error-line-container {
  hr.upper-border {
    display: none;
  }
  hr.lower-border {
    display: unset;
  }
}

.machine-error-line-group:last-child {
  .machine-error-line-container:last-child {
    hr.lower-border {
      display: unset;
    }
  }
}
.machine-error-line-group:first-child {
  .machine-error-line-container:first-child {
    hr.upper-border {
      display: unset;
    }
  }
}
.machine-error-line-group {
  .machine-error-line-container {
    hr.upper-border {
      display: none;
    }
    hr.lower-border {
      display: unset;
    }
  }
}
</style>
