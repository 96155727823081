<template>
  <div class="time-range-selector">
    <div v-if="SelectedRangeTimeRange" class="range-picker">
      <DateInput
        :readonly="false"
        label="From"
        :showHour="true"
        :showMinute="true"
        :showSecond="false"
        :date.sync="DateFrom"
      >
      </DateInput>

      <DateInput
        :readonly="false"
        label="To"
        class="ml-3"
        :showHour="true"
        :showMinute="true"
        :showSecond="false"
        :date.sync="DateTo"
      >
      </DateInput>
    </div>

    <div :class="['options', SelectedRangeTimeRange ? 'ml-3' : '']">
      <button
        v-for="rangeType of availableTimeRangeTypes"
        :key="rangeType"
        :class="['option mr-2', IsTimeRangeTypeSelected(rangeType) ? 'selected' : '']"
        :data-testid="getTestId(`timeRange.${TimeRangeTypeTranslation(rangeType).toLowerCase()}`)"
        @click="SelectTimeRangeType(rangeType)"
      >
        <span>{{ TimeRangeTypeTranslation(rangeType) }}</span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch, Emit, PropSync } from 'vue-property-decorator';
import moment from 'moment';

import { TimeRangeType, TimeRange } from '@/models/util/TimeModels';
import DateInput from './DateInput.vue';
import { createTestId } from '@/util/tests';

@Component({
  components: {
    DateInput: DateInput,
  },
})
export default class TimeRangeSelector extends Vue {
  // Preserve time (hours, minutes, seconds, milliseconds)
  @Prop({ default: true }) preserveTime!: boolean;
  @Prop({ default: true }) rangeInPast!: boolean;
  @Prop({ default: undefined }) testId?: string;

  @PropSync('timeRange')
  timeRangeValue!: TimeRange;

  //#region WATCHERS
  @Watch('timeRangeValue', { immediate: true })
  OnTimeRangeChanged(newValue: TimeRange, oldValue?: TimeRange) {
    if (newValue.type == TimeRangeType.Default) {
      this.timeRangeValue.type = TimeRangeType.Day;
    }

    // Todo: implement this!
    this.SetStateFromTimeRange(this.timeRangeValue);

    if (oldValue == undefined) {
      this.ChangeTimeRangeAccordingToState();
    }
  }

  @Watch('selectedTimeRangeType')
  OnSelectedTimeRangeTypeChanged(newValue: TimeRangeType) {
    if (newValue == TimeRangeType.Range) {
      let from = new Date();

      if (!this.preserveTime) {
        from = this.TruncateTime(from);
      }

      // Can't use setter here because I don't want to fire off prop sync for time range multiple times
      this.dateFrom = from;
      this.dateTo = moment(from).add(1, 'days').toDate();
    }

    this.ChangeTimeRangeAccordingToState();
  }
  //#endregion

  //#region STATE
  private dateFrom: Date = new Date();
  private get DateFrom() {
    return this.dateFrom;
  }
  private set DateFrom(newDate: Date) {
    if (!this.preserveTime) {
      newDate = this.TruncateTime(newDate);
    }
    this.dateFrom = newDate;
    this.ChangeTimeRangeAccordingToState();
  }

  private dateTo: Date = new Date();
  private get DateTo() {
    return this.dateTo;
  }
  private set DateTo(newDate: Date) {
    if (!this.preserveTime) {
      newDate = this.TruncateTime(newDate);
    }
    this.dateTo = newDate;
    this.ChangeTimeRangeAccordingToState();
  }

  private availableTimeRangeTypes: TimeRangeType[] = [];
  private selectedTimeRangeType: TimeRangeType = TimeRangeType.Day;

  private get SelectedRangeTimeRange() {
    return this.selectedTimeRangeType == TimeRangeType.Range;
  }

  private getTestId(elementId: string) {
    return createTestId(this.testId, elementId);
  }

  //#endregion

  //#region LOGIC
  SetStateFromTimeRange(timeRange: TimeRange) {
    this.selectedTimeRangeType = timeRange.type == undefined ? TimeRangeType.Default : timeRange.type;
  }

  SelectTimeRangeType(type: TimeRangeType) {
    this.selectedTimeRangeType = type;
  }

  IsTimeRangeTypeSelected(type: TimeRangeType) {
    return this.selectedTimeRangeType == type;
  }

  ChangeTimeRangeAccordingToState() {
    const timeRangeForSelectedType = this.GetTimeRangeFor(this.selectedTimeRangeType);

    // console.log(
    //   `From ${dateformat(
    //     timeRangeForSelectedType.dateFrom,
    //     "yyyy.mm.dd HH:MM:ss"
    //   )} to ${dateformat(
    //     timeRangeForSelectedType.dateTo,
    //     "yyyy.mm.dd HH:MM:ss"
    //   )}`
    // );

    this.timeRangeValue = timeRangeForSelectedType;
  }

  GetTimeRangeFor(type: TimeRangeType): TimeRange {
    let fromDate = new Date();
    let toDate = new Date();

    if (!this.preserveTime) {
      fromDate = this.TruncateTime(fromDate);
      toDate = this.TruncateTime(toDate);
    }

    if (type == TimeRangeType.Day) {
      if (this.rangeInPast) {
        fromDate = moment(fromDate).subtract(1, 'days').toDate();
      } else {
        toDate = moment(fromDate).add(1, 'days').toDate();
      }
    } else if (type == TimeRangeType.Week) {
      if (this.rangeInPast) {
        fromDate = moment(fromDate).subtract(1, 'weeks').toDate();
      } else {
        toDate = moment(fromDate).add(1, 'weeks').toDate();
      }
    } else if (type == TimeRangeType.Month) {
      if (this.rangeInPast) {
        fromDate = moment(fromDate).subtract(1, 'months').toDate();
      } else {
        toDate = moment(fromDate).add(1, 'months').toDate();
      }
    } else if (type == TimeRangeType.Range) {
      fromDate = this.dateFrom;
      toDate = this.dateTo;
    }

    return {
      dateFrom: fromDate,
      dateTo: toDate,
      type: type,
    };
  }

  TruncateTime(date: Date) {
    const newDate = moment(date);
    newDate.set('hours', 0);
    newDate.set('minutes', 0);
    newDate.set('seconds', 0);
    newDate.set('milliseconds', 0);

    return newDate.toDate();
  }
  //#endregion

  //#region EVENTS
  @Emit('closed')
  Closed() {}
  //#endregion

  //#region HOOKS
  mounted() {
    this.availableTimeRangeTypes.push(TimeRangeType.Range);
    this.availableTimeRangeTypes.push(TimeRangeType.Day);
    this.availableTimeRangeTypes.push(TimeRangeType.Week);
    this.availableTimeRangeTypes.push(TimeRangeType.Month);
    this.availableTimeRangeTypes.push(TimeRangeType.Live);
  }
  //#endregion

  //#region TRANSLATIONS
  private TimeRangeTypeTranslation(range: TimeRangeType) {
    if (range == TimeRangeType.Range) {
      return 'Range';
    } else if (range == TimeRangeType.Day) {
      return 'Day';
    } else if (range == TimeRangeType.Week) {
      return 'Week';
    } else if (range == TimeRangeType.Month) {
      return 'Month';
    } else if (range == TimeRangeType.Year) {
      return 'Year';
    } else if (range == TimeRangeType.Live) {
      return 'Live';
    }
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.time-range-selector {
  display: flex;
  align-items: center;
  row-gap: 12px;
  flex-wrap: wrap;
  justify-content: flex-end;

  .range-picker {
    display: flex;
    flex-wrap: wrap;
    row-gap: 12px;
    justify-content: flex-end;
    align-items: center;
  }

  .options {
    display: flex;
    flex-wrap: wrap;
    padding: 2px 0;
    justify-content: flex-end;

    .option {
      border: none;
      padding: 3px 13px;
      display: flex;
      background: #2e2c2c;
      border-radius: 21px;
      align-items: center;
      justify-content: center;
      height: 24px;
      color: #ffffff;
      transition: all 0.2s ease-out;

      &:not(.selected) {
        background: transparent;
        border: none;
        color: #bcbcbc;
      }

      &:hover {
        color: white;
      }

      span {
        font-size: 13px;
      }
    }
  }
}
</style>
