<template>
  <div class="dashboard-container px-3 py-3">
    <div class="dashboard">
      <div class="left-panel thin-scroll">
        <div class="header mb-2">
          <span>{{ CompanyName }}</span>
        </div>

        <div class="main-container">
          <div v-if="HasPrintersInGroups" class="printers-summary pr-2 mb-3">
            <div class="categories-header">
              <div class="finished">
                <span>Finished</span>
              </div>

              <div class="in-progress">
                <span>In progress</span>
              </div>

              <div class="errors">
                <span>Errors</span>
              </div>
            </div>

            <div class="printers">
              <div class="finished thin-scroll">
                <PrinterSummary
                  v-for="printer in FinishedPrinters"
                  :key="printer.printer.Id.toString()"
                  :printer="printer"
                  :type="SUMMARY_TYPE_FINISHED"
                />
              </div>

              <div class="in-progress thin-scroll">
                <PrinterSummary
                  v-for="printer in InProgressPrinters"
                  :key="printer.printer.Id.toString()"
                  :printer="printer"
                  :type="SUMMARY_TYPE_IN_PROGRESS"
                />
              </div>

              <div class="errors thin-scroll">
                <PrinterSummary
                  v-for="printer in ErrorsPrinters"
                  :key="printer.printer.Id.toString()"
                  :printer="printer"
                  :type="SUMMARY_TYPE_ERRORS"
                />
              </div>
            </div>
          </div>

          <div v-if="!isLoadingPrinters" class="main-buttons pr-2 mb-3">
            <StartNewJobButton
              v-if="AllPrinters.length > 0"
              class="start-new-button"
              :label="StartNewJobCaption"
              test-id="mainDashboard.startNewJobButton"
              @clicked="StartNewJobClicked"
            />

            <StartNewJobButton
              v-else
              class="add-new-printer"
              :label="AddNewPrinterCaption"
              test-id="mainDashboard.addNewPrinterButton"
              @clicked="AddNewPrinterClicked"
            />
          </div>

          <div class="printer-telemetries pr-2 thin-scroll">
            <PrinterTelemetrySummary
              v-for="printer in ConnectedPrinters"
              :key="printer.printer.Id.toString()"
              :printer="printer"
            />
            <div v-for="(chunk, indx) of DisconnectedPrintersChunks" :key="indx" class="disconnected-group">
              <PrinterTelemetrySummary
                v-for="printer in chunk"
                :key="printer.printer.Id.toString()"
                class="disconnected"
                :printer="printer"
              />
            </div>
          </div>

          <div :class="['spinner', isLoadingPrinters ? '' : 'hidden']"></div>
        </div>
      </div>

      <div class="vertical-resizer"></div>

      <div class="right-panel thin-scroll">
        <div class="dashboard-stats">
          <div class="dashboard-stats-header">
            <div class="period">
              <span>On this</span>
              <select v-model="selectedTimeRangeType" data-test-id="dashboardStats.timeRangeSelect">
                <option v-for="type of availableTimeRangeTypes" :key="type" :value="type">
                  {{ TimeRangeTypeTranslation(type) }}
                </option>
              </select>
            </div>
            <div class="total">
              <span>Total</span>
            </div>
          </div>

          <!-- Todo: this should be taken out into a separate component -->
          <div class="stats mb-3">
            <div class="period-container">
              <div v-if="!isLoadingCompanyStats" class="period">
                <div class="material-consumptions-container">
                  <div class="material-consumption-group">
                    <div class="stat-item">
                      <span class="value mr-2">
                        {{ JobCount }}
                      </span>
                      <div class="info-container">
                        <div class="unit">jobs</div>
                        <div class="additional-text">
                          /
                          {{ TimeRangeTypeTranslation(selectedTimeRangeType).toLowerCase() }}
                        </div>
                      </div>
                    </div>

                    <div class="stat-item">
                      <span class="value mr-2">
                        {{ HourCount }}
                      </span>
                      <div class="info-container">
                        <div class="unit">hours</div>
                        <div class="additional-text">
                          /
                          {{ TimeRangeTypeTranslation(selectedTimeRangeType).toLowerCase() }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-for="(chunk, indx) in CompanyMaterialConsumptionStatsChunks"
                  :key="indx"
                  class="material-consumptions-container"
                >
                  <div :class="['material-consumption-group', chunk.length == 1 ? 'single' : '']">
                    <div v-for="stat in chunk" :key="stat.Id.toString()" class="stat-item">
                      <span
                        v-tooltip="{
                          content: GetMaterialConsumptionValueFull(stat),
                          placement: 'top',
                        }"
                        class="value mr-2"
                      >
                        {{ GetMaterialConsumptionValue(stat) }}
                      </span>
                      <div class="info-container">
                        <div class="unit">
                          {{ GetMaterialConsumptionUnit(stat) }}
                        </div>
                        <div class="additional-text">
                          {{ stat.MaterialName }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div :class="['spinner', isLoadingCompanyStats ? '' : 'hidden']"></div>
            </div>

            <!-- Todo: this should be taken out into a separate component -->
            <div class="total">
              <div class="print-hours-by-weekday">
                <div class="header mb-2">
                  <div class="metric">
                    <span></span>
                  </div>
                  <div class="metric-name">
                    <span>Hours</span>
                  </div>
                </div>

                <div v-if="!isLoadingTotalPrintTimeStats" class="content">
                  <div v-for="(data, indx) of WeekdayBarData" :key="indx" class="labeled-bar mb-1">
                    <div class="label mr-2">
                      <span>{{ data.label }}</span>
                    </div>
                    <div class="bar-container">
                      <div class="bar-inner-container">
                        <div
                          v-tooltip="{
                            content: FormatSeconds(data.value),
                            placement: 'right',
                          }"
                          :class="['bar', data.value == data.maxValue ? 'max' : '']"
                          :style="CalculateBarStyle(data.value, data.maxValue)"
                        ></div>

                        <div class="value" :style="CalculateBarValueStyle(data.value, data.maxValue)">
                          <span>{{ data.valueString }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div :class="['spinner mt-2', isLoadingTotalPrintTimeStats ? '' : 'hidden']"></div>
              </div>

              <div class="jobs-by-user mt-4">
                <div class="header mb-2">
                  <div class="metric">
                    <span></span>
                  </div>
                  <div class="metric-name">
                    <span>Jobs</span>
                  </div>
                </div>

                <div v-if="!isLoadingUserStats" class="content">
                  <div v-for="(data, indx) of JobCountByUserBarData" :key="indx" class="labeled-bar mb-1">
                    <div
                      class="label clickable mr-2"
                      style="position: relative"
                      @click="TotalUserJobsNameClicked(indx)"
                    >
                      <i v-if="indx < 3" class="fas fa-award mr-1" :style="GetUserAwardStyle(indx)"></i>
                      <span>{{ data.label }}</span>
                    </div>
                    <div class="bar-container">
                      <div class="bar-inner-container">
                        <div
                          :class="['bar', data.value == data.maxValue ? 'max' : '']"
                          :style="CalculateBarStyle(data.value, data.maxValue)"
                        ></div>

                        <div class="value" :style="CalculateBarValueStyle(data.value, data.maxValue)">
                          <span>{{ data.valueString }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div :class="['spinner mt-2', isLoadingUserStats ? '' : 'hidden']"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import PrinterTelemetrySummary from '@/components/presentation/PrinterTelemetrySummary.vue';
import PrinterSummary, { SummaryType } from '@/components/presentation/PrinterSummary.vue';
import { FullPrinter } from '@/models/CompositeEntities';
import { MaterialConsumptionCompanyStat, MaterialType, Printer, PrinterState, User, UserStat } from '@/models/Entities';
import { ConnectorModule } from '@/store/modules/connectorModule';
import { JobModule } from '@/store/modules/jobModule';
import { PrinterModule } from '@/store/modules/printerModule';
import { Guid } from 'guid-typescript';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { StatModule } from '@/store/modules/statModule';
import { LoginModule } from '@/store/modules/loginModule';
import { UserModule } from '@/store/modules/userModule';
import { TimeRangeType } from '@/models/util/TimeModels';
import en from '@/localization/en';
import { AsyncBatchQueueSignalR } from '@/store/util/Globals';
import { TypeHelper } from '@/util/TypeHelper';
import { create } from 'vue-modal-dialogs';
import StartNewJobDialog, { StartNewJobDialogResult } from './dialogs/StartNewJobDialog.vue';
import StartNewJobButton from '@/components/buttons/StartNewJobButton.vue';
import DarkButton from '@/components/buttons/DarkButton.vue';
import { Routes } from '@/router/routes';
import moment from 'moment';

export interface LabeledBarData {
  label: string;
  value: number;
  maxValue: number;
  valueString: string;
}

const StartNewJob = create<Printer | null, boolean, StartNewJobDialogResult | null>(
  StartNewJobDialog,
  'printer',
  'backButtonAvailable',
);

function MapPrinterStateByActivity(state: PrinterState): number {
  switch (state) {
    case PrinterState.UNKNOWN:
      return -1;
    case PrinterState.DISCONNECTED:
      return 0;
    case PrinterState.UPDATING_FIRMWARE:
      return 1;
    case PrinterState.HALTED:
      return 2;
    case PrinterState.BUSY:
      return 3;
    case PrinterState.PAUSING:
      return 4;
    case PrinterState.PAUSED:
      return 5;
    case PrinterState.PRINT_FINISHED:
      return 6;
    case PrinterState.RESUMING:
      return 7;
    case PrinterState.IDLE:
      return 8;
    case PrinterState.PRINTING:
      return 9;
  }
}

@Component({
  components: {
    PrinterSummary: PrinterSummary,
    PrinterTelemetrySummary: PrinterTelemetrySummary,
    StartNewJobButton: StartNewJobButton,
    DarkButton: DarkButton,
  },
})
export default class MainDashboard extends Vue {
  SUMMARY_TYPE_FINISHED = SummaryType.Finished;
  SUMMARY_TYPE_IN_PROGRESS = SummaryType.InProgress;
  SUMMARY_TYPE_ERRORS = SummaryType.Errors;

  @Watch('Users')
  async OnUsersChanged(newValue: User[], oldValue?: User[]) {
    if (oldValue == undefined) {
      AsyncBatchQueueSignalR.Queue({
        Batch: async () => {
          for (const user of this.Users) {
            await StatModule.SubscribeToUserGroup(user.Id);
          }
        },
      });

      return;
    }

    const addedUsers = newValue.filter(a => oldValue.firstOrDefault(u => u.Id.toString() == a.Id.toString()) == null);
    const removedUsers = oldValue.filter(a => newValue.firstOrDefault(u => u.Id.toString() == a.Id.toString()) == null);

    AsyncBatchQueueSignalR.Queue({
      Batch: async () => {
        for (const user of addedUsers) {
          await StatModule.SubscribeToUserGroup(user.Id);
        }

        for (const user of removedUsers) {
          await StatModule.DeleteFromUserGroup(user.Id);
        }
      },
    });
  }

  //#region STATE
  private _componentId!: Guid;

  private isLoadingCompanyStats: boolean = false;
  private isLoadingTotalPrintTimeStats: boolean = false;
  private isLoadingUserStats: boolean = false;
  private isLoadingPrinters: boolean = false;

  private availableTimeRangeTypes: TimeRangeType[] = [];
  private selectedTimeRangeType: TimeRangeType = TimeRangeType.Week;

  private get CompanyId() {
    return LoginModule.Me!.CompanyId;
  }

  private get CompanyName() {
    return LoginModule.Company?.Name;
  }

  private get Users(): User[] {
    const users = UserModule.Users.filter(a => LoginModule.Me && a.CompanyId.toString() == this.CompanyId.toString());

    UserModule.OccupyUsers([users.map(a => a.Id), this._componentId]);

    return users;
  }

  private get Printers(): Printer[] {
    let printers = PrinterModule.Printers.sort((a: Printer, b: Printer) => (a.Name < b.Name ? -1 : 1));

    printers = printers.sort((a, b) => {
      const stateA = MapPrinterStateByActivity(a.PrinterState);
      const stateB = MapPrinterStateByActivity(b.PrinterState);

      return stateA < stateB ? -1 : 1;
    });

    PrinterModule.OccupyPrinters([printers, this._componentId]);

    return printers;
  }

  private get CompanyStat() {
    return StatModule.CompanyStats.firstOrDefault(a => a.companyId.equals(this.CompanyId));
  }

  private get CompanyMaterialConsumptionStats() {
    return StatModule.MaterialConsumptionCompanyStats.filter(a => a.CompanyId.equals(this.CompanyId));
  }

  private get CompanyMaterialConsumptionStatsChunks() {
    return TypeHelper.SliceIntoChunks(this.CompanyMaterialConsumptionStats, 2);
  }

  private get TotalTimeStat() {
    return StatModule.TotalPrintTimeByWeekDayCompanyStat.firstOrDefault(a => a.CompanyId.equals(this.CompanyId));
  }

  private get UserStats(): UserStat[] {
    return StatModule.UserStats;
  }

  private get AllPrinters(): FullPrinter[] {
    if (this.isLoadingPrinters) return [];

    let fullPrinters: FullPrinter[] = [];

    for (let printer of this.Printers) {
      let model = PrinterModule.PrinterModels.firstOrDefault(
        a => a.Id.toString() == printer.PrinterModelId.toString(),
      )!;

      let connectorModel = ConnectorModule.PrinterModels.firstOrDefault(
        a => a.Id.toString() == printer.PrinterModelId.toString(),
      )!;

      let fullPrinter: FullPrinter = {
        printer: printer,
        model: model,
        connectorModel: connectorModel,
      };

      fullPrinters.push(fullPrinter);
    }

    return fullPrinters;
  }

  private get ConnectedPrinters(): FullPrinter[] {
    return this.AllPrinters.filter(a => a.printer.PrinterState != PrinterState.DISCONNECTED);
  }

  private get DisonnectedPrinters(): FullPrinter[] {
    return this.AllPrinters.filter(a => a.printer.PrinterState == PrinterState.DISCONNECTED);
  }

  private get DisconnectedPrintersChunks(): FullPrinter[][] {
    return TypeHelper.SliceIntoChunks(this.DisonnectedPrinters, 4);
  }

  private get HasPrintersInGroups() {
    return this.FinishedPrinters.length + this.InProgressPrinters.length + this.ErrorsPrinters.length > 0;
  }

  private get FinishedPrinters(): FullPrinter[] {
    return this.AllPrinters.filter(a => a.printer.PrinterState == PrinterState.PRINT_FINISHED);
  }

  private get InProgressPrinters(): FullPrinter[] {
    return this.AllPrinters.filter(a => a.printer.PrinterState == PrinterState.PRINTING);
  }

  private get ErrorsPrinters(): FullPrinter[] {
    return this.AllPrinters.filter(a => a.printer.ErrorCount > 0);
  }

  private get JobCount() {
    if (this.CompanyStat == null) return 0;

    if (this.selectedTimeRangeType == TimeRangeType.Week) {
      return this.CompanyStat.jobCountWeek;
    } else if (this.selectedTimeRangeType == TimeRangeType.Month) {
      return this.CompanyStat.jobCountMonth;
    } else if (this.selectedTimeRangeType == TimeRangeType.Year) {
      return this.CompanyStat.jobCountYear;
    }

    return this.CompanyStat.jobCountWhole;
  }

  private get HourCount() {
    if (this.CompanyStat == null) return 0;

    if (this.selectedTimeRangeType == TimeRangeType.Week) {
      return parseFloat((this.CompanyStat.printTimeWeekS / 3600).toFixed(1));
    } else if (this.selectedTimeRangeType == TimeRangeType.Month) {
      return parseFloat((this.CompanyStat.printTimeMonthS / 3600).toFixed(1));
    } else if (this.selectedTimeRangeType == TimeRangeType.Year) {
      return parseFloat((this.CompanyStat.printTimeYearS / 3600).toFixed(1));
    }

    return parseFloat((this.CompanyStat.printTimeWholeS / 3600).toFixed(1));
  }

  private get TotalTimeMaxTime() {
    let max = 0;

    if (this.TotalTimeStat == null) return max;

    if (this.TotalTimeStat.MondayTimeS > max) {
      max = this.TotalTimeStat.MondayTimeS;
    }

    if (this.TotalTimeStat.TuesdayTimeS > max) {
      max = this.TotalTimeStat.TuesdayTimeS;
    }

    if (this.TotalTimeStat.WednesdayTimeS > max) {
      max = this.TotalTimeStat.WednesdayTimeS;
    }

    if (this.TotalTimeStat.ThursdayTimeS > max) {
      max = this.TotalTimeStat.ThursdayTimeS;
    }

    if (this.TotalTimeStat.FridayTimeS > max) {
      max = this.TotalTimeStat.FridayTimeS;
    }

    if (this.TotalTimeStat.SaturdayTimeS > max) {
      max = this.TotalTimeStat.SaturdayTimeS;
    }

    if (this.TotalTimeStat.SundayTimeS > max) {
      max = this.TotalTimeStat.SundayTimeS;
    }

    return max;
  }

  private get WeekdayBarData(): LabeledBarData[] {
    const result: LabeledBarData[] = [];
    const maxTime = this.TotalTimeMaxTime;
    const stat = this.TotalTimeStat;

    if (stat == null) return result;

    result.push({
      label: 'Monday',
      value: stat.MondayTimeS,
      valueString: parseFloat((stat.MondayTimeS / 3600).toFixed(1)).toString(),
      maxValue: maxTime,
    });

    result.push({
      label: 'Tuesday',
      value: stat.TuesdayTimeS,
      valueString: parseFloat((stat.TuesdayTimeS / 3600).toFixed(1)).toString(),
      maxValue: maxTime,
    });

    result.push({
      label: 'Wednesday',
      value: stat.WednesdayTimeS,
      valueString: parseFloat((stat.WednesdayTimeS / 3600).toFixed(1)).toString(),
      maxValue: maxTime,
    });

    result.push({
      label: 'Thursday',
      value: stat.ThursdayTimeS,
      valueString: parseFloat((stat.ThursdayTimeS / 3600).toFixed(1)).toString(),
      maxValue: maxTime,
    });

    result.push({
      label: 'Friday',
      value: stat.FridayTimeS,
      valueString: parseFloat((stat.FridayTimeS / 3600).toFixed(1)).toString(),
      maxValue: maxTime,
    });

    result.push({
      label: 'Saturday',
      value: stat.SaturdayTimeS,
      valueString: parseFloat((stat.SaturdayTimeS / 3600).toFixed(1)).toString(),
      maxValue: maxTime,
    });

    result.push({
      label: 'Sunday',
      value: stat.SundayTimeS,
      valueString: parseFloat((stat.SundayTimeS / 3600).toFixed(1)).toString(),
      maxValue: maxTime,
    });

    return result;
  }

  private get JobCountByUserMax() {
    let max = 0;

    for (const stat of this.UserStats) {
      if (stat.jobCountWhole > max) {
        max = stat.jobCountWhole;
      }
    }

    return max;
  }

  private get JobCountByUserBarData(): LabeledBarData[] {
    const result: LabeledBarData[] = [];
    const maxJobs = this.JobCountByUserMax;

    const sorted = this.UserStats.sort((a, b) => (a.jobCountWhole > b.jobCountWhole ? -1 : 1));

    for (const stat of sorted.slice(0, 5)) {
      const user = this.Users.firstOrDefault(a => a.Id.toString() == stat.userId.toString());

      if (user == null) continue;

      let label = user.FirstName;

      if (user.SecondName != undefined) {
        if (user.SecondName.length > 0) {
          label += ' ' + user.SecondName[0] + '.';
        }
      }

      result.push({
        value: stat.jobCountWhole,
        label: label,
        maxValue: maxJobs,
        valueString: stat.jobCountWhole.toString(),
      });
    }

    return result;
  }
  //#endregion

  //#region LOGIC
  private TotalUserJobsNameClicked(indx: number) {
    const sorted = this.UserStats.sort((a, b) => (a.jobCountWhole > b.jobCountWhole ? -1 : 1));

    const user = sorted[indx];

    this.$router.push({
      name: Routes.USER,
      query: {
        userId: user.userId.toString(),
      },
    });
  }

  private async StartNewJobClicked() {
    await StartNewJob(null, true);
  }

  private async AddNewPrinterClicked() {
    this.$router.push({
      name: Routes.MACHINES,
      query: {
        action: 'add-new-machine',
      },
    });
  }

  GetUserAwardStyle(indx: number) {
    let color = '#cd7f32';

    if (indx == 0) color = '#ffd700';
    else if (indx == 1) color = '#c0c0c0';
    else if (indx == 2) color = '#cd7f32';

    return {
      color: color,
      'font-size': '15px',
      position: 'absolute',
      left: '-17px',
    };
  }

  FormatSeconds(totalSeconds: number) {
    const momentTime = moment.duration(totalSeconds, 'seconds');
    const days = momentTime.days();
    const hours = momentTime.hours();
    const minutes = momentTime.minutes();
    const seconds = momentTime.seconds();

    let result = '';

    if (days >= 1) {
      result += days + ' day' + (days > 1 ? 's' : '') + ' ';
    }

    if (hours >= 1) {
      result += hours + ' hour' + (hours > 1 ? 's' : '') + ' ';
    }

    if (minutes >= 1) {
      result += minutes + ' minute' + (minutes > 1 ? 's' : '') + ' ';
    }

    if (seconds >= 1 && hours < 1) {
      result += seconds + ' second' + (seconds > 1 ? 's' : '') + ' ';
    }

    return result;
  }

  CalculateBarStyle(val: number, maxVal: number) {
    if (maxVal == 0) {
      maxVal = 1;
    }

    return {
      'margin-right': (100 - (val / maxVal) * 100).toFixed(1) + '%',
    };
  }

  CalculateBarValueStyle(val: number, maxVal: number) {
    if (maxVal == 0) {
      maxVal = 1;
    }

    return {
      left: ((val / maxVal) * 100).toFixed(1) + '%',
      'margin-left': val == 0 ? undefined : '6px',
    };
  }

  GetMaterialConsumptionValueFull(stat: MaterialConsumptionCompanyStat) {
    let result = 0;

    if (this.selectedTimeRangeType == TimeRangeType.Week) {
      result = stat.ConsumedWeek;
    } else if (this.selectedTimeRangeType == TimeRangeType.Month) {
      result = stat.ConsumedMonth;
    } else if (this.selectedTimeRangeType == TimeRangeType.Year) {
      result = stat.ConsumedYear;
    }

    result = stat.ConsumedWhole;

    if (stat.MaterialType == MaterialType.PLASTIC) {
      result *= 1e6;
    }

    return parseFloat(result.toFixed(6)).toString() + ' ' + this.GetMaterialConsumptionUnit(stat);
  }

  GetMaterialConsumptionValue(stat: MaterialConsumptionCompanyStat) {
    let result = 0;

    if (this.selectedTimeRangeType == TimeRangeType.Week) {
      result = stat.ConsumedWeek;
    } else if (this.selectedTimeRangeType == TimeRangeType.Month) {
      result = stat.ConsumedMonth;
    } else if (this.selectedTimeRangeType == TimeRangeType.Year) {
      result = stat.ConsumedYear;
    }

    result = stat.ConsumedWhole;

    if (stat.MaterialType == MaterialType.PLASTIC) {
      result *= 1e6;
    }

    if (result < 1) {
      return '<1';
    }

    return parseFloat(result.toFixed(1));
  }

  GetMaterialConsumptionUnit(stat: MaterialConsumptionCompanyStat) {
    if (stat.MaterialType == MaterialType.PLASTIC) {
      return 'cm³';
    } else if (stat.MaterialType == MaterialType.FIBER) {
      return 'm';
    }

    return 'N/A';
  }

  private async LoadPrinterModels() {
    await PrinterModule.LoadAllPrinterModels();
    await ConnectorModule.LoadAllPrinterModels();
  }

  private async LoadConnectionTypes() {
    await PrinterModule.ReadAllConnectionTypes();
  }

  private async LoadPrinters() {
    this.isLoadingPrinters = true;

    await this.LoadPrinterModels();
    await this.LoadConnectionTypes();
    await PrinterModule.LoadPrintersForCompanyAll();

    this.isLoadingPrinters = false;
  }

  private async LoadCompanyUsers() {
    await UserModule.LoadUsersForCompany([]);
  }

  private async LoadStats() {
    const loadCompanyStats = async () => {
      this.isLoadingCompanyStats = true;

      await StatModule.LoadCompanyStat(this.CompanyId);
      await StatModule.LoadMaterialConsumptionCompanyStat(this.CompanyId);

      this.isLoadingCompanyStats = false;
    };

    const loadTotalPrintTimeStats = async () => {
      this.isLoadingTotalPrintTimeStats = true;

      await StatModule.LoadTotalPrintTimeByWeekDayCompanyStat(this.CompanyId);

      this.isLoadingTotalPrintTimeStats = false;
    };

    const loadUserStats = async () => {
      this.isLoadingUserStats = true;

      await this.LoadCompanyUsers();
      await StatModule.LoadUserStats(this.Users.map(a => a.Id));

      this.isLoadingUserStats = false;
    };

    await Promise.all([loadCompanyStats(), loadTotalPrintTimeStats(), loadUserStats()]);
  }

  // We could add async queue here but it's much more neat in hook :)
  private async SubscribeToCompanyPrinters() {
    await PrinterModule.SubscribeToCompanyPrintersGroup();
    await JobModule.SubscribeToCompanyJobsGroup();
  }

  private async SubscribeToUsers() {
    await UserModule.SubscribeToCompanyUsersGroup();
  }

  private async SubscribeToStats() {
    await StatModule.SubscribeToCompanyGroup();

    for (const user of this.Users) {
      await StatModule.SubscribeToUserGroup(user.Id);
    }
  }

  private async UnsubscribeFromCompanyPrinters() {
    await PrinterModule.DeleteFromCompanyPrintersGroup();
    await JobModule.DeleteFromCompanyJobsGroup();
  }

  private async UnsubscribeFromUsers() {
    await UserModule.DeleteFromCompanyUsersGroup();
  }

  private async UnsubscribeFromStats() {
    await StatModule.DeleteFromCompanyGroup();

    for (const user of this.Users) {
      await StatModule.DeleteFromUserGroup(user.Id);
    }
  }

  //#endregion

  //#region HOOKS
  async beforeCreate() {
    this._componentId = Guid.create();
  }

  async created() {}

  async mounted() {
    AsyncBatchQueueSignalR.Queue({
      Batch: async () => {
        await this.SubscribeToCompanyPrinters();
        await this.SubscribeToUsers();
        await this.SubscribeToStats();
      },
    });

    this.availableTimeRangeTypes.push(TimeRangeType.Week);
    this.availableTimeRangeTypes.push(TimeRangeType.Month);
    this.availableTimeRangeTypes.push(TimeRangeType.Year);

    await Promise.all([this.LoadPrinters(), this.LoadStats()]);
  }

  async beforeDestroy() {
    AsyncBatchQueueSignalR.Queue({
      Batch: async () => {
        await this.UnsubscribeFromCompanyPrinters();
        await this.UnsubscribeFromUsers();
        await this.UnsubscribeFromStats();
      },
    });

    await UserModule.LooseUsersNew([this.Users.map(a => a.Id), this._componentId]);
    await UserModule.CollectUsers();

    await PrinterModule.LoosePrintersNew([this.Printers, this._componentId]);
    await PrinterModule.CollectPrinters();

    // Todo: implement GC here (also for material consumptions and time by weekday stats)

    // await StatModule.LooseUserStatsNew([this.UserStats, this._componentId]);
    // await StatModule.CollectUserStats();

    // await StatModule.LooseCompanyStatsNew([this.CompanyStat, this._componentId]);
    // await StatModule.CollectCompanyStats();
  }
  //#endregion

  //#region TRANSLATIONS
  private TimeRangeTypeTranslation(range: TimeRangeType) {
    if (range == TimeRangeType.Range) {
      return 'Range';
    } else if (range == TimeRangeType.Day) {
      return 'Day';
    } else if (range == TimeRangeType.Week) {
      return 'Week';
    } else if (range == TimeRangeType.Month) {
      return 'Month';
    } else if (range == TimeRangeType.Year) {
      return 'Year';
    } else if (range == TimeRangeType.Live) {
      return 'Live';
    }
  }

  private get StartNewJobCaption(): string {
    return en.startNewJob.toUpperCase();
  }

  private get AddNewPrinterCaption(): string {
    return en.addNewMachine.toUpperCase();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.dashboard-container {
  flex-grow: 1;
  height: 100%;
  position: relative;
  overflow: auto;
}

.dashboard {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: auto;
  color: white;

  .left-panel {
    width: 60%;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .header {
      display: flex;
      color: #e1e1e1;
      font-size: 20px;
    }

    .main-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
      position: relative;

      .printers-summary {
        display: flex;
        flex-direction: column;
        // height: 50%;
        overflow: auto;
        max-height: 50%;
        // background: rgba(255, 0, 0, 0.1);

        .categories-header {
          font-size: 16px;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          border-bottom: 1px solid #787878;
          text-transform: uppercase;
          justify-items: start;
          padding: 0.5rem 0;
          margin-bottom: 1rem;
          column-gap: 16px;

          .finished {
            color: #00f654;
          }

          .in-progress {
            color: #d8d8d8;
          }

          .errors {
            color: #f63e00;
          }
        }

        .printers {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          column-gap: 16px;
          row-gap: 16px;
          flex: 1;
          overflow: auto;

          // background: rgba(0, 255, 0, 0.1);

          .finished {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            overflow: auto;
          }

          .in-progress {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            overflow: auto;
          }

          .errors {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            overflow: auto;
          }
        }
      }

      .printer-telemetries {
        flex: 1;
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 16px;
        column-gap: 16px;
        grid-auto-rows: minmax(min-content, max-content);
        overflow: overlay;

        .disconnected-group {
          display: grid;
          row-gap: 16px;

          .disconnected {
            height: 46px;
            max-height: 46px;
          }
        }
      }
    }
  }

  .main-buttons {
    display: flex;

    .start-new-button {
      width: 100%;
    }

    .add-new-printer {
      width: 100%;
    }
  }

  .vertical-resizer {
    cursor: col-resize;
    width: 4px;
    margin: 0 3px;
  }

  .right-panel {
    width: 40%;
    display: flex;
    overflow: auto;
    position: relative;
    flex-direction: column;

    .dashboard-stats {
      display: flex;
      flex-direction: column;
      background: #2d2d2d;
      border-radius: 6px;
      padding: 15px 32px;
      margin-top: calc(15px + 0.5rem);
      min-width: 600px;

      .dashboard-stats-header {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #787878;
        color: #d8d8d8;
        margin-bottom: 1rem;
        column-gap: 48px;
        padding: 0.5rem 0;

        .period {
          display: flex;
          width: 60%;
          max-width: 60%;
          text-transform: uppercase;

          select {
            margin-left: 2px;
            text-transform: uppercase;
            border: none;
            background: transparent;
            color: #d8d8d8;
            transform: translateY(-1px);

            option {
              text-transform: uppercase;
              color: #d8d8d8;
              background-color: #2d2d2d;
            }
          }
        }

        .total {
          display: flex;
          flex: 1.45;
          text-transform: uppercase;
        }
      }

      .stats {
        display: flex;
        column-gap: 48px;

        .period-container {
          position: relative;
          width: 60%;
          max-width: 60%;

          .period {
            display: flex;
            flex-wrap: wrap;
            gap: 18px;

            .stat-item {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              background: #424242;
              border: 1px solid #575757;
              border-radius: 6px;
              padding: 18px;
              line-height: 1;
              max-height: 76px;

              .value {
                color: #dcdcdc;
                font-size: 38px;
                max-width: 100px;
              }

              .info-container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                height: 100%;
                gap: 8px;
                padding: 2px 0;

                .unit {
                  color: #bbbbbb;
                  font-size: 12px;
                }

                .additional-text {
                  color: #dbdbdb;
                  font-size: 14px;
                  text-align: left;
                  overflow: hidden;
                }
              }
            }

            .material-consumptions-container {
              display: grid;
              width: 100%;

              .material-consumption-group {
                display: grid;
                gap: 18px;
                grid-template-columns: auto 1fr;

                &.single {
                  grid-template-columns: 1fr;
                }
              }
            }
          }
        }

        .total {
          display: flex;
          flex: 1.45;
          flex-direction: column;

          .print-hours-by-weekday {
            font-size: 11px;
            display: flex;
            flex-direction: column;
            position: relative;
            min-height: 100px;

            .header {
              display: flex;
              gap: 0.5rem;

              .metric {
                display: flex;
                flex: 1.25;
                align-items: flex-start;
              }

              .metric-name {
                display: flex;
                flex: 1;
                align-items: flex-start;
                color: #bebebe;

                margin-right: 2rem;
              }
            }

            .content {
              display: flex;
              flex-direction: column;
              margin-right: 2rem;
            }

            .loading-container {
              flex: 1;
              position: relative;
            }
          }

          .jobs-by-user {
            font-size: 11px;
            display: flex;
            flex-direction: column;
            position: relative;
            min-height: 100px;

            .header {
              display: flex;
              gap: 0.5rem;

              .metric {
                display: flex;
                flex: 1.25;
                align-items: flex-start;
              }

              .metric-name {
                display: flex;
                flex: 1;
                align-items: flex-start;
                color: #bebebe;
                margin-right: 2rem;
              }
            }

            .content {
              display: flex;
              flex-direction: column;
              margin-right: 2rem;
            }

            .loading-container {
              flex: 1;
              position: relative;
            }
          }
        }
      }
    }
  }

  .labeled-bar {
    display: flex;
    align-items: center;

    .label {
      display: flex;
      flex: 1.25;
      color: #949494;
      align-items: center;

      &.clickable {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .bar-container {
      display: flex;
      flex: 1;
      align-items: flex-start;
      align-items: center;

      .bar-inner-container {
        position: relative;
        flex: 1;

        .bar {
          height: 20px;
          min-height: 20px;
          background-color: #888888;

          transition: all 0.2s ease-out;

          &.max {
            background-color: #c8c8c8;
          }

          &:hover {
            background-color: #c8c8c8;

            &.max {
              background-color: #f2f2f2;
            }
          }
        }

        .value {
          position: absolute;
          color: #949494;
          margin-top: 3px;
          top: 0;
        }
      }
    }
  }
}
</style>
