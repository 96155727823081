<template>
  <div class="badge">
    <span>{{ text }}</span>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Badge extends Vue {
  @Prop({ default: '' }) text!: string;
}
</script>

<style lang="scss" scoped>
.badge {
  border-radius: 9px;
  height: 18px;
  color: var(--badge-text);
  font-size: 13px;
  background-color: var(--badge-background);
  padding: 2px 14px;
  font-weight: 500;
}
</style>
