type EnumType = Record<string, unknown>;

export const getAllEnumKeys = <T extends EnumType>(enumType: T): Array<keyof T> => {
  return Object.keys(enumType).filter(key => isNaN(Number(key)));
};

export const getAllEnumValues = <T extends EnumType>(enumType: T): Array<T[keyof T]> => {
  return getAllEnumKeys(enumType).map(key => enumType[key]);
};

export const getAllEnumEntries = <T extends EnumType>(enumType: T): Array<[keyof T, T[keyof T]]> => {
  return getAllEnumKeys(enumType).map(key => [key, enumType[key]]);
};
