<template>
  <div class="source-download">
    <div class="header mb-2">
      <span class="mb-3">Download for</span>
      <span class="filename"> {{ Name }}</span>
    </div>
    <ProgressBar :minValue="0" :maxValue="Total" :value="Downloaded"></ProgressBar>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SourceDownload } from '@/store/modules/sourceModule';
import ProgressBar from './ProgressBar.vue';

@Component({
  name: 'source-download-viewer',
  components: {
    ProgressBar: ProgressBar,
  },
})
export default class SourceDownloadViewer extends Vue {
  @Prop() download!: SourceDownload;

  private get Downloaded() {
    return this.download.bytesDownloaded;
  }

  private get Total() {
    return this.download.bytesTotal;
  }

  private get Name() {
    return this.download.fileName;
  }
}
</script>

<style lang="scss" scoped>
.source-download {
  display: flex;
  flex-direction: column;
  color: var(--editor-field-value);
  font-size: 13px;

  span.filename {
    font-weight: bold;
  }
}
</style>
