<template>
  <div class="cart-list-item-container">
    <hr />
    <div class="cart-list-item">
      <div class="product-name">
        {{ ProductName }}
      </div>

      <quantity-text-box
        :quantity.sync="cartItem.quantity"
        @add-quantity="AddQuantity"
        @remove-quantity="RemoveQuantity"
      >
      </quantity-text-box>

      <div class="sum">
        {{ Sum }}
      </div>

      <div class="delete" @click="Delete">
        <i class="far fa-trash"> </i>
      </div>
    </div>
    <hr />
  </div>
</template>

<script lang="ts">
//#region IMPORTS

import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import ComponentHelper from '@/util/ComponentHelper';
import { CartItem } from '@/store/modules/billing/cartModule';
import en from '@/localization/en';
import QuantityTextBox from '@/components/inputs/QuantityTextBox.vue';

//#endregion

@Component({
  name: 'cart-list-item',
  components: {
    QuantityTextBox: QuantityTextBox,
  },
})
export default class CartListItem extends Vue {
  @Prop() private cartItem!: CartItem;

  private get ProductName() {
    return this.cartItem.product.name;
  }

  private get Quantity() {
    return this.cartItem.quantity;
  }

  private get Sum() {
    let currency = en.CurrencySymbols[this.cartItem.price.currency.toUpperCase()];

    let recurringStr = '';
    if (this.cartItem.price.recurringInterval !== null) {
      recurringStr =
        ' / ' +
        ComponentHelper.GetReadableRecurringIntervalNonPlural(this.cartItem.price.recurringInterval).toLowerCase();
    }
    return currency + ((this.cartItem.quantity * this.cartItem.price.price) / 100).toFixed(2) + recurringStr;
  }

  @Emit('remove-quantity')
  private RemoveQuantity() {
    return this.cartItem;
  }

  @Emit('add-quantity')
  private AddQuantity() {
    return this.cartItem;
  }

  @Emit('delete')
  private Delete() {
    return this.cartItem;
  }
}
</script>

<style lang="scss" scoped>
hr {
  height: 1px;
  width: 100%;
  background-image: linear-gradient(to right, gray 15%, rgba(129, 129, 129, 0) 0%);
  background-position: top;
  background-size: 6px 1px;
  background-repeat: repeat-x;
  border: none;
  margin: 0 0 0 0;
}

.cart-list-item {
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  margin: 0 14px;
  color: var(--list-item-text);

  .product-name {
    text-align: left;
    flex: 2;
  }

  .quantity {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      width: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid var(--editor-field-border);
      border-radius: 6px;
      padding: 2px 0;
    }
  }

  .sum {
    flex: 1;
  }

  .delete {
  }

  i {
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  }
}
</style>
