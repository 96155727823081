<template>
  <div class="source-viewer">
    <EditorHeader>
      <template slot="header">
        <span class="source-name">
          {{ GCodeName }}
        </span>
      </template>

      <template slot="buttons">
        <HeaderIconButton test-id="sourceViewer.deleteButton" iconName="fa-trash" @clicked="DeleteButtonClicked" />
        <HeaderIconButton test-id="sourceViewer.toggleModeButton" iconName="fa-pen" @clicked="ToggleEditMode()" />
        <HeaderIconButton test-id="sourceViewer.closeButton" iconName="fa-times" @clicked="closeButtonClicked" />
      </template>
    </EditorHeader>

    <EditorContent>
      <div class="source-fields">
        <TextInput
          class="mb-2"
          :label="ForPrinterCaption"
          :value.sync="PrinterType"
          :labelWidth="labelWidth"
          :disabled="isLoading"
          :readonly="true"
        />

        <TextInput
          class="mb-2"
          :label="DurationCaption"
          :value.sync="printDuration"
          :labelWidth="labelWidth"
          :disabled="isLoading"
          :readonly="true"
        />

        <TextInput
          class="mb-2"
          :label="OwnerCaption"
          :value.sync="Owner"
          :labelWidth="labelWidth"
          :disabled="isLoading"
          :readonly="true"
          @clicked="HasOwner ? OnOwnerClicked() : undefined"
        />

        <TextInput
          class="mb-2"
          :label="CreatedAtCaption"
          :value.sync="CreatedAt"
          :labelWidth="labelWidth"
          :disabled="isLoading"
          :readonly="true"
        />

        <FileInput
          class="mb-2"
          :label="GCodeCaption"
          :invalid="gCodeFileValidationFailed"
          :disabled="isLoading"
          :labelWidth="labelWidth"
          :readonly="!isEditing"
          :downloadable="true"
          extension=".gcode"
          :file-name.sync="gCodeName"
          :file-size.sync="GCodeSizeBytes"
          :enableFileDrag="true"
          test-id="sourceViewer.gcode"
          @file-changed="OnGCodeFileChanged"
          @download-file="DownloadGCode"
          @reset-file="ResetGCodeFile"
        />

        <FileInput
          class="mb-2"
          :label="AuprojCaption"
          :invalid="projectFileValidationFailed"
          :disabled="isLoading"
          :labelWidth="labelWidth"
          :readonly="!isEditing"
          :downloadable="HasAuproj"
          extension=".auprojx"
          :file-name.sync="auprojName"
          :file-size.sync="AuprojSizeBytes"
          :enableFileDrag="true"
          test-id="sourceViewer.auprojx"
          @file-changed="OnAuprojxFileChanged"
          @download-file="DownloadAuproj"
          @reset-file="ResetAuprojxFile"
        />

        <SelectInput
          class="mb-2"
          :label="CategoryCaption"
          :options.sync="groups"
          :disabled="isLoading"
          :labelWidth="labelWidth"
          :readonly="!isEditing"
          :selected-option.sync="selectedGroup"
          :GetId="a => a.Id.toString()"
          :GetName="a => a.Name"
          test-id="sourceViewer.categoriesSelect"
          @clicked="CategoryClicked"
        >
        </SelectInput>

        <TextInput
          :label="PrintCountCaption"
          :value.sync="PrintCount"
          :labelWidth="labelWidth"
          :disabled="isLoading"
          :readonly="true"
        />

        <FadeTransition :maxHeight="28">
          <div v-if="validationErrors.length != 0" class="errors-container">
            <span class="mt-2">{{ validationErrors[0] }}</span>
          </div>
        </FadeTransition>

        <button v-if="!isEditing" data-testid="sourceViewer.addToQueueButton" class="mt-2" @click="AddSourceToQueue">
          Add to queue
        </button>

        <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
      </div>

      <ActionCard
        v-if="SourceDownloads.length > 0"
        headerText="Downloads"
        class="downloads mt-3"
        :initiallyCollapsed="DownloadsCollapsed"
        test-id="sourceViewer.downloads"
        @collapse-toggled="DownloadsCollapseToggled"
      >
        <SourceDownloadViewer
          v-for="(download, index) in SourceDownloads"
          :key="download.id.toString()"
          :class="[index == SourceDownloads.length - 1 ? 'mb-1' : 'mb-3']"
          :download="download"
        />
      </ActionCard>

      <ActionCard
        v-if="MaterialConsumptions.length > 0"
        :headerText="MaterialConsumptionsCaption"
        class="material-consumptions mt-3"
        test-id="sourceViewer.materialConsumptions"
        :initiallyCollapsed="MaterialConsumptionsCollapsed"
        @collapse-toggled="MaterialConsumptionsCollapseToggled"
      >
        <SortableHeader :header-items="MaterialConsumptionsHeader" test-id="sourceViewer.materialConsumptions" />
        <div class="thin-scroll overflow-auto">
          <ListItemV3
            v-for="item in MaterialConsumptions"
            :id="item.Id"
            :key="item.Id.toString()"
            :items="MaterialConsumptionsItems(item)"
            :headerIconType="MaterialConsumptionIconType(item)"
            test-id="sourceViewer.materialConsumptions"
            headerIconName="fa-circle"
            :headerIconFontSize="14"
          />
        </div>
      </ActionCard>

      <div :class="['spinner-container', isLoadingPrintingHistory ? '' : 'hidden']">
        <div :class="['spinner', isLoadingPrintingHistory ? '' : 'hidden']"></div>
      </div>

      <ActionCard
        v-if="FullJobArchieves.length > 0"
        :headerText="PrintingHistoryCaption"
        class="printing-history mt-3"
        :initiallyCollapsed="PrintingHistoryCollapsed"
        test-id="sourceViewer.printingHistory"
        @collapse-toggled="PrintingHistoryCollapseToggled"
      >
        <SortableHeader :header-items="ArchieveHeader" test-id="sourceViewer.printingHistory" />
        <div class="thin-scroll overflow-auto">
          <ListItemV3
            v-for="item in FullJobArchieves"
            :id="item.jobArchieve.Id"
            :key="item.jobArchieve.Id.toString()"
            test-id="sourceViewer.printingHistory"
            class="cur-pointer"
            :items="ArchieveItems(item)"
            @openItem="OpenJobArchive(item)"
          />
        </div>
      </ActionCard>
    </EditorContent>

    <EditorFooter v-if="isEditing">
      <template slot="buttons">
        <IconButton
          class="mr-3"
          :disabled="isLoading"
          iconName="fa-check"
          :text="ApplyCaption"
          test-id="sourceViewer.applyButton"
          @clicked="ApplyButtonClicked"
        />
        <IconButton
          class="mr-3"
          :disabled="isLoading"
          iconName="fa-sync-alt"
          :text="ResetCaption"
          test-id="sourceViewer.resetButton"
          @clicked="ResetButtonClicked"
        />
        <IconButton
          :disabled="isLoading"
          iconName="fa-times"
          :text="CancelCaption"
          :iconSize="15"
          test-id="sourceViewer.cancelButton"
          @clicked="CancelButtonClicked"
        />
      </template>
    </EditorFooter>
  </div>
</template>

<script lang="ts">
import en from '@/localization/en';
import { toast } from '@/main';
import { FullJobArchieve, FullSource } from '@/models/CompositeEntities';
import {
  Job,
  JobArchieve,
  MaterialConsumption,
  MaterialType,
  Printer,
  Source,
  SourceGroup,
  User,
} from '@/models/Entities';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { ArchieveJobSortBy } from '@/models/requests/RequestMonolith';
import jobModule, { ArchieveJobQueryAdditions, JobModule } from '@/store/modules/jobModule';
import { PrinterModule } from '@/store/modules/printerModule';
import {
  FILE_SIZE_LIMIT_IN_MB,
  GCODE_FORM_KEY,
  PROJECT_FORM_KEY,
  SourceDownload,
  SourceModule,
  SOURCE_ID_FORM_KEY,
} from '@/store/modules/sourceModule';
import { UserModule } from '@/store/modules/userModule';
import ComponentHelper, { HeaderItem, ItemData, SortMode } from '@/util/ComponentHelper';
import { GuidHelper } from '@/util/GuidHelper';
import dateFormat from 'dateformat';
import { Guid } from 'guid-typescript';
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import { POSITION } from 'vue-toastification';
import FadeTransition from '../presentation/FadeTransition.vue';
import ActionCard from '../presentation/ActionCard.vue';
import ListItemV3 from '@/components/presentation/ListItemV3.vue';
import SortableHeader from '@/components/presentation/SortableHeader.vue';
import SourceDownloadViewer from '@/components/presentation/SourceDownloadViewer.vue';

import AuraMessageBoxDialog, { Result } from '@/views/dialogs/AuraMessageBoxDialog.vue';
import { create } from 'vue-modal-dialogs';
import { GlobalDataModule } from '@/store/modules/globalDataModule';
import AddSourceToQueueDialog from '@/views/dialogs/AddSourceToQueueDialog.vue';
import EditorContent from '@/components/forms/base/EditorContent.vue';
import EditorFooter from '@/components/forms/base/EditorFooter.vue';
import EditorHeader from '@/components/forms/base/EditorHeader.vue';
import IconButton from '../buttons/IconButton.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import FileInput from '@/components/inputs/FileInput.vue';
import SelectInput from '@/components/inputs/SelectInput.vue';
import HeaderIconButton from '@/components/buttons/HeaderIconButton.vue';

const ResetFileOnServerSure = create<Result, String, String, Result>(
  AuraMessageBoxDialog,
  'results',
  'header',
  'mainText',
);

const AddSourceToQueue = create<Source, boolean>(AddSourceToQueueDialog, 'source');

@Component({
  name: 'source-viewer',
  components: {
    HeaderIconButton,
    ActionCard: ActionCard,
    ListItemV3: ListItemV3,
    SortableHeader: SortableHeader,
    SourceDownloadViewer: SourceDownloadViewer,
    FadeTransition: FadeTransition,
    IconButton: IconButton,
    EditorHeader: EditorHeader,
    EditorContent: EditorContent,
    EditorFooter: EditorFooter,
    TextInput: TextInput,
    FileInput: FileInput,
    SelectInput: SelectInput,
  },
})
export default class SourceViewer extends Vue {
  private componentId!: Guid;
  private labelWidth: number = 150;

  @Prop() source!: FullSource;
  @Prop() groups!: SourceGroup[];
  private selectedGroup!: SourceGroup;

  @Watch('source', { immediate: true, deep: true })
  private async OnSourceChanged(newValue: FullSource, oldValue: FullSource) {
    if (oldValue !== undefined) {
      // Clear GC ?
      if (newValue.file.Id.toString() != oldValue.file.Id.toString()) {
        // Reload
        await this.SetLocalStateFromSource(newValue);

        await JobModule.LooseJobArchieves([this.JobArchieves, this.componentId]);
        await JobModule.CollectJobArchieves();

        await this.LoadMoreArchieves();
        return;
      }
    }

    // Load initial
    await this.SetLocalStateFromSource(newValue);
    await this.LoadMoreArchieves();
  }

  @Watch('groups', { immediate: true, deep: false })
  private async OnGroupsChanged() {
    this.SetLocalStateFromSource(this.source);
  }

  //#region STATE
  private isEditing: boolean = false;
  private isLoading: boolean = false;
  private isLoadingPrintingHistory: boolean = false;

  private formData: FormData = new FormData();

  private gCodeName: string = '';
  private auprojName: string = '';

  private deleteAuprojOnServer: boolean = false;

  private get HasOwner(): boolean {
    return this.source.author !== null;
  }

  private get HasAuproj(): boolean {
    return this.source.file.ProjectFileName != null;
  }

  private get PrinterType(): string {
    if (this.source.file.PrinterModel == null || this.source.file.PrinterModel == '') {
      return 'Unknown';
    }
    return this.source.file.PrinterModel;
  }

  private get printDuration(): string {
    const { PrintDuration } = this.source.file;
    if (!PrintDuration) {
      return '—';
    }

    const { formattedHours, formattedMinutes, formattedSeconds } = PrintDuration;
    if (!formattedHours && !formattedMinutes && !formattedSeconds) {
      return '—';
    }

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  private get Owner(): string {
    if (this.source.author == null) return '—';

    return ComponentHelper.GetFullname(this.source.author);
  }

  private get CreatedAt(): string {
    return ComponentHelper.GetReadableDateTime(this.source.file.CreationDateTime);
  }

  private get GCodeName(): string {
    return this.source.file.CodeFileName;
  }

  private get GCodeSizeBytes() {
    return this.source.file.CodeFileSize;
  }

  private get GCodeSize(): string {
    return ComponentHelper.GetReadbaleBytesSize(this.source.file.CodeFileSize);
  }

  private get AuprojName(): string {
    if (!this.HasAuproj) return '—';

    return this.source.file.ProjectFileName!;
  }

  private get AuprojSizeBytes() {
    return this.source.file.ProjectFileSize;
  }

  private get AuprojSize(): string {
    if (!this.HasAuproj) return '—';

    return ComponentHelper.GetReadbaleBytesSize(this.source.file.ProjectFileSize!);
  }

  private get Category(): string {
    if (this.source.group == null) return '—';

    return this.source.group.Name;
  }

  private get PrintCount(): string {
    return this.source.file.PrintCount.toString();
  }

  private get SourceDownloads(): SourceDownload[] {
    return SourceModule.SourceDownload.filter(a => a.source.Id.equals(this.source.file.Id));
  }

  //#region VALIDATION
  private validationTimeoutHandle: number = -1;
  private gCodeFileValidationFailed: boolean = false;
  private projectFileValidationFailed: boolean = false;
  private validationErrors: string[] = [];
  //#endregion

  //#region ARCHIEVES
  private get Users(): User[] {
    let userIds: Guid[] = jobModule.GetUserIdsByAll(this.Jobs, this.JobArchieves);
    let users = UserModule.Users.filter(a => GuidHelper.includes(userIds, a.Id));
    UserModule.UsersSync();
    return users;
  }

  private get Jobs(): Job[] {
    let jobs = JobModule.Jobs.filter(a => a.SourceId && a.SourceId.equals(this.source.file.Id));
    JobModule.OccupyJobs([jobs, this.componentId]);
    return jobs;
  }

  private get Printers(): Printer[] {
    let printerIds: Guid[] = GuidHelper.distinct(
      this.Jobs.map(a => a.PrinterId).concat(this.JobArchieves.map(a => a.PrinterId)),
    );
    let printers = PrinterModule.Printers.filter(a => GuidHelper.includes(printerIds, a.Id));
    PrinterModule.OccupyPrinters([printers, this.componentId]);
    return printers;
  }

  private get JobArchieves(): JobArchieve[] {
    let jobArchieves = JobModule.JobArchieves.map(a => a);
    JobModule.OccupyJobArchieves([jobArchieves, this.componentId]);
    return jobArchieves;
  }

  private get FullJobArchieves(): FullJobArchieve[] {
    let fullJobArchieves: FullJobArchieve[] = [];
    for (let archieve of this.JobArchieves.filter(a => a.SourceId && a.SourceId.equals(this.source.file.Id!))) {
      let printer = this.Printers.singleOrDefault(a => a.Id.toString() === archieve.PrinterId.toString());
      if (printer == null) continue;
      let source = this.source.file;
      let user = this.Users.singleOrDefault(a => GuidHelper.equalsWithNull(a.Id, archieve.UserId));
      let fullJob: FullJobArchieve = {
        jobArchieve: archieve,
        printer: printer,
        source: source,
        owner: user,
      };
      fullJobArchieves.push(fullJob);
    }
    // Todo: add sorting
    // fullJobArchieves.sort(this.SortArchieveByDateDESC);
    // fullJobArchieves = fullJobArchieves.slice(0, this.currentJobArchievesCount -1);
    return fullJobArchieves;
  }

  //#region HEADERS & ITEMS
  private get ArchieveHeader(): HeaderItem[] {
    let result: HeaderItem[] = [];

    result.push({
      caption: 'Printer',
      itemClass: ComponentHelper.GetWidthClass('grow-2'),
    });

    result.push({
      caption: 'Date',
      itemClass: ComponentHelper.GetWidthClass(150),
    });

    result.push({
      caption: 'Owner',
      itemClass: ComponentHelper.GetWidthClass('grow-1'),
    });

    result.push({
      caption: 'Status',
      itemClass: ComponentHelper.GetWidthClass(100),
    });

    return result;
  }

  private ArchieveItems(value: FullJobArchieve) {
    let result: ItemData[] = [];

    let itemPrinter = new ItemData('Printer', value.printer.Name, 'grow-2');
    let itemDate = new ItemData('Date', dateFormat(value.jobArchieve.EndTime!, 'dd.mm.yyyy'), 150);
    let itemOwner = new ItemData('Owner', ComponentHelper.GetFullname(value.owner!), 'grow-1');
    let itemStatus = new ItemData('Status', ComponentHelper.GetReadableEndStatus(value.jobArchieve.EndStatus!), 100);

    result.push(itemPrinter);
    result.push(itemDate);
    result.push(itemOwner);
    result.push(itemStatus);

    return result;
  }
  //#endregion
  //#endregion

  //#region MATERIAL CONSUMPTIONS
  private get MaterialConsumptions(): MaterialConsumption[] {
    return this.source.file.MaterialConsumptions;
  }

  //#region HEADERS & ITEMS
  private get MaterialConsumptionsHeader(): HeaderItem[] {
    let result: HeaderItem[] = [];

    result.push({
      caption: 'Name',
      itemClass: ComponentHelper.GetWidthClass(),
    });

    result.push({
      caption: 'Count',
      itemClass: ComponentHelper.GetWidthClass(125),
    });

    return result;
  }

  private MaterialConsumptionsItems(value: MaterialConsumption): ItemData[] {
    let result: ItemData[] = [];

    let itemName: ItemData = new ItemData('Name', value.MaterialName, 'grow-1');
    let itemCount: ItemData = new ItemData('Count', value.Count.toFixed(1) + ' ' + this.GetDimention(value), 125);

    result.push(itemName);
    result.push(itemCount);

    return result;
  }

  private MaterialConsumptionIconType(value: MaterialConsumption): string {
    if (this.IsPlastic(value)) {
      // empty circle
      return 'far';
    }

    // filled circle
    return 'fas';
  }

  private GetDimention(item: MaterialConsumption): string {
    if (this.IsPlastic(item)) return this.GCaption;
    return this.MCaption;
  }

  private IsPlastic(item: MaterialConsumption): boolean {
    return item.MaterialType === MaterialType.PLASTIC;
  }
  //#endregion
  //#endregion

  //#region VIEW STATE
  private get DownloadsCollapsed(): boolean {
    return GlobalDataModule.SourceViewerViewState.downloadsCollapsed;
  }

  private get MaterialConsumptionsCollapsed(): boolean {
    return GlobalDataModule.SourceViewerViewState.materialConsumptionsCollapsed;
  }

  private get PrintingHistoryCollapsed(): boolean {
    return GlobalDataModule.SourceViewerViewState.printingHistoryCollapsed;
  }
  //#endregion
  //#endregion

  //#region LOGIC

  //#region VIEW STATE
  private async DownloadsCollapseToggled(isCollapsed: boolean) {
    GlobalDataModule.ChangeSourceViewerDownloadsCollapsed(isCollapsed);
  }

  private async MaterialConsumptionsCollapseToggled(isCollapsed: boolean) {
    GlobalDataModule.ChangeSourceViewerMaterialConsumptionsCollapsed(isCollapsed);
  }

  private async PrintingHistoryCollapseToggled(isCollapsed: boolean) {
    GlobalDataModule.ChangeSourceViewerPrintingHistoryCollapsed(isCollapsed);
  }
  //#endregion

  private async SetLocalStateFromSource(source: FullSource) {
    this.selectedGroup = this.groups.firstOrDefault(a => source.group != null && a.Id.equals(source.group.Id))!;

    this.gCodeName = source.file.CodeFileName;
    this.auprojName = source.file.ProjectFileName == null ? '' : source.file.ProjectFileName;

    this.formData.delete(GCODE_FORM_KEY);
    this.formData.delete(PROJECT_FORM_KEY);

    this.deleteAuprojOnServer = false;

    this.$forceUpdate();
  }

  private UploadGCodeFile() {
    (this.$refs.inputGCodeFile as HTMLElement).click();
  }

  private UploadAuprojxFile() {
    (this.$refs.inputAuprojxFile as HTMLElement).click();
  }

  private async ResetAuprojxFile() {
    let entry = this.formData.get(PROJECT_FORM_KEY);
    if (entry != null) {
      this.formData.delete(PROJECT_FORM_KEY);
      this.auprojName = this.source.file.ProjectFileName == null ? '' : this.source.file.ProjectFileName;
    } else if (this.source.file.ProjectFileName != null) {
      const userConfirmation = await ResetFileOnServerSure(
        Result.Yes | Result.No,
        'Reset .auprojx',
        "You haven't selected any local .auprojx files. Do you want to delete the one currently loaded on server?",
      );

      if (userConfirmation == Result.No || userConfirmation == Result.Cancel) {
        return;
      }

      this.auprojName = '';
      this.deleteAuprojOnServer = true;
    }
  }

  private async ResetGCodeFile() {
    let entry = this.formData.get(GCODE_FORM_KEY);
    if (entry != null) {
      this.formData.delete(GCODE_FORM_KEY);
      this.gCodeName = this.source.file.CodeFileName;
    }
  }

  private async AddSourceToQueue() {
    await AddSourceToQueue(this.source.file);
  }

  @Emit('category-clicked')
  private CategoryClicked() {
    return this.source.group;
  }

  private OnGCodeFileChanged(files: File[]) {
    const file = files[0];

    this.gCodeName = file.name;

    this.ChangeFormFile(file, GCODE_FORM_KEY);
  }

  private OnAuprojxFileChanged(files: File[]) {
    const file = files[0];

    this.auprojName = file.name;

    this.ChangeFormFile(file, PROJECT_FORM_KEY);
  }

  private ChangeFormFile(file: File, key: string) {
    let entry = this.formData.get(key);

    if (entry != null) this.formData.delete(key);

    this.formData.append(key, file, file.name);
  }

  private async DownloadGCode() {
    await SourceModule.downloadGCodeFile(this.source.file);
  }

  private async DownloadAuproj() {
    await SourceModule.downloadProjectFile(this.source.file);
  }

  private async LoadMoreArchieves() {
    let additions = new ArchieveJobQueryAdditions();
    additions.sortBy = ArchieveJobSortBy.BY_DATE_END;
    additions.sortMode = SortMode.DESC;
    additions.page = 0;
    additions.pageSize = 50;

    await JobModule.LooseJobArchieves([this.JobArchieves, this.componentId]);
    await JobModule.CollectJobArchieves();

    this.isLoadingPrintingHistory = true;

    let jobArchieves: JobArchieve[] = await JobModule.JustLoadMoreJobArcievesForSource([
      additions,
      this.source.file.Id,
    ]);

    this.isLoadingPrintingHistory = false;

    if (jobArchieves.any()) {
      let printerIds = GuidHelper.distinct(jobArchieves.map(a => a.PrinterId));
      let sourceIds = jobModule.GetSourceIdsByArchieves(jobArchieves);
      let userIds = jobModule.GetUserIdsByArchieves(jobArchieves);

      await Promise.all([
        PrinterModule.LoadPrinters(printerIds),
        SourceModule.LoadSources(sourceIds),
        UserModule.LoadUsers(userIds),
      ]);

      for (let jobArchieve of jobArchieves) {
        JobModule.AddJobArchieveToModule(jobArchieve);
      }
    }
  }

  private async ResetButtonClicked() {
    this.SetLocalStateFromSource(this.source);
  }

  private async ApplyButtonClicked() {
    this.isLoading = true;

    if (!(await this.validate())) {
      this.isLoading = false;
      return;
    }

    this.formData.set(SOURCE_ID_FORM_KEY, this.source.file.Id.toString());

    let message = 'Successfuly updated file';
    let success = true;

    if (
      (this.source.group == null && this.selectedGroup != null) ||
      !this.selectedGroup.Id.equals(this.source.group!.Id)
    ) {
      const result = await SourceModule.UpdateCategory([this.source.file, this.selectedGroup.Id]);

      if (result[0] !== RequestStatus.OK) {
        message = 'Could not update category';
        success = false;
      }
    }

    if (this.formData.get(GCODE_FORM_KEY) !== null) {
      const result = await SourceModule.UpdateGCode(this.formData);

      if (result[0] !== RequestStatus.OK) {
        message = 'Could not update .gcode';
        success = false;
      }
    }

    if (this.formData.get(PROJECT_FORM_KEY) !== null || this.deleteAuprojOnServer) {
      const result = await SourceModule.UpdateAuproj(this.formData);

      if (result[0] !== RequestStatus.OK) {
        message = 'Could not update .auprojx';
        success = false;
      }
    }

    this.isLoading = false;

    if (success) {
      toast.success(message, {
        position: POSITION.BOTTOM_RIGHT,
      });

      this.formData.delete(GCODE_FORM_KEY);
      this.formData.delete(PROJECT_FORM_KEY);
      this.deleteAuprojOnServer = false;

      this.isEditing = false;
    } else {
      toast.error(message, {
        position: POSITION.BOTTOM_RIGHT,
      });
    }
  }

  private async CancelButtonClicked() {
    this.SetLocalStateFromSource(this.source);

    this.isEditing = false;
  }

  private async ToggleEditMode() {
    if (this.isEditing) {
      this.SetLocalStateFromSource(this.source);
    }

    this.isEditing = !this.isEditing;
  }

  @Emit('delete-clicked')
  private async DeleteButtonClicked() {
    return this.source;
  }

  //#region VALIDATION
  private clearValidationErrors() {
    if (this.validationTimeoutHandle !== -1) {
      clearTimeout(this.validationTimeoutHandle);
    }

    this.validationErrors = [];
    this.gCodeFileValidationFailed = false;
    this.projectFileValidationFailed = false;
    this.validationTimeoutHandle = -1;
  }

  private async validate() {
    this.clearValidationErrors();

    // Validate
    let limitInBytes = FILE_SIZE_LIMIT_IN_MB * 1024 * 1024;

    let gCodeSize = 0;
    let projectSize = 0;

    if (this.formData.get(GCODE_FORM_KEY) != null) {
      gCodeSize = (this.formData.get(GCODE_FORM_KEY) as File).size;
    }

    if (this.formData.get(PROJECT_FORM_KEY) != null) {
      projectSize = (this.formData.get(PROJECT_FORM_KEY) as File).size;
    }

    let limit = await SourceModule.RequestSourceSizeLimit();
    let total = await SourceModule.RequestSourceTotalSize();

    if (total + gCodeSize + projectSize > limit) {
      this.validationErrors.push(en.fileLimitExceeds.growFirst());
      this.gCodeFileValidationFailed = true;
      this.projectFileValidationFailed = true;
    } else if (gCodeSize > limitInBytes) {
      this.validationErrors.push('GCode file exceeds 50 MB');
      this.gCodeFileValidationFailed = true;
    } else if (projectSize > limitInBytes) {
      this.validationErrors.push('Aura project file exceeds 50MB');
      this.projectFileValidationFailed = true;
    }

    const failed = this.validationErrors.length != 0;

    if (failed) {
      this.validationTimeoutHandle = window.setTimeout(() => {
        this.clearValidationErrors();
      }, 3500);
    }

    return !failed;
  }
  //#endregion
  //#endregion

  //#region HOOKS
  async beforeCreate() {
    this.componentId = Guid.create();
  }

  async beforeDestroy() {
    await PrinterModule.LoosePrintersNew([this.Printers, this.componentId]);
    await PrinterModule.CollectPrinters();

    await JobModule.LooseJobArchieves([this.JobArchieves, this.componentId]);
    await JobModule.CollectJobArchieves();
  }
  //#endregion

  //#region EVENTS
  @Emit('close-cliked')
  closeButtonClicked() {}

  @Emit('owner-clicked')
  OnOwnerClicked() {
    return this.source.author;
  }

  @Emit('job-archive-clicked')
  OpenJobArchive(arch: FullJobArchieve) {
    return arch.jobArchieve;
  }
  //#endregion

  //#region TRANSLATIONS
  private get MCaption(): string {
    return en.meter;
  }
  private get GCaption(): string {
    return en.gr;
  }

  private get MaterialConsumptionsCaption(): string {
    return en.materialConsumptions.growFirst();
  }

  private get PrintingHistoryCaption(): string {
    return en.printingHistory.growFirst();
  }

  private get ForPrinterCaption(): string {
    return en.forPrinter.growFirst();
  }

  private get DurationCaption(): string {
    return en.duration.growFirst();
  }

  private get OwnerCaption(): string {
    return en.owner.growFirst();
  }

  private get CreatedAtCaption(): string {
    return en.createdAt.growFirst();
  }

  private get GCodeCaption(): string {
    return en.gcode;
  }

  private get AuprojCaption(): string {
    return en.auproj;
  }

  private get CategoryCaption(): string {
    return en.category.growFirst();
  }

  private get PrintCountCaption(): string {
    return en.printCount.growFirst();
  }

  private get HoursShort(): string {
    return en.hoursShort;
  }
  private get MinutesShort(): string {
    return en.minutesShort;
  }

  private get ApplyCaption(): string {
    return en.apply.titleCase();
  }
  private get ResetCaption(): string {
    return en.reset.titleCase();
  }
  private get CancelCaption(): string {
    return en.cancel.titleCase();
  }
  private get EditCaption(): string {
    return en.edit.toUpperCase();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.source-viewer {
  background: var(--editor-background);
  border-radius: 6px;

  .source-fields {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .material-consumptions {
    background: none;
  }

  .printing-history {
    background: none;
    max-height: 300px;
  }

  .downloads {
    background: none;
  }

  button {
    border: 1px solid var(--editor-field-value);
    border-radius: 6px;
    font-size: 13px;
    color: var(--editor-field-value);
    background: transparent;
    height: 26px;
  }

  .editor-content {
    &:last-child {
      margin-bottom: 0.5rem;
    }
  }

  .errors-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      color: var(--editor-error-text);
      font-size: 14px;
    }
  }

  .spinner-container {
    min-height: 40px;
    margin: 20px 0;
    margin-bottom: 0px;
    position: relative;
    opacity: 1;

    &.hidden {
      opacity: 0;
      min-height: 0px;
      margin: 0px;
      margin-bottom: 0px;
    }

    transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);
  }
}
</style>
