<template>
  <div class="username-container d-inline-flex">
    <input
      :class="[hasError ? 'has-error' : 'has-no-error', 'transparent-input']"
      type="text"
      autocomplete="false"
      spellcheck="false"
      :data-testid="testId"
      :placeholder="placeholder"
      @input="updateValue($event.target.value)"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

@Component
export default class TextBoxAlt extends Vue {
  @Prop() placeholder!: string;
  @Prop({ default: false }) hasError!: boolean;
  @Prop({ default: undefined }) testId?: string;

  @Emit('input')
  private updateValue() {}
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400');

@media (min-width: 576px) {
  div.username-container {
    max-width: 480px;
  }
}
@media (max-width: 576px) {
  div.username-container {
    max-width: 380px;
  }
}
input.transparent-input {
  width: 100%;
  min-width: 200px;
  background-color: transparent;
  border-width: 0 0 1px 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  padding-left: 10px;
  box-sizing: border-box;
  color: var(--login-fields-text);
  outline: none;
}
input.transparent-input:-moz-placeholder {
  color: var(--login-fields-text);
  opacity: 1;
}
input.transparent-input::-moz-placeholder {
  color: var(--login-fields-text);
  opacity: 1;
}
input.transparent-input:hover {
  color: white;
  border-color: white;
}
input.has-no-error {
  border-color: var(--login-fields-gray);
}
input.has-error {
  border-color: var(--login-errors);
}
input.transparent-input:focus {
  color: white;
  border-color: white;
  border-width: 0 0 1px 0;
}
input.transparent-input::placeholder {
  color: var(--login-fields-text);
  text-transform: uppercase;
}
input.transparent-input::placeholder:hover {
  color: white;
}
</style>
