<template>
  <div class="basic-account-editor">
    <editor-header>
      <template slot="header">
        <span>
          {{ account.name }}
        </span>
        <span class="editing-header-text"> . {{ EditCaption }} </span>
      </template>

      <template slot="buttons">
        <header-icon-button iconName="fa-times" @clicked="closeButtonClicked" />
      </template>
    </editor-header>

    <editor-content>
      <text-input
        class="mb-2"
        :label="NameCaption"
        :value.sync="name"
        :labelWidth="labelWidth"
        :invalid="nameValidationFailed"
        :disabled="isLoading"
        placeholder="Enter company name..."
      />

      <select-input
        class="mb-2"
        :label="CountryCaption"
        :options.sync="countryItems"
        :disabled="isLoading"
        :labelWidth="labelWidth"
        :readonly="false"
        :selected-option.sync="country"
        :GetId="a => a.toString()"
        :GetName="a => GetCountryName(a)"
      >
      </select-input>

      <select-input
        class="mb-2"
        :label="StateCaption"
        :options.sync="stateItems"
        :invalid="stateValidationFailed"
        :disabled="isLoading"
        :labelWidth="labelWidth"
        :readonly="false"
        :selected-option.sync="state"
        :GetId="a => a.toString()"
        :GetName="a => GetStateName(a)"
      >
      </select-input>

      <text-input
        class="mb-2"
        :label="PostalCodeCaption"
        :value.sync="postalCode"
        :labelWidth="labelWidth"
        :invalid="postalCodeValidationFailed"
        :disabled="isLoading"
        placeholder="Enter postal code..."
      />

      <text-input
        class="mb-2"
        :label="CityCaption"
        :value.sync="city"
        :labelWidth="labelWidth"
        :invalid="cityValidationFailed"
        :disabled="isLoading"
        placeholder="Enter city name..."
      />

      <text-input
        class="mb-2"
        :label="EmailCaption"
        :value.sync="email"
        :labelWidth="labelWidth"
        :invalid="emailValidationFailed"
        :disabled="isLoading"
        placeholder="Enter email..."
      />

      <text-input
        :label="AddressCaption"
        :value.sync="address"
        :labelWidth="labelWidth"
        :invalid="addressValidationFailed"
        :disabled="isLoading"
        placeholder="Enter account address..."
      />

      <text-input
        v-if="TaxIDNeeded"
        class="mt-2"
        :label="VatIDCaption"
        :value.sync="taxId"
        :labelWidth="labelWidth"
        :invalid="taxIdValidationFailed"
        :disabled="isLoading"
        placeholder="Enter VAT ID..."
      />

      <fade-transition :maxHeight="28">
        <div v-if="validationErrors.length != 0" class="errors-container">
          <span class="mt-2">{{ validationErrors[0] }}</span>
        </div>
      </fade-transition>

      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </editor-content>

    <editor-footer>
      <template slot="buttons">
        <icon-button
          class="mr-3"
          :disabled="isLoading || !HasChanges"
          iconName="fa-check"
          :text="ApplyCaption"
          @clicked="applyButtonClicked"
        />
        <icon-button
          class="mr-3"
          :disabled="isLoading"
          iconName="fa-sync-alt"
          :text="ResetCaption"
          @clicked="resetButtonClicked"
        />
        <icon-button
          :disabled="isLoading"
          iconName="fa-times"
          :iconSize="15"
          :text="CancelCaption"
          @clicked="closeButtonClicked"
        />
      </template>
    </editor-footer>
  </div>
</template>

<script lang="ts">
import en from '@/localization/en';
import { Countries, States } from '@/models/responses/Countries';
import { Account } from '@/models/Entities';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import FadeTransition from '@/components/presentation/FadeTransition.vue';
import * as EmailValidator from 'email-validator';
import ComponentHelper from '@/util/ComponentHelper';
import { AccountModule } from '@/store/modules/billing/accountModule';
import { toast } from '@/main';
import { POSITION } from 'vue-toastification';
import IconButton from '../buttons/IconButton.vue';
import EditorFooter from '@/components/forms/base/EditorFooter.vue';
import EditorHeader from '@/components/forms/base/EditorHeader.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import EditorContent from '@/components/forms/base/EditorContent.vue';
import SelectInput from '@/components/inputs/SelectInput.vue';
import HeaderIconButton from '@/components/buttons/HeaderIconButton.vue';

@Component({
  name: 'billing-account-editor',
  components: {
    HeaderIconButton,
    FadeTransition: FadeTransition,
    IconButton: IconButton,
    EditorHeader: EditorHeader,
    EditorContent: EditorContent,
    EditorFooter: EditorFooter,
    TextInput: TextInput,
    SelectInput: SelectInput,
  },
})
export default class BillingAccountEditor extends Vue {
  labelWidth: number = 100;
  //#region PROPS + WATCHERS
  @Prop()
  private account!: Account;

  @Watch('account', { immediate: true })
  OnAccountChanged(newValue: Account) {
    this.setLocalStateFromAccount(newValue);
  }

  @Watch('country', { immediate: true })
  OnCountryChanged(newValue: Countries) {
    if (newValue) {
      this.InitializeStates();
    }
  }

  @Watch('TaxIDNeeded', { immediate: true })
  OnTaxIdNeededChanged(newValue: boolean) {
    if (newValue) {
      if (this.country == this.account.country && this.state == this.account.state) {
        this.taxId = this.account.taxId;
      }
    } else {
      this.taxId = null;
    }
  }
  //#endregion

  //#region STATE
  private validationTimeoutHandle: number = -1;
  private nameValidationFailed: boolean = false;
  private stateValidationFailed: boolean = false;
  private postalCodeValidationFailed: boolean = false;
  private cityValidationFailed: boolean = false;
  private addressValidationFailed: boolean = false;
  private emailValidationFailed: boolean = false;
  private taxIdValidationFailed: boolean = false;
  private validationErrors: string[] = [];

  private name: string = '';
  private country: Countries = Countries.RussianFederation;
  private state: States | null = null;
  private postalCode: string = '';
  private city: string = '';
  private address: string = '';
  private email: string = '';
  private taxId: string | null = null;

  private isLoading: boolean = false;

  private get HasChanges(): boolean {
    return (
      this.name != this.account.name ||
      this.country != this.account.country ||
      this.state != this.account.state ||
      this.postalCode != this.account.postalCode ||
      this.city != this.account.city ||
      this.address != this.account.address ||
      this.email != this.account.email
    );
  }

  private get TaxIDNeeded(): boolean {
    return this.CountryEuropean && !this.CountryIsLuxembourg;
  }

  private get CountryIsLuxembourg(): boolean {
    return this.country == Countries.Luxembourg;
  }

  private get CountryEuropean(): boolean {
    return ComponentHelper.IsCountryInEurope(this.country);
  }

  private countryItems: Countries[] = [];
  private GetCountryName(country: Countries): string {
    return en.CountryNames[country];
  }

  private stateItems: States[] = [];
  private GetStateName(state: States): string {
    return en.StateNames[state];
  }
  //#endregion

  //#region STATE LOGIC
  private resetButtonClicked() {
    this.name = this.account.name;
    this.country = this.account.country;
    this.state = this.account.state;
    this.postalCode = this.account.postalCode;
    this.city = this.account.city;
    this.address = this.account.address;
    this.email = this.account.email;
    this.taxId = this.account.taxId;
  }

  private InitializeCountryItems() {
    for (let key in Countries) {
      if (!isNaN(Number(key))) continue;
      let country = Countries[key as keyof typeof Countries];
      this.countryItems.push(country);
    }
  }

  private InitializeStates() {
    this.stateItems = [];

    const statesForCountry = ComponentHelper.StatesForCountry(this.country);

    if (this.country != this.account.country) {
      this.state = null;
    } else {
      this.state = this.account.state;
    }

    for (const state of statesForCountry) {
      this.stateItems.push(state);
    }
  }

  private setLocalStateFromAccount(account: Account) {
    this.name = account.name;
    this.country = account.country;
    this.state = account.state;
    this.postalCode = account.postalCode;
    this.city = account.city;
    this.address = account.address;
    this.email = account.email;
    this.taxId = account.taxId;
  }

  private clearValidationErrors() {
    if (this.validationTimeoutHandle !== -1) {
      clearTimeout(this.validationTimeoutHandle);
    }

    this.validationErrors = [];
    this.nameValidationFailed = false;
    this.stateValidationFailed = false;
    this.postalCodeValidationFailed = false;
    this.cityValidationFailed = false;
    this.emailValidationFailed = false;
    this.taxIdValidationFailed = false;
    this.validationTimeoutHandle = -1;
  }

  public validate(): boolean {
    this.clearValidationErrors();

    // Validate
    if (this.name == null || this.name.length == 0) {
      this.validationErrors.push('Enter account name');
      this.nameValidationFailed = true;
    } else if (this.address == null || this.address.length == 0) {
      this.validationErrors.push('Enter account address');
      this.addressValidationFailed = true;
    } else if (this.stateItems.length > 0 && this.state == null) {
      this.validationErrors.push('Enter state');
      this.stateValidationFailed = true;
    } else if (this.postalCode == null || this.postalCode.length == 0) {
      this.validationErrors.push('Enter postal code');
      this.postalCodeValidationFailed = true;
    } else if (this.city == null || this.city.length == 0) {
      this.validationErrors.push('Enter city');
      this.cityValidationFailed = true;
    } else if (this.email == null || this.email.length == 0) {
      this.validationErrors.push('Enter email');
      this.emailValidationFailed = true;
    } else if (!EmailValidator.validate(this.email)) {
      this.validationErrors.push('Enter valid email');
      this.emailValidationFailed = true;
    } else if (this.CountryEuropean && !this.CountryIsLuxembourg && (this.taxId == null || this.taxId.length == 0)) {
      this.validationErrors.push('Enter VAT ID');
      this.taxIdValidationFailed = true;
    }

    const failed = this.validationErrors.length != 0;

    if (failed) {
      this.validationTimeoutHandle = window.setTimeout(() => {
        this.clearValidationErrors();
      }, 3500);
    }

    return !failed;
  }
  //#endregion

  //#region EVENTS
  private closeButtonClicked() {
    this.$emit('close-clicked');
  }

  private async applyButtonClicked() {
    if (!this.validate()) {
      return;
    }

    this.isLoading = true;

    const result = await AccountModule.UpdateAccountInfo({
      id: this.account.id.toString(),
      name: this.name,
      country: this.country,
      state: this.state,
      postalCode: this.postalCode,
      city: this.city,
      address: this.address,
      email: this.email,
      taxId: this.taxId,
    });

    this.isLoading = false;

    if (result.success) {
      toast.success(en.changedBillingCompanyInfo, {
        position: POSITION.BOTTOM_RIGHT,
      });

      this.closeButtonClicked();
    } else {
      toast.error(result.message, {
        position: POSITION.BOTTOM_RIGHT,
      });
    }
  }
  //#endregion

  //#region HOOKS
  async created() {
    this.InitializeCountryItems();
  }

  async beforeDestroy() {}
  //#endregion

  //#region TRANSLATIONS
  private get CompanyCaption(): string {
    return en.company.titleCase();
  }
  private get EditCaption(): string {
    return en.edit.toUpperCase();
  }
  private get AddressCaption(): string {
    return en.address.titleCase();
  }
  private get NameCaption(): string {
    return en.nameCaption.titleCase();
  }
  private get DescriptionCaption(): string {
    return en.description.titleCase();
  }
  private get CountryCaption(): string {
    return en.country.titleCase();
  }
  private get ApplyCaption(): string {
    return en.apply.titleCase();
  }
  private get ResetCaption(): string {
    return en.reset.titleCase();
  }
  private get CancelCaption(): string {
    return en.cancel.titleCase();
  }
  private get StateCaption(): string {
    return 'State';
  }
  private get PostalCodeCaption(): string {
    return 'Zip-code';
  }
  private get CityCaption(): string {
    return 'City';
  }
  private get EmailCaption(): string {
    return 'Email';
  }
  private get VatIDCaption(): string {
    return 'VAT ID';
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.basic-account-editor {
  background: var(--editor-background);
  border-radius: 6px;

  span.editing-header-text {
    color: #f63e00;
  }

  .errors-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      color: var(--editor-error-text);
      font-size: 14px;
    }
  }
}
</style>
