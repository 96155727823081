import Vue from 'vue';
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { HubConnection } from '@microsoft/signalr';

import * as ips from '@/util/api/ips';
import * as routes from '@/util/api/routes';
import * as hubActions from '@/util/api/hubActions';
import { Guid } from 'guid-typescript';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { UtilModule } from './utilModule';
import store from '@/store';
import { Source, MaterialConsumption } from '@/models/Entities';
import '@/models/responses/ResponseMonolithExtensions';
import {
  ResponseMonolithHelper,
  ResponseSources,
  ResponseSource,
  ResponseSourceGetSizeLimit,
  ResponseSourceGetTotalSize,
} from '@/models/responses/ResponseMonolith';
import {
  RequestReadSourceByIds,
  RequestReadMoreSources,
  SourceSortBy,
  RequestDeleteSource,
  RequestUpdateSourceSourceGroup,
  RequestReadSourceById,
  RequestDownloadSource,
  RequestReadMoreSourcesByGroupId,
} from '@/models/requests/RequestMonolith';
import { New_GC } from '../util/GC';
import { TimeSpan, timeToTimeSpan } from '@/util/TimeSpan';
import { GuidHelper } from '@/util/GuidHelper';
import { LoginModule } from './loginModule';
import { ErrorModule } from '@/store/modules/errorsModule';
import { SortMode } from '@/models/requests/Datas';
import { StoreHelper } from '../util/storeHelpers';
import { TypeHelper } from '@/util/TypeHelper';

export interface SourceDownload {
  id: Guid;
  source: Source;
  bytesDownloaded: number;
  bytesTotal: number;
  fileName: string;
}

export const GCODE_FORM_KEY = 'contentCode';
export const PROJECT_FORM_KEY = 'contentProject';
export const GROUP_ID_FORM_KEY = 'groupId';
export const SOURCE_ID_FORM_KEY = 'id';

export const GCODE_EXTENSION = '.gcode';
export const PROJECT_EXTENSION = '.auprojx';

export const FILE_SIZE_LIMIT_IN_MB = 50;

@Module({ dynamic: true, name: 'source', store: store })
export default class sourceModule extends VuexModule {
  private sources: Source[] = [];
  private sourceDownloads: SourceDownload[] = [];

  private _newGC = new New_GC();

  get Sources() {
    return this.sources;
  }

  get SourceDownload() {
    return this.sourceDownloads;
  }

  @Mutation
  ClearSources() {
    this._newGC.Remove(this.sources.map(a => a.Id));
    this.sources = [];
  }

  @Mutation
  AddSourceToModule(value: Source) {
    if (this.sources.find(a => a.Id.equals(value.Id))) return;
    // this._gc.UpdateSubscription(value.Id);
    this._newGC.Add([value.Id]);
    this.sources.push(value);
  }

  @Mutation
  DeleteSourceFromModule(value: Source) {
    // this._gc.DeleteSubscription(value.Id);
    this._newGC.Remove([value.Id]);
    this.sources.delete(value);
  }

  @Mutation
  AddSourceDownload(download: SourceDownload) {
    this.sourceDownloads.push(download);
  }

  @Mutation
  RemoveSourceDownload(download: SourceDownload) {
    this.sourceDownloads.delete(download);
  }

  @Mutation
  UpdateSourceDownload(download: SourceDownload) {
    this.sourceDownloads = this.sourceDownloads.map(a => {
      if (a.id.equals(download.id)) {
        a.bytesDownloaded = download.bytesDownloaded;
        a.bytesTotal = download.bytesTotal;
      }

      return a;
    });
  }

  @Mutation
  UpdateSourceCodeFileName([source, codeFileName]: [Source, string]) {
    source.CodeFileName = codeFileName;
  }
  @Mutation
  UpdateSourceCodePath([source, codePath]: [Source, string]) {
    source.CodePath = codePath;
  }
  @Mutation
  UpdateSourceCreationDateTime([source, creationDateTime]: [Source, Date]) {
    source.CreationDateTime = creationDateTime;
  }
  @Mutation
  UpdateSourceGroupId([source, groupId]: [Source, Guid | null]) {
    source.GroupId = groupId;
  }
  @Mutation
  UpdateSourceMaterialConsumptions([source, materialConsumptions]: [Source, MaterialConsumption[]]) {
    source.MaterialConsumptions = materialConsumptions;
  }
  @Mutation
  UpdateSourcePrintDuration([source, printDuration]: [Source, TimeSpan | null]) {
    source.PrintDuration = printDuration;
  }
  @Mutation
  UpdateSourceProjectPath([source, projectPath]: [Source, string | null]) {
    source.ProjectPath = projectPath;
  }
  @Mutation
  UpdateSourceProjectFilename([source, projectFilename]: [Source, string | null]) {
    source.ProjectFileName = projectFilename;
  }
  @Mutation
  UpdateSourceCategory([source, groupId]: [Source, Guid | null]) {
    source.GroupId = groupId;
  }
  @Mutation
  UpdateSourcePrintCount([source, printCount]: [Source, number]) {
    source.PrintCount = printCount;
  }
  @Mutation
  UpdateSourceCodeFileSize([source, newSize]: [Source, number]) {
    source.CodeFileSize = newSize;
  }
  @Mutation
  UpdateSourceProjectFileSize([source, newSize]: [Source, number]) {
    source.ProjectFileSize = newSize;
  }
  @Mutation
  UpdateSourceLastPrint([source, lastPrint]: [Source, Date | null]) {
    source.LastPrint = lastPrint;
  }
  @Action({ rawError: true })
  async GetSource(sourceId: Guid): Promise<Source | null> {
    const inLst = this.Sources.singleOrDefault(a => a.Id.equals(sourceId));
    if (inLst) return inLst;
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.SOURCE,
      routes.UriSource.READ_BY_ID,
    );
    const request = new RequestReadSourceById();
    request.id = sourceId.toString();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealSource(axiosResponse.data.response as ResponseSource);
      const source = response.Map();
      this.AddSourceToModule(source);
      return source;
    } else {
      ErrorModule.ShowError(message);
    }
    return null;
  }
  @Action({ rawError: true })
  async LoadSources(ids: Guid[]): Promise<Source[]> {
    const toAdd = GuidHelper.except(
      ids,
      this.Sources.map(a => a.Id),
    );
    if (toAdd.any()) {
      const sources = await this.JustLoadSourcesByIds(ids);
      for (const item of sources) {
        this.AddSourceToModule(item);
      }
    }
    return this.Sources.filter(a => GuidHelper.includes(ids, a.Id));
  }
  @Action({ rawError: true })
  async JustLoadSourcesByIds(ids: Guid[]): Promise<Source[]> {
    if (ids.empty()) return [];
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.SOURCE,
      routes.UriSource.READ_SOURCES_BY_IDS,
    );
    const request = new RequestReadSourceByIds();
    request.ids = ids.map(a => a.toString());
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealSources(axiosResponse.data.response as ResponseSources);
      return response.jobSources.map(a => a.Map());
    } else ErrorModule.ShowError(message);
    return [];
  }
  @Action({ rawError: true })
  async LoadAndAddSources([page, pageSize, sortBy, sortMode]: [
    number,
    number,
    SourceSortBy | null,
    SortMode | null,
  ]): Promise<Source[]> {
    const sources = await this.JustLoadSources([page, pageSize, sortBy, sortMode]);
    if (sources.empty()) return [];
    const sourceIds = sources.map(a => a.Id);
    const inLst = this.Sources.filter(a => GuidHelper.includes(sourceIds, a.Id));
    const inLstIds = inLst.map(a => a.Id);
    const toAdd = sources.filter(a => !GuidHelper.includes(inLstIds, a.Id));
    for (const item of toAdd) {
      this.AddSourceToModule(item);
    }
    return this.Sources.filter(a => GuidHelper.includes(sourceIds, a.Id));
  }
  @Action({ rawError: true })
  async JustLoadSources([page, pageSize, sortBy, sortMode, search]: [
    number,
    number,
    SourceSortBy | null,
    SortMode | null,
    string?,
  ]): Promise<Source[]> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.SOURCE,
      routes.UriSource.READ_MORE,
    );

    this._newGC.Lock();

    const request = new RequestReadMoreSources();
    request.page = page;
    request.pageSize = pageSize;
    if (sortBy !== null) request.sortBy = sortBy;
    if (sortMode !== null) request.sortMode = sortMode;
    if (search !== undefined) request.search = search;
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealSources(axiosResponse.data.response as ResponseSources);

      Vue.nextTick(() => {
        this._newGC.Unlock();
      });

      return response.jobSources.map(a => a.Map());
    } else ErrorModule.ShowError(message);

    Vue.nextTick(() => {
      this._newGC.Unlock();
    });

    return [];
  }
  @Action({ rawError: true })
  async JustLoadSourcesByGroupId([groupId, page, pageSize, sortBy, sortMode, search]: [
    Guid,
    number,
    number,
    SourceSortBy | null,
    SortMode | null,
    string?,
  ]): Promise<Source[]> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.SOURCE,
      routes.UriSource.READ_MORE_BY_GROUP_ID,
    );

    this._newGC.Lock();

    const outer = new RequestReadMoreSources();
    outer.page = page;
    outer.pageSize = pageSize;
    if (sortBy !== undefined) outer.sortBy = sortBy;
    if (sortMode !== undefined) outer.sortMode = sortMode;
    if (search !== undefined) outer.search = search;

    const request = new RequestReadMoreSourcesByGroupId();
    request.outer = outer;
    request.groupId = groupId.toString();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealSources(axiosResponse.data.response as ResponseSources);

      Vue.nextTick(() => {
        this._newGC.Unlock();
      });

      return response.jobSources.map(a => a.Map());
    } else ErrorModule.ShowError(message);

    Vue.nextTick(() => {
      this._newGC.Unlock();
    });

    return [];
  }
  @Action({ rawError: true })
  async AddNewSource(formData: FormData): Promise<[RequestStatus, string, Source | null]> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.SOURCE,
      routes.UriSource.CREATE,
    );
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPostFile(uri, formData);
    let newSource: Source | null = null;

    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealSource(axiosResponse.data.response as ResponseSource);
      newSource = response.Map();
      this.AddSourceToModule(newSource);
    }
    return [result, message, newSource];
  }
  @Action({ rawError: true })
  async RequestSourceSizeLimit(): Promise<number> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.SOURCE,
      routes.UriSource.SIZE_LIMIT,
    );

    const { result, axiosResponse } = await UtilModule.SHW.ProcessGet(uri, null);

    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealSizeLimit(
        axiosResponse.data.response as ResponseSourceGetSizeLimit,
      );

      return response.sizeLimitInBytes;
    }

    return -1;
  }

  @Action({ rawError: true })
  async RequestSourceTotalSize(): Promise<number> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.SOURCE,
      routes.UriSource.TOTAL_SIZE,
    );

    const { result, axiosResponse } = await UtilModule.SHW.ProcessGet(uri, null);

    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealTotalSize(
        axiosResponse.data.response as ResponseSourceGetTotalSize,
      );

      return response.totalSizeInBytes;
    }

    return -1;
  }
  @Action({ rawError: true })
  async DeleteSource(source: Source): Promise<[RequestStatus, string]> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.SOURCE,
      routes.UriSource.DELETE,
    );
    const request = new RequestDeleteSource();
    request.id = source.Id.toString();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      this.DeleteSourceFromModule(source);
    }
    return [result, message];
  }
  @Action({ rawError: true })
  async UpdateGCode(formData: FormData): Promise<[RequestStatus, string]> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.SOURCE,
      routes.UriSource.UPDATE_CODE,
    );
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPostFile(uri, formData);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealSource(axiosResponse.data.response as ResponseSource);
      const obj = response.Map();
      const source = this.Sources.singleOrDefault(a => a.Id.equals(obj.Id));
      if (source == null) this.AddSourceToModule(obj);
      else {
        source.CodePath = obj.CodePath;
        source.CodeFileName = obj.CodeFileName;
      }
    }
    return [result, message];
  }
  @Action({ rawError: true })
  async UpdateAuproj(formData: FormData): Promise<[RequestStatus, string]> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.SOURCE,
      routes.UriSource.UPDATE_PROJECT,
    );
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPostFile(uri, formData);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealSource(axiosResponse.data.response as ResponseSource);
      const obj = response.Map();
      const source = this.Sources.singleOrDefault(a => a.Id.equals(obj.Id));
      if (source == null) this.AddSourceToModule(obj);
      else {
        source.ProjectFileName = obj.ProjectFileName;
        source.ProjectPath = obj.ProjectPath;
      }
    }
    return [result, message];
  }
  @Action({ rawError: true })
  async UpdateCategory([source, groupId]: [Source, Guid | null]): Promise<[RequestStatus, string]> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.SOURCE,
      routes.UriSource.UPDATE_CATEGORY,
    );
    const request = new RequestUpdateSourceSourceGroup();
    request.id = source.Id.toString();
    if (groupId != null) request.groupId = groupId.toString();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      source.GroupId = groupId;
    }
    return [result, message];
  }

  @Action({ rawError: true })
  downloadGCodeFile(source: Source) {
    return this.downLoadFile({
      source,
      fileName: source.CodeFileName ?? 'g-code',
      apiPath: routes.UriSource.DOWNLOAD_G_CODE,
    });
  }

  @Action({ rawError: true })
  downloadProjectFile(source: Source) {
    return this.downLoadFile({
      source,
      fileName: source.ProjectFileName ?? 'project',
      apiPath: routes.UriSource.DOWNLOAD_PROJECT,
    });
  }

  @Action({ rawError: true })
  async downLoadFile({ source, fileName, apiPath }: { source: Source; fileName: string; apiPath: string }) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.SOURCE,
      apiPath,
    );

    const request = new RequestDownloadSource();
    request.sourceId = source.Id.toString();

    const download: SourceDownload = {
      source: source,
      id: Guid.create(),
      bytesDownloaded: 0,
      bytesTotal: 1337,
      fileName,
    };

    this.AddSourceDownload(download);

    const result = await UtilModule.SHW.ProcessDownloadFile(uri, request, (curr, total) => {
      download.bytesDownloaded = curr;
      download.bytesTotal = total;

      this.UpdateSourceDownload(download);
    });

    if (result) {
      StoreHelper.DownloadBlob(result.blob, result.fileName);
    }

    this.RemoveSourceDownload(download);
  }

  @Action({ rawError: true })
  async LooseSources([sources, componentId]: [Source[], Guid]) {
    // console.log(`Loose ${sources.length} sources by ${componentId.toString()}`);

    this._newGC.Loose(
      sources.map(a => a.Id),
      componentId,
    );
  }
  @Action({ rawError: true })
  async CollectSources() {
    const removed = this._newGC.Collect();

    // console.log("Removing " + removed.length + " sources");

    for (const job of this.sources.filter(a => GuidHelper.includes(removed, a.Id))) {
      this.DeleteSourceFromModule(job);
    }
  }
  @Action({ rawError: true })
  async OccupySources([sources, componentId]: [Source[], Guid]) {
    // console.log(`Occupy ${sources.length} sources by ${componentId.toString()}`);

    this._newGC.Occupy(
      sources.map(a => a.Id),
      componentId,
    );
  }

  @Action({ rawError: true })
  async SourceSubscribe(hubConnection: HubConnection) {
    hubConnection?.on(hubActions.RECEIVE_CREATE_SOURCE, (response: ResponseSource) => {
      const realResponse = ResponseMonolithHelper.createRealSource(response);
      this.AddSourceToModule(realResponse.Map());
    });
    hubConnection?.on(hubActions.RECEIVE_UPDATE_SOURCE, (response: ResponseSource) => {
      const realResponse = ResponseMonolithHelper.createRealSource(response);
      const source = this.Sources.find(a => a.Id.equals(realResponse.id));
      if (!source) {
        return;
      }

      if (source.CodeFileName !== realResponse.codeFileName)
        this.UpdateSourceCodeFileName([source, realResponse.codeFileName]);
      if (
        (source.GroupId == null && realResponse.groupId != null) ||
        (source.GroupId != null && realResponse.groupId == null) ||
        (source.GroupId != null && realResponse.groupId != null && !source.GroupId.equals(realResponse.groupId))
      ) {
        this.UpdateSourceCategory([source, realResponse.groupId]);
      }
      if (source.LastPrint != realResponse.lastPrint) {
        const date = realResponse.lastPrint == null ? null : TypeHelper.StringToDate(realResponse.lastPrint);
        this.UpdateSourceLastPrint([source, date]);
      }
      if (source.PrintCount != realResponse.printCount) {
        this.UpdateSourcePrintCount([source, realResponse.printCount]);
      }
      if (source.CodePath !== realResponse.codePath) this.UpdateSourceCodePath([source, realResponse.codePath]);
      const olds = source.MaterialConsumptions.sort((a, b) => (a.Id.toString() > b.Id.toString() ? 1 : -1));
      const news = realResponse.materialConsumptions
        .sort((a, b) => (a.id.toString() > b.id.toString() ? 1 : -1))
        .map(a => a.Map());
      let hasDiff = false;
      if (olds.length !== news.length) hasDiff = true;
      else {
        for (let i = 0; i < olds.length; i++) {
          if (olds[i].Equals(news[i])) continue;
          hasDiff = true;
          break;
        }
      }
      if (source.CodeFileSize != realResponse.codeFileSize) {
        this.UpdateSourceCodeFileSize([source, realResponse.codeFileSize]);
      }
      if (source.ProjectFileSize != realResponse.projectFileSize) {
        this.UpdateSourceProjectFileSize([source, realResponse.projectFileSize!]);
      }
      if (hasDiff) {
        this.UpdateSourceMaterialConsumptions([source, news]);
      }

      const newDuration = timeToTimeSpan(realResponse.printDuration);
      if (source.PrintDuration?.totalMilliseconds !== newDuration?.totalMilliseconds) {
        this.UpdateSourcePrintDuration([source, newDuration]);
      }

      if (source.ProjectPath !== realResponse.projectPath)
        this.UpdateSourceProjectPath([source, realResponse.projectPath]);
      if (source.ProjectFileName !== realResponse.projectFileName)
        this.UpdateSourceProjectFilename([source, realResponse.projectFileName]);
      if (
        (source.UserId && !realResponse.userId) ||
        (!source.UserId && realResponse.userId) ||
        (source.UserId && realResponse.userId && !source.UserId.equals(realResponse.userId))
      ) {
        Vue.set(source, 'UserId', realResponse.userId);
      }
    });
    hubConnection?.on(hubActions.RECEIVE_DELETE_SOURCE, (response: ResponseSource) => {
      const realResponse = ResponseMonolithHelper.createRealSource(response);
      const inModule = this.Sources.find(a => a.Id.equals(realResponse.id));
      if (!inModule) return;
      this.DeleteSourceFromModule(inModule);
    });
  }
  @Action({ rawError: true })
  async SubscribeToCompanySourcesGroup() {
    if (LoginModule.MonolithConnection == null) return;
    await LoginModule.MonolithConnection.send(hubActions.ADD_TO_COMPANY_JOB_SOURCES_GROUP);

    LoginModule.AddMonolithConnectionGroup({
      func: this.SubscribeToCompanySourcesGroup,
    });
  }
  @Action({ rawError: true })
  async DeleteFromCompanySourcesGroup() {
    if (LoginModule.MonolithConnection == null) return;
    await LoginModule.MonolithConnection.send(hubActions.DELETE_FROM_COMPANY_JOB_SOURCES_GROUP);

    LoginModule.DeleteMonolithConnectionGroup({
      func: this.DeleteFromCompanySourcesGroup,
    });
  }
}

export const SourceModule = getModule(sourceModule);
