type LoadMoreOnScrollOptions = {
  target: HTMLElement;
  isLoading: boolean;
  hasMore: boolean;
  setLoading: (loading: boolean) => void;
  loadMore: () => Promise<void>;
  nextPage: () => void;
  offset?: number;
};

export const loadMoreOnScroll = async (options: LoadMoreOnScrollOptions) => {
  const { target, isLoading, hasMore, setLoading, loadMore, nextPage, offset = 100 } = options;
  const currScroll = target.scrollTop;
  const endScroll = target.scrollHeight - target.clientHeight;

  if (!hasMore || isLoading) {
    return;
  }

  if (currScroll >= endScroll - offset && currScroll > 0) {
    setLoading(true);
    nextPage();
    await loadMore();

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }
};
