<template>
  <div :class="[disabled == true ? 'disabled' : '', 'button-container', 'd-inline-flex']">
    <button :disabled="disabled" :data-testid="testId" @click="onClick">{{ text }}</button>
  </div>
</template>

<script lang="ts">
import { Prop, Vue, Emit, Component } from 'vue-property-decorator';

@Component
export default class TransparentButton extends Vue {
  @Prop({ default: '' }) text!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: undefined }) testId?: string;

  @Emit('click')
  private onClick() {}
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto');

@media (min-width: 576px) {
  div.button-container {
    max-width: 480px;
  }
}
@media (max-width: 576px) {
  div.button-container {
    max-width: 380px;
  }
}

div.button-container {
  background-color: transparent;
  width: 100%;
  min-width: 200px;
  border: var(--login-fields-gray) solid 1px;
}
div.button-container button {
  width: 100%;
  background-color: transparent;
  color: var(--login-fields-text);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  text-transform: uppercase;
  outline: none;
  border-width: 0;
  padding: 5px 0;
  box-sizing: border-box;
  height: 46px;
}
div.disabled button {
  color: var(--login-fields-gray);
}
div.button-container:hover {
  border-color: white;
}
div.button-container button:hover {
  color: white;
}
div.disabled:hover button {
  color: var(--login-fields-gray);
}
div.disabled:hover {
  border-color: var(--login-fields-gray);
}
</style>
