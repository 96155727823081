<template>
  <AuraDialogWrapper
    ref="dialogWrapper"
    :headerText="header"
    :testId="testId"
    @closed="DialogWrapperClosed"
    @dismissed="DialogWrapperClosed"
  >
    <div class="thin-scroll mb-2" style="max-height: 50vh; overflow: auto" @scroll="OnUsersScrolled">
      <SortableHeader
        :header-items="UsersHeader"
        :by.sync="SortByUserHeader"
        :mode.sync="UserSortMode"
        :testId="testId"
        @sortBy="OnUserSortBy"
      />
      <div class="thin-scroll">
        <ListItemV3
          v-for="item in CompanyUsers"
          :id="item.id"
          :key="item.Id.toString()"
          :items="UserListItems(item)"
          :testId="testId"
          @openItem="NewCompanyAdminUserSelected(item)"
        />
      </div>
    </div>
  </AuraDialogWrapper>
</template>

<script lang="ts">
import { Component, Prop, Ref } from 'vue-property-decorator';
import { DialogComponent } from 'vue-modal-dialogs';
import { delay } from 'lodash';
import { Company, CompanyUser, LoginSession, Role, User } from '@/models/Entities';

import SortableHeader from '@/components/presentation/SortableHeader.vue';
import ListItemV3 from '@/components/presentation/ListItemV3.vue';
import ComponentHelper, { HeaderItem, ItemData, SortMode } from '@/util/ComponentHelper';
import en from '@/localization/en';
import { Guid } from 'guid-typescript';
import { LoadUsersPageData, SortUsers, UserModule, UserPageSize } from '@/store/modules/userModule';
import { LoginSessionModule } from '@/store/modules/loginSessionModule';
import { UserSortBy } from '@/models/requests/RequestIdentity';
import { JobModule } from '@/store/modules/jobModule';

import AuraDialogWrapper from '@/views/dialogs/base/AuraDialogWrapper.vue';

//#region CONSTS
const EMAIL_WIDTH = 250;
const FULLNAME_WIDTH = 225;
const LASTSEEN_WIDTH = 125;
//#endregion

@Component({
  components: {
    SortableHeader: SortableHeader,
    ListItemV3: ListItemV3,
    AuraDialogWrapper: AuraDialogWrapper,
  },
})
export default class SelectUserDialog extends DialogComponent<CompanyUser | null> {
  @Ref() dialogWrapper!: AuraDialogWrapper;

  @Prop() private company!: Company;
  @Prop() private includeRoles!: Role[];
  @Prop() private excludeRoles!: Role[];
  @Prop() private header!: string;
  @Prop({ default: undefined }) testId?: string;
  private componentId!: Guid;

  private sortByUserHeader: HeaderItem | null = null;
  private userSortMode: SortMode | null = null;
  private userPage: number = 0;

  private isLoadingUsers: boolean = false;

  private get SortByUserHeader(): HeaderItem | null {
    return this.sortByUserHeader;
  }
  private set SortByUserHeader(value: HeaderItem | null) {
    this.sortByUserHeader = value;
  }
  private get UserSortMode(): SortMode | null {
    return this.userSortMode;
  }
  private set UserSortMode(value: SortMode | null) {
    this.userSortMode = value;
  }

  private get Users(): { user: User; loginSessions: LoginSession[] }[] {
    let users = UserModule.Users;

    let userWithSessions: {
      user: User;
      loginSessions: LoginSession[];
    }[] = [];

    for (let item of users) {
      let userLss = LoginSessionModule.LoginSessions.filter(a => a.UserId.equals(item.Id));
      userWithSessions.push({ user: item, loginSessions: userLss });
      LoginSessionModule.LoginSessionsSync([userLss, this.componentId]);
    }

    return userWithSessions;
  }

  private get CompanyUsers(): CompanyUser[] {
    if (this.Users.length == 0) return [];

    let companyUsers: CompanyUser[] = [];

    for (let item of this.Users) {
      if (item.user.Roles.firstOrDefault(r => this.excludeRoles.firstOrDefault(excR => excR.Id.equals(r.Id))) !== null)
        continue;
      if (
        item.user.Roles.firstOrDefault(r => this.includeRoles.firstOrDefault(inclR => inclR.Id.equals(r.Id))) === null
      )
        continue;

      let model = new CompanyUser();
      model.Id = item.user.Id;
      model.Email = item.user.Email;
      model.FullName = ComponentHelper.GetFullname(item.user);
      if (item.loginSessions.length > 0) {
        let lastSeenMax = item.loginSessions.map((a: LoginSession) => a.LastSeen.getTime()).max();
        let loginSession = item.loginSessions.firstOrDefault((a: LoginSession) => a.LastSeen.getTime() === lastSeenMax);
        if (loginSession != null) model.LastSeen = loginSession.LastSeen;
      } else model.LastSeen = new Date(0);
      model.TotalPrints = 0;
      companyUsers.push(model);
    }

    companyUsers = SortUsers(
      companyUsers.map(a => a),
      this.GetUserSortBy(this.SortByUserHeader),
      this.UserSortMode,
    );

    const maxCount = (this.userPage + 1) * UserPageSize;

    const toDisplay = companyUsers.slice(0, maxCount);
    const toLoose = companyUsers.slice(maxCount);

    UserModule.LooseUsersNew([toLoose.map(a => a.Id), this.componentId]);
    UserModule.OccupyUsers([toDisplay.map(a => a.Id), this.componentId]);

    if (toLoose.length > 0) {
      UserModule.CollectUsers();
    }

    return toDisplay;
  }

  async beforeCreate() {
    this.componentId = Guid.create();
  }

  async created() {
    UserModule.SubscribeToCompanyUsersGroup();

    UserModule.LooseUsersNew([this.CompanyUsers.map(a => a.Id), this.componentId!]);

    UserModule.CollectUsers();

    this.userPage = 0;

    await this.LoadMoreUsers();
  }

  async beforeDestroy() {
    // console.log("BEFORE DESTROY DIALOG");

    await UserModule.LooseUsersNew([this.Users.map(a => a.user.Id), this.componentId!]);

    UserModule.CollectUsers();
  }

  private userEmailHeader: HeaderItem = {
    caption: en.email.titleCase(),
    itemClass: ComponentHelper.GetWidthClass(EMAIL_WIDTH),
    isSortable: true,
  };
  private userFullNameHeader: HeaderItem = {
    caption: en.nameCaption.titleCase(),
    itemClass: ComponentHelper.GetWidthClass(FULLNAME_WIDTH),
    isSortable: true,
  };
  private userLastSeenHeader: HeaderItem = {
    caption: en.lastSeen.titleCase(),
    itemClass: ComponentHelper.GetWidthClass(LASTSEEN_WIDTH),
    isSortable: true,
  };
  private get UsersHeader(): HeaderItem[] {
    let headerItems: HeaderItem[] = [];
    headerItems.push(this.userEmailHeader);
    headerItems.push(this.userFullNameHeader);
    headerItems.push(this.userLastSeenHeader);
    return headerItems;
  }

  private UserListItems(value: CompanyUser): ItemData[] {
    let items: ItemData[] = [];
    let itemEmail: ItemData = new ItemData('Email', value.Email, EMAIL_WIDTH);
    let itemFullName: ItemData = new ItemData('FullName', value.FullName, FULLNAME_WIDTH);
    let itemLastSeen: ItemData = new ItemData(
      'LastSeen',
      ComponentHelper.GetSimpleDate(value.LastSeen),
      LASTSEEN_WIDTH,
    );
    items.push(itemEmail);
    items.push(itemFullName);
    items.push(itemLastSeen);
    return items;
  }

  private GetUserSortBy(headerItem: HeaderItem | null): UserSortBy | null {
    if (headerItem === this.userEmailHeader) return UserSortBy.BY_EMAIL;
    else if (headerItem === this.userFullNameHeader) return UserSortBy.BY_FULL_NAME;
    else if (headerItem === this.userLastSeenHeader) return UserSortBy.BY_LAST_SEEN;

    return null;
  }

  private GetUserLoadData(): LoadUsersPageData {
    return {
      companyId: this.company.Id,
      sortBy: this.GetUserSortBy(this.SortByUserHeader),
      sortMode: this.UserSortMode,
      page: this.userPage,
      includeRoles: this.includeRoles,
      excludeRoles: this.excludeRoles,
    };
  }

  private async LoadMoreUsers() {
    if (this.isLoadingUsers || this.company === null) return;

    this.isLoadingUsers = true;

    const loaded = await UserModule.LoadUsersForCompanyPaged(this.GetUserLoadData());
    await JobModule.LoadCountsForUsers(this.Users.map(a => a.user));

    this.isLoadingUsers = false;

    return loaded;
  }

  private async OnUserSortBy() {
    UserModule.LooseUsersNew([this.Users.map(a => a.user.Id), this.componentId]);
    UserModule.CollectUsers();
    this.userPage = 0;

    await this.LoadMoreUsers();
  }

  private async OnUsersScrolled(e: any) {
    const currScroll = e.target.scrollTop;
    const endScroll = e.target.scrollHeight - e.target.clientHeight;

    if (currScroll === endScroll && currScroll > 0 && !this.isLoadingUsers) {
      // console.log("LOADING MORE");
      ++this.userPage;
      var loaded = await this.LoadMoreUsers();

      if (loaded == 0) {
        --this.userPage;
      }
      delay(() => {
        this.isLoadingUsers = false;
      }, 500);
    }
  }

  private async NewCompanyAdminUserSelected(user: CompanyUser) {
    this.dialogWrapper.CloseDialog();
    this.$close(user);
  }

  private async DialogWrapperClosed() {
    this.$close(null);
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  background: var(--dialog-background);
  border-radius: 3px;
  overflow: auto;
  z-index: 2;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.35);
  animation: scaleIn 150ms ease-in;
  animation-fill-mode: forwards;

  &.closing {
    animation: scaleOut 150ms ease-in;
  }
}

.dialog-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--main-text);

  i {
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  }
}

.dialog-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1061;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.35);
  animation: fadeIn 150ms ease-in;
  animation-fill-mode: forwards;

  &.closing {
    animation: fadeOut 150ms ease-in;
  }
}

.dialog-dismiss-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
</style>
