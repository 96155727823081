<template>
  <AuraDialogWrapper ref="dialogWrapper" :headerText="HeaderText" @closed="DialogWrapperClosed">
    <div class="printer-connection-panel">
      <div class="printer-connection-content mt-3 mb-2">
        <div class="code">
          <span class="mr-3">{{ code }}</span>
          <i class="far fa-clone copy-icon" @click="CopyConnectionCodeToClipboard"></i>
        </div>
        <div class="separator my-2"></div>
        <div class="hint mt-3 mx-2">
          <span> Use this code to connect to {{ printer.Name }} </span>
        </div>
      </div>
    </div>
  </AuraDialogWrapper>
</template>

<script lang="ts">
import { toast } from '@/main';
import { Printer, PrinterState } from '@/models/Entities';
import { PrinterModule } from '@/store/modules/printerModule';
import { DialogComponent } from 'vue-modal-dialogs';
import { Component, Prop, Ref } from 'vue-property-decorator';
import { POSITION } from 'vue-toastification';
import AuraDialogWrapper from '@/views/dialogs/base/AuraDialogWrapper.vue';

@Component({
  components: {
    AuraDialogWrapper: AuraDialogWrapper,
  },
})
export default class UpdateConnectionDialog extends DialogComponent<{}> {
  @Prop() code!: string;
  @Prop() printer!: Printer;

  @Ref() dialogWrapper!: AuraDialogWrapper;

  //#region STATE
  private get HeaderText() {
    return 'Printer connection';
  }
  //#endregion

  //#region LOGIC
  private DialogWrapperClosed() {
    this.$close({});
  }

  CopyConnectionCodeToClipboard() {
    navigator.clipboard.writeText(this.code);

    toast.info('Code copied to clipboard', {
      position: POSITION.BOTTOM_RIGHT,
    });
  }

  async HandlePrinterStatusUpdated(printer: Printer) {
    if (this.printer.Id.toString() != printer.Id.toString()) {
      return;
    }

    if (printer.PrinterState != PrinterState.DISCONNECTED) {
      this.dialogWrapper.CloseDialog();
      toast.success(`Printer ${printer.Name} connected successfuly`, {
        position: POSITION.BOTTOM_RIGHT,
      });
      this.$close({});
    }
  }
  //#endregion

  //#region HOOKS
  mounted() {
    PrinterModule.OnPrinterStatusUpdated.subscribe(this.HandlePrinterStatusUpdated);
  }

  beforeDestroy() {
    PrinterModule.OnPrinterStatusUpdated.unsubscribe(this.HandlePrinterStatusUpdated);
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.printer-connection-panel {
  color: var(--main-text);
  pointer-events: all;

  min-width: 350px;

  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .printer-connection-content {
    .code {
      color: #cecece;
      font-size: 36px;
      display: flex;
      align-items: center;
      justify-content: center;

      .copy-icon {
        font-size: 28px;
      }
    }

    .separator {
      height: 1px;
      width: 100%;
      background-image: linear-gradient(to right, gray 15%, rgba(129, 129, 129, 0) 0%);
      background-position: top;
      background-size: 6px 1px;
      background-repeat: repeat-x;
    }

    .hint {
      font-size: 12px;
      color: #c7c7c7;
      text-align: left;
    }
  }

  i {
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }
}
</style>
