<template>
  <div class="company-editor">
    <EditorHeader>
      <template slot="header">
        <span class="mr-1">
          {{ translations.company }}
        </span>
        <span>
          {{ company.Name }}
        </span>
      </template>

      <template slot="buttons">
        <HeaderIconButton
          v-if="canAddNewProduct"
          test-id="companyEditor.addProductButton"
          iconName="fa-plus"
          @clicked="addProductsClicked"
        />
        <HeaderIconButton test-id="companyEditor.editButton" iconName="fa-pen" @clicked="IsEditing = !IsEditing" />
        <HeaderIconButton test-id="companyEditor.closeButton" iconName="fa-times" @clicked="closeButtonClicked" />
      </template>
    </EditorHeader>

    <EditorContent>
      <TextInput
        :disabled="isLoading"
        class="mb-2"
        :label="translations.name"
        :value.sync="name"
        :labelWidth="labelWidth"
        :invalid="nameValidationFailed"
        :readonly="!IsEditing"
        type="text"
        placeholder="Enter company name..."
        test-id="companyEditor.nameInput"
      />

      <TextInput
        class="mb-2"
        :disabled="isLoading"
        :label="translations.description"
        :value.sync="description"
        :labelWidth="labelWidth"
        :invalid="descriptionValidationFailed"
        :readonly="!IsEditing"
        type="text"
        placeholder="Enter company description..."
        test-id="companyEditor.descriptionInput"
      />

      <SelectInput
        class="mb-2"
        :disabled="isLoading"
        :label="translations.country"
        :options.sync="countryItems"
        :labelWidth="labelWidth"
        :readonly="!IsEditing"
        :selected-option.sync="country"
        :GetId="a => a.toString()"
        :GetName="a => GetCountryName(a)"
        test-id="companyEditor.countrySelect"
      >
      </SelectInput>

      <TextInput
        :disabled="isLoading"
        :label="translations.address"
        :value.sync="address"
        :labelWidth="labelWidth"
        :invalid="addressValidationFailed"
        :readonly="!IsEditing"
        type="text"
        placeholder="Enter company address..."
        test-id="companyEditor.addressInput"
      />

      <fade-transition :maxHeight="28">
        <div v-if="validationErrors.length != 0" class="errors-container">
          <span class="mt-2">{{ validationErrors[0] }}</span>
        </div>
      </fade-transition>

      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </EditorContent>

    <EditorFooter v-if="IsEditing">
      <template slot="buttons">
        <IconButton
          class="mr-3"
          :disabled="isLoading || !hasChanges"
          iconName="fa-check"
          :text="translations.applyButton"
          test-id="companyEditor.applyButton"
          @clicked="applyButtonClicked"
        />
        <IconButton
          class="mr-3"
          :disabled="isLoading"
          iconName="fa-sync"
          :text="translations.resetButton"
          test-id="companyEditor.resetButton"
          @clicked="resetButtonClicked"
        />
        <IconButton
          v-if="IsEditing"
          :disabled="isLoading"
          iconName="fa-times"
          :text="translations.cancelButton"
          :iconSize="15"
          test-id="companyEditor.cancelButton"
          @clicked="closeButtonClicked"
        />
      </template>
    </EditorFooter>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { create } from 'vue-modal-dialogs';

import en from '@/localization/en';
import { CompanyModule } from '@/store/modules/companyModule';
import { Countries } from '@/models/responses/Countries';
import { Company } from '@/models/Entities';
import FadeTransition from '@/components/presentation/FadeTransition.vue';
import IconButton from '../buttons/IconButton.vue';
import HeaderIconButton from '../buttons/HeaderIconButton.vue';
import EditorHeader from './base/EditorHeader.vue';
import EditorContent from './base/EditorContent.vue';
import EditorFooter from './base/EditorFooter.vue';
import TextInput from '../inputs/TextInput.vue';
import SelectInput from '../inputs/SelectInput.vue';
import { RequestStatus } from '@/models/enums/RequestStatus';
import AuraMessageBoxDialog, { Result } from '@/views/dialogs/AuraMessageBoxDialog.vue';
import { canAddNewProduct } from '@/util/env';

const DeleteCompanyDialog = create<Result, String, String, Result>(
  AuraMessageBoxDialog,
  'results',
  'header',
  'mainText',
);

@Component({
  name: 'company-editor',
  components: {
    HeaderIconButton,
    FadeTransition: FadeTransition,
    IconButton: IconButton,
    EditorHeader: EditorHeader,
    EditorContent: EditorContent,
    EditorFooter: EditorFooter,
    TextInput: TextInput,
    SelectInput: SelectInput,
  },
})
export default class CompanyEditor extends Vue {
  labelWidth: number = 100;

  @Prop()
  private company!: Company;

  @Watch('company', { immediate: true })
  OnCompanyChanged(newValue: Company) {
    this.setLocalStateFromCompany(newValue);
  }

  private validationTimeoutHandle: number = -1;
  private nameValidationFailed: boolean = false;
  private descriptionValidationFailed: boolean = false;
  private countryValidationFailed: boolean = false;
  private addressValidationFailed: boolean = false;
  private validationErrors: string[] = [];

  private name = '';
  private description = '';
  private country: Countries = Countries.RussianFederation;
  private address = '';

  private isLoading = false;

  private isEditing: boolean = false;
  private get IsEditing() {
    return this.isEditing;
  }
  private set IsEditing(value: boolean) {
    this.isEditing = value;

    if (this.isEditing) {
      this.setLocalStateFromCompany(this.company);
    }
  }

  private get canAddNewProduct() {
    return canAddNewProduct();
  }

  private countryItems: Countries[] = [];
  private GetCountryName(country: Countries): string {
    return en.CountryNames[country];
  }

  get hasChanges(): boolean {
    return (
      this.name != this.company.Name ||
      this.address != this.company.Address ||
      this.country != this.company.Country ||
      this.description !== this.company.Description
    );
  }

  private resetButtonClicked() {
    this.name = this.company.Name;
    this.description = this.company.Description;
    this.address = this.company.Address;
    this.country = this.company.Country;
  }

  private InitializeCountryItems() {
    for (let key in Countries) {
      if (!isNaN(Number(key))) continue;
      let country = Countries[key as keyof typeof Countries];
      this.countryItems.push(country);
    }
  }

  private async handleCompanyDeleted(company: Company) {
    if (company.Id.toString() === this.company.Id.toString()) {
      this.closeButtonClicked();
    }
  }

  private setLocalStateFromCompany(company: Company) {
    this.name = company.Name;
    this.description = company.Description;
    this.address = company.Address;
    this.country = company.Country;
  }

  private clearValidationErrors() {
    if (this.validationTimeoutHandle !== -1) {
      clearTimeout(this.validationTimeoutHandle);
    }

    this.validationErrors = [];
    this.nameValidationFailed = false;
    this.descriptionValidationFailed = false;
    this.addressValidationFailed = false;
    this.validationTimeoutHandle = -1;
  }

  private validate(): boolean {
    this.clearValidationErrors();

    // Validate
    if (this.name == null || this.name.length == 0) {
      this.validationErrors.push('Enter company name');
      this.nameValidationFailed = true;
    } else if (this.address == null || this.address.length == 0) {
      this.validationErrors.push('Enter company address');
      this.addressValidationFailed = true;
    }

    const failed = this.validationErrors.length != 0;

    if (failed) {
      this.validationTimeoutHandle = window.setTimeout(() => {
        this.clearValidationErrors();
      }, 3500);
    }

    return !failed;
  }

  private closeButtonClicked() {
    this.$emit('close-clicked');
  }

  private addProductsClicked() {
    this.$emit('add-products-clicked');
  }

  private async deleteButtonClicked() {
    const userConfirmation = await DeleteCompanyDialog(
      Result.Yes | Result.No,
      'Delete company',
      'Are you sure you want to delete this company?',
    );

    if (userConfirmation == Result.No || userConfirmation == Result.Cancel) {
      return;
    }

    this.isLoading = true;

    await CompanyModule.DeleteCompany(this.company.Id);

    this.isLoading = true;
  }

  private async applyButtonClicked() {
    if (!this.validate()) {
      return;
    }

    this.isLoading = true;

    const result = await CompanyModule.ChangeCompanyInfo({
      companyId: this.company.Id,
      name: this.name,
      description: this.description,
      country: this.country,
      address: this.address,
    });

    this.isLoading = false;

    if (result[0] === RequestStatus.OK) {
      this.IsEditing = false;
    }
  }

  async created() {
    CompanyModule.OnCompanyDeleted.subscribe(this.handleCompanyDeleted);
    this.InitializeCountryItems();
  }

  async beforeDestroy() {
    CompanyModule.OnCompanyDeleted.unsubscribe(this.handleCompanyDeleted);
  }

  get translations() {
    return {
      company: en.company.titleCase(),
      edit: en.edit.toUpperCase(),
      address: en.address.titleCase(),
      name: en.nameCaption.titleCase(),
      description: en.description.titleCase(),
      country: en.country.titleCase(),
      applyButton: en.apply.titleCase(),
      resetButton: en.reset.titleCase(),
      cancelButton: en.cancel.titleCase(),
    };
  }
}
</script>

<style lang="scss" scoped>
.company-editor {
  background: var(--editor-background);
  border-radius: 6px;

  .errors-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      color: var(--editor-error-text);
      font-size: 14px;
    }
  }

  .error-border {
    border: 1px solid var(--main-orange);
  }
}
</style>
