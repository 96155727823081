export interface Rectangle {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface Point {
  x: number;
  y: number;
}

// Возвращает точку с парой минимальных координат от других точек
export function MinPoint(points: Point[]): Point {
  let minX = points[0].x;
  let minY = points[0].y;

  for (let i = 1; i < points.length; ++i) {
    const currX = points[i].x;
    const currY = points[i].y;

    if (currX < minX) {
      minX = currX;
    }

    if (currY < minY) {
      minY = currY;
    }
  }

  return {
    x: minX,
    y: minY,
  };
}

// См. выше :)
export function MaxPoint(points: Point[]): Point {
  let maxX = points[0].x;
  let maxY = points[0].y;

  for (let i = 1; i < points.length; ++i) {
    const currX = points[i].x;
    const currY = points[i].y;

    if (currX > maxX) {
      maxX = currX;
    }

    if (currY > maxY) {
      maxY = currY;
    }
  }

  return {
    x: maxX,
    y: maxY,
  };
}

export function RectangleContainsPoint(rect: Rectangle, point: Point): boolean {
  return rect.x <= point.x && point.x <= rect.x + rect.width && rect.y <= point.y && point.y <= rect.y + rect.height;
}

export function Map(value: number, fromMin: number, fromMax: number, toMin: number, toMax: number) {
  return ((value - fromMin) / (fromMax - fromMin)) * (toMax - toMin) + toMin;
}
