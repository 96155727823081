import * as ips from '@/util/api/ips';
import * as routes from '@/util/api/routes';
import * as hubActions from '@/util/api/hubActions';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { PreviewSubscriptionChanges, PurchasedSubscription } from '@/models/Entities';
import { HubConnection } from '@microsoft/signalr';
import { Guid } from 'guid-typescript';
import { UtilModule } from '../utilModule';
import {
  RequestCancelPurchasedSubscription,
  RequestPreviewPurchasedSubscriptionPriceChanges,
  RequestReadAllByAccountIdPurchasedSubscription,
  RequestSubscribeToSubscription,
  RequestUpdatePurchasedSubscription,
} from '@/models/requests/RequestsBilling';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { TypeHelper } from '@/util/TypeHelper';
import {
  ResponsePreviewSubscriptionChanges,
  ResponsePurchasedSubscription,
  ResponsePurchasedSubscriptions,
} from '@/models/responses/ResponseBilling';
import { PurchaseItemData, PurchaseItemsData } from './purchasedItemModule';
import { PurchaseStatus } from '@/models/enums/PurchaseStatus';
import { LoginModule } from '../loginModule';

export interface PriceUpdateData {
  newProductPriceId: Guid | null;
  newQuantity: number | null;
}

export interface SubscriptionUpdateData {
  accountId: Guid;
  subId: Guid;
  updateData: PriceUpdateData;
  prorationDate: Date | null;
}

@Module({ dynamic: true, name: 'purchased-subscription', store: store })
export default class purchasedSubsctionModule extends VuexModule {
  private purchasedSubscriptions: PurchasedSubscription[] = [];

  get PurchasedSubscriptions(): PurchasedSubscription[] {
    return this.purchasedSubscriptions.filter(
      a =>
        a.status === PurchaseStatus.Active ||
        a.status === PurchaseStatus.Canceled ||
        a.status === PurchaseStatus.PastDue,
    );
  }

  @Mutation
  AddPurchasedSubscription(val: PurchasedSubscription) {
    this.purchasedSubscriptions.push(val);
  }

  @Mutation
  UpdateSubscription(val: PurchasedSubscription) {
    this.purchasedSubscriptions = this.purchasedSubscriptions.map(sub => {
      if (val.id.toString() === sub.id.toString()) {
        sub.currentPeriodEnd = val.currentPeriodEnd;
        sub.currentPeriodStart = val.currentPeriodStart;
        sub.status = val.status;
        sub.cancelAtPeriodEnd = val.cancelAtPeriodEnd;
      }

      return sub;
    });
  }

  @Mutation
  ClearPurchasedSubscriptions() {
    this.purchasedSubscriptions = [];
  }

  @Action({ rawError: true })
  PurchasedSubscriptionSubscribe(hubConnection: HubConnection) {
    hubConnection.on(hubActions.RECEIVE_CREATE_PURCHASED_SUBSCRIPTION, async (res: ResponsePurchasedSubscription) => {
      const sub = TypeHelper.DeepCopyFrom(res, ResponsePurchasedSubscription).Map();

      this.AddPurchasedSubscription(sub);
    });

    hubConnection.on(hubActions.RECEIVE_CANCEL_PURCHASED_SUBSCRIPTION, async (res: ResponsePurchasedSubscription) => {
      const sub = TypeHelper.DeepCopyFrom(res, ResponsePurchasedSubscription).Map();

      this.UpdateSubscription(sub);
    });

    hubConnection.on(hubActions.RECEIVE_UPDATE_PURCHASED_SUBSCRIPTION, async (res: ResponsePurchasedSubscription) => {
      const sub = TypeHelper.DeepCopyFrom(res, ResponsePurchasedSubscription).Map();

      this.UpdateSubscription(sub);
    });
  }

  @Action({ rawError: true })
  async LoadPurchasedSubscriptions(accountId: Guid) {
    this.ClearPurchasedSubscriptions();

    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PURCHASED_SUBSCRIPTION,
      routes.UriPurchasedSubscription.READ_ALL_BY_ACCOUNT_ID,
    );

    const request = new RequestReadAllByAccountIdPurchasedSubscription();
    request.accountId = accountId.toString();

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = axiosResponse.data.response as ResponsePurchasedSubscriptions;
      const subscriptions = TypeHelper.DeepCopyFrom(response, ResponsePurchasedSubscriptions).subscriptions;

      for (const res of subscriptions) {
        const item = TypeHelper.DeepCopyFrom(res, ResponsePurchasedSubscription);
        this.AddPurchasedSubscription(item.Map());
      }
    }
  }

  @Action({ rawError: true })
  async Subscribe(data: PurchaseItemsData): Promise<PurchaseItemData[]> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PURCHASED_SUBSCRIPTION,
      routes.UriPurchasedSubscription.SUBSCRIBE,
    );

    const subscribed: PurchaseItemData[] = [];

    for (const item of data.items) {
      const request = new RequestSubscribeToSubscription();
      request.accountId = data.accountId.toString();
      request.productPriceId = item.productPriceId.toString();
      request.quantity = item.quantity;

      const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

      if (result === RequestStatus.OK && axiosResponse != null) {
        subscribed.push(item);
      }
    }

    return subscribed;
  }

  @Action({ rawError: true })
  async Cancel(subId: Guid): Promise<boolean> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PURCHASED_SUBSCRIPTION,
      routes.UriPurchasedSubscription.CANCEL,
    );

    const request = new RequestCancelPurchasedSubscription();
    request.purchasedSubscriptionId = subId.toString();

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result !== RequestStatus.OK || axiosResponse == null) {
      return false;
    }

    return true;
  }

  @Action({ rawError: true })
  async CancelPendingUpdate(subId: Guid): Promise<boolean> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PURCHASED_SUBSCRIPTION,
      routes.UriPurchasedSubscription.CANCEL_PENDING_UPDATE,
    );

    const request = new RequestCancelPurchasedSubscription();
    request.purchasedSubscriptionId = subId.toString();

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result !== RequestStatus.OK || axiosResponse == null) {
      return false;
    }

    return true;
  }

  @Action({ rawError: true })
  async ScheduleCancellation(subId: Guid): Promise<boolean> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PURCHASED_SUBSCRIPTION,
      routes.UriPurchasedSubscription.SCHEDULE_CANCELLATION,
    );

    const request = new RequestCancelPurchasedSubscription();
    request.purchasedSubscriptionId = subId.toString();

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result !== RequestStatus.OK || axiosResponse == null) {
      return false;
    }

    return true;
  }

  @Action({ rawError: true })
  async Reactivate(subId: Guid): Promise<boolean> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PURCHASED_SUBSCRIPTION,
      routes.UriPurchasedSubscription.REACTIVATE,
    );

    const request = new RequestCancelPurchasedSubscription();
    request.purchasedSubscriptionId = subId.toString();

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result !== RequestStatus.OK || axiosResponse == null) {
      return false;
    }

    return true;
  }

  @Action({ rawError: true })
  async PreviewPriceChanges(data: SubscriptionUpdateData): Promise<PreviewSubscriptionChanges | null> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PURCHASED_SUBSCRIPTION,
      routes.UriPurchasedSubscription.PREVIEW_PRICE_CHANGES,
    );

    const request = new RequestPreviewPurchasedSubscriptionPriceChanges();
    request.accountId = data.accountId.toString();
    request.existingSubscriptionId = data.subId.toString();
    request.newProductPriceId =
      data.updateData.newProductPriceId === null ? null : data.updateData.newProductPriceId.toString();
    request.newQuantity = data.updateData.newQuantity;

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = axiosResponse.data.response as ResponsePreviewSubscriptionChanges;

      const res = TypeHelper.DeepCopyFrom(response, ResponsePreviewSubscriptionChanges).Map();

      return res;
    }

    return null;
  }

  @Action({ rawError: true })
  async Update(data: SubscriptionUpdateData) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PURCHASED_SUBSCRIPTION,
      routes.UriPurchasedSubscription.UPDATE,
    );

    const request = new RequestUpdatePurchasedSubscription();
    request.accountId = data.accountId.toString();
    request.existingSubscriptionId = data.subId.toString();
    request.newProductPriceId =
      data.updateData.newProductPriceId === null ? null : data.updateData.newProductPriceId.toString();
    request.newQuantity = data.updateData.newQuantity;
    request.prorationDate = data.prorationDate == null ? null : data.prorationDate.toJSON();

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result !== RequestStatus.OK || axiosResponse == null) {
      return false;
    }

    return true;
  }

  @Action({ rawError: true })
  async AddToPurchasedSubscriptionGroup() {
    if (LoginModule.BillingConnection == null) return;
    await LoginModule.BillingConnection.send(hubActions.ADD_TO_PURCHASED_SUBSCRIPTION_GROUP);
    LoginModule.AddBillingConnectionGroup({
      func: this.AddToPurchasedSubscriptionGroup,
    });
  }

  @Action({ rawError: true })
  async AddToSpecificPurchasedSubscriptionGroup(accountId: Guid) {
    if (LoginModule.BillingConnection == null) return;
    await LoginModule.BillingConnection.send(hubActions.ADD_TO_PURCHASED_SUBSCRIPTION_GROUP, accountId.toString());
    LoginModule.AddBillingConnectionGroup({
      func: this.AddToSpecificPurchasedSubscriptionGroup,
      args: accountId,
    });
  }

  @Action({ rawError: true })
  async DeleteFromPurchasedSubscriptionGroup() {
    if (LoginModule.BillingConnection == null) return;
    await LoginModule.BillingConnection.send(hubActions.DELETE_FROM_PURCHASED_SUBSCRIPTION_GROUP);
    LoginModule.DeleteBillingConnectionGroup({
      func: this.AddToPurchasedSubscriptionGroup,
    });
  }

  @Action({ rawError: true })
  async DeleteFromSpecificPurchasedSubscriptionGroup(accountId: Guid) {
    if (LoginModule.BillingConnection == null) return;
    await LoginModule.BillingConnection.send(hubActions.DELETE_FROM_PURCHASED_SUBSCRIPTION_GROUP, accountId.toString());
    LoginModule.DeleteBillingConnectionGroup({
      func: this.AddToSpecificPurchasedSubscriptionGroup,
      args: accountId,
    });
  }
}

export const PurchasedSubscriptionModule = getModule(purchasedSubsctionModule);
