<template>
  <action-card
    class="machine-errors"
    test-id="machineErrors"
    headerText="Error log"
    :closable="closable"
    :collapsible="false"
    :headerTopMargin="10"
    :fillContentVertically="true"
    :headerTextVerticalOffset="1"
    :headerHeight="'auto'"
    :headerAlignment="'flex-start'"
    @closed="Closed"
  >
    <template slot="headerCenter">
      <div class="machine-errors-header-controls">
        <div class="main-container mr-2">
          <div class="main ml-3">
            <div v-if="!IsLive" class="error-count">{{ Errors.length }} of {{ lastTotal }}</div>

            <div class="buttons">
              <i
                v-tooltip="{ content: 'Toggle errors visiblity', offset: 5 }"
                :class="['fal fa-bug', IsErrorTypeFilterActive(2) ? 'active' : '']"
                data-testid="machineErrors.toggleErrorTypeButton"
                @click="ToggleErrorType(2)"
              />
              <i
                v-tooltip="{ content: 'Toggle warnings visiblity', offset: 5 }"
                :class="['fal fa-exclamation-triangle', IsErrorTypeFilterActive(1) ? 'active' : '']"
                data-testid="machineErrors.toggleErrorTypeButton"
                @click="ToggleErrorType(1)"
              />
              <i
                v-tooltip="{
                  content: 'Toggle information visibility',
                  offset: 5,
                }"
                :class="['fal fa-info-circle', IsErrorTypeFilterActive(0) ? 'active' : '']"
                data-testid="machineErrors.toggleErrorTypeButton"
                @click="ToggleErrorType(0)"
              />
              <i
                v-tooltip="{
                  content: 'Toggle grouping of similar messages',
                  offset: 5,
                }"
                :class="['fal fa-bars', groupSimilarErrors ? 'active' : '']"
                data-testid="machineErrors.toggleGroupSimilarErrorsButton"
                @click="ToggleGroupSimilarErrors"
              />
              <i
                v-tooltip="{
                  content: 'Toggle acknowledged errors visibility',
                  offset: 5,
                }"
                :class="['fal fa-check-double', !onlyUnacknowledged ? 'active' : '']"
                data-testid="machineErrors.toggleOnlyUnacknowledgedButton"
                @click="ToggleOnlyUnacknowledged"
              />
            </div>
          </div>

          <RegularButton
            class="ml-3"
            label="Confirm all"
            test-id="machineErrors.confirmAllErrorsButton"
            @clicked="ConfirmAllErrorsClicked"
          />

          <RegularButton
            v-if="!IsLive"
            class="ml-3"
            label="Refresh"
            test-id="machineErrors.refreshButton"
            @clicked="RefreshButtonClicked"
          />
        </div>

        <TimeRangeSelector test-id="machineErrors" :timeRange.sync="errorTimeRange" />
      </div>
    </template>

    <div class="error-list thin-scroll overflow-overlay" @scroll="OnErrorListScrolled">
      <div class="machine-errors-container" style="flex: 1">
        <MachineErrorGroupLine
          v-for="group in ErrorGroups"
          :key="group.id"
          :group="group"
          :collapsed="groupSimilarErrors"
        />
      </div>

      <div :class="['spinner-container', isLoading ? '' : 'hidden']">
        <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
      </div>

      <RegularButton
        v-if="!IsLive && lastTotal > 0"
        class="mt-3 w-100"
        :disabled="isLoading"
        label="Load more"
        test-id="machineErrors.loadMoreErrorsButton"
        @clicked="LoadMoreErrorsClicked"
      />
    </div>
  </action-card>
</template>

<script lang="ts">
import { ErrorType, Printer, PrinterError, PrinterErrors } from '@/models/Entities';
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import ActionCard from '../presentation/ActionCard.vue';
import { GetDefaultTimeRange, TimeRange, TimeRangeType } from '@/models/util/TimeModels';
import { ConnectorModule } from '@/store/modules/connectorModule';
import TimeRangeSelector from '@/components/inputs/TimeRangeSelector.vue';
import MachineErrorGroupLine from './MachineErrorGroupLine.vue';
import RegularButton from '../buttons/RegularButton.vue';
import { delay } from 'lodash';
import { Guid } from 'guid-typescript';
import { GuidHelper } from '@/util/GuidHelper';
import { GlobalDataModule } from '@/store/modules/globalDataModule';
import moment from 'moment';

export interface PrinterErrorGroup {
  errors: PrinterError[];
  id: string;
}

@Component({
  components: {
    ActionCard: ActionCard,
    TimeRangeSelector: TimeRangeSelector,
    MachineErrorGroupLine: MachineErrorGroupLine,
    RegularButton: RegularButton,
  },
})
export default class MachineErrors extends Vue {
  @Prop() printer!: Printer;
  @Prop({ default: false }) closable!: boolean;

  //#region WATCHERS
  @Watch('printer', { immediate: true })
  async OnPrinterChanged(newValue: Printer, oldValue?: Printer) {
    if (oldValue != undefined) {
      if (oldValue.Id.toString() != newValue.Id.toString()) {
        await this.UnsubscribeFromErrors(oldValue.Id);
      } else {
        return;
      }
    }

    await this.SubscribeToErrors(newValue.Id);

    console.log('????');

    if (this.errors.length == 0 || (oldValue != undefined && oldValue.Id.toString() != newValue.Id.toString())) {
      await this.ClearErrors();
      await this.LoadMoreErrors();
    }
  }

  @Watch('errorTimeRange')
  async OnErrorTimeRangeChanged(newValue: TimeRange, oldValue: TimeRange) {
    if (oldValue.type == TimeRangeType.Live && newValue.type != TimeRangeType.Live) {
      // await this.UnsubscribeFromErrors(this.printer.Id);
    } else if (newValue.type == TimeRangeType.Live && oldValue.type != TimeRangeType.Live) {
      // await this.SubscribeToErrors(this.printer.Id);
      await this.ClearErrors();
      return;
    }

    if (!this.errorTimeRangeInitialized) {
      this.errorTimeRangeInitialized = true;
      return;
    }

    await this.ClearErrors();
    await this.LoadMoreErrors();
  }

  @Watch('errorTypeFilters')
  async OnErrorTypeFiltersChanged() {
    if (this.errorTimeRange.type == TimeRangeType.Live) {
      return;
    }

    await this.ClearErrors();
    await this.LoadMoreErrors();
  }

  @Watch('onlyUnacknowledged')
  async OnOnlyUnacknowledgedChanged() {
    if (this.errorTimeRange.type == TimeRangeType.Live) {
      return;
    }

    await this.ClearErrors();
    await this.LoadMoreErrors();
  }
  //#endregion

  //#region STATE
  private isLoading = false;
  private canScrollLoad = true;

  private errorTimeRangeInitialized: boolean = false;
  private errorTimeRange: TimeRange = GetDefaultTimeRange();
  private errorTypeFilters: ErrorType[] = [];
  private groupSimilarErrors: boolean = true;
  private onlyUnacknowledged: boolean = false;

  private errors: PrinterError[] = [];
  private errorsPage: number = 0;

  private lastTotal: number = 0;

  private get Errors() {
    let resultErrors = this.errors.sort((a, b) => (a.Time < b.Time ? 1 : -1));

    if (this.onlyUnacknowledged) {
      resultErrors = resultErrors.filter(a => !a.Acknowledged);
    }

    if (this.errorTypeFilters.length > 0) {
      resultErrors = resultErrors.filter(a => this.errorTypeFilters.indexOf(a.ErrorType) != -1);
    }

    return resultErrors;
  }

  private get ErrorGroups(): PrinterErrorGroup[] {
    let outErrorGroups = [];

    let currErrors = this.Errors;

    let lastErrorMsg: string | null = null;
    let lastErrorType: ErrorType | null = null;

    let lastGroup: PrinterErrorGroup = {
      errors: [],
      id: Guid.create().toString(),
    };

    for (let i = 0; i < currErrors.length; ++i) {
      if (lastErrorMsg === null && lastErrorType == null) {
        lastErrorMsg = currErrors[i].Message;
        lastErrorType = currErrors[i].ErrorType;

        lastGroup.errors.push(currErrors[i]);

        continue;
      }

      if (currErrors[i].Message === lastErrorMsg && currErrors[i].ErrorType == lastErrorType) {
        lastGroup.errors.push(currErrors[i]);
      } else {
        outErrorGroups.push(lastGroup);

        lastGroup = {
          errors: [],
          id: Guid.create().toString(),
        };
        lastGroup.errors.push(currErrors[i]);

        lastErrorMsg = currErrors[i].Message;
        lastErrorType = currErrors[i].ErrorType;
      }
    }

    if (lastGroup.errors.length !== 0) {
      outErrorGroups.push(lastGroup);
    }

    return outErrorGroups;
  }

  private get IsLive() {
    return this.errorTimeRange.type == TimeRangeType.Live;
  }
  //#endregion

  //#region LOGIC
  async LoadMoreErrors() {
    if (this.isLoading) return;

    this.isLoading = true;

    const res = await ConnectorModule.ReadErrorsByPrinterPaged([
      this.printer.Id,
      this.errorTimeRange.dateFrom,
      this.errorTimeRange.dateTo,
      25,
      this.errorsPage,
      this.errorTypeFilters,
      this.onlyUnacknowledged,
    ]);

    if (res == null) {
      return;
    }

    this.lastTotal = res.total;

    for (const err of res.errors.Errors) {
      this.errors.push(err);
    }

    this.isLoading = false;

    if (res.errors.Errors.length > 0) {
      this.errorsPage++;
    }
  }

  ClearErrors() {
    this.lastTotal = 0;
    this.errorsPage = 0;
    this.errors = [];
  }

  async ToggleErrorType(errorType: ErrorType) {
    if (this.errorTypeFilters.indexOf(errorType) == -1) {
      this.errorTypeFilters.push(errorType);
    } else {
      this.errorTypeFilters.delete(errorType);
    }

    await GlobalDataModule.ChangeMachineErrorsViewStateActivatedErrorTypes(this.errorTypeFilters);
  }

  async ToggleGroupSimilarErrors() {
    this.groupSimilarErrors = !this.groupSimilarErrors;

    await GlobalDataModule.ChangeMachineErrorsViewStateGroupSimilarErrors(this.groupSimilarErrors);
  }

  async ToggleOnlyUnacknowledged() {
    this.onlyUnacknowledged = !this.onlyUnacknowledged;

    await GlobalDataModule.ChangeMachineErrorsViewStateShowOnlyUnacknowledged(this.onlyUnacknowledged);
  }

  IsErrorTypeFilterActive(errorType: ErrorType) {
    return this.errorTypeFilters.indexOf(errorType) != -1;
  }

  async LoadMoreErrorsClicked() {
    await this.LoadMoreErrors();
  }

  async ConfirmAllErrorsClicked() {
    this.isLoading = true;

    await ConnectorModule.AcknowledgeAllPrinterErrors(this.printer.Id);

    this.isLoading = false;
  }

  async RefreshButtonClicked() {
    this.ClearErrors();
    this.CorrectErrorTimeRange();
    await this.LoadMoreErrors();
  }

  async OnErrorListScrolled(e: any) {
    if (this.IsLive) return;

    const currScroll = e.target.scrollTop;
    const endScroll = e.target.scrollHeight - e.target.clientHeight;

    if (currScroll === endScroll && currScroll > 0 && this.canScrollLoad) {
      this.canScrollLoad = false;

      await this.LoadMoreErrors();

      delay(() => {
        this.canScrollLoad = true;
      }, 500);
    }
  }

  ErrorsReceived(errors: PrinterErrors) {
    if (!this.IsLive) return;

    for (const err of errors.Errors) {
      this.errors.push(err);
    }
  }

  ErrorsAcknowledged(ids: Guid[]) {
    this.errors = this.errors.map(a => {
      if (GuidHelper.includes(ids, a.Id)) {
        a.Acknowledged = true;
      }

      return a;
    });
  }

  async SubscribeToErrors(printerId: Guid) {
    await ConnectorModule.SubscribeToError(printerId);
    ConnectorModule.OnErrorsReceived.subscribe(this.ErrorsReceived);
    ConnectorModule.OnErrorsAcknowledged.subscribe(this.ErrorsAcknowledged);
  }

  async UnsubscribeFromErrors(printerId: Guid) {
    await ConnectorModule.UnsubscribeFromError(printerId);
    ConnectorModule.OnErrorsReceived.unsubscribe(this.ErrorsReceived);
    ConnectorModule.OnErrorsAcknowledged.unsubscribe(this.ErrorsAcknowledged);
  }

  CorrectErrorTimeRange() {
    const type = this.errorTimeRange.type;

    if (type == undefined || type == TimeRangeType.Default || type == TimeRangeType.Live) {
      return;
    }

    let fromDate = new Date();
    const toDate = new Date();

    if (type == TimeRangeType.Day) {
      fromDate = moment(fromDate).subtract(1, 'days').toDate();
    } else if (type == TimeRangeType.Week) {
      fromDate = moment(fromDate).subtract(1, 'weeks').toDate();
    } else if (type == TimeRangeType.Month) {
      fromDate = moment(fromDate).subtract(1, 'months').toDate();
    }

    this.errorTimeRange.dateFrom = fromDate;
    this.errorTimeRange.dateTo = toDate;
  }
  //#endregion

  //#region EVENTS
  @Emit('closed')
  Closed() {}
  //#endregion

  //#region HOOKS
  mounted() {
    this.groupSimilarErrors = GlobalDataModule.MachineErrorsViewState.groupSimilarErrors;
    this.onlyUnacknowledged = GlobalDataModule.MachineErrorsViewState.showOnlyUnacknowledged;
    this.errorTypeFilters = GlobalDataModule.MachineErrorsViewState.activatedErrorTypes;
  }

  beforeDestroy() {
    this.UnsubscribeFromErrors(this.printer.Id);
  }
  //#endregion

  //#region TRANSLATIONS
  private TimeRangeTranslation(range: TimeRangeType) {
    if (range == TimeRangeType.Range) {
      return 'Range';
    } else if (range == TimeRangeType.Day) {
      return 'Day';
    } else if (range == TimeRangeType.Week) {
      return 'Week';
    } else if (range == TimeRangeType.Month) {
      return 'Month';
    } else if (range == TimeRangeType.Live) {
      return 'Live';
    }
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.machine-errors {
  .machine-errors-header-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
    flex: 1;
    align-items: flex-start;

    .main-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;
    }

    .main {
      display: flex;
      border: var(--machine-errors-controls-border);
      border-radius: 10px;
      padding: 1px 22px;
      align-items: center;

      .error-count {
        margin-right: 26px;
        font-size: 14px;
      }

      .buttons {
        i {
          cursor: pointer;
          margin-right: 16px;

          &:last-child {
            margin-right: 0;
          }

          &:hover {
            opacity: 0.75;
          }

          &.active {
            color: var(--machine-errors-controls-button-active);
          }
        }
      }
    }

    .error-list {
      position: relative;
    }
  }

  .spinner-container {
    min-height: 40px;
    margin-top: 20px;
    margin-bottom: 10px;

    position: relative;
    opacity: 1;

    &.hidden {
      opacity: 0;
      min-height: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);
  }
}
</style>
