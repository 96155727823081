<template>
  <div class="job-archive-editor">
    <EditorHeader>
      <template slot="header">
        <span>
          {{ Source }}
        </span>
      </template>

      <template slot="buttons">
        <HeaderIconButton test-id="jobArchiveEditor.closeButton" iconName="fa-times" @clicked="CloseClicked" />
      </template>
    </EditorHeader>

    <EditorContent>
      <FileInput
        v-if="SourceAvailable"
        class="mb-2"
        :downloadable="true"
        :fileName="Source"
        :label="SourceCaption"
        :readonly="true"
        :labelWidth="labelWidth"
        extension=".gcode"
        test-id="jobArchiveEditor.sourceField"
        @download-file="DownloadGCode"
        @clicked="SourceClicked"
      />

      <TextInput
        v-else-if="IsLocal"
        class="mb-2"
        :label="SourceCaption"
        :labelWidth="labelWidth"
        :readonly="true"
        :value="LocalFilename"
        :fillWidth="false"
        test-id="jobArchiveEditor.fileNameField"
      />

      <TextInput
        class="mb-2"
        :label="PinterCaption"
        :labelWidth="labelWidth"
        :readonly="true"
        :value="Printer"
        :fillWidth="false"
        test-id="jobArchiveEditor.printerField"
        @clicked="PrinterClicked"
      />

      <TextInput
        v-if="OwnerAvailable"
        class="mb-2"
        :label="OwnerCaption"
        :labelWidth="labelWidth"
        :readonly="true"
        :value="Owner"
        :fillWidth="false"
        test-id="jobArchiveEditor.ownerField"
        @clicked="OwnerClicked"
      />

      <TextInput
        class="mb-2"
        :label="StartedCaption"
        :labelWidth="labelWidth"
        :readonly="true"
        :value="Started"
        :fillWidth="false"
        test-id="jobArchiveEditor.startedDateField"
      />

      <TextInput
        class="mb-2"
        :label="FinishedCaption"
        :labelWidth="labelWidth"
        :readonly="true"
        :value="Finished"
        :fillWidth="false"
        test-id="jobArchiveEditor.finishedDateField"
      />

      <TextInput
        class="mb-2"
        :label="StatusCaption"
        :labelWidth="labelWidth"
        :readonly="true"
        :value="Status"
        :fillWidth="false"
        test-id="jobArchiveEditor.statusField"
      />

      <RegularButton
        class="w-100 mb-2"
        :label="PrintAgainCaption"
        :disabled="isLoading || !SourceAvailable"
        test-id="jobArchiveEditor.printAgainButton"
        @clicked="PrintAgainClicked"
      />

      <div :class="['spinner-container', isLoadingMaterialConsumptionArchives ? '' : 'hidden']">
        <div :class="['spinner', isLoadingMaterialConsumptionArchives ? '' : 'hidden']"></div>
      </div>

      <ActionCard
        v-if="MaterialConsumptionArchives.length > 0"
        :headerText="MaterialConsumptionsCaption"
        class="material-consumptions mt-2 mb-2"
        test-id="jobArchiveEditor.materialConsumptions"
      >
        <SortableHeader :header-items="MaterialConsumptionsHeader" test-id="jobArchiveEditor.materialConsumptions" />
        <div class="thin-scroll overflow-auto">
          <ListItemV3
            v-for="item in MaterialConsumptionArchives"
            :key="item.MaterialName + `_` + item.MaterialType.toString()"
            :items="MaterialConsumptionsItems(item)"
            :headerIconType="MaterialConsumptionIconType(item)"
            headerIconName="fa-circle"
            test-id="jobArchiveEditor.materialConsumptions"
            :headerIconFontSize="14"
          />
        </div>
      </ActionCard>

      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </EditorContent>
  </div>
</template>

<script lang="ts">
import en from '@/localization/en';
import { FullJobArchieve } from '@/models/CompositeEntities';
import { MaterialConsumptionArchive, MaterialType } from '@/models/Entities';
import { JobModule } from '@/store/modules/jobModule';
import { SourceModule } from '@/store/modules/sourceModule';
import ComponentHelper, { HeaderItem, ItemData } from '@/util/ComponentHelper';
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import EditorContent from '@/components/forms/base/EditorContent.vue';
import EditorHeader from '@/components/forms/base/EditorHeader.vue';
import FileInput from '@/components/inputs/FileInput.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import RegularButton from '../buttons/RegularButton.vue';

import AuraMessageBoxDialog, { Result } from '@/views/dialogs/AuraMessageBoxDialog.vue';
import { create } from 'vue-modal-dialogs';
import { toast } from '@/main';
import { POSITION } from 'vue-toastification';
import { VPopover } from 'v-tooltip';
import ActionCard from '../presentation/ActionCard.vue';
import ListItemV3 from '@/components/presentation/ListItemV3.vue';
import SortableHeader from '@/components/presentation/SortableHeader.vue';
import HeaderIconButton from '@/components/buttons/HeaderIconButton.vue';

const PrintAgainSure = create<Result, String, String, Result>(AuraMessageBoxDialog, 'results', 'header', 'mainText');

@Component({
  components: {
    HeaderIconButton,
    EditorHeader: EditorHeader,
    EditorContent: EditorContent,
    TextInput: TextInput,
    FileInput: FileInput,
    RegularButton: RegularButton,
    VPopover: VPopover,
    ActionCard: ActionCard,
    ListItemV3: ListItemV3,
    SortableHeader: SortableHeader,
  },
})
export default class JobArchiveEditor extends Vue {
  labelWidth: number = 85;

  @Prop() fullArchive!: FullJobArchieve;

  @Watch('fullArchive', { immediate: true })
  async OnArchiveChanged(newValue: FullJobArchieve, oldValue?: FullJobArchieve) {
    const oldId = oldValue?.jobArchieve.Id;
    const newId = newValue?.jobArchieve.Id;

    const sameId = oldId != undefined && oldId.equals(newId);

    if (sameId) {
      return;
    }

    // Load material consumption archives :)
    await this.LoadMaterialConsumptionArchives();
  }

  //#region STATE
  isLoading: boolean = false;
  isLoadingMaterialConsumptionArchives: boolean = false;

  private get SourceAvailable() {
    return this.fullArchive.source != null;
  }

  private get IsLocal() {
    return this.fullArchive.jobArchieve.LocalFilename != null;
  }

  private get OwnerAvailable() {
    return this.fullArchive.owner != null;
  }

  private get Source() {
    if (this.IsLocal) {
      return this.LocalFilename;
    }

    if (!this.SourceAvailable) {
      return '—';
    }

    return this.fullArchive.source!.CodeFileName;
  }

  private get LocalFilename() {
    if (!this.IsLocal) {
      return '—';
    }

    return this.fullArchive.jobArchieve.LocalFilename;
  }

  private get Printer() {
    return this.fullArchive.printer.Name;
  }

  private get Started() {
    if (this.fullArchive.jobArchieve.ActualStartTime == null) {
      return '—';
    }

    return ComponentHelper.GetReadableDateTime(this.fullArchive.jobArchieve.ActualStartTime);
  }

  private get Finished() {
    if (this.fullArchive.jobArchieve.EndTime == null) {
      return '—';
    }

    return ComponentHelper.GetReadableDateTime(this.fullArchive.jobArchieve.EndTime);
  }

  private get Status() {
    return ComponentHelper.GetReadableEndStatus(this.fullArchive.jobArchieve.EndStatus);
  }

  private get Owner() {
    if (!this.OwnerAvailable) {
      return '—';
    }

    return ComponentHelper.GetFullname(this.fullArchive.owner!);
  }
  //#endregion

  //#region MATERIAL CONSUMPTIONS
  private materialConsumptionArchives: MaterialConsumptionArchive[] = [];

  private get MaterialConsumptionArchives() {
    return this.materialConsumptionArchives;
  }

  //#region HEADERS & ITEMS
  private get MaterialConsumptionsHeader(): HeaderItem[] {
    let result: HeaderItem[] = [];

    result.push({
      caption: 'Name',
      itemClass: ComponentHelper.GetWidthClass(),
    });

    result.push({
      caption: 'Count',
      itemClass: ComponentHelper.GetWidthClass(125),
    });

    return result;
  }

  private MaterialConsumptionsItems(value: MaterialConsumptionArchive): ItemData[] {
    let result: ItemData[] = [];

    let consumed = value.Consumed;

    if (value.MaterialType == MaterialType.PLASTIC) {
      consumed *= 1e6;
    }

    let itemName: ItemData = new ItemData('Name', value.MaterialName, 'grow-1');
    let itemCount: ItemData = new ItemData('Count', consumed.toFixed(1) + ' ' + this.GetDimention(value), 125);

    result.push(itemName);
    result.push(itemCount);

    return result;
  }

  private MaterialConsumptionIconType(value: MaterialConsumptionArchive): string {
    if (this.IsPlastic(value)) {
      // empty circle
      return 'far';
    }

    // filled circle
    return 'fas';
  }

  private GetDimention(item: MaterialConsumptionArchive): string {
    if (item.MaterialType == MaterialType.PLASTIC) {
      return 'cm³';
    } else if (item.MaterialType == MaterialType.FIBER) {
      return 'm';
    }

    return '?';
  }

  private IsPlastic(item: MaterialConsumptionArchive): boolean {
    return item.MaterialType === MaterialType.PLASTIC;
  }
  //#endregion

  //#endregion

  //#region LOGIC
  private async DownloadGCode() {
    if (!this.SourceAvailable) return;

    SourceModule.downloadGCodeFile(this.fullArchive.source!);
  }

  private async PrintAgainClicked() {
    if (this.fullArchive.source == null) return;

    const userConfirmation = await PrintAgainSure(
      Result.Yes | Result.No,
      'Print again',
      `This action will queue ${this.fullArchive.source.CodeFileName} onto ${this.fullArchive.printer.Name} printer. Are you sure?`,
    );

    if (userConfirmation == Result.No || userConfirmation == Result.Cancel) {
      return;
    }

    this.isLoading = true;

    const result = await JobModule.StartNewJob([this.fullArchive.source.Id, this.fullArchive.printer.Id]);

    if (result != null) {
      toast.success(
        `New job for file ${this.fullArchive.source.CodeFileName} has been successfully queued for printer ${this.fullArchive.printer.Name}`,
        {
          position: POSITION.BOTTOM_RIGHT,
        },
      );
    }

    this.isLoading = false;
  }

  private async LoadMaterialConsumptionArchives() {
    this.materialConsumptionArchives = [];

    this.isLoadingMaterialConsumptionArchives = true;

    const result = await JobModule.ReadMaterialConsumptionsByArchiveId(this.fullArchive.jobArchieve.Id);

    this.isLoadingMaterialConsumptionArchives = false;

    if (result == null) {
      return;
    }

    for (const item of result) {
      this.materialConsumptionArchives.push(item);
    }
  }
  //#endregion

  //#region EVENTS
  @Emit('close-clicked')
  private async CloseClicked() {}

  @Emit('printer-clicked')
  private async PrinterClicked() {
    return this.fullArchive.printer;
  }

  @Emit('owner-clicked')
  private async OwnerClicked() {
    return this.fullArchive.owner;
  }

  @Emit('source-clicked')
  private async SourceClicked() {
    return this.fullArchive.source;
  }
  //#endregion

  //#region HOOKS
  //#endregion

  //#region TRANSLATIONS
  private get MCaption(): string {
    return en.meter;
  }
  private get GCaption(): string {
    return en.gr;
  }
  private get SourceCaption() {
    return en.source.growFirst();
  }
  private get PinterCaption() {
    return en.printer.growFirst();
  }
  private get StartedCaption() {
    return en.started.growFirst();
  }
  private get FinishedCaption() {
    return en.finished.growFirst();
  }
  private get StatusCaption() {
    return en.status.growFirst();
  }
  private get OwnerCaption() {
    return en.owner.growFirst();
  }
  private get PrintAgainCaption() {
    return en.printAgain.growFirst();
  }
  private get MaterialConsumptionsCaption(): string {
    return en.materialConsumptions.growFirst();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.job-archive-editor {
  background: var(--editor-background);
  border-radius: 6px;

  .material-consumptions {
    background: none;
  }

  .spinner-container {
    min-height: 40px;
    margin: 20px 0;
    position: relative;
    opacity: 1;

    &.hidden {
      opacity: 0;
      min-height: 0px;
      margin: 0px;
    }

    transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);
  }
}
</style>
