<template>
  <div class="shop-product" :collapsible="false" :hasHeader="false">
    <div class="info">
      <div class="d-flex flex-column">
        <span class="name">
          {{ Name }}
        </span>
        <span class="description">
          {{ Description }}
        </span>
      </div>

      <div v-if="IsPriceOneTime" class="price mt-3">
        <span> {{ PriceOneTime }} </span>
        <span> {{ OneTimePaymentCaption }} </span>
      </div>
      <div v-if="IsPriceRecurring" class="price-recurring mt-3">
        <span> {{ PriceMonthly }} </span>
        <binary-switch
          :class="['mx-3', !Purchasable ? 'disabled' : null]"
          :initialState="0"
          @clicked="OnSwitchRecurring"
        />
        <span> {{ PriceYearly }} </span>

        <!--<input
          class="ml-3"
          type="checkbox"
          v-if="HasDailyPrice"
          v-model="selectedDaily"
        />-->
      </div>
    </div>

    <v-popover trigger="hover" placement="right" :disabled="Purchasable">
      <div :class="['buttons mr-2 ml-2', !Purchasable ? 'unavailable' : null]">
        <div v-if="Quantity == 0" class="add-to-cart">
          <span class="i" @click="Purchasable ? AddQuantity() : undefined"
            ><i class="far fa-cart-arrow-down"> </i
          ></span>
        </div>
        <div v-else class="quantity-control">
          <div>
            <span class="i" @click="Purchasable ? RemoveQuantity() : undefined"><i class="far fa-minus"> </i></span>
            <span class="mx-3">{{ Quantity }}</span>
            <span class="i" @click="Purchasable ? AddQuantity() : undefined"> <i class="far fa-plus"> </i></span>
          </div>
        </div>
      </div>

      <div slot="popover">
        {{ PurchasableReason }}
      </div>
    </v-popover>
  </div>
</template>

<script lang="ts">
import en from '@/localization/en';
import { AccountModule } from '@/store/modules/billing/accountModule';
import { CartModule } from '@/store/modules/billing/cartModule';
import { ProductFull } from '@/store/modules/billing/productModule';
import { ProductPrice } from '@/models/Entities';
import { PaymentType } from '@/models/enums/PaymentType';
import { RecurringIntervalKind } from '@/models/enums/RecurringIntervalKind';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ActionCard from './ActionCard.vue';
import BinarySwitch from '../inputs/BinarySwitch.vue';
import { VPopover } from 'v-tooltip';

interface RecurringPriceData {
  monthly: ProductPrice;
  yearly: ProductPrice;
  daily: ProductPrice | null;
}

export interface PurchasableData {
  isPurchasable: boolean;
  reason: string;
}

@Component({
  components: {
    ActionCard: ActionCard,
    BinarySwitch: BinarySwitch,
    VPopover: VPopover,
  },
  name: 'shop-product',
})
export default class ShopProduct extends Vue {
  @Prop() productFull!: ProductFull;
  @Prop() purchasableData!: PurchasableData;

  private recurringSelected: 'daily' | 'monthly' | 'yearly' = 'monthly';
  private get Quantity(): number {
    const inCart = CartModule.CartItems.firstOrDefault(a => a.product.id === this.productFull.product.id);
    if (inCart === null) return 0;

    return inCart.quantity;
  }

  private selectedDaily: boolean = false;

  private get HasDailyPrice(): boolean {
    return this.RecurringData?.daily !== null;
  }

  private get Purchasable(): boolean {
    return this.purchasableData.isPurchasable;
  }

  private get PurchasableReason(): string {
    return this.purchasableData.reason;
  }

  private get Price(): ProductPrice | null {
    if (AccountModule.Account === null) {
      return null;
    }

    // stripe currencies are lowercase
    const accountCurrecy = en.CountryCurrencies[AccountModule.Account.country].toLowerCase();

    const found = this.productFull.prices.firstOrDefault(a => a.currency === accountCurrecy);

    // fallback to euro
    if (found === null) {
      return this.productFull.prices.firstOrDefault(a => a.currency == 'eur');
    }

    return found;
  }
  private get RecurringData(): RecurringPriceData | null {
    if (AccountModule.Account === null) {
      return null;
    }

    const accountCurrecy = en.CountryCurrencies[AccountModule.Account.country].toLowerCase();

    let monthly = this.productFull.prices.firstOrDefault(
      a => a.recurringInterval === RecurringIntervalKind.Month && a.currency === accountCurrecy,
    );
    let yearly = this.productFull.prices.firstOrDefault(
      a => a.recurringInterval === RecurringIntervalKind.Year && a.currency === accountCurrecy,
    );
    let daily = this.productFull.prices.firstOrDefault(
      a => a.recurringInterval === RecurringIntervalKind.Day && a.currency === accountCurrecy,
    );

    if (monthly === null || yearly === null) {
      monthly = this.productFull.prices.firstOrDefault(
        a => a.recurringInterval === RecurringIntervalKind.Month && a.currency === 'eur',
      );
      yearly = this.productFull.prices.firstOrDefault(
        a => a.recurringInterval === RecurringIntervalKind.Year && a.currency === 'eur',
      );
      let daily = this.productFull.prices.firstOrDefault(
        a => a.recurringInterval === RecurringIntervalKind.Day && a.currency === 'eur',
      );

      if (monthly === null || yearly === null) {
        return null;
      }

      return {
        monthly: monthly,
        yearly: yearly,
        daily: daily,
      };
    }

    return {
      monthly: monthly,
      yearly: yearly,
      daily: daily,
    };
  }

  private get PriceOneTime(): string | null {
    let price = this.Price;

    if (price == null) {
      return null;
    }

    return en.CurrencySymbols[price.currency.toUpperCase()] + (price.price / 100).toFixed(2);
  }

  private get PriceDaily(): string | null {
    let data = this.RecurringData;

    if (data == null) {
      return null;
    }

    const price = data.monthly;

    return en.CurrencySymbols[price.currency.toUpperCase()] + (price.price / 100).toFixed(2) + '/' + this.DayCaption;
  }
  private get PriceMonthly(): string | null {
    let data = this.RecurringData;

    if (data == null) {
      return null;
    }

    const price = data.monthly;

    return en.CurrencySymbols[price.currency.toUpperCase()] + (price.price / 100).toFixed(2) + '/' + this.MonthCaption;
  }
  private get PriceYearly(): string | null {
    let data = this.RecurringData;

    if (data == null) {
      return null;
    }

    const price = data.yearly;

    return en.CurrencySymbols[price.currency.toUpperCase()] + (price.price / 100).toFixed(2) + '/' + this.YearCaption;
  }

  private get IsPriceOneTime(): boolean {
    let price = this.Price;

    if (price === null) return false;

    return price.paymentType === PaymentType.OneTime;
  }
  private get IsPriceRecurring(): boolean {
    let price = this.Price;

    if (price === null) return false;

    return price.paymentType === PaymentType.Recurring;
  }

  private get Name(): string {
    if (this.productFull.baseProduct !== null) {
      // Todo: I though we had this logic..
      // return (
      //   this.productFull.baseProduct.name + "." + this.productFull.product.name
      // );
    }
    return this.productFull.product.name;
  }
  private get Description(): string {
    return this.productFull.product.description;
  }

  private get SelectedPrice(): ProductPrice | null {
    if (this.IsPriceOneTime) {
      return this.Price;
    }

    if (this.selectedDaily) {
      return this.RecurringData?.daily!;
    }

    if (this.recurringSelected == 'monthly') {
      return this.RecurringData?.monthly!;
    } else if (this.recurringSelected == 'yearly') {
      return this.RecurringData?.yearly!;
    }

    return null;
  }

  private OnSwitchRecurring() {
    const prevPrice = this.SelectedPrice;

    if (this.recurringSelected == 'monthly') {
      this.recurringSelected = 'yearly';
    } else if (this.recurringSelected == 'yearly') {
      this.recurringSelected = 'monthly';
    }

    CartModule.SwitchRecurring([prevPrice!, this.SelectedPrice!]);
  }

  private AddQuantity() {
    CartModule.QuantityAdded(this.SelectedPrice!);
  }

  private RemoveQuantity() {
    CartModule.QuantityRemoved(this.SelectedPrice!);
  }

  private get OneTimePaymentCaption(): string {
    return 'one time payment';
  }
  private get DayCaption(): string {
    return 'day';
  }
  private get MonthCaption(): string {
    return 'month';
  }
  private get YearCaption(): string {
    return 'year';
  }
}
</script>

<style lang="scss" scoped>
.shop-product {
  display: grid;
  grid-template-columns: 4fr 100px;
  justify-content: space-between;

  padding: 12px 18px;
  min-height: 120px;

  // css variables don't work here ???? WHAT
  background: linear-gradient(90deg, rgba(#f4541f, 0.2) 0%, rgba(#424242, 0.2) 100%);
  color: var(--main-text);
  border: 1px solid var(--action-card-border);
  box-sizing: border-box;
  border-radius: 6px;

  .info {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: space-between;

    span.name {
      font-size: 20px;
    }

    span.description {
      color: var(--grayed-out-text);
      font-size: 14px;
    }

    .price {
      font-size: 16px;
    }

    .price-recurring {
      font-size: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .buttons {
    display: flex;
    align-self: center;
    user-select: none;
    font-size: 24px;

    .add-to-cart {
      display: flex;
      align-items: center;
    }

    .quantity-control {
      display: flex;
      align-items: center;
    }

    &:not(.unavailable) {
      .i {
        cursor: pointer;
        &:hover {
          opacity: 0.75;
        }
      }
    }

    &.unavailable {
      opacity: 0.5;
    }
  }

  .v-popover {
    align-self: center;
    display: flex;
    justify-self: end;
  }
}
</style>
