import { Guid } from 'guid-typescript';
import { InvoiceStatus } from '../enums/InvoiceStatus';
import { LicenseType } from '../enums/LicenseType';
import { PaymentMethodKind } from '@/models/enums/PaymentMethodKind';
import { PaymentType } from '@/models/enums/PaymentType';
import { PurchaseStatus } from '@/models/enums/PurchaseStatus';
import { RecurringIntervalKind } from '@/models/enums/RecurringIntervalKind';
import { IPlainResponse } from './BaseResponse';
import { Countries, States } from './Countries';
import { PaymentState } from '../enums/PaymentState';
import { TaxType } from '../enums/TaxType';
import { TaxIdType } from '../enums/TaxIdType';

export class ResponseAccount implements IPlainResponse {
  id!: Guid;
  name!: string;
  address!: string;
  country!: Countries;
  city!: string;
  state!: States | null;
  postalCode!: string;
  email!: string;
  stripeBalance!: number;
  activated!: boolean;
  taxIdType!: TaxIdType | null;
  taxId!: string | null;
}

export class ResponseAccountAdmin implements IPlainResponse {
  id!: Guid;
  accountId!: Guid;
  isOwner!: boolean;
}

export class ResponseInvoice implements IPlainResponse {
  id!: Guid;
  accountId!: Guid;
  subscriptionId!: Guid | null;
  status!: InvoiceStatus;
  invoiceNumber!: string | null;
  createdDate!: string; // Date
  currency!: string;
  totalAmount!: number;
  invoicePdfUrl!: string | null;
  stripePaymentIntentId!: string | null;
  paymentState!: PaymentState;
}

export class ResponseInvoices implements IPlainResponse {
  invoices!: ResponseInvoice[];
}

export class ResponseInvoicePaymentIntentSecret implements IPlainResponse {
  clientSecret!: string;
}

export class ResponsePaymentMethod implements IPlainResponse {
  id!: Guid;
  accountId!: Guid;
  stripeId!: string;
  methodType!: PaymentMethodKind;
  expirationMonth!: number;
  expirationYear!: number;
  last4Digits!: string;
  isDefault!: boolean;
}

export class ResponsePaymentMethods implements IPlainResponse {
  paymentMethods!: ResponsePaymentMethod[];
}

export class ResponsePrepareCreatePaymentMethod implements IPlainResponse {
  clientSecret!: string;
}

export class ResponsePendingSubscriptionUpdate implements IPlainResponse {
  id!: Guid;
  subscriptionId!: Guid;
  newQuantity!: number | null;
  newProductPriceId!: Guid | null;
  stripeSubscriptionScheduleId!: string | null;
  invoiceId!: string | null;
  applyDate!: string | null;
}

export class ResponsePendingSubscriptionUpdates implements IPlainResponse {
  pendingSubscriptionUpdates!: ResponsePendingSubscriptionUpdate[];
}

export class ResponseProduct implements IPlainResponse {
  id!: Guid;
  licenseType!: LicenseType;
  name!: string;
  description!: string;
  outdated!: boolean;
  isPublic!: boolean;
  baseProductId!: Guid | null;
  shortName!: string;
}

export class ResponseProducts implements IPlainResponse {
  products!: ResponseProduct[];
}

export class ResponseProductPrice implements IPlainResponse {
  id!: Guid;
  productId!: Guid;
  name!: string;
  paymentType!: PaymentType;
  recurringInterval!: RecurringIntervalKind | null;
  recurringIntervalCount!: number | null;
  currency!: string;
  price!: number;
  metadata!: string;
  outdated!: boolean;
  isPublic!: boolean;
  recurringLevel!: number | null;
}

export class ResponseProductPrices implements IPlainResponse {
  prices!: ResponseProductPrice[];
}

export class ResponsePurchasedItem implements IPlainResponse {
  id!: Guid;
  productPriceId!: Guid;
  accountId!: Guid;
  purchaseDate!: string; // Date
  status!: PurchaseStatus;
  baseItemId!: Guid | null;
  cryptlexId!: string | null;
  cryptlexKey!: string | null;
  activated!: boolean;
  deviceName!: string;
  purchasedSubscriptionId!: Guid | null;
  invoiceId!: Guid | null;
  deleted!: boolean;
  offlineActivationResponse!: string | null;
  offlineActivationResponseValidBefore!: string | null;
}

export class ResponsePurchasedItemActivateOffline {
  offlineResponse!: string;
}

export class ResponsePurchasedItems implements IPlainResponse {
  items!: ResponsePurchasedItem[];
}

export class ResponsePurchasedSubscription implements IPlainResponse {
  id!: Guid;
  accountId!: Guid;
  status!: PurchaseStatus;
  subscriptionStart!: string; // Date
  currentPeriodStart!: string | null; // Date
  currentPeriodEnd!: string | null; // Date
  cancelAtPeriodEnd!: boolean;
}

export class ResponsePurchasedSubscriptions implements IPlainResponse {
  subscriptions!: ResponsePurchasedSubscription[];
}

export class ResponsePreviewSubscriptionChanges implements IPlainResponse {
  currentTotal!: number;
  previewTotal!: number;
  chargeNow!: number;

  currency!: string;
  prorationDate!: string;
  nextAttempt!: string | null;

  currentTotalTax!: number;
  previewTotalTax!: number;
  chargeNowTax!: number;
}

export class ResponseTaxRate implements IPlainResponse {
  id!: Guid;
  country!: Countries;
  name!: string;
  inclusive!: boolean;
  state!: States | null;
  percentage!: number;
  type!: TaxType;
}
