<template>
  <AuraDialogWrapper
    ref="dialogWrapper"
    :headerText="'Export logs'"
    @closed="DialogWrapperClosed"
    @dismissed="DialogWrapperClosed"
  >
    <div class="log-export-container">
      <div :class="['d-flex mb-3', isLoading ? 'disabled' : '']">
        <span> All </span>
        <binary-switch :class="['mx-3']" :initialState="0" @clicked="OnSwitchExportType" />
        <span> Range </span>
      </div>

      <div :class="['d-flex mb-2', exportAll ? 'disabled' : '']">
        <date-input
          :readonly="false"
          :label="DateFromCaption"
          :disabled="isLoading"
          :invalid="dateValidationFailed"
          :showHour="true"
          :showMinute="false"
          :showSecond="false"
          :date.sync="dateFrom"
          :labelWidth="labelWidth"
        >
        </date-input>
      </div>

      <div :class="['d-flex mb-1', exportAll ? 'disabled' : '']">
        <date-input
          :readonly="false"
          :label="DateToCaption"
          :disabled="isLoading"
          :invalid="dateValidationFailed"
          :showHour="true"
          :showMinute="false"
          :showSecond="false"
          :date.sync="dateTo"
          :labelWidth="labelWidth"
        >
        </date-input>

        <dark-button class="ml-2" :label="NowCaption" :disabled="isLoading" @clicked="setToDateToNow" />
      </div>

      <fade-transition :maxHeight="28">
        <div v-if="validationErrors.length != 0" class="errors-container">
          <span class="mt-2">{{ validationErrors[0] }}</span>
        </div>
      </fade-transition>

      <regular-button class="w-100 mt-4" :label="'Export'" @clicked="ExportButtonClicked" />

      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </div>
  </AuraDialogWrapper>
</template>

<script lang="ts">
import DarkButton from '@/components/buttons/DarkButton.vue';
import RegularButton from '@/components/buttons/RegularButton.vue';
import BinarySwitch, { BinarySwitchState } from '@/components/inputs/BinarySwitch.vue';
import DateInput from '@/components/inputs/DateInput.vue';
import FadeTransition from '@/components/presentation/FadeTransition.vue';
import en from '@/localization/en';
import { toast } from '@/main';
import { Printer } from '@/models/Entities';
import { ConnectorModule } from '@/store/modules/connectorModule';
import { DialogComponent } from 'vue-modal-dialogs';
import { Component, Prop, Ref } from 'vue-property-decorator';
import { POSITION } from 'vue-toastification';
import AuraDialogWrapper from './base/AuraDialogWrapper.vue';

@Component({
  components: {
    DateInput: DateInput,
    DarkButton: DarkButton,
    BinarySwitch: BinarySwitch,
    RegularButton: RegularButton,
    AuraDialogWrapper: AuraDialogWrapper,
    FadeTransition: FadeTransition,
  },
})
export default class LogExportDialog extends DialogComponent<boolean> {
  labelWidth: number = 100;

  @Prop() printer!: Printer;

  @Ref() dialogWrapper!: AuraDialogWrapper;

  //#region STATE
  private isLoading: boolean = false;

  private validationTimeoutHandle: number = -1;
  private dateValidationFailed: boolean = false;
  private validationErrors: string[] = [];

  private from: Date = new Date();
  private to: Date = new Date();

  private get dateFrom(): Date {
    return this.from;
  }
  private set dateFrom(value: Date) {
    value.setMinutes(0);
    value.setSeconds(0);
    value.setMilliseconds(0);
    this.from = value;
  }

  private get dateTo(): Date {
    return this.to;
  }
  private set dateTo(value: Date) {
    value.setMinutes(0);
    value.setSeconds(0);
    value.setMilliseconds(0);
    this.to = value;
  }

  private exportAll: boolean = true;
  //#endregion

  //#region LOGIC
  private DialogWrapperClosed() {
    this.$close(false);
  }

  private setToDateToNow() {
    this.dateTo = new Date();
  }

  private OnSwitchExportType(state: BinarySwitchState) {
    if (state == BinarySwitchState.Left) {
      this.exportAll = true;
    } else {
      this.exportAll = false;
    }
  }

  private clearValidationErrors() {
    if (this.validationTimeoutHandle !== -1) {
      clearTimeout(this.validationTimeoutHandle);
    }

    this.validationErrors = [];
    this.dateValidationFailed = false;
    this.validationTimeoutHandle = -1;
  }

  private validate(): boolean {
    this.clearValidationErrors();

    if (this.from > this.to) {
      this.validationErrors.push('Final date has to be later than start date');
      this.dateValidationFailed = true;
    }

    const failed = this.validationErrors.length != 0;

    if (failed) {
      this.validationTimeoutHandle = window.setTimeout(() => {
        this.clearValidationErrors();
      }, 3500);
    }

    return !failed;
  }

  private async ExportButtonClicked() {
    if (!this.validate()) {
      return;
    }

    this.from.setMinutes(0);
    this.from.setSeconds(0);
    this.from.setMilliseconds(0);

    this.to.setMinutes(0);
    this.to.setSeconds(0);
    this.to.setMilliseconds(0);

    const from = this.exportAll ? null : this.from;
    const to = this.exportAll ? null : this.to;

    this.isLoading = true;
    const result = await ConnectorModule.GetLogs({ printerId: this.printer.Id, from, to });
    this.isLoading = false;

    if (result) {
      toast.success('Logs export started successfuly', {
        position: POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error('Could not start log export :(', {
        position: POSITION.BOTTOM_RIGHT,
      });
    }

    this.$close(result);
  }
  //#endregion

  //#region HOOKS
  mounted() {
    this.dateFrom = new Date();
    this.dateTo = new Date();
  }
  //#endregion

  //#region TRANSLATIONS
  private get DateFromCaption(): string {
    return en.dateFrom.titleCase();
  }
  private get NowCaption(): string {
    return en.now.titleCase();
  }
  private get DateToCaption(): string {
    return en.dateTo.titleCase();
  }
  private get TomorrowCaption(): string {
    return en.tomorrow.titleCase();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.log-export-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 400px;

  .message {
    text-align: left;
    font-size: 14px;
  }

  .errors-container {
    display: flex;
    span {
      color: var(--editor-error-text);
      font-size: 14px;
    }
  }
}
</style>
