<template>
  <div class="editor-content px-3 py-2">
    <slot />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class EditorContent extends Vue {}
</script>

<style lang="scss" scoped>
.editor-content {
  display: flex;
  flex-direction: column;
  position: relative;

  i {
    cursor: pointer;
    color: var(--editor-action-button);
    &:hover {
      opacity: 0.75;
    }
  }
}
</style>
