<template>
  <div class="search-input">
    <input
      v-model="search"
      class="w-100"
      type="text"
      placeholder="Search..."
      spellcheck="false"
      :data-testid="dataTestId"
      @input="OnSearchChanged"
    />
    <i class="fas fa-search ml-2"></i>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import { createTestId } from '@/util/tests';

@Component({
  name: 'search-input',
  components: {},
})
export default class SearchInput extends Vue {
  @Prop() value!: string | undefined;
  @Prop({ default: undefined }) testId?: string;

  @Watch('value')
  private OnSearchValueChanged(newValue?: string) {
    if (newValue !== undefined) {
      this.search = newValue;
    }
  }

  private search: string = '';

  @Emit('search-changed')
  OnSearchChanged() {
    return this.search;
  }

  private get dataTestId() {
    return createTestId(this.testId, 'searchInput');
  }
}
</script>

<style lang="scss" scoped>
.search-input {
  font-size: 13px;
  display: flex;
  color: var(--search-input-text);
  align-items: center;
  input {
    border: none;
    background: none;
    color: var(--search-input-text);
  }
  border-radius: 30px;
  background-color: var(--search-input-background);
  border: var(--search-input-border);
  padding: 4px 12px;
}
</style>
