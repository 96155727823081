<template>
  <transition name="fade" enter-active-class="fadeIn" leave-active-class="fadeOut" @enter="enter" @leave="leave">
    <slot></slot>
  </transition>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FadeTransition extends Vue {
  @Prop({ type: Number, default: 100 }) private maxHeight!: number;
  @Prop({ type: Number, default: 0.25 }) duration!: number;

  private enter(el: HTMLElement) {
    el.style.maxHeight = `${this.maxHeight}px`;
    el.style.animationDuration = `${this.duration}s`;
  }
  private leave(el: HTMLElement) {
    el.style.animationDuration = `${this.duration}s`;
    el.style.overflow = 'hidden';
  }
}
</script>

<style scoped>
@keyframes fadeIn {
  from {
    max-height: 0;
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
  animation-timing-function: ease-out;
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    max-height: 0;
    opacity: 0;
  }
}
.fadeOut {
  animation-name: fadeOut;
  animation-timing-function: ease-out;
}
</style>
