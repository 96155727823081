<template>
  <div class="list-item d-flex flex-column w-100 root">
    <hr class="upper-border" />
    <a
      active
      :class="[
        mainClass,
        selected ? 'selected' : '',
        'main-text d-flex flex-row w-100 overflow-hidden',
        bigMargin ? 'pl-2 pr-2' : 'pl-1 pr-1',
      ]"
      :data-testid="getTestId('listItem')"
      @click="onClick"
    >
      <div
        v-for="(item, indx) in Items"
        :key="item.name"
        :class="[item.GetWidthClass(), 'd-flex flex-row align-items-center list-view-cell overflow-hidden']"
        :style="item.GetMaxWidthStyle()"
      >
        <div :class="['data-cotainer d-flex flex-row text-left overflow-hidden text-truncate']">
          <div v-if="headerText != null && indx == 0" class="header mr-2">
            {{ headerText }}
          </div>
          <div
            v-if="headerIconName != null && indx == 0"
            class="header-icon mr-2"
            :style="{ 'font-size': headerIconFontSize + 'px' }"
          >
            <i :class="[headerIconType, headerIconName]"></i>
          </div>
          <div :class="item.specialText === undefined && item.iconName === undefined ? 'mr-3' : ''">
            {{ item.content }}
          </div>
          <div
            v-if="item.specialText !== undefined"
            :class="['ml-2 special-text', item.iconName === undefined && item.iconClicked === undefined ? 'mr-3' : '']"
          >
            {{ item.specialText }}
          </div>
          <div
            v-if="item.iconName !== undefined"
            :class="['icon ml-2', item.iconShowAlways ? 'show-always' : '', item.iconClicked ? 'clickable' : '']"
            :style="{
              'font-size': item.iconFontSize + 'px',
              color: item.iconColor === undefined ? 'unset' : item.iconColor,
            }"
          >
            <span class="icon-button" @click.stop="item.iconClicked">
              <i v-tooltip.right="item.iconToolTip" class="cur-pointer mr-3" :class="[item.iconType, item.iconName]"></i
            ></span>
          </div>
        </div>

        <div
          v-if="indx === Items.length - 1 && footerIconName != null"
          :class="['footer-icon mr-1', footerIconShowOnlyOnHover ? 'show-only-on-hover' : null]"
          :style="{ 'font-size': footerIconFontSize + 'px' }"
        >
          <span class="icon-button" :data-testid="getTestId('listItem.actionButton')" @click.stop="footerIconClicked">
            <i class="cur-pointer" :class="[footerIconType, footerIconName]"></i>
          </span>
        </div>
      </div>
    </a>
    <hr class="lower-border" />
  </div>
</template>

<script lang="ts">
//#region IMPORTS

import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';
import { ItemData } from '@/util/ComponentHelper';
import { Guid } from 'guid-typescript';
import { createTestId } from '@/util/tests';

//#endregion

@Component({})
export default class ListItemV3 extends Vue {
  @Prop() private id!: Guid;
  @Prop() private items!: ItemData[];
  @Prop({ default: '' }) private mainClass!: string;
  @Prop({ default: null }) private headerText!: string | null;
  @Prop({ default: undefined }) testId?: string;

  @Prop({ default: null }) private headerIconName!: string | null;
  @Prop({ default: 'fas' }) private headerIconType!: string;
  @Prop({ default: 20 }) private headerIconFontSize!: number;

  @Prop({ default: null }) private footerIconName!: string | null;
  @Prop({ default: true }) private footerIconShowOnlyOnHover!: boolean;
  @Prop({ default: 'fas' }) private footerIconType!: string;
  @Prop({ default: 20 }) private footerIconFontSize!: number;

  @Prop({ default: false }) private selected!: boolean;

  @Prop({ default: true }) private bigMargin!: boolean;

  asyncItemsLoaded: ItemData[] = [];
  @Prop() private itemsAsync!: Promise<ItemData[]>;

  @Watch('itemsAsync')
  async onItemsAsyncChanged(newValue: Promise<ItemData[]>) {
    this.asyncItemsLoaded = await newValue;
  }
  //#region ITEMS

  private get Items(): ItemData[] {
    if (this.itemsAsync !== undefined) {
      return this.asyncItemsLoaded;
    }

    return this.items;
  }

  async created() {
    if (this.itemsAsync !== null) {
      this.asyncItemsLoaded = await this.itemsAsync;
    }
  }

  //#endregion

  //#region ON CLICK

  @Emit('openItem')
  private onClick() {
    return this.id;
  }

  //#endregion

  @Emit('footer-icon-clicked')
  private footerIconClicked() {
    return this.id;
  }

  private getTestId(elementId: string) {
    return createTestId(this.testId, elementId);
  }
}
</script>

<style lang="scss" scoped>
a {
  color: var(--list-item-text);
  transition: background-color 100ms ease-out;

  &:hover,
  &.selected {
    background-color: rgba(black, 0.1);
    color: var(--list-item-text);
  }

  &:active {
    background-color: rgba(black, 0.185);
    color: var(--list-item-text);
  }

  .header {
    font-weight: 500;
    color: var(--special-text);
  }

  .footer-icon {
    &:hover {
      opacity: 0.75;
    }
  }

  .icon.clickable .icon-button {
    &:hover {
      opacity: 0.75;
    }
  }

  &:hover {
    .footer-icon.show-only-on-hover {
      opacity: 1;
      pointer-events: all;
      &:hover {
        opacity: 0.75;
      }
    }

    .icon {
      .icon-button {
        opacity: 1;
        pointer-events: all;

        &.clickable {
          &:hover {
            opacity: 0.75;
          }
        }
      }
    }
  }

  .footer-icon.show-only-on-hover {
    opacity: 0;
    pointer-events: none;
  }

  .icon {
    .icon-button {
      opacity: 0;
      pointer-events: none;
    }
  }

  .icon.show-always {
    .icon-button {
      opacity: 1;
      pointer-events: all;
    }
  }

  user-select: none;
}

.data-cotainer {
  height: 36px;
  display: flex;
  align-items: center;

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

hr {
  height: 1px;
  width: 100%;
  background-image: linear-gradient(to right, gray 15%, rgba(129, 129, 129, 0) 0%);
  background-position: top;
  background-size: 6px 1px;
  background-repeat: repeat-x;
  border: none;
  margin: 0 0 0 0;
}

.list-view-cell {
  justify-content: space-between;
}

div {
  box-sizing: border-box;
}

.dark-theme {
  a {
    color: rgb(31, 31, 31);
  }
}

.list-item:last-child {
  hr.lower-border {
    display: unset;
  }
}
.list-item:first-child {
  hr.upper-border {
    display: unset;
  }
}
.list-item {
  hr.upper-border {
    display: none;
  }
  hr.lower-border {
    display: unset;
  }
}

.header {
  min-width: fit-content;
}
</style>
