import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import * as ips from '../../util/api/ips';
import * as routes from '../../util/api/routes';
import * as hubActions from '../../util/api/hubActions';
import store from '@/store';
import GC from '../util/GC';
import { LoginSession } from '../../models/Entities';
import { Guid } from 'guid-typescript';
import { RequestDeleteLoginSessions } from '../../models/requests/RequestIdentity';
import { UtilModule } from './utilModule';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { HubConnection } from '@microsoft/signalr';
import { ResponseLoginSession, ResponseIdentityHelper } from '../../models/responses/ResponseIdentity';
import { TypeHelper } from '@/util/TypeHelper';
import { GuidHelper } from '@/util/GuidHelper';
import { LoginModule } from './loginModule';
import { ErrorModule } from '@/store/modules/errorsModule';

@Module({ dynamic: true, name: 'loginSessions', store: store })
export default class loginSessionModule extends VuexModule {
  private loginSessions: LoginSession[] = [];
  private _gc = new GC();

  get LoginSessions(): LoginSession[] {
    return this.loginSessions;
  }
  @Mutation
  AddLoginSession(value: LoginSession) {
    if (this.loginSessions.find(a => a.Id.equals(value.Id))) return;
    this._gc.UpdateSubscription(value.Id);
    this.loginSessions.push(value);
  }
  @Mutation
  DeleteLoginSession(value: LoginSession) {
    this._gc.DeleteSubscription(value.Id);
    this.loginSessions.delete(value);
  }
  @Mutation
  UpdateLoginSessionIP([loginSession, ip]: [LoginSession, string]) {
    loginSession.IP = ip;
  }
  @Mutation
  UpdateLoginSessionLastSeen([loginSession, lastSeen]: [LoginSession, Date]) {
    loginSession.LastSeen = lastSeen;
  }
  @Action({ rawError: true })
  async TerminateAll(sessions: LoginSession[]): Promise<void> {
    if (sessions.empty()) return;
    const request = new RequestDeleteLoginSessions();
    request.ids = sessions.map(a => a.Id.toString());
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.TERMINATE_SESSIONS,
    );
    const { result, message } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result !== RequestStatus.OK) {
      ErrorModule.ShowError(message);
      return;
    }
    for (const session of sessions) {
      this.DeleteLoginSession(session);
    }
  }
  @Action({ rawError: true })
  LoginSessionsSync([loginSessions, componentId]: [LoginSession[], Guid]) {
    this._gc.Occupy(
      loginSessions.map(a => a.Id),
      componentId,
    );
  }
  @Action({ rawError: true })
  async LooseLoginSessions([loginSessionIds, componentId]: [Guid[], Guid]) {
    const toDeleteLoginSessionIds = await this._gc.Loose(loginSessionIds, componentId);
    let lssToDelete = this.LoginSessions.filter(a => GuidHelper.includes(toDeleteLoginSessionIds, a.Id));
    if (LoginModule.Me) {
      lssToDelete = lssToDelete.filter(a => !a.UserId.equals(LoginModule.Me!.Id));
    }
    for (const loginSession of lssToDelete) {
      this.DeleteLoginSession(loginSession);
    }
  }
  @Action({ rawError: true })
  LoginSessionSubscribe(hubConnection: HubConnection) {
    hubConnection.on(hubActions.RECEIVE_NEW_SIGNIN, (response: ResponseLoginSession) => {
      const realLS = ResponseIdentityHelper.createRealLS(response);
      const ls = realLS.Map();
      this.AddLoginSession(ls);
    });
    hubConnection.on(hubActions.RECEIVE_UPDATE_LOGIN_SESSION, (response: ResponseLoginSession) => {
      const realLS = ResponseIdentityHelper.createRealLS(response);
      const loginSesion = this.LoginSessions.singleOrDefault(a => a.Id.equals(realLS.id));
      if (loginSesion == null) return;
      if (loginSesion.IP !== realLS.deviceIP) {
        this.UpdateLoginSessionIP([loginSesion, realLS.deviceIP]);
      }
      if (loginSesion.LastSeen !== TypeHelper.StringToDate(realLS.lastSeen)) {
        this.UpdateLoginSessionLastSeen([loginSesion, TypeHelper.StringToDate(realLS.lastSeen)]);
      }
    });
    hubConnection.on(hubActions.RECEIVE_DELETE_LOGIN_SESSION, async (response: ResponseLoginSession) => {
      const realLS = ResponseIdentityHelper.createRealLS(response);
      const loginSession = this.LoginSessions.singleOrDefault(a => a.Id.equals(realLS.id));
      if (loginSession == null) return;
      if (realLS.id.equals(LoginModule.LoginSessionId)) {
        if (!LoginModule.IsAuthorized) return;
        await LoginModule.LogoutFromClient();
      }
      this.DeleteLoginSession(loginSession);
    });
  }
}

export const LoginSessionModule = getModule(loginSessionModule);
