<template>
  <AuraDialogWrapper
    ref="dialogWrapper"
    :headerText="SubscriptionProductName + ' ' + AvailableSubscriptionsCaption"
    @closed="DialogWrapperClosed"
    @dismissed="DialogWrapperClosed"
  >
    <div :class="['content thin-scroll mb-2']" style="max-height: 50vh; overflow: auto">
      <sortable-header :header-items="SubscriptionAddonsGropHeader" />
      <list-item-v-3
        v-for="item in SortedGroups"
        :id="item.id"
        :key="item.id.toString()"
        mainClass="cur-pointer"
        :items="SubscriptionAddonsGropItems(item)"
        @openItem="OpenSubscriptionItemsGroup(item)"
      />
    </div>
  </AuraDialogWrapper>
</template>

<script lang="ts">
import { Component, Prop, Ref } from 'vue-property-decorator';
import { DialogComponent } from 'vue-modal-dialogs';
import SortableHeader from '@/components/presentation/SortableHeader.vue';
import ListItemV3 from '@/components/presentation/ListItemV3.vue';
import { PurchasedItem } from '@/models/Entities';
import ComponentHelper, { HeaderItem, ItemData } from '@/util/ComponentHelper';
import dateformat from 'dateformat';
import { toast } from '@/main';
import { POSITION } from 'vue-toastification';
import { PurchasedItemFullGroup } from '@/store/modules/billing/purchasedItemModule';
import en from '@/localization/en';
import AuraDialogWrapper from '@/views/dialogs/base/AuraDialogWrapper.vue';

@Component({
  components: {
    SortableHeader: SortableHeader,
    ListItemV3: ListItemV3,
    AuraDialogWrapper: AuraDialogWrapper,
  },
})
export default class AttachSubscriptionDialog extends DialogComponent<PurchasedItem | null> {
  @Prop() private subscriptionItemGroups!: PurchasedItemFullGroup[];
  @Ref() dialogWrapper!: AuraDialogWrapper;

  private get SortedGroups() {
    return this.subscriptionItemGroups
      .sort((a, b) => {
        const dateA = a.group[0].subscription!.currentPeriodEnd!;
        const dateB = b.group[0].subscription!.currentPeriodEnd!;

        return dateB.getTime() - dateA.getTime();
      })
      .sort((a, b) => {
        return a.group[0].subscription!.status - b.group[0].subscription!.status;
      });
  }

  private get SubscriptionProductName() {
    return this.subscriptionItemGroups[0].group[0].product.name;
  }

  private get SubscriptionAddonsGropHeader(): HeaderItem[] {
    let headerName: HeaderItem = {
      caption: en.subscriptionName.titleCase(),
      itemClass: ComponentHelper.GetWidthClass('grow-1'),
    };
    let headerValidBefore: HeaderItem = {
      caption: en.validBefore.titleCase(),
      itemClass: ComponentHelper.GetWidthClass(125),
    };
    let headerStatus: HeaderItem = {
      caption: en.status.titleCase(),
      itemClass: ComponentHelper.GetWidthClass(125),
    };

    let headerItems: HeaderItem[] = [];

    headerItems.push(headerName);
    headerItems.push(headerValidBefore);
    headerItems.push(headerStatus);

    return headerItems;
  }

  private SubscriptionAddonsGropItems(item: PurchasedItemFullGroup): ItemData[] {
    let items: ItemData[] = [];

    let value = item.group[0];
    const currentPeriodEnd = value.subscription!.currentPeriodEnd!;

    let itemSubName: ItemData = new ItemData(
      'SubscriptionName',
      value.product.name + ' (' + ComponentHelper.GetReadableRecurringInterval(value.price.recurringInterval!) + ')',
      300,
    );
    let itemValidBefore: ItemData = new ItemData('ValidBefore', dateformat(currentPeriodEnd!, 'dd/mm/yyyy'), 125);
    let itemStatus: ItemData = new ItemData(
      'Status',
      ComponentHelper.GetReadablePurchaseStatus(value.item.status),
      125,
    );

    items.push(itemSubName);
    items.push(itemValidBefore);
    items.push(itemStatus);

    return items;
  }

  OpenSubscriptionItemsGroup(item: PurchasedItemFullGroup) {
    const active = item.group.filter(a => a.baseItem !== null).length;
    const total = item.group.length;

    if (active < total) {
      this.dialogWrapper.CloseDialog();
      this.$close(item.group.firstOrDefault(a => a.baseItem === null)?.item!);
    } else {
      toast.error(en.thereAreNoMoreSubs, {
        position: POSITION.BOTTOM_RIGHT,
      });
    }
  }

  private DialogWrapperClosed() {
    this.$close(null);
  }

  private get AvailableSubscriptionsCaption() {
    return en.availableSubscriptions;
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  text-align: left;
}
</style>
