<template>
  <div class="bc-sm h-100 d-flex flex-column side-menu">
    <nav class="thin-scroll">
      <ul class="nav flex-column">
        <li v-for="item in MenuItems" :key="item.caption" class="nav-item">
          <a
            :class="[item.isChecked ? 'active' : '', 'nav-link', getClassName(item.caption)]"
            :data-testid="getTestId(item.caption)"
            @click="handleClickOnItem(item)"
          >
            <div class="d-flex flex-column">
              <div class="btn-symbol d-inline w-100 justify-content-center">
                <i :class="[item.symbol.toString(), item.isSpecial ? 'special' : '', 'nav-item__icon']"></i>
              </div>

              <div class="text-uppercase mt-0 text-center caption nav-item__text">
                {{ getCaption(item) }}
              </div>
            </div>
          </a>
        </li>
        <li v-if="isAura3Visible" class="nav-item">
          <a class="nav-link sm-value-1" :data-testid="getTestId('aura')" @click="handleClickOnAura">
            <div class="d-flex flex-column">
              <div class="btn-symbol btn-symbol-offset d-inline w-100 justify-content-center">
                <Cube />
              </div>
              <div class="text-uppercase mt-0 text-center caption">Aura</div>
            </div>
          </a>
        </li>
      </ul>
    </nav>
    <div class="ft d-flex mt-auto flex-column">
      <span id="version" class="mb-2 text-center mr-2">{{ versionCaption }} {{ versionNumber }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { Guid } from 'guid-typescript';

import globalDataModule from '@/store/modules/globalDataModule';
import { LoginModule } from '@/store/modules/loginModule';
import { CompanyModule } from '@/store/modules/companyModule';
import { WFComponentData } from '@/views/WorkField.vue';

import { IsConnectUserRoute } from '@/router';
import { Routes } from '@/router/routes';
import { StoreHelper } from '@/store/util/storeHelpers';
import { isAura3Visible } from '@/util/env';
import Cube from '@/components/icons/Cube.vue';
import en from '@/localization/en';

interface SideMenuItem {
  caption: string;
  routeName: string;
  isChecked: boolean;
  isSpecial: boolean;
  symbol: string;
}

@Component({
  components: { Cube },
})
export default class SideMenu extends Vue {
  @Prop() private checkedItem!: WFComponentData;
  @Prop() private items!: WFComponentData[];
  @Prop() private specialItem!: WFComponentData;
  private globalDataModule!: globalDataModule;

  private componentId: Guid = Guid.create();

  get IsConnectUser() {
    return LoginModule.IsConnectUser;
  }

  get IsSuperAdmin() {
    return LoginModule.IsSuperAdmin;
  }

  get isAura3Visible() {
    return isAura3Visible();
  }

  async created() {
    this.globalDataModule = getModule(globalDataModule, this.$store);
  }

  async beforeDestroy() {
    if (LoginModule.Company) {
      await CompanyModule.LooseCompanies([[LoginModule.Company.Id], this.componentId]);
    }
  }

  isCompanyItem(item: SideMenuItem): boolean {
    return item.caption === en.company;
  }

  getCaption(item: SideMenuItem): string | undefined {
    if (this.isCompanyItem(item)) {
      if (LoginModule.Company) {
        CompanyModule.CompaniesSync([[LoginModule.Company], this.componentId]);
        return LoginModule.Company.Name;
      }

      return en.company;
    }

    return item.caption;
  }

  isItemVisible(item: SideMenuItem): boolean {
    if (IsConnectUserRoute(item.routeName)) {
      if (this.IsConnectUser) {
        return true;
      }

      return false;
    }

    if (item.routeName == Routes.ADMIN) {
      if (this.IsSuperAdmin) {
        return true;
      }

      return false;
    }

    return true;
  }

  get MenuItems(): SideMenuItem[] {
    if (!this.items || this.items.empty()) return [];
    // if (!this.checkedItem) this.checkedItem === this.items[0];
    let sideMenu: SideMenuItem[] = [];
    for (let item of this.items) {
      let itemToAdd: SideMenuItem = {
        caption: item.name,
        routeName: item.routeName,
        isChecked: this.checkedItem.routeName === item.routeName,
        isSpecial: this.specialItem && this.specialItem.name === item.name,
        symbol: item.symbol ?? '',
      };
      sideMenu.push(itemToAdd);
    }
    return sideMenu.filter(a => this.isItemVisible(a));
  }

  get versionNumber(): string {
    return this.globalDataModule.Version;
  }

  get versionCaption(): string {
    return en.version;
  }

  getClassName(caption: string): string {
    let classname = 'sm-value-4';
    if (caption) {
      if (caption.length <= 16) classname = 'sm-value-1';
      else {
        if (caption.length <= 25) classname = 'sm-value-2';
        else {
          if (caption.length <= 30) classname = 'sm-value-3';
        }
      }
    }
    return classname;
  }

  getTestId(name: string) {
    return `nav.${name}`;
  }

  handleClickOnItem(item: SideMenuItem) {
    if (item.routeName === this.checkedItem.routeName) {
      return;
    }
    this.$router.push({ name: item.routeName });
  }

  async handleClickOnAura() {
    const queryParams = await LoginModule.getAuthQueryParams();
    StoreHelper.redirectToAuraApp(queryParams);
  }
}
</script>

<style scoped>
.bc-sm {
  background-color: #313131;
  max-width: 90px;
}

nav {
  overflow: overlay;
}

ul.nav {
  padding-top: 26px;
}

.nav-item {
  width: 90px;
}

.caption {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

li.nav-item {
  text-align: left;
  font-family: 'Roboto', sans-serif;
}

.sm-value-1 {
  font-size: 10px;
}

.sm-value-2 {
  font-size: 9px;
}

.sm-value-3 {
  font-size: 8px;
}

.sm-value-4 {
  font-size: 7px;
}

li.nav-item a {
  height: 100%;
  letter-spacing: 0.145em;
  color: var(--nav-not-active-color);
  vertical-align: center;
  padding: 15px 5px;
  transition: background-color 0.2s ease-out;
}

li.nav-item:hover a {
  color: var(--nav-active-color);
  cursor: pointer;
}

li a::first-letter {
  text-transform: uppercase;
}

li.nav-item a.active {
  color: var(--nav-active-color);
  background: var(--nav-active-background-color);
}

.special {
  text-transform: uppercase;
  color: #f63e00;
}

.nav-item:hover .nav-link:not(.active) .special {
  color: #fd6331;
}

.nav-item__icon,
.nav-item__text {
  transition: color 0.2s ease-out;
}

.btn-symbol {
  width: 18px;
  text-align: center;
  font-size: 18px;
}

.btn-symbol-offset {
  transform: translateY(-2px);
}

.ft {
  background-color: transparent;
  width: 100%;
}

#version {
  color: var(--light-gray);
  font-size: 10px;
  font-family: 'Roboto Mono', sans-serif;
  text-transform: capitalize;
  opacity: 0.8;
  text-align: end;
}

.side-menu {
  z-index: 2;
}
</style>
