<template>
  <button
    :class="['start-new-job-button', disabled ? 'disabled' : '']"
    :data-testid="testId"
    @click="disabled ? undefined : Clicked()"
  >
    <i :class="['start-icon far fa-chevron-double-right']"></i>
    <span class="ml-2">{{ label }}</span>
  </button>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

@Component
export default class StartNewJobButton extends Vue {
  @Prop({ default: '' }) label!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: undefined }) testId?: string;

  @Emit('clicked')
  Clicked() {}
}
</script>

<style lang="scss" scoped>
.start-new-job-button {
  font-size: 16px;
  color: var(--start-new-job-button-text);
  padding: 3px 13px;
  background: var(--start-new-job-button-background);
  border-radius: 6px;
  border: var(--start-new-job-button-border);
  padding-right: 32px;

  transform-style: preserve-3d;
  backface-visibility: hidden;

  &.disabled {
    opacity: 0.5;
    cursor: unset;
  }

  .start-icon {
    // opacity: 0;
    transform: translateX(-4px);
    transition: all 0.15s ease-in-out;
  }

  &:hover:not(.disabled) {
    color: var(--start-new-job-button-text-hover);

    .start-icon {
      // opacity: 1;
      transform: translateX(0px);
    }
  }

  i {
    color: var(--main-orange);
  }
}
</style>
