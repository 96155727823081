import { Point } from './Geometry';

export class CellRange {
  startPosition!: Point;
  endPosition!: Point;

  static FromPointCoordinates(fromX: number, fromY: number, toX: number, toY: number) {
    const result = new CellRange();

    result.startPosition = {
      x: fromX,
      y: fromY,
    };

    result.endPosition = {
      x: toX,
      y: toY,
    };

    return result;
  }

  static FromPoints(from: Point, to: Point) {
    const result = new CellRange();

    result.startPosition = {
      x: from.x,
      y: from.y,
    };

    result.endPosition = {
      x: to.x,
      y: to.y,
    };

    return result;
  }

  static DeepCopyFrom(cellRange: CellRange) {
    const result = new CellRange();

    result.startPosition = {
      x: cellRange.startPosition.x,
      y: cellRange.startPosition.y,
    };

    result.endPosition = {
      x: cellRange.endPosition.x,
      y: cellRange.endPosition.y,
    };

    return result;
  }

  private constructor() {}

  public get Width() {
    return this.endPosition.x - this.startPosition.x;
  }

  public get Height() {
    return this.endPosition.y - this.startPosition.y;
  }

  public SameAs(other: CellRange) {
    return (
      this.startPosition.x == other.startPosition.x &&
      this.startPosition.y == other.startPosition.y &&
      this.Width == other.Width &&
      this.Height == other.Height
    );
  }
}
