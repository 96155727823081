import { ErrorType } from '../Entities';
import { TelemetryType } from '../enums/TelemetryType';

//connection
export class RequestCreateConnection {
  printerId!: string;
  connectionTypeId!: string;
  clientId!: string;
}
export class RequestUpdateConenction {
  printerId!: string;
}
export class RequestReadConnectionTypes {}
export class RequestRawCommand {
  printerId!: string;
  command!: string;
}

//data
export class RequestReadTelemetryByPrinterId {
  printerId!: string;
}
export class RequestReadTelemetryForPrinterForTimeRange {
  printerId!: string;
  timeIntervalTop!: string;
  timeIntervalBottom!: string;
  intervalTicks!: number;
  from!: number | null;
  count!: number | null;
  types!: TelemetryType[];
}
export class RequestReadErrorsByPrinterForTimeRange {
  printerId!: string;
  timeIntervalTop!: string;
  timeIntervalBottom!: string;
  from!: number | null;
  count!: number | null;
  before!: string;
  pageSize!: number;
}
export class RequestReadErrorsByPrinterIdPaged {
  printerId!: string;
  dateFrom!: string;
  dateTo!: string;
  pageSize!: number;
  page!: number;
  errorTypes!: ErrorType[];
  onlyUnacknowledged!: boolean;
}
export class RequestAcknowledgeErrors {
  errorIds!: string[];
  printerId!: string;
}
export class RequestAcknowledgeAllErrorsForPrinter {
  printerId!: string;
}
export class RequestReadUnacknowledgedCountByPrinter {
  printerId!: string;
}
export class RequestReadByClientId {
  clientId!: string;
}
export class RequestReadPrinterModelById {
  id!: string;
}
export class RequestReadAllPrinterModels {}
export class RequestLogConfiguration {
  printerId!: string;
}

export class RequestGetLogs {
  printerId!: string;
  from!: Date | null;
  to!: Date | null;
}

export class RequestDownloadLogs {
  printerId!: string;
}
