import store from '@/store';
import { PaymentMethod } from '@/models/Entities';
import { Guid } from 'guid-typescript';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { UtilModule } from '../utilModule';

import * as ips from '@/util/api/ips';
import * as routes from '@/util/api/routes';
import * as hubActions from '@/util/api/hubActions';
import {
  RequestDeleteByIdPaymentMethod,
  RequestMakeDefaultPaymentMethod,
  RequestPrepareCreatePaymentMethod,
  RequestReadAllByAccountIdPaymentMethod,
} from '@/models/requests/RequestsBilling';
import { RequestStatus } from '@/models/enums/RequestStatus';
import {
  ResponsePaymentMethod,
  ResponsePaymentMethods,
  ResponsePrepareCreatePaymentMethod,
} from '@/models/responses/ResponseBilling';
import { TypeHelper } from '@/util/TypeHelper';
import { HubConnection } from '@microsoft/signalr';
import { LoginModule } from '../loginModule';

@Module({ dynamic: true, name: 'paymentMethod', store: store })
export default class paymentMethodModule extends VuexModule {
  private paymentMethods: PaymentMethod[] = [];

  get PaymentMethods(): PaymentMethod[] {
    return this.paymentMethods;
  }

  @Mutation
  AddPaymentMethod(val: PaymentMethod) {
    this.paymentMethods.push(val);
  }

  @Mutation
  RemovePaymentMethod(val: PaymentMethod) {
    this.paymentMethods.delete(val);
  }

  @Mutation
  UpdatePaymentMethod(val: PaymentMethod) {
    this.paymentMethods = this.paymentMethods.map(pm => {
      if (pm.id.toString() === val.id.toString()) {
        pm.isDefault = val.isDefault;
      }

      return pm;
    });
  }

  @Mutation
  ClearPaymentMethods() {
    this.paymentMethods = [];
  }

  @Action({ rawError: true })
  PaymentMethodSubscribe(hubConnection: HubConnection) {
    hubConnection.on(hubActions.RECEIVE_CREATE_PAYMENT_METHOD, async (res: ResponsePaymentMethod) => {
      const paymentMethod = TypeHelper.DeepCopyFrom(res, ResponsePaymentMethod).Map();

      this.AddPaymentMethod(paymentMethod);
    });
    hubConnection.on(hubActions.RECEIVE_DELETE_PAYMENT_METHOD, async (res: ResponsePaymentMethod) => {
      const paymentMethod = TypeHelper.DeepCopyFrom(res, ResponsePaymentMethod).Map();

      const found = this.paymentMethods.firstOrDefault(a => a.id.toString() == paymentMethod.id.toString());

      if (found !== null) {
        this.RemovePaymentMethod(found);
      }
    });
    hubConnection.on(hubActions.RECEIVE_UPDATE_PAYMENT_METHOD, async (res: ResponsePaymentMethod) => {
      const paymentMethod = TypeHelper.DeepCopyFrom(res, ResponsePaymentMethod).Map();

      this.UpdatePaymentMethod(paymentMethod);
    });
  }

  @Action({ rawError: true })
  async LoadPaymentMethods(accountId: Guid) {
    this.ClearPaymentMethods();

    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PAYMENT_METHOD,
      routes.UriPaymentMethod.READ_ALL_BY_ACCOUNT_ID,
    );

    const request = new RequestReadAllByAccountIdPaymentMethod();
    request.accountId = accountId.toString();

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = axiosResponse.data.response as ResponsePaymentMethods;
      const paymentMethods = TypeHelper.DeepCopyFrom(response, ResponsePaymentMethods).paymentMethods;

      for (const res of paymentMethods) {
        const paymentMethod = TypeHelper.DeepCopyFrom(res, ResponsePaymentMethod);
        this.AddPaymentMethod(paymentMethod.Map());
      }
    }
  }

  @Action({ rawError: true })
  async FetchPaymentIntentClientSecret(accountId: Guid) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PAYMENT_METHOD,
      routes.UriPaymentMethod.PREPARE_CREATE,
    );

    const request = new RequestPrepareCreatePaymentMethod();
    request.accountId = accountId.toString();

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result === RequestStatus.OK && axiosResponse != null) {
      const res = axiosResponse.data.response as ResponsePrepareCreatePaymentMethod;
      const response = TypeHelper.DeepCopyFrom(res, ResponsePrepareCreatePaymentMethod);

      return response.clientSecret;
    }
  }

  @Action({ rawError: true })
  async MakeDefault(id: Guid) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PAYMENT_METHOD,
      routes.UriPaymentMethod.MAKE_DEFAULT,
    );

    const request = new RequestMakeDefaultPaymentMethod();
    request.paymentMethodId = id.toString();

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = axiosResponse.data.response as ResponsePaymentMethod;
      const paymentMethod = TypeHelper.DeepCopyFrom(response, ResponsePaymentMethod);

      this.UpdatePaymentMethod(paymentMethod);

      return true;
    }

    return false;
  }

  @Action({ rawError: true })
  async Delete(id: Guid) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PAYMENT_METHOD,
      routes.UriPaymentMethod.DELETE,
    );

    const request = new RequestDeleteByIdPaymentMethod();
    request.id = id.toString();

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = axiosResponse.data.response as ResponsePaymentMethod;
      TypeHelper.DeepCopyFrom(response, ResponsePaymentMethod);

      return true;
    }

    return false;
  }

  @Action({ rawError: true })
  async AddToPaymentMethodGroup() {
    if (LoginModule.BillingConnection == null) return;
    await LoginModule.BillingConnection.send(hubActions.ADD_TO_PAYMENT_METHOD_GROUP);
    LoginModule.AddBillingConnectionGroup({
      func: this.AddToPaymentMethodGroup,
    });
  }

  @Action({ rawError: true })
  async DeleteFromPaymentMethodGroup() {
    if (LoginModule.BillingConnection == null) return;
    await LoginModule.BillingConnection.send(hubActions.DELETE_FROM_PAYMENT_METHOD_GROUP);
    LoginModule.DeleteBillingConnectionGroup({
      func: this.AddToPaymentMethodGroup,
    });
  }
}

export const PaymentMethodModule = getModule(paymentMethodModule);
