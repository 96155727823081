<template>
  <div :data-testid="getTestId('container')" :class="['dialog-container p-4', closing ? 'closing' : '']">
    <div class="dialog-dismiss-bg" @click="OnDismissed" />

    <div :data-testid="getTestId('content')" :class="['dialog p-3', closing ? 'closing' : '']">
      <div class="dialog-title-container mb-3 py-1">
        <span>{{ headerText }}</span>

        <div class="i" style="min-width: fit-content" :data-testid="getTestId('closeButton')" @click="OnClosed">
          <i class="fas fa-times" />
        </div>
      </div>

      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { createTestId } from '@/util/tests';

@Component({
  name: 'aura-dialog-wrapper',
})
export default class AuraDialogWrapper extends Vue {
  @Prop() headerText!: string;
  @Prop({ default: undefined }) testId?: string;

  private closing: boolean = false;

  @Emit('dismissed')
  private OnDismissed() {
    if (this.$listeners.dismissed == undefined) return;
    this.CloseDialog();
  }

  @Emit('closed')
  private OnClosed() {
    if (this.$listeners.closed == undefined) return;
    this.CloseDialog();
  }

  public CloseDialog() {
    this.closing = true;
  }

  private getTestId(elementId: string) {
    return createTestId(this.testId, elementId);
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  background: var(--dialog-background);
  border-radius: 3px;
  overflow: auto;
  z-index: 2;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.35);
  animation: scaleIn 150ms ease-in;
  animation-fill-mode: forwards;

  &.closing {
    animation: scaleOut 150ms ease-in;
  }
}

.dialog-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--main-text);

  .i {
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  }
}

.dialog-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1061;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.35);

  color: var(--editor-field-value);

  animation: fadeIn 150ms ease-in;
  animation-fill-mode: forwards;

  &.closing {
    animation: fadeOut 150ms ease-in;
  }
}

.dialog-dismiss-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
</style>
