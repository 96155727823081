<template>
  <div class="progress-bar">
    <div class="value" :style="{ width: GetProgressValue }"></div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'progress-bar',
  components: {},
})
export default class ProgressBar extends Vue {
  @Prop() minValue!: number;
  @Prop() maxValue!: number;
  @Prop() value!: number;

  // 0%-100%
  private get GetProgressValue(): string {
    const percentDone = this.value / this.maxValue;

    return Math.round(percentDone * 100) + '%';
  }
}
</script>

<style lang="scss">
.progress-bar {
  border: 1px solid var(--main-orange);
  height: 12px;
  background: none;
  border-radius: 4px;

  .value {
    background: var(--main-orange);
    height: 100%;
    transition: width 0.25s ease-in;
  }
}
</style>
