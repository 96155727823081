<template>
  <action-card
    class="machine-telemtry"
    test-id="machineTelemetry"
    headerText="Telemetry"
    :closable="closable"
    :collapsible="false"
    :headerTopMargin="10"
    :fillContentVertically="true"
    :overflow="false"
    :headerTextVerticalOffset="1"
    :additionalBottomPadding="false"
    :headerHeight="'auto'"
    :headerAlignment="'flex-start'"
    @closed="Closed"
  >
    <template slot="headerCenter">
      <div class="machine-telemtry-header-controls">
        <div class="toggles-container mx-3">
          <i
            v-tooltip="{ content: 'Toggle filters panel', offset: 5 }"
            :class="['far fa-filter', showFilters ? 'active' : '']"
            :style="{ 'font-size': '13px' }"
            data-testid="machineTelemetry.toggleShowFiltersPanelButton"
            @click="ToggleShowFiltersPanel"
          ></i>
          <i
            v-tooltip="{ content: 'Toggle plot legends', offset: 4 }"
            :class="['far fa-chart-bar', showLegend ? 'active' : '']"
            data-testid="machineTelemetry.toggleShowLegendsPanelButton"
            @click="ToggleShowLedengPanel"
          ></i>
        </div>

        <TimeRangeSelector test-id="machineTelemetry" :timeRange.sync="TelemetryTimeRange" />
      </div>
    </template>

    <div v-if="showFilters" :class="['telemetry-type-filters', isLoadingTelemetry ? 'disabled' : '']">
      <dark-button
        v-for="type in availableTelemetryTypes"
        :key="type"
        :class="['telemetry-type-switch mb-3', IsActiveTelemetryType(type) ? 'active' : '']"
        :label="TranslateTelemetryType(type)"
        test-id="machineTelemetry.changeTelemetryTypeButton"
        @clicked="TelemetryTypeClicked(type)"
      ></dark-button>
    </div>

    <div class="telemetry-plot mb-2 thin-scroll">
      <div ref="telemetryGrapthContainer" class="telemetry-graph-container thin-scroll">
        <UplotVue
          :class="[isLoadingTelemetry ? 'disabled' : '']"
          :options="uPlotOptions"
          :data="uPlotData"
          :traget="telemetryGrapthContainer"
        />

        <div :class="['spinner', isLoadingTelemetry ? '' : 'hidden']"></div>
      </div>

      <div v-if="showLegend" class="legend ml-2 thin-scroll" :class="[isLoadingTelemetry ? 'disabled' : '']">
        <div
          v-for="line of TelemetryLines"
          :key="line.key"
          :class="['legend-item', IsHiddenTelemetryKey(line.key) ? 'hidden' : '']"
        >
          <div
            class="color"
            :style="GetLegendItemColorStyle(line)"
            data-testid="machineTelemetry.toggleTelemetryKeyVisibility"
            @click="ToggleTelemetryKeyVisibility(line.key)"
          ></div>
          <div class="caption">{{ line.readableName }}</div>
        </div>
      </div>

      <div v-if="loadedNoData && !isLoadingTelemetry" class="no-data-container thin-scroll">
        <span class="mb-2">No data for this period</span>
        <span :style="{ 'font-size': '24px' }">¯\_(ツ)_/¯</span>
      </div>
    </div>

    <div ref="cursorInfoContainer" class="cursor-info-container">
      <div class="cursor-info" :style="CursorInfoStyle">
        <div class="date">
          {{ CurrentPointDate }}
        </div>
        <div class="points">
          <div v-for="pt of CursorPoints" :key="pt.line.key" class="point">
            <div class="color" :style="GetLegendItemColorStyle(pt.line)"></div>
            <div class="name">{{ pt.line.readableName }}</div>
            <div class="value">{{ pt.value.toFixed(1) }}</div>
            <div class="unit">{{ UnitFor(pt.line.viewModel) }}</div>
          </div>
        </div>
      </div>
    </div>
  </action-card>
</template>

<script lang="ts">
import { Printer } from '@/models/Entities';
import { Component, Prop, Vue, Watch, Emit, Ref } from 'vue-property-decorator';
import ActionCard from '../presentation/ActionCard.vue';
import { GetDefaultTimeRange, TimeRange, TimeRangeType } from '@/models/util/TimeModels';
import { ConnectorModule } from '@/store/modules/connectorModule';
import TimeRangeSelector from '@/components/inputs/TimeRangeSelector.vue';
import { Guid } from 'guid-typescript';
import { TypeHelper } from '@/util/TypeHelper';
import {
  ResponseTelemetryFiltered,
  ResponseTelemetryItemFiltered,
  TelemetryViewModel,
} from '@/models/responses/ResponseConnector';
import { TelemetryType } from '@/models/enums/TelemetryType';
import DarkButton from '../buttons/DarkButton.vue';
import { debounce, DebouncedFunc } from 'lodash';
import moment from 'moment';
import ComponentHelper from '@/util/ComponentHelper';
import dateFormat from 'dateformat';
import uPlot, { Axis, Series } from 'uplot';
import UplotVue from 'uplot-vue';
import { Options, AlignedData } from 'uplot';
import 'uplot/dist/uPlot.min.css';
import * as convert from 'color-convert';
import { GlobalDataModule } from '@/store/modules/globalDataModule';

export interface TelemetryLine {
  viewModel: TelemetryViewModel;
  points: number[];
  readableName: string;
  color: string;
  series?: Series;
  key: string;
}

export interface TelemetryLines {
  [key: string]: TelemetryLine;
}

export interface CursorPoint {
  line: TelemetryLine;
  time: Date;
  value: number;
  indx: number;
}

@Component({
  components: {
    ActionCard: ActionCard,
    TimeRangeSelector: TimeRangeSelector,
    DarkButton: DarkButton,
    UplotVue: UplotVue,
  },
})
export default class MachineTelemetry extends Vue {
  @Prop() printer!: Printer;
  @Prop({ default: false }) closable!: boolean;

  @Ref('telemetryGrapthContainer') telemetryGrapthContainer?: HTMLElement;
  @Ref('cursorInfoContainer') cursorInfoContainer!: HTMLElement;

  private liveModeTelemetryLock = false;

  private telemetryGraphContainerWidth: number = 0;
  private get TelemetryGraphContainerWidth() {
    return this.telemetryGraphContainerWidth;
  }

  private telemetryGraphContainerHeight: number = 0;
  private get TelemetryGraphContainerHeight() {
    return this.telemetryGraphContainerHeight;
  }

  //#region WATCHERS
  @Watch('printer', { immediate: true })
  async OnPrinterChanged(newValue: Printer, oldValue?: Printer) {
    if (oldValue != undefined) {
      if (oldValue.Id.toString() != newValue.Id.toString()) {
        this.debounceFetchTelemetry.cancel();
        this.debounceFetchDetalizedTelemetry.cancel();
        await this.UnsubscribeFromTelemetry(oldValue.Id);
        // await ConnectorModule.UnsubscribeFromTelemetry(oldValue.Id);
      }
    }

    if (this.IsLive) {
      this.liveModeTelemetryLock = true;
      await this.SubscribeToTelemetry(newValue.Id);
      // await ConnectorModule.SubscribeToTelemetry(newValue.Id);
    }

    if (
      this.detalizedTelemetries.length == 0 ||
      (oldValue != undefined && oldValue.Id.toString() != newValue.Id.toString())
    ) {
      await this.ClearTelemetries();
      await this.LoadTelemetry();
    }
  }

  @Watch('telemetryTimeRange')
  async OnTelemetryTimeRangeChanged(newValue: TimeRange, oldValue: TimeRange) {
    if (oldValue.type == TimeRangeType.Live && newValue.type != TimeRangeType.Live) {
      await this.UnsubscribeFromTelemetry(this.printer.Id);
    } else if (newValue.type == TimeRangeType.Live && oldValue.type != TimeRangeType.Live) {
      this.liveModeTelemetryLock = true;
      await this.SubscribeToTelemetry(this.printer.Id);
    }

    if (!this.telemetryTimeRangeInitialized) {
      this.telemetryTimeRangeInitialized = true;
      return;
    }

    this.debounceFetchTelemetry(newValue);
  }
  //#endregion

  //#region STATE
  // private isLoading = false;
  private isLoadingTelemetry = false;
  private loadedNoData = false;
  private telemetryTimeRangeInitialized = false;

  private showLegend = true;
  private showFilters = true;

  //#region UPLOT
  private availableLineColors: string[] = [];

  private hiddenLineKeys: string[] = [];

  uPlotData!: AlignedData;
  uPlotOptions!: Options;
  uPlot!: uPlot | null;

  private GlobalMouseX: number = 0;
  private GlobalMouseY: number = 0;

  private ShowCursor: boolean = false;
  private CursorPoints: CursorPoint[] = [];

  private get CurrentPointDate() {
    if (this.CursorPoints.length == 0) return 'N/A';

    return dateFormat(this.CursorPoints[0].time, 'yyyy-mm-dd HH:MM:ss');
  }

  SetUPlotOptions() {
    // console.log(this.minXDetalized, this.maxXDetalized);

    // insert gaps where adjacent datapoints with values are > delta
    // todo: does not accumulate prior gap when adjacent so can make [1,2],[2,3] instead of [1,3]
    // todo: does not handle undefined-filled datasets (e.g. from unaligned joining)
    const gapRefined: Series.GapsRefiner = (u, sidx, idx0, idx1, nullGaps) => {
      const xData = u.data[0];

      const yData = u.data[sidx];

      let addlGaps: Series.Gaps = [];
      const isNum = Number.isFinite;
      const minDelta = 2;

      // console.log(this.detalizedStartIndex, this.detalizedEndIndex);
      // console.log(this.regularIntervalTicks, this.detalizedIntervalTicks);

      for (let i = idx0 + 1; i <= idx1; i++) {
        // let xVal = xData[i];
        // let yVal = yData[i];

        let delta = this.regularIntervalTicks;

        if (i > this.detalizedStartIndex && i < this.detalizedEndIndex) {
          delta = this.detalizedIntervalTicks;
        }

        // right now the delta is in ticks (10k ms per tick)
        // we need to convert it back to seconds
        delta /= 10000;
        delta /= 1000;

        if (delta < minDelta) {
          delta = minDelta;
        }

        delta *= 5;

        if (i == this.detalizedStartIndex || i == this.detalizedEndIndex) {
          delta /= 5;
        }

        if (isNum(yData[i]) && isNum(yData[i - 1])) {
          // in seconds
          const currDelta = xData[i] - xData[i - 1];

          // console.log(currDelta);

          if (currDelta > delta) {
            uPlot.addGap(
              addlGaps,
              Math.round(u.valToPos(xData[i - 1], 'x', true)),
              Math.round(u.valToPos(xData[i], 'x', true)),
            );
          }
        }
      }

      nullGaps.push(...addlGaps);
      nullGaps.sort((a, b) => a[0] - b[0]);

      return nullGaps;
    };

    const series: Series[] = [
      {
        label: 'Date',
        gaps: gapRefined,
      },
    ];

    const axes: Axis[] = [
      {
        scale: 'x',
        labelFont: '12px Roboto',
        font: '12px Roboto',
        stroke: 'rgba(220, 220, 220, 1)',
        grid: {
          stroke: 'rgba(130, 130, 130, 0.45)',
          width: 0.5,
        },
        incrs: [
          // minute divisors (# of secs)
          1,
          5,
          10,
          15,
          30,
          // hour divisors
          60,
          60 * 5,
          60 * 10,
          60 * 15,
          60 * 30,
          60 * 45,
          60 * 60,
          2 * 60 * 60,
          4 * 60 * 60,
          // day divisors
          3600,
          3600 * 2,
          3600 * 8,
          3600 * 24,
          3600 * 48,
          // ...
          3600 * 256,
          3600 * 512,
          3600 * 1024,
          3600 * 3600,
        ],
        values: [
          // tick incr          default           year                             month    day                        hour     min                sec       mode
          [3600 * 24 * 365, '{YYYY}', null, null, null, null, null, null, 1],
          [3600 * 24 * 28, '{MMM}', null, null, null, null, null, null, 1],
          [3600 * 24, '{MMM} {DD}', null, null, null, null, null, null, 1],
          [3600, '{HH}:{mm}', null, null, null, null, null, null, 1],
          [60, '{HH}:{mm}', null, null, null, null, null, null, 1],
          [1, '{HH}:{mm}:{ss}', null, null, null, null, null, null, 1],
          [0.001, '{HH}:{mm}:{ss}.{fff}', null, null, null, null, null, null, 1],
        ],
        gap: 0,
        ticks: {
          stroke: 'rgba(130, 130, 130, 0.45)',
          width: 0.5,
        },
        size: (self: uPlot, values: string[], axisIdx: number) => {
          let axis = self.axes[axisIdx];

          let axisSize = (axis.ticks?.size ?? 0) + (axis.gap ?? 0);

          let longestVal = (values ?? []).reduce((acc, val) => (val.length > acc.length ? val : acc), '');

          if (longestVal != '') {
            self.ctx.font = axis.font![0] ?? 'arial';
            var measure = self.ctx.measureText(longestVal);

            if (axisIdx == 0) {
              axisSize += measure.actualBoundingBoxAscent + measure.actualBoundingBoxDescent;
            } else {
              axisSize += measure.width / devicePixelRatio;
            }
          }

          return axisSize;
        },
      },
      {
        scale: 'y',
        labelFont: '12px Roboto',
        font: '12px Roboto',
        stroke: 'rgba(220, 220, 220, 1)',
        grid: {
          stroke: 'rgba(130, 130, 130, 0.45)',
          width: 0.5,
        },

        gap: 0,
        ticks: {
          stroke: 'rgba(130, 130, 130, 0.45)',
          width: 0.5,
        },
        size: (self: uPlot, values: string[], axisIdx: number) => {
          let axis = self.axes[axisIdx];

          let axisSize = (axis.ticks?.size ?? 10) + (axis.gap ?? 0);

          let longestVal = (values ?? []).reduce((acc, val) => (val.length > acc.length ? val : acc), '');

          if (longestVal != '') {
            self.ctx.font = axis.font![0] ?? 'arial';
            var measure = self.ctx.measureText(longestVal);

            if (axisIdx == 0) {
              axisSize += measure.actualBoundingBoxAscent + measure.actualBoundingBoxDescent;
            } else {
              axisSize += measure.width / devicePixelRatio;
            }
          }

          return axisSize;
        },
      },
    ];

    const isDetalized = this.minXDetalized != undefined && this.maxXDetalized != undefined;

    for (const lineKey in this.telemetryLines) {
      const line = this.telemetryLines[lineKey];
      // Todo: add separate Y axis for different units here
      this.UnitFor(line.viewModel);
      const clr = convert.hex.rgb(line.color);
      const toAdd: Series = {
        label: line.readableName + '|' + lineKey,
        stroke: line.color,
        fill: `rgba(${clr[0]}, ${clr[1]}, ${clr[2]}, 0.1)`,
        width: 2,
        show: !this.IsHiddenTelemetryKey(line.key),
        paths: uPlot.paths.spline!(),
        scale: 'y',
        gaps: gapRefined,
      };

      line.series = toAdd;
      series.push(toAdd);
    }

    this.uPlotOptions = {
      series: series,
      width: this.TelemetryGraphContainerWidth,
      height: this.TelemetryGraphContainerHeight,
      legend: {
        show: false,
      },
      select: {
        show: !this.IsLive,
        left: 0,
        top: 0,
        width: 0,
        height: 0,
      },
      cursor: {
        drag: {
          setScale: !this.IsLive,
        },
      },
      plugins: [
        {
          hooks: {
            ready: u => {
              // console.log("ready");

              if (this.minXDetalized != undefined && this.maxXDetalized != undefined) {
                u.setScale('x', {
                  min: this.minXDetalized,
                  max: this.maxXDetalized,
                });
              }

              let xMin = 0;
              let xMax = 0;

              if (this.detalizedTelemetries.length > 0) {
                xMin = this.detalizedTelemetries[0].time.getTime() / 1000;
                xMax = this.detalizedTelemetries[this.detalizedTelemetries.length - 1].time.getTime() / 1000;
              }
              const yMin = u.scales.y.min!;
              const yMax = u.scales.y.max!;

              function clamp(nRange: number, nMin: number, nMax: number, fRange: number, fMin: number, fMax: number) {
                if (nRange > fRange) {
                  nMin = fMin;
                  nMax = fMax;
                } else if (nMin < fMin) {
                  nMin = fMin;
                  nMax = fMin + nRange;
                } else if (nMax > fMax) {
                  nMax = fMax;
                  nMin = fMax - nRange;
                }

                return [nMin, nMax];
              }

              const xRange = xMax - xMin;
              const yRange = yMax - yMin;

              let over = u.over;
              let rect = over.getBoundingClientRect();

              // wheel drag pan
              over.addEventListener('mousedown', e => {
                // console.log("down");

                if (e.button == 1 && !this.IsLive) {
                  //	plot.style.cursor = "move";
                  e.preventDefault();

                  let left0 = e.clientX;
                  //	let top0 = e.clientY;

                  let scXMin0 = u.scales.x.min!;
                  let scXMax0 = u.scales.x.max!;

                  let xUnitsPerPx = u.posToVal(1, 'x') - u.posToVal(0, 'x');

                  const onmove = (e: MouseEvent) => {
                    e.preventDefault();

                    let left1 = e.clientX;
                    //	let top1 = e.clientY;

                    let dx = xUnitsPerPx * (left1 - left0);

                    let newMinX = scXMin0 - dx;
                    let newMaxX = scXMax0 - dx;

                    [newMinX, newMaxX] = clamp(newMaxX - newMinX, newMinX, newMaxX, xRange, xMin, xMax);

                    u.batch(() => {
                      u.setScale('x', {
                        min: newMinX,
                        max: newMaxX,
                      });

                      this.minXDetalized = newMinX;
                      this.maxXDetalized = newMaxX;

                      const fromVal = newMinX * 1000;
                      const toVal = newMaxX * 1000;

                      const fromDate = new Date(fromVal);
                      const toDate = new Date(toVal);

                      this.debounceFetchDetalizedTelemetry({
                        dateFrom: fromDate,
                        dateTo: toDate,
                      });
                    });
                  };

                  const onup = () => {
                    document.removeEventListener('mousemove', onmove);
                    document.removeEventListener('mouseup', onup);
                  };

                  document.addEventListener('mousemove', onmove);
                  document.addEventListener('mouseup', onup);
                }
              });

              // wheel scroll zoom
              over.addEventListener('wheel', e => {
                e.preventDefault();

                this.isZooming = true;

                if (this.IsLive) {
                  return;
                }

                let factor = 0.95;

                if (e.altKey) {
                  factor = 0.75;
                }

                let left = u.cursor.left!;
                let top = u.cursor.top!;

                let leftPct = left / rect.width;
                let btmPct = 1 - top / rect.height;
                let xVal = u.posToVal(left, 'x');
                let yVal = u.posToVal(top, 'y');
                let oxRange = u.scales.x.max! - u.scales.x.min!;
                let oyRange = u.scales.y.max! - u.scales.y.min!;

                let nxRange = e.deltaY < 0 ? oxRange * factor : oxRange / factor;
                let nxMin = xVal - leftPct * nxRange;
                let nxMax = nxMin + nxRange;
                [nxMin, nxMax] = clamp(nxRange, nxMin, nxMax, xRange, xMin, xMax);

                let nyRange = e.deltaY < 0 ? oyRange * factor : oyRange / factor;
                let nyMin = yVal - btmPct * nyRange;
                let nyMax = nyMin + nyRange;
                [nyMin, nyMax] = clamp(nyRange, nyMin, nyMax, yRange, yMin, yMax);

                u.batch(() => {
                  u.setScale('x', {
                    min: nxMin,
                    max: nxMax,
                  });

                  this.minXDetalized = nxMin;
                  this.maxXDetalized = nxMax;

                  const fromVal = nxMin * 1000;
                  const toVal = nxMax * 1000;

                  const fromDate = new Date(fromVal);
                  const toDate = new Date(toVal);

                  this.debounceFetchDetalizedTelemetry({
                    dateFrom: fromDate,
                    dateTo: toDate,
                  });

                  this.isZooming = false;

                  // Uncomment this is we want to keep Y axis as it was
                  // u.setScale("y", {
                  //   min: nyMin,
                  //   max: nyMax,
                  // });
                });
              });
            },
          },
        },
      ],
      padding: [0, 0, 0, 0],
      hooks: {
        init: [
          plot => {
            this.uPlot = plot;
          },
        ],
        setSelect: [
          plot => {
            const nxMin = plot.posToVal(plot.select.left, 'x');
            const nxMax = plot.posToVal(plot.select.left + plot.select.width, 'x');

            this.minXDetalized = nxMin;
            this.maxXDetalized = nxMax;

            const fromVal = nxMin * 1000;
            const toVal = nxMax * 1000;

            const fromDate = new Date(fromVal);
            const toDate = new Date(toVal);

            this.debounceFetchDetalizedTelemetry({
              dateFrom: fromDate,
              dateTo: toDate,
            });
          },
        ],
        setLegend: [
          plot => {
            if (plot.legend.idx != null) {
              this.CursorPoints = [];

              this.ShowCursor = true;

              const index = plot.legend.idx;

              const date = new Date(plot.data[0][index] * 1000);

              for (let i = 1; i < plot.series.length; ++i) {
                const series = plot.series[i];
                const lineKey = series.label!.split('|')[1];
                const line = this.telemetryLines[lineKey];

                if (this.IsHiddenTelemetryKey(line.key)) {
                  continue;
                }

                this.CursorPoints.push({
                  line: line,
                  time: date,
                  value: plot.data[i][index]!,
                  indx: index,
                });
              }
            } else {
              this.ShowCursor = false;
              this.CursorPoints = [];
            }
          },
        ],
      },
      axes: axes,
      scales: {
        x: {
          auto: !isDetalized,
          range:
            isDetalized && !this.isZooming
              ? () => {
                  return [this.minXDetalized!, this.maxXDetalized!];
                }
              : undefined,
        },
        y: {},
      },
    };

    this.$forceUpdate();

    return this.uPlotOptions;
  }

  private resizeObserver!: ResizeObserver;

  private telemetryLines: TelemetryLines = {};
  private get TelemetryLines() {
    return this.telemetryLines;
  }
  private TelemetryLinesCount() {
    return Object.keys(this.telemetryLines).length;
  }

  private get CursorInfoStyle() {
    return {
      left: this.GlobalMouseX + 14 + 'px',
      top: this.GlobalMouseY + 14 + 'px',
      display: !this.ShowCursor ? 'none' : '',
    };
  }
  //#endregion

  //#region TELEMETRY
  private debounceFetchTelemetry!: DebouncedFunc<(range: TimeRange) => Promise<void>>;

  private debounceFetchDetalizedTelemetry!: DebouncedFunc<(range: TimeRange) => Promise<void>>;

  private get PrinterModel() {
    return ConnectorModule.PrinterModels.firstOrDefault(a => a.Id.toString() == this.printer.PrinterModelId.toString());
  }

  private availableTelemetryTypes: TelemetryType[] = [];

  private selectedTelemetryTypes: TelemetryType[] = [
    TelemetryType.BuildPlateTemperature,
    TelemetryType.ChamberTemperature,
  ];
  private telemetryTimeRange: TimeRange = GetDefaultTimeRange(TimeRangeType.Live);
  private actualTimeRange: TimeRange = GetDefaultTimeRange(TimeRangeType.Live);
  private minXDetalized?: number;
  private maxXDetalized?: number;
  private isZooming = false;

  private get TelemetryTimeRange() {
    return this.telemetryTimeRange;
  }

  private get IsLive() {
    return this.telemetryTimeRange.type == TimeRangeType.Live;
  }

  private set TelemetryTimeRange(newValue: TimeRange) {
    if (newValue.type == TimeRangeType.Live) {
      // console.log("Set telemetry to 5 minute range in setter");
      newValue.dateFrom = moment(new Date()).subtract(5, 'minutes').toDate();
      newValue.dateTo = new Date();
    }
    this.telemetryTimeRange = newValue;
    this.actualTimeRange = {
      dateFrom: this.telemetryTimeRange.dateFrom,
      dateTo: this.telemetryTimeRange.dateTo,
    };
  }

  private telemetries: ResponseTelemetryItemFiltered[] = [];
  private detalizedTelemetries: ResponseTelemetryItemFiltered[] = [];

  private detalizedStartIndex = -1;
  private detalizedEndIndex = -1;

  private regularIntervalTicks = 0;
  private detalizedIntervalTicks = 0;

  //#endregion
  //#endregion

  //#region LOGIC
  //#region TELEMETRY
  private async LoadDetalizedTelemetry(range: TimeRange) {
    this.isLoadingTelemetry = true;

    const fullRange =
      this.detalizedTelemetries[this.detalizedTelemetries.length - 1].time.getTime() -
      this.detalizedTelemetries[0].time.getTime();
    const newRange = range.dateTo.getTime() - range.dateFrom.getTime();

    this.detalizedTelemetries = [];
    this.actualTimeRange = range;

    let timeRangeToLoad = this.actualTimeRange;

    if (newRange / fullRange > 0.85) {
      timeRangeToLoad = this.telemetryTimeRange;
    }

    this.detalizedIntervalTicks = Math.round(
      (TypeHelper.DateToTicks(timeRangeToLoad.dateTo) - TypeHelper.DateToTicks(timeRangeToLoad.dateFrom)) / 1000,
    );

    const res = await ConnectorModule.ReadTelemetryByPrinterDate([
      this.printer.Id,
      timeRangeToLoad.dateFrom,
      timeRangeToLoad.dateTo,
      this.detalizedIntervalTicks,
      this.selectedTelemetryTypes,
    ]);

    const before: ResponseTelemetryItemFiltered[] = [];
    const detalized: ResponseTelemetryItemFiltered[] = [];
    const after: ResponseTelemetryItemFiltered[] = [];

    if (res != null) {
      for (const tel of res.items) {
        detalized.push(tel);
      }
    }

    for (const tel of this.telemetries) {
      if (tel.time <= timeRangeToLoad.dateFrom) {
        before.push(tel);
      } else if (tel.time >= timeRangeToLoad.dateTo) {
        after.push(tel);
      }
    }

    this.detalizedTelemetries = [before, detalized, after].flatMap(a => a);
    this.detalizedStartIndex = before.length;
    this.detalizedEndIndex = before.length + detalized.length;

    this.loadedNoData = this.telemetries.length < 2;

    this.uPlotData = this.CalculateUPlotData();
    this.TelemetryGrapthContainerResized();

    this.isLoadingTelemetry = false;
  }

  private async LoadTelemetry() {
    this.isLoadingTelemetry = true;

    this.minXDetalized = undefined;
    this.maxXDetalized = undefined;

    this.regularIntervalTicks = Math.round(
      (TypeHelper.DateToTicks(this.TelemetryTimeRange.dateTo) -
        TypeHelper.DateToTicks(this.TelemetryTimeRange.dateFrom)) /
        1000,
    );
    this.detalizedIntervalTicks = this.regularIntervalTicks;

    // console.log("Loading telemetry....");

    const res = await ConnectorModule.ReadTelemetryByPrinterDate([
      this.printer.Id,
      this.TelemetryTimeRange.dateFrom,
      this.TelemetryTimeRange.dateTo,
      this.regularIntervalTicks,
      this.selectedTelemetryTypes,
    ]);

    if (res != null) {
      for (const tel of res.items) {
        this.telemetries.push(tel);
        this.detalizedTelemetries.push(tel);
      }
    }

    this.loadedNoData = this.telemetries.length < 2;

    this.uPlotData = this.CalculateUPlotData();
    this.TelemetryGrapthContainerResized();

    // console.log("Loaded telemetry");

    if (this.IsLive) {
      this.liveModeTelemetryLock = false;
    }

    this.isLoadingTelemetry = false;
  }

  ClearTelemetries() {
    this.telemetries = [];
    this.detalizedTelemetries = [];
  }

  TelemetriesReceived(tel: ResponseTelemetryFiltered) {
    if (this.isLoadingTelemetry || this.liveModeTelemetryLock || !this.IsLive) {
      return;
    }

    // console.log("Received telemetry...");

    let needsOptionsReset = false;
    if (this.detalizedTelemetries.length == 0) {
      needsOptionsReset = true;
    }

    let maxTime = this.TelemetryTimeRange.dateTo;

    if (this.selectedTelemetryTypes.length == 0) {
      return;
    }

    for (const item of tel.items) {
      if (!this.IsActiveTelemetryType(TelemetryType.BuildPlateTemperature)) {
        item.buildPlateTemperature = null;
      }
      if (!this.IsActiveTelemetryType(TelemetryType.ChamberTemperature)) {
        item.chamberTemperature = null;
      }
      if (!this.IsActiveTelemetryType(TelemetryType.PrintHeadTemperature)) {
        item.printHeadTemperatures = null;
      }
      if (!this.IsActiveTelemetryType(TelemetryType.FanSpeed)) {
        item.fanSpeeds = null;
      }
      if (!this.IsActiveTelemetryType(TelemetryType.MaterialUsed)) {
        item.materialUsed = null;
      }
      if (!this.IsActiveTelemetryType(TelemetryType.PrintHeadPosition)) {
        item.printHeadPosition = null;
      }

      this.telemetries.push(item);
      this.detalizedTelemetries.push(item);

      if (item.time > maxTime) {
        maxTime = item.time;
      }
    }

    let minTime = moment(maxTime).subtract(5, 'minutes').toDate();

    const toDelete: ResponseTelemetryItemFiltered[] = [];
    for (const item of this.telemetries) {
      if (item.time < minTime) {
        toDelete.push(item);
        continue;
      }

      if (item.time >= minTime) {
        minTime = item.time;
        break;
      }
    }

    // Todo: this is the only way we can prevent racing conditions for now :(
    if (this.isLoadingTelemetry || this.liveModeTelemetryLock || !this.IsLive) {
      return;
    }

    for (const item of toDelete) {
      this.telemetries.delete(item);
      this.detalizedTelemetries.delete(item);
    }

    this.loadedNoData = this.telemetries.length < 2;

    this.uPlotData = this.CalculateUPlotData();

    if (maxTime > this.TelemetryTimeRange.dateTo) {
      this.telemetryTimeRange.dateFrom = minTime;
      this.telemetryTimeRange.dateTo = maxTime;
    }

    if (needsOptionsReset) {
      this.SetUPlotOptions();
    }

    this.$forceUpdate();
  }

  async SubscribeToTelemetry(printerId: Guid) {
    await ConnectorModule.SubscribeToTelemetry(printerId);

    if (!ConnectorModule.OnTelemetryReceived.has(this.TelemetriesReceived)) {
      ConnectorModule.OnTelemetryReceived.subscribe(this.TelemetriesReceived);
    }

    // console.log("Subscribed to telemetry");
  }

  async UnsubscribeFromTelemetry(printerId: Guid) {
    await ConnectorModule.UnsubscribeFromTelemetry(printerId);

    if (ConnectorModule.OnTelemetryReceived.has(this.TelemetriesReceived)) {
      ConnectorModule.OnTelemetryReceived.unsubscribe(this.TelemetriesReceived);
    }

    // console.log("Unsubscribed from telemetry");
  }
  //#endregion

  //#region TELEMETRY TYPES
  InitializeAvailableTelemetryTypes() {
    this.availableTelemetryTypes = [];
    this.availableTelemetryTypes.push(TelemetryType.BuildPlateTemperature);
    this.availableTelemetryTypes.push(TelemetryType.ChamberTemperature);
    this.availableTelemetryTypes.push(TelemetryType.PrintHeadTemperature);
    this.availableTelemetryTypes.push(TelemetryType.FanSpeed);
    this.availableTelemetryTypes.push(TelemetryType.MaterialUsed);
    this.availableTelemetryTypes.push(TelemetryType.PrintHeadPosition);
  }

  private IsActiveTelemetryType(type: TelemetryType) {
    return this.selectedTelemetryTypes.firstOrDefault(a => a == type) != null;
  }

  async ToggleShowFiltersPanel() {
    this.showFilters = !this.showFilters;

    await GlobalDataModule.ChangeMachineTelemetryViewStateShowFiltersPanel(this.showFilters);
  }

  async ToggleShowLedengPanel() {
    this.showLegend = !this.showLegend;

    await GlobalDataModule.ChangeMachineTelemetryViewStateShowLegendPanel(this.showLegend);
  }

  async TelemetryTypeClicked(type: TelemetryType) {
    const found = this.selectedTelemetryTypes.firstOrDefault(a => a == type);

    if (found == null) {
      this.selectedTelemetryTypes.push(type);
    } else {
      this.selectedTelemetryTypes.delete(found);
    }

    await GlobalDataModule.ChangeMachineTelemetryViewStateTelemetryTypes(this.selectedTelemetryTypes);

    this.debounceFetchTelemetry(this.telemetryTimeRange);
  }

  async TelemetryGraphTimeRangeChanged(newRange: TimeRange) {
    this.debounceFetchTelemetry(newRange);
  }
  //#endregion

  //#region UPLOT
  TelemetryGrapthContainerResized() {
    this.telemetryGraphContainerWidth = this.telemetryGrapthContainer!.clientWidth;
    this.telemetryGraphContainerHeight = this.telemetryGrapthContainer!.clientHeight;

    this.SetUPlotOptions();
  }

  CalculateUPlotData(): AlignedData {
    this.telemetryLines = {};

    const dates: number[] = [];

    if (!this.loadedNoData) {
      for (const tel of this.detalizedTelemetries) {
        dates.push(Math.round(tel.time.getTime() / 1000));
        this.CalculateTelemetryPoints(tel);
      }
    }

    const result: AlignedData = [dates];
    for (const lineKey in this.telemetryLines) {
      const line = this.telemetryLines[lineKey];

      result.push(line.points);
    }

    return result;
  }

  CalculateTelemetryPoints(tel: ResponseTelemetryItemFiltered) {
    if (tel.buildPlateTemperature != null) {
      for (const tempName in tel.buildPlateTemperature.data) {
        const temp = tel.buildPlateTemperature.data[tempName];
        const indx = Object.keys(tel.buildPlateTemperature.data).indexOf(tempName);

        const currentKey = `buildPlateTemperature.data.${tempName}.current`;
        const targetKey = `buildPlateTemperature.data.${tempName}.target`;

        let currentFound = this.telemetryLines[currentKey];
        let targetFound = this.telemetryLines[targetKey];

        if (currentFound == null) {
          this.telemetryLines[currentKey] = {
            points: [],
            viewModel: tel.buildPlateTemperature,
            readableName:
              this.PrinterModel == null ? '?' : `Build plate temperature ${this.PrinterModel.BPSensorNames[indx]}`,
            color: this.availableLineColors[this.TelemetryLinesCount() % this.availableLineColors.length],
            key: currentKey,
          };

          currentFound = this.telemetryLines[currentKey];
        }

        if (targetFound == null) {
          this.telemetryLines[targetKey] = {
            points: [],
            viewModel: tel.buildPlateTemperature,
            readableName:
              this.PrinterModel == null
                ? '?'
                : `Build plate target temperature ${this.PrinterModel.BPSensorNames[indx]}`,
            color: this.availableLineColors[this.TelemetryLinesCount() % this.availableLineColors.length],
            key: targetKey,
          };

          targetFound = this.telemetryLines[targetKey];
        }

        const currentPoint = temp.current;
        currentFound!.points.push(currentPoint);

        if (temp.target != null) {
          const targetPoint = temp.target;
          targetFound!.points.push(targetPoint);
        }
      }
    }

    if (tel.chamberTemperature != null) {
      for (const tempName in tel.chamberTemperature.data) {
        const temp = tel.chamberTemperature.data[tempName];
        const indx = Object.keys(tel.chamberTemperature.data).indexOf(tempName);

        const currentKey = `chamberTemperature.data.${tempName}.current`;
        const targetKey = `chamberTemperature.data.${tempName}.target`;

        let currentFound = this.telemetryLines[currentKey];
        let targetFound = this.telemetryLines[targetKey];

        if (currentFound == null) {
          this.telemetryLines[currentKey] = {
            points: [],
            viewModel: tel.chamberTemperature,
            readableName:
              this.PrinterModel == null ? '?' : `Chamber temperature ${this.PrinterModel.CHSensorNames[indx]}`,
            color: this.availableLineColors[this.TelemetryLinesCount() % this.availableLineColors.length],
            key: currentKey,
          };

          currentFound = this.telemetryLines[currentKey];
        }

        if (targetFound == null) {
          this.telemetryLines[targetKey] = {
            points: [],
            viewModel: tel.chamberTemperature,
            readableName:
              this.PrinterModel == null ? '?' : `Chamber target temperature ${this.PrinterModel.CHSensorNames[indx]}`,
            color: this.availableLineColors[this.TelemetryLinesCount() % this.availableLineColors.length],
            key: targetKey,
          };

          targetFound = this.telemetryLines[targetKey];
        }

        const currentPoint = temp.current;
        currentFound!.points.push(currentPoint);

        if (temp.target != null) {
          const targetPoint = temp.target;
          targetFound!.points.push(targetPoint);
        }
      }
    }

    if (tel.materialUsed != null) {
      for (const usage of tel.materialUsed) {
        const used = usage.used;
        const indx = tel.materialUsed.indexOf(usage);

        const key = `materialUsed.${usage.name}`;

        let found = this.telemetryLines[key];

        if (found == null) {
          this.telemetryLines[key] = {
            points: [],
            viewModel: usage,
            readableName: this.PrinterModel == null ? '?' : `Usage of ${this.PrinterModel.ExtruderNames[indx]}`,
            color: this.availableLineColors[this.TelemetryLinesCount() % this.availableLineColors.length],
            key: key,
          };

          found = this.telemetryLines[key];
        }

        found!.points.push(used);
      }
    }

    if (tel.fanSpeeds != null) {
      for (const fan of tel.fanSpeeds) {
        const indx: number = tel.fanSpeeds.indexOf(fan);
        const speed = fan.speed;

        const key = `fanSpeeds.${indx}`;

        let found = this.telemetryLines[key];

        if (found == null) {
          this.telemetryLines[key] = {
            points: [],
            viewModel: fan,
            readableName: this.PrinterModel == null ? '?' : `${this.PrinterModel.FanNames[indx]} speed`,
            color: this.availableLineColors[this.TelemetryLinesCount() % this.availableLineColors.length],
            key: key,
          };

          found = this.telemetryLines[key];
        }

        found!.points.push(speed);
      }
    }

    if (tel.printHeadPosition != null) {
      const keyX = `printerHeadPosition.X`;
      const keyY = `printerHeadPosition.Y`;
      const keyZ = `printerHeadPosition.Z`;

      let foundX = this.telemetryLines[keyX];
      let foundY = this.telemetryLines[keyY];
      let foundZ = this.telemetryLines[keyZ];

      if (foundX == null) {
        this.telemetryLines[keyX] = {
          points: [],
          viewModel: tel.printHeadPosition,
          readableName: 'Position X',
          color: this.availableLineColors[this.TelemetryLinesCount() % this.availableLineColors.length],
          key: keyX,
        };

        foundX = this.telemetryLines[keyX];
      }

      if (foundY == null) {
        this.telemetryLines[keyY] = {
          points: [],
          viewModel: tel.printHeadPosition,
          readableName: 'Position Y',
          color: this.availableLineColors[this.TelemetryLinesCount() % this.availableLineColors.length],
          key: keyY,
        };

        foundY = this.telemetryLines[keyY];
      }

      if (foundZ == null) {
        this.telemetryLines[keyZ] = {
          points: [],
          viewModel: tel.printHeadPosition,
          readableName: 'Position Z',
          color: this.availableLineColors[this.TelemetryLinesCount() % this.availableLineColors.length],
          key: keyZ,
        };

        foundZ = this.telemetryLines[keyZ];
      }

      foundX!.points.push(tel.printHeadPosition.x);
      foundY!.points.push(tel.printHeadPosition.y);
      foundZ!.points.push(tel.printHeadPosition.z);
    }

    if (tel.printHeadTemperatures != null) {
      for (const printerHead of tel.printHeadTemperatures) {
        const indxPH = tel.printHeadTemperatures.indexOf(printerHead);
        const phName = this.PrinterModel == null ? '?' : this.PrinterModel.PHNames[indxPH];

        for (const tempName in printerHead.data) {
          const temp = printerHead.data[tempName];
          const indx = Object.keys(printerHead.data).indexOf(tempName);

          const currentKey = `printHeadTemperatures.${printerHead.name}.data.${tempName}.current`;
          const targetKey = `printHeadTemperatures.${printerHead.name}.data.${tempName}.target`;

          let currentFound = this.telemetryLines[currentKey];
          let targetFound = this.telemetryLines[targetKey];

          if (currentFound == null) {
            this.telemetryLines[currentKey] = {
              points: [],
              viewModel: printerHead,
              readableName:
                this.PrinterModel == null
                  ? '?'
                  : `${phName} temperature ${this.PrinterModel.PHSensorNames[indxPH][indx]}`,
              color: this.availableLineColors[this.TelemetryLinesCount() % this.availableLineColors.length],
              key: currentKey,
            };

            currentFound = this.telemetryLines[currentKey];
          }

          if (targetFound == null) {
            this.telemetryLines[targetKey] = {
              points: [],
              viewModel: printerHead,
              readableName:
                this.PrinterModel == null
                  ? '?'
                  : `${phName} target temperature ${this.PrinterModel.PHSensorNames[indxPH][indx]}`,
              color: this.availableLineColors[this.TelemetryLinesCount() % this.availableLineColors.length],
              key: targetKey,
            };

            targetFound = this.telemetryLines[targetKey];
          }

          const currentPoint = temp.current;
          currentFound!.points.push(currentPoint);

          if (temp.target != null) {
            const targetPoint = temp.target;
            targetFound!.points.push(targetPoint);
          }
        }
      }
    }
  }

  GenerateColorsForPrinters() {
    this.availableLineColors.push('#9721f3');
    this.availableLineColors.push('#1b9fff');
    this.availableLineColors.push('#ff2527');
    this.availableLineColors.push('#fd7419');
    this.availableLineColors.push('#f9f918');
    this.availableLineColors.push('#10c565');
  }

  MouseMoved(e: MouseEvent) {
    // console.log(e);
    this.GlobalMouseX = e.clientX;
    this.GlobalMouseY = e.clientY;
  }

  IsHiddenTelemetryKey(key: string) {
    return this.hiddenLineKeys.indexOf(key) != -1;
  }

  GetLegendItemColorStyle(line: TelemetryLine) {
    return {
      'background-color': this.IsHiddenTelemetryKey(line.key) ? 'black' : line.color,
    };
  }

  UnitFor(vm: TelemetryViewModel) {
    return ComponentHelper.GetTelemetryViewModelUnit(vm);
  }

  async ToggleTelemetryKeyVisibility(key: string) {
    if (this.IsHiddenTelemetryKey(key)) {
      this.hiddenLineKeys.delete(key);
    } else {
      this.hiddenLineKeys.push(key);
    }

    await GlobalDataModule.ChangeMachineTelemetryViewStateHiddenTelemetryKeys(this.hiddenLineKeys);
    this.SetUPlotOptions();
  }
  //#endregion

  //#endregion

  //#region EVENTS
  @Emit('closed')
  Closed() {}
  //#endregion

  //#region HOOKS
  beforeCreate() {
    this.resizeObserver = new ResizeObserver(() => {
      this.TelemetryGrapthContainerResized();
    });
  }

  created() {
    this.InitializeAvailableTelemetryTypes();
    this.SetUPlotOptions();

    this.uPlotData = [[], []];

    this.debounceFetchTelemetry = debounce(async () => {
      await this.ClearTelemetries();
      await this.LoadTelemetry();
    }, 500);

    this.debounceFetchDetalizedTelemetry = debounce(async (range: TimeRange) => {
      await this.LoadDetalizedTelemetry(range);
    }, 1500);

    this.GenerateColorsForPrinters();
  }

  mounted() {
    this.cursorInfoContainer.remove();
    document.body.appendChild(this.cursorInfoContainer);

    this.TelemetryGrapthContainerResized();

    this.resizeObserver.observe(this.telemetryGrapthContainer!);

    window.addEventListener('mousemove', this.MouseMoved);

    this.showLegend = GlobalDataModule.MachineTelemetryViewState.showLegendPanel;
    this.showFilters = GlobalDataModule.MachineTelemetryViewState.showFiltersPanel;
    this.selectedTelemetryTypes = GlobalDataModule.MachineTelemetryViewState.selectedTelemetryTypes;
    this.hiddenLineKeys = GlobalDataModule.MachineTelemetryViewState.hiddenTelemetryLineKeys;
  }

  async beforeDestroy() {
    this.cursorInfoContainer.remove();
    this.resizeObserver.unobserve(this.telemetryGrapthContainer!);
    this.resizeObserver.disconnect();
    window.removeEventListener('mousemove', this.MouseMoved);
    await this.UnsubscribeFromTelemetry(this.printer.Id);
  }
  //#endregion

  //#region TRANSLATIONS
  TranslateTelemetryType(type: TelemetryType) {
    if (type == TelemetryType.BuildPlateTemperature) {
      return 'Build plate °C';
    } else if (type == TelemetryType.ChamberTemperature) {
      return 'Chamber °C';
    } else if (type == TelemetryType.PrintHeadTemperature) {
      return 'Printer head °C';
    } else if (type == TelemetryType.FanSpeed) {
      return 'Fan speed';
    } else if (type == TelemetryType.MaterialUsed) {
      return 'Material usage';
    } else if (type == TelemetryType.PrintHeadPosition) {
      return 'Printer head position';
    }

    return '?';
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.machine-telemtry {
  .toggles-container {
    display: flex;
    flex-direction: row;
    height: 28px;
    border: var(--machine-telemetry-controls-border);
    border-radius: 10px;
    padding: 0 14px;
    align-items: center;

    i {
      cursor: pointer;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        opacity: 0.75;
      }

      &.active {
        color: var(--machine-telemetry-controls-button-active);
      }
    }
  }

  .machine-telemtry-header-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
    flex: 1;
    align-items: flex-start;
  }

  .telemetry-plot {
    display: flex;
    position: relative;
    flex: 1;
    min-height: 0;
    overflow: auto;

    .no-data-container {
      font-weight: 300;
      color: white;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  .telemetry-graph-container {
    // background: rgba(255, 0, 0, 0.165);
    flex: 1;
    color: white;
    overflow: hidden;
    position: relative;
  }

  .telemetry-type-filters {
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.5rem;

    .telemetry-type-switch {
      border-radius: 18px;

      transition: all 0.2s ease-out;
      padding: 2px 12px;

      &.active {
        outline: 1px solid #9b9b9b;
      }

      &:not(.active) {
        outline: 1px solid #313131;
        opacity: 0.5;
      }
    }
  }

  .legend {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .legend-item {
    display: flex;
    align-items: center;
    color: rgb(225, 225, 225);
    &.hidden {
      .caption {
        color: rgba(225, 225, 225, 0.5);
      }
    }
    .color {
      width: 14px;
      height: 4px;
      margin-right: 8px;
      cursor: pointer;
    }
    .caption {
      font-size: 12px;
      margin-right: 8px;
    }
  }
}
</style>

<style lang="scss">
.cursor-info-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;

  .cursor-info {
    background: rgb(34, 37, 43);
    padding: 0.5rem;
    border-radius: 6px;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    color: rgb(211, 211, 211);
    top: 0;
    left: 0;
    z-index: 1000;
    position: absolute;
    pointer-events: none;
    font-size: 12px;

    .date {
      margin-bottom: 4px;
    }

    .points {
      display: flex;
      flex-direction: column;

      .point {
        display: flex;
        align-items: center;

        .color {
          width: 14px;
          height: 4px;
          margin-right: 8px;
        }

        .name {
          margin-right: 12px;
        }

        .value {
          flex: 1;
          text-align: right;
          color: white;
        }
      }
    }
  }
}
</style>
