<template>
  <div class="editor-footer px-3 py-2">
    <slot name="buttons" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class EditorFooter extends Vue {}
</script>

<style lang="scss" scoped>
.editor-footer {
  display: flex;
  color: var(--editor-footer-text);
  justify-content: flex-end;
  background: var(--editor-footer-background);
  border-radius: 0px 0px 6px 6px;
}
</style>
