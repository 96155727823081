import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { SourceGroup } from '../../models/Entities';
import { New_GC } from '../util/GC';
import { Guid } from 'guid-typescript';
import { GuidHelper } from '@/util/GuidHelper';
import { UtilModule } from './utilModule';
import * as ips from '../../util/api/ips';
import * as routes from '../../util/api/routes';
import * as hubActions from '../../util/api/hubActions';
import {
  RequestReadSourceGroupByIds,
  RequestCreateSourceGroup,
  RequestDeleteSourceGroup,
  RequestUpdateSourceGroup,
} from '../../models/requests/RequestMonolith';
import { RequestStatus } from '@/models/enums/RequestStatus';
import {
  ResponseMonolithHelper,
  ResponseSourceGroups,
  ResponseSourceGroup,
} from '../../models/responses/ResponseMonolith';
import { HubConnection } from '@microsoft/signalr';
import { ErrorModule } from '@/store/modules/errorsModule';
import Vue from 'vue';

@Module({ dynamic: true, name: 'sourceGroup', store: store })
export default class sourceGroupModule extends VuexModule {
  private sourceGroups: SourceGroup[] = [];
  private _newGC = new New_GC();

  get SourceGroups() {
    return this.sourceGroups;
  }

  @Mutation
  AddSourceGroupToModule(value: SourceGroup) {
    if (this.sourceGroups.find(a => a.Id.equals(value.Id))) return;
    this._newGC.Add([value.Id]);
    this.sourceGroups.push(value);
  }
  @Mutation
  DeleteSourceGroupFromModule(value: SourceGroup) {
    this._newGC.Remove([value.Id]);
    this.sourceGroups.delete(value);
  }
  @Mutation
  UpdateSourceGroupName([sourceGroup, name]: [SourceGroup, string]) {
    sourceGroup.Name = name;
  }
  @Action({ rawError: true })
  async UpdateSourceGroup([groupId, name]: [Guid, string]) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.SOURCE_GROUP,
      routes.UriJobSourcesGroup.UPDATE,
    );
    const request = new RequestUpdateSourceGroup();
    request.id = groupId.toString();
    request.name = name;
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      ResponseMonolithHelper.createRealSourceGroup(axiosResponse.data.response as ResponseSourceGroup);
    }
    return { result, message };
  }
  @Action({ rawError: true })
  async LoadGroupByIds(ids: Guid[]): Promise<SourceGroup[]> {
    const toAddGuids = GuidHelper.except(
      ids,
      this.SourceGroups.map(a => a.Id),
    );
    if (toAddGuids.any()) {
      const groups = await this.JustLoadGroups(toAddGuids);
      const responseIds = groups.map(a => a.Id);
      const inList = this.SourceGroups.filter(a => GuidHelper.includes(responseIds, a.Id));
      const inListIds = inList.map(a => a.Id);
      const toAddGroups = groups.filter(a => !GuidHelper.includes(inListIds, a.Id));
      for (const item of toAddGroups) {
        this.AddSourceGroupToModule(item);
      }
      return this.SourceGroups.filter(a => GuidHelper.includes(responseIds, a.Id));
    }
    return [];
  }
  @Action({ rawError: true })
  async JustLoadGroups(ids: Guid[]): Promise<SourceGroup[]> {
    if (ids.empty()) return [];
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.SOURCE_GROUP,
      routes.UriJobSourcesGroup.READ_BY_IDS,
    );
    const request = new RequestReadSourceGroupByIds();
    request.ids = ids.map(a => a.toString());
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealSourceGroups(
        axiosResponse.data.response as ResponseSourceGroups,
      );
      return response.groups.map(a => a.Map());
    } else ErrorModule.ShowError(message);
    return [];
  }
  @Action({ rawError: true })
  async LoadAllGroups(): Promise<SourceGroup[] | null> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.SOURCE_GROUP,
      routes.UriJobSourcesGroup.READ_ALL,
    );
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, null);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealSourceGroups(
        axiosResponse.data.response as ResponseSourceGroups,
      );
      const responseIds = response.groups.map(a => a.id);
      const inList = this.SourceGroups.filter(a => GuidHelper.includes(responseIds, a.Id));
      const inListIds = inList.map(a => a.Id);
      const toAdd = response.groups.filter(a => !GuidHelper.includes(inListIds, a.id));
      for (const item of toAdd) {
        this.AddSourceGroupToModule(item.Map());
      }
      return this.SourceGroups.filter(a => GuidHelper.includes(responseIds, a.Id));
    } else ErrorModule.ShowError(message);
    return [];
  }
  @Action({ rawError: true })
  async RegisterNew(name: string): Promise<[RequestStatus, string]> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.SOURCE_GROUP,
      routes.UriJobSourcesGroup.CREATE,
    );
    const request = new RequestCreateSourceGroup();
    request.name = name;
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealSourceGroup(axiosResponse.data.response as ResponseSourceGroup);
      this.AddSourceGroupToModule(response.Map());
    } else ErrorModule.ShowError(message);
    return [result, message];
  }
  @Action({ rawError: true })
  async DeleteGroup(groupId: Guid): Promise<[RequestStatus, string]> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.SOURCE_GROUP,
      routes.UriJobSourcesGroup.DELETE,
    );
    const request = new RequestDeleteSourceGroup();
    request.id = groupId.toString();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealSourceGroup(axiosResponse.data.response as ResponseSourceGroup);
      this.DeleteSourceGroupFromModule(response.Map());
    } else ErrorModule.ShowError(message);
    return [result, message];
  }
  // @Action({ rawError: true })
  // async SourceGroupsSync([sourceGroups, componentId]:[SourceGroup[], Guid]) {
  //   this._gc.Occupy(
  //     sourceGroups.map(a=>a.Id),
  //     componentId
  //   );
  // }
  // @Action({ rawError: true })
  // async LooseSourceGroups([sourceGroupIds, componentId]:[Guid[], Guid]) {
  //   let toDeleteIds = await this._gc.Loose(sourceGroupIds, componentId);
  //   let toDelete = this.SourceGroups.filter(a=>GuidHelper.includes(toDeleteIds, a.Id));
  //   for (let sourceGroup of toDelete) {
  //     this.DeleteSourceGroupFromModule(sourceGroup);
  //   }
  // }

  @Action({ rawError: true })
  async LooseSourceGroups([sources, componentId]: [SourceGroup[], Guid]) {
    this._newGC.Loose(
      sources.map(a => a.Id),
      componentId,
    );
  }
  @Action({ rawError: true })
  async CollectSourceGroups() {
    const removed = this._newGC.Collect();

    for (const job of this.sourceGroups.filter(a => GuidHelper.includes(removed, a.Id))) {
      this.DeleteSourceGroupFromModule(job);
    }
  }
  @Action({ rawError: true })
  async OccupySourceGroups([sources, componentId]: [SourceGroup[], Guid]) {
    this._newGC.Occupy(
      sources.map(a => a.Id),
      componentId,
    );
  }

  @Action({ rawError: true })
  async SourceGroupSubscribe(hubConnection: HubConnection) {
    hubConnection?.on(hubActions.RECEIVE_CREATE_SOURCE_GROUP, (response: ResponseSourceGroup) => {
      const realResponse = ResponseMonolithHelper.createRealSourceGroup(response);
      const inModule = this.SourceGroups.firstOrDefault(a => a.Id.equals(realResponse.id));
      if (inModule != null) return;
      this.AddSourceGroupToModule(realResponse.Map());
    });
    hubConnection?.on(hubActions.RECEIVE_UPDATE_SOURCE_GROUP, (response: ResponseSourceGroup) => {
      const realResponse = ResponseMonolithHelper.createRealSourceGroup(response);
      const group = this.SourceGroups.firstOrDefault(a => a.Id.equals(realResponse.id));
      if (group == null) return;
      if (group.Name !== realResponse.name) {
        this.UpdateSourceGroupName([group, realResponse.name]);
      }
      if (
        (group.UserId && !realResponse.userId) ||
        (!group.UserId && realResponse.userId) ||
        (group.UserId && realResponse.userId && !group.UserId.equals(realResponse.userId))
      ) {
        Vue.set(group, 'UserId', realResponse.userId);
      }
      if (group.LastUpdated != realResponse.lastUpdated) {
        Vue.set(group, 'LastUpdated', realResponse.lastUpdated == null ? null : new Date(realResponse.lastUpdated));
      }
      if (group.FileCount != realResponse.fileCount) {
        Vue.set(group, 'FileCount', realResponse.fileCount);
      }
    });
    hubConnection?.on(hubActions.RECEIVE_DELETE_SOURCE_GROUP, (response: ResponseSourceGroup) => {
      const realResponse = ResponseMonolithHelper.createRealSourceGroup(response);
      const inModule = this.SourceGroups.firstOrDefault(a => a.Id.equals(realResponse.id));
      if (inModule == null) return;
      this.DeleteSourceGroupFromModule(inModule);
    });
  }
}

export const SourceGroupModule = getModule(sourceGroupModule);
