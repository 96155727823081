<template>
  <div class="aura-key-editor">
    <editor-header>
      <template slot="header">
        <span>
          {{ item.item.cryptlexKey }}
        </span>
      </template>

      <template slot="buttons">
        <header-icon-button testid="auraKeyEditor.closebutton" iconName="fa-times" @clicked="closeButtonClicked" />
      </template>
    </editor-header>

    <editor-content>
      <text-input
        class="mb-2"
        :label="KeyCaption"
        :value.sync="Key"
        :labelWidth="labelWidth"
        :disabled="isLoading"
        :readonly="true"
        testid="auraKeyEditor.keyField"
      />

      <text-input
        class="mb-2"
        :label="MachineIdCaption"
        :value.sync="MachineID"
        :labelWidth="labelWidth"
        :disabled="isLoading"
        :readonly="true"
        testid="auraKeyEditor.machineIDField"
      />

      <text-input
        class="mb-2"
        :label="StatusCaption"
        :value.sync="Status"
        :labelWidth="labelWidth"
        :disabled="isLoading"
        :readonly="true"
        testid="auraKeyEditor.statusField"
      />

      <div class="d-flex">
        <text-input
          class="mb-2"
          :label="ActivationCaption"
          :labelWidth="labelWidth"
          :disabled="isLoading"
          :readonly="true"
        />

        <regular-button
          v-if="Activated"
          testid="auraKeyEditor.deactivateOfflineButton"
          :disabled="isLoading"
          :label="ManageCaption"
          @clicked="DeactivateOffline"
        />

        <regular-button
          v-else
          testid="auraKeyEditor.activateOfflineButton"
          :disabled="isLoading"
          :label="ActivateOfflineCaption"
          @clicked="ActivateOffline"
        />
      </div>

      <div class="addons">
        <div v-for="addon of PossibleAddons" :key="addon.id" :class="['addon-line w-100 mb-2']">
          <span :class="['mr-2', HasAddon(addon) ? 'added' : null]" :style="{ 'min-width': labelWidth + 'px' }"
            >+ {{ addon.shortName }}</span
          >

          <v-popover v-if="!HasAddon(addon)" trigger="hover" placement="right" :disabled="!Activated">
            <regular-button :disabled="Activated" :label="AddCaption" @clicked="AddAddon(addon)" />

            <div slot="popover">
              {{ KeyAddonsLockedCaption }}
            </div>
          </v-popover>

          <v-popover v-else trigger="hover" placement="right" :disabled="!Activated">
            <regular-button :disabled="Activated" :label="DeleteCaption" @clicked="RemoveAddon(addon)" />

            <div slot="popover">
              {{ KeyAddonsLockedCaption }}
            </div>
          </v-popover>
        </div>
      </div>

      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </editor-content>
  </div>
</template>

<script lang="ts">
import en from '@/localization/en';
import { ProductModule } from '@/store/modules/billing/productModule';
import { ProductPriceModule } from '@/store/modules/billing/productPriceModule';
import {
  PurchasedItemFull,
  PurchasedItemFullGroup,
  PurchasedItemModule,
} from '@/store/modules/billing/purchasedItemModule';
import { Product, ProductPrice, PurchasedItem } from '@/models/Entities';
import { Component, Prop, Vue } from 'vue-property-decorator';
import FadeTransition from '@/components/presentation/FadeTransition.vue';

import { POSITION } from 'vue-toastification';
import { toast } from '@/main';

import AttachSubscriptionDialog from '@/views/dialogs/subscriptions/AttachSubscriptionDialog.vue';
import { create } from 'vue-modal-dialogs';
import { VPopover } from 'v-tooltip';
import OfflineActivationDialog from '@/views/dialogs/OfflineActivationDialog.vue';
import EditorHeader from '@/components/forms/base/EditorHeader.vue';
import EditorContent from '@/components/forms/base/EditorContent.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import RegularButton from '../buttons/RegularButton.vue';
import HeaderIconButton from '@/components/buttons/HeaderIconButton.vue';

const AttachSubscription = create<PurchasedItemFullGroup[], PurchasedItem>(
  AttachSubscriptionDialog,
  'subscriptionItemGroups',
);

const OfflineActivation = create<PurchasedItemFull, boolean>(OfflineActivationDialog, 'purchasedItem');

@Component({
  name: 'aura-key-editor',
  components: {
    HeaderIconButton,
    FadeTransition: FadeTransition,
    VPopover: VPopover,
    EditorHeader: EditorHeader,
    EditorContent: EditorContent,
    TextInput: TextInput,
    RegularButton: RegularButton,
  },
})
export default class AuraKeyEditor extends Vue {
  labelWidth: number = 135;

  //#region PROPS
  @Prop()
  private item!: PurchasedItemFull;
  //#endregion

  //#region STATE
  private isLoading: boolean = false;

  private get Key() {
    return this.item.item.cryptlexKey;
  }
  private get MachineID() {
    return this.item.item.deviceName === null ? '-' : this.item.item.deviceName;
  }
  private get Status() {
    return this.item.item.activated ? en.activated : en.free;
  }
  private get PossibleAddons(): Product[] {
    return ProductModule.Products.filter(a => {
      let addonPurchased = PurchasedItemModule.PurchasedItems.firstOrDefault(item => {
        let p = this.ProductForPurchasedItem(item);
        return p?.id.toString() === a.id.toString();
      });
      return a.baseProductId?.toString() == this.item.product.id.toString() && addonPurchased !== null;
    });
  }
  private get CurrentAddons(): PurchasedItem[] {
    return this.item.addons;
  }
  private get Activated() {
    return this.item.item.activated;
  }

  private HasAddon(product: Product): boolean {
    return (
      this.item.addons.firstOrDefault(a => {
        let p = this.ProductForPurchasedItem(a);
        return p?.id.toString() === product.id.toString();
      }) !== null
    );
  }

  ProductForPurchasedItem(value: PurchasedItem): Product | null {
    let price = ProductPriceModule.ProductPrices.firstOrDefault(a => a.id === value.productPriceId);

    if (price === null) {
      return null;
    }

    let product = ProductModule.Products.firstOrDefault(a => a.id === price!.productId);

    return product;
  }
  //#endregion

  //#region STATE LOGIC
  async AddAddon(product: Product) {
    this.isLoading = true;
    let addonPurchased = PurchasedItemModule.PurchasedItems.firstOrDefault(a => {
      let p = this.ProductForPurchasedItem(a);
      return p?.id.toString() === product.id.toString() && a.baseItemId === null;
    });

    if (addonPurchased === null) {
      toast.error(`${en.thereAreNoMoreAddons} ${product.name}`, {
        position: POSITION.BOTTOM_RIGHT,
      });
      this.isLoading = false;
      return;
    }

    const full = this.PurchasedItemsFull.firstOrDefault(a => a.item.id.toString() === addonPurchased?.id.toString());

    if (addonPurchased.purchasedSubscriptionId !== null) {
      // It's a subscription, let's see if we have any cancelled
      const sameSubscriptionGroups = this.AddonGroups.filter(
        a => a.group[0].subscription !== null && a.group[0].product.id.toString() === full?.product.id.toString(),
      );

      if (sameSubscriptionGroups.length > 1) {
        addonPurchased = await AttachSubscription(sameSubscriptionGroups);
      }
    }

    if (addonPurchased === null) {
      this.isLoading = false;
      return;
    }

    const result = await PurchasedItemModule.Attach({
      id: addonPurchased!.id,
      attachToId: this.item.item.id,
    });

    if (result) {
      toast.success(en.addonAttachedSuccessfully, {
        position: POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error(en.couldNotAttachAddon, {
        position: POSITION.BOTTOM_RIGHT,
      });
    }

    this.isLoading = false;
  }

  async RemoveAddon(product: Product) {
    this.isLoading = true;
    let addonPurchased = this.item.addons.firstOrDefault(a => {
      let p = this.ProductForPurchasedItem(a);
      return p?.id.toString() === product.id.toString() && a.baseItemId?.toString() === this.item.item.id.toString();
    });

    if (addonPurchased === null) {
      return;
    }

    const result = await PurchasedItemModule.Detach({
      id: addonPurchased!.id,
      detachFromId: this.item.item.id,
    });

    if (result) {
      toast.success(en.addonDetachededSuccessfully, {
        position: POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error(en.couldNotDetachedAddon, {
        position: POSITION.BOTTOM_RIGHT,
      });
    }

    this.isLoading = false;
  }

  async DeactivateOffline() {
    return OfflineActivation(this.item);
  }

  async ActivateOffline() {
    return OfflineActivation(this.item);
  }

  private get PurchasedItems(): PurchasedItem[] {
    return PurchasedItemModule.PurchasedItems;
  }

  private get ProductPrices(): ProductPrice[] {
    return ProductPriceModule.ProductPrices;
  }

  private get Products(): Product[] {
    return ProductModule.Products;
  }

  private get PurchasedAddons(): PurchasedItemFull[] {
    return this.PurchasedItemsFull.filter(a => {
      return a.product.baseProductId !== null;
    });
  }

  private get PurchasedItemsFull(): PurchasedItemFull[] {
    return PurchasedItemModule.PurchasedItemsFull;
  }
  private get AddonGroups(): PurchasedItemFullGroup[] {
    return PurchasedItemModule.PurchasedItemsGroups;
  }

  //#endregion

  //#region EVENTS
  private closeButtonClicked() {
    this.$emit('close-clicked');
  }
  //#endregion

  //#region HOOKS
  async created() {}

  async beforeDestroy() {}
  //#endregion

  //#region TRANSLATIONS
  private get KeyCaption(): string {
    return en.key.titleCase();
  }
  private get MachineIdCaption(): string {
    return en.machineId.titleCase();
  }
  private get StatusCaption(): string {
    return en.status.titleCase();
  }
  private get ActivationCaption(): string {
    return en.offlineActivation.titleCase();
  }
  private get AddCaption(): string {
    return en.add.titleCase();
  }
  private get DeleteCaption(): string {
    return en.delete.titleCase();
  }
  private get KeyAddonsLockedCaption(): string {
    return en.keyAddonsLockedCaption;
  }
  private get ManageCaption(): string {
    return 'Mange';
  }
  private get ActivateOfflineCaption(): string {
    return 'Activate offline';
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.aura-key-editor {
  background: var(--editor-background);
  border-radius: 6px;

  .addons {
    .addon-line {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 28px;
      color: var(--editor-field-name);

      span {
        display: flex;
        font-size: 14px;
        line-height: 16px;

        &.added {
          color: var(--special-text);
        }
      }

      .unavailable {
        opacity: 0.5;
        cursor: unset;
      }
    }
  }

  button {
    width: 150px;
    height: 26px;
  }
}
</style>
