<template>
  <div
    :class="[
      isFocused ? 'focused' : '',
      hasError ? 'has-error' : 'has-no-error',
      'password-container align-items-center',
      'd-inline-flex',
    ]"
  >
    <input
      class="main-input"
      :type="InputType"
      autocomplete="false"
      spellcheck="false"
      :placeholder="placeholder"
      :data-testid="testId"
      @input="updateValue($event.target.value)"
      @focus="onFocus"
    />
    <div>
      <div v-if="isHide" @click.prevent="showUnshow">
        <i class="fa-eye fal mr-2" />
      </div>
    </div>
    <div v-if="!isHide" @click.prevent="showUnshow">
      <i class="fal mr-2 fa-eye-slash" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

@Component
export default class PasswordTextBox extends Vue {
  @Prop() placeholder!: string;
  @Prop({ default: false }) hasError!: boolean;
  @Prop({ default: undefined }) testId?: string;

  @Emit('input')
  private updateValue() {}

  private isFocused: boolean = false;
  private onFocus() {
    this.isFocused = !this.isFocused;
  }

  private isHide: boolean = true;
  private get InputType(): string {
    return this.isHide ? 'password' : 'text';
  }
  private showUnshow() {
    this.isHide = !this.isHide;
  }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400');

@media (min-width: 576px) {
  div.password-container {
    max-width: 480px;
  }
}
@media (max-width: 576px) {
  div.password-container {
    max-width: 380px;
  }
}

div.password-container {
  border-width: 0 0 1px 0;
  border-style: solid;
  background-color: transparent;
}
div.has-no-error {
  border-color: var(--login-fields-gray);
}
div.has-error {
  border-color: var(--login-errors);
}
input.main-input {
  width: 100%;
  min-width: 200px;
  background-color: transparent;
  border-width: 0;
  border-color: transparent;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  padding-left: 10px;
  padding-right: 15px;
  color: var(--login-fields-text);
  outline: none;
}
input.main-input:-moz-placeholder {
  color: var(--login-fields-text);
  opacity: 1;
}
input.main-input::-moz-placeholder {
  color: var(--login-fields-text);
  opacity: 1;
}
input.main-input::placeholder {
  color: var(--login-fields-text);
  text-transform: uppercase;
}
input.main-input::placeholder:hover {
  color: white;
}
div.password-container:hover {
  color: white;
  border-color: white;
}
div.focused {
  color: white;
  border-color: white;
}
button.eye {
  background-color: transparent;
  background-size: 27px 16px;
  width: 28px;
  height: 16px;
  border-width: 0;
  font-size: 18px;
  outline: none;
}
button.open {
  background-image: url('~@/assets/images/eye.png');
}
button.closed {
  background-image: url('~@/assets/images/eye-closed.png');
}

i {
  cursor: pointer;
  display: flex;
  min-width: 26px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: rgb(235, 235, 235);
}
</style>
