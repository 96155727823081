import moment from 'moment';
import { Point } from './Geometry';

export enum TimeRangeType {
  Range,
  Day,
  Week,
  Month,
  Year,
  Live,
  Default,
}

export interface TimeRange {
  dateFrom: Date;
  dateTo: Date;
  type?: TimeRangeType;
}

export function GetDefaultTimeRange(type?: TimeRangeType): TimeRange {
  const now = moment(new Date());
  const today = new Date(now.year(), now.month(), now.date(), 0, 0, 0, 0);
  const tommorow = moment(today).add(1, 'days').toDate();

  return {
    dateFrom: today,
    dateTo: tommorow,
    type: type ?? TimeRangeType.Default,
  };
}

export interface DrawnTimeSegment {
  date: Date;
  dateString: string;
  lineStart: Point;
  lineEnd: Point;
  dateStringPosition: Point;
  dateStringWidth: number;
}

export enum TimeSegmentType {
  HundredMilliseconds,
  Second,
  Minute,
  FiveMinutes,
  Hour,
  Day,
  Month,
  Year,
}
