<template>
  <div class="add-company-user">
    <EditorHeader>
      <template slot="header">
        <span>{{ AddCompanyUserCaption }}</span>
      </template>

      <template slot="buttons">
        <HeaderIconButton iconName="fa-times" test-id="addNewUser.closeButton" @clicked="closeButtonClicked" />
      </template>
    </EditorHeader>

    <EditorContent>
      <TextInput
        class="mb-2"
        :label="FirstNameCaption"
        :value.sync="FirstName"
        :labelWidth="labelWidth"
        :invalid="firstNameValidationFailed"
        :disabled="isLoading"
        placeholder="Enter first name..."
        auto-complete="off"
        test-id="addNewUser.firstNameInput"
      />

      <TextInput
        class="mb-2"
        :label="SecondNameCaption"
        :value.sync="SecondName"
        :labelWidth="labelWidth"
        :invalid="secondNameValidationFailed"
        :disabled="isLoading"
        placeholder="Enter second name..."
        auto-complete="off"
        test-id="addNewUser.secondNameInput"
      />

      <TextInput
        class="mb-2"
        :label="EmailCaption"
        :value.sync="Email"
        :labelWidth="labelWidth"
        :invalid="emailValidationFailed"
        :disabled="isLoading"
        placeholder="Enter e-mail..."
        auto-complete="off"
        test-id="addNewUser.emailInput"
      />

      <TextInput
        :label="PasswordCaption"
        :value.sync="Password"
        :labelWidth="labelWidth"
        :invalid="passwordValidationFailed"
        :disabled="isLoading"
        type="password"
        placeholder="Enter password..."
        auto-complete="new-password"
        test-id="addNewUser.passwordInput"
      />

      <fade-transition :maxHeight="28">
        <div v-if="validationErrors.length != 0" class="errors-container">
          <span class="mt-2">{{ validationErrors[0] }}</span>
        </div>
      </fade-transition>

      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </EditorContent>

    <EditorFooter>
      <template slot="buttons">
        <IconButton
          :disabled="isLoading"
          iconName="fa-check"
          :text="AddCaption"
          test-id="addNewUser.addButton"
          @clicked="addButtonClicked"
        />
      </template>
    </EditorFooter>
  </div>
</template>

<script lang="ts">
import en from '@/localization/en';
import { UserModule } from '@/store/modules/userModule';
import { Countries, CountryWithName } from '@/models/responses/Countries';
import { Component, Vue, Emit } from 'vue-property-decorator';
import FadeTransition from '@/components/presentation/FadeTransition.vue';
import * as EmailValidator from 'email-validator';
import { RequestStatus } from '@/models/enums/RequestStatus';
import IconButton from '../buttons/IconButton.vue';
import EditorFooter from '@/components/forms/base/EditorFooter.vue';
import EditorHeader from '@/components/forms/base/EditorHeader.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import EditorContent from '@/components/forms/base/EditorContent.vue';
import HeaderIconButton from '@/components/buttons/HeaderIconButton.vue';

@Component({
  name: 'add-company-user',
  components: {
    HeaderIconButton,
    FadeTransition: FadeTransition,
    IconButton: IconButton,
    EditorHeader: EditorHeader,
    EditorContent: EditorContent,
    EditorFooter: EditorFooter,
    TextInput: TextInput,
  },
})
export default class AddCompanyUser extends Vue {
  labelWidth: number = 150;

  private validationTimeoutHandle: number = -1;
  private firstNameValidationFailed: boolean = false;
  private secondNameValidationFailed: boolean = false;
  private passwordValidationFailed: boolean = false;
  private emailValidationFailed: boolean = false;
  private validationErrors: string[] = [];

  private FirstName: string = '';
  private SecondName: string = '';
  private Password: string = '';
  private Email: string = '';

  private isLoading: boolean = false;

  private countryItems: CountryWithName[] = [];
  private GetCountryName(country: Countries): string {
    return en.CountryNames[country];
  }

  private InitializeCountryItems() {
    for (let key in Countries) {
      if (!isNaN(Number(key))) continue;
      let country = Countries[key as keyof typeof Countries];
      this.countryItems.push({
        country: country,
        name: this.GetCountryName(country),
      });
    }
  }

  private clearValidationErrors() {
    if (this.validationTimeoutHandle !== -1) {
      clearTimeout(this.validationTimeoutHandle);
    }

    this.validationErrors = [];
    this.secondNameValidationFailed = false;
    this.passwordValidationFailed = false;
    this.emailValidationFailed = false;
    this.validationTimeoutHandle = -1;
  }

  private validate(): boolean {
    this.clearValidationErrors();

    // Validate
    if (this.FirstName == null || this.FirstName.length == 0) {
      this.validationErrors.push('Enter first name');
      this.firstNameValidationFailed = true;
    } else if (this.SecondName == null || this.SecondName.length == 0) {
      this.validationErrors.push('Enter second name');
      this.secondNameValidationFailed = true;
    } else if (this.Password == null || this.Password.length == 0) {
      this.validationErrors.push('Enter password');
      this.passwordValidationFailed = true;
    } else if (this.Email == null || this.Email.length == 0) {
      this.validationErrors.push('Enter email');
      this.emailValidationFailed = true;
    } else if (!EmailValidator.validate(this.Email)) {
      this.validationErrors.push('Enter correct email');
      this.emailValidationFailed = true;
    }

    const failed = this.validationErrors.length != 0;

    if (failed) {
      this.validationTimeoutHandle = window.setTimeout(() => {
        this.clearValidationErrors();
      }, 3500);
    }

    return !failed;
  }

  public clearState() {
    this.FirstName = '';
    this.SecondName = '';
    this.Password = '';
    this.Email = '';
  }

  private closeButtonClicked() {
    this.$emit('close-clicked');
  }

  @Emit('add-clicked')
  private async addButtonClicked() {
    if (!this.validate()) {
      return;
    }

    this.isLoading = true;

    const result = await UserModule.RegisterUser([this.Email, this.Password, this.FirstName, this.SecondName]);

    this.isLoading = false;

    if (result[0] === RequestStatus.OK) {
      this.clearState();
    }

    return result[0] === RequestStatus.OK;
  }

  created() {
    this.InitializeCountryItems();
  }

  private get AddCompanyUserCaption(): string {
    return en.addNewUser.titleCase();
  }
  private get NameCaption(): string {
    return en.nameCaption.titleCase();
  }
  private get DescriptionCaption(): string {
    return en.description.titleCase();
  }
  private get CountryCaption(): string {
    return en.country.titleCase();
  }
  private get AddressCaption(): string {
    return en.address.titleCase();
  }
  private get FirstNameCaption(): string {
    return en.firstName.titleCase();
  }
  private get SecondNameCaption(): string {
    return en.secondName.titleCase();
  }
  private get PasswordCaption(): string {
    return en.password.titleCase();
  }
  private get EmailCaption(): string {
    return en.email.titleCase();
  }
  private get AddCaption(): string {
    return en.add.titleCase();
  }
}
</script>

<style lang="scss" scoped>
.add-company-user {
  background: var(--editor-background);
  border-radius: 6px;

  .errors-container {
    display: flex;
    span {
      color: var(--editor-error-text);
      font-size: 14px;
    }
  }

  .error-border {
    border: 1px solid var(--main-orange);
  }
}
</style>
