<template>
  <div class="binary-switch" @click="clicked">
    <div :class="['circle', GetStateClass()]"></div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';

export enum BinarySwitchState {
  Left = 0,
  Right = 1,
}

@Component({
  components: {},
  name: 'binary-switch',
})
export default class BinarySwitch extends Vue {
  @Prop({ default: undefined }) initialState: BinarySwitchState | undefined;
  @Prop({ default: undefined }) syncTo: BinarySwitchState | undefined;

  @Watch('syncTo')
  onSyncToChanged(oldValue: BinarySwitchState, newValue: BinarySwitchState) {
    this.state = newValue;
  }

  private state!: BinarySwitchState;

  created() {
    if (this.initialState !== undefined) {
      this.state = this.initialState;
    }
  }

  @Emit('clicked')
  clicked() {
    if (this.syncTo === undefined) {
      if (this.state === BinarySwitchState.Left) {
        this.state = BinarySwitchState.Right;
      } else {
        this.state = BinarySwitchState.Left;
      }
    }

    // weird..
    super.$forceUpdate();

    return this.state;
  }

  GetStateClass() {
    if (this.state === BinarySwitchState.Left) {
      return 'left';
    }

    return 'right';
  }
}
</script>

<style lang="scss" scoped>
.binary-switch {
  height: 24px;
  width: 44px;
  border: 2px solid var(--account-view-border);
  border-radius: 18px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .circle {
    width: 16px;
    height: 16px;
    background-color: var(--account-view-border);
    border-radius: 16px;
    margin: 0 2px;
    transition: transform 125ms ease-out;

    &.left {
      transform: translateX(-9px);
    }

    &.right {
      transform: translateX(9px);
    }
  }
}
</style>
