export interface FunctionBatch {
  Batch(): Promise<any>;
  Name?: string;
}

export class AsyncBatchQueue {
  private _queue!: FunctionBatch[];
  private _currentBatch!: FunctionBatch | null;

  constructor() {
    this._queue = [];
    this._currentBatch = null;
  }

  public Queue(batch: FunctionBatch) {
    this._queue.push(batch);

    if (this._currentBatch == null) {
      this.Perform();
    }
  }

  private async Perform() {
    while (this._queue.length > 0) {
      const batch = this._queue.shift()!;
      this._currentBatch = batch;

      if (batch.Name !== undefined) console.log('Performing batch: ' + batch.Name);

      await batch.Batch();

      if (batch.Name !== undefined) console.log('Finished performing batch: ' + batch.Name);
    }

    this._currentBatch = null;
  }
}
