import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import store from '@/store';

import { loadStripe, Stripe, StripeElements, StripeElementsOptions, StripePaymentElement } from '@stripe/stripe-js';
import { PaymentMethodModule } from './paymentMethodModule';
import { Guid } from 'guid-typescript';
import getEnv from '@/util/env';

interface MountPaymentElemetData {
  elementSelector: string;
  accountId: Guid;
}

interface ConfirmPaymentData {
  clientSecret: string;
  returnUrl: string;
  paymentMethodId: string;
}

interface ConfirmPaymentResult {
  error?: string;
  success: boolean;
}

@Module({ dynamic: true, name: 'stripe', store: store })
export default class stripeModule extends VuexModule {
  private stripe: Stripe | null = null;
  private paymentElement: StripePaymentElement | null = null;
  private elements: StripeElements | null = null;
  private paymentElementLoading: boolean = false;

  get PaymentElementLoading(): boolean {
    return this.paymentElementLoading;
  }

  get Stripe(): Stripe | null {
    return this.stripe;
  }

  get PaymentElement(): StripePaymentElement | null {
    return this.paymentElement;
  }

  get Elements(): StripeElements | null {
    return this.elements;
  }

  @Mutation
  SetStripe(val: Stripe | null) {
    this.stripe = val;
  }

  @Mutation
  SetPaymentElement(val: StripePaymentElement | null) {
    this.paymentElement = val;
  }

  @Mutation
  SetElements(val: StripeElements | null) {
    this.elements = val;
  }

  @Mutation
  SetPaymentElementLoading(val: boolean) {
    this.paymentElementLoading = val;
  }

  @Action({ rawError: true })
  async Initialize() {
    try {
      const stripeKey = <string>getEnv('VITE_APP_STRIPE_PUBLISHABLE_KEY');

      const loadedStripe = await loadStripe(stripeKey, {
        locale: 'en',
      });

      if (loadStripe === null) {
        // Inform user?
        return;
      }

      this.SetStripe(loadedStripe);
    } catch (err) {
      console.error(err);
    }
  }

  @Action({ rawError: true })
  async MountPaymentElemet(data: MountPaymentElemetData) {
    if (this.Stripe === null) {
      // Inform user?
      return;
    }

    this.SetPaymentElementLoading(true);

    try {
      const secret = await PaymentMethodModule.FetchPaymentIntentClientSecret(data.accountId);

      if (!secret) {
        // Inform user?
        return;
      }

      this.CreateElements(secret);
      this.CreatePaymentElement();

      this.PaymentElement!.mount(data.elementSelector);
    } catch {
      this.SetPaymentElementLoading(false);
    }
  }

  @Action({ rawError: true })
  CreateElements(secret: string) {
    if (this.Elements !== null) {
      return;
    }

    const options: StripeElementsOptions = {
      clientSecret: secret,
      locale: 'en',
      fonts: [
        {
          family: 'Roboto',
          src: 'url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxK.woff2)',
        },
      ],
      appearance: {
        theme: 'night',
        variables: {
          fontSizeBase: '14px',
          colorDanger: '#ff4305',
          fontFamily: 'Roboto',
          focusBoxShadow: '',
          focusOutline: 'solid 1px #808080',
          colorPrimary: '#cecece',
          colorText: '#cecece',
          colorBackground: '#3e3e3e',
        },
        rules: {
          '.Label': {
            marginBottom: '6px',
            color: '#cecece',
          },
          '.Error': {
            marginTop: '8px',
          },
          '.Input': {
            outline: '1px solid #808080',
            boxShadow: 'none',
            border: 'none',
            padding: '0.5rem',
          },
          '.Input--invalid': {
            outline: '1px solid #eb3021',
            border: 'none',
            boxShadow: 'none',
          },
        },
      },
    };

    const elements = this.Stripe!.elements(options);

    this.SetElements(elements);
  }

  @Action({ rawError: true })
  CreatePaymentElement() {
    this.DestroyPaymentElement();

    const created = this.Elements!.create('payment');

    created.on('ready', () => {
      this.SetPaymentElementLoading(false);
    });

    this.SetPaymentElement(created);
  }

  @Action({ rawError: true })
  DestroyPaymentElement() {
    if (this.PaymentElement !== null) {
      this.PaymentElement.destroy();
      this.SetPaymentElement(null);
    }
  }

  @Action({ rawError: true })
  async ConfirmPayment(data: ConfirmPaymentData): Promise<ConfirmPaymentResult> {
    if (this.stripe === null) {
      return {
        success: false,
        error: 'Could not confirm payment, because stripe was not initialized',
      };
    }

    try {
      const stripeResult = await this.stripe.confirmCardPayment(data.clientSecret, {
        return_url: data.returnUrl,
        payment_method: data.paymentMethodId,
      });

      return {
        success: stripeResult.error === undefined,
        error: stripeResult.error?.message,
      };
    } catch {
      return {
        success: false,
        error: 'Unknown error while confirming payment',
      };
    }
  }
}

export const StripeModule = getModule(stripeModule);
