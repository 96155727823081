<template>
  <div :class="['icon-button', disabled ? 'disabled' : '']" :data-testid="testId" @click="Clicked">
    <i :class="['mr-1', iconName, iconType]" :style="{ 'font-size': iconSize + 'px' }" />
  </div>
</template>

<script lang="ts">
import { Prop, Component, Vue, Emit } from 'vue-property-decorator';

@Component
export default class HeaderIconButton extends Vue {
  @Prop({ default: 14 }) iconSize!: number;
  @Prop({ default: 'fas' }) iconType!: string;
  @Prop({ default: '' }) iconName!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: undefined }) testId?: string;

  @Emit('clicked')
  private Clicked() {}
}
</script>

<style lang="scss" scoped>
.icon-button {
  display: inline-block;
  align-items: center;
  cursor: pointer;
  color: var(--icon-button-text);
  user-select: none;
  margin-left: 7px;

  &:hover {
    opacity: 0.75;
  }
}
</style>
