<template>
  <div
    :class="['flow-layout-child']"
    :style="Style"
    :draggable="!editable ? false : draggable"
    @dragstart="StartDragging"
    @drag="Dragging"
    @dragend="StopDragging"
  >
    <div class="content-container">
      <div :class="['content', editable ? 'editable' : '']">
        <slot />
      </div>

      <div
        v-if="editable"
        :class="['resize-cotainer bottom right']"
        @mouseenter="ResizeHandleMouseEnter"
        @mouseleave="ResizeHandleMouseLeave"
        @mousedown="StartResizing"
      >
        <div class="handle"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SimpleEventDispatcher } from 'strongly-typed-events';
import { Guid } from 'guid-typescript';
import { CellRange } from '@/models/util/FlowLayout';

export interface FlowLayoutChildResizeStartedEvent {
  sender: FlowLayoutChild;
  mouseEvent: MouseEvent;
}

export interface FlowLayoutChildResizeStoppedEvent {
  sender: FlowLayoutChild;
  mouseEvent: MouseEvent;
}

export interface FlowLayoutChildResizeEvent {
  sender: FlowLayoutChild;
  newWidth: number;
  newHeight: number;
}

export interface FlowLayoutChildDragStartedEvent {
  sender: FlowLayoutChild;
  dragEvent: DragEvent;
}

export interface FlowLayoutChildDragStoppedEvent {
  sender: FlowLayoutChild;
  dragEvent: DragEvent;
}

export interface FlowLayoutChildDragEvent {
  sender: FlowLayoutChild;

  fromX: number;
  fromY: number;

  toX: number;
  toY: number;
}

const defaultCellRange = CellRange.FromPoints(
  {
    x: 0,
    y: 0,
  },
  {
    x: 1,
    y: 1,
  },
);

@Component({
  components: {},
  name: 'flow-layout-child',
})
export default class FlowLayoutChild extends Vue {
  @Prop({ default: () => defaultCellRange }) initialCellRange!: CellRange;
  @Prop({ default: () => [1, 1] }) minSize!: number[]; // size is passed as an array containing [width, height]
  @Prop({ default: () => Guid.create().toString() }) id!: string;

  cellRange!: CellRange;
  zIndex: number = 0;

  editable: boolean = false;

  //#region STATE

  private width: number = 0;
  private height: number = 0;

  private previewWidth: number = 0;
  private previewHeight: number = 0;

  private x: number = 0;
  private y: number = 0;

  //#region DRAGGING
  private draggable: boolean = true;
  private isDragging: boolean = false;

  private dragStartX: number = 0;
  private dragStartY: number = 0;

  private onDragStarted = new SimpleEventDispatcher<FlowLayoutChildDragStartedEvent>();
  public get OnDragStarted() {
    return this.onDragStarted.asEvent();
  }

  private onDragStopped = new SimpleEventDispatcher<FlowLayoutChildDragStoppedEvent>();
  public get OnDragStopped() {
    return this.onDragStopped.asEvent();
  }

  private onDrag = new SimpleEventDispatcher<FlowLayoutChildDragEvent>();
  public get OnDrag() {
    return this.onDrag.asEvent();
  }
  //#endregion

  //#region RESIZING
  private isResizing: boolean = false;

  private resizingStartX: number = 0;
  private resizingStartY: number = 0;

  private onResizeStarted = new SimpleEventDispatcher<FlowLayoutChildResizeStartedEvent>();
  public get OnResizeStarted() {
    return this.onResizeStarted.asEvent();
  }

  private onResizeStopped = new SimpleEventDispatcher<FlowLayoutChildResizeStoppedEvent>();
  public get OnResizeStopped() {
    return this.onResizeStopped.asEvent();
  }

  private onResize = new SimpleEventDispatcher<FlowLayoutChildResizeEvent>();
  public get OnResize() {
    return this.onResize.asEvent();
  }
  //#endregion

  private get Width() {
    if (this.isResizing) return this.previewWidth + 'px';

    return this.width + 'px';
  }

  private get Height() {
    if (this.isResizing) return this.previewHeight + 'px';

    return this.height + 'px';
  }

  private get X() {
    return this.x + 'px';
  }

  private get Y() {
    return this.y + 'px';
  }

  private get Color() {
    return 'transparent';

    // const r = Math.random() * 255;
    // const g = Math.random() * 255;
    // const b = Math.random() * 255;
    //
    // const hex = convert.rgb.hex(r, g, b);
    // return '#' + hex;
  }
  //#endregion

  //#region LOGIC
  public SetSize(width: number, height: number) {
    this.width = width;
    this.height = height;
  }

  public SetPosition(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  ResizeHandleMouseEnter() {
    this.draggable = false;
  }

  ResizeHandleMouseLeave() {
    this.draggable = true;
  }

  StartResizing(e: MouseEvent) {
    if (this.isResizing) return;

    this.previewWidth = this.width;
    this.previewHeight = this.height;

    this.resizingStartX = e.clientX;
    this.resizingStartY = e.clientY;

    this.isResizing = true;

    this.onResizeStarted.dispatch({
      mouseEvent: e,
      sender: this,
    });
  }

  ResizingMouseMove(e: MouseEvent) {
    if (!this.isResizing) return;

    if (e.clientX < this.$el.getBoundingClientRect().x) {
      return;
    }
    if (e.clientY < this.$el.getBoundingClientRect().y) {
      return;
    }

    const dx = e.clientX - this.resizingStartX;
    const dy = e.clientY - this.resizingStartY;

    let newPreviewWidth = this.previewWidth + dx;
    let newPreviewHeight = this.previewHeight + dy;

    if (newPreviewWidth < 10) {
      newPreviewWidth = 10;
    }

    if (newPreviewHeight < 10) {
      newPreviewHeight = 10;
    }

    const result = this.onResize.dispatch({
      newWidth: newPreviewWidth,
      newHeight: newPreviewHeight,
      sender: this,
    });

    if (result.propagationStopped) {
      console.log('stopped');

      return;
    }

    this.previewWidth = newPreviewWidth;
    this.previewHeight = newPreviewHeight;

    this.resizingStartX = e.clientX;
    this.resizingStartY = e.clientY;
  }

  StopResizing(e: MouseEvent) {
    if (!this.isResizing) return;

    this.isResizing = false;

    this.onResizeStopped.dispatch({
      mouseEvent: e,
      sender: this,
    });
  }
  //#endregion

  //#region EVENTS
  StartDragging(e: DragEvent) {
    if (this.isDragging || !this.draggable || !this.editable) return;
    var img = new Image();
    img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
    e.dataTransfer?.setDragImage(img, 0, 0);

    this.dragStartX = e.clientX;
    this.dragStartY = e.clientY;

    this.isDragging = true;

    this.onDragStarted.dispatch({
      sender: this,
      dragEvent: e,
    });
  }

  Dragging(e: DragEvent) {
    if (!this.isDragging || !this.draggable || !this.editable) return;

    const dx = e.clientX - this.dragStartX;
    const dy = e.clientY - this.dragStartY;

    this.onDrag.dispatch({
      sender: this,
      fromX: this.dragStartX,
      fromY: this.dragStartY,
      toX: this.dragStartX + dx,
      toY: this.dragStartY + dy,
    });
  }

  StopDragging(e: DragEvent) {
    if (!this.isDragging || !this.draggable || !this.editable) return;

    this.isDragging = false;

    this.onDragStopped.dispatch({
      dragEvent: e,
      sender: this,
    });
  }

  get Style() {
    return {
      width: this.Width,
      height: this.Height,
      left: this.X,
      top: this.Y,
      background: this.Color,
      'z-index': this.isDragging || this.isResizing ? 6 : this.zIndex,
      transition: this.isResizing ? '' : 'all 0.3s cubic-bezier(0.2, 0, 0, 1), z-index 0ms',
    };
  }
  //#endregion

  //#region HOOKS
  mounted() {
    this.cellRange = CellRange.DeepCopyFrom(this.initialCellRange);

    window.addEventListener('mousemove', this.ResizingMouseMove);
    window.addEventListener('mouseup', this.StopResizing);
  }

  beforeDestroy() {
    window.removeEventListener('mousemove', this.ResizingMouseMove);
    window.removeEventListener('mouseup', this.StopResizing);
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.flow-layout-child {
  position: absolute;

  .content-container {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;

    .content {
      width: 100%;
      height: 100%;

      &.editable {
        cursor: all-scroll;
      }
    }

    .resize-cotainer {
      position: absolute;
      background: transparent;
      z-index: 10;
      // background: red;
      padding: 0 4px 4px 0;

      cursor: se-resize;

      .handle {
        height: 12px;
        width: 12px;
        border: 2px solid rgba(125, 125, 125, 0.5);
        border-left-width: 0;
        border-top-width: 0;
      }

      &.bottom {
        bottom: 0;
      }

      &.left {
        left: 0;
      }

      &.right {
        right: 0;
      }

      &.top {
        top: 0;
      }
    }
  }
}

.no-pointer-events {
  pointer-events: none;
}
</style>
