import { Action, Module, VuexModule, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { HubConnection } from '@microsoft/signalr';
import * as hubActions from '../../util/api/hubActions';
import { ResponseGlobalNotification, ResponseGlobalNotifications } from '../../models/responses/ResponseMonolith';
import { GlobalNotification } from '../../models/Entities';
import { UtilModule } from './utilModule';
import { LoginModule } from './loginModule';
import { ErrorModule } from '@/store/modules/errorsModule';
import * as ips from '../../util/api/ips';
import * as routes from '../../util/api/routes';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { ResponseMonolithHelper } from '../../models/responses/ResponseMonolith';
import {
  RequestCreateGlobalNotification,
  RequestDeleteGlobalNotification,
  RequestUpdateGlobalNotification,
} from '../../models/requests/RequestMonolith';
import { ISimpleEvent, SimpleEventDispatcher } from 'strongly-typed-events';

const RELEVANT_GLOBAL_NOTIFICATION_ERROR_IN_SECONDS = 10;
const LOCAL_STORAGE_HIDDEN_GLOBAL_NOTIFICATIONS_IDS_KEY = 'HiddenGlobalNotificationIds';

@Module({ dynamic: true, name: 'globalNotifications', store: store })
export default class globalNotificationsModule extends VuexModule {
  // Если мы супер админ, то будем хранить локальную копию того что в БД
  private globalNotifications: GlobalNotification[] = [];
  private hiddenGlobalNotificationIds: string[] = [];

  get GlobalNotifications(): GlobalNotification[] {
    return this.globalNotifications;
  }

  set GlobalNotification(value: GlobalNotification[]) {
    this.globalNotifications = value;
  }

  get GlobalNotificationsRelevant(): GlobalNotification[] {
    const now = new Date();
    const ret = this.globalNotifications.filter(gn => {
      const start = new Date(Date.parse(gn.startShowing));
      const end = new Date(Date.parse(gn.endShowing));

      start.setSeconds(start.getSeconds() - RELEVANT_GLOBAL_NOTIFICATION_ERROR_IN_SECONDS);
      end.setSeconds(end.getSeconds() + RELEVANT_GLOBAL_NOTIFICATION_ERROR_IN_SECONDS);

      const isHidden = this.hiddenGlobalNotificationIds.find(i => i === gn.id.toString()) != undefined;

      return start <= now && end >= now && !isHidden;
    });

    return ret;
  }

  private onGlobalNotificationAdded = new SimpleEventDispatcher<GlobalNotification>();
  private onGlobalNotificationDeleted = new SimpleEventDispatcher<GlobalNotification>();
  private onGlobalNotificationUpdated = new SimpleEventDispatcher<GlobalNotification>();

  public get OnGlobalNotificationAdded(): ISimpleEvent<GlobalNotification> {
    return this.onGlobalNotificationAdded.asEvent();
  }
  public get OnGlobalNotificationDeleted(): ISimpleEvent<GlobalNotification> {
    return this.onGlobalNotificationDeleted.asEvent();
  }
  public get OnGlobalNotificationUpdated(): ISimpleEvent<GlobalNotification> {
    return this.onGlobalNotificationUpdated.asEvent();
  }

  @Mutation
  AddGlobalNotification(gn: GlobalNotification) {
    this.globalNotifications.push(gn);
  }

  @Mutation
  RemoveGlobalNotification(gn: GlobalNotification) {
    this.globalNotifications.delete(gn);
  }

  @Mutation
  UpdateGlobalNotification(newGn: GlobalNotification) {
    this.globalNotifications = this.globalNotifications.map(gn => {
      if (gn.id.toString() === newGn.id.toString()) {
        gn.startShowing = newGn.startShowing;
        gn.endShowing = newGn.endShowing;
        gn.message = newGn.message;
        gn.userId = newGn.userId;
      }

      return gn;
    });
  }

  @Mutation
  ClearGlobalNotifications() {
    this.globalNotifications = [];
  }

  @Mutation
  SetHiddenGlobalNotificationIds(newVal: string[]) {
    this.hiddenGlobalNotificationIds = newVal;
  }

  @Mutation
  AddHiddenGlobalNotificationIds(id: string) {
    if (this.hiddenGlobalNotificationIds.indexOf(id) != -1) {
      // It's already hidden, bro
      return;
    }

    this.hiddenGlobalNotificationIds.push(id);
  }

  @Action({ rawError: true })
  async LoadHiddenGlobalNotificationIdsFromLocalStorage() {
    const localStorageHidden = localStorage.getItem(LOCAL_STORAGE_HIDDEN_GLOBAL_NOTIFICATIONS_IDS_KEY);

    if (localStorageHidden == null) {
      this.SetHiddenGlobalNotificationIds([]);
      return;
    }

    this.SetHiddenGlobalNotificationIds(JSON.parse(localStorageHidden!) as string[]);
  }

  @Action({ rawError: true })
  async ReadGlobalNotifications() {
    await this.LoadHiddenGlobalNotificationIdsFromLocalStorage();

    let endpoint = routes.UriGlobalNotifications.READ_ALL_RELEVANT;

    if (LoginModule.IsSuperAdmin) {
      endpoint = routes.UriGlobalNotifications.READ_ALL;
    }

    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.GLOBAL_NOTIFICATIONS,
      endpoint,
    );

    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessGet(uri, null);

    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealGlobalNotifications(
        axiosResponse.data.response as ResponseGlobalNotifications,
      );

      this.ClearGlobalNotifications();
      for (const item of response.globalNotifications) {
        this.AddGlobalNotification(item.Map());
      }
    } else {
      ErrorModule.ShowError(message);
    }
  }

  @Action({ rawError: true })
  async CreateGlobalNotification([message, startShowing, endShowing]: [
    string,
    string,
    string,
  ]): Promise<GlobalNotification | null> {
    const request = new RequestCreateGlobalNotification();
    request.message = message;
    request.startShowing = startShowing;
    request.endShowing = endShowing;

    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.GLOBAL_NOTIFICATIONS,
      routes.UriGlobalNotifications.CREATE,
    );

    const res = await UtilModule.SHW.ProcessPost(uri, request);

    let newGN: GlobalNotification | null = null;
    if (res.result === RequestStatus.OK && res.axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealGlobalNotification(
        res.axiosResponse.data.response as ResponseGlobalNotification,
      );

      newGN = response.Map();
    } else {
      ErrorModule.ShowError(res.message);
    }

    return newGN;
  }

  @Action({ rawError: true })
  async UpdateGlobalNotificationOnServer([id, newMessage, newStartShowing, newEndShowing]: [
    string,
    string,
    string,
    string,
  ]): Promise<GlobalNotification | null> {
    const request = new RequestUpdateGlobalNotification();
    request.id = id;
    request.newMessage = newMessage;
    request.newStartShowing = newStartShowing;
    request.newEndShowing = newEndShowing;

    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.GLOBAL_NOTIFICATIONS,
      routes.UriGlobalNotifications.UPDATE,
    );

    const res = await UtilModule.SHW.ProcessPost(uri, request);

    let newGN: GlobalNotification | null = null;
    if (res.result === RequestStatus.OK && res.axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealGlobalNotification(
        res.axiosResponse.data.response as ResponseGlobalNotification,
      );

      newGN = response.Map();
    } else {
      ErrorModule.ShowError(res.message);
    }

    return newGN;
  }

  @Action({ rawError: true })
  async DeleteGlobalNotificationOnServer(id: string): Promise<GlobalNotification | null> {
    const request = new RequestDeleteGlobalNotification();
    request.id = id;

    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.GLOBAL_NOTIFICATIONS,
      routes.UriGlobalNotifications.DELETE,
    );

    const res = await UtilModule.SHW.ProcessPost(uri, request);

    let newGN: GlobalNotification | null = null;
    if (res.result === RequestStatus.OK && res.axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealGlobalNotification(
        res.axiosResponse.data.response as ResponseGlobalNotification,
      );

      newGN = response.Map();
    } else {
      ErrorModule.ShowError(res.message);
    }

    return newGN;
  }

  @Action({ rawError: true })
  GlobalNotificationsSubscribe(hubConnection: HubConnection) {
    hubConnection.on(hubActions.RECEIVE_CREATE_GLOBAL_NOTIFICATION, async (response: ResponseGlobalNotification) => {
      // A global message has been created. Let's see if we should display it
      response = ResponseMonolithHelper.createRealGlobalNotification(response as ResponseGlobalNotification);

      const gnCreated = response.Map();
      const gnFound = this.globalNotifications.firstOrDefault(gn => gn.id.equals(gnCreated.id));

      if (gnFound == null) {
        // Add it
        this.AddGlobalNotification(gnCreated);
        this.onGlobalNotificationAdded.dispatch(gnCreated);
      } else {
        // It's already in, skip
      }
    });

    hubConnection.on(hubActions.RECEIVE_DELETE_GLOBAL_NOTIFICATION, async (response: ResponseGlobalNotification) => {
      // A global message has been deleted. Let's delete it and stop showing it
      response = ResponseMonolithHelper.createRealGlobalNotification(response as ResponseGlobalNotification);

      const gnDeleted = response.Map();
      const gnFound = this.globalNotifications.firstOrDefault(gn => gn.id.equals(gnDeleted.id));

      if (gnFound != null) {
        // We have it localy, remove it
        this.RemoveGlobalNotification(gnFound);
        this.onGlobalNotificationDeleted.dispatch(gnFound);
      } else {
        // Nothing localy, skip
      }
    });

    hubConnection.on(hubActions.RECEIVE_UPDATE_GLOBAL_NOTIFICATION, async (response: ResponseGlobalNotification) => {
      // A global message has been updated. Let's update it localy
      response = ResponseMonolithHelper.createRealGlobalNotification(response as ResponseGlobalNotification);

      const gnUpdated = response.Map();
      const gnFound = this.globalNotifications.firstOrDefault(gn => gn.id.equals(gnUpdated.id));

      if (gnFound != null) {
        // Update localy
        this.UpdateGlobalNotification(gnUpdated);
        this.onGlobalNotificationUpdated.dispatch(gnUpdated);
      } else {
        // Skip
      }
    });

    hubConnection.on(
      hubActions.RECEIVE_BECAME_RELEVANT_GLOBAL_NOTIFICATION,
      async (response: ResponseGlobalNotification) => {
        // A global message has become relevant, let's add it, if it doesn't exist
        response = ResponseMonolithHelper.createRealGlobalNotification(response as ResponseGlobalNotification);

        const gnRelevant = response.Map();
        const gnFound = this.globalNotifications.firstOrDefault(gn => gn.id.equals(gnRelevant.id));

        if (gnFound == null) {
          // Add localy
          this.AddGlobalNotification(gnRelevant);
        } else {
          // Update localy to refresh state
          this.UpdateGlobalNotification(gnRelevant);
        }
      },
    );
  }

  @Action({ rawError: true })
  async HideGlobalMessage(toHide: GlobalNotification) {
    const gnFound = this.globalNotifications.firstOrDefault(gn => gn.id.equals(toHide.id));

    if (gnFound == null) {
      return;
    }

    this.AddHiddenGlobalNotificationIds(gnFound.id.toString());
    localStorage.setItem(
      LOCAL_STORAGE_HIDDEN_GLOBAL_NOTIFICATIONS_IDS_KEY,
      JSON.stringify(this.hiddenGlobalNotificationIds),
    );
  }
}

export const GlobalNotificationsModule = getModule(globalNotificationsModule);
