<template>
  <div :class="['text-area-input', disabled ? 'disabled' : '']">
    <span
      v-if="label !== undefined"
      :style="labelWidth !== undefined ? { 'min-width': labelWidth + 'px' } : {}"
      class="label mr-2"
      >{{ label }}</span
    >
    <textarea
      v-if="!readonly && textValue !== undefined"
      v-model="textValue"
      :class="['value', fillWidth ? 'flex-grow-1' : '', invalid ? 'error-border' : '']"
      :placeholder="placeholder"
      :data-testid="testId"
    />
    <span
      v-if="readonly && textValue !== undefined"
      :class="['value message-text', fillWidth ? 'flex-grow-1' : '', IsClickable ? 'clickable' : '']"
      @click="Clicked"
    >
      {{ textValue }}
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, PropSync, Emit } from 'vue-property-decorator';

@Component
export default class TextAreaInput extends Vue {
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: undefined }) label?: string;
  @Prop({ default: false }) invalid!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: undefined }) placeholder?: string;
  @Prop({ default: undefined }) labelWidth?: number;
  @Prop({ default: true }) fillWidth!: boolean;
  @Prop({ default: undefined }) testId?: string;

  @PropSync('value', { type: String, default: undefined })
  textValue?: string;

  @Emit('clicked')
  Clicked() {}

  private get IsClickable() {
    return this.$listeners.clicked != undefined;
  }
}
</script>

<style lang="scss" scoped>
.text-area-input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 28px;

  span {
    display: flex;
    font-size: 14px;
    line-height: 16px;

    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    white-space: nowrap;
    color: var(--editor-field-name);

    &.value {
      color: var(--editor-field-value);
      margin-left: 11px;
    }

    &.clickable {
      color: var(--editor-field-value-clickable);
      cursor: pointer;
      &:hover {
        opacity: 0.75;
      }
    }
  }

  textarea {
    background: none;
    border-radius: 6px;
    border: 1px solid var(--editor-field-border);
    height: 29px;
    color: var(--editor-field-value);
    max-height: 100px;
    min-height: 29px;
    overflow-y: auto;
    font-size: 14px;
    padding: 3px 10px;
    letter-spacing: 0.04em;
  }

  .error-border {
    border: 1px solid var(--main-orange);
  }
}
</style>
