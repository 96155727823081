import { SortMode } from '@/util/ComponentHelper';
import { Countries, States } from '../responses/Countries';
import { LicenseType } from '../enums/LicenseType';
import { PaymentType } from '@/models/enums/PaymentType';
import { RecurringIntervalKind } from '@/models/enums/RecurringIntervalKind';

export enum InvoiceSortBy {
  BY_NUMBER,
  BY_DATE,
  BY_STATUS,
  BY_SUM,
}

//#region ACCOUNT
export class RequestReadAccountById {
  accountId!: string;
}

export class RequestUpdateAccountById {
  accountId!: string;
  name!: string;
  address!: string;
  country!: Countries;
  city!: string;
  state!: States | null;
  postalCode!: string;
  email!: string;
  taxId!: string | null;
}
//#endregion

//#region INVOICE
export class RequestReadAllByAccountIdInvoice {
  accountId!: string;
}
export class RequestReadPagedByAccountIdInvoice {
  accountId!: string;
  sortBy!: InvoiceSortBy | null;
  sortMode!: SortMode | null;
  pageSize!: number;
  page!: number;
}
export class RequestReadPaymentIntentSecretInvoice {
  invoiceId!: string;
}
//#endregion

//#region PAYMENT METHOD
export class RequestDeleteByIdPaymentMethod {
  id!: string;
}

export class RequestMakeDefaultPaymentMethod {
  paymentMethodId!: string;
}

export class RequestPrepareCreatePaymentMethod {
  accountId!: string;
}

export class RequestReadAllByAccountIdPaymentMethod {
  accountId!: string;
}
//#endregion

//#region PRODUCT
export class RequestReadAllProduct {}

export class RequestCreateProduct {
  licenseType!: LicenseType;
  name!: string;
  description!: string;
  isPublic!: boolean;
  baseProductId!: string | null;
  shortName!: string;
}

export class RequestMakeOutdatedProduct {
  productId!: string;
}

export class RequestUpdateProduct {
  id!: string;
  name!: string;
  description!: string;
  outdated!: boolean;
  isPublic!: boolean;
}
//#endregion

//#region PRODUCT PRICE
export class RequestReadAllProductPrice {}

export class RequestCreateProductPrice {
  productId!: string;
  name!: string;
  paymentType!: PaymentType;
  recurringInterval!: RecurringIntervalKind | null;
  recurringIntervalCount!: number | null;
  currency!: string;
  price!: number;
  isPublic!: boolean;
  metadata!: string;
}

export class RequestMakeOutdatedProductPrice {
  productPriceId!: string;
}

export class RequestUpdateProductPrice {
  id!: string;
  name!: string;
  outdated!: boolean;
  metadata!: string;
}
//#endregion

//#region PURCHASED ITEM
export class RequestReadAllByAccountIdPurchasedItem {
  accountId!: string;
}

export class RequestAttachPurchasedItem {
  id!: string;
  attachToId!: string;
}

export class RequestDetachPurchasedItem {
  id!: string;
  detachFromId!: string;
}

export class RequestAdminPurchaseItem {
  productPriceId!: string;
  addonProductPrices!: string[];
  auraKey!: string | null;
}

export class RequestAdminPurchaseItems {
  accountId!: string;
  items!: RequestAdminPurchaseItem[];
}

export class RequestPurchaseItem {
  productPriceId!: string;
  quantity!: number;
}

export class RequestPurchaseItems {
  accountId!: string;
  items!: RequestPurchaseItem[];
}

export class RequestDownloadOfflineActivationResponsePurchasedItem {
  itemId!: string;
}
//#endregion

//#region PURCHASED SUBSCRIPTION
export class RequestCancelPurchasedSubscription {
  purchasedSubscriptionId!: string;
}

export class RequestCancelPendingUpdatePurchasedSubscription {
  purchasedSubscriptionId!: string;
}

export class RequestScheduleCancellationPurchasedSubscription {
  purchasedSubscriptionId!: string;
}

export class RequestReactivatePurchasedSubscription {
  purchasedSubscriptionId!: string;
}

export class RequestPreviewPurchasedSubscriptionPriceChanges {
  accountId!: string;
  existingSubscriptionId!: string;
  newProductPriceId!: string | null;
  newQuantity!: number | null;
}

export class RequestSubscribeToSubscription {
  accountId!: string;
  productPriceId!: string;
  quantity!: number;
}

export class RequestUpdatePurchasedSubscription {
  accountId!: string;
  existingSubscriptionId!: string;
  newProductPriceId!: string | null;
  newQuantity!: number | null;
  prorationDate!: string | null;
}

export class RequestReadAllByAccountIdPurchasedSubscription {
  accountId!: string;
}
//#endregion

//#region PENDING SUBSCRIPTION UPDATE
export class RequestReadAllByAccountIdPendingSubscriptionUpdate {
  accountId!: string;
}
//#endregion

//#region TAX RATE
export class RequestReadTaxRateByAccountId {
  accountId!: string;
}
//#endregion
