import { LoginSession, User, Role, Company } from '../Entities';
import { TypeHelper } from '@/util/TypeHelper';
import { GuidHelper } from '@/util/GuidHelper';
import { ResponseLoginSession, ResponseUser, ResponseRole, ResponseCompany } from './ResponseIdentity';
import { Guid } from 'guid-typescript';

declare module './ResponseIdentity' {
  interface ResponseLoginSession {
    Map(): LoginSession;
  }
  interface ResponseUser {
    Map(roles: Role[]): User;
  }
  interface ResponseRole {
    Map(): Role;
  }
  interface ResponseCompany {
    Map(): Company;
  }
}
ResponseLoginSession.prototype.Map = function (): LoginSession {
  const loginSession = new LoginSession();
  loginSession.Id = Guid.parse(this.id.toString());
  loginSession.UserId = Guid.parse(this.userId.toString());
  loginSession.IP = this.deviceIP;
  loginSession.LastSeen = TypeHelper.StringToDate(this.lastSeen);
  return loginSession;
};
ResponseUser.prototype.Map = function (roles: Role[]): User {
  const user = new User();
  user.Id = Guid.parse(this.id.toString());
  user.CompanyId = Guid.parse(this.idCompany.toString());
  user.Phone = this.phone;
  user.Email = this.email;
  user.FirstName = this.firstName;
  user.SecondName = this.secondName;
  user.Roles = roles.filter(a => GuidHelper.includes(this.idsRole, a.Id));
  return user;
};

ResponseRole.prototype.Map = function (): Role {
  const role = new Role();
  role.Id = Guid.parse(this.id.toString());
  role.Name = this.name;
  role.IsAdmin = this.isAdmin;
  role.IsSuperAdmin = this.isSuperAdmin;
  role.IsBillingAccountAdmin = this.isBillingAccountAdmin;
  role.IsBillingAccountOwner = this.isBillingAccountOwner;
  role.IsConnectUser = this.isConnectUser;
  return role;
};

ResponseCompany.prototype.Map = function (): Company {
  const company = new Company();
  company.Id = Guid.parse(this.id.toString());
  company.Name = this.name;
  company.Address = this.address;
  company.Description = this.description;
  company.Country = this.country;
  company.Email = this.email;
  return company;
};
