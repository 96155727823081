<template>
  <div :class="['action-card', isCollapsed ? 'collapsed' : '']" :data-testid="testId">
    <div
      v-if="hasHeader"
      :class="['header', isCollapsed ? 'mb-1' : 'mb-2', noPadding ? 'px-0 py-0' : '']"
      :style="{
        'font-size': headerFontSize + 'px',
        'margin-top': headerTopMargin + 'px',
        height: HeaderHeight,
      }"
    >
      <div
        v-if="!HasHeaderStartSlot"
        :class="['header-text', headerMarginClass]"
        :style="{ transform: `translateY(${headerTextVerticalOffset}px)`, 'align-self': headerAlignment }"
      >
        <span>{{ headerText }}</span>
        <span v-if="headerSpecialText !== undefined" class="special-text ml-3">
          {{ headerSpecialText }}
        </span>
      </div>

      <div v-else class="header-start">
        <slot name="headerStart" />
      </div>

      <div v-if="HasHeaderCenterSlot" class="header-center">
        <slot name="headerCenter" />
      </div>

      <div class="header-button-container h-100">
        <div v-if="headerButtonDatas.length > 0" class="main-button">
          <v-popover
            v-for="data in headerButtonDatas"
            :key="data.name"
            class="action-button-container"
            trigger="manual"
            placement="bottom"
            :open="ShowHeaderButtonPopover(data.name)"
            :disabled="!HasButtonPopoverSlot(data.name)"
            :autoHide="true"
            @auto-hide="AutoHidden(data.name)"
          >
            <div
              class="action-button"
              :data-testid="getTestId(data.testId ?? 'actionButton')"
              @click="OnHeaderButtonClick(data.name)"
            >
              <i :class="[data.iconType, data.iconName]"></i>
              <span v-if="data.text !== ''" class="mx-2">{{ data.text }}</span>
              <slot :name="data.name"> </slot>
            </div>

            <div slot="popover">
              <slot :name="data.name + '-header-button-popover'" />
            </div>
          </v-popover>
        </div>
        <div
          v-if="collapsible"
          class="collapse-button ml-3"
          :style="{ 'align-self': headerAlignment }"
          :data-testid="getTestId('collapseButton')"
          @click="OnCollapseButtonClicked"
        >
          <SquareCaretDown v-if="isCollapsed" />
          <SquareCaretUp v-else />
        </div>
        <div
          v-if="closable"
          class="collapse-button ml-3"
          :style="{ 'align-self': headerAlignment }"
          :data-testid="getTestId('closeButton')"
          @click="OnCloseButtonClicked"
        >
          <i class="far fa-times" />
        </div>
      </div>
    </div>

    <div
      :class="[
        'content thin-scroll',
        isCollapsed ? 'hidden px-0 py-0 pb-1' : '',
        additionalBottomPadding && !noPadding ? 'add-pading-bottom' : '',
        noPadding ? 'px-0 py-0' : '',
        fillContentVertically ? 'h-100' : '',
      ]"
      :style="{
        'overflow-y': overflow ? 'auto' : 'hidden',
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { VPopover } from 'v-tooltip';
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

import SquareCaretUp from '@/components/icons/SquareCaretUp.vue';
import SquareCaretDown from '@/components/icons/SquareCaretDown.vue';
import { createTestId } from '@/util/tests';

export interface HeaderButtonData {
  showPopover?: boolean;
  iconType: string;
  iconName: string;
  text: string;
  name: string;
  testId?: string;
}

@Component({
  components: {
    SquareCaretDown,
    SquareCaretUp,
    VPopover: VPopover,
  },
  name: 'action-card',
})
export default class ActionCard extends Vue {
  @Prop({ default: '' }) headerText!: string;
  @Prop() headerSpecialText!: string | undefined;
  @Prop({ default: 16 }) headerFontSize!: number;
  @Prop({ default: '' }) headerMarginClass!: string;
  @Prop({ default: 4 }) headerTextVerticalOffset!: number;
  @Prop({ default: true }) collapsible!: boolean;
  @Prop({ default: false }) closable!: boolean;
  @Prop({ default: true }) hasHeader!: boolean;
  @Prop({ default: true }) additionalBottomPadding!: boolean;
  @Prop({ default: false }) noPadding!: boolean;
  @Prop({ default: 0 }) headerTopMargin!: number;
  @Prop({ default: 32 }) headerHeight!: number | 'auto';
  @Prop({ default: 'center' }) headerAlignment!: 'flex-start' | 'flex-end' | 'center';
  @Prop({ default: undefined }) testId?: string;
  @Prop({ default: false }) initiallyCollapsed!: boolean;
  @Prop({ default: () => [] }) headerButtonDatas!: HeaderButtonData[];
  @Prop({ default: false }) fillContentVertically!: boolean;
  @Prop({ default: true }) overflow!: boolean;

  private async created() {
    if (this.initiallyCollapsed) {
      this.isCollapsed = true;
    }
  }

  private isCollapsed = false;

  private get HeaderHeight() {
    if (this.headerHeight == 'auto') {
      return 'auto';
    } else {
      return this.headerHeight + 'px';
    }
  }

  private get HasHeaderStartSlot() {
    return this.$slots.headerStart != undefined;
  }

  private get HasHeaderCenterSlot() {
    return this.$slots.headerCenter != undefined;
  }

  private HasButtonPopoverSlot(name: string) {
    return this.$slots[name + '-header-button-popover'] != undefined;
  }

  private ShowHeaderButtonPopover(name: string) {
    const found = this.headerButtonDatas.firstOrDefault(a => a.name == name);

    if (found == null) return false;

    return found.showPopover;
  }

  @Emit('collapse-toggled')
  private toggleIsCollapsed() {
    this.isCollapsed = !this.isCollapsed;
    return this.isCollapsed;
  }

  private async OnHeaderButtonClick(name: string) {
    // this.$emit("header-button-clicked");
    this.$emit(name + '-header-button-clicked');
  }

  private async AutoHidden(name: string) {
    this.$emit(name + '-header-button-popover-autohidden');
  }

  private async OnCollapseButtonClicked() {
    this.toggleIsCollapsed();
  }

  @Emit('closed')
  private async OnCloseButtonClicked() {}

  private getTestId(elementId: string) {
    return createTestId(this.testId, elementId);
  }
}
</script>

<style lang="scss" scoped>
.action-card {
  display: flex;
  flex-direction: column;
  background: var(--action-card-background);
  border: 1px solid var(--action-card-border);
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;

  &.collapsed {
    padding-bottom: 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--action-card-header-text);
    flex-shrink: 0;
    white-space: nowrap;

    padding: 0 16px;

    .header-start {
      place-self: self-end;
    }

    .header-center {
      flex: 1;
      place-self: self-end;
    }

    .header-button-container {
      display: flex;
      flex-direction: row;
      user-select: none;

      .main-button {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        min-width: 248px;
        background: var(--action-card-header-button-background);
        color: var(--action-card-header-button-text);
        border-radius: 0 5px 0 40px;
        padding: 12px 12px 12px 42px;

        .action-button-container:not(:last-child) {
          margin-right: 6px;
        }

        .action-button-container:last-child {
          span {
            margin-right: 0 !important;
          }
        }

        .action-button {
          cursor: pointer;
          &:hover {
            color: var(--action-card-header-button-text-hover);
          }
        }

        i {
          color: var(--action-card-header-button-icon) !important;
          transform: translateY(1px);
        }

        span {
          font-size: 14px;
        }
      }

      .collapse-button {
        display: flex;
        margin-top: 4px;

        i {
          color: var(--action-card-collapsse-button);
          font-size: 18px;
        }

        cursor: pointer;

        &:hover {
          opacity: 0.75;
        }
      }
    }

    span {
      line-height: 19px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    position: relative;

    padding: 4px 16px 8px 16px;

    &.add-pading-bottom {
      padding: 4px 16px 18px 16px;
    }

    &.hidden {
      height: 0px;
      visibility: hidden;
    }
  }
}

// EXPIRIMENTAL !
.dark-theme {
  .action-card {
    background: linear-gradient(#dadada, #e9e9e9);
    border: 1px solid #b5b5b5;

    .header span {
      color: rgb(31, 31, 31);
    }

    .header .main-button {
      background-color: #ededed;
    }

    .header .header-button-container .collapse-button i {
      color: rgb(31, 31, 31);
    }
  }
}
</style>
