import ComponentHelper from '@/util/ComponentHelper';
import { UserJob } from '../Entities';

export const SortUserJobByFileDESC: (a: UserJob, b: UserJob) => 1 | -1 = (a: UserJob, b: UserJob) => {
  const sourceACodeFileName =
    a.Job.LocalFilename != null ? a.Job.LocalFilename : a.Source != null ? a.Source.CodeFileName : '';
  const sourceBCodeFileName =
    b.Job.LocalFilename != null ? b.Job.LocalFilename : b.Source != null ? b.Source.CodeFileName : '';

  return sourceACodeFileName > sourceBCodeFileName! ? -1 : 1;
};
export const SortUserJobByFileASC: (a: UserJob, b: UserJob) => 1 | -1 = (a: UserJob, b: UserJob) => {
  const sourceACodeFileName =
    a.Job.LocalFilename != null ? a.Job.LocalFilename : a.Source != null ? a.Source.CodeFileName : '';
  const sourceBCodeFileName =
    b.Job.LocalFilename != null ? b.Job.LocalFilename : b.Source != null ? b.Source.CodeFileName : '';

  return sourceACodeFileName! > sourceBCodeFileName! ? 1 : -1;
};

export const SortUserJobByPrinterDESC: (a: UserJob, b: UserJob) => 1 | -1 = (a: UserJob, b: UserJob) => {
  return a.Printer.Name > b.Printer.Name ? -1 : 1;
};
export const SortUserJobByPrinterASC: (a: UserJob, b: UserJob) => 1 | -1 = (a: UserJob, b: UserJob) => {
  return a.Printer.Name > b.Printer.Name ? 1 : -1;
};

export const SortUserJobByDateStartDESC: (a: UserJob, b: UserJob) => 1 | -1 = (a: UserJob, b: UserJob) => {
  const startA = a.Job.ActualStartTime == null ? -1 : a.Job.ActualStartTime.getTime();
  const startB = b.Job.ActualStartTime == null ? -1 : b.Job.ActualStartTime.getTime();

  return startA > startB ? -1 : 1;
};
export const SortUserJobByDateStartASC: (a: UserJob, b: UserJob) => 1 | -1 = (a: UserJob, b: UserJob) => {
  const startA = a.Job.ActualStartTime == null ? -1 : a.Job.ActualStartTime.getTime();
  const startB = b.Job.ActualStartTime == null ? -1 : b.Job.ActualStartTime.getTime();

  return startA > startB ? 1 : -1;
};

export const SortUserJobByDateCreatedDESC: (a: UserJob, b: UserJob) => 1 | -1 = (a: UserJob, b: UserJob) => {
  const startA = a.Job.CreationDateTime == null ? -1 : a.Job.CreationDateTime.getTime();
  const startB = b.Job.CreationDateTime == null ? -1 : b.Job.CreationDateTime.getTime();

  return startA > startB ? -1 : 1;
};
export const SortUserJobByDateCreatedASC: (a: UserJob, b: UserJob) => 1 | -1 = (a: UserJob, b: UserJob) => {
  const startA = a.Job.CreationDateTime == null ? -1 : a.Job.CreationDateTime.getTime();
  const startB = b.Job.CreationDateTime == null ? -1 : b.Job.CreationDateTime.getTime();

  return startA > startB ? 1 : -1;
};

export const SortUserJobByProgressDESC: (a: UserJob, b: UserJob) => 1 | -1 = (a: UserJob, b: UserJob) => {
  const progressA = a.Progress == null ? -1 : a.Progress;
  const progressB = b.Progress == null ? -1 : b.Progress;

  return progressA > progressB ? -1 : 1;
};
export const SortUserJobByProgressASC: (a: UserJob, b: UserJob) => 1 | -1 = (a: UserJob, b: UserJob) => {
  const progressA = a.Progress == null ? -1 : a.Progress;
  const progressB = b.Progress == null ? -1 : b.Progress;

  return progressA > progressB ? 1 : -1;
};

export const SortUserJobByOrderDESC: (a: UserJob, b: UserJob) => 1 | -1 = (a: UserJob, b: UserJob) => {
  const orderA = a.Job.Order == null ? -1 : a.Job.Order;
  const orderB = b.Job.Order == null ? -1 : b.Job.Order;

  return orderA > orderB ? -1 : 1;
};
export const SortUserJobByOrderASC: (a: UserJob, b: UserJob) => 1 | -1 = (a: UserJob, b: UserJob) => {
  const orderA = a.Job.Order == null ? -1 : a.Job.Order;
  const orderB = b.Job.Order == null ? -1 : b.Job.Order;

  return orderA > orderB ? 1 : -1;
};

export const SortUserJobByAuthorDESC: (a: UserJob, b: UserJob) => 1 | -1 = (a: UserJob, b: UserJob) => {
  const authorA = a.Author == null ? '' : ComponentHelper.GetFullname(a.Author);
  const authorB = b.Author == null ? '' : ComponentHelper.GetFullname(b.Author);

  return authorA > authorB ? -1 : 1;
};
export const SortUserJobByAuthorASC: (a: UserJob, b: UserJob) => 1 | -1 = (a: UserJob, b: UserJob) => {
  const authorA = a.Author == null ? '' : ComponentHelper.GetFullname(a.Author);
  const authorB = b.Author == null ? '' : ComponentHelper.GetFullname(b.Author);

  return authorA > authorB ? 1 : -1;
};

export const SortUserJobByIdDESC: (a: UserJob, b: UserJob) => 1 | -1 = (a: UserJob, b: UserJob) => {
  return a.Id.toString() > b.Id.toString() ? -1 : 1;
};
export const SortUserJobByIdASC: (a: UserJob, b: UserJob) => 1 | -1 = (a: UserJob, b: UserJob) => {
  return a.Id.toString() > b.Id.toString() ? 1 : -1;
};
