import { Guid } from 'guid-typescript';

export class GuidHelper {
  static includes(array: Guid[], toCheck: Guid): boolean {
    for (const item of array) {
      if (item.toString() === toCheck.toString()) return true;
    }
    return false;
  }
  static distinct(array: Guid[]): Guid[] {
    const res: Guid[] = [];
    for (const item of array) {
      if (this.includes(res, item)) continue;
      res.push(item);
    }
    return res;
  }
  static except(from: Guid[], clip: Guid[]): Guid[] {
    const res: Guid[] = [];
    for (const item of from) {
      if (this.includes(clip, item)) continue;
      res.push(item);
    }
    return res;
  }
  static delete(from: Guid[], item: Guid): void {
    for (let i = 0; i < from.length; i++) {
      if (from[i].equals(item)) {
        from.splice(i, 1);
        return;
      }
    }
  }
  static equalsWithNull(one: Guid | null, two: Guid | null): boolean {
    if (one == null && two == null) return true;
    if ((one == null && two != null) || (one != null && two == null)) return false;
    return one!.equals(two!);
  }
}
