<template>
  <div :class="['file-input mb-2', disabled ? 'disabled' : '']">
    <span
      v-if="label !== undefined"
      :style="labelWidth !== undefined ? { 'min-width': labelWidth + 'px' } : {}"
      class="label mr-2"
      >{{ label }}</span
    >

    <span
      v-if="readonly"
      :class="['value message-text', IsClickable ? 'clickable' : '']"
      style="min-width: auto"
      :data-testid="testId"
      @click="Clicked"
    >
      {{ HasFilename ? fileNameValue : '—' }}
    </span>

    <span v-if="HasSize && readonly && downloadable" class="value mx-1 downloadFile" @click="DownloadFile">
      <i v-if="readonly && downloadable" class="mx-1 far fa-arrow-to-bottom"></i>
      {{ ReadableSize }}
    </span>

    <div
      v-if="!readonly"
      :class="['input-group mr-2', invalid ? 'error-border' : '', isDraggingFiles ? 'dragging-files' : '']"
    >
      <input
        ref="inputFile"
        type="file"
        :accept="extension"
        style="display: none"
        @change="OnFileChanged($event.target.files)"
      />
      <input
        v-model="fileNameValue"
        readonly
        :class="['mr-2']"
        type="text"
        :data-testid="getTestId('dropInput')"
        @dragover.prevent="OnDragOver"
        @dragleave.prevent="OnDragLeave"
        @drop.prevent="OnDrop"
      />
      <span :data-testid="getTestId('uploadFile')" class="i" @click="UploadFile"
        ><i class="fal fa-cloud-upload"></i
      ></span>
    </div>
    <div v-if="!readonly" :data-testid="getTestId('resetFile')" class="i" @click="ResetFile">
      <i class="far fa-times"></i>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, PropSync, Emit } from 'vue-property-decorator';
import { POSITION } from 'vue-toastification';

import { toast } from '@/main';
import ComponentHelper from '@/util/ComponentHelper';
import { createTestId } from '@/util/tests';

@Component
export default class FileInput extends Vue {
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: undefined }) label?: string;
  @Prop({ default: false }) invalid!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: undefined }) labelWidth?: number;
  @Prop({ default: false }) downloadable!: boolean;
  @Prop({ default: '.txt' }) extension!: string;
  @Prop({ default: true }) fillWidth!: boolean;
  @Prop({ default: false }) enableFileDrag!: boolean;
  @Prop({ default: 1 }) maxDraggedFilesCount!: number;
  @Prop({ default: undefined }) testId?: string;

  @PropSync('fileName', { type: String, default: null })
  fileNameValue!: string | null;

  @PropSync('fileSize', { type: Number, default: null })
  fileSizeValue!: number | null;

  private isDraggingFiles: boolean = false;
  private filesDraggedAreValid: boolean = true;

  private get HasFilename() {
    return this.fileNameValue != null && this.fileNameValue != '';
  }

  private get HasSize() {
    return this.fileSizeValue != null && this.fileSizeValue != 0;
  }

  private get IsClickable() {
    return this.$listeners.clicked != undefined;
  }

  private get ReadableSize() {
    if (!this.HasSize) return '—';

    return ComponentHelper.GetReadbaleBytesSize(this.fileSizeValue!);
  }

  private getTestId(elementId: string) {
    return createTestId(this.testId, elementId);
  }

  UploadFile() {
    (this.$refs.inputFile as HTMLElement).click();
  }

  OnDragOver() {
    if (!this.enableFileDrag || this.readonly) {
      this.isDraggingFiles = false;
      return;
    }

    this.isDraggingFiles = true;
  }

  OnDragLeave() {
    if (!this.enableFileDrag || this.readonly || !this.isDraggingFiles) {
      this.isDraggingFiles = false;
      return;
    }

    this.isDraggingFiles = false;
  }

  OnDrop(e: DragEvent) {
    if (!this.enableFileDrag || this.readonly || !this.isDraggingFiles || e.dataTransfer == undefined) {
      this.isDraggingFiles = false;
      return;
    }

    if (e.dataTransfer.files.length > this.maxDraggedFilesCount) {
      toast.error(
        `Maximum amout of files is ${this.maxDraggedFilesCount}, you're trying to upload ${e.dataTransfer.files.length}`,
        {
          position: POSITION.BOTTOM_RIGHT,
        },
      );
      this.isDraggingFiles = false;
      return;
    }

    if (!this.ValidateFileExtensions(e.dataTransfer.items)) {
      toast.error(`Only ${this.extension} files are supported`, {
        position: POSITION.BOTTOM_RIGHT,
      });
      this.isDraggingFiles = false;
      return;
    }

    const files: File[] = [];

    for (let i = 0; i < e.dataTransfer.files.length; ++i) {
      const file = e.dataTransfer.files.item(i);

      if (file == null) continue;

      files.push(file);
    }

    this.OnFileChanged(files);

    this.isDraggingFiles = false;
  }

  ValidateFileExtensions(DTIL: DataTransferItemList) {
    for (let i = 0; i < DTIL.length; ++i) {
      const item = DTIL[i];
      const file = item.getAsFile();

      if (file == null || !file.name.endsWith(this.extension)) {
        return false;
      }
    }

    return true;
  }

  @Emit('download-file')
  DownloadFile() {}

  @Emit('reset-file')
  ResetFile() {
    (this.$refs.inputFile as HTMLInputElement).value = '';
  }

  @Emit('file-changed')
  private OnFileChanged(files: File[]) {
    return files;
  }

  @Emit('clicked')
  Clicked() {}
}
</script>

<style lang="scss" scoped>
.file-input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 28px;
  position: relative;

  span {
    font-size: 14px;
    line-height: 16px;

    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    white-space: nowrap;
    color: var(--editor-field-name);

    &.value {
      color: var(--editor-field-value);
      margin-left: 11px;
    }

    &.clickable {
      color: var(--editor-field-value-clickable);
      cursor: pointer;
      &:hover {
        opacity: 0.75;
      }
    }
  }

  input {
    background: none;
    border-radius: 6px;
    border: 1px solid var(--editor-field-border);
    height: 28px;
    color: var(--editor-field-value);
    font-size: 14px;
  }

  .input-group {
    background: none;
    border-radius: 6px;
    border: 1px solid var(--editor-field-border);
    height: 28px;
    color: var(--editor-field-value);
    font-size: 14px;
    padding: 0 10px;
    align-items: center;

    input {
      border: none;
      padding: 0;
      display: flex;
      flex: 1;
      transform: translateY(-1px);
    }

    &.dragging-files {
      background: rgba(0, 0, 0, 0.15);
    }
  }

  .i {
    cursor: pointer;
    color: var(--editor-field-name);

    &:hover {
      opacity: 0.75;
    }
  }

  .error-border {
    border: 1px solid var(--main-orange);
  }

  .downloadFile {
    cursor: pointer;
    &:hover {
      color: #fff;
    }
  }
}
</style>
