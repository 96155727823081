<template>
  <div class="d-flex align-items-center">
    <form id="forgotPasswordForm" class="w-100" autocomplete="off" @submit.prevent="Try">
      <text-box-alt
        v-if="ShowUserName"
        id="username"
        key="username"
        v-model="UserName"
        class="w-100"
        testId="reset.emailInput"
        :placeholder="UserNamePlaceholder"
        :hasError="UsernameError"
        autocomplete="off"
      />
      <text-box-alt
        v-if="ShowCode"
        id="code"
        key="code"
        v-model="Code"
        class="w-100"
        testId="reset.codeInput"
        :placeholder="CodePlaceholder"
        :hasError="CodeError"
        autocomplete="off"
      />
      <password-text-box
        v-if="ShowPassword"
        id="password"
        key="password"
        v-model="Password"
        class="w-100"
        testId="reset.passwordInput"
        :placeholder="PasswordPlaceholder"
        :hasError="PasswordError"
      />
      <password-text-box
        v-if="ShowPassword"
        id="passwordConfirm"
        key="passwordConfirm"
        v-model="PasswordConfirm"
        class="w-100"
        testId="reset.passwordConfirmInput"
        :placeholder="PasswordConfirmPlaceholder"
        :hasError="PasswordConfirmError"
      />
      <div id="signin-container" class="w-100">
        <div class="d-flex justify-content-end w-auto">
          <button id="signin" data-testid="reset.signInButton" type="button" @click.self="SignIn">
            {{ SignInCaption }}
          </button>
        </div>
      </div>
      <div id="errors" data-testid="reset.errors" class="d-flex w-100 justify-content-start">
        <p id="error-text">{{ ErrorText }}</p>
      </div>
      <div class="w-100 mt-6">
        <transparent-button
          id="try"
          testId="reset.sendButton"
          :text="TryButtonCaption"
          type="submit"
          :disabled="inProcess"
        />
      </div>
    </form>
  </div>
</template>

<script lang="ts">
enum Mode {
  USERNAME,
  CODE,
  NEWPASS,
}
import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import TextBoxAlt from '@/components/inputs/TextBoxAlt.vue';
import PasswordTextBox from '@/components/buttons/PasswordTextBox.vue';
import TransparentButton from '@/components/buttons/TransparentButton.vue';
import en from '../../localization/en';
import loginModule from '../../store/modules/loginModule';
import { RequestStatus } from '@/models/enums/RequestStatus';
import * as EmailValidator from 'email-validator';

@Component({
  components: {
    TextBoxAlt: TextBoxAlt,
    passwordTextBox: PasswordTextBox,
    transparentButton: TransparentButton,
  },
})
export default class ForgotPasswordForm extends Vue {
  private loginModule!: loginModule;
  private username: string = '';
  private password: string = '';
  private passwordConfirm: string = '';
  private code: string = '';
  private mode: Mode = Mode.USERNAME;
  private errorOnTry: string = '';
  private calcErrors: boolean = false;
  private inProcess: boolean = false;

  private created() {
    this.loginModule = getModule(loginModule, this.$store);
    this.username = '';
    this.code = '';
    this.password = '';
    this.ResetStates();
  }
  private ResetStates() {
    this.errorOnTry = '';
    this.calcErrors = false;
    this.inProcess = false;
  }
  private get ErrorText(): string {
    if (this.errorOnTry.length > 0) return this.errorOnTry;
    if (!this.calcErrors) return '';
    switch (this.Mode) {
      case Mode.USERNAME: {
        if (this.username.length > 0) return '';
        return this.FillRequiredCaption;
      }
      case Mode.CODE: {
        if (this.code.length > 0) return '';
        return this.FillRequiredCaption;
      }
      case Mode.NEWPASS: {
        if (this.password.length > 0 && this.passwordConfirm.length > 0 && this.password === this.passwordConfirm)
          return '';
        if (this.password.length === 0 || this.passwordConfirm.length === 0) return this.FillRequiredCaption;
        if (this.password !== this.passwordConfirm) return this.PasswordShouldBeSameCaption;
      }
    }
    return '';
  }

  //#region MODE

  private get Mode(): Mode {
    return this.mode;
  }
  private set Mode(mode: Mode) {
    this.mode = mode;
    this.ResetStates();
  }

  //#endregion

  //#region USERNAME

  private get UserName(): string {
    return this.username;
  }
  private set UserName(username: string) {
    this.calcErrors = false;
    this.username = username;
  }
  private get UsernameError(): boolean {
    if (!this.calcErrors) return false;
    else return this.username.length <= 0;
  }
  private get ShowUserName(): boolean {
    return this.Mode === Mode.USERNAME;
  }

  //#endregion

  //#region CODE

  private get Code(): string {
    return this.code;
  }
  private set Code(code: string) {
    this.code = code;
  }
  private get CodeError(): boolean {
    if (!this.calcErrors) return false;
    else return this.code.length <= 0;
  }
  private get ShowCode(): boolean {
    return this.Mode === Mode.CODE;
  }

  //#endregion

  //#region PASSWORD

  private get Password(): string {
    return this.password;
  }
  private set Password(password: string) {
    this.password = password;
  }
  private get PasswordError(): boolean {
    if (!this.calcErrors) return false;
    else return this.password.length <= 0;
  }
  private get PasswordConfirm(): string {
    return this.passwordConfirm;
  }
  private get PasswordConfirmError(): boolean {
    if (!this.calcErrors) return false;
    else return this.passwordConfirm.length <= 0;
  }
  private set PasswordConfirm(passwordConfirm: string) {
    this.passwordConfirm = passwordConfirm;
  }
  private get ShowPassword(): boolean {
    return this.Mode === Mode.NEWPASS;
  }

  //#endregion

  private OperateWithError(result: RequestStatus, message: string) {
    switch (result) {
      case RequestStatus.CANT_REACH_SERVER:
      case RequestStatus.INTERNAL_SERVER_ERROR: {
        if (message) this.errorOnTry = message;
        else this.errorOnTry = this.InternalServerErrorCaption;
        break;
      }
      case RequestStatus.OK: {
        break;
      }
      default: {
        if (message) this.errorOnTry = message;
        else this.errorOnTry = this.UnknownErrorCaption;
      }
    }
  }
  private async Try() {
    this.errorOnTry = '';
    this.calcErrors = true;
    switch (this.Mode) {
      case Mode.USERNAME: {
        if (this.UsernameError) return;
        if (!EmailValidator.validate(this.username)) {
          this.errorOnTry = this.InvalidEmailCaption;
          return;
        }
        this.Mode = Mode.CODE;
        await this.loginModule.ForgotPasswordRequest(this.username);
        break;
      }
      case Mode.CODE: {
        if (this.CodeError) return;
        this.inProcess = true;
        let [result, message] = await this.loginModule.ForgotPasswordCheckCode([this.UserName, this.Code]);
        this.inProcess = false;
        if (result !== RequestStatus.OK) {
          this.OperateWithError(result, message);
          return;
        } else this.Mode = Mode.NEWPASS;
        break;
      }
      case Mode.NEWPASS: {
        if (this.PasswordError || this.PasswordConfirmError) return;
        this.inProcess = true;
        let [result, message] = await this.loginModule.ForgotPasswordNewRequest([
          this.UserName,
          this.Code,
          this.Password,
        ]);
        this.inProcess = false;
        if (result !== RequestStatus.OK) this.OperateWithError(result, message);
        else this.$emit('success');
        break;
      }
    }
  }
  private SignIn() {
    this.$emit('signin');
  }

  //#region CAPTIONS

  private get FillRequiredCaption(): string {
    return en.fillRequired;
  }
  private get PasswordShouldBeSameCaption(): string {
    return en.passwordsShouldBeSame;
  }
  private get UserNamePlaceholder(): string {
    return en.usernamePlaceholder;
  }
  private get CodePlaceholder(): string {
    return en.codePlaceholder;
  }
  private get PasswordPlaceholder(): string {
    return en.passwordPlaceholder;
  }
  private get SignInCaption(): string {
    return en.signIn;
  }
  private get PasswordConfirmPlaceholder(): string {
    return en.passwordConfirmPlaceholder;
  }
  private get InternalServerErrorCaption(): string {
    return en.internalServerError;
  }
  private get UnknownErrorCaption(): string {
    return en.unknownError;
  }
  private get InvalidEmailCaption(): string {
    return en.invalidEmail;
  }
  private get TryButtonCaption(): string {
    return this.inProcess ? en.sending : en.send;
  }

  //#endregion
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono');
@import url('https://fonts.googleapis.com/css?family=Roboto');

@media (min-width: 576px) {
  div#signin-container {
    max-width: 480px;
  }
  div#errors {
    max-width: 480px;
  }
}
@media (max-width: 576px) {
  div#signin-container {
    max-width: 380px;
  }
  div#errors {
    max-width: 380px;
  }
}
div#signin-container {
  min-width: 200px;
}
button#signin {
  background-color: transparent;
  border-width: 0;
  color: var(--login-fields-text);
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 32px;
  padding: 0;
  outline: none;
}
button#signin::first-letter {
  text-transform: uppercase;
}
button#signin:hover {
  color: white;
}
div#errors {
  /* margin-top: 1.5em; */
  height: 22px;
  color: var(--login-errors);
  white-space: nowrap;
  text-align: left;
}
div#errors p::first-letter {
  text-transform: uppercase;
}
.mt-6 {
  margin-top: 3rem !important;
}
#passwordConfirm {
  margin-top: 25px;
}
</style>
