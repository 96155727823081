import Vue from 'vue';
import VueRouter, { RouteConfig, Route } from 'vue-router';

import MainView from '../views/MainView.vue';
import NotFound from '../views/errors/NotFound.vue';
import Unauthorized from '../views/errors/Unauthorized.vue';
import { Guid } from 'guid-typescript';
import { LoginModule, USER_ID_FIELD, USER_TOKEN_FIELD } from '@/store/modules/loginModule';
import { CompanyRouteTab, Routes } from './routes';
import LoadingPage from '@/views/LoadingPage.vue';
import { getBaseAppPath, isDevMode } from '@/util/env';

Vue.use(VueRouter);

export enum WorkFieldChild {
  DASHBOARD,
  COMPANY,
  FILE_LIBRARY,
  MACHINES,
  JOBS,
  MY_ACCOUNT,
  USER,
  ADMIN,
}

export function IsConnectUserRoute(route: string | null | undefined) {
  return route == Routes.DASHBOARD || route == Routes.FILE_LIBRARY || route == Routes.MACHINES || route == Routes.JOBS;
}

export function IsBillingAdminCompanyTab(tab: string | null) {
  return tab == CompanyRouteTab.BILLING || tab == CompanyRouteTab.PRODUCTS || tab == CompanyRouteTab.STORE;
}

function getId(route: Route): Guid | null {
  const idStr = route.params.id;
  if (idStr === undefined) return null;
  return Guid.parse(idStr.toString());
}

function dynamicPropsFn(route: Route): any {
  if (route.name === Routes.DASHBOARD) return { pageParam: { page: WorkFieldChild.DASHBOARD } };
  if (route.name === Routes.MY_ACCOUNT) return { pageParam: { page: WorkFieldChild.MY_ACCOUNT } };
  if (route.name === Routes.USER) return { pageParam: { page: WorkFieldChild.USER, id: getId(route) } };
  if (route.name === Routes.COMPANY) return { pageParam: { page: WorkFieldChild.COMPANY } };
  if (route.name === Routes.FILE_LIBRARY)
    return {
      pageParam: { page: WorkFieldChild.FILE_LIBRARY, id: getId(route) },
    };
  if (route.name === Routes.MACHINES) return { pageParam: { page: WorkFieldChild.MACHINES, id: getId(route) } };
  if (route.name === Routes.JOBS) return { pageParam: { page: WorkFieldChild.JOBS, id: getId(route) } };
  if (route.name === Routes.ADMIN) return { pageParam: { page: WorkFieldChild.ADMIN } };

  if (LoginModule.IsConnectUser) {
    return { pageParam: { page: WorkFieldChild.DASHBOARD } };
  } else {
    return { pageParam: { page: WorkFieldChild.MY_ACCOUNT } };
  }
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: Routes.HOME,
    component: MainView,
    props: dynamicPropsFn,
  },
  {
    path: '/loading',
    name: Routes.LOADING,
    component: LoadingPage,
    props: dynamicPropsFn,
  },
  {
    path: '/dashboard',
    name: Routes.DASHBOARD,
    component: MainView,
    props: dynamicPropsFn,
  },
  {
    path: '/login',
    name: Routes.LOGIN,
    component: MainView,
    props: dynamicPropsFn,
  },
  {
    path: '/my-account',
    name: Routes.MY_ACCOUNT,
    component: MainView,
    props: dynamicPropsFn,
  },
  {
    path: '/user',
    name: Routes.USER,
    component: MainView,
    props: dynamicPropsFn,
  },
  {
    path: '/company',
    name: Routes.COMPANY,
    component: MainView,
    props: dynamicPropsFn,
  },
  {
    path: '/file-library',
    name: Routes.FILE_LIBRARY,
    component: MainView,
    props: dynamicPropsFn,
  },
  {
    path: '/machines',
    name: Routes.MACHINES,
    component: MainView,
    props: dynamicPropsFn,
  },
  {
    path: '/jobs',
    name: Routes.JOBS,
    component: MainView,
    props: dynamicPropsFn,
  },
  {
    path: '/admin',
    name: Routes.ADMIN,
    component: MainView,
    props: dynamicPropsFn,
  },
  {
    path: '/unauthorized',
    name: Routes.UNAUTHORIZED,
    component: Unauthorized,
  },
  {
    path: '*',
    name: Routes.NOT_FOUND,
    component: NotFound,
  },
];

const router = new VueRouter({
  routes,
  base: getBaseAppPath(),
  mode: 'history',
});

router.beforeEach(async (to, from, next) => {
  // First page visit, basically
  if (to.name == Routes.LOADING) {
    if (isDevMode()) {
      const query = Object.assign({}, to.query);
      const { refreshToken, userId } = query;
      if (typeof refreshToken === 'string' && typeof userId === 'string') {
        localStorage.setItem(USER_ID_FIELD, decodeURIComponent(userId));
        localStorage.setItem(USER_TOKEN_FIELD, decodeURIComponent(refreshToken));
        delete query['userId'];
        delete query['refreshToken'];
        try {
          await router.replace({ name: Routes.LOADING, query: query });
          return;
        } catch (err) {
          console.error(err);
        }
      }
    }

    LoginModule.Connect();
    await next();
    return;
  }

  if (to.name == Routes.NOT_FOUND) {
    await next();
    return;
  }

  if (!LoginModule.Initialized) {
    if (to.params.isLoading != '1') {
      next({
        name: Routes.LOADING,
        params: {
          forwardTo: to.name!,
          isLoading: '1',
        },
        query: to.query,
      });
      return;
    }

    next({ name: to.params.forwardTo, query: to.query });
    return;
  }

  if (!LoginModule.IsAuthorized) {
    if (to.name !== Routes.LOGIN) {
      next({ name: Routes.LOGIN });
      return;
    }
    next({ name: to.params.forwardTo, query: to.query });
    return;
  }

  let authorized = true;

  if (to.name == Routes.ADMIN) {
    if (!LoginModule.IsSuperAdmin) {
      authorized = false;
    }
  } else if (IsConnectUserRoute(to.name)) {
    if (!LoginModule.IsConnectUser) {
      authorized = false;
    }
  } else if (to.name == Routes.COMPANY) {
    if (IsBillingAdminCompanyTab(to.query.tab as string)) {
      if (!LoginModule.IsBillingAccountAdmin) {
        authorized = false;
      }
    }
  }

  if (!authorized) {
    next({ name: Routes.UNAUTHORIZED });
    return;
  }

  next({ name: to.params.forwardTo, query: to.query });
});

export default router;
