<template>
  <div
    class="current-jobs overflow-auto thin-scroll"
    :style="{ 'user-select': isResizing ? 'none' : 'unset' }"
    @mousemove="MouseMove"
    @mouseup="MouseUp"
  >
    <div class="jobs-control-panel">
      <div class="jobs-container overflow-auto thin-scroll">
        <div class="jobs">
          <JobCard
            v-for="fullJob of FullJobs"
            :key="fullJob.job.Id.toString()"
            :fullJob="fullJob"
            :selected="IsSelectedFullJob(fullJob)"
            @clicked="SelectFullJob(fullJob)"
          />
        </div>
      </div>
      <div class="vertical-resizer"></div>
      <div class="job-editor-container thin-scroll">
        <JobEditor
          v-if="selectedJob != null"
          :fullJob="selectedJob"
          @owner-clicked="OwnerClicked"
          @printer-clicked="PrinterClicked"
          @source-clicked="SourceClicked"
          @close-clicked="CloseJobClicked"
        />
      </div>

      <div :class="['spinner', isLoadingPrinters || isLoadingJobs ? '' : 'hidden']"></div>
    </div>

    <div ref="horizontalResizer" class="horizontal-resizer" @mousedown="TimelineResizerMouseDown"></div>

    <div ref="timelinePanel" class="jobs-timeline-panel" :style="{ height: TimeLineHeight }">
      <div class="jobs-timeline-controls">
        <IconButton
          v-tooltip="'Reset view'"
          icon-name="fa-history"
          icon-type="far"
          icon-size="15"
          test-id="jobsTimeline.resetButton"
          @clicked="ResetTimelineView"
        />
        <IconButton
          v-if="timelineFollowCurrentTime"
          key="timeUnlockButton"
          v-tooltip="'Unlock time'"
          iconName="fa-lock"
          icon-type="far"
          icon-size="15"
          test-id="jobsTimeline.timeUnlockButton"
          @clicked="ToggleTimelineTimeLock"
        />
        <IconButton
          v-else
          key="timeLockButton"
          v-tooltip="'Lock time'"
          iconName="fa-lock-open"
          icon-type="far"
          icon-size="15"
          test-id="jobsTimeline.timeLockButton"
          @clicked="ToggleTimelineTimeLock"
        />
      </div>

      <JobTimeline
        ref="jobTimeline"
        :jobs="FullJobs"
        :followCurrentTime="timelineFollowCurrentTime"
        :selectedJob="selectedJob"
        :hiddenPrinterIds="hiddenPrinterIds"
        @job-clicked="JobClickedOnTimeline"
        @job-hovered="JobHoveredOnTimeline"
        @time-range-changed="JobTimelineTimeRangeChanged"
        @vertical-offset-changed="JobTimelineVerticalOffsetChanged"
        @mounted="JobTimelineTimeMounted"
      />

      <div class="jobs-timeline-tutorial">
        <v-popover trigger="hover" placement="top">
          <i class="fal fa-question-circle"></i>

          <template slot="popover">
            <div class="jobs-timeline-popover">
              <span>Use the left mouse button on the timeline to scroll through time</span>
              <span>Use the left mouse button on the printer list to move printers up or down</span>
              <span>Use the mouse wheel to zoom in or out on time</span>
            </div>
          </template>
        </v-popover>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Guid } from 'guid-typescript';
import { VPopover } from 'v-tooltip';
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';

import DarkButton from '@/components/buttons/DarkButton.vue';
import RegularButton from '@/components/buttons/RegularButton.vue';
import IconButton from '@/components/buttons/IconButton.vue';
import JobCard from '@/components/presentation/JobCard.vue';
import JobEditor from '@/components/forms/JobEditor.vue';
import JobTimeline from '@/components/presentation/JobTimeline.vue';
import { FullJob } from '@/models/CompositeEntities';
import { Job, JobArchieve, Printer, Source, User } from '@/models/Entities';
import { JobSortBy } from '@/models/requests/RequestMonolith';
import { Routes } from '@/router/routes';
import { ConnectorModule } from '@/store/modules/connectorModule';
import { GlobalDataModule } from '@/store/modules/globalDataModule';
import jobModule, { JobModule, JobQueryAdditions } from '@/store/modules/jobModule';
import { PrinterModule } from '@/store/modules/printerModule';
import { SourceModule } from '@/store/modules/sourceModule';
import { UserModule } from '@/store/modules/userModule';
import { SortMode } from '@/util/ComponentHelper';
import { GuidHelper } from '@/util/GuidHelper';

@Component({
  components: {
    RegularButton: RegularButton,
    DarkButton: DarkButton,
    IconButton: IconButton,
    JobCard: JobCard,
    JobEditor: JobEditor,
    JobTimeline: JobTimeline,
    VPopover: VPopover,
  },
  name: 'current-jobs',
})
export default class CurrentJobs extends Vue {
  @Prop({ default: [] }) hiddenPrinterIds!: Guid[];

  //#region WATCHERS
  @Watch('$route', { immediate: true, deep: true })
  private async OnRouteChanged() {
    const companyId = this.$route.query.companyId;
    const jobId = this.$route.query.jobId;

    // const oldCompanyId = this.CompanyId == undefined ? undefined : Guid.parse(this.CompanyId.toString());
    // const oldSourceId = this.JobId == undefined ? undefined : Guid.parse(this.JobId.toString());

    if (companyId !== undefined && Guid.isGuid(companyId)) {
      this.CompanyId = Guid.parse(companyId as string);
    } else {
      this.CompanyId = undefined;
    }

    if (jobId !== undefined && Guid.isGuid(jobId)) {
      this.JobId = Guid.parse(jobId as string);

      const loaded = this.FullJobs.firstOrDefault(a => a.job.Id.equals(this.JobId!));

      this.selectedJob = loaded;
    } else {
      this.JobId = undefined;
      this.selectedJob = null;
    }
  }
  //#endregion

  //#region STATE
  private componentId!: Guid;

  private CompanyId: Guid | undefined = undefined;
  private JobId: Guid | undefined = undefined;

  private selectedJob: FullJob | null = null;

  private isLoadingPrinters: boolean = false;
  private isLoadingJobs: boolean = false;

  //#region TIMELINE
  private timeLinePanelHeightInPercent = 0.5;
  private timeLinePanelHeight: number = -1;
  private isResizing: boolean = false;
  private resizingStartY: number = -1;

  private timelineFollowCurrentTime: boolean = false;

  private get TimeLineHeight() {
    return this.timeLinePanelHeightInPercent * 100 + '%';
  }
  //#endregion

  private get Jobs(): Job[] {
    return JobModule.Jobs.map(a => a).sort((a, b) => {
      var orderA = a.Order == null ? -1 : a.Order;
      var orderB = b.Order == null ? -1 : b.Order;

      return orderA > orderB ? 1 : -1;
    });
  }

  private get JobArchieves(): JobArchieve[] {
    return JobModule.JobArchieves;
  }

  private get Printers(): Printer[] {
    let printers = PrinterModule.Printers.map(a => a).sort((a: Printer, b: Printer) => (a.Name < b.Name ? -1 : 1));
    PrinterModule.OccupyPrinters([printers, this.componentId]);
    return printers.sort((a, b) => (a.Name > b.Name ? 1 : -1));
  }

  private get Sources(): Source[] {
    let sourceIds: Guid[] = jobModule.GetSourceIdsByAll(this.Jobs, this.JobArchieves);
    let sources = SourceModule.Sources.map(a => a).filter(a => GuidHelper.includes(sourceIds, a.Id));
    SourceModule.OccupySources([sources, this.componentId]);
    return sources;
  }

  private get Users(): User[] {
    let userIds: Guid[] = jobModule.GetUserIdsByAll(this.Jobs, this.JobArchieves);
    let users = UserModule.Users.map(a => a).filter(a => GuidHelper.includes(userIds, a.Id));
    return users;
  }

  private get FullJobs(): FullJob[] {
    let fullJobs: FullJob[] = [];

    for (let job of this.Jobs) {
      let printer = this.Printers.singleOrDefault(a => a.Id.toString() === job.PrinterId.toString());

      if (printer == null) continue;

      if (this.hiddenPrinterIds.firstOrDefault(a => a.toString() == printer!.Id.toString()) != null) continue;

      let source = this.Sources.singleOrDefault(a => GuidHelper.equalsWithNull(a.Id, job.SourceId));
      let user = this.Users.singleOrDefault(a => GuidHelper.equalsWithNull(a.Id, job.UserId));

      let fullJob: FullJob = {
        job: job,
        printer: printer,
        source: source,
        owner: user,
      };

      if (this.selectedJob != null && job.Id.toString() == this.selectedJob.job.Id.toString()) {
        this.selectedJob = fullJob;
      }

      fullJobs.push(fullJob);
    }

    if (this.JobId != undefined) {
      this.selectedJob = fullJobs.firstOrDefault(a => a.job.Id.equals(this.JobId!));
    }

    if (
      this.selectedJob != null &&
      fullJobs.firstOrDefault(a => a.job.Id.toString() == this.selectedJob!.job.Id.toString()) == null
    ) {
      this.selectedJob = null;
    }

    return fullJobs;
  }
  //#endregion

  //#region LOGIC
  private async LoadPrinters() {
    this.isLoadingPrinters = true;

    await PrinterModule.CollectPrinters();
    await this.LoadPrinterModels();
    await this.LoadConnectionTypes();
    await PrinterModule.LoadPrintersForCompanyAll(this.CompanyId);

    this.isLoadingPrinters = false;
  }

  private async LoadPrinterModels() {
    await PrinterModule.LoadAllPrinterModels();
    await ConnectorModule.LoadAllPrinterModels();
  }

  private async LoadConnectionTypes() {
    await PrinterModule.ReadAllConnectionTypes();
  }

  private async LoadJobs() {
    let additions = new JobQueryAdditions();
    additions.sortBy = JobSortBy.BY_ORDER;
    additions.sortMode = SortMode.ASC;
    additions.page = 0;
    additions.pageSize = 25;

    this.isLoadingJobs = true;

    let jobs: Job[] = await JobModule.JustLoadMoreForCompany([additions, this.CompanyId]);

    if (jobs.any()) {
      let printerIds = GuidHelper.distinct(jobs.map(a => a.PrinterId));
      let sourceIds = jobModule.GetSourceIdsByJobs(jobs);
      let userIds = jobModule.GetUserIdsByJobs(jobs);

      await Promise.all([
        PrinterModule.LoadPrinters(printerIds),
        SourceModule.LoadSources(sourceIds),
        UserModule.LoadUsers(userIds),
      ]);

      for (let job of jobs) {
        JobModule.AddJobToModule(job);
      }
    }

    this.isLoadingJobs = false;
  }

  private SelectFullJob(fullJob: FullJob) {
    if (this.JobId?.equals(fullJob.job.Id)) {
      return;
    }

    this.$router.push({
      name: Routes.JOBS,
      query: { ...this.$route.query, jobId: fullJob.job.Id.toString() },
    });

    this.selectedJob = fullJob;
  }

  private IsSelectedFullJob(fullJob: FullJob) {
    if (this.selectedJob == null) return false;

    return this.selectedJob.job.Id.toString() == fullJob.job.Id.toString();
  }

  private JobClickedOnTimeline(job: FullJob) {
    this.SelectFullJob(job);
  }

  private JobHoveredOnTimeline() {}

  private async JobTimelineTimeRangeChanged(range: { from: Date; to: Date }) {
    await GlobalDataModule.ChangeJobsViewCurrentTabTimelineDateRange({
      from: range.from,
      to: range.to,
    });
  }

  private async JobTimelineVerticalOffsetChanged(newVal: number) {
    await GlobalDataModule.ChangeJobsViewCurrentTabTimelineVerticalOffset(newVal);
  }

  private async JobTimelineTimeMounted() {
    window.setTimeout(() => {
      const timeline = this.$refs.jobTimeline as JobTimeline;

      const from = GlobalDataModule.JobsViewState.currentTabViewState.timelineViewState.dateFrom;
      const to = GlobalDataModule.JobsViewState.currentTabViewState.timelineViewState.dateTo;

      if (from == null || to == null) {
        this.ResetTimelineView();
      } else {
        this.ResetTimelineView();
        // This behaviour was here previously, may be restored
        // timeline.SetTimeRange(new Date(from), new Date(to));
      }

      timeline.SetVerticalOffset(GlobalDataModule.JobsViewState.currentTabViewState.timelineViewState.verticalOffset);

      this.timelineFollowCurrentTime = GlobalDataModule.JobsViewState.currentTabViewState.timelineViewState.timeLocked;

      timeline.DrawData();
    }, 25);
  }

  private OnJobDeleted(job: Job) {
    if (this.selectedJob != null && this.selectedJob.job.Id.toString() == job.Id.toString()) {
      this.selectedJob = null;
    }
  }

  private async ToggleTimelineTimeLock() {
    this.timelineFollowCurrentTime = !this.timelineFollowCurrentTime;

    await GlobalDataModule.ChangeJobsViewCurrentTabTimelineTimeLocked(this.timelineFollowCurrentTime);
  }

  private ResetTimelineView() {
    const timeline = this.$refs.jobTimeline as JobTimeline;

    timeline.ResetView();
    timeline.DrawData();
  }

  //#region RESIZE TIMELINE
  private TimelineResizerMouseDown(e: MouseEvent) {
    this.isResizing = true;
    this.resizingStartY = e.clientY;
    const timelinePanel = this.$refs.timelinePanel as Element;
    this.timeLinePanelHeight = timelinePanel.getBoundingClientRect().height;
  }

  private MouseUp() {
    this.isResizing = false;
  }

  private MouseMove(e: MouseEvent) {
    if (!this.isResizing) {
      return;
    }

    const timeline = this.$refs.jobTimeline as JobTimeline;

    const horizontalResizer = this.$refs.horizontalResizer as Element;
    let parentHeight = horizontalResizer.parentElement!.getBoundingClientRect().height;
    parentHeight -= horizontalResizer.getBoundingClientRect().height;
    parentHeight += parseFloat(getComputedStyle(horizontalResizer).height);

    const dy = e.clientY - this.resizingStartY;

    const newtimeLinePanelHeight = this.timeLinePanelHeight - dy;

    const newtimeLinePanelHeightInPercent = newtimeLinePanelHeight / parentHeight;

    this.timeLinePanelHeight = newtimeLinePanelHeight;

    this.timeLinePanelHeightInPercent = newtimeLinePanelHeightInPercent;

    GlobalDataModule.ChangeJobsViewCurrentTabTimelineHeight(this.timeLinePanelHeightInPercent);

    if (
      GlobalDataModule.JobsViewState.currentTabViewState.timelineViewState.verticalOffset != timeline.plotDataYOffset
    ) {
      this.JobTimelineVerticalOffsetChanged(timeline.plotDataYOffset);
    }

    this.resizingStartY = e.clientY;
  }
  //#endregion

  private IsPrinterHidden(printer: Printer) {
    return this.hiddenPrinterIds.firstOrDefault(a => a.toString() == printer.Id.toString()) != null;
  }

  private TogglePrinterVisiblity(printer: Printer) {
    if (this.IsPrinterHidden(printer)) {
      const found = this.hiddenPrinterIds.firstOrDefault(a => a.toString() == printer.Id.toString())!;

      this.hiddenPrinterIds.delete(found);
      GlobalDataModule.RemoveHiddenJobsViewTimelinePrinter(found);

      return;
    }

    GlobalDataModule.AddHiddenJobsViewTimelinePrinter(printer.Id);
    this.hiddenPrinterIds.push(printer.Id);
  }

  private async CloseJobClicked() {
    this.$router.push({
      name: Routes.JOBS,
      query: { tab: 'current', jobId: undefined },
    });
  }
  //#endregion

  //#region HOOKS
  async beforeCreate() {
    this.componentId = Guid.create();
  }

  async created() {
    if (GlobalDataModule.JobsViewState.currentTabViewState.timelineHeight != null) {
      this.timeLinePanelHeightInPercent = GlobalDataModule.JobsViewState.currentTabViewState.timelineHeight;
    }
  }

  async mounted() {}

  async activated() {
    await this.LoadPrinters();
    await this.LoadJobs();
  }

  async deactivated() {
    await PrinterModule.LoosePrintersNew([this.Printers, this.componentId]);
    await SourceModule.LooseSources([this.Sources, this.componentId]);
    await JobModule.LooseJobs([this.Jobs, this.componentId]);

    await JobModule.CollectJobs();
    await SourceModule.CollectSources();
    await PrinterModule.CollectPrinters();
  }

  async beforeDestroy() {}
  //#endregion

  //#region EVENTS
  @Emit('openUser')
  private async OwnerClicked(user: User) {
    return user.Id;
  }

  @Emit('openMachine')
  private async PrinterClicked(printer: Printer) {
    return printer.Id;
  }

  @Emit('openSource')
  private async SourceClicked(source: Source) {
    return source.Id;
  }
  //#endregion

  //#region TRANSLATIONS
  //#endregion
}
</script>

<style lang="scss" scoped>
.current-jobs {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;

  .jobs-control-panel {
    flex: 5;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: relative;

    .jobs-container {
      flex: 3;
      min-width: 365px;

      .jobs {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        row-gap: 14px;
        column-gap: 18px;
        overflow: auto;
      }
    }

    .job-editor-container {
      flex: 2;
      overflow: auto;
      min-width: fit-content;
    }
  }

  .jobs-timeline-panel {
    min-height: 175px;
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 24px;
    position: relative;

    .jobs-timeline-controls {
      display: flex;
      position: absolute;
      gap: 6px;
      margin-top: 35px;
    }

    .jobs-timeline-tutorial {
      position: absolute;
      bottom: 14px;
      right: 6px;

      color: #c8c7c7;
      font-size: 14px;

      i {
        cursor: pointer;
        &:hover {
          color: white;
        }
      }
    }
  }
}

.vertical-resizer {
  cursor: col-resize;
  width: 10px;
  margin: 0 3px;
}

.horizontal-resizer {
  cursor: row-resize;
  height: 16px;
  margin: 3px 0;
  opacity: 0.15;
}
</style>

<style lang="scss">
.jobs-timeline-popover {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.35rem;
}
</style>
