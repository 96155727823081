<template>
  <div class="global-notification-editor">
    <editor-header>
      <template slot="header">
        {{ MessageCaption }}
        <span class="message-id"> #{{ globalNotification.id.toString() }} </span>
        <span v-if="IsEditing" class="editing-header-text"> . {{ EditCaption }} </span>
      </template>

      <template slot="buttons">
        <HeaderIconButton
          iconName="fa-trash"
          test-id="globalNotificationEditor.deleteButton"
          @clicked="deleteButtonClicked"
        />
        <HeaderIconButton
          iconName="fa-pen"
          test-id="globalNotificationEditor.editButton"
          @clicked="IsEditing = !IsEditing"
        />
        <HeaderIconButton
          iconName="fa-times"
          test-id="globalNotificationEditor.closeButton"
          @clicked="closeButtonClicked"
        />
      </template>
    </editor-header>

    <editor-content>
      <text-area-input
        class="mb-2"
        :readonly="!IsEditing"
        :label="TextCaption"
        :disabled="isLoading"
        :invalid="textValidationFailed"
        placeholder="Enter message text..."
        :labelWidth="labelWidth"
        :value.sync="text"
        test-id="globalNotificationEditor.messageInput"
      >
      </text-area-input>

      <div class="d-flex mb-2">
        <date-input
          :readonly="!IsEditing"
          :label="DateFromCaption"
          :disabled="isLoading"
          :invalid="dateValidationFailed"
          :showHour="true"
          :showMinute="true"
          :showSecond="false"
          :date.sync="dateFrom"
          :labelWidth="labelWidth"
        >
        </date-input>

        <dark-button
          v-if="IsEditing"
          class="ml-2"
          :label="NowCaption"
          :disabled="isLoading"
          test-id="globalNotificationEditor.todayButton"
          @clicked="setFromDateToNow"
        />
      </div>

      <div class="d-flex">
        <date-input
          :readonly="!IsEditing"
          :label="DateToCaption"
          :disabled="isLoading"
          :invalid="dateValidationFailed"
          :showHour="true"
          :showMinute="true"
          :showSecond="false"
          :date.sync="dateTo"
          :labelWidth="labelWidth"
        >
        </date-input>

        <dark-button
          v-if="IsEditing"
          class="ml-2"
          :label="TommorowCaption"
          :disabled="isLoading"
          test-id="globalNotificationEditor.tomorrowButton"
          @clicked="setToDateToTommorow"
        />
      </div>

      <text-input
        v-if="!IsEditing"
        class="mt-2"
        :readonly="true"
        :label="CreatedByCaption"
        :disabled="isLoading"
        :labelWidth="labelWidth"
        :value="CreatedBy"
        @clicked="() => {}"
      >
      </text-input>

      <fade-transition :maxHeight="28">
        <div v-if="validationErrors.length != 0" class="errors-container">
          <span class="mt-2">{{ validationErrors[0] }}</span>
        </div>
      </fade-transition>

      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </editor-content>

    <editor-footer v-if="IsEditing">
      <template slot="buttons">
        <icon-button
          class="mr-3"
          :disabled="isLoading || !HasChanges"
          iconName="fa-check"
          :text="ApplyCaption"
          test-id="globalNotificationEditor.applyButton"
          @clicked="applyButtonClicked"
        />
        <icon-button
          class="mr-3"
          :disabled="isLoading"
          iconName="fa-sync-alt"
          :text="ResetCaption"
          test-id="globalNotificationEditor.resetButton"
          @clicked="resetButtonClicked"
        />
        <icon-button
          :disabled="isLoading"
          iconName="fa-times"
          :iconSize="15"
          :text="CancelCaption"
          test-id="globalNotificationEditor.cancelButton"
          @clicked="IsEditing = false"
        />
      </template>
    </editor-footer>
  </div>
</template>

<script lang="ts">
import { GlobalNotification, User } from '@/models/Entities';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { GlobalNotificationsModule } from '@/store/modules/globalNotificationsModule';
import { UserModule } from '@/store/modules/userModule';
import en from '@/localization/en';
import FadeTransition from '@/components/presentation/FadeTransition.vue';
import IconButton from '../buttons/IconButton.vue';
import EditorFooter from '@/components/forms/base/EditorFooter.vue';
import EditorHeader from '@/components/forms/base/EditorHeader.vue';
import EditorContent from '@/components/forms/base/EditorContent.vue';
import TextAreaInput from '@/components/inputs/TextAreaInput.vue';
import DateInput from '@/components/inputs/DateInput.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import ComponentHelper from '@/util/ComponentHelper';
import { TypeHelper } from '@/util/TypeHelper';
import DarkButton from '../buttons/DarkButton.vue';
import HeaderIconButton from '@/components/buttons/HeaderIconButton.vue';

@Component({
  name: 'global-notification-editor',
  components: {
    HeaderIconButton,
    DatePicker: DatePicker,
    FadeTransition: FadeTransition,
    IconButton: IconButton,
    EditorHeader: EditorHeader,
    EditorContent: EditorContent,
    EditorFooter: EditorFooter,
    TextAreaInput: TextAreaInput,
    DateInput: DateInput,
    TextInput: TextInput,
    DarkButton: DarkButton,
  },
})
export default class GlobalNotificationEditor extends Vue {
  labelWidth: number = 100;

  //#region PROPRS + WATCHERS
  @Prop()
  private globalNotification!: GlobalNotification;

  @Watch('globalNotification', { immediate: true })
  async OnGlobalNotificationChanged(newValue: GlobalNotification) {
    await this.setLocalStateFromGlobalNotifiation(newValue);
  }

  @Watch('isEditing')
  async OnEditingChanged() {
    await this.setLocalStateFromGlobalNotifiation(this.globalNotification);
  }
  //#endregion

  //#region STATE
  private isLoading: boolean = false;

  private validationTimeoutHandle: number = -1;
  private textValidationFailed: boolean = false;
  private dateValidationFailed: boolean = false;
  private validationErrors: string[] = [];

  private isEditing: boolean = false;
  private get IsEditing() {
    return this.isEditing;
  }
  private set IsEditing(value: boolean) {
    this.isEditing = value;

    if (this.isEditing) {
      this.setLocalStateFromGlobalNotifiation(this.globalNotification);
    }
  }

  private get HasChanges() {
    return (
      this.text != this.globalNotification.message ||
      this.from.getDate() != TypeHelper.StringToDate(this.globalNotification.startShowing).getDate() ||
      this.to.getDate() != TypeHelper.StringToDate(this.globalNotification.endShowing).getDate()
    );
  }

  private text: string = '';

  private showFromTime: boolean = false;
  private showToTime: boolean = false;

  private from: Date = new Date(new Date().setSeconds(0));
  private to: Date = new Date(new Date().setSeconds(0));

  private createdBy: User | null = null;

  private get dateFrom(): Date {
    return this.from;
  }
  private set dateFrom(value: Date) {
    value.setSeconds(0);
    this.from = value;
  }

  private get dateTo(): Date {
    return this.to;
  }
  private set dateTo(value: Date) {
    value.setSeconds(0);
    this.to = value;
  }

  private get CreatedBy(): string {
    if (this.createdBy == null) return 'Loading...';

    return ComponentHelper.GetFullname(this.createdBy);
  }
  //#endregion

  //#region STATE LOGIC
  private toggleFromTime() {
    this.showFromTime = !this.showFromTime;
  }

  private toggleToTime() {
    this.showToTime = !this.showToTime;
  }

  private resetButtonClicked() {
    this.text = this.globalNotification.message;
    this.from = new Date(this.globalNotification.startShowing);
    this.to = new Date(this.globalNotification.endShowing);
  }

  private setFromDateToNow() {
    this.dateFrom = new Date();
  }

  private setToDateToTommorow() {
    const now = new Date();
    const tommorow = new Date();
    tommorow.setDate(now.getDate() + 1);

    this.dateTo = tommorow;
  }

  private clearValidationErrors() {
    if (this.validationTimeoutHandle !== -1) {
      clearTimeout(this.validationTimeoutHandle);
    }

    this.validationErrors = [];
    this.dateValidationFailed = false;
    this.textValidationFailed = false;
    this.validationTimeoutHandle = -1;
  }

  private validate(): boolean {
    this.clearValidationErrors();

    if (this.text == null || this.text.length == 0) {
      this.validationErrors.push('Enter message text');
      this.textValidationFailed = true;
    } else if (this.from > this.to) {
      this.validationErrors.push('Final date has to be earlier than start date');
      this.dateValidationFailed = true;
    } else if (this.from.differenceInDays(this.to) < 0.95) {
      this.validationErrors.push('Minimum difference is one day. Select a larger range');
      this.dateValidationFailed = true;
    }

    const failed = this.validationErrors.length != 0;

    if (failed) {
      this.validationTimeoutHandle = window.setTimeout(() => {
        this.clearValidationErrors();
      }, 3500);
    }

    return !failed;
  }

  private async handleGlobalNotificationDeleted(gn: GlobalNotification) {
    if (gn.id.toString() === this.globalNotification.id.toString()) {
      this.closeButtonClicked();
    }
  }

  private async setLocalStateFromGlobalNotifiation(gn: GlobalNotification) {
    this.text = this.globalNotification.message;
    this.from = new Date(this.globalNotification.startShowing);
    this.to = new Date(this.globalNotification.endShowing);
    this.createdBy = await UserModule.LoadUser(gn.userId);
  }
  //#endregion

  //#region HOOKS
  async created() {
    GlobalNotificationsModule.OnGlobalNotificationDeleted.subscribe(this.handleGlobalNotificationDeleted);

    this.createdBy = await UserModule.LoadUser(this.globalNotification.userId);
  }

  async beforeDestroy() {
    GlobalNotificationsModule.OnGlobalNotificationDeleted.unsubscribe(this.handleGlobalNotificationDeleted);
  }
  //#endregion

  //#region EVENTS
  private closeButtonClicked() {
    this.$emit('close-clicked');
  }

  private async deleteButtonClicked() {
    this.isLoading = true;

    await GlobalNotificationsModule.DeleteGlobalNotificationOnServer(this.globalNotification.id.toString());

    this.isLoading = false;
  }

  private async applyButtonClicked() {
    // Update message on backend
    if (!this.validate()) {
      return;
    }

    this.from.setSeconds(0);
    this.from.setMilliseconds(0);

    this.to.setSeconds(0);
    this.to.setMilliseconds(0);

    this.isLoading = true;

    await GlobalNotificationsModule.UpdateGlobalNotificationOnServer([
      this.globalNotification.id.toString(),
      this.text,
      this.from.toJSON(),
      this.to.toJSON(),
    ]);

    this.isLoading = false;

    this.IsEditing = false;
  }
  //#endregion

  //#region TRANSLATIONS
  private get MessageCaption(): string {
    return en.message.titleCase();
  }
  private get EditCaption(): string {
    return en.edit.toUpperCase();
  }
  private get TextCaption(): string {
    return en.text.titleCase();
  }
  private get DateFromCaption(): string {
    return en.dateFrom.titleCase();
  }
  private get NowCaption(): string {
    return en.now.titleCase();
  }
  private get DateToCaption(): string {
    return en.dateTo.titleCase();
  }
  private get TommorowCaption(): string {
    return en.tomorrow.titleCase();
  }
  private get CreatedByCaption(): string {
    return en.createdBy.titleCase();
  }
  private get ApplyCaption(): string {
    return en.apply.titleCase();
  }
  private get ResetCaption(): string {
    return en.reset.titleCase();
  }
  private get CancelCaption(): string {
    return en.cancel.titleCase();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.global-notification-editor {
  background: var(--editor-background);
  border-radius: 6px;

  .errors-container {
    display: flex;
    span {
      color: var(--editor-error-text);
      font-size: 14px;
    }
  }

  button {
    width: 100px;
  }

  .error-border {
    border: 1px solid var(--main-orange);
  }
}
</style>
