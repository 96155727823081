export enum PaymentMethodKind {
  CardAmex,
  CardDiners,
  CardDiscover,
  CardJCB,
  CardMastercard,
  CardUnionpay,
  CardVisa,
  Unknown,
}
