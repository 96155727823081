<template>
  <div class="machine-editor">
    <EditorHeader>
      <template slot="header">
        <span>
          {{ EditMachineCaption }}
        </span>
      </template>
      <template slot="buttons">
        <HeaderIconButton test-id="machineEditor.closeButton" iconName="fa-times" @clicked="CloseButtonClicked" />
      </template>
    </EditorHeader>

    <EditorContent>
      <text-input
        :label="MachineNameCaption"
        :value.sync="machineName"
        :labelWidth="labelWidth"
        :invalid="machineNameValidationFailed"
        :disabled="isLoading"
        :readonly="false"
        placeholder="Enter machine name..."
        test-id="machineEditor.machineNameInput"
      />

      <fade-transition :maxHeight="28">
        <div v-if="validationErrors.length != 0" class="errors-container">
          <span class="mt-2">{{ validationErrors[0] }}</span>
        </div>
      </fade-transition>

      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </EditorContent>

    <EditorFooter>
      <template slot="buttons">
        <IconButton
          class="mr-3"
          :disabled="isLoading || !HasChanges"
          iconName="fa-check"
          :text="ApplyCaption"
          test-id="machineEditor.applyButton"
          @clicked="ApplyButtonClicked"
        />
        <IconButton
          test-id="machineEditor.resetButton"
          :disabled="isLoading"
          iconName="fa-sync-alt"
          :text="ResetCaption"
          @clicked="ResetButtonClicked"
        />
      </template>
    </EditorFooter>
  </div>
</template>

<script lang="ts">
import en from '@/localization/en';
import { toast } from '@/main';
import { Printer } from '@/models/Entities';
import { PrinterModule } from '@/store/modules/printerModule';
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import { POSITION } from 'vue-toastification';
import FadeTransition from '../presentation/FadeTransition.vue';
import EditorContent from '@/components/forms/base/EditorContent.vue';
import EditorFooter from '@/components/forms/base/EditorFooter.vue';
import EditorHeader from '@/components/forms/base/EditorHeader.vue';
import IconButton from '../buttons/IconButton.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import HeaderIconButton from '@/components/buttons/HeaderIconButton.vue';

@Component({
  components: {
    HeaderIconButton,
    FadeTransition: FadeTransition,
    IconButton: IconButton,
    EditorHeader: EditorHeader,
    EditorContent: EditorContent,
    EditorFooter: EditorFooter,
    TextInput: TextInput,
  },
})
export default class MachineEditor extends Vue {
  @Prop() printer!: Printer | null;
  labelWidth: number = 100;

  //#region WATCHERS
  @Watch('printer', { immediate: true })
  OnPrinterChanged(newValue: Printer | null) {
    if (newValue != null) {
      this.SetLocalStateFromPrinter(newValue);
    }
  }
  //#endregion

  //#region STATE
  private isLoading: boolean = false;

  private machineName: string = '';

  private validationTimeoutHandle: number = -1;
  private machineNameValidationFailed: boolean = false;
  private validationErrors: string[] = [];

  private get HasChanges() {
    if (this.printer == null) return false;

    return this.machineName != this.printer.Name;
  }
  //#endregion

  //#region LOGIC
  SetLocalStateFromPrinter(printer: Printer) {
    this.machineName = printer.Name;
  }

  private clearValidationErrors() {
    if (this.validationTimeoutHandle !== -1) {
      clearTimeout(this.validationTimeoutHandle);
    }

    this.validationErrors = [];
    this.machineNameValidationFailed = false;
    this.validationTimeoutHandle = -1;
  }

  public validate(): boolean {
    this.clearValidationErrors();

    // Validate
    if (this.machineName == null || this.machineName.length == 0) {
      this.validationErrors.push('Enter machine name');
      this.machineNameValidationFailed = true;
    }

    const failed = this.validationErrors.length != 0;

    if (failed) {
      this.validationTimeoutHandle = window.setTimeout(() => {
        this.clearValidationErrors();
      }, 3500);
    }

    return !failed;
  }

  private async ApplyButtonClicked() {
    if (!this.validate() || this.printer == null) {
      return;
    }

    this.isLoading = true;

    const result = await PrinterModule.UpdatePrinterNaming([this.printer.Id, this.machineName]);

    this.isLoading = false;

    if (result != null) {
      toast.success('Printer updated successfuly', {
        position: POSITION.BOTTOM_RIGHT,
      });

      this.CloseButtonClicked();
    } else {
      toast.error('Could not update printer', {
        position: POSITION.BOTTOM_RIGHT,
      });
    }
  }

  ResetButtonClicked() {
    if (this.printer == null) return;

    this.machineName = this.printer.Name;
  }
  //#endregion

  //#region EVENTS
  @Emit('close-clicked')
  CloseButtonClicked() {}
  //#endregion

  //#region TRANSLATIONS
  private get AddCaption() {
    return en.add.growFirst();
  }

  private get ApplyCaption(): string {
    return en.apply.titleCase();
  }
  private get ResetCaption(): string {
    return en.reset.titleCase();
  }

  private get EditMachineCaption() {
    return en.editMachine.growFirst();
  }

  private get MachineIdCaption() {
    return en.machineId.growFirst();
  }

  private get MachineNameCaption() {
    return en.nameCaption.growFirst();
  }

  private get ModelCaption() {
    return en.model.growFirst();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.machine-editor {
  background: var(--editor-background);
  border-radius: 6px;

  .errors-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      color: var(--editor-error-text);
      font-size: 14px;
    }
  }
}
</style>
