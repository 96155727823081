<template>
  <div class="jobs-view-container px-3 py-3">
    <div class="header">
      <StartNewJobButton
        test-id="jobs.startNewJobButton"
        class="new-job-button"
        :label="StartNewJobCaption"
        @clicked="StartNewJobClicked"
      />

      <div v-if="ShowCurrentJobs" class="priter-switches-container ml-1">
        <div class="printer-switches" data-testid="jobs.printerSwitches">
          <DarkButton
            v-for="printer of Printers"
            :key="printer.Id.toString()"
            :label="printer.Name"
            test-id="jobs.printerSwitches.printer"
            :class="['printer-switch', IsPrinterHidden(printer) ? '' : 'active']"
            @clicked="TogglePrinterVisiblity(printer)"
          />
        </div>
      </div>

      <div class="tabs" data-testid="jobs.tabs">
        <div
          v-for="tab in availableTabs"
          :key="tab"
          :data-testid="`jobs.tab.${tab}`"
          :class="['tab', IsSelectedTab(tab)]"
          @click="IsSelectedTab(tab) ? undefined : SwitchToTab(tab, undefined, true)"
        >
          <span>{{ ReadableTabString(tab) }}</span>
        </div>
      </div>
    </div>

    <div class="selected-tab-container thin-scroll">
      <KeepAlive>
        <CurrentJobs
          v-if="ShowCurrentJobs"
          :hiddenPrinterIds="hiddenPrinterIds"
          @openMachine="PrinterClicked"
          @openSource="SourceClicked"
          @openUser="OwnerClicked"
        />
        <ArchiveJobs
          v-else-if="ShowArchiveJobs"
          @printer-clicked="PrinterClicked"
          @source-clicked="SourceClicked"
          @owner-clicked="OwnerClicked"
        />
      </KeepAlive>
    </div>
  </div>
</template>

<script lang="ts">
import DarkButton from '@/components/buttons/DarkButton.vue';
import RegularButton from '@/components/buttons/RegularButton.vue';
import JobCard from '@/components/presentation/JobCard.vue';
import JobEditor from '@/components/forms/JobEditor.vue';
import JobTimeline from '@/components/presentation/JobTimeline.vue';
import en from '@/localization/en';
import { Printer, Source, User } from '@/models/Entities';
import { JobsRouteTab, Routes } from '@/router/routes';
import { GlobalDataModule } from '@/store/modules/globalDataModule';
import { JobModule } from '@/store/modules/jobModule';
import { PrinterModule } from '@/store/modules/printerModule';
import { SourceModule } from '@/store/modules/sourceModule';
import ComponentHelper from '@/util/ComponentHelper';
import { Guid } from 'guid-typescript';
import { Component, Vue, Watch, Emit } from 'vue-property-decorator';
import ArchiveJobs from './tabs/ArchiveJobs.vue';
import CurrentJobs from './tabs/CurrentJobs.vue';

import StartNewJobDialog, { StartNewJobDialogResult } from '@/views/dialogs/StartNewJobDialog.vue';
import { create } from 'vue-modal-dialogs';
import StartNewJobButton from '@/components/buttons/StartNewJobButton.vue';
import { AsyncBatchQueueSignalR } from '@/store/util/Globals';

const StartNewJob = create<Printer | null, boolean, StartNewJobDialogResult | null>(
  StartNewJobDialog,
  'printer',
  'backButtonAvailable',
);

@Component({
  components: {
    RegularButton: RegularButton,
    DarkButton: DarkButton,
    JobCard: JobCard,
    JobEditor: JobEditor,
    JobTimeline: JobTimeline,
    CurrentJobs: CurrentJobs,
    ArchiveJobs: ArchiveJobs,
    StartNewJobButton: StartNewJobButton,
  },
  name: 'jobs-new',
})
export default class JobsNew extends Vue {
  @Watch('$route')
  private async OnRouteChanged() {
    await this.ChangeTabToQueryParam();
  }

  //#region STATE
  private availableTabs: JobsRouteTab[] = [];
  private printers: Printer[] = [];
  private hiddenPrinterIds: Guid[] = [];

  private get Printers() {
    return PrinterModule.Printers.sort((a, b) => (a.Name > b.Name ? 1 : -1));
  }

  private get currentTab(): JobsRouteTab {
    return GlobalDataModule.JobsViewState.selectedTab;
  }

  private get ShowCurrentJobs() {
    return this.currentTab == JobsRouteTab.CURRENT;
  }

  private get ShowArchiveJobs() {
    return this.currentTab == JobsRouteTab.ARCHIVE;
  }
  //#endregion

  //#region LOGIC
  //#region TABS
  private FillAvailableTabs() {
    this.availableTabs = [];

    this.availableTabs.push(JobsRouteTab.CURRENT);
    this.availableTabs.push(JobsRouteTab.ARCHIVE);
  }

  private async ChangeTabToQueryParam() {
    var tabFromRoute = this.$route.query.tab as JobsRouteTab;

    if (
      tabFromRoute !== undefined &&
      tabFromRoute !== this.currentTab &&
      this.availableTabs.firstOrDefault(a => a === tabFromRoute) !== null
    ) {
      await this.SwitchToTab(tabFromRoute);
    } else if (tabFromRoute === undefined) {
      await this.SwitchToTab(this.currentTab);
    }
  }

  private async SwitchToTab(
    tab: JobsRouteTab,
    params: Record<string, string> | undefined = undefined,
    clearQuery: boolean = false,
  ) {
    await GlobalDataModule.ChangeJobsViewTab(tab);

    if (this.$route.query.tab == null) {
      this.$router
        .replace({
          name: Routes.JOBS,
          query: clearQuery ? { tab: tab, ...params } : { ...this.$route.query, tab: tab, ...params },
        })
        .catch(() => {});
    } else {
      this.$router
        .push({
          name: Routes.JOBS,
          query: clearQuery ? { tab: tab, ...params } : { ...this.$route.query, tab: tab, ...params },
        })
        .catch(() => {});
    }
  }

  private ReadableTabString(tab: JobsRouteTab) {
    return ComponentHelper.GetReadableJobsViewTab(tab).growFirst();
  }

  private IsSelectedTab(tab: JobsRouteTab): string {
    return this.currentTab === tab ? 'selected' : '';
  }
  //#endregion
  private async SubscribeToJobGroups() {
    AsyncBatchQueueSignalR.Queue({
      Batch: async () => {
        await JobModule.SubscribeToCompanyJobsGroup();
        await SourceModule.SubscribeToCompanySourcesGroup();
        await PrinterModule.SubscribeToCompanyPrintersGroup();
      },
    });
  }

  private async UnsubscribeFromJobGroups() {
    AsyncBatchQueueSignalR.Queue({
      Batch: async () => {
        await JobModule.DeleteFromCompanyJobsGroup();
        await SourceModule.DeleteFromCompanySourcesGroup();
        await PrinterModule.DeleteFromCompanyPrintersGroup();
      },
    });
  }

  private async StartNewJobClicked() {
    await StartNewJob(null, true);
  }

  private IsPrinterHidden(printer: Printer) {
    return this.hiddenPrinterIds.firstOrDefault(a => a.toString() == printer.Id.toString()) != null;
  }

  private TogglePrinterVisiblity(printer: Printer) {
    if (this.IsPrinterHidden(printer)) {
      const found = this.hiddenPrinterIds.firstOrDefault(a => a.toString() == printer.Id.toString())!;

      this.hiddenPrinterIds.delete(found);
      GlobalDataModule.RemoveHiddenJobsViewTimelinePrinter(found);

      return;
    }

    GlobalDataModule.AddHiddenJobsViewTimelinePrinter(printer.Id);
    this.hiddenPrinterIds.push(printer.Id);
  }
  //#endregion

  //#region HOOKS
  async created() {
    this.FillAvailableTabs();
    await this.ChangeTabToQueryParam();

    for (const printerId of GlobalDataModule.JobsViewState.currentTabViewState.hiddenPrinterIds) {
      this.hiddenPrinterIds.push(Guid.parse(printerId));
    }
  }

  async mounted() {
    await this.SubscribeToJobGroups();
  }

  async beforeDestroy() {
    await this.UnsubscribeFromJobGroups();
  }
  //#endregion

  //#region EVENTS
  @Emit('openUser')
  private async OwnerClicked(user: User) {
    return user.Id;
  }

  @Emit('openMachine')
  private async PrinterClicked(printer: Printer) {
    return printer.Id;
  }

  @Emit('openSource')
  private async SourceClicked(source: Source) {
    return source.Id;
  }
  //#endregion

  //#region TRANSLATIONS
  private get StartNewJobCaption(): string {
    return en.startNewJob.toUpperCase();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.jobs-view-container {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  color: #e1e1e1;
  height: fit-content;

  width: 100%;

  .new-job-button {
    width: 350px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 1rem;

  h1 {
    font-size: 20px;
    padding: 0;
    margin: 0;
    margin-left: 0.65rem;

    &.shop-title {
      font-size: 24px;
      letter-spacing: 2px;
    }
  }

  .tabs {
    display: flex;
    flex-direction: row;
    user-select: none;
    height: fit-content;

    .tab {
      font-size: 16px;
      margin: 0 4px;
      padding: 3px 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      border-radius: 6px;
      outline: 1px solid;
      outline-color: transparent;
      outline-offset: -1px;

      &.selected {
        background-color: var(--main-orange);
      }

      &:hover:not(.selected) {
        outline-color: var(--main-orange);
      }

      span {
        padding: 0;
        margin: 0;
      }

      transition:
        background-color 150ms ease-in,
        outline-color 150ms ease-in;
    }
  }

  .start-new-job {
    min-width: 352px;
  }
}

.selected-tab-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
  display: flex;
}

.priter-switches-container {
  white-space: nowrap;
}

.printer-switches {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .printer-switch {
    margin: 0 0.5rem;
    border-radius: 18px;
    z-index: 2;

    transition: all 0.2s ease-out;

    &.active {
      outline: 1px solid #9b9b9b;
    }

    &:not(.active) {
      outline: none;
      opacity: 0.5;
    }
  }
}
</style>
