<template>
  <div class="group-editor">
    <EditorHeader>
      <template slot="header">
        <span>
          {{ GroupName }}
        </span>
      </template>
      <template slot="buttons">
        <HeaderIconButton
          test-id="sourceCategoryEditor.deleteButton"
          iconName="fa-trash"
          @clicked="deleteButtonClicked"
        />
        <HeaderIconButton
          test-id="sourceCategoryEditor.closeButton"
          iconName="fa-times"
          @clicked="closeButtonClicked"
        />
      </template>
    </EditorHeader>

    <EditorContent>
      <text-input
        :label="NameCaption"
        :value.sync="name"
        :labelWidth="labelWidth"
        :invalid="nameValidationFailed"
        :disabled="isLoading"
        placeholder="Enter group name..."
        test-id="sourceCategoryEditor.nameInput"
      />

      <fade-transition :maxHeight="28">
        <div v-if="validationErrors.length != 0" class="errors-container">
          <span class="mt-2">{{ validationErrors[0] }}</span>
        </div>
      </fade-transition>

      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </EditorContent>

    <EditorFooter>
      <template slot="buttons">
        <IconButton
          class="mr-3"
          :disabled="isLoading || !HasChanges"
          iconName="fa-check"
          :text="ApplyCaption"
          test-id="sourceCategoryEditor.applyButton"
          @clicked="applyButtonClicked"
        />
        <IconButton
          class="mr-3"
          :disabled="isLoading"
          iconName="fa-sync-alt"
          :text="ResetCaption"
          test-id="sourceCategoryEditor.resetButton"
          @clicked="resetButtonClicked"
        />
        <IconButton
          :disabled="isLoading"
          iconName="fa-times"
          :text="CancelCaption"
          test-id="sourceCategoryEditor.cancelButton"
          @clicked="closeButtonClicked"
        />
      </template>
    </EditorFooter>
  </div>
</template>

<script lang="ts">
import en from '@/localization/en';
import { toast } from '@/main';
import { SourceGroup } from '@/models/Entities';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { SourceGroupModule } from '@/store/modules/sourceGroupModule';
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import { POSITION } from 'vue-toastification';
import FadeTransition from '../presentation/FadeTransition.vue';
import EditorContent from '@/components/forms/base/EditorContent.vue';
import EditorFooter from '@/components/forms/base/EditorFooter.vue';
import EditorHeader from '@/components/forms/base/EditorHeader.vue';
import IconButton from '../buttons/IconButton.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import HeaderIconButton from '@/components/buttons/HeaderIconButton.vue';

@Component({
  name: 'source-category-editor',
  components: {
    HeaderIconButton,
    FadeTransition: FadeTransition,
    IconButton: IconButton,
    EditorHeader: EditorHeader,
    EditorContent: EditorContent,
    EditorFooter: EditorFooter,
    TextInput: TextInput,
  },
})
export default class SourceCategoryEditor extends Vue {
  labelWidth: number = 50;

  @Prop()
  private sourceGroup!: SourceGroup;

  @Watch('sourceGroup', { immediate: true, deep: true })
  OnGroupChanged() {
    this.setStateFromGroup(this.sourceGroup);
  }

  //#region STATE
  private validationTimeoutHandle: number = -1;
  private nameValidationFailed: boolean = false;
  private validationErrors: string[] = [];

  private name: string = '';

  private isLoading: boolean = false;

  private get GroupName() {
    return this.sourceGroup.Name;
  }

  private get HasChanges() {
    return this.name != this.sourceGroup.Name;
  }
  //#endregion

  //#region LOGIC
  private resetButtonClicked() {
    this.name = this.sourceGroup.Name;
  }

  private setStateFromGroup(group: SourceGroup) {
    this.name = group.Name;
  }

  private clearValidationErrors() {
    if (this.validationTimeoutHandle !== -1) {
      clearTimeout(this.validationTimeoutHandle);
    }

    this.validationErrors = [];
    this.nameValidationFailed = false;
    this.validationTimeoutHandle = -1;
  }

  private validate(): boolean {
    this.clearValidationErrors();

    // Validate
    if (this.name == null || this.name.length == 0) {
      this.validationErrors.push('Enter group name');
      this.nameValidationFailed = true;
    }

    const failed = this.validationErrors.length != 0;

    if (failed) {
      this.validationTimeoutHandle = window.setTimeout(() => {
        this.clearValidationErrors();
      }, 3500);
    }

    return !failed;
  }
  //#endregion

  //#region EVENTS
  @Emit('close-clicked')
  private closeButtonClicked() {}

  @Emit('apply-clicked')
  private async applyButtonClicked() {
    if (!this.validate()) {
      return null;
    }

    this.isLoading = true;

    // Todo: add logic
    const result = await SourceGroupModule.UpdateSourceGroup([this.sourceGroup.Id, this.name]);

    if (result.result == RequestStatus.OK) {
      this.isLoading = false;
      return true;
    }

    toast.error('Could not update source group', {
      position: POSITION.BOTTOM_RIGHT,
    });

    this.isLoading = false;
    return false;
  }

  @Emit('delete-clicked')
  private async deleteButtonClicked() {
    this.isLoading = true;

    const result = await SourceGroupModule.DeleteGroup(this.sourceGroup.Id);

    if (result[0] == RequestStatus.OK) {
      this.isLoading = false;
      return true;
    }

    this.isLoading = false;
    return false;
  }
  //#endregion

  //#region HOOKS
  //#endregion

  //#region TRANSLATIONS
  private get ApplyCaption(): string {
    return en.apply.titleCase();
  }
  private get ResetCaption(): string {
    return en.reset.titleCase();
  }
  private get CancelCaption(): string {
    return en.cancel.titleCase();
  }
  private get NameCaption(): string {
    return en.nameCaption.growFirst();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.group-editor {
  background: var(--editor-background);
  border-radius: 6px;

  .errors-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      color: var(--editor-error-text);
      font-size: 14px;
    }
  }
}
</style>
