<template>
  <div class="add-company">
    <EditorHeader>
      <template slot="header">
        <span>{{ AddCompanyCaption }}</span>
      </template>

      <template slot="buttons">
        <HeaderIconButton test-id="addCompany.deleteButton" iconName="fa-times" @clicked="closeButtonClicked" />
      </template>
    </EditorHeader>

    <EditorContent>
      <TextInput
        class="mb-2"
        :label="NameCaption"
        :value.sync="name"
        :labelWidth="labelWidth"
        :invalid="nameValidationFailed"
        :disabled="isLoading"
        placeholder="Enter company name..."
        test-id="addCompany.nameInput"
      />

      <SelectInput
        class="mb-2"
        :label="CountryCaption"
        :options.sync="countryItems"
        :disabled="isLoading"
        :labelWidth="labelWidth"
        :readonly="false"
        :selected-option.sync="country"
        :GetId="a => a.toString()"
        :GetName="a => GetCountryName(a)"
        test-id="addCompany.countrySelect"
      >
      </SelectInput>

      <TextInput
        class="mb-2"
        :label="DescriptionCaption"
        :value.sync="description"
        :labelWidth="labelWidth"
        :invalid="descriptionValidationFailed"
        :disabled="isLoading"
        placeholder="Enter company description..."
        test-id="addCompany.descriptionInput"
      />

      <TextInput
        class="mb-2"
        :label="AddressCaption"
        :value.sync="address"
        :labelWidth="labelWidth"
        :invalid="addressValidationFailed"
        :disabled="isLoading"
        placeholder="Enter company address..."
        test-id="addCompany.addressInput"
      />

      <TextInput
        class="mb-2"
        :label="CompanyEmailCaption"
        :value.sync="email"
        :labelWidth="labelWidth"
        :invalid="companyEmailValidationFailed"
        :disabled="isLoading"
        placeholder="Enter company email..."
        test-id="addCompany.emailInput"
      />

      <TextInput
        class="mb-2"
        :label="FirstNameCaption"
        :value.sync="adminFirstName"
        :labelWidth="labelWidth"
        :invalid="firstNameValidationFailed"
        :disabled="isLoading"
        placeholder="Enter user first name..."
        test-id="addCompany.adminFirstNameInput"
      />

      <TextInput
        class="mb-2"
        :label="SecondNameCaption"
        :value.sync="adminSecondName"
        :labelWidth="labelWidth"
        :invalid="secondNameValidationFailed"
        :disabled="isLoading"
        placeholder="Enter user second name..."
        test-id="addCompany.adminSecondNameInput"
      />

      <TextInput
        class="mb-2"
        :label="EmailCaption"
        :value.sync="adminEmail"
        :labelWidth="labelWidth"
        :invalid="emailValidationFailed"
        :disabled="isLoading"
        placeholder="Enter user email..."
        test-id="addCompany.adminEmailInput"
      />

      <TextInput
        :label="PasswordCaption"
        :value.sync="adminPassword"
        :labelWidth="labelWidth"
        :invalid="passwordValidationFailed"
        :disabled="isLoading"
        placeholder="Enter user password..."
        type="password"
        test-id="addCompany.adminPasswordInput"
      />

      <fade-transition :maxHeight="28">
        <div v-if="validationErrors.length != 0" class="errors-container">
          <span class="mt-2">{{ validationErrors[0] }}</span>
        </div>
      </fade-transition>

      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </EditorContent>

    <EditorFooter>
      <template slot="buttons">
        <IconButton
          :disabled="isLoading"
          test-id="addCompany.addButton"
          iconName="fa-check"
          :text="AddCaption"
          @clicked="addButtonClicked"
        />
      </template>
    </EditorFooter>
  </div>
</template>

<script lang="ts">
import en from '@/localization/en';
import { CompanyModule, INewCompanyData } from '@/store/modules/companyModule';
import { Countries } from '@/models/responses/Countries';
import { Component, Vue } from 'vue-property-decorator';
import FadeTransition from '@/components/presentation/FadeTransition.vue';
import * as EmailValidator from 'email-validator';
import IconButton from '../buttons/IconButton.vue';
import HeaderIconButton from '../buttons/IconButton.vue';
import EditorFooter from '@/components/forms/base/EditorFooter.vue';
import EditorHeader from '@/components/forms/base/EditorHeader.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import EditorContent from '@/components/forms/base/EditorContent.vue';
import SelectInput from '@/components/inputs/SelectInput.vue';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { POSITION } from 'vue-toastification';
import { toast } from '@/main';

@Component({
  name: 'add-company',
  components: {
    HeaderIconButton,
    FadeTransition: FadeTransition,
    IconButton: IconButton,
    EditorHeader: EditorHeader,
    EditorContent: EditorContent,
    EditorFooter: EditorFooter,
    TextInput: TextInput,
    SelectInput: SelectInput,
  },
})
export default class AddCompany extends Vue {
  labelWidth: number = 125;

  //#region STATE
  private validationTimeoutHandle: number = -1;
  private nameValidationFailed: boolean = false;
  private descriptionValidationFailed: boolean = false;
  private countryValidationFailed: boolean = false;
  private addressValidationFailed: boolean = false;
  private firstNameValidationFailed: boolean = false;
  private secondNameValidationFailed: boolean = false;
  private passwordValidationFailed: boolean = false;
  private companyEmailValidationFailed: boolean = false;
  private emailValidationFailed: boolean = false;
  private validationErrors: string[] = [];

  private name: string = '';
  private description: string = '';
  private country: Countries = Countries.RussianFederation;
  private address: string = '';
  private email: string = '';
  private adminFirstName: string = '';
  private adminSecondName: string = '';
  private adminPassword: string = '';
  private adminEmail: string = '';

  private isLoading: boolean = false;

  private countryItems: Countries[] = [];
  private GetCountryName(country: Countries): string {
    return en.CountryNames[country];
  }
  //#endregion

  //#region STATE LOGIC
  private InitializeCountryItems() {
    for (let key in Countries) {
      if (!isNaN(Number(key))) continue;
      let country = Countries[key as keyof typeof Countries];
      this.countryItems.push(country);
    }
  }

  public clearState() {
    this.name = '';
    this.description = '';
    this.country = Countries.RussianFederation;
    this.address = '';
    this.email = '';
    this.adminFirstName = '';
    this.adminSecondName = '';
    this.adminPassword = '';
    this.adminEmail = '';
  }

  private clearValidationErrors() {
    if (this.validationTimeoutHandle !== -1) {
      clearTimeout(this.validationTimeoutHandle);
    }

    this.validationErrors = [];
    this.nameValidationFailed = false;
    this.descriptionValidationFailed = false;
    this.addressValidationFailed = false;
    this.firstNameValidationFailed = false;
    this.secondNameValidationFailed = false;
    this.passwordValidationFailed = false;
    this.emailValidationFailed = false;
    this.companyEmailValidationFailed = false;
    this.validationTimeoutHandle = -1;
  }

  private validate(): boolean {
    this.clearValidationErrors();

    // Validate
    if (this.name == null || this.name.length == 0) {
      this.validationErrors.push('Enter company name');
      this.nameValidationFailed = true;
    } else if (this.address == null || this.address.length == 0) {
      this.validationErrors.push('Enter company address');
      this.addressValidationFailed = true;
    } else if (this.email == null || this.email.length == 0) {
      this.validationErrors.push('Enter company email');
      this.companyEmailValidationFailed = true;
    } else if (this.adminFirstName == null || this.adminFirstName.length == 0) {
      this.validationErrors.push('Enter admin first name');
      this.firstNameValidationFailed = true;
    } else if (this.adminSecondName == null || this.adminSecondName.length == 0) {
      this.validationErrors.push('Enter admin second name');
      this.secondNameValidationFailed = true;
    } else if (this.adminPassword == null || this.adminPassword.length == 0) {
      this.validationErrors.push('Enter admin password');
      this.passwordValidationFailed = true;
    } else if (this.adminEmail == null || this.adminEmail.length == 0) {
      this.validationErrors.push('Enter admin email');
      this.emailValidationFailed = true;
    } else if (!EmailValidator.validate(this.adminEmail)) {
      this.validationErrors.push('Enter correct admin email');
      this.emailValidationFailed = true;
    } else if (!EmailValidator.validate(this.email)) {
      this.validationErrors.push('Enter correct company email');
      this.companyEmailValidationFailed = true;
    }

    const failed = this.validationErrors.length != 0;

    if (failed) {
      this.validationTimeoutHandle = window.setTimeout(() => {
        this.clearValidationErrors();
      }, 3500);
    }

    return !failed;
  }
  //#endregion

  //#region EVENTS
  private closeButtonClicked() {
    this.$emit('close-clicked');
  }

  private async addButtonClicked() {
    if (!this.validate()) {
      return;
    }

    const data: INewCompanyData = {
      name: this.name,
      description: this.description,
      country: this.country,
      address: this.address,
      email: this.email,
      adminFirstName: this.adminFirstName,
      adminSecondName: this.adminSecondName,
      adminPassword: this.adminPassword,
      adminEmail: this.adminEmail,
    };

    this.isLoading = true;

    const result = await CompanyModule.CreateCompany(data);

    this.isLoading = false;

    if (result.status === RequestStatus.OK) {
      this.clearState();
    } else {
      toast.error(result.message, {
        position: POSITION.BOTTOM_RIGHT,
      });
    }
  }
  //#endregion

  //#region HOOKS
  created() {
    this.InitializeCountryItems();
  }
  //#endregion

  //#region TRANSLATIONS
  private get AddCompanyCaption(): string {
    return en.addCompany.titleCase();
  }
  private get NameCaption(): string {
    return en.nameCaption.titleCase();
  }
  private get DescriptionCaption(): string {
    return en.description.titleCase();
  }
  private get CountryCaption(): string {
    return en.country.titleCase();
  }
  private get AddressCaption(): string {
    return en.address.titleCase();
  }
  private get CompanyEmailCaption(): string {
    return en.companyEmail.titleCase();
  }
  private get FirstNameCaption(): string {
    return en.firstName.titleCase();
  }
  private get SecondNameCaption(): string {
    return en.secondName.titleCase();
  }
  private get PasswordCaption(): string {
    return en.password.titleCase();
  }
  private get EmailCaption(): string {
    return en.email.titleCase();
  }
  private get AddCaption(): string {
    return en.add.titleCase();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.add-company {
  background: var(--editor-background);
  border-radius: 6px;

  .errors-container {
    display: flex;
    span {
      color: var(--editor-error-text);
      font-size: 14px;
    }
  }
}
</style>
