<template>
  <div :class="['icon-button', disabled ? 'disabled' : '']" :data-testid="testId" @click="Clicked">
    <i :class="['mr-1', iconName, iconType]" :style="{ 'font-size': iconSize + 'px' }" />
    <span v-if="text" :style="{ 'font-size': textSize + 'px' }">{{ text }}</span>
  </div>
</template>

<script lang="ts">
import { Prop, Component, Vue, Emit } from 'vue-property-decorator';

@Component
export default class IconButton extends Vue {
  @Prop({ default: 12 }) iconSize!: number;
  @Prop({ default: 'fas' }) iconType!: string;
  @Prop({ default: '' }) iconName!: string;

  @Prop({ default: '' }) text!: string;
  @Prop({ default: 12 }) textSize!: number;

  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: undefined }) testId?: string;

  @Emit('clicked')
  private Clicked() {}
}
</script>

<style lang="scss" scoped>
.icon-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: var(--icon-button-text);
  user-select: none;

  &:hover {
    opacity: 0.75;
  }
}
</style>
