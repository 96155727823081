<template>
  <unauthorized-page>
    <template slot="content">
      <div class="loading h-100">
        <span class="main">{{ LoadingCaption }}</span>
        <div class="spinner-container">
          <div class="spinner"></div>
        </div>
      </div>
    </template>
  </unauthorized-page>
</template>

<script lang="ts">
import { Routes } from '@/router/routes';
import { LoginModule } from '@/store/modules/loginModule';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'loading-page',
  components: {
    UnauthorizedPage: () => import('@/components/presentation/UnauthorizedPage.vue'),
  },
})
export default class LoadingPage extends Vue {
  @Watch('Initialized')
  private async OnInitializedChanged() {
    if (this.Initialized) {
      let forwardTo = this.$route.params.forwardTo;

      if (forwardTo == undefined) {
        forwardTo = Routes.MY_ACCOUNT;
      }

      if (!LoginModule.IsAuthorized) {
        this.$router.replace({
          name: Routes.LOGIN,
          query: this.$route.query,
        });
        return;
      }

      this.$router.replace({
        name: forwardTo,
        query: this.$route.query,
      });
    }
  }

  private get Initialized() {
    return LoginModule.Initialized;
  }

  private get Dots() {
    return this.dots;
  }

  private intervalId!: number;

  private dots: string = '';

  //#region LOGIN
  private CycleDots() {
    if (this.dots.length == 3) {
      this.dots = '';
      return;
    }

    this.dots += '.';
  }
  //#endregion

  //#region CREATED
  async created() {
    this.intervalId = window.setInterval(() => {
      this.CycleDots();
    }, 750);
  }

  async beforeDestroy() {
    clearInterval(this.intervalId);
  }
  //#endregion

  //#region TRANSLATIONS
  private get LoadingCaption() {
    return 'Loading';
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;

  .spinner-container {
    min-height: 60px;
    position: relative;
  }

  .main {
    color: #e3e3e3;
    font-size: 64px;
    font-weight: 100;
  }
}

.content {
  justify-content: center;
}
</style>
