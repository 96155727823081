<template>
  <div :class="['subscription-plan', isCurrent ? 'current' : '', selected ? 'selected' : '']">
    <div>
      <span class="price">
        {{ Price }}
      </span>
      <span class="period"> / {{ Period }} </span>
    </div>
    <div :class="['current-label', isCurrent ? 'visible' : '']">current</div>
  </div>
</template>

<script lang="ts">
import en from '@/localization/en';
import { ProductPrice } from '@/models/Entities';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ComponentHelper from '@/util/ComponentHelper';

@Component({
  components: {},
})
export default class SubscriptionPlanCard extends Vue {
  @Prop() price!: ProductPrice;
  @Prop() isCurrent!: boolean;
  @Prop() selected!: boolean;

  private get Price() {
    const currencySymbol = en.CurrencySymbols[this.price.currency.toUpperCase()];

    return currencySymbol + (this.price.price / 100).toFixed(2);
  }

  private get Period() {
    return ComponentHelper.GetReadableRecurringIntervalNonPlural(this.price.recurringInterval!).toLowerCase();
  }
}
</script>

<style lang="scss" scoped>
.subscription-plan {
  background-color: #535353;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding: 10px 12px;
  user-select: none;

  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  .price {
    font-size: 20px;
    color: var(--main-text);
  }

  .period {
    font-size: 12px;
  }

  .current-label {
    font-size: 13px;
    opacity: 0;

    &.visible {
      opacity: 1;
    }
  }

  &.selected {
    background-color: var(--main-orange);

    .current-label {
      color: var(--main-text);
    }
    .period {
      color: var(--main-text);
    }
  }
}
</style>
