<template>
  <AuraDialogWrapper
    ref="dialogWrapper"
    :headerText="'Change log configuration'"
    @closed="DialogWrapperClosed"
    @dismissed="DialogWrapperClosed"
  >
    <div class="change-log-container">
      <span class="message w-100 mb-1">Select a .lcfg file on your PC</span>
      <file-input
        class="w-100 mb-4"
        :disabled="isLoading"
        :readonly="false"
        :downloadable="false"
        :enableFileDrag="true"
        extension=".lcfg"
        :file-name.sync="configFileName"
        @file-changed="OnConfigFileChanged"
        @reset-file="ResetConfigFile"
      />
      <regular-button
        :disabled="!LoadedConfigFile"
        class="w-100 mt-1"
        :label="'Confirm'"
        @clicked="ConfirmButtonClicked"
      />

      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </div>
  </AuraDialogWrapper>
</template>

<script lang="ts">
import RegularButton from '@/components/buttons/RegularButton.vue';
import FileInput from '@/components/inputs/FileInput.vue';
import { toast } from '@/main';
import { Printer } from '@/models/Entities';
import { ConnectorModule } from '@/store/modules/connectorModule';
import { DialogComponent } from 'vue-modal-dialogs';
import { Component, Prop, Ref } from 'vue-property-decorator';
import { POSITION } from 'vue-toastification';
import AuraDialogWrapper from '@/views/dialogs/base/AuraDialogWrapper.vue';

const PRINTER_ID_FORM_KEY = 'printerId';
const LOG_CONFIG_FORM_KEY = 'logConfig';

@Component({
  components: {
    AuraDialogWrapper: AuraDialogWrapper,
    FileInput: FileInput,
    RegularButton: RegularButton,
  },
})
export default class ChangeLogConfigDialog extends DialogComponent<boolean> {
  @Prop() printer!: Printer;

  @Ref() dialogWrapper!: AuraDialogWrapper;

  //#region STATE
  private isLoading: boolean = false;
  private formData: FormData = new FormData();
  private configFileName: string = '';

  private get LoadedConfigFile() {
    return this.configFileName != '';
  }
  //#endregion

  //#region LOGIC
  private DialogWrapperClosed() {
    this.$close(false);
  }

  private OnConfigFileChanged(files: File[]) {
    const file = files[0];
    this.configFileName = file.name;
    this.ChangeFormFile(file, LOG_CONFIG_FORM_KEY);
  }

  private async ResetConfigFile() {
    let entry = this.formData.get(LOG_CONFIG_FORM_KEY);
    if (entry != null) {
      this.formData.delete(LOG_CONFIG_FORM_KEY);
      this.configFileName = '';
    }
  }

  private ChangeFormFile(file: File, key: string) {
    let entry = this.formData.get(key);
    if (entry != null) this.formData.delete(key);
    this.formData.append(key, file, file.name);
  }

  private async ConfirmButtonClicked() {
    this.formData.set(PRINTER_ID_FORM_KEY, this.printer.Id.toString());

    this.isLoading = true;

    const { response } = await ConnectorModule.UploadLogConfiguration(this.formData);

    this.isLoading = false;

    this.dialogWrapper.CloseDialog();

    if (response != null) {
      if (response.success) {
        toast.success('Log configuration updated succesffully', {
          position: POSITION.BOTTOM_RIGHT,
        });

        this.$close(true);
      } else {
        toast.error(`Error, while uploading log configuration. ${response.errorMessage}`, {
          position: POSITION.BOTTOM_RIGHT,
        });
      }
    } else {
      toast.error('Could not upload log configuration', {
        position: POSITION.BOTTOM_RIGHT,
      });
    }

    this.$close(false);
  }
  //#endregion

  //#region EVENTS
  //#endregion

  //#region TRANSLATIONS
  //#endregion
}
</script>

<style lang="scss" scoped>
.change-log-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 400px;

  .message {
    text-align: left;
    font-size: 14px;
  }
}
</style>
