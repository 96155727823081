<template>
  <div class="archive-jobs-container">
    <div class="left-panel">
      <ActionCard test-id="archiveJobs" class="archive-jobs overflow-auto" :headerText="ArchieveJobsCaption">
        <SortableHeader
          :header-items="ArchieveJobHeaders"
          :by.sync="SortByJobArchieveHeader"
          :mode.sync="JobArchieveSortMode"
          test-id="archiveJobs"
          @sortBy="OnJobArchievesSortBy"
        />
        <div class="thin-scroll overflow-overlay" @scroll="OnJobArchievesScrolled">
          <ListItemV3
            v-for="item in FullJobArchieves"
            :id="item.jobArchieve.Id"
            :key="item.jobArchieve.Id.toString()"
            class="cur-pointer"
            test-id="archiveJobs"
            :items="ArchieveJobItems(item)"
            :selected="IsArchiveSelected(item)"
            @openItem="OpenArchieveJob(item)"
          />
        </div>

        <div :class="['spinner-container', loadingJobArchieves ? '' : 'hidden']">
          <div :class="['spinner', loadingJobArchieves ? '' : 'hidden']"></div>
        </div>
      </ActionCard>
    </div>

    <div class="vertical-resizer"></div>

    <div class="right-panel">
      <JobArchiveEditor
        v-if="selectedArchive != null"
        :fullArchive="selectedArchive"
        @close-clicked="CloseJobArchiveClicked"
        @owner-clicked="JobArchiveOwnerClicked"
        @printer-clicked="JobArchivePrinterClicked"
        @source-clicked="JobArchiveSourceClicked"
      />
    </div>
  </div>
</template>

<script lang="ts">
import ActionCard from '@/components/presentation/ActionCard.vue';
import ListItemV3 from '@/components/presentation/ListItemV3.vue';
import SortableHeader from '@/components/presentation/SortableHeader.vue';
import en from '@/localization/en';
import { JobArchieve, Printer, Source, User } from '@/models/Entities';
import jobModule, { ArchieveJobQueryAdditions, JobModule } from '@/store/modules/jobModule';
import { LoginModule } from '@/store/modules/loginModule';
import { PrinterModule } from '@/store/modules/printerModule';
import { SourceModule } from '@/store/modules/sourceModule';
import { UserModule } from '@/store/modules/userModule';
import ComponentHelper, { HeaderItem, ItemData, SortMode } from '@/util/ComponentHelper';
import { GuidHelper } from '@/util/GuidHelper';
import { Guid } from 'guid-typescript';
import { delay } from 'lodash';
import { create } from 'vue-modal-dialogs';
import { Component, Vue, Watch, Emit } from 'vue-property-decorator';
import AuraMessageBoxDialog, { Result } from '@/views/dialogs/AuraMessageBoxDialog.vue';
import { FullJobArchieve } from '@/models/CompositeEntities';
import { ArchieveJobSortBy } from '@/models/requests/RequestMonolith';
import {
  SortFullJobArchieveByFileDESC,
  SortFullJobArchieveByFileASC,
  SortFullJobArchieveByPrinterDESC,
  SortFullJobArchieveByPrinterASC,
  SortFullJobArchieveDateEndDESC,
  SortFullJobArchieveDateEndASC,
  SortFullJobArchieveByStatusDESC,
  SortFullJobArchieveByStatusASC,
  SortFullJobArchieveByIdDESC,
} from '@/models/util/JobArchieveSortings';
import { Routes } from '@/router/routes';
import JobArchiveEditor from '@/components/forms/JobArchiveEditor.vue';

create<Result, String, String, Result>(AuraMessageBoxDialog, 'results', 'header', 'mainText');

@Component({
  components: {
    ActionCard: ActionCard,
    SortableHeader: SortableHeader,
    ListItemV3: ListItemV3,
    JobArchiveEditor: JobArchiveEditor,
  },
  name: 'archive-jobs',
})
export default class ArchiveJobs extends Vue {
  //#region WATCHERS
  @Watch('$route', { immediate: true, deep: true })
  private async OnRouteChanged() {
    const archiveId = this.$route.query.archiveId;

    if (archiveId !== undefined && Guid.isGuid(archiveId)) {
      this.ArchiveId = Guid.parse(archiveId as string);

      const loaded = this.FullJobArchieves.firstOrDefault(a => a.jobArchieve.Id.equals(this.ArchiveId!));

      this.selectedArchive = loaded;
    } else {
      this.ArchiveId = undefined;
      this.selectedArchive = null;
    }
  }
  //#endregion

  //#region STATE
  private _componentId!: Guid;

  private get CompanyId() {
    return LoginModule.Me!.CompanyId;
  }

  private ArchiveId: Guid | undefined = undefined;

  //#region ARCHIEVE JOBS
  private sortByJobArchieveHeader: HeaderItem | null = null;
  private jobArchieveSortMode: SortMode | null = null;
  private jobArchievePage: number = 0;
  private loadingJobArchieves: boolean = false;
  private canScrollLoadArchives: boolean = true;
  private selectedArchive: FullJobArchieve | null = null;

  private get SortByJobArchieveHeader(): HeaderItem | null {
    return this.sortByJobArchieveHeader;
  }
  private set SortByJobArchieveHeader(value: HeaderItem | null) {
    this.sortByJobArchieveHeader = value;
  }
  private get JobArchieveSortMode(): SortMode | null {
    return this.jobArchieveSortMode;
  }
  private set JobArchieveSortMode(value: SortMode | null) {
    this.jobArchieveSortMode = value;
  }

  private get JobArchieves(): JobArchieve[] {
    let jobArchieves = JobModule.JobArchieves.filter(a => GuidHelper.equalsWithNull(a.CompanyId, this.CompanyId)).map(
      a => a,
    );

    return jobArchieves;
  }

  private get FullJobArchieves(): FullJobArchieve[] {
    let fullJobArchieves: FullJobArchieve[] = [];

    for (let archieve of this.JobArchieves) {
      let printer = PrinterModule.Printers.singleOrDefault(a => a.Id.toString() === archieve.PrinterId.toString());
      if (printer == null) continue;
      let source = SourceModule.Sources.singleOrDefault(a => GuidHelper.equalsWithNull(a.Id, archieve.SourceId));
      let owner = UserModule.Users.singleOrDefault(a => GuidHelper.equalsWithNull(a.Id, archieve.UserId));

      let fullJob: FullJobArchieve = {
        jobArchieve: archieve,
        printer: printer,
        source: source,
        owner: owner,
      };

      if (
        this.selectedArchive != null &&
        fullJob.jobArchieve.Id.toString() == this.selectedArchive.jobArchieve.Id.toString()
      ) {
        this.selectedArchive = fullJob;
      }

      fullJobArchieves.push(fullJob);
    }

    if (this.ArchiveId != undefined) {
      this.selectedArchive = fullJobArchieves.firstOrDefault(
        a => a.jobArchieve.Id.toString() == this.ArchiveId!.toString(),
      );
    }

    fullJobArchieves = this.SortFullJobArchieves(fullJobArchieves);

    const maxCount = (this.jobArchievePage + 1) * 25;

    const toDisplay = fullJobArchieves.splice(0, maxCount);
    const toLoose = fullJobArchieves.slice(maxCount);

    JobModule.LooseJobArchieves([toLoose.map(a => a.jobArchieve), this._componentId]);
    JobModule.OccupyJobArchieves([toDisplay.map(a => a.jobArchieve), this._componentId]);

    SourceModule.LooseSources([toLoose.filter(a => a.source != null).map(a => a.source!), this._componentId]);
    SourceModule.OccupySources([toDisplay.filter(a => a.source != null).map(a => a.source!), this._componentId]);

    PrinterModule.LoosePrintersNew([toLoose.filter(a => a.source != null).map(a => a.printer), this._componentId]);
    PrinterModule.OccupyPrinters([toDisplay.filter(a => a.source != null).map(a => a.printer), this._componentId]);

    if (toLoose.length > 0) {
      JobModule.CollectJobArchieves();
    }

    return toDisplay;
  }

  //#region HEADERS & ITEMS
  private jobArchHeaderFile: HeaderItem = {
    caption: 'File',
    itemClass: ComponentHelper.GetWidthClass('grow-2'),
    isSortable: true,
  };
  private jobArchHeaderPrinter: HeaderItem = {
    caption: 'Printer',
    itemClass: ComponentHelper.GetWidthClass('grow-1'),
    isSortable: true,
  };
  private jobArchHeaderFinished: HeaderItem = {
    caption: 'Finished',
    itemClass: ComponentHelper.GetWidthClass(175),
    isSortable: true,
    width: 175,
  };
  private jobArchHeaderStatus: HeaderItem = {
    caption: 'Status',
    itemClass: ComponentHelper.GetWidthClass(100),
    isSortable: true,
  };
  private get ArchieveJobHeaders(): HeaderItem[] {
    const result: HeaderItem[] = [];

    result.push(this.jobArchHeaderFile);
    result.push(this.jobArchHeaderPrinter);
    result.push(this.jobArchHeaderFinished);
    result.push(this.jobArchHeaderStatus);

    return result;
  }

  private ArchieveJobItems(value: FullJobArchieve): ItemData[] {
    const result: ItemData[] = [];

    let itemFile = new ItemData(
      'File',
      value.jobArchieve.LocalFilename != null
        ? value.jobArchieve.LocalFilename
        : value.source == null
          ? '—'
          : value.source.CodeFileName,
      'grow-2',
    );

    if (value.jobArchieve.LocalFilename != null) {
      itemFile = new ItemData(
        'File',
        value.jobArchieve.LocalFilename != null
          ? value.jobArchieve.LocalFilename
          : value.source == null
            ? '—'
            : value.source.CodeFileName,
        'grow-2',
        undefined,
        'fa-sd-card',
        'far',
        undefined,
        true,
        'This job was printed from a local file',
      );
    }

    let itemPrinter = new ItemData('Printer', value.printer.Name, 'grow-1');
    let itemFinished = new ItemData(
      'Finished',
      value.jobArchieve.EndTime == null ? '—' : ComponentHelper.GetReadableDateTime(value.jobArchieve.EndTime, true),
      175,
    );
    let itemStatus = new ItemData(
      'Status',
      value.jobArchieve.EndStatus == null ? '—' : ComponentHelper.GetReadableEndStatus(value.jobArchieve.EndStatus),
      100,
    );

    result.push(itemFile);
    result.push(itemPrinter);
    result.push(itemFinished);
    result.push(itemStatus);

    return result;
  }
  //#endregion
  //#endregion
  //#endregion

  //#region LOGIC
  //#region JOB ARCHIEVE RELATED
  async OnJobArchievesSortBy() {
    await JobModule.LooseJobArchieves([this.JobArchieves, this._componentId!]);
    await JobModule.CollectJobArchieves();

    this.jobArchievePage = 0;
    await this.LoadMoreJobArchieves();
  }

  private GetJobArchieveSortBy(headerItem: HeaderItem | null): ArchieveJobSortBy | null {
    if (headerItem === this.jobArchHeaderFile) return ArchieveJobSortBy.BY_FILE;
    else if (headerItem === this.jobArchHeaderPrinter) return ArchieveJobSortBy.BY_PRINTER;
    else if (headerItem === this.jobArchHeaderFinished) return ArchieveJobSortBy.BY_DATE_END;
    else if (headerItem === this.jobArchHeaderStatus) return ArchieveJobSortBy.BY_STATUS;

    return null;
  }

  async LoadMoreJobArchieves() {
    this.loadingJobArchieves = true;

    let archJobAdditions = new ArchieveJobQueryAdditions();
    archJobAdditions.pageSize = 25;
    archJobAdditions.page = this.jobArchievePage;
    archJobAdditions.sortBy = this.GetJobArchieveSortBy(this.SortByJobArchieveHeader);
    archJobAdditions.sortMode = this.JobArchieveSortMode;

    const jobArchieves = await JobModule.JustLoadMoreJobsArchievesForCompany([archJobAdditions, this.CompanyId]);

    if (jobArchieves.any()) {
      let printerIds = GuidHelper.distinct(jobArchieves.map(a => a.PrinterId));
      let sourceIds = jobModule.GetSourceIdsByArchieves(jobArchieves);
      let userIds = jobModule.GetUserIdsByArchieves(jobArchieves);

      await Promise.all([
        PrinterModule.LoadPrinters(printerIds),
        SourceModule.LoadSources(sourceIds),
        UserModule.LoadUsers(userIds),
      ]);

      for (let jobArchieve of jobArchieves) {
        JobModule.AddJobArchieveToModule(jobArchieve);
      }
    }

    this.loadingJobArchieves = false;
  }

  async OnJobArchievesScrolled(e: any) {
    const currScroll = e.target.scrollTop;
    const endScroll = e.target.scrollHeight - e.target.clientHeight;

    if (currScroll === endScroll && currScroll > 0 && this.canScrollLoadArchives) {
      this.canScrollLoadArchives = false;
      ++this.jobArchievePage;
      await this.LoadMoreJobArchieves();
      delay(() => {
        this.canScrollLoadArchives = true;
      }, 500);
    }
  }

  SortFullJobArchieves(jobArchs: FullJobArchieve[]): FullJobArchieve[] {
    let sortBy =
      this.SortByJobArchieveHeader == null ? undefined : this.GetJobArchieveSortBy(this.SortByJobArchieveHeader);

    if (
      (this.JobArchieveSortMode == null && sortBy !== undefined) ||
      (this.JobArchieveSortMode != null && sortBy === undefined) ||
      jobArchs.length == 0
    )
      return jobArchs;

    if (sortBy === ArchieveJobSortBy.BY_FILE) {
      if (this.JobArchieveSortMode === SortMode.ASC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveByFileASC);
      if (this.JobArchieveSortMode === SortMode.DESC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveByFileDESC);
    } else if (sortBy === ArchieveJobSortBy.BY_PRINTER) {
      if (this.JobArchieveSortMode === SortMode.ASC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveByPrinterASC);
      if (this.JobArchieveSortMode === SortMode.DESC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveByPrinterDESC);
    } else if (sortBy === ArchieveJobSortBy.BY_DATE_END) {
      if (this.JobArchieveSortMode === SortMode.ASC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveDateEndASC);
      if (this.JobArchieveSortMode === SortMode.DESC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveDateEndDESC);
    } else if (sortBy === ArchieveJobSortBy.BY_STATUS) {
      if (this.JobArchieveSortMode === SortMode.ASC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveByStatusASC);
      if (this.JobArchieveSortMode === SortMode.DESC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveByStatusDESC);
    }

    return jobArchs;
  }

  private OpenArchieveJob(archieveJob: FullJobArchieve) {
    if (this.ArchiveId?.equals(archieveJob.jobArchieve.Id)) {
      return;
    }

    this.$router.push({
      name: Routes.JOBS,
      query: {
        ...this.$route.query,
        archiveId: archieveJob.jobArchieve.Id.toString(),
      },
    });

    this.selectedArchive = archieveJob;
  }

  private IsArchiveSelected(item: FullJobArchieve) {
    return item.jobArchieve.Id.toString() == this.selectedArchive?.jobArchieve.Id.toString();
  }

  private async CloseJobArchiveClicked() {
    this.$router.push({
      name: Routes.JOBS,
      query: { tab: 'archive', archiveId: undefined },
    });
  }

  @Emit('owner-clicked')
  private async JobArchiveOwnerClicked(owner: User | null) {
    return owner;
  }

  @Emit('printer-clicked')
  private async JobArchivePrinterClicked(printer: Printer) {
    return printer;
  }

  @Emit('source-clicked')
  private async JobArchiveSourceClicked(source: Source | null) {
    return source;
  }

  //#endregion
  //#endregion

  //#region HOOKS
  async created() {
    this._componentId = Guid.create();

    this.JobArchieveSortMode = SortMode.DESC;
    this.SortByJobArchieveHeader = this.jobArchHeaderFinished;
  }

  async activated() {
    await JobModule.LooseJobArchieves([this.JobArchieves, this._componentId!]);
    await JobModule.CollectJobArchieves();

    await SourceModule.LooseSources([
      this.FullJobArchieves.filter(a => a.source != null).map(a => a.source!),
      this._componentId,
    ]);
    await SourceModule.CollectSources();

    this.jobArchievePage = 0;
    await this.LoadMoreJobArchieves();
  }

  async deactivated() {
    await JobModule.LooseJobArchieves([this.JobArchieves, this._componentId!]);
    await JobModule.CollectJobArchieves();

    await SourceModule.LooseSources([
      this.FullJobArchieves.filter(a => a.source != null).map(a => a.source!),
      this._componentId,
    ]);
    await SourceModule.CollectSources();
  }

  async beforeDestroy() {
    await JobModule.LooseJobArchieves([this.JobArchieves, this._componentId!]);
    await JobModule.CollectJobArchieves();

    await SourceModule.LooseSources([
      this.FullJobArchieves.filter(a => a.source != null).map(a => a.source!),
      this._componentId,
    ]);
    await SourceModule.CollectSources();
  }
  //#endregion

  //#region TRANSLATIONS
  private get InfoCaption() {
    return en.info.growFirst();
  }

  private get ActiveDevicesCaption() {
    return en.activeDevices.growFirst();
  }

  private get CurrentJobsCaption() {
    return en.currentJobs.growFirst();
  }

  private get ArchieveJobsCaption() {
    return en.archieveJobs.growFirst();
  }

  private get EmailCaption() {
    return en.email.growFirst();
  }

  private get FirstNameCaption() {
    return en.firstName.growFirst();
  }

  private get SecondNameCaption() {
    return en.secondName.growFirst();
  }

  private get PhoneCaption() {
    return en.phone.growFirst();
  }

  private get plasticCaption(): string {
    return en.plastic;
  }
  private get compositeCaption(): string {
    return en.composite;
  }
  private get GrCaption(): string {
    return en.gr;
  }
  private get MCaption(): string {
    return en.meter;
  }
  private get WeekCaption(): string {
    return en.week;
  }
  private get MonthCaption(): string {
    return en.month;
  }
  private get WholeCaption(): string {
    return en.total;
  }
  private get JobsCaption(): string {
    return en.jobs;
  }
  private get PrintHoursCaption(): string {
    return en.printHours;
  }
  private get FilesCaption(): string {
    return en.files;
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.archive-jobs-container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: auto;

  .left-panel {
    width: 55%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .right-panel {
    overflow: auto;
    width: 45%;
    display: flex;
    flex-direction: column;
    min-width: 350px;
  }

  .vertical-resizer {
    cursor: col-resize;
    width: 10px;
    margin: 0 3px;
  }

  .spinner-container {
    min-height: 40px;
    margin-top: 20px;
    position: relative;
    opacity: 1;

    &.hidden {
      opacity: 0;
      min-height: 0px;
      margin-top: 0px;
    }

    transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);
  }
}
</style>
