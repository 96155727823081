import { Guid } from 'guid-typescript';
import * as Collections from 'typescript-collections';
import { GuidHelper } from '@/util/GuidHelper';

export default class GC {
  private dictSubs = new Collections.Dictionary<string, Guid[]>();
  UpdateSubscription(objectId: Guid, subscriberId?: Guid) {
    const objectIdentifier = objectId.toString().toLowerCase();
    const subs = this.dictSubs.getValue(objectIdentifier);
    if (!subs) {
      const subscriberIds: Guid[] = [];
      if (subscriberId) subscriberIds.push(subscriberId);
      this.dictSubs.setValue(objectIdentifier, subscriberIds);
    } else if (subscriberId && !subs.includes(subscriberId)) subs.push(subscriberId);
  }
  DeleteSubscription(objectId: Guid, subscriberId?: Guid) {
    const objectIdentifier = objectId.toString().toLowerCase();
    if (!subscriberId) {
      this.dictSubs.remove(objectIdentifier);
    } else {
      const subs = this.dictSubs.getValue(objectIdentifier);
      if (subs && GuidHelper.includes(subs, subscriberId)) {
        GuidHelper.delete(subs, subscriberId);
      }
    }
  }
  Collect(): Guid[] {
    const loosen: Guid[] = [];
    this.dictSubs.forEach((a, b) => {
      if (b.length === 0) loosen.push(Guid.parse(a));
    });
    for (const loose of loosen) {
      this.dictSubs.remove(loose.toString().toLowerCase());
    }
    return loosen;
  }
  Occupy(occupied: Guid[], by: Guid): Guid[] {
    for (const item of occupied) {
      this.UpdateSubscription(item, by);
    }
    return this.Collect();
  }
  Loose(items: Guid[], by: Guid): Guid[] {
    for (const item of items) {
      this.DeleteSubscription(item, by);
    }
    return this.Collect();
  }
  async ForceLoose(items: Guid[]): Promise<void> {
    for (const item of items) {
      this.dictSubs.remove(item.toString().toLowerCase());
    }
  }
}

export class New_GC {
  private dictSubs = new Collections.Dictionary<string, Guid[]>();

  isLocked: boolean = false;

  private UpdateSubscription(objectId: Guid, subscriberId?: Guid) {
    const objectIdentifier = objectId.toString().toLowerCase();
    const subs = this.dictSubs.getValue(objectIdentifier);
    if (!subs) {
      const subscriberIds: Guid[] = [];
      if (subscriberId) subscriberIds.push(subscriberId);
      this.dictSubs.setValue(objectIdentifier, subscriberIds);
    } else if (subscriberId && !subs.includes(subscriberId)) subs.push(subscriberId);
  }

  private DeleteSubscription(objectId: Guid, subscriberId?: Guid) {
    const objectIdentifier = objectId.toString().toLowerCase();
    if (!subscriberId) {
      this.dictSubs.remove(objectIdentifier);
    } else {
      const subs = this.dictSubs.getValue(objectIdentifier);

      if (subs && GuidHelper.includes(subs, subscriberId)) {
        GuidHelper.delete(subs, subscriberId);
      }
    }
  }

  Add(ids: Guid[]) {
    for (const item of ids) {
      this.UpdateSubscription(item, undefined);
    }
  }

  Remove(ids: Guid[]) {
    for (const item of ids) {
      this.DeleteSubscription(item);
    }
  }

  Collect(): Guid[] {
    if (this.isLocked) {
      return [];
    }

    console.log('Trying to collect');

    const loosen: Guid[] = [];
    this.dictSubs.forEach((a, b) => {
      if (b.length === 0) loosen.push(Guid.parse(a));
    });
    for (const loose of loosen) {
      this.dictSubs.remove(loose.toString().toLowerCase());
    }
    return loosen;
  }

  Occupy(occupied: Guid[], by: Guid) {
    // console.log("Occupy for", occupied, by);

    for (const item of occupied) {
      this.UpdateSubscription(item, by);
    }
  }

  Loose(items: Guid[], by: Guid) {
    // console.log("Loose for", items);

    for (const item of items) {
      this.DeleteSubscription(item, by);
    }
  }

  Lock() {
    this.isLocked = true;
  }

  Unlock() {
    this.isLocked = false;
  }
}
