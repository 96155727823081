<template>
  <div :style="{ height: height + 'px' }" :class="['text-input', disabled ? 'disabled' : '']">
    <span v-if="label !== undefined" :style="LabelStyle" class="label mr-2">{{ label }}</span>
    <input
      v-if="!readonly && textValue !== undefined"
      v-model.trim="textValue"
      :type="type"
      :class="['value', fillWidth ? 'flex-grow-1' : '', invalid ? 'error-border' : '']"
      :placeholder="placeholder"
      :style="ValueInputStyle"
      :autocomplete="autoComplete"
      :data-testid="testId"
    />
    <span
      v-if="readonly && textValue !== undefined"
      :class="['value message-text', fillWidth ? 'flex-grow-1' : '', IsClickable ? 'clickable' : '']"
      :style="ValueStyle"
      :data-testid="testId"
      @click="Clicked"
    >
      {{ textValue }}
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, PropSync, Emit } from 'vue-property-decorator';

@Component
export default class TextInput extends Vue {
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: undefined }) label?: string;
  @Prop({ default: false }) invalid!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: undefined }) placeholder?: string;
  @Prop({ default: undefined }) labelWidth?: number;
  @Prop({ default: undefined }) labelColor?: string;
  @Prop({ default: 'text' }) type!: string;
  @Prop({ default: true }) fillWidth!: boolean;
  @Prop({ default: 14 }) fontSize!: number;
  @Prop({ default: 28 }) height!: number;
  @Prop({ default: undefined }) valueColor!: string;
  @Prop({ default: undefined }) autoComplete?: string;
  @Prop({ default: undefined }) testId?: string;

  @PropSync('value', { type: String, default: undefined })
  textValue?: string;

  @Emit('clicked')
  Clicked() {}

  get LabelStyle() {
    let style: { [key: string]: any } = {};

    style['font-size'] = this.fontSize + 'px';

    if (this.labelWidth !== undefined) {
      style['min-width'] = this.labelWidth + 'px';
    }

    if (this.labelColor !== undefined) {
      style['color'] = this.labelColor;
    }

    return style;
  }

  get ValueStyle() {
    let style: { [key: string]: any } = {};

    style['font-size'] = this.fontSize + 'px';

    if (this.valueColor !== undefined) {
      style['color'] = this.valueColor;
    }

    return style;
  }

  get ValueInputStyle() {
    let style: { [key: string]: any } = {};

    style['font-size'] = this.fontSize + 'px';
    style['height'] = this.height + 'px';

    if (this.valueColor !== undefined) {
      style['color'] = this.valueColor;
    }

    return style;
  }

  private get IsClickable() {
    return this.$listeners.clicked != undefined;
  }
}
</script>

<style lang="scss" scoped>
.text-input {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span {
    display: flex;
    line-height: 16px;

    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    white-space: nowrap;
    color: var(--editor-field-name);

    &.value {
      color: var(--editor-field-value);
      margin-left: 11px;
    }

    &.clickable {
      color: var(--editor-field-value-clickable);
      cursor: pointer;
      &:hover {
        opacity: 0.75;
      }
    }
  }

  input {
    background: none;
    border-radius: 6px;
    border: 1px solid var(--editor-field-border);
    color: var(--editor-field-value);
    padding: 0 10px;
  }

  .error-border {
    border: 1px solid var(--main-orange);
  }
}
</style>
