import { CompanyUser } from '@/models/Entities';

export const SortCompanyUserByEmailDESC: (a: CompanyUser, b: CompanyUser) => 1 | -1 = (
  a: CompanyUser,
  b: CompanyUser,
) => {
  return a.Email! > b.Email! ? -1 : 1;
};
export const SortCompanyUserByEmailASC: (a: CompanyUser, b: CompanyUser) => 1 | -1 = (
  a: CompanyUser,
  b: CompanyUser,
) => {
  return a.Email! > b.Email! ? 1 : -1;
};

export const SortCompanyUserByPrintsDESC: (a: CompanyUser, b: CompanyUser) => 1 | -1 = (
  a: CompanyUser,
  b: CompanyUser,
) => {
  return a.TotalPrints > b.TotalPrints ? -1 : 1;
};
export const SortCompanyUserByPrintsASC: (a: CompanyUser, b: CompanyUser) => 1 | -1 = (
  a: CompanyUser,
  b: CompanyUser,
) => {
  return a.TotalPrints > b.TotalPrints ? 1 : -1;
};

export const SortCompanyUserByLastSeenDESC: (a: CompanyUser, b: CompanyUser) => 1 | -1 = (
  a: CompanyUser,
  b: CompanyUser,
) => {
  return a.LastSeen > b.LastSeen ? -1 : 1;
};
export const SortCompanyUserByLastSeenASC: (a: CompanyUser, b: CompanyUser) => 1 | -1 = (
  a: CompanyUser,
  b: CompanyUser,
) => {
  return a.LastSeen > b.LastSeen ? 1 : -1;
};

export const SortCompanyUserByFullNameDESC: (a: CompanyUser, b: CompanyUser) => 1 | -1 = (
  a: CompanyUser,
  b: CompanyUser,
) => {
  return a.FullName > b.FullName ? -1 : 1;
};
export const SortCompanyUserByFullNameASC: (a: CompanyUser, b: CompanyUser) => 1 | -1 = (
  a: CompanyUser,
  b: CompanyUser,
) => {
  return a.FullName > b.FullName ? 1 : -1;
};

export const SortCompanyUserByIdDESC: (a: CompanyUser, b: CompanyUser) => 1 | -1 = (a: CompanyUser, b: CompanyUser) => {
  return a.Id > b.Id ? -1 : 1;
};
export const SortCompanyUserByIdASC: (a: CompanyUser, b: CompanyUser) => 1 | -1 = (a: CompanyUser, b: CompanyUser) => {
  return a.Id > b.Id ? 1 : -1;
};
