import getEnv from '@/util/env';

export function PROTOCOL(): string {
  return <string>getEnv('VITE_APP_MAIN_PROTOCOL');
}
export function IP(): string {
  return <string>getEnv('VITE_APP_MAIN_IP');
}
export function PORT(): string {
  return <string>getEnv('VITE_APP_MAIN_PORT');
}
