import { TaxRate } from '@/models/Entities';
import store from '@/store';
import { Action, getModule, Module, VuexModule } from 'vuex-module-decorators';
import { UtilModule } from '../utilModule';

import * as ips from '@/util/api/ips';
import * as routes from '@/util/api/routes';
import { RequestReadTaxRateByAccountId } from '@/models/requests/RequestsBilling';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { ResponseTaxRate } from '@/models/responses/ResponseBilling';
import { TypeHelper } from '@/util/TypeHelper';
import { Guid } from 'guid-typescript';

@Module({ dynamic: true, name: 'taxRate', store: store })
export default class taxRateModule extends VuexModule {
  @Action({ rawError: true })
  async LoadTaxRateByAccountId(id: Guid) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.TAX_RATE,
      routes.UriTaxRate.READ_BY_ACCOUNT_ID,
    );

    const request = new RequestReadTaxRateByAccountId();
    request.accountId = id.toString();

    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    let taxRate: TaxRate | null = null;

    if (result === RequestStatus.OK && axiosResponse != null && axiosResponse.data.response != null) {
      const response = axiosResponse.data.response as ResponseTaxRate;
      taxRate = TypeHelper.DeepCopyFrom(response, ResponseTaxRate).Map();
    }

    return { taxRate, result, message };
  }
}

export const TaxRateModule = getModule(taxRateModule);
