<template>
  <div class="terminal" data-testid="terminal">
    <div class="header">
      <div class="command-input">
        <div class="arrow">
          <span>></span>
        </div>
        <input v-model="command" :disabled="isLoading" type="text" @keydown.enter="OnEnterKeyDownCommandInput" />
      </div>

      <div class="buttons" data-testid="terminal.buttons">
        <span data-testid="terminal.showRawCommand" @click="ToggleShowRawCommand"
          ><i :class="['fas fa-brackets-curly', showRawCommand ? 'active' : '']"
        /></span>
        <span data-testid="terminal.clearCommand" @click="ClearCommands"><i :class="['fas fa-broom']" /></span>
        <span v-if="closable" @click="CloseClicked"><i class="fas fa-times flow-layout-close-button" /></span>
      </div>
    </div>
    <div class="body thin-scroll">
      <div :class="['commands-container', isLoading ? 'disabled' : '']">
        <div v-for="cmd of Commands" :key="cmd.time.getTime()" class="command class mb-1">
          <span class="time mr-3">{{ GetCommandTime(cmd) }}</span>
          <span class="text">{{ GetCommandText(cmd) }}</span>
        </div>
      </div>

      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Printer } from '@/models/Entities';
import { ResponseRaw, ResponseRawCommand } from '@/models/responses/ResponseConnector';
import { ConnectorModule } from '@/store/modules/connectorModule';
import dateFormat from 'dateformat';
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';

interface PrinterRawCommand {
  time: Date;
  responseRawCommand?: ResponseRawCommand;
  responseRaw?: ResponseRaw;
  command: string;
}

@Component({
  components: {},
})
export default class Terminal extends Vue {
  @Prop() printer!: Printer;
  @Prop({ default: false }) closable!: boolean;

  //#region WATCHERS
  @Watch('printer', { immediate: true })
  async OnPrinterChanged(newValue?: Printer, oldValue?: Printer) {
    if (newValue == undefined) {
      if (oldValue != undefined) {
        await ConnectorModule.UnsubscribeFromRaw(this.printer.Id);
      }

      return;
    }

    if (oldValue != undefined) {
      if (oldValue.Id.toString() != newValue.Id.toString()) {
        await ConnectorModule.UnsubscribeFromRaw(this.printer.Id);
      } else {
        return;
      }
    }

    this.ClearState();
  }
  //#endregion

  //#region STATE
  private isLoading: boolean = false;

  private command: string = '';
  private showRawCommand: boolean = false;

  private commands: PrinterRawCommand[] = [];
  private get Commands() {
    return this.commands.sort((a, b) => {
      return a.time.getTime() < b.time.getTime() ? 1 : -1;
    });
  }
  //#endregion

  //#region LOGIC
  ClearState() {
    this.command = '';
    this.showRawCommand = false;
    this.ClearCommands();
  }

  async ToggleShowRawCommand() {
    this.showRawCommand = !this.showRawCommand;

    if (this.showRawCommand) {
      await ConnectorModule.SubscribeToRaw(this.printer.Id);
    } else {
      await ConnectorModule.UnsubscribeFromRaw(this.printer.Id);
    }
  }

  async OnEnterKeyDownCommandInput() {
    await this.SendCommand();
  }

  async SendCommand() {
    this.isLoading = true;

    const result = await ConnectorModule.SendRawCommand([this.printer.Id, this.command]);

    if (result == null) {
      this.isLoading = false;
      return;
    }

    this.commands.push({
      time: new Date(),
      responseRawCommand: result,
      command: this.command,
    });

    this.isLoading = false;
  }

  ClearCommands() {
    this.commands = [];
  }

  GetCommandTime(cmd: PrinterRawCommand) {
    return dateFormat(cmd.time, 'HH:MM:ss dd.mm.yyyy');
  }

  GetCommandText(cmd: PrinterRawCommand) {
    if (cmd.responseRawCommand != undefined) {
      if (!cmd.responseRawCommand.success) {
        if (cmd.responseRawCommand.result != undefined && cmd.responseRawCommand.result.length > 0) {
          return cmd.responseRawCommand.result;
        }

        return 'Command could not reach printer';
      }

      if (cmd.responseRawCommand.result == '') {
        return cmd.command;
      }

      return cmd.responseRawCommand.result;
    } else if (cmd.responseRaw != undefined) {
      return cmd.responseRaw.raw;
    }
  }

  RawCommandReceived(cmd: ResponseRaw) {
    this.commands.push({
      time: new Date(),
      responseRaw: cmd,
      command: cmd.raw,
    });
  }
  //#endregion

  //#region EVENTS
  @Emit('closed')
  CloseClicked() {}
  //#endregion

  //#region HOOKS
  async mounted() {
    ConnectorModule.OnRawCommandReceived.subscribe(this.RawCommandReceived);
  }

  async beforeDestroy() {
    await ConnectorModule.UnsubscribeFromRaw(this.printer.Id);
    ConnectorModule.OnRawCommandReceived.unsubscribe(this.RawCommandReceived);
  }
  //#endregion

  //#region TRANSLATIONS
  //#endregion
}
</script>

<style lang="scss" scoped>
.terminal {
  display: flex;
  flex-direction: column;

  .header {
    background: var(--terminal-header-background);
    border: var(--terminal-header-border);
    border-radius: 6px 6px 0 0;
    padding: 0 8px;
    display: flex;

    .command-input {
      display: flex;
      flex: 1;

      .arrow {
        user-select: none;
        margin-right: 6px;
        color: var(--terminal-command-input-caret);
      }

      input {
        flex: 1;
        padding: 0;
        margin: 0;
        background: none;
        border: none;
        color: var(--terminal-command-input-text);
        caret-color: var(--terminal-command-input-caret);
        font-size: 13px;

        &:disabled {
          opacity: 0.5;
        }
      }
    }

    .buttons {
      color: var(--terminal-command-buttons);
      font-size: 14px;
      display: flex;
      align-items: center;

      span {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }

        cursor: pointer;

        &:hover {
          opacity: 0.75;
        }

        &.active {
          color: var(--main-orange);
        }
      }
    }
  }

  .body {
    background: var(--terminal-body-background);
    border: var(--terminal-body-border);
    border-radius: 0 0 6px 6px;
    flex: 1;
    position: relative;
    overflow: auto;

    .commands-container {
      padding: 4px 8px;

      .command {
        display: flex;
        font-size: 13px;

        .time {
          color: var(--terminal-command-time);
        }

        .text {
          flex: 1;
          color: var(--terminal-command-text);
          text-align: left;
          word-break: break-all;
        }
      }
    }
  }
}
</style>
