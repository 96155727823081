<template>
  <div class="company-products">
    <div class="left-panel thin-scroll">
      <action-card
        class="aura-keys w-100 mb-3 overflow-auto thin-scroll"
        :headerText="AuraKeysCaption"
        :headerSpecialText="FreeOccupiedString"
        :initiallyCollapsed="AuraKeysCollapsed"
        @collapse-toggled="AuraKeysCollapseToggled"
      >
        <sortable-header :header-items="AuraKeysHeader" />
        <div class="overflow-overlay thin-scroll">
          <list-item-v-3
            v-for="item in PurchasedAuraKeys"
            :id="item.id"
            :key="item.item.id.toString()"
            :items="AuraKeyData(item)"
            :selected="IsSelectedKey(item)"
            mainClass="cur-pointer"
            @openItem="OpenAuraKey(item)"
          />
        </div>

        <div :class="['spinner-container', IsLoadingBillingData ? '' : 'hidden']">
          <div :class="['spinner', IsLoadingBillingData ? '' : 'hidden']"></div>
        </div>
      </action-card>

      <div class="w-100 premium-controls">
        <action-card
          v-for="item in PurchasedSubscriptions"
          :key="item.item.id.toString()"
          class="justify-content-center"
          :collapsible="false"
          :additionalBottomPadding="false"
          :hasHeader="false"
        >
          <div class="header" style="font-size: 13px">
            <span>{{ item.product.name }}</span>
            <span class="ml-2">. {{ NextPaymentDueCaption }}</span>
          </div>
          <div class="sub-payment">
            <span>
              {{ GetSubscriptionNextPaymentPrice(item) }}
              <span v-if="TaxRateAvailable" class="tax"> + {{ TaxRate }}</span>
              <br />
              {{ OnCaption }} {{ GetSubscriptionNextPaymentDate(item) }}
            </span>
          </div>
        </action-card>
      </div>
    </div>

    <div class="vertical-resizer"></div>

    <div class="right-panel thin-scroll">
      <aura-key-editor v-if="selectedKey !== null" class="mb-3" :item="selectedKey" @close-clicked="selectedKey = null">
      </aura-key-editor>

      <action-card
        class="w-100 mb-3 overflow-auto"
        :headerText="AddonsCaption"
        :initiallyCollapsed="AddonsCollapsed"
        @collapse-toggled="AddonsCollapseToggled"
      >
        <sortable-header :header-items="AddonsHeader" />
        <div class="overflow-overlay thin-scroll">
          <list-item-v-3
            v-for="item in AddonGroups"
            :id="item.id"
            :key="item.id.toString()"
            mainClass="cur-pointer"
            :items="AddonsData(item)"
            :selected="IsSelectedAddon(item)"
            @openItem="OpenAddonGroup(item)"
          />
        </div>

        <div :class="['spinner-container', IsLoadingBillingData ? '' : 'hidden']">
          <div :class="['spinner', IsLoadingBillingData ? '' : 'hidden']"></div>
        </div>
      </action-card>

      <keep-alive>
        <one-time-addon-editor
          v-if="selectedGroup !== null && SelectedOneTimeAddon"
          class="overflow-auto"
          :purchasedItemGroup="selectedGroup"
          @close-clicked="selectedGroup = null"
        >
        </one-time-addon-editor>

        <subscription-addon-editor
          v-if="selectedGroup !== null && SelectedSubscriptionAddon"
          class="overflow-auto"
          :purchasedItemGroup="selectedGroup"
          @close-clicked="selectedGroup = null"
          @pay-pending-subscription-invoice="PayPendingSubscriptionInvoice"
        >
        </subscription-addon-editor>
      </keep-alive>
    </div>
  </div>
</template>

<script lang="ts">
import ComponentHelper, { HeaderItem, ItemData } from '@/util/ComponentHelper';
import SortableHeader from '@/components/presentation/SortableHeader.vue';
import ActionCard from '@/components/presentation/ActionCard.vue';
import AuraKeyEditor from '@/components/forms/AuraKeyEditor.vue';
import ListItemV3 from '@/components/presentation/ListItemV3.vue';
import OneTimeAddonEditor from '@/components/forms/OneTimeAddonEditor.vue';
import SubscriptionAddonEditor from '@/components/forms/SubscriptionAddonEditor.vue';
import { ProductModuleHelper } from '@/store/modules/billing/productModule';

import {
  PurchasedItemFull,
  PurchasedItemFullGroup,
  PurchasedItemModule,
} from '@/store/modules/billing/purchasedItemModule';
import { LicenseType } from '@/models/enums/LicenseType';
import dateformat from 'dateformat';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

import { POSITION } from 'vue-toastification';
import { toast } from '@/main';
import en from '@/localization/en';
import { PurchaseStatus } from '@/models/enums/PurchaseStatus';
import { Guid } from 'guid-typescript';
import { AccountModule } from '@/store/modules/billing/accountModule';
import { GlobalDataModule } from '@/store/modules/globalDataModule';

@Component({
  name: 'company-products',
  components: {
    ActionCard: ActionCard,
    ListItemV3: ListItemV3,
    SortableHeader: SortableHeader,
    AuraKeyEditor: AuraKeyEditor,
    OneTimeAddonEditor: OneTimeAddonEditor,
    SubscriptionAddonEditor: SubscriptionAddonEditor,
  },
})
export default class CompanyProducts extends Vue {
  @Prop({ default: null }) selectedGroupProp!: PurchasedItemFullGroup | null;

  @Watch('selectedGroupProp')
  async OnSelectedGroupPropChanged(newVal: PurchasedItemFullGroup | null) {
    if (newVal === null) {
      this.selectedGroup = null;
      return;
    }

    const foundLocal = this.AddonGroups.firstOrDefault(a => a.id.toString() === newVal!.id.toString());

    if (foundLocal !== null) {
      this.selectedGroup = foundLocal;
    }
  }

  private selectedKey: PurchasedItemFull | null = null;
  private selectedGroup: PurchasedItemFullGroup | null = null;

  @Watch('selectedGroup')
  @Emit('selectedGroupChanged')
  async OnSelectedGroupChanged(newVal: PurchasedItemFullGroup | null) {
    return newVal;
  }

  private get IsLoadingBillingData() {
    return GlobalDataModule.IsLoadingBillingData;
  }

  private get TaxRateAvailable() {
    return AccountModule.AccountTaxRate != null;
  }

  private get SelectedOneTimeAddon() {
    return this.selectedGroup !== null && this.selectedGroup.group[0].price.recurringInterval === null;
  }
  private get SelectedSubscriptionAddon() {
    return this.selectedGroup !== null && this.selectedGroup.group[0].price.recurringInterval !== null;
  }
  private get PurchasedItemsFull(): PurchasedItemFull[] {
    const items = PurchasedItemModule.PurchasedItemsFull;

    for (const item of items) {
      if (this.selectedKey !== null && this.selectedKey.item.id.toString() === item.item.id.toString()) {
        this.selectedKey = item;
      }
    }

    return items;
  }
  private get PurchasedAddons(): PurchasedItemFull[] {
    return this.PurchasedItemsFull.filter(a => {
      return a.product.baseProductId !== null;
    });
  }
  private get PurchasedAuraKeys(): PurchasedItemFull[] {
    return this.PurchasedItemsFull.filter(a => {
      return a.product.licenseType === LicenseType.CryptlexKey;
    });
  }
  private get PurchasedSubscriptions(): PurchasedItemFull[] {
    const subs = this.PurchasedItemsFull.filter(a => {
      return a.price.recurringInterval !== null && a.subscription?.status === PurchaseStatus.Active;
    });

    const distinctSubs: PurchasedItemFull[] = [];

    for (const sub of subs) {
      if (distinctSubs.firstOrDefault(a => a.product.id.toString() === sub.product.id.toString())) {
        continue;
      }

      distinctSubs.push(sub);
    }

    return distinctSubs;
  }
  private get FreeOccupiedString(): string {
    const total = this.PurchasedAuraKeys.length;
    const free = this.PurchasedAuraKeys.filter(a => !a.item.activated).length;

    return `${free}/${total} ${en.free}`;
  }
  private get AddonGroups(): PurchasedItemFullGroup[] {
    const result = PurchasedItemModule.PurchasedItemsGroups.filter(a => a.group[0].product.baseProductId !== null);

    for (const item of result) {
      if (item.id.toString() === this.selectedGroup?.id.toString()) {
        this.selectedGroup = item;
      }
    }

    return result.filter(a => this.AddonGroupShowableInUI(a));
  }

  //#region VIEW STATE

  private get AuraKeysCollapsed() {
    return GlobalDataModule.CompanyViewState.productsTabViewState.auraKeysCollapsed;
  }

  private get AddonsCollapsed() {
    return GlobalDataModule.CompanyViewState.productsTabViewState.addonsCollapsed;
  }

  //#endregion

  //#region LOGIC
  private AddonGroupShowableInUI(group: PurchasedItemFullGroup) {
    const groupSub = group.group[0].subscription;

    if (groupSub == null) return true;

    const subPeriodEnd = groupSub.currentPeriodEnd;

    if (subPeriodEnd == null || subPeriodEnd! < new Date()) return false;

    if (groupSub.status === PurchaseStatus.Canceled) return false;

    return true;
  }

  private OpenAddonGroup(item: PurchasedItemFullGroup) {
    this.selectedGroup = item;
  }
  private IsSelectedAddon(item: PurchasedItemFullGroup) {
    return this.selectedGroup == item;
  }

  private OpenAuraKey(item: PurchasedItemFull) {
    this.selectedKey = item;
  }
  private IsSelectedKey(item: PurchasedItemFull) {
    return this.selectedKey == item;
  }

  private get TaxRate(): string {
    if (!this.TaxRateAvailable) return '';

    const taxRate = AccountModule.AccountTaxRate!;

    return `${taxRate.percentage}% ${ComponentHelper.GetReadableTaxRateType(taxRate.type)}`;
  }

  private GetSubscriptionNextPaymentPrice(item: PurchasedItemFull) {
    const quantity = this.PurchasedItemsFull.filter(
      a => a.subscription?.id.toString() === item.subscription?.id.toString(),
    ).length;

    const currency = en.CurrencySymbols[item.price.currency.toUpperCase()];
    let priceStr = currency + ((item.price.price * quantity) / 100).toFixed(2);

    return priceStr;
  }

  private GetSubscriptionNextPaymentDate(item: PurchasedItemFull) {
    if (item.subscription == null || item.subscription.currentPeriodEnd == null) {
      return 'N/A';
    }

    const dateStr = dateformat(item.subscription!.currentPeriodEnd!, 'mmmm dS yyyy');

    return dateStr;
  }

  //#region VIEW STATE

  private AuraKeysCollapseToggled(newVal: boolean) {
    GlobalDataModule.ChangeCompanyViewProductsTabAuraKeysCollapsed(newVal);
  }

  private AddonsCollapseToggled(newVal: boolean) {
    GlobalDataModule.ChangeCompanyViewProductsTabAddonsCollapsed(newVal);
  }

  //#endregion

  //#endregion

  //#region HEADERS AND ITEMS
  private get AuraKeysHeader(): HeaderItem[] {
    if (this.PurchasedAuraKeys.length == 0) return [];

    let headerKey: HeaderItem = {
      caption: '#',
      itemClass: ComponentHelper.GetWidthClass('grow-1'),
    };
    let headerStatus: HeaderItem = {
      caption: en.status.titleCase(),
      itemClass: ComponentHelper.GetWidthClass(125),
    };
    let headerMachineId: HeaderItem = {
      caption: en.machineId,
      itemClass: ComponentHelper.GetWidthClass(200),
    };

    let headerItems: HeaderItem[] = [];

    headerItems.push(headerKey);
    headerItems.push(headerStatus);
    headerItems.push(headerMachineId);

    return headerItems;
  }

  private AuraKeyData(full: PurchasedItemFull): ItemData[] {
    let items: ItemData[] = [];

    const item = full.item;
    const addonsStr =
      '+ ' + full.addons.map(a => ProductModuleHelper.ProductForPurchasedItem(a)?.shortName).join(' + ');

    let itemKey: ItemData = new ItemData(
      'Key',
      item.cryptlexKey === null ? '-' : item.cryptlexKey!,
      'grow-1',
      full.addons.length > 0 ? addonsStr : undefined,
      'fa-clone',
      'far',
      () => {
        navigator.clipboard.writeText(item.cryptlexKey!);
        toast.info(en.keyCopiedToClipboard, {
          position: POSITION.BOTTOM_RIGHT,
        });
      },
    );
    let itemStatus: ItemData = new ItemData(
      'Status',
      item.activated ? en.activated.titleCase() : en.free.titleCase(),
      125,
    );
    let itemMachineId: ItemData = new ItemData('MachineId', item.deviceName === null ? '-' : item.deviceName!, 200);

    items.push(itemKey);
    items.push(itemStatus);
    items.push(itemMachineId);

    return items;
  }

  private get AddonsHeader(): HeaderItem[] {
    if (this.PurchasedAddons.length == 0) return [];

    let headerName: HeaderItem = {
      caption: en.nameCaption.titleCase(),
      itemClass: ComponentHelper.GetWidthClass('grow-1'),
    };
    let headerFreeTotal: HeaderItem = {
      caption: `${en.free.titleCase()}/${en.total.titleCase()}`,
      itemClass: ComponentHelper.GetWidthClass(125),
    };
    let headerValidBefore: HeaderItem = {
      caption: en.validBefore.titleCase(),
      itemClass: ComponentHelper.GetWidthClass(125),
    };
    let headerStatus: HeaderItem = {
      caption: en.status.titleCase(),
      itemClass: ComponentHelper.GetWidthClass(125),
    };

    let headerItems: HeaderItem[] = [];

    headerItems.push(headerName);
    headerItems.push(headerFreeTotal);
    headerItems.push(headerValidBefore);
    headerItems.push(headerStatus);

    return headerItems;
  }

  private AddonsData(item: PurchasedItemFullGroup): ItemData[] {
    let items: ItemData[] = [];

    let value = item.group[0];

    let currentPeriodEnd: Date | null = null;

    if (value.subscription !== null && value.subscription.currentPeriodEnd !== null) {
      currentPeriodEnd = value.subscription!.currentPeriodEnd!;
    }

    const total = item.group.length;
    const notAcitvated: number = item.group.filter(a => !a.item.activated).length;
    const notAttachaed: number = item.group.filter(a => a.item.baseItemId === null).length;

    const occupied = value.product.licenseType === LicenseType.CryptlexKey ? notAcitvated : notAttachaed;

    let itemName: ItemData = new ItemData('Name', value.product.name, 'grow-1');

    let itemFreeTotal: ItemData = new ItemData('FreeTotal', occupied.toString() + '/' + total.toString(), 125);
    let itemValidBefore: ItemData = new ItemData(
      'ValidBefore',
      currentPeriodEnd === null ? '∞' : dateformat(currentPeriodEnd!, 'dd/mm/yyyy'),
      125,
    );

    let itemStatus: ItemData = new ItemData(
      'Status',
      ComponentHelper.GetReadablePurchaseStatus(value.item.status),
      125,
    );
    items.push(itemName);
    items.push(itemFreeTotal);
    items.push(itemValidBefore);
    items.push(itemStatus);

    return items;
  }
  //#endregion

  @Emit('pay-pending-subscription-invoice')
  private PayPendingSubscriptionInvoice(id: Guid) {
    return id;
  }

  //#region TRANSLATIONS
  private get AuraKeysCaption() {
    return en.auraKeys;
  }

  private get NextPaymentDueCaption() {
    return en.nextPaymentDue;
  }

  private get OnCaption() {
    return en.on;
  }

  private get AddonsCaption() {
    return en.addons;
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.company-products {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;

  .left-panel {
    width: 55%;
    max-height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;

    .premium-controls {
      flex-direction: row;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;

      .header {
        justify-content: unset !important;
        padding: 0;
      }

      .connect,
      .sub-payment {
        display: flex;
        align-items: center;
        margin: 2px;
        text-align: left;
        .tax {
          font-size: 14px;
          opacity: 0.8;
        }
        i {
          color: var(--main-orange);
          font-size: 20px;
          cursor: pointer;

          &:hover {
            opacity: 0.75;
          }
        }

        span {
          color: var(--main-text);
          font-size: 20px;
        }
      }
    }
  }

  .aura-keys {
    min-height: 300px;
    &.collapsed {
      min-height: 42px;
    }
  }

  .right-panel {
    width: 45%;
    overflow: auto;
  }

  .vertical-resizer {
    cursor: col-resize;
    width: 10px;
    margin: 0 3px;
  }

  .spinner-container {
    min-height: 40px;
    margin-top: 20px;
    position: relative;
    opacity: 1;

    &.hidden {
      opacity: 0;
      min-height: 0px;
      margin-top: 0px;
    }

    transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);
  }
}
</style>
