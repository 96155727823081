<template>
  <div class="printer-telemetry-summary">
    <div :class="['header', !IsDisconnected ? 'mb-2' : '']">
      <div class="printer">
        <div class="name mr-2" data-testid="printerTelemetrySummary.name" @click="OnPrinterNameClicked">
          <span>{{ PrinterName }}</span>
        </div>

        <div class="model-badge">
          <span>{{ PrinterModelString }}</span>
        </div>
      </div>

      <div :class="['status ml-3', IsPrinting ? 'printing' : '']">
        <span>{{ PrinterStatus }}</span>
      </div>
    </div>

    <div :class="['telemetry-plot', IsDisconnected ? 'hidden' : '']">
      <div ref="telemetryGrapthContainer" class="telemetry-graph-container thin-scroll">
        <UplotVue
          :class="[isLoadingTelemetry ? 'disabled' : '']"
          :options="uPlotOptions"
          :data="uPlotData"
          :traget="telemetryGrapthContainer"
        />

        <div :class="['spinner', isLoadingTelemetry ? '' : 'hidden']"></div>
      </div>
    </div>

    <div :class="['footer mt-3', IsDisconnected ? 'hidden' : '']">
      <div v-for="phInfo of LastPrintHeadTemperatures" :key="phInfo.phName" class="telemetry-info">
        <div class="color" :style="{ 'background-color': phInfo.color }"></div>
        <span class="label">{{ phInfo.phName }}</span>
        <span class="value">{{ phInfo.temp }}</span>
      </div>

      <div class="telemetry-info">
        <div class="color" :style="{ 'background-color': BuildPlateColor }"></div>
        <span class="label">Build plate</span>
        <span class="value">{{ LastBuildPlateTemperature }}</span>
      </div>
    </div>

    <div ref="cursorInfoContainer" class="cursor-info-container">
      <div class="cursor-info" :style="CursorInfoStyle">
        <div class="date">
          {{ CurrentPointDate }}
        </div>
        <div class="points">
          <div v-for="pt of CursorPoints" :key="pt.line.key" class="point">
            <div class="color" :style="GetLegendItemColorStyle(pt.line)"></div>
            <div class="name">{{ pt.line.readableName }}</div>
            <div class="value">{{ pt.value.toFixed(1) }}</div>
            <div class="unit">{{ UnitFor(pt.line.viewModel) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import en from '@/localization/en';
import { FullPrinter } from '@/models/CompositeEntities';
import { PrinterState } from '@/models/Entities';
import {
  ResponseTelemetryFiltered,
  ResponseTelemetryItemFiltered,
  TelemetryViewModel,
} from '@/models/responses/ResponseConnector';
import { ConnectorModule } from '@/store/modules/connectorModule';
import { Guid } from 'guid-typescript';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import uPlot, { Axis, Series } from 'uplot';
import UplotVue from 'uplot-vue';
import { Options, AlignedData } from 'uplot';
import 'uplot/dist/uPlot.min.css';
import { GetDefaultTimeRange, TimeRange, TimeRangeType } from '@/models/util/TimeModels';
import moment from 'moment';
import { CursorPoint, TelemetryLine, TelemetryLines } from './MachineTelemetry.vue';
import dateFormat from 'dateformat';
import * as convert from 'color-convert';
import ComponentHelper from '@/util/ComponentHelper';
import { TypeHelper } from '@/util/TypeHelper';
import { TelemetryType } from '@/models/enums/TelemetryType';
import TextInput from '@/components/inputs/TextInput.vue';
import { Routes } from '@/router/routes';

@Component({
  components: {
    UplotVue: UplotVue,
    TextInput: TextInput,
  },
})
export default class PrinterTelemetrySummary extends Vue {
  @Prop() printer!: FullPrinter;

  @Ref('telemetryGrapthContainer') telemetryGrapthContainer?: HTMLElement;
  @Ref('cursorInfoContainer') cursorInfoContainer!: HTMLElement;

  private telemetryGraphContainerWidth: number = 0;
  private get TelemetryGraphContainerWidth() {
    return this.telemetryGraphContainerWidth;
  }

  private telemetryGraphContainerHeight: number = 0;
  private get TelemetryGraphContainerHeight() {
    return this.telemetryGraphContainerHeight;
  }

  //#region WATCHERS

  @Watch('printer', { immediate: true })
  async OnPrinterChanged(newValue: FullPrinter, oldValue?: FullPrinter) {
    if (oldValue == undefined) {
      this.telemetryTimeRange = {
        dateFrom: moment(new Date()).subtract(5, 'minutes').toDate(),
        dateTo: new Date(),
        type: TimeRangeType.Live,
      };

      await this.LoadTelemetry();
      await this.SubscribeToTelemetry(newValue.printer.Id);
    }
  }

  //#endregion

  //#region STATE
  private isLoadingTelemetry = false;
  private subscribedToTelemetry = false;
  private regularIntervalTicks = 0;

  //#region UPLOT
  private availableLineColors: string[] = [];
  private hiddenLineKeys: string[] = [];

  uPlotData!: AlignedData;
  uPlotOptions!: Options;
  uPlot!: uPlot | null;

  private GlobalMouseX: number = 0;
  private GlobalMouseY: number = 0;

  private ShowCursor: boolean = false;
  private CursorPoints: CursorPoint[] = [];

  private get CurrentPointDate() {
    if (this.CursorPoints.length == 0) return 'N/A';

    return dateFormat(this.CursorPoints[0].time, 'yyyy-mm-dd HH:MM:ss');
  }

  SetUPlotOptions() {
    // console.log(this.minXDetalized, this.maxXDetalized);
    const gapRefined: Series.GapsRefiner = (u, sidx, idx0, idx1, nullGaps) => {
      const xData = u.data[0];

      const yData = u.data[sidx];

      let addlGaps: Series.Gaps = [];
      const isNum = Number.isFinite;
      const minDelta = 2;

      for (let i = idx0 + 1; i <= idx1; i++) {
        // let xVal = xData[i];
        // let yVal = yData[i];

        let delta = this.regularIntervalTicks;

        // right now the delta is in ticks (10k ms per tick)
        // we need to convert it back to seconds
        delta /= 10000;
        delta /= 1000;

        if (delta < minDelta) {
          delta = minDelta;
        }

        delta *= 5;

        if (isNum(yData[i]) && isNum(yData[i - 1])) {
          // in seconds
          const currDelta = xData[i] - xData[i - 1];

          // console.log(currDelta);

          if (currDelta > delta) {
            uPlot.addGap(
              addlGaps,
              Math.round(u.valToPos(xData[i - 1], 'x', true)),
              Math.round(u.valToPos(xData[i], 'x', true)),
            );
          }
        }
      }

      nullGaps.push(...addlGaps);
      nullGaps.sort((a, b) => a[0] - b[0]);

      return nullGaps;
    };

    const series: Series[] = [
      {
        label: 'Date',
        gaps: gapRefined,
      },
    ];

    const axes: Axis[] = [
      {
        scale: 'x',
        labelFont: '12px Roboto',
        font: '12px Roboto',
        stroke: 'rgba(220, 220, 220, 1)',
        grid: {
          stroke: 'rgba(130, 130, 130, 0.45)',
          width: 0.5,
        },
        incrs: [
          // minute divisors (# of secs)
          1,
          5,
          10,
          15,
          30,
          // hour divisors
          60,
          60 * 5,
          60 * 10,
          60 * 15,
          60 * 30,
          60 * 45,
          60 * 60,
          2 * 60 * 60,
          4 * 60 * 60,
          // day divisors
          3600,
          3600 * 2,
          3600 * 8,
          3600 * 24,
          3600 * 48,
          // ...
          3600 * 256,
          3600 * 512,
          3600 * 1024,
          3600 * 3600,
        ],
        values: [
          // tick incr          default           year                             month    day                        hour     min                sec       mode
          [3600 * 24 * 365, '{YYYY}', null, null, null, null, null, null, 1],
          [3600 * 24 * 28, '{MMM}', null, null, null, null, null, null, 1],
          [3600 * 24, '{MMM} {DD}', null, null, null, null, null, null, 1],
          [3600, '{HH}:{mm}', null, null, null, null, null, null, 1],
          [60, '{HH}:{mm}', null, null, null, null, null, null, 1],
          [1, '{HH}:{mm}:{ss}', null, null, null, null, null, null, 1],
          [0.001, '{HH}:{mm}:{ss}.{fff}', null, null, null, null, null, null, 1],
        ],
        show: true,
        gap: 0,
        ticks: {
          stroke: 'rgba(130, 130, 130, 0.45)',
          width: 0.5,
        },
        size: (self: uPlot, values: string[], axisIdx: number) => {
          let axis = self.axes[axisIdx];

          let axisSize = (axis.ticks?.size ?? 0) + (axis.gap ?? 0);

          let longestVal = (values ?? []).reduce((acc, val) => (val.length > acc.length ? val : acc), '');

          if (longestVal != '') {
            self.ctx.font = axis.font![0] ?? 'arial';
            var measure = self.ctx.measureText(longestVal);

            if (axisIdx == 0) {
              axisSize += measure.actualBoundingBoxAscent + measure.actualBoundingBoxDescent;
            } else {
              axisSize += measure.width / devicePixelRatio;
            }
          }

          return axisSize;
        },
      },
      {
        scale: 'y',
        font: '12px Roboto',
        stroke: 'rgba(220, 220, 220, 1)',
        grid: {
          stroke: 'rgba(130, 130, 130, 0.45)',
          width: 0.5,
        },
        show: true,
        gap: 0,
        ticks: {
          stroke: 'rgba(130, 130, 130, 0.45)',
          width: 0.5,
        },
        size: (self: uPlot, values: string[], axisIdx: number) => {
          let axis = self.axes[axisIdx];

          let axisSize = (axis.ticks?.size ?? 0) + (axis.gap ?? 0);

          let longestVal = (values ?? []).reduce((acc, val) => (val.length > acc.length ? val : acc), '');

          if (longestVal != '') {
            self.ctx.font = axis.font![0] ?? 'arial';
            var measure = self.ctx.measureText(longestVal);

            if (axisIdx == 0) {
              axisSize += measure.actualBoundingBoxAscent + measure.actualBoundingBoxDescent;
            } else {
              axisSize += measure.width / devicePixelRatio;
            }
          }

          return axisSize;
        },
      },
    ];

    for (const lineKey in this.telemetryLines) {
      const line = this.telemetryLines[lineKey];
      // Todo: add separate Y axis for different units here
      this.UnitFor(line.viewModel);
      const clr = convert.hex.rgb(line.color);
      const toAdd: Series = {
        label: line.readableName + '|' + lineKey,
        stroke: line.color,
        fill: `rgba(${clr[0]}, ${clr[1]}, ${clr[2]}, 0.1)`,
        width: 2,
        show: true,
        paths: uPlot.paths.spline!(),
        scale: 'y',
        gaps: gapRefined,
      };

      line.series = toAdd;
      series.push(toAdd);
    }

    this.uPlotOptions = {
      series: series,
      width: this.TelemetryGraphContainerWidth,
      height: this.TelemetryGraphContainerHeight,
      legend: {
        show: false,
      },
      padding: [0, 0, 0, 0],
      select: {
        show: false,
        left: 0,
        top: 0,
        width: 0,
        height: 0,
      },
      cursor: {
        drag: {
          setScale: false,
        },
      },
      hooks: {
        init: [
          plot => {
            this.uPlot = plot;
          },
        ],
        setLegend: [
          plot => {
            if (plot.legend.idx != null) {
              this.CursorPoints = [];

              this.ShowCursor = true;

              const index = plot.legend.idx;

              const date = new Date(plot.data[0][index] * 1000);

              for (let i = 1; i < plot.series.length; ++i) {
                const series = plot.series[i];
                const lineKey = series.label!.split('|')[1];
                const line = this.telemetryLines[lineKey];

                if (this.IsHiddenTelemetryKey(line.key)) {
                  continue;
                }

                this.CursorPoints.push({
                  line: line,
                  time: date,
                  value: plot.data[i][index]!,
                  indx: index,
                });
              }
            } else {
              this.ShowCursor = false;
              this.CursorPoints = [];
            }
          },
        ],
      },
      axes: axes,
    };

    this.$forceUpdate();

    return this.uPlotOptions;
  }

  private resizeObserver!: ResizeObserver;

  private telemetryLines: TelemetryLines = {};
  private get TelemetryLines() {
    return this.telemetryLines;
  }
  private TelemetryLinesCount() {
    return Object.keys(this.telemetryLines).length;
  }

  private get CursorInfoStyle() {
    return {
      left: this.GlobalMouseX + 14 + 'px',
      top: this.GlobalMouseY + 14 + 'px',
      display: !this.ShowCursor ? 'none' : '',
    };
  }
  //#endregion

  //#region PRINTER
  private get PrinterModelString() {
    if (this.printer.model == null) return '?';

    // Todo: add short name
    return this.printer.model.Name.split(' ')[1];
  }

  private get PrinterName() {
    return this.printer.printer.Name;
  }

  private get PrinterStatus() {
    return this.TranslateStatus(this.printer.printer.PrinterState);
  }

  private get IsPrinting() {
    const state = this.printer.printer.PrinterState;

    return state == PrinterState.PRINTING;
  }

  private get IsDisconnected() {
    const state = this.printer.printer.PrinterState;

    return state == PrinterState.DISCONNECTED || state == PrinterState.UNKNOWN;
  }
  //#endregion

  //#region TELEMETRY
  private telemetryTimeRange: TimeRange = GetDefaultTimeRange();

  private get TelemetryTimeRange() {
    return this.telemetryTimeRange;
  }

  private detalizedTelemetries: ResponseTelemetryItemFiltered[] = [];

  private get PrinterModel() {
    return this.printer.connectorModel;
  }

  private get LastTelemetry() {
    if (this.detalizedTelemetries.length == 0) return null;

    return this.detalizedTelemetries[this.detalizedTelemetries.length - 1];
  }

  private get MinTime() {
    if (this.detalizedTelemetries.length == 0) return '-';

    return dateFormat(this.detalizedTelemetries[0].time, 'HH:MM:ss');
  }

  private get MaxTime() {
    if (this.detalizedTelemetries.length == 0) return '-';

    return dateFormat(this.detalizedTelemetries[this.detalizedTelemetries.length - 1].time, 'HH:MM:ss');
  }

  private get LastBuildPlateTemperature() {
    const tel = this.LastTelemetry;

    if (tel == null || tel.buildPlateTemperature == null) return '-';

    const keys = Object.keys(tel.buildPlateTemperature.data);

    return tel.buildPlateTemperature.data[keys[0]].current.toFixed(1) + '°C';
  }

  private get BuildPlateColor() {
    if (this.LastTelemetry == null || this.LastTelemetry.buildPlateTemperature == null) return 'black';

    const lastTelKey = Object.keys(this.LastTelemetry.buildPlateTemperature.data)[0];
    const key = `buildPlateTemperature.data.${lastTelKey}.current`;
    return this.telemetryLines[key].color;
  }

  private get LastPrintHeadTemperatures() {
    const tel = this.LastTelemetry;

    if (tel == null || tel.printHeadTemperatures == null) return [];

    const result: { phName: string; temp: string; color: string }[] = [];

    let i = 0;
    for (const phTemp of tel.printHeadTemperatures) {
      const keys = Object.keys(phTemp.data);
      const phKey = `printHeadTemperatures.${phTemp.name}.data..current`;
      result.push({
        phName: 'T' + i,
        temp: phTemp.data[keys[0]].current.toFixed(1) + '°C',
        color: this.telemetryLines[phKey].color,
      });
      ++i;
    }

    return result;
  }
  //#endregion

  //#region LOGIC

  private async LoadTelemetry() {
    this.isLoadingTelemetry = true;

    this.regularIntervalTicks = Math.round(
      (TypeHelper.DateToTicks(this.TelemetryTimeRange.dateTo) -
        TypeHelper.DateToTicks(this.TelemetryTimeRange.dateFrom)) /
        1000,
    );

    const res = await ConnectorModule.ReadTelemetryByPrinterDate([
      this.printer.printer.Id,
      this.TelemetryTimeRange.dateFrom,
      this.TelemetryTimeRange.dateTo,
      this.regularIntervalTicks,
      [TelemetryType.BuildPlateTemperature, TelemetryType.PrintHeadTemperature],
    ]);

    if (res != null) {
      for (const tel of res.items) {
        this.detalizedTelemetries.push(tel);
      }
    }

    this.uPlotData = this.CalculateUPlotData();
    this.TelemetryGrapthContainerResized();

    // this.loadedNoData = this.telemetries.length == 0;

    this.isLoadingTelemetry = false;
  }

  TelemetriesReceived(tel: ResponseTelemetryFiltered) {
    // console.log("hello");

    if (tel.printerId.toString() != this.printer.printer.Id.toString()) {
      return;
    }

    let maxTime = this.TelemetryTimeRange.dateTo;

    for (const item of tel.items) {
      item.chamberTemperature = null;
      item.fanSpeeds = null;
      item.materialUsed = null;
      item.printHeadPosition = null;

      this.detalizedTelemetries.push(item);

      if (item.time > maxTime) {
        maxTime = item.time;
      }
    }

    let minTime = moment(maxTime).subtract(5, 'minutes').toDate();

    const toDelete: ResponseTelemetryItemFiltered[] = [];
    for (const item of this.detalizedTelemetries) {
      if (item.time < minTime) {
        toDelete.push(item);
        continue;
      }

      if (item.time >= minTime) {
        minTime = item.time;
        break;
      }
    }

    for (const item of toDelete) {
      this.detalizedTelemetries.delete(item);
    }

    this.uPlotData = this.CalculateUPlotData();

    if (maxTime > this.TelemetryTimeRange.dateTo) {
      this.telemetryTimeRange.dateFrom = minTime;
      this.telemetryTimeRange.dateTo = maxTime;
    }

    this.$forceUpdate();
  }

  async SubscribeToTelemetry(printerId: Guid) {
    await ConnectorModule.SubscribeToTelemetry(printerId);
    ConnectorModule.OnTelemetryReceived.subscribe(this.TelemetriesReceived);
    this.subscribedToTelemetry = true;
  }

  async UnsubscribeFromTelemetry(printerId: Guid) {
    await ConnectorModule.UnsubscribeFromTelemetry(printerId);
    ConnectorModule.OnTelemetryReceived.unsubscribe(this.TelemetriesReceived);
    this.subscribedToTelemetry = false;
  }

  //#region UPLOT
  TelemetryGrapthContainerResized() {
    if (!this.telemetryGrapthContainer) {
      return;
    }
    this.telemetryGraphContainerWidth = this.telemetryGrapthContainer.clientWidth;
    this.telemetryGraphContainerHeight = this.telemetryGrapthContainer.clientHeight;

    this.SetUPlotOptions();
  }

  CalculateUPlotData(): AlignedData {
    this.telemetryLines = {};

    const dates: number[] = [];

    // console.log("?");

    for (const tel of this.detalizedTelemetries) {
      dates.push(Math.round(tel.time.getTime() / 1000));
      this.CalculateTelemetryPoints(tel);
    }

    const result: AlignedData = [dates];
    for (const lineKey in this.telemetryLines) {
      const line = this.telemetryLines[lineKey];

      result.push(line.points);
    }

    return result;
  }

  CalculateTelemetryPoints(tel: ResponseTelemetryItemFiltered) {
    if (tel.buildPlateTemperature != null) {
      for (const tempName in tel.buildPlateTemperature.data) {
        const temp = tel.buildPlateTemperature.data[tempName];
        const indx = Object.keys(tel.buildPlateTemperature.data).indexOf(tempName);

        const currentKey = `buildPlateTemperature.data.${tempName}.current`;
        // const targetKey = `buildPlateTemperature.data.${tempName}.target`;

        let currentFound = this.telemetryLines[currentKey];
        // let targetFound = this.telemetryLines[targetKey];

        if (currentFound == null) {
          this.telemetryLines[currentKey] = {
            points: [],
            viewModel: tel.buildPlateTemperature,
            readableName:
              this.PrinterModel == null ? '?' : `Build plate temperature ${this.PrinterModel.BPSensorNames[indx]}`,
            color: this.availableLineColors[this.TelemetryLinesCount() % this.availableLineColors.length],
            key: currentKey,
          };

          currentFound = this.telemetryLines[currentKey];
        }

        // if (targetFound == null) {
        //   this.telemetryLines[targetKey] = {
        //     points: [],
        //     viewModel: tel.buildPlateTemperature,
        //     readableName:
        //       this.PrinterModel == null
        //         ? "?"
        //         : `Build plate target temperature ${this.PrinterModel.BPSensorNames[indx]}`,
        //     color: this.availableLineColors[
        //       this.TelemetryLinesCount() % this.availableLineColors.length
        //     ],
        //     key: targetKey,
        //   };

        //   targetFound = this.telemetryLines[targetKey];
        // }

        const currentPoint = temp.current;
        currentFound!.points.push(currentPoint);

        // if (temp.target != null) {
        //   const targetPoint = temp.target;
        //   targetFound!.points.push(targetPoint);
        // }
      }
    }

    if (tel.chamberTemperature != null) {
      for (const tempName in tel.chamberTemperature.data) {
        const temp = tel.chamberTemperature.data[tempName];
        const indx = Object.keys(tel.chamberTemperature.data).indexOf(tempName);

        const currentKey = `chamberTemperature.data.${tempName}.current`;
        // const targetKey = `chamberTemperature.data.${tempName}.target`;

        let currentFound = this.telemetryLines[currentKey];
        // let targetFound = this.telemetryLines[targetKey];

        if (currentFound == null) {
          this.telemetryLines[currentKey] = {
            points: [],
            viewModel: tel.chamberTemperature,
            readableName:
              this.PrinterModel == null ? '?' : `Chamber temperature ${this.PrinterModel.CHSensorNames[indx]}`,
            color: this.availableLineColors[this.TelemetryLinesCount() % this.availableLineColors.length],
            key: currentKey,
          };

          currentFound = this.telemetryLines[currentKey];
        }

        // if (targetFound == null) {
        //   this.telemetryLines[targetKey] = {
        //     points: [],
        //     viewModel: tel.chamberTemperature,
        //     readableName:
        //       this.PrinterModel == null
        //         ? "?"
        //         : `Chamber target temperature ${this.PrinterModel.CHSensorNames[indx]}`,
        //     color: this.availableLineColors[
        //       this.TelemetryLinesCount() % this.availableLineColors.length
        //     ],
        //     key: targetKey,
        //   };

        //   targetFound = this.telemetryLines[targetKey];
        // }

        const currentPoint = temp.current;
        currentFound!.points.push(currentPoint);

        // if (temp.target != null) {
        //   const targetPoint = temp.target;
        //   targetFound!.points.push(targetPoint);
        // }
      }
    }

    if (tel.materialUsed != null) {
      for (const usage of tel.materialUsed) {
        const used = usage.used;
        const indx = tel.materialUsed.indexOf(usage);

        const key = `materialUsed.${usage.name}`;

        let found = this.telemetryLines[key];

        if (found == null) {
          this.telemetryLines[key] = {
            points: [],
            viewModel: usage,
            readableName: this.PrinterModel == null ? '?' : `Usage of ${this.PrinterModel.ExtruderNames[indx]}`,
            color: this.availableLineColors[this.TelemetryLinesCount() % this.availableLineColors.length],
            key: key,
          };

          found = this.telemetryLines[key];
        }

        found!.points.push(used);
      }
    }

    if (tel.fanSpeeds != null) {
      for (const fan of tel.fanSpeeds) {
        const indx: number = tel.fanSpeeds.indexOf(fan);
        const speed = fan.speed;

        const key = `fanSpeeds.${indx}`;

        let found = this.telemetryLines[key];

        if (found == null) {
          this.telemetryLines[key] = {
            points: [],
            viewModel: fan,
            readableName: this.PrinterModel == null ? '?' : `${this.PrinterModel.FanNames[indx]} speed`,
            color: this.availableLineColors[this.TelemetryLinesCount() % this.availableLineColors.length],
            key: key,
          };

          found = this.telemetryLines[key];
        }

        found!.points.push(speed);
      }
    }

    if (tel.printHeadPosition != null) {
      const keyX = `printerHeadPosition.X`;
      const keyY = `printerHeadPosition.Y`;
      const keyZ = `printerHeadPosition.Z`;

      let foundX = this.telemetryLines[keyX];
      let foundY = this.telemetryLines[keyY];
      let foundZ = this.telemetryLines[keyZ];

      if (foundX == null) {
        this.telemetryLines[keyX] = {
          points: [],
          viewModel: tel.printHeadPosition,
          readableName: 'Position X',
          color: this.availableLineColors[this.TelemetryLinesCount() % this.availableLineColors.length],
          key: keyX,
        };

        foundX = this.telemetryLines[keyX];
      }

      if (foundY == null) {
        this.telemetryLines[keyY] = {
          points: [],
          viewModel: tel.printHeadPosition,
          readableName: 'Position Y',
          color: this.availableLineColors[this.TelemetryLinesCount() % this.availableLineColors.length],
          key: keyY,
        };

        foundY = this.telemetryLines[keyY];
      }

      if (foundZ == null) {
        this.telemetryLines[keyZ] = {
          points: [],
          viewModel: tel.printHeadPosition,
          readableName: 'Position Z',
          color: this.availableLineColors[this.TelemetryLinesCount() % this.availableLineColors.length],
          key: keyZ,
        };

        foundZ = this.telemetryLines[keyZ];
      }

      foundX!.points.push(tel.printHeadPosition.x);
      foundY!.points.push(tel.printHeadPosition.y);
      foundZ!.points.push(tel.printHeadPosition.z);
    }

    if (tel.printHeadTemperatures != null) {
      for (const printerHead of tel.printHeadTemperatures) {
        const indxPH = tel.printHeadTemperatures.indexOf(printerHead);
        const phName = this.PrinterModel == null ? '?' : this.PrinterModel.PHNames[indxPH];

        for (const tempName in printerHead.data) {
          const temp = printerHead.data[tempName];
          const indx = Object.keys(printerHead.data).indexOf(tempName);

          const currentKey = `printHeadTemperatures.${printerHead.name}.data.${tempName}.current`;
          // const targetKey = `printHeadTemperatures.${printerHead.name}.data.${tempName}.target`;

          let currentFound = this.telemetryLines[currentKey];
          // let targetFound = this.telemetryLines[targetKey];

          if (currentFound == null) {
            this.telemetryLines[currentKey] = {
              points: [],
              viewModel: printerHead,
              readableName:
                this.PrinterModel == null
                  ? '?'
                  : `${phName} temperature ${this.PrinterModel.PHSensorNames[indxPH][indx]}`,
              color: this.availableLineColors[this.TelemetryLinesCount() % this.availableLineColors.length],
              key: currentKey,
            };

            currentFound = this.telemetryLines[currentKey];
          }

          // if (targetFound == null) {
          //   this.telemetryLines[targetKey] = {
          //     points: [],
          //     viewModel: printerHead,
          //     readableName:
          //       this.PrinterModel == null
          //         ? "?"
          //         : `${phName} target temperature ${this.PrinterModel.PHSensorNames[indxPH][indx]}`,
          //     color: this.availableLineColors[
          //       this.TelemetryLinesCount() % this.availableLineColors.length
          //     ],
          //     key: targetKey,
          //   };

          //   targetFound = this.telemetryLines[targetKey];
          // }

          const currentPoint = temp.current;
          currentFound!.points.push(currentPoint);

          // if (temp.target != null) {
          //   const targetPoint = temp.target;
          //   targetFound!.points.push(targetPoint);
          // }
        }
      }
    }
  }

  GenerateColorsForPrinters() {
    this.availableLineColors.push('#9721f3');
    this.availableLineColors.push('#1b9fff');
    this.availableLineColors.push('#ff2527');
    this.availableLineColors.push('#fd7419');
    this.availableLineColors.push('#f9f918');
    this.availableLineColors.push('#10c565');
  }

  MouseMoved(e: MouseEvent) {
    // console.log(e);
    this.GlobalMouseX = e.clientX;
    this.GlobalMouseY = e.clientY;
  }

  IsHiddenTelemetryKey(key: string) {
    return this.hiddenLineKeys.indexOf(key) != -1;
  }

  GetLegendItemColorStyle(line: TelemetryLine) {
    return {
      'background-color': this.IsHiddenTelemetryKey(line.key) ? 'black' : line.color,
    };
  }

  UnitFor(vm: TelemetryViewModel) {
    return ComponentHelper.GetTelemetryViewModelUnit(vm);
  }

  ToggleTelemetryKeyVisibility(key: string) {
    if (this.IsHiddenTelemetryKey(key)) {
      this.hiddenLineKeys.delete(key);
    } else {
      this.hiddenLineKeys.push(key);
    }

    this.SetUPlotOptions();
  }

  OnPrinterNameClicked() {
    this.$router.push({
      name: Routes.MACHINES,
      query: {
        machineId: this.printer.printer.Id.toString(),
      },
    });
  }
  //#endregion

  //#endregion

  //#region HOOKS
  async beforeCreate() {
    this.resizeObserver = new ResizeObserver(() => {
      this.TelemetryGrapthContainerResized();
    });
  }

  async created() {
    this.SetUPlotOptions();
    this.uPlotData = [[], []];

    this.GenerateColorsForPrinters();
  }

  async beforeDestroy() {
    this.cursorInfoContainer.remove();
    this.resizeObserver.unobserve(this.telemetryGrapthContainer!);
    this.resizeObserver.disconnect();
    window.removeEventListener('mousemove', this.MouseMoved);

    if (this.subscribedToTelemetry) {
      this.UnsubscribeFromTelemetry(this.printer.printer.Id);
    }
  }

  mounted() {
    this.cursorInfoContainer.remove();
    document.body.appendChild(this.cursorInfoContainer);

    this.TelemetryGrapthContainerResized();

    this.resizeObserver.observe(this.telemetryGrapthContainer!);

    window.addEventListener('mousemove', this.MouseMoved);
  }
  //#endregion

  //#region TRANSLATIONS
  private TranslateStatus(printerState: PrinterState): string {
    switch (printerState) {
      case PrinterState.UNKNOWN:
        return this.UnknownCaption;
      case PrinterState.IDLE:
        return this.IdleCaption;
      case PrinterState.BUSY:
        return this.BusyCaption;
      case PrinterState.PRINTING:
        return this.PrintingCaption;
      case PrinterState.PAUSING:
        return this.PausingCaption;
      case PrinterState.PAUSED:
        return this.PausedCaption;
      case PrinterState.RESUMING:
        return this.ResumingCaption;
      case PrinterState.PRINT_FINISHED:
        return this.PrintFinishedCaption;
      case PrinterState.HALTED:
        return this.HaltedCaption;
      case PrinterState.UPDATING_FIRMWARE:
        return this.UpdatingFirmwareCaption;
      case PrinterState.DISCONNECTED:
        return this.DisconnectedCaption;
    }
  }

  private get StatusCaption(): string {
    return en.status.growFirst();
  }
  private get UnknownCaption(): string {
    return en.unknown.growFirst();
  }
  private get IdleCaption(): string {
    return en.idle.growFirst();
  }
  private get BusyCaption(): string {
    return en.busy.growFirst();
  }
  private get PrintingCaption(): string {
    return en.printing.growFirst();
  }
  private get PausingCaption(): string {
    return en.pausing.growFirst();
  }
  private get PausedCaption(): string {
    return en.paused.growFirst();
  }
  private get ResumingCaption(): string {
    return en.resuming.growFirst();
  }
  private get PrintFinishedCaption(): string {
    return en.printFinished.growFirst();
  }
  private get HaltedCaption(): string {
    return en.halted.growFirst();
  }
  private get UpdatingFirmwareCaption(): string {
    return en.updatingFirmware.growFirst();
  }
  private get DisconnectedCaption(): string {
    return en.disconnected.growFirst();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.printer-telemetry-summary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #424242;
  border: 1px solid #575757;
  border-radius: 6px;
  color: #d8d8d8;
  padding: 10px 16px;
  white-space: nowrap;
  justify-content: center;

  .header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    width: 100%;

    .printer {
      display: flex;
      flex-direction: row;

      .model-badge {
        background: #939393;
        border-radius: 2px;
        padding: 0 6px;
        color: black;
        user-select: none;
      }

      .name {
        flex: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 16px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .status {
      font-size: 13px;
      display: flex;
      align-items: center;

      &.printing {
        color: var(--special-text);
      }
    }
  }

  .telemetry-plot {
    position: relative;
    height: 125px;
    width: 100%;

    &.hidden {
      display: none;
    }

    .telemetry-graph-container {
      width: calc(100% - 2px);
      height: 100%;
    }
  }

  .telemetry-plot-time {
    font-size: 12px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    &.hidden {
      display: none;
    }
  }

  .footer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 60px;

    &.hidden {
      display: none;
    }

    .telemetry-info {
      display: flex;
      align-items: center;

      .color {
        width: 14px;
        height: 4px;
        margin-right: 8px;
        transform: translateY(-1px);
      }

      .label {
        font-size: 11px;
        color: #949494;
        width: 50%;
        text-align: start;
        margin-left: 4px;
        margin-right: 8px;
      }

      .value {
        font-size: 14px;
        color: #cecece;
        font-weight: 600;
        width: 50%;
        text-align: right;
      }
    }
  }
}
</style>
