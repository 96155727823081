<template>
  <div class="subscription-addon-editor">
    <editor-header>
      <template slot="header">
        <span>
          {{ Name }}
        </span>
      </template>

      <template slot="buttons">
        <header-icon-button iconName="fa-times" @clicked="closeButtonClicked" />
      </template>
    </editor-header>

    <editor-content>
      <pending-subscription-update-info
        v-if="HasPendingUpdate"
        class="mt-1 mb-2"
        :PSU="PendingSubscriptionUpdate"
        :purchasedItemGroup="purchasedItemGroup"
        @cancel-clicked="CancelPendingUpdate"
        @pay-invoice-clicked="PayInvoiceClicked"
      >
      </pending-subscription-update-info>

      <div v-if="ScheduledCancellation" class="scheduled-cancellation p-2 mb-2 mt-1">
        <span> {{ SubscriptionWillBeCanceledOnCaption }} {{ ValidBefore }} </span>
      </div>

      <text-input
        class="mb-2"
        :label="ValidBeforeCaption"
        :value.sync="ValidBefore"
        :labelWidth="labelWidth"
        :disabled="isLoading"
        :readonly="true"
      />

      <div class="d-flex">
        <text-input
          class="mb-2 mr-3"
          :label="PlanCaption"
          :value.sync="Plan"
          :labelWidth="labelWidth"
          :disabled="isLoading"
          :readonly="true"
        />

        <v-popover trigger="hover" placement="top" :disabled="Editable">
          <regular-button :label="ChangeCaption" :disabled="!Editable" @clicked="ChangePlan()" />

          <div slot="popover">
            <div class="pending-subscription-popover">
              <span>
                {{ NotEditableReason }}
              </span>
            </div>
          </div>
        </v-popover>
      </div>

      <text-input
        class="mb-2"
        :label="NextPaymentCaption"
        :value.sync="NextPayment"
        :labelWidth="labelWidth"
        :disabled="isLoading"
        :readonly="true"
      />

      <div class="d-flex">
        <text-input
          class="mb-2 mr-3"
          :label="FreeCaption + ' / ' + TotalCaption"
          :value="Free + '/' + Total"
          :labelWidth="labelWidth"
          :disabled="isLoading"
          :readonly="true"
        />
        <v-popover trigger="hover" placement="top" :disabled="Editable">
          <regular-button :label="ManageCaption" :disabled="!Editable" @clicked="ManageSubscription()" />

          <div slot="popover">
            <div class="pending-subscription-popover">
              <span>
                {{ NotEditableReason }}
              </span>
            </div>
          </div>
        </v-popover>
      </div>

      <action-card
        class="aura-keys w-100 overflow-auto thin-scroll"
        :headerText="AuraKeysCaption"
        :initiallyCollapsed="true"
      >
        <sortable-header :header-items="AuraKeysHeader" />
        <div class="overflow-overlay thin-scroll">
          <list-item-v-3
            v-for="item in BaseItems"
            :id="item.id"
            :key="item.id.toString()"
            :items="AuraKeyData(item)"
            @openItem="OpenAuraKey(item)"
          />
        </div>
      </action-card>
      <regular-button
        v-if="ScheduledCancellation"
        class="w-100 mb-2 mt-3"
        :label="ReactivateCaption"
        @clicked="Reactivate()"
      />

      <regular-button
        v-else
        class="w-100 mb-2 mt-3"
        :label="ScheduleCancellationCaption"
        @clicked="ScheduleCancellation()"
      />

      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </editor-content>
  </div>
</template>

<script lang="ts">
import { PendingSubscriptionUpdate, PurchasedItem } from '@/models/Entities';
import dateFormat from 'dateformat';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ComponentHelper, { HeaderItem, ItemData } from '@/util/ComponentHelper';
import SortableHeader from '@/components/presentation/SortableHeader.vue';
import ActionCard from '../presentation/ActionCard.vue';
import ListItemV3 from '@/components/presentation/ListItemV3.vue';
import en from '@/localization/en';

import { POSITION } from 'vue-toastification';
import { toast } from '@/main';
import { PurchasedSubscriptionModule } from '@/store/modules/billing/purchasedSubscriptionModule';
import ChangeSubscriptionSeatsDialog from '@/views/dialogs/subscriptions/ChangeSubscriptionSeatsDialog.vue';
import { create } from 'vue-modal-dialogs';
import ChangeSubscriptionPlanDialog from '@/views/dialogs/subscriptions/ChangeSubscriptionPlanDialog.vue';
import { PurchasedItemFullGroup } from '@/store/modules/billing/purchasedItemModule';
import { PendingSubscriptionUpdateModule } from '@/store/modules/billing/pendingSubscriptionUpdateModule';
import { VPopover } from 'v-tooltip';
import PendingSubscriptionUpdateInfo from '@/components/presentation/PendingSubscriptionUpdateInfo.vue';
import { Guid } from 'guid-typescript';
import AuraMessageBoxDialog, { Result } from '@/views/dialogs/AuraMessageBoxDialog.vue';
import { AccountModule } from '@/store/modules/billing/accountModule';
import EditorHeader from '@/components/forms/base/EditorHeader.vue';
import EditorContent from '@/components/forms/base/EditorContent.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import RegularButton from '../buttons/RegularButton.vue';
import HeaderIconButton from '@/components/buttons/HeaderIconButton.vue';

const ManageSubscrpion = create<PurchasedItemFullGroup, boolean>(
  ChangeSubscriptionSeatsDialog,
  'subscriptionItemsGroup',
);

const ChangeSubscriptionPlan = create<PurchasedItemFullGroup, boolean>(
  ChangeSubscriptionPlanDialog,
  'subscriptionItemsGroup',
);

const CancelPendingSubscriptionUpdate = create<Result, String, String, Result>(
  AuraMessageBoxDialog,
  'results',
  'header',
  'mainText',
);

const ScheduleCancellationWithPendingUpdate = create<Result, String, String, Result>(
  AuraMessageBoxDialog,
  'results',
  'header',
  'mainText',
);

@Component({
  name: 'subscription-addon-editor',
  components: {
    HeaderIconButton,
    ActionCard: ActionCard,
    ListItemV3: ListItemV3,
    SortableHeader: SortableHeader,
    VPopover: VPopover,
    PendingSubscriptionUpdateInfo: PendingSubscriptionUpdateInfo,
    EditorHeader: EditorHeader,
    EditorContent: EditorContent,
    TextInput: TextInput,
    RegularButton: RegularButton,
  },
})
export default class SubscriptionAddonEditor extends Vue {
  labelWidth: number = 120;
  @Prop() purchasedItemGroup!: PurchasedItemFullGroup;

  //#region STATE
  private isLoading: boolean = false;

  private get TaxRateAvailable() {
    return AccountModule.AccountTaxRate != null;
  }

  private get TaxRate(): string {
    if (!this.TaxRateAvailable) return '';

    const taxRate = AccountModule.AccountTaxRate!;

    return `${taxRate.percentage}% ${ComponentHelper.GetReadableTaxRateType(taxRate.type)}`;
  }

  private get PendingSubscriptionUpdate(): PendingSubscriptionUpdate | null {
    return PendingSubscriptionUpdateModule.PendingSubscriptionUpdates.firstOrDefault(
      a => a.subscriptionId.toString() == this.Subscription.id.toString(),
    );
  }

  private get HasPendingUpdate(): boolean {
    return this.PendingSubscriptionUpdate !== null;
  }

  private get ScheduledCancellation(): boolean {
    return this.Subscription.cancelAtPeriodEnd;
  }

  private get Editable(): boolean {
    return !this.HasPendingUpdate && !this.ScheduledCancellation;
  }

  private get NotEditableReason(): string {
    if (this.ScheduledCancellation) {
      return this.NotEditableReasonCancellationScheduledCaption;
    } else if (this.HasPendingUpdate) {
      return this.NotEditableReasonHasPendingUpdateCaption;
    }

    return 'N/A';
  }

  private get PurcahsedItemFull() {
    return this.purchasedItemGroup.group[0];
  }

  private get Subscription() {
    return this.PurcahsedItemFull.subscription!;
  }

  private get ValidBefore() {
    return dateFormat(this.Subscription.currentPeriodEnd!, 'dd/mm/yyyy HH:MM');
  }

  private get Price() {
    const currencySynmbol = en.CurrencySymbols[this.PurcahsedItemFull.price.currency.toUpperCase()];

    return currencySynmbol + (this.PurcahsedItemFull.price.price / 100).toFixed(2);
  }

  private get NextPaymentPrice() {
    const currencySynmbol = en.CurrencySymbols[this.PurcahsedItemFull.price.currency.toUpperCase()];

    return currencySynmbol + ((this.PurcahsedItemFull.price.price * this.Total) / 100).toFixed(2);
  }

  private get Plan() {
    const interval = this.PurcahsedItemFull.price.recurringInterval!;
    let intervalCaption = ComponentHelper.GetReadableRecurringIntervalNonPlural(interval).toLowerCase();

    return `${this.Price}/${intervalCaption}`;
  }

  private get NextPayment() {
    if (this.TaxRateAvailable) {
      return `${this.NextPaymentPrice} + ${this.TaxRate} ${this.AtCaption} ${this.ValidBefore}`;
    }

    return `${this.NextPaymentPrice} ${this.AtCaption} ${this.ValidBefore}`;
  }

  private get Name() {
    return this.PurcahsedItemFull.product.name;
  }

  private get Free() {
    return this.purchasedItemGroup.group.filter(a => a.baseItem === null).length.toString();
  }

  private get Total() {
    return this.purchasedItemGroup.group.length;
  }

  private get BaseItems(): PurchasedItem[] {
    return this.purchasedItemGroup.group.filter(a => a.baseItem !== null).map(a => a.baseItem!);
  }
  //#endregion

  //#region HEADERS & ITEMS
  private get AuraKeysHeader(): HeaderItem[] {
    let headerKey: HeaderItem = {
      caption: '#',
      itemClass: ComponentHelper.GetWidthClass('grow-1'),
    };
    let headerStatus: HeaderItem = {
      caption: en.status.titleCase(),
      itemClass: ComponentHelper.GetWidthClass(100),
    };
    let headerMachineId: HeaderItem = {
      caption: en.machineId.titleCase(),
      itemClass: ComponentHelper.GetWidthClass(150),
    };

    let headerItems: HeaderItem[] = [];

    headerItems.push(headerKey);
    headerItems.push(headerStatus);
    headerItems.push(headerMachineId);

    return headerItems;
  }

  private AuraKeyData(item: PurchasedItem): ItemData[] {
    let items: ItemData[] = [];

    let itemKey: ItemData = new ItemData(
      en.key.titleCase(),
      item.cryptlexKey === null ? '-' : item.cryptlexKey!,
      'grow-1',
      undefined,
      'fa-clone',
      'far',
      () => {
        navigator.clipboard.writeText(item.cryptlexKey!);
        toast.info('Key copied to clipboard', {
          position: POSITION.BOTTOM_RIGHT,
        });
      },
    );
    let itemStatus: ItemData = new ItemData(
      en.status.titleCase(),
      item.activated ? en.activated.titleCase() : en.free.titleCase(),
      100,
    );
    let itemMachineId: ItemData = new ItemData(
      en.machineId.titleCase(),
      item.deviceName === null ? '-' : item.deviceName!,
      150,
    );

    items.push(itemKey);
    items.push(itemStatus);
    items.push(itemMachineId);

    return items;
  }
  //#endregion

  //#region LOGIC
  private async CancelPendingUpdate() {
    const userConfirmation = await CancelPendingSubscriptionUpdate(
      Result.Yes | Result.No,
      'Cancel pending update',
      'Are you sure you want to cancel this pending update?',
    );

    if (userConfirmation == Result.No || userConfirmation == Result.Cancel) {
      return;
    }

    this.isLoading = true;

    const result = await PurchasedSubscriptionModule.CancelPendingUpdate(this.Subscription.id);

    this.isLoading = false;

    if (result) {
      toast.success(en.pendingUpdateCanceledSuccessfully, {
        position: POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error(en.couldNotCancelPendingupdate, {
        position: POSITION.BOTTOM_RIGHT,
      });
    }
  }

  private async ScheduleCancellation() {
    if (this.HasPendingUpdate) {
      const userConfirmation = await ScheduleCancellationWithPendingUpdate(
        Result.Yes | Result.No,
        'Schedule cancellation',
        'Scheduling a cancellation will cancel all pending updates on this subscription, are you sure you want to continue?',
      );

      if (userConfirmation == Result.No || userConfirmation == Result.Cancel) {
        return;
      }
    }

    this.isLoading = true;

    const result = await PurchasedSubscriptionModule.ScheduleCancellation(this.Subscription.id);

    this.isLoading = false;

    if (result) {
      toast.success(en.subscriptionWillBeCanceledOnPeriodEnd, {
        position: POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error(en.couldNotScheduleSubscriptionCancellation, {
        position: POSITION.BOTTOM_RIGHT,
      });
    }
  }

  private async Reactivate() {
    this.isLoading = true;

    const result = await PurchasedSubscriptionModule.Reactivate(this.Subscription.id);

    this.isLoading = false;

    if (result) {
      toast.success(en.subscriptionReactivated, {
        position: POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error(en.couldNotReactivateSubscription, {
        position: POSITION.BOTTOM_RIGHT,
      });
    }
  }

  private async ManageSubscription() {
    return ManageSubscrpion(this.purchasedItemGroup);
  }

  private async ChangePlan() {
    return ChangeSubscriptionPlan(this.purchasedItemGroup);
  }

  private async CancelSubscription() {
    this.isLoading = true;

    const result = await PurchasedSubscriptionModule.Cancel(this.Subscription.id);

    this.isLoading = false;

    if (result) {
      toast.success(en.subWasCancelledSuccessfully, {
        position: POSITION.BOTTOM_RIGHT,
      });

      this.closeButtonClicked();
    } else {
      toast.error(en.couldNotCancellSubscription, {
        position: POSITION.BOTTOM_RIGHT,
      });
    }
  }
  //#endregion

  //#region EVENTS
  @Emit('open-base-item')
  private OpenAuraKey(item: PurchasedItem) {
    return item;
  }

  @Emit('pay-pending-subscription-invoice')
  private PayInvoiceClicked(id: Guid) {
    return id;
  }

  closeButtonClicked() {
    this.$emit('close-clicked');
  }
  //#endregion

  //#region TRANSLATIONS
  private get ValidBeforeCaption() {
    return en.validBefore;
  }

  private get PlanCaption() {
    return en.plan.titleCase();
  }

  private get ChangeCaption() {
    return en.change.titleCase();
  }

  private get NextPaymentCaption() {
    return en.nextPaymentDue;
  }

  private get FreeCaption() {
    return en.free.titleCase();
  }

  private get TotalCaption() {
    return en.total.titleCase();
  }

  private get ManageCaption() {
    return en.manage.titleCase();
  }

  private get AuraKeysCaption() {
    return en.auraKeys;
  }

  private get CancelSubscriptionCaption() {
    return en.cancelSubscription;
  }

  private get AtCaption(): string {
    return en.at;
  }

  private get ScheduleCancellationCaption() {
    return en.scheduleCancellation;
  }

  private get ReactivateCaption() {
    return en.reactivate;
  }

  private get NotEditableReasonHasPendingUpdateCaption() {
    return en.subscriptionNotEditableReasonHasPendingUpdate;
  }

  private get NotEditableReasonCancellationScheduledCaption() {
    return en.subscriptionNotEditableReasonCancellationScheduled;
  }

  private get SubscriptionWillBeCanceledOnCaption() {
    return en.subscriptionWillBeCanceledOn.growFirst();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.subscription-addon-editor {
  background: var(--editor-background);
  border-radius: 6px;
  position: relative;

  .scheduled-cancellation {
    font-size: 13px;
    color: var(--editor-field-value);
    border-radius: 5px;
    border: 1px solid var(--action-card-border);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .aura-keys {
    background: transparent;
  }

  .pending-update {
    background: transparent;

    i {
      color: var(--main-orange);
    }

    .container {
      display: flex;
      align-items: flex-start;
    }
  }

  button {
    width: 150px;
    height: 26px;
  }
}
</style>

<style lang="scss">
.pending-subscription-popover {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;

  span a {
    text-decoration: underline !important;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }
}
</style>
