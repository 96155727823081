import { Guid } from 'guid-typescript';
import { IPlainResponse } from './BaseResponse';
import { Countries } from './Countries';

//#region LOGIN SESSION

export class ResponseLoginSession implements IPlainResponse {
  id!: Guid;
  userId!: Guid;
  deviceIP!: string;
  lastSeen!: string;
}

export class ResponseLoginSessions implements IPlainResponse {
  loginSessions!: ResponseLoginSession[];
}

export class ResponseLoginSessionId implements IPlainResponse {
  id!: Guid;
}

//#endregion

//#region TOKEN

export class ResponseToken implements IPlainResponse {
  token!: string;
  refreshToken!: string;
  validBeforeToken!: string;
  validBeforeRefreshToken!: string;
  responseUser!: ResponseUser;
  loginSessionId!: Guid;
}

export class ResponseUpdateToken implements IPlainResponse {
  token!: string;
  refreshToken!: string;
  validBeforeToken!: string;
  validBeforeRefreshToken!: string;
  loginSessionId!: Guid;
  companyId!: Guid;
}

//#endregion

//#region USER

export class ResponseUser implements IPlainResponse {
  id!: Guid;
  idCompany!: Guid;
  idsRole!: Guid[];
  loginSessions!: ResponseLoginSession[];
  firstName!: string;
  secondName!: string;
  email!: string;
  phone!: string;
}

export class ResponseUserId implements IPlainResponse {
  id!: Guid;
}

export class ResponseUsers implements IPlainResponse {
  users!: ResponseUser[];
}

export class ResponseUserRole implements IPlainResponse {
  userId!: Guid;
  roleId!: Guid;
}

//#endregion

//#region COMPANY

export class ResponseCompany implements IPlainResponse {
  id!: Guid;
  name!: string;
  description!: string;
  country!: Countries;
  address!: string;
  email!: string;
  users!: ResponseUser[];
}
export class ResponseCompanies implements IPlainResponse {
  companies!: ResponseCompany[];
}
export class ResponseCompanyUserCount implements IPlainResponse {
  id!: Guid;
  count!: number;
}
export class ResponseCompanyUserCounts implements IPlainResponse {
  companies!: ResponseCompanyUserCount[];
}
export class ResponseNewCompany implements IPlainResponse {
  company!: ResponseCompany;
  admin!: ResponseUser;
}

//#endregion

//#region ROLE

export class ResponseRole implements IPlainResponse {
  id!: Guid;
  name!: string;
  isAdmin!: boolean;
  isSuperAdmin!: boolean;
  isBillingAccountAdmin!: boolean;
  isBillingAccountOwner!: boolean;
  isConnectUser!: boolean;
}

export class ResponseRoles implements IPlainResponse {
  roles!: ResponseRole[];
}

//#endregion

export class ResponseIdentityHelper {
  static createRealLS(from: ResponseLoginSession): ResponseLoginSession {
    const r = new ResponseLoginSession();
    r.id = Guid.parse(from.id.toString());
    r.userId = Guid.parse(from.userId.toString());
    r.deviceIP = from.deviceIP;
    r.lastSeen = from.lastSeen;
    return r;
  }
  static createRealLSs(from: ResponseLoginSessions): ResponseLoginSessions {
    const r = new ResponseLoginSessions();
    r.loginSessions = [];
    for (const item of from.loginSessions) {
      r.loginSessions.push(this.createRealLS(item));
    }
    return r;
  }
  static createRealLSI(from: ResponseLoginSessionId): ResponseLoginSessionId {
    const r = new ResponseLoginSessionId();
    r.id = Guid.parse(from.id.toString());
    return r;
  }
  static createRealToken(from: ResponseToken): ResponseToken {
    const r = new ResponseToken();
    r.token = from.token;
    r.refreshToken = from.refreshToken;
    r.validBeforeToken = from.validBeforeToken;
    r.validBeforeRefreshToken = from.validBeforeRefreshToken;
    r.loginSessionId = Guid.parse(from.loginSessionId.toString());
    r.responseUser = this.createRealUser(from.responseUser);
    return r;
  }
  static createRealUpdateToken(from: ResponseUpdateToken): ResponseUpdateToken {
    const r = new ResponseUpdateToken();
    r.token = from.token;
    r.refreshToken = from.refreshToken;
    r.validBeforeToken = from.validBeforeToken;
    r.validBeforeRefreshToken = from.validBeforeRefreshToken;
    r.loginSessionId = Guid.parse(from.loginSessionId.toString());
    r.companyId = Guid.parse(from.companyId.toString());
    return r;
  }
  static createRealUser(from: ResponseUser): ResponseUser {
    const r = new ResponseUser();
    r.id = Guid.parse(from.id.toString());
    r.idCompany = Guid.parse(from.idCompany.toString());
    r.idsRole = from.idsRole.map(a => Guid.parse(a.toString()));
    r.loginSessions = [];
    for (const item of from.loginSessions) {
      r.loginSessions.push(this.createRealLS(item as ResponseLoginSession));
    }
    r.firstName = from.firstName;
    r.secondName = from.secondName;
    r.email = from.email;
    r.phone = from.phone;
    return r;
  }
  static createRealUserId(from: ResponseUserId): ResponseUserId {
    const r = new ResponseUserId();
    r.id = Guid.parse(from.id.toString());
    return r;
  }
  static createRealCompany(from: ResponseCompany): ResponseCompany {
    const r = new ResponseCompany();
    r.id = Guid.parse(from.id.toString());
    r.name = from.name;
    r.description = from.description;
    r.country = from.country;
    r.address = from.address;
    r.email = from.email;
    return r;
  }
  static createReaCompanies(from: ResponseCompanies): ResponseCompanies {
    const r = new ResponseCompanies();
    r.companies = [];
    for (const item of from.companies) {
      r.companies.push(this.createRealCompany(item));
    }
    return r;
  }
  static createRealCompanyUserCount(from: ResponseCompanyUserCount): ResponseCompanyUserCount {
    const r = new ResponseCompanyUserCount();
    r.count = from.count;
    r.id = Guid.parse(from.id.toString());
    return r;
  }
  static createRealCompanyUserCounts(from: ResponseCompanyUserCounts): ResponseCompanyUserCounts {
    const r = new ResponseCompanyUserCounts();
    r.companies = [];
    for (const item of from.companies) {
      r.companies.push(this.createRealCompanyUserCount(item));
    }
    return r;
  }
  static createRealNewCompany(from: ResponseNewCompany): ResponseNewCompany {
    const r = new ResponseNewCompany();
    r.company = this.createRealCompany(from.company);
    r.admin = this.createRealUser(from.admin);
    return r;
  }
  static createRealUsers(from: ResponseUsers): ResponseUsers {
    const r = new ResponseUsers();
    r.users = [];
    for (const item of from.users) {
      r.users.push(this.createRealUser(item));
    }
    return r;
  }
  static createRealRole(from: ResponseRole): ResponseRole {
    const r = new ResponseRole();
    r.id = Guid.parse(from.id.toString());
    r.name = from.name;
    r.isAdmin = from.isAdmin;
    r.isSuperAdmin = from.isSuperAdmin;
    r.isBillingAccountAdmin = from.isBillingAccountAdmin;
    r.isBillingAccountOwner = from.isBillingAccountOwner;
    r.isConnectUser = from.isConnectUser;
    return r;
  }
  static createRealRoles(from: ResponseRoles): ResponseRoles {
    const r = new ResponseRoles();
    r.roles = [];
    for (const item of from.roles) {
      r.roles.push(this.createRealRole(item as ResponseRole));
    }
    return r;
  }
  static createRealUserRole(from: ResponseUserRole): ResponseUserRole {
    const r = new ResponseUserRole();
    r.userId = Guid.parse(from.userId.toString());
    r.roleId = Guid.parse(from.roleId.toString());
    return r;
  }
}
