import { FullJobArchieve } from '@/models/CompositeEntities';

export const SortFullJobArchieveByFileDESC: (a: FullJobArchieve, b: FullJobArchieve) => 1 | -1 = (
  a: FullJobArchieve,
  b: FullJobArchieve,
) => {
  const fileNameA =
    a.jobArchieve.LocalFilename != null ? a.jobArchieve.LocalFilename : a.source == null ? '' : a.source.CodeFileName;
  const fileNameB =
    b.jobArchieve.LocalFilename != null ? b.jobArchieve.LocalFilename : b.source == null ? '' : b.source.CodeFileName;

  return fileNameA > fileNameB ? -1 : 1;
};
export const SortFullJobArchieveByFileASC: (a: FullJobArchieve, b: FullJobArchieve) => 1 | -1 = (
  a: FullJobArchieve,
  b: FullJobArchieve,
) => {
  const fileNameA =
    a.jobArchieve.LocalFilename != null ? a.jobArchieve.LocalFilename : a.source == null ? '' : a.source.CodeFileName;
  const fileNameB =
    b.jobArchieve.LocalFilename != null ? b.jobArchieve.LocalFilename : b.source == null ? '' : b.source.CodeFileName;

  return fileNameA > fileNameB ? 1 : -1;
};

export const SortFullJobArchieveByPrinterDESC: (a: FullJobArchieve, b: FullJobArchieve) => 1 | -1 = (
  a: FullJobArchieve,
  b: FullJobArchieve,
) => {
  return a.printer.Name > b.printer.Name ? -1 : 1;
};
export const SortFullJobArchieveByPrinterASC: (a: FullJobArchieve, b: FullJobArchieve) => 1 | -1 = (
  a: FullJobArchieve,
  b: FullJobArchieve,
) => {
  return a.printer.Name > b.printer.Name ? 1 : -1;
};

export const SortFullJobArchieveDateEndDESC: (a: FullJobArchieve, b: FullJobArchieve) => 1 | -1 = (
  a: FullJobArchieve,
  b: FullJobArchieve,
) => {
  const endTimeA = a.jobArchieve.EndTime == null ? -1 : a.jobArchieve.EndTime.getTime();
  const endTimeB = b.jobArchieve.EndTime == null ? -1 : b.jobArchieve.EndTime.getTime();

  return endTimeA > endTimeB ? -1 : 1;
};
export const SortFullJobArchieveDateEndASC: (a: FullJobArchieve, b: FullJobArchieve) => 1 | -1 = (
  a: FullJobArchieve,
  b: FullJobArchieve,
) => {
  const endTimeA = a.jobArchieve.EndTime == null ? -1 : a.jobArchieve.EndTime.getTime();
  const endTimeB = b.jobArchieve.EndTime == null ? -1 : b.jobArchieve.EndTime.getTime();

  return endTimeA > endTimeB ? 1 : -1;
};

export const SortFullJobArchieveByStatusDESC: (a: FullJobArchieve, b: FullJobArchieve) => 1 | -1 = (
  a: FullJobArchieve,
  b: FullJobArchieve,
) => {
  return a.jobArchieve.EndStatus > b.jobArchieve.EndStatus ? -1 : 1;
};
export const SortFullJobArchieveByStatusASC: (a: FullJobArchieve, b: FullJobArchieve) => 1 | -1 = (
  a: FullJobArchieve,
  b: FullJobArchieve,
) => {
  return a.jobArchieve.EndStatus > b.jobArchieve.EndStatus ? 1 : -1;
};

export const SortFullJobArchieveByIdDESC: (a: FullJobArchieve, b: FullJobArchieve) => 1 | -1 = (
  a: FullJobArchieve,
  b: FullJobArchieve,
) => {
  return a.jobArchieve.Id.toString() > b.jobArchieve.Id.toString() ? -1 : 1;
};
export const SortFullJobArchieveByIdASC: (a: FullJobArchieve, b: FullJobArchieve) => 1 | -1 = (
  a: FullJobArchieve,
  b: FullJobArchieve,
) => {
  return a.jobArchieve.Id.toString() > b.jobArchieve.Id.toString() ? 1 : -1;
};
