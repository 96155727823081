<template>
  <div ref="canvasContainer" class="jobs-timeline">
    <canvas
      ref="canvas"
      @click="CanvasClicked"
      @mousedown="CanvasMouseDown"
      @mouseup="CanvasMouseUp"
      @mousemove="CanvasMouseMoved"
      @mouseleave="CanvasMouseLeave"
      @mousewheel="CanvasMouseWheel"
    >
    </canvas>
  </div>
</template>

<script lang="ts">
import { FullJob } from '@/models/CompositeEntities';
import { Printer } from '@/models/Entities';
import dateFormat from 'dateformat';
import { Guid } from 'guid-typescript';
import moment from 'moment';
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import * as convert from 'color-convert';
import { Rectangle, Point } from '@/models/util/Geometry';
import { DrawnTimeSegment, TimeSegmentType } from '@/models/util/TimeModels';

// 1 Hour
const PRINT_INTERVAL_MS = 3600000;
// 10 Hours
const PRINT_DURATION_UNKNOWN = 36000000;

const CLOCK_UNICODE = '\u29D7';
const PLAY_UNICODE = '\u25B6';

interface JobTimelineLayout {
  currentTimeIndicatorHeight: number;
  currentTimeIndicatorColor: string;
  currentTimeIndicatorLineOffset: number;
  currentTimeIndicatorOffset: number;

  timeSegmentOffset: number;
  timeSegmentTextColor: string;
  timeSegmentLineColor: string;
  timeSegmentLineOffset: number;

  timeSubsegmentTextColor: string;
  timeSubsegmentLineColor: string;

  plotBackgroundColor: string;
  plotBorderColor: string;
  plotMarginTop: number;
  plotMarginLeft: number;

  printerNameTextColor: string;
  printerNameTextWidth: number;
  printerNameLineOffset: number;

  jobRectangleHeight: number;
  jobRectangleMargin: number;
  jobRectangleTextColor: string;
  jobRectangleTextOffset: number;
}

interface PrinterColor {
  printerId: Guid;
  color: string;
}

interface PrinterJobs {
  printer: Printer;
  jobs: FullJob[];
}

interface DrawnJobRectangle {
  rect: Rectangle;
  job: FullJob;
}

interface DrawnPrinterLine {
  printerString: string;
  lineStart: Point;
  lineEnd: Point;
  printerStringPosition: Point;
  color: string;
}

@Component({
  components: {},
})
export default class JobTimeline extends Vue {
  @Prop({ default: [] }) jobs!: FullJob[];
  @Prop({ default: false }) followCurrentTime!: boolean;
  @Prop({ default: null }) selectedJob!: FullJob | null;
  @Prop({ default: [] }) hiddenPrinterIds!: Guid[];

  //#region WATCHERS
  @Watch('jobs', { immediate: true, deep: true })
  private OnJobsChanged(newValue: FullJob[], oldValue: FullJob[]) {
    if (oldValue != undefined) {
      this.DrawData();
    }
  }

  @Watch('followCurrentTime', { immediate: true, deep: false })
  private OnFollowCurrentTimeChanged(newValue: boolean, oldValue: boolean) {
    if (oldValue != undefined) {
      this.CenterTimeRangeWithRespectToCurrentTime();
      this.DrawData();
    }
  }

  @Watch('hiddenPrinterIds', { immediate: true, deep: false })
  private OnHiddenPrintersChanged(newValue: Guid[], oldValue: Guid[]) {
    if (oldValue != undefined) {
      this.DrawData();
    }
  }
  //#endregion

  //#region STATE

  //#region JOBS
  private availablePrinterColors: string[] = [];
  private printerColors: PrinterColor[] = [];
  private jobsDrawn: DrawnJobRectangle[] = [];
  private jobHovered: DrawnJobRectangle | null = null;

  private get Printers(): Printer[] {
    const result: Printer[] = [];

    for (const job of this.jobs) {
      if (this.IsPrinterHidden(job.printer)) continue;

      const found = result.firstOrDefault(a => a.Id.toString() == job.printer.Id.toString());

      if (found) continue;

      result.push(job.printer);
    }

    return result.sort((a, b) => {
      return a.CreationDate > b.CreationDate ? 1 : -1;
    });
  }

  private get Jobs(): FullJob[] {
    const sorted = this.jobs.map(a => a).filter(a => !this.IsPrinterHidden(a.printer));

    sorted.sort((a, b) => {
      const aOrder = a.job.Order == null ? -9999999 : a.job.Order;
      const bOrder = b.job.Order == null ? -9999999 : b.job.Order;

      return aOrder > bOrder ? 1 : -1;
    });

    return sorted;
  }

  private get PrinterJobs(): PrinterJobs[] {
    const result: PrinterJobs[] = [];

    for (const job of this.Jobs) {
      let found = result.firstOrDefault(a => a.printer.Id.toString() == job.printer.Id.toString());

      if (found == null) {
        found = {
          printer: job.printer,
          jobs: [],
        };
        result.push(found!);
      }

      found.jobs.push(job);
    }

    result.sort((a, b) => {
      const aPrinterName = a.printer.Name;
      const bPrinterName = b.printer.Name;

      return aPrinterName > bPrinterName ? 1 : -1;
    });

    return result;
  }
  //#endregion

  //#region TIMELINE
  private dateFrom!: Date;
  private dateTo!: Date;

  public plotDataYOffset!: number;

  private segmentType!: TimeSegmentType;
  private layout!: JobTimelineLayout;

  private timeSegmentsDrawn: DrawnTimeSegment[] = [];
  private timeSubsegmentsDrawn: DrawnTimeSegment[] = [];
  private printerLinesDrawn: DrawnPrinterLine[] = [];
  //#endregion

  //#region CANVAS
  private canvasContainer!: HTMLDivElement;
  private canvasContext!: CanvasRenderingContext2D;
  private resizeObserver!: ResizeObserver;

  private MouseX: number = 0;
  private MouseY: number = 0;

  private DragStartMouseX: number = 0;
  private DragStartMouseY: number = 0;
  private IsDragging: boolean = false;

  private redrawIntervalId!: number;

  private MouseOverPrinters() {
    const plotRect = this.PlotRectangle();

    const x = this.MouseX;
    // const y = this.MouseY;

    return x > 0 && x < plotRect.x;
  }

  private CanvasWidth() {
    return this.canvasContext.canvas.width;
  }

  private CanvasHeight() {
    return this.canvasContext.canvas.height;
  }

  private MillisecondsPerPixel() {
    const canvasWidth = this.CanvasWidth();

    return this.TimeRange() / canvasWidth;
  }

  private PixelsPerMillisecond() {
    const canvasWidth = this.CanvasWidth();

    return canvasWidth / this.TimeRange();
  }
  //#endregion

  //#endregion

  //#region LOGIC

  //#region PRINTERS
  IsPrinterHidden(printer: Printer) {
    return this.hiddenPrinterIds.firstOrDefault(a => a.toString() == printer.Id.toString()) != null;
  }
  //#endregion

  //#region JOBS
  GenerateColorsForPrinters() {
    this.availablePrinterColors.push('#0A4339');
    this.availablePrinterColors.push('#43190A');
    this.availablePrinterColors.push('#43280A');
    this.availablePrinterColors.push('#430A0B');
    this.availablePrinterColors.push('#430A0C');
    this.availablePrinterColors.push('#430A1C');
    this.availablePrinterColors.push('#431E0A');
    this.availablePrinterColors.push('#0A2843');
    this.availablePrinterColors.push('#430A25');
    this.availablePrinterColors.push('#1E0A43');
  }

  ColorForPrinter(printer: Printer) {
    let indx = this.Printers.indexOf(printer);

    const found: PrinterColor = {
      printerId: printer.Id,
      color: this.availablePrinterColors[indx % this.availablePrinterColors.length],
    };

    this.printerColors.push(found);

    return found.color;
  }
  //#endregion

  //#region TIMELINE
  TimeRange() {
    return this.dateTo.getTime() - this.dateFrom.getTime();
  }

  public ResetView() {
    const now = new Date();
    this.dateFrom = moment(now).subtract(6, 'hours').toDate();
    this.dateTo = moment(this.dateFrom).add(12, 'hours').toDate();
    this.plotDataYOffset = 5;

    this.segmentType = TimeSegmentType.Hour;

    this.TimeRangeChanged();
  }

  public SetTimeRange(from: Date, to: Date) {
    this.dateFrom = from;
    this.dateTo = to;

    this.CalculateTimeSegment();
    this.CanvasContainerResized();
    this.TimeRangeChanged();
  }

  public SetVerticalOffset(amount: number) {
    this.plotDataYOffset = amount;
    // this.FitVerticalOffset();
    // this.VerticalOffsetChanged();
  }

  MoveTimeRange(ticks: number) {
    this.dateFrom = new Date(this.dateFrom.getTime() + ticks);
    this.dateTo = new Date(this.dateTo.getTime() + ticks);
  }

  CenterTimeRangeWithRespectToCurrentTime() {
    const now = new Date();

    const currRange = this.TimeRange();
    this.dateFrom = new Date(now.getTime() - Math.round(currRange / 2));
    this.dateTo = new Date(now.getTime() + Math.round(currRange / 2));

    this.TimeRangeChanged();
  }

  MovePlotDataVertically(amount: number) {
    this.plotDataYOffset += amount;

    this.FitVerticalOffset();
  }

  FitVerticalOffset() {
    const plotRect = this.PlotRectangle();

    const verticalLimitPadding = 4;

    // 1 pixel compinastes the border
    const bottomLimit = plotRect.height - this.layout.jobRectangleHeight - 1 - verticalLimitPadding;
    const topLimit =
      -((this.PrinterJobs.length - 1) * (this.layout.jobRectangleHeight + this.layout.jobRectangleMargin)) +
      1 +
      verticalLimitPadding;

    if (this.plotDataYOffset > bottomLimit) {
      this.plotDataYOffset = bottomLimit;
    } else if (this.plotDataYOffset < topLimit) {
      this.plotDataYOffset = topLimit;
    }
  }

  ZoomTimeRange(amount: number) {
    if (!this.CanZoom(amount)) {
      return;
    }

    let timeZoomAmount = this.TimeRange() * (amount / 1000);

    const centerX = this.CanvasWidth() / 2;

    const mouseOffsetFromCenter = this.MouseX - centerX;
    const mouseOffsetPercent = mouseOffsetFromCenter / centerX;

    const fromWeight = 1 + mouseOffsetPercent;
    const toWeight = 1 - mouseOffsetPercent;

    this.dateFrom = new Date(this.dateFrom.getTime() - timeZoomAmount * fromWeight);
    this.dateTo = new Date(this.dateTo.getTime() + timeZoomAmount * toWeight);

    this.CalculateTimeSegment();
    this.TimeRangeChanged();
  }

  CanZoom(amount: number) {
    const MAX_ZOOM_SECONDS = 6048000;
    const MIN_ZOOM_SESCONDS = 60 * 10;

    const from = moment(this.dateFrom);
    const to = moment(this.dateTo);

    const diffSeconds = to.diff(from, 'seconds');

    if (amount > 0 && diffSeconds < MAX_ZOOM_SECONDS) {
      return true;
    } else if (amount < 0 && diffSeconds > MIN_ZOOM_SESCONDS) {
      return true;
    }

    return false;
  }

  CalculateTimeSegment() {
    const from = moment(this.dateFrom);
    const to = moment(this.dateTo);

    const diffSeconds = to.diff(from, 'seconds');

    if (diffSeconds > 31536000) {
      this.segmentType = TimeSegmentType.Year;
    } else if (diffSeconds > 2630000) {
      this.segmentType = TimeSegmentType.Month;
    } else if (diffSeconds > 86400) {
      this.segmentType = TimeSegmentType.Day;
    } else if (diffSeconds > 3600 * 2) {
      this.segmentType = TimeSegmentType.Hour;
    } else if (diffSeconds > 60 * 5) {
      this.segmentType = TimeSegmentType.FiveMinutes;
    }
  }

  TimeSegmentTemplate(type: TimeSegmentType): string {
    if (type == TimeSegmentType.Year) {
      return 'yyyy';
    } else if (type == TimeSegmentType.Month) {
      return 'mmmm';
    } else if (type == TimeSegmentType.Day) {
      return 'dd mmm';
    } else if (type == TimeSegmentType.Hour) {
      return 'HH:MM';
    } else if (type == TimeSegmentType.FiveMinutes) {
      return 'HH:MM';
    } else if (type == TimeSegmentType.Second) {
      return 'HH:MM';
    }

    return 'yyyy';
  }

  TimeSegmentIntervals(type: TimeSegmentType): number {
    if (type == TimeSegmentType.Year) {
      return 31536000;
    } else if (type == TimeSegmentType.Month) {
      return 2630000;
    } else if (type == TimeSegmentType.Day) {
      return 86400;
    } else if (type == TimeSegmentType.Hour) {
      return 3600;
    } else if (type == TimeSegmentType.FiveMinutes) {
      return 60 * 5;
    } else if (type == TimeSegmentType.Second) {
      return 1;
    }

    return 31536000;
  }

  SubsegmentFor(type: TimeSegmentType): TimeSegmentType {
    if (type == TimeSegmentType.Year) {
      return TimeSegmentType.Month;
    } else if (type == TimeSegmentType.Month) {
      return TimeSegmentType.Day;
    } else if (type == TimeSegmentType.Day) {
      return TimeSegmentType.Hour;
    } else if (type == TimeSegmentType.Hour) {
      return TimeSegmentType.FiveMinutes;
    } else if (type == TimeSegmentType.FiveMinutes) {
      return TimeSegmentType.Second;
    }

    return TimeSegmentType.Month;
  }

  TimeSegmentStartTime(): Date {
    const start = this.dateFrom;
    let year = moment(start).get('years');
    let month = moment(start).get('months');
    let day = moment(start).get('date');
    let hour = moment(start).get('hours');
    let minute = moment(start).get('minutes');

    if (this.segmentType == TimeSegmentType.Year) {
      return new Date(year, 0);
    } else if (this.segmentType == TimeSegmentType.Month) {
      return new Date(year, month);
    } else if (this.segmentType == TimeSegmentType.Day) {
      return new Date(year, month, day);
    } else if (this.segmentType == TimeSegmentType.Hour) {
      return new Date(year, month, day, hour);
    } else if (this.segmentType == TimeSegmentType.FiveMinutes) {
      while (minute % 5 != 0) {
        --minute;
      }

      return new Date(year, month, day, hour, minute);
    }

    return new Date();
  }

  DateToX(date: Date): number {
    const from = moment(this.dateFrom);
    const to = moment(date);

    const coeff = this.PixelsPerMillisecond();
    const diff = to.diff(from, 'milliseconds');

    return coeff * diff;
  }

  XToDate(x: number): Date {
    const from = 0;
    const to = x;

    const coeff = this.MillisecondsPerPixel();
    const diff = to - from;

    const result = moment(this.dateFrom)
      .add(moment.duration(diff * coeff, 'milliseconds'))
      .toDate();

    return result;
  }
  //#endregion

  //#region CANVAS
  CanvasClicked() {
    if (this.jobHovered != null) {
      this.JobSelected(this.jobHovered.job);
    }
  }

  CanvasMouseMoved(e: MouseEvent) {
    var cRect = this.canvasContext!.canvas.getBoundingClientRect();
    var canvasX = e.clientX - cRect.left;
    var canvasY = e.clientY - cRect.top;

    this.MouseX = canvasX;
    this.MouseY = canvasY;

    let needsRerender = false;

    const jobHoveredNew = this.DrawnJobRectangleUnder(this.MouseX, this.MouseY);

    if (this.jobHovered != jobHoveredNew) {
      needsRerender = true;
      this.jobHovered = jobHoveredNew;

      if (this.jobHovered != null) {
        this.JobHovererd(this.jobHovered.job);
      }
    }

    if (this.IsDragging) {
      needsRerender = true;

      if (this.MouseOverPrinters()) {
        this.MovePlotDataVertically(e.movementY);
        this.VerticalOffsetChanged();
      } else if (!this.followCurrentTime) {
        this.MoveTimeRange(this.MillisecondsPerPixel() * -e.movementX);
        this.TimeRangeChanged();
      }
    }

    if (needsRerender) {
      this.DrawData();
    }
  }

  CanvasMouseDown(e: MouseEvent) {
    var cRect = this.canvasContext!.canvas.getBoundingClientRect();
    var canvasX = e.clientX - cRect.left;
    var canvasY = e.clientY - cRect.top;

    this.DragStartMouseX = canvasX;
    this.DragStartMouseY = canvasY;

    this.IsDragging = true;
  }

  CanvasMouseUp() {
    this.IsDragging = false;
  }

  CanvasMouseLeave() {
    this.IsDragging = false;
  }

  CanvasMouseWheel(e: WheelEvent) {
    const zoomBy = e.deltaY;
    this.ZoomTimeRange(zoomBy);
    this.DrawData();
  }

  CanvasContainerResized() {
    this.FitCanvas();
    this.FitVerticalOffset();
    this.DrawData();
  }

  FitCanvas() {
    this.canvasContext.canvas.width = this.canvasContainer.clientWidth;
    this.canvasContext.canvas.height = this.canvasContainer.clientHeight;
  }

  ClearCanvas() {
    this.canvasContext.clearRect(0, 0, this.CanvasWidth(), this.CanvasHeight());
  }

  public DrawData() {
    this.ClearCanvas();

    if (this.followCurrentTime) {
      this.CenterTimeRangeWithRespectToCurrentTime();
    }

    this.DrawPlot();

    this.DrawTimeSegments();
    this.DrawTimeSubsegments();

    this.DrawPrinterLines();

    this.DrawJobRectangles();
    this.DrawHoveredJobRectangle();
    this.DrawSelectedJobRecntage();

    this.DrawPlotClip();
    this.DrawPlotBorder();

    this.DrawPrinterLinesCaptions();
    this.DrawPrinterLinesClip();

    this.DrawTimeSegmentsCaptions();
    this.DrawTimeSubsegmentCaptions();

    this.DrawCurrentTimeIndicator();
  }

  DrawTimeSegments() {
    this.timeSegmentsDrawn = [];

    const segmentTemplate = this.TimeSegmentTemplate(this.segmentType);
    const segmentInterval = this.TimeSegmentIntervals(this.segmentType);
    const segmentStartTime = this.TimeSegmentStartTime();

    const yOffset =
      this.layout.timeSegmentOffset +
      this.layout.currentTimeIndicatorLineOffset +
      this.layout.currentTimeIndicatorHeight;

    const currSegmentTime = moment(segmentStartTime);

    this.canvasContext.lineWidth = 1;

    const plotRect = this.PlotRectangle();

    const minX = plotRect.x;
    const maxX = plotRect.x + plotRect.width;

    let prevTextPosition: Point = {
      x: -999,
      y: -999,
    };
    let prevTextWidth = 0;

    for (;;) {
      const segmentX = this.DateToX(currSegmentTime.toDate());

      const segmentDateStr = dateFormat(currSegmentTime.toDate(), segmentTemplate);

      this.canvasContext.font = 'normal 14px Roboto';
      const textMeasure = this.canvasContext.measureText(segmentDateStr);
      const textHeight = 14;

      this.canvasContext.strokeStyle = this.layout.timeSegmentLineColor;

      if (!(segmentX < minX || segmentX > maxX)) {
        const lineStartX = segmentX;
        const lineStartY = yOffset + textHeight + this.layout.timeSegmentLineOffset;

        const lineEndX = segmentX;
        const lineEndY = this.CanvasHeight();

        const text = segmentDateStr;
        const textPosX = segmentX - textMeasure.width / 2;
        const textPosY = yOffset + textHeight;

        // 6 is margin
        if (prevTextPosition.x + prevTextWidth > textPosX - textMeasure.width - 6) {
          currSegmentTime.add(segmentInterval, 'seconds');
          continue;
        }

        this.timeSegmentsDrawn.push({
          dateString: text,
          lineStart: {
            x: lineStartX,
            y: lineStartY,
          },
          lineEnd: {
            x: lineEndX,
            y: lineEndY,
          },
          dateStringPosition: {
            x: textPosX,
            y: textPosY,
          },
          dateStringWidth: textMeasure.width,
          date: currSegmentTime.toDate(),
        });

        prevTextPosition = {
          x: textPosX,
          y: textPosY,
        };
        prevTextWidth = textMeasure.width;

        this.canvasContext.beginPath();
        this.canvasContext.moveTo(lineStartX, lineStartY);
        this.canvasContext.lineTo(lineEndX, lineEndY);
        this.canvasContext.stroke();
      }

      if (currSegmentTime.toDate() > moment(this.dateTo).add(6, 'hours').toDate()) {
        break;
      }

      currSegmentTime.add(segmentInterval, 'seconds');
    }
  }

  DrawTimeSegmentsCaptions() {
    this.canvasContext.strokeStyle = this.layout.timeSegmentLineColor;
    this.canvasContext.fillStyle = this.layout.timeSegmentTextColor;
    const pltoRect = this.PlotRectangle();

    for (const timeSegment of this.timeSegmentsDrawn) {
      this.canvasContext.font = 'normal 14px Roboto';
      this.canvasContext.fillText(
        timeSegment.dateString,
        timeSegment.dateStringPosition.x,
        timeSegment.dateStringPosition.y,
      );

      this.canvasContext.beginPath();
      this.canvasContext.moveTo(timeSegment.lineStart.x, timeSegment.lineStart.y);
      this.canvasContext.lineTo(timeSegment.lineEnd.x, pltoRect.y);
      this.canvasContext.stroke();
    }
  }

  PadTimeSegments(segments: DrawnTimeSegment[]): DrawnTimeSegment[] {
    if (segments.length == 0) return [];

    const segmentInterval = this.TimeSegmentIntervals(this.segmentType);
    const segmentTemplate = this.TimeSegmentTemplate(this.segmentType);

    const segmentsPadded = segments.map(a => a);

    const firstSegment = segmentsPadded[0];

    this.canvasContext.font = 'normal 14px Roboto';
    const dateBeforeFirst = moment(firstSegment.date).subtract(segmentInterval, 'seconds').toDate();
    const dateStringBeforeFirst = dateFormat(dateBeforeFirst, segmentTemplate);
    const lineXBeforeFirst = this.DateToX(dateBeforeFirst);
    const textMeasureBeforeFirst = this.canvasContext.measureText(dateStringBeforeFirst);
    const textPosXBeforeFirst = lineXBeforeFirst - textMeasureBeforeFirst.width / 2;
    const textPosYBeforeFirst = firstSegment.dateStringPosition.y;

    segmentsPadded.unshift({
      date: dateBeforeFirst,
      dateString: dateStringBeforeFirst,
      lineStart: {
        x: lineXBeforeFirst,
        y: firstSegment.lineStart.y,
      },
      lineEnd: {
        x: lineXBeforeFirst,
        y: firstSegment.lineEnd.y,
      },
      dateStringPosition: {
        x: textPosXBeforeFirst,
        y: textPosYBeforeFirst,
      },
      dateStringWidth: textMeasureBeforeFirst.width,
    });

    const lastSegment = segmentsPadded[segmentsPadded.length - 1];

    const dateAfterLast = moment(lastSegment.date).add(segmentInterval, 'seconds').toDate();
    const dateStringAfterLast = dateFormat(dateAfterLast, segmentTemplate);
    const lineXAfterLast = this.DateToX(dateAfterLast);
    const textMeasureAfterLast = this.canvasContext.measureText(dateStringAfterLast);
    const textPosXAfterLast = lineXAfterLast - textMeasureAfterLast.width / 2;
    const textPosYAfterLast = lastSegment.dateStringPosition.y;

    segmentsPadded.push({
      date: dateAfterLast,
      dateString: dateStringAfterLast,
      lineStart: {
        x: lineXAfterLast,
        y: lastSegment.lineStart.y,
      },
      lineEnd: {
        x: lineXAfterLast,
        y: lastSegment.lineEnd.y,
      },
      dateStringPosition: {
        x: textPosXAfterLast,
        y: textPosYAfterLast,
      },
      dateStringWidth: textMeasureAfterLast.width,
    });

    return segmentsPadded;
  }

  DrawTimeSubsegments() {
    this.timeSubsegmentsDrawn = [];

    const subsegment = this.SubsegmentFor(this.segmentType);
    const segmentTemplate = this.TimeSegmentTemplate(subsegment);

    const yOffset =
      this.layout.timeSegmentOffset +
      this.layout.currentTimeIndicatorLineOffset +
      this.layout.currentTimeIndicatorHeight;

    this.canvasContext.lineWidth = 1;

    const plotRect = this.PlotRectangle();

    const minX = plotRect.x;
    const maxX = plotRect.x + plotRect.width;

    this.canvasContext.strokeStyle = this.layout.timeSubsegmentLineColor;

    const segmentsPadded = this.PadTimeSegments(this.timeSegmentsDrawn);

    for (let i = 0; i < segmentsPadded.length - 1; ++i) {
      const currSegment = segmentsPadded[i];
      const nextSegment = segmentsPadded[i + 1];

      const distanceX =
        nextSegment.dateStringPosition.x - (currSegment.dateStringPosition.x + currSegment.dateStringWidth);

      // const lineDistanceX = nextSegment.lineStart.x - currSegment.lineStart.x;

      const subsegmentWidth = dateFormat(currSegment.date, segmentTemplate);

      this.canvasContext.font = 'normal 11px Roboto';
      const textMeasure = this.canvasContext.measureText(subsegmentWidth);
      const textHorizontalMargin = 12;
      const textHeight = 14;

      let subsegmentCount = Math.floor(distanceX / (textMeasure.width + textHorizontalMargin * 2));

      if (subsegmentCount == 0) continue;

      const maxSubsegmentCount = 5;

      if (subsegmentCount > maxSubsegmentCount) subsegmentCount = maxSubsegmentCount;

      const subsegmentIntervalMilliseconds =
        moment(nextSegment.date).diff(currSegment.date, 'milliseconds') / subsegmentCount;

      let subsegmentTime = moment(currSegment.date).add(subsegmentIntervalMilliseconds, 'milliseconds').toDate();

      for (let j = 1; j < subsegmentCount; ++j) {
        const subsegmentX = this.DateToX(subsegmentTime);
        if (!(subsegmentX < minX || subsegmentX > maxX)) {
          const currSubsegmentTime = this.XToDate(subsegmentX);
          const subsegmentDateStr = dateFormat(currSubsegmentTime, segmentTemplate);

          const lineStartX = subsegmentX;
          const lineStartY = yOffset + textHeight + this.layout.timeSegmentLineOffset;

          const lineEndX = subsegmentX;
          const lineEndY = this.CanvasHeight();

          this.canvasContext.beginPath();
          this.canvasContext.moveTo(lineStartX, lineStartY);
          this.canvasContext.lineTo(lineEndX, lineEndY);
          this.canvasContext.stroke();

          const text = subsegmentDateStr;
          const textPosX = subsegmentX - textMeasure.width / 2;
          const textPosY = yOffset + textHeight;

          this.timeSubsegmentsDrawn.push({
            dateString: text,
            lineStart: {
              x: lineStartX,
              y: lineStartY,
            },
            lineEnd: {
              x: lineEndX,
              y: lineEndY,
            },
            dateStringPosition: {
              x: textPosX,
              y: textPosY,
            },
            dateStringWidth: textMeasure.width,
            date: currSubsegmentTime,
          });
        }

        subsegmentTime = moment(subsegmentTime).add(subsegmentIntervalMilliseconds, 'milliseconds').toDate();
      }
    }
  }

  DrawTimeSubsegmentCaptions() {
    this.canvasContext.strokeStyle = this.layout.timeSubsegmentLineColor;
    this.canvasContext.fillStyle = this.layout.timeSubsegmentTextColor;
    const pltoRect = this.PlotRectangle();

    for (const timeSegment of this.timeSubsegmentsDrawn) {
      this.canvasContext.font = 'normal 11px Roboto';
      this.canvasContext.fillText(
        timeSegment.dateString,
        timeSegment.dateStringPosition.x,
        timeSegment.dateStringPosition.y,
      );

      this.canvasContext.beginPath();
      this.canvasContext.moveTo(timeSegment.lineStart.x, timeSegment.lineStart.y);
      this.canvasContext.lineTo(timeSegment.lineEnd.x, pltoRect.y);
      this.canvasContext.stroke();
    }
  }

  DrawCurrentTimeIndicator() {
    const now = new Date();
    const indicatorX = this.DateToX(now);

    const plotRect = this.PlotRectangle();

    const minX = plotRect.x - 2;
    const maxX = plotRect.x + plotRect.width + 2;

    if (indicatorX < minX || indicatorX > maxX) return;

    // Draw rectangle
    this.canvasContext.beginPath();
    this.canvasContext.moveTo(indicatorX, this.layout.currentTimeIndicatorHeight);
    this.canvasContext.lineTo(indicatorX + this.layout.currentTimeIndicatorHeight / 1.65, 0);
    this.canvasContext.lineTo(indicatorX - this.layout.currentTimeIndicatorHeight / 1.65, 0);
    this.canvasContext.fillStyle = this.layout.currentTimeIndicatorColor;
    this.canvasContext.fill();

    // Draw line
    this.canvasContext.beginPath();
    this.canvasContext.lineWidth = 2;
    this.canvasContext.moveTo(
      indicatorX,
      this.layout.currentTimeIndicatorHeight + this.layout.currentTimeIndicatorLineOffset,
    );
    this.canvasContext.strokeStyle = this.layout.currentTimeIndicatorColor;
    this.canvasContext.lineTo(indicatorX, this.CanvasHeight());
    this.canvasContext.stroke();
  }

  DrawPrinterLines() {
    this.printerLinesDrawn = [];
    this.canvasContext.fillStyle = this.layout.printerNameTextColor;

    const plotRect = this.PlotRectangle();

    let offsetY = this.plotDataYOffset + plotRect.y + this.layout.jobRectangleHeight / 2 + 2;

    let offsetX = 0;

    for (const printerJobs of this.PrinterJobs) {
      const bottom = offsetY + this.layout.jobRectangleHeight / 2;
      const top = offsetY - this.layout.jobRectangleHeight / 2 - 2;
      if (bottom < plotRect.y) {
        offsetY += this.layout.jobRectangleHeight + this.layout.jobRectangleMargin;
        continue;
      }
      if (top > plotRect.y + plotRect.height) {
        break;
      }
      const printer = printerJobs.printer;

      const color = this.ColorForPrinter(printer);
      const colorHSL = convert.hex.hsl(color);
      colorHSL[2] = 40;
      const colorBrightened = '#' + convert.hsl.hex(colorHSL);

      const name = printer.Name;

      this.canvasContext.font = 'normal 14px Roboto';
      const fitName = this.canvasContext.fitText(name, this.layout.printerNameTextWidth - offsetX * 2);
      const measure = this.canvasContext.measureText(fitName);

      const textPosX = offsetX;
      const textPosY = offsetY + 2;

      const lineStartX = offsetX + measure.width + 6;
      const lineStartY = offsetY - 2;

      const lineEndX = this.CanvasWidth() - offsetX;
      const lineEndY = lineStartY;

      this.canvasContext.strokeStyle = colorBrightened;
      this.canvasContext.beginPath();
      this.canvasContext.moveTo(lineStartX, lineStartY);
      this.canvasContext.lineTo(lineEndX, lineEndY);
      this.canvasContext.setLineDash([1, 5]);
      this.canvasContext.stroke();

      this.printerLinesDrawn.push({
        printerString: fitName,
        lineStart: {
          x: lineStartX,
          y: lineStartY,
        },
        lineEnd: {
          x: lineEndX,
          y: lineEndY,
        },
        printerStringPosition: {
          x: textPosX,
          y: textPosY,
        },
        color: colorBrightened,
      });

      offsetY += this.layout.jobRectangleHeight + this.layout.jobRectangleMargin;
    }

    this.canvasContext.setLineDash([0, 0]);
  }

  DrawPrinterLinesCaptions() {
    this.canvasContext.fillStyle = this.layout.timeSegmentTextColor;
    const pltoRect = this.PlotRectangle();

    this.canvasContext.font = 'normal 14px Roboto';
    this.canvasContext.fillStyle = this.layout.printerNameTextColor;

    for (const printerLine of this.printerLinesDrawn) {
      this.canvasContext.strokeStyle = printerLine.color;

      this.canvasContext.fillText(
        printerLine.printerString,
        printerLine.printerStringPosition.x,
        printerLine.printerStringPosition.y,
      );

      this.canvasContext.beginPath();
      this.canvasContext.moveTo(printerLine.lineStart.x, printerLine.lineStart.y);
      this.canvasContext.lineTo(pltoRect.x, printerLine.lineEnd.y);
      this.canvasContext.setLineDash([1, 5]);
      this.canvasContext.stroke();
    }

    this.canvasContext.setLineDash([0, 0]);
  }

  DrawJobRectangles() {
    const plotRect = this.PlotRectangle();
    this.jobsDrawn = [];

    const now = new Date();
    let offsetY = this.plotDataYOffset + plotRect.y;

    const minX = plotRect.x;
    const maxX = plotRect.x + plotRect.width;

    for (const printerJobs of this.PrinterJobs) {
      const top = offsetY;
      const bottom = offsetY + this.layout.jobRectangleHeight;

      if (bottom < plotRect.y) {
        offsetY += this.layout.jobRectangleHeight + this.layout.jobRectangleMargin;
        continue;
      }
      if (top > plotRect.y + plotRect.height) {
        break;
      }

      const color = this.ColorForPrinter(printerJobs.printer);
      const currPrintingJob = printerJobs.jobs.firstOrDefault(a => a.job.Order == null);

      let currNotStartedX = this.DateToX(now);

      if (currPrintingJob != null) {
        let endTime: Date = moment(currPrintingJob.job.ActualStartTime)
          .add(PRINT_DURATION_UNKNOWN, 'milliseconds')
          .toDate();

        if (currPrintingJob.source != null && currPrintingJob.source.PrintDuration != null) {
          endTime = moment(currPrintingJob.job.ActualStartTime).add(currPrintingJob.source.PrintDuration).toDate();
        }

        currNotStartedX = this.DateToX(endTime);
      }

      for (const job of printerJobs.jobs) {
        this.canvasContext.fillStyle = color;
        const startTime: Date =
          job.job.ActualStartTime == null ? this.XToDate(currNotStartedX) : job.job.ActualStartTime;

        let endTime: Date = moment(startTime).add(PRINT_DURATION_UNKNOWN, 'milliseconds').toDate();

        let endTimeUnknown = true;

        if (job.source != null && job.source.PrintDuration != null) {
          endTime = moment(startTime).add(job.source.PrintDuration.totalMilliseconds, 'milliseconds').toDate();

          endTimeUnknown = false;
        }

        let startX = this.DateToX(startTime);
        let endX = this.DateToX(endTime);

        let length = endX - startX;

        if (Math.floor(startX) == Math.floor(currNotStartedX)) {
          currNotStartedX += length + Math.round(PRINT_INTERVAL_MS * this.PixelsPerMillisecond());
        } else if (currPrintingJob != null && job.job.Id.toString() == currPrintingJob.job.Id.toString()) {
          currNotStartedX += Math.round(PRINT_INTERVAL_MS * this.PixelsPerMillisecond());
        }

        if (startX < minX) {
          length -= Math.abs(startX - minX);
          startX = minX;
        }

        if (length < 0) {
          continue;
        }

        let actualEndX = startX + length;

        let drewLast = false;

        if (actualEndX > maxX) {
          length -= Math.abs(actualEndX - maxX);
          actualEndX = maxX;
          drewLast = true;
        }

        if (length < 0) break;

        this.canvasContext.globalAlpha = 0.75;
        this.canvasContext.roundRect(startX, offsetY, length, this.layout.jobRectangleHeight, 6);
        if (endTimeUnknown) {
          const gradient = this.canvasContext.createLinearGradient(startX, offsetY, startX + length, offsetY);
          gradient.addColorStop(0, color);
          gradient.addColorStop(0.5, color);
          gradient.addColorStop(1, 'transparent');
          this.canvasContext.fillStyle = gradient;
        }
        this.canvasContext.fill();
        this.canvasContext.globalAlpha = 1;

        const drawnJobToAdd: DrawnJobRectangle = {
          rect: {
            x: startX,
            y: offsetY,
            width: length,
            height: this.layout.jobRectangleHeight,
          },
          job: job,
        };

        if (this.jobHovered != null && drawnJobToAdd.job.job.Id.toString() == this.jobHovered.job.job.Id.toString()) {
          this.jobHovered = drawnJobToAdd;
        }

        this.jobsDrawn.push(drawnJobToAdd);

        const jobFileName =
          job.job.LocalFilename != null
            ? job.job.LocalFilename
            : job.source != null
              ? job.source.CodeFileName
              : 'Not available';

        const iconToDraw = job.job.Order == null ? PLAY_UNICODE : CLOCK_UNICODE;
        const iconOffset = 22;

        const iconLeftMargin = 4;
        const iconFontSize = iconToDraw == CLOCK_UNICODE ? 18 : 14;

        if (length - 12 > iconOffset + iconLeftMargin) {
          this.canvasContext.font = `normal ${iconFontSize}px Roboto`;
          this.canvasContext.fillStyle = this.layout.jobRectangleTextColor;
          this.canvasContext.fillText(
            iconToDraw,
            startX + this.layout.jobRectangleTextOffset + iconLeftMargin,
            offsetY + this.layout.jobRectangleHeight / 2 + 5,
          );
        }

        let textToFit = jobFileName;

        if (job.job.Progress != null) {
          textToFit += ` ${job.job.Progress.toFixed(1)}%`;
        }
        this.canvasContext.font = 'normal 14px Roboto';
        const textFit = this.canvasContext.fitText(
          textToFit,
          length - this.layout.jobRectangleTextOffset * 2 - iconOffset - iconLeftMargin,
        );

        this.canvasContext.fillStyle = this.layout.jobRectangleTextColor;
        this.canvasContext.fillText(
          textFit,
          startX + iconOffset + this.layout.jobRectangleTextOffset + iconLeftMargin,
          offsetY + this.layout.jobRectangleHeight / 2 + 4,
        );

        if (drewLast) {
          break;
        }
      }

      offsetY += this.layout.jobRectangleHeight + this.layout.jobRectangleMargin;
    }
  }

  DrawSelectedJobRecntage() {
    if (this.selectedJob == null) {
      return;
    }

    let selectedDrawn: DrawnJobRectangle | null = null;
    for (const drawnJob of this.jobsDrawn) {
      if (drawnJob.job.job.Id.toString() == this.selectedJob.job.Id.toString()) {
        selectedDrawn = drawnJob;
      }
    }

    if (selectedDrawn == null) {
      return;
    }

    const rect = selectedDrawn.rect;
    this.canvasContext.lineWidth = 1;
    this.canvasContext.strokeStyle = 'white';
    this.canvasContext.roundRect(rect.x, rect.y, rect.width, rect.height, 6);
    this.canvasContext.stroke();
  }

  DrawHoveredJobRectangle() {
    if (this.jobHovered == null) {
      return;
    }

    const rect = this.jobHovered.rect;
    this.canvasContext.lineWidth = 1;
    this.canvasContext.strokeStyle = 'white';
    this.canvasContext.roundRect(rect.x, rect.y, rect.width, rect.height, 6);
    this.canvasContext.globalAlpha = 0.5;
    this.canvasContext.stroke();
    this.canvasContext.globalAlpha = 1;
  }

  DrawPlot() {
    const plotRect = this.PlotRectangle();

    this.canvasContext.fillStyle = this.layout.plotBackgroundColor;
    this.canvasContext.roundRect(plotRect.x, plotRect.y, plotRect.width, plotRect.height, 6);
    this.canvasContext.fill();
  }

  DrawPlotBorder() {
    const plotRect = this.PlotRectangle();

    this.canvasContext.strokeStyle = this.layout.plotBorderColor;
    this.canvasContext.lineWidth = 1;
    this.canvasContext.roundRect(plotRect.x, plotRect.y, plotRect.width, plotRect.height, 6);
    this.canvasContext.stroke();
  }

  DrawPlotClip() {
    const plotRect = this.PlotRectangle();

    this.canvasContext.fillStyle = 'black';
    this.canvasContext.roundRect(plotRect.x, plotRect.y, plotRect.width, plotRect.height, 6);
    this.canvasContext.globalCompositeOperation = 'destination-in';
    this.canvasContext.fill();
    this.canvasContext.globalCompositeOperation = 'source-over';
  }

  DrawPrinterLinesClip() {
    const plotRect = this.PlotRectangle();

    this.canvasContext.fillStyle = 'black';
    this.canvasContext.roundRect(0, plotRect.y - 1, this.CanvasWidth(), plotRect.height + 2, 6);
    this.canvasContext.globalCompositeOperation = 'destination-in';
    this.canvasContext.fill();
    this.canvasContext.globalCompositeOperation = 'source-over';
  }

  PlotRectangle(): Rectangle {
    let offsetY =
      this.layout.currentTimeIndicatorLineOffset +
      this.layout.currentTimeIndicatorHeight +
      this.layout.currentTimeIndicatorOffset +
      32;

    let offsetX = this.layout.printerNameTextWidth;

    return {
      x: offsetX,
      y: offsetY,
      width: this.CanvasWidth() - offsetX,
      height: this.CanvasHeight() - offsetY - 12,
    };
  }

  DrawnJobRectangleUnder(x: number, y: number) {
    for (const drawnJob of this.jobsDrawn) {
      const rect = drawnJob.rect;

      if (x >= rect.x && y >= rect.y && x <= rect.x + rect.width && y <= rect.y + rect.height) {
        return drawnJob;
      }
    }

    return null;
  }
  //#endregion
  //#endregion

  //#region HOOKS
  beforeCreate() {
    this.resizeObserver = new ResizeObserver(() => {
      this.CanvasContainerResized();
    });

    this.plotDataYOffset = 0;

    this.layout = {
      currentTimeIndicatorHeight: 12,
      currentTimeIndicatorColor: '#00F654',
      currentTimeIndicatorLineOffset: 12,
      currentTimeIndicatorOffset: 6,

      timeSegmentOffset: 12,
      timeSegmentTextColor: '#DCDCDC',
      timeSegmentLineColor: '#5B5B5B',
      timeSegmentLineOffset: 6,

      timeSubsegmentTextColor: '#ababab',
      timeSubsegmentLineColor: '#5B5B5B',

      plotBackgroundColor: '#373737',
      plotBorderColor: '#666666',
      plotMarginTop: 14,
      plotMarginLeft: 30,

      printerNameTextColor: '#DCDCDC',
      printerNameTextWidth: 180,
      printerNameLineOffset: 6,

      jobRectangleHeight: 40,
      jobRectangleMargin: 4,
      jobRectangleTextColor: '#DCDCDC',
      jobRectangleTextOffset: 8,
    };
  }

  created() {
    this.GenerateColorsForPrinters();
  }

  @Emit('mounted')
  mounted() {
    if (this.dateFrom == undefined || this.dateTo == undefined) {
      const now = new Date();
      this.dateFrom = moment(now).subtract(6, 'hours').toDate();
      this.dateTo = moment(this.dateFrom).add(12, 'hours').toDate();
      this.plotDataYOffset = 0;

      this.segmentType = TimeSegmentType.Hour;

      this.canvasContainer = this.$refs.canvasContainer as HTMLDivElement;
      this.canvasContext = (this.$refs.canvas as HTMLCanvasElement).getContext('2d')!;
    }

    this.resizeObserver.observe(this.canvasContainer);

    this.CanvasContainerResized();

    this.redrawIntervalId = window.setInterval(() => {
      this.DrawData();
    }, 1000);
  }

  activated() {
    // if (this.dateFrom == undefined || this.dateTo == undefined) {
    //   const now = new Date();
    //   this.dateFrom = moment(now)
    //     .subtract(6, "hours")
    //     .toDate();
    //   this.dateTo = moment(this.dateFrom)
    //     .add(12, "hours")
    //     .toDate();
    //   this.plotDataYOffset = 0;
    //   this.segmentType = TimeSegmentType.Hour;
    //   this.canvasContainer = this.$refs.canvasContainer as HTMLDivElement;
    //   this.canvasContext = (this.$refs.canvas as HTMLCanvasElement).getContext(
    //     "2d"
    //   )!;
    // }
    // this.resizeObserver.observe(this.canvasContainer);
    // this.CanvasContainerResized();
    // this.redrawIntervalId = window.setInterval(() => {
    //   this.DrawData();
    // }, 1000);
  }

  deactivated() {
    // this.resizeObserver.unobserve(this.canvasContainer);
    // this.resizeObserver.disconnect();
    // window.clearInterval(this.redrawIntervalId);
  }

  beforeDestroy() {
    this.resizeObserver.unobserve(this.canvasContainer);
    this.resizeObserver.disconnect();
    window.clearInterval(this.redrawIntervalId);
  }
  //#endregion

  //#region EVENTS
  @Emit('job-clicked')
  JobSelected(job: FullJob) {
    return job;
  }

  @Emit('job-hovered')
  JobHovererd(job: FullJob) {
    return job;
  }

  @Emit('time-range-changed')
  TimeRangeChanged() {
    return { from: this.dateFrom, to: this.dateTo };
  }

  @Emit('vertical-offset-changed')
  VerticalOffsetChanged() {
    return this.plotDataYOffset;
  }
  //#endregion

  //#region TRANSLATIONS
  //#endregion
}
</script>

<style lang="scss" scoped>
.jobs-timeline {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  canvas {
    width: 100%;
    height: 100%;
  }
}
</style>
