<template>
  <div class="editor-header px-3 py-2">
    <span class="overflow-hidden" style="text-overflow: ellipsis">
      <slot name="header" />
    </span>
    <div class="ml-3" style="min-width: fit-content">
      <slot name="buttons" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class EditorHeader extends Vue {}
</script>

<style lang="scss" scoped>
.editor-header {
  display: flex;
  color: var(--editor-header-text);
  align-items: center;
  justify-content: space-between;
  background: var(--editor-header-background);
  border-radius: 6px 6px 0px 0px;
  font-size: 14px;

  span {
    color: var(--editor-header-text);
  }

  i {
    cursor: pointer;
    color: var(--editor-action-button);
    &:hover {
      opacity: 0.75;
    }
  }
}
</style>
