import store from '@/store';
import { Invoice } from '@/models/Entities';
import { HubConnection } from '@microsoft/signalr';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import * as ips from '@/util/api/ips';
import * as routes from '@/util/api/routes';
import * as hubActions from '@/util/api/hubActions';
import {
  ResponseInvoice,
  ResponseInvoicePaymentIntentSecret,
  ResponseInvoices,
} from '@/models/responses/ResponseBilling';
import { TypeHelper } from '@/util/TypeHelper';
import { Guid } from 'guid-typescript';
import { UtilModule } from '../utilModule';
import {
  InvoiceSortBy,
  RequestReadAllByAccountIdInvoice,
  RequestReadPagedByAccountIdInvoice,
  RequestReadPaymentIntentSecretInvoice,
} from '@/models/requests/RequestsBilling';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { LoginModule } from '../loginModule';
import { SortMode } from '@/util/ComponentHelper';
import { New_GC } from '@/store/util/GC';
import { GuidHelper } from '@/util/GuidHelper';
import Vue from 'vue';
import { ISimpleEvent, SimpleEventDispatcher } from 'strongly-typed-events';

export interface LoadInvoicesPageData {
  accountId: Guid;
  sortBy: InvoiceSortBy | null;
  sortMode: SortMode | null;
  page: number;
}

export const InvoicePageSize = 20;

@Module({ dynamic: true, name: 'invoice', store: store })
export default class invoiceModule extends VuexModule {
  private _gc: New_GC = new New_GC();

  private invoices: Invoice[] = [];

  private onInvoicePaymentActionRequired = new SimpleEventDispatcher<Invoice>();
  private onInvoicePaymentFailed = new SimpleEventDispatcher<Invoice>();

  get Invoices(): Invoice[] {
    return this.invoices;
  }

  public get OnInvoicePaymentActionRequired(): ISimpleEvent<Invoice> {
    return this.onInvoicePaymentActionRequired.asEvent();
  }

  public get OnInvoicePaymentFailed(): ISimpleEvent<Invoice> {
    return this.onInvoicePaymentFailed.asEvent();
  }

  @Mutation
  AddInvoice(val: Invoice) {
    if (this.invoices.firstOrDefault(a => a.id.toString() == val.id.toString()) != null) {
      return;
    }

    this.invoices.push(val);
    this._gc.Add([val.id]);
  }

  @Mutation
  UpdateInvoice(val: Invoice) {
    this.invoices = this.invoices.map(inv => {
      if (val.id.toString() == inv.id.toString()) {
        inv.invoiceNumber = val.invoiceNumber;
        inv.invoicePdfUrl = val.invoicePdfUrl;
        inv.status = val.status;
        inv.totalAmount = val.totalAmount;
        inv.paymentState = val.paymentState;
        inv.stripePaymentIntentId = val.stripePaymentIntentId;
      }

      return inv;
    });
  }

  @Mutation
  DeleteInvoice(val: Invoice) {
    this.invoices.delete(val);
    this._gc.Remove([val.id]);
  }

  @Mutation
  ClearInvoices() {
    this._gc.Remove(this.invoices.map(a => a.id));
    this.invoices = [];
  }

  @Action({ rawError: true })
  CollectInvoices() {
    const removed = this._gc.Collect();

    for (const invoice of this.invoices.filter(a => GuidHelper.includes(removed, a.id))) {
      this.DeleteInvoice(invoice);
    }
  }

  @Action({ rawError: true })
  OccupyInvoices([invoices, componentId]: [Invoice[], Guid]) {
    this._gc.Occupy(
      invoices.map(a => a.id),
      componentId,
    );
  }

  @Action({ rawError: true })
  LooseInvoices([invoices, componentId]: [Invoice[], Guid]) {
    this._gc.Loose(
      invoices.map(a => a.id),
      componentId,
    );
  }

  @Action({ rawError: true })
  InvoiceSubscribe(hubConnection: HubConnection) {
    hubConnection.on(hubActions.RECEIVE_CREATE_INVOICE, async (res: ResponseInvoice) => {
      this._gc.Lock();
      const invoice = TypeHelper.DeepCopyFrom(res, ResponseInvoice).Map();

      this.AddInvoice(invoice);

      Vue.nextTick(() => {
        this._gc.Unlock();
      });
    });

    hubConnection.on(hubActions.RECEIVE_UPDATE_INVOICE, async (res: ResponseInvoice) => {
      const invoice = TypeHelper.DeepCopyFrom(res, ResponseInvoice).Map();

      this.UpdateInvoice(invoice);
    });

    hubConnection.on(hubActions.RECEIVE_INVOICE_PAYMENT_ACTION_REQUIRED, async (res: ResponseInvoice) => {
      const invoice = TypeHelper.DeepCopyFrom(res, ResponseInvoice).Map();

      this.onInvoicePaymentActionRequired.dispatch(invoice);
    });

    hubConnection.on(hubActions.RECEIVE_INVOICE_PAYMENT_FAILED, async (res: ResponseInvoice) => {
      const invoice = TypeHelper.DeepCopyFrom(res, ResponseInvoice).Map();

      this.onInvoicePaymentFailed.dispatch(invoice);
    });
  }

  @Action({ rawError: true })
  async LoadInvoices(accountId: Guid) {
    this.ClearInvoices();

    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.INVOICE,
      routes.UriInvoice.READ_ALL_BY_ACCOUNT_ID,
    );

    const request = new RequestReadAllByAccountIdInvoice();
    request.accountId = accountId.toString();

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = axiosResponse.data.response as ResponseInvoices;
      const invoices = TypeHelper.DeepCopyFrom(response, ResponseInvoices).invoices;

      for (const res of invoices) {
        const invoice = TypeHelper.DeepCopyFrom(res, ResponseInvoice);
        this.AddInvoice(invoice.Map());
      }
    }
  }

  @Action({ rawError: true })
  async LoadInvoicesPage(data: LoadInvoicesPageData) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.INVOICE,
      routes.UriInvoice.READ_PAGED_BY_ACCOUNT_ID,
    );

    this._gc.Lock();

    const request = new RequestReadPagedByAccountIdInvoice();
    request.accountId = data.accountId.toString();
    request.page = data.page;
    request.pageSize = InvoicePageSize;
    request.sortBy = data.sortBy;
    request.sortMode = data.sortMode;

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = axiosResponse.data.response as ResponseInvoices;
      const invoices = TypeHelper.DeepCopyFrom(response, ResponseInvoices).invoices;

      for (const res of invoices) {
        const invoice = TypeHelper.DeepCopyFrom(res, ResponseInvoice).Map();
        this.AddInvoice(invoice);
      }
    }

    Vue.nextTick(() => {
      this._gc.Unlock();
    });
  }

  @Action({ rawError: true })
  async LoadPaymentIntentSecret(invoiceId: Guid): Promise<string | undefined> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.INVOICE,
      routes.UriInvoice.READ_PAYMENT_INTENT_SECRET,
    );

    const request = new RequestReadPaymentIntentSecretInvoice();
    request.invoiceId = invoiceId.toString();

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = axiosResponse.data.response as ResponseInvoicePaymentIntentSecret;
      const secret = TypeHelper.DeepCopyFrom(response, ResponseInvoicePaymentIntentSecret).clientSecret;

      return secret;
    }

    return undefined;
  }

  @Action({ rawError: true })
  async AddToInvoiceGroup() {
    if (LoginModule.BillingConnection == null) return;
    await LoginModule.BillingConnection.send(hubActions.ADD_TO_INVOICE_GROUP);
    LoginModule.AddBillingConnectionGroup({
      func: this.AddToInvoiceGroup,
    });
  }

  @Action({ rawError: true })
  async DeleteFromInvoiceGroup() {
    if (LoginModule.BillingConnection == null) return;
    await LoginModule.BillingConnection.send(hubActions.DELETE_FROM_INVOICE_GROUP);
    LoginModule.DeleteBillingConnectionGroup({
      func: this.AddToInvoiceGroup,
    });
  }
}

export const InvoiceModule = getModule(invoiceModule);
