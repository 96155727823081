<template>
  <div :class="['date-input', disabled ? 'disabled' : '']">
    <span
      v-if="label !== undefined"
      :style="labelWidth !== undefined ? { 'min-width': labelWidth + 'px' } : {}"
      class="label mr-2"
      >{{ label }}</span
    >

    <date-picker
      v-if="!readonly && dateValue !== undefined"
      v-model="dateValue"
      type="datetime"
      :input-class="['date-picker-input', invalid ? 'error-border' : '']"
      :clearable="false"
      :show-hour="showHour"
      :show-minute="showMinute"
      :show-second="showSecond"
      :show-time-panel="showTime"
      :editable="false"
      :format="formatInput"
    >
      <template #footer>
        <button class="mx-btn mx-btn-text" @click="toggleTime">
          {{ showTime ? 'Select date' : 'Select time' }}
        </button>
      </template>
    </date-picker>
    <span
      v-if="readonly && dateValue !== undefined"
      :class="['value message-text', fillWidth ? 'flex-grow-1' : '', IsClickable ? 'clickable' : '']"
      @click="Clicked"
    >
      {{ DateString }}
    </span>
  </div>
</template>

<script lang="ts">
import dateformat from 'dateformat';
import { Component, Prop, Vue, PropSync, Emit } from 'vue-property-decorator';

import DatePicker from 'vue2-datepicker';

@Component({
  components: {
    DatePicker: DatePicker,
  },
})
export default class DateInput extends Vue {
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: undefined }) label?: string;
  @Prop({ default: false }) invalid!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: undefined }) labelWidth?: number;
  @Prop({ default: true }) showHour!: boolean;
  @Prop({ default: true }) showMinute!: boolean;
  @Prop({ default: false }) showSecond!: boolean;
  @Prop({ default: 'yyyy-mm-dd HH:MM' }) format!: string;
  @Prop({ default: 'YYYY-MM-DD HH:mm' }) formatInput!: string;
  @Prop({ default: true }) fillWidth!: boolean;
  @Prop({ default: undefined }) testId?: string;

  @PropSync('date', { type: Date, default: undefined })
  dateValue?: Date;

  @Emit('clicked')
  Clicked() {}

  private showTime: boolean = false;

  private toggleTime() {
    this.showTime = !this.showTime;
  }

  private get DateString() {
    return dateformat(new Date(this.dateValue!), this.format);
  }

  private get IsClickable() {
    return this.$listeners.clicked != undefined;
  }
}
</script>

<style lang="scss" scoped>
.date-input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 28px;

  span {
    display: flex;
    font-size: 14px;
    line-height: 16px;

    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    white-space: nowrap;
    color: var(--editor-field-name);

    &.value {
      color: var(--editor-field-value);
      margin-left: 11px;
    }

    &.clickable {
      color: var(--editor-field-value-clickable);
      cursor: pointer;
      &:hover {
        opacity: 0.75;
      }
    }
  }

  .error-border {
    border: 1px solid var(--main-orange);
  }
}
</style>
