import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import store from '@/store';
import { Product, ProductPrice } from '@/models/Entities';
import { Guid } from 'guid-typescript';
import { ProductModule } from './productModule';

export class CartItem {
  price!: ProductPrice;
  product!: Product;
  quantity!: number;
  id!: Guid;
}

@Module({ dynamic: true, name: 'cart', store: store })
export default class cartModule extends VuexModule {
  private cartItems: CartItem[] = [];

  get CartItems(): CartItem[] {
    return this.cartItems;
  }

  get HasSubscription(): boolean {
    return this.cartItems.firstOrDefault(a => a.price.recurringInterval !== null) !== null;
  }

  @Mutation
  AddToCart(val: CartItem) {
    this.cartItems.push(val);
  }

  @Mutation
  RemoveFromCart(val: CartItem) {
    this.cartItems.delete(val);
  }

  @Mutation
  UpdateInCart(toUpdate: CartItem) {
    this.cartItems = this.cartItems.map(item => {
      if (toUpdate.id.toString() === item.id.toString()) {
        item.price = toUpdate.price;
        item.quantity = toUpdate.quantity;
      }
      return item;
    });
  }

  @Mutation
  ClearCart() {
    this.cartItems = [];
  }

  @Action({ rawError: true })
  async SwitchRecurring([prevPrice, newPrice]: [ProductPrice, ProductPrice]) {
    const foundInCart = await this.FindInCart(prevPrice);

    if (foundInCart === null) {
      return;
    }

    foundInCart.price = newPrice;

    this.UpdateInCart(foundInCart);
  }

  @Action({ rawError: true })
  async QuantityAdded(price: ProductPrice) {
    const foundInCart = await this.FindInCart(price);

    if (foundInCart === null) {
      const toAdd: CartItem = {
        price: price,
        quantity: 1,
        id: Guid.create(),
        product: ProductModule.Products.firstOrDefault(a => a.id.toString() === price.productId.toString())!,
      };

      this.AddToCart(toAdd);

      return;
    }

    foundInCart.quantity++;

    this.UpdateInCart(foundInCart);
  }

  @Action({ rawError: true })
  async QuantityRemoved(price: ProductPrice) {
    const foundInCart = await this.FindInCart(price);

    if (foundInCart === null) {
      return;
    }

    foundInCart.quantity--;

    if (foundInCart.quantity === 0) {
      this.RemoveFromCart(foundInCart);
    }

    this.UpdateInCart(foundInCart);
  }

  @Action({ rawError: true })
  async FindInCart(price: ProductPrice): Promise<CartItem | null> {
    return this.cartItems.firstOrDefault(a => a.price.id.toString() === price.id.toString());
  }
}

export const CartModule = getModule(cartModule);
