import { TypeHelper, TimeStatus } from '@/util/TypeHelper';
import en from '../localization/en';
import moment from 'moment';
import { JobState, UserJob, User, Invoice, PaymentMethod, EndStatus, PrinterState, Role } from '@/models/Entities';
import { UtilModule } from '@/store/modules/utilModule';
import { PaymentMethodKind } from '@/models/enums/PaymentMethodKind';
import { InvoiceStatus } from '@/models/enums/InvoiceStatus';
import { PurchaseStatus } from '@/models/enums/PurchaseStatus';
import { RecurringIntervalKind } from '@/models/enums/RecurringIntervalKind';
import { CompanyRouteTab, JobsRouteTab } from '@/router/routes';
import { Countries, States } from '@/models/responses/Countries';
import { getAllEnumEntries } from '@/util/enums';
import { TaxType } from '@/models/enums/TaxType';
import {
  BuildPlateTemperatureTelemetry,
  ChamberTemperatureTelemetry,
  ExtruderMaterialUsedTelemetry,
  FanSpeedTelemetry,
  PrintHeadPositionTelemetry,
  PrintHeadTemperatureTelemetry,
  TelemetryViewModel,
} from '@/models/responses/ResponseConnector';

const KILOBYTE = 1024;
const MEGABYTE = 1024 * 1024;
const GIGABYTE = 1024 * 1024 * 1024;

export enum PrinterPanelControlType {
  CurrentJobs,
  ArchiveJobs,
  Terminal,
  ErrorLog,
  Telemetry,
}

export enum PrinterPanelControlState {
  FullWidth,
  HalfWidth,
}

export default class ComponentHelper {
  static GetReadableRoleName(role: Role) {
    if (role.IsAdmin) {
      return 'Company admin';
    } else if (role.IsBillingAccountAdmin) {
      return 'Billing account admin';
    } else if (role.IsBillingAccountOwner) {
      return 'Billing account owner';
    } else if (role.IsSuperAdmin) {
      return 'Aura admin';
    } else if (role.IsConnectUser) {
      return 'Connect user';
    }

    return 'Unknown';
  }

  static GetTelemetryViewModelUnit(vm: TelemetryViewModel) {
    if (vm instanceof BuildPlateTemperatureTelemetry) {
      return '°C';
    } else if (vm instanceof ChamberTemperatureTelemetry) {
      return '°C';
    } else if (vm instanceof ExtruderMaterialUsedTelemetry) {
      return 'mm';
    } else if (vm instanceof FanSpeedTelemetry) {
      return '%';
    } else if (vm instanceof PrintHeadPositionTelemetry) {
      return 'mm';
    } else if (vm instanceof PrintHeadTemperatureTelemetry) {
      return '°C';
    }
  }

  static GetPrinterStateReadableText(state: PrinterState): string {
    switch (state) {
      case PrinterState.UNKNOWN:
        return en.unknown;
      case PrinterState.IDLE:
        return en.idle;
      case PrinterState.BUSY:
        return en.busy;
      case PrinterState.PRINTING:
        return en.printing;
      case PrinterState.PAUSING:
        return en.pausing;
      case PrinterState.PAUSED:
        return en.paused;
      case PrinterState.RESUMING:
        return en.resuming;
      case PrinterState.PRINT_FINISHED:
        return en.printFinished;
      case PrinterState.HALTED:
        return en.halted;
      case PrinterState.UPDATING_FIRMWARE:
        return en.updatingFirmware;
      case PrinterState.DISCONNECTED:
        return en.disconnected;
    }
  }
  static GetReadbaleBytesSize(byteCount: number) {
    let firstPart = byteCount / KILOBYTE;
    let secondPart = 'KB';

    if (byteCount > GIGABYTE / 5) {
      firstPart = byteCount / GIGABYTE;
      secondPart = 'GB';
    } else if (byteCount > MEGABYTE) {
      firstPart = byteCount / MEGABYTE;
      secondPart = 'MB';
    }
    // else if (byteCount > KILOBYTE) {
    //   firstPart = byteCount / KILOBYTE;
    //   secondPart = "KB";
    // }

    return `${parseFloat(firstPart.toFixed(2))} ${secondPart}`;
  }

  static GetReadableControlType(controlType: PrinterPanelControlType): string {
    switch (controlType) {
      case PrinterPanelControlType.ArchiveJobs: {
        return 'Archive jobs';
      }
      case PrinterPanelControlType.CurrentJobs: {
        return 'Jobs';
      }
      case PrinterPanelControlType.ErrorLog: {
        return 'Error log';
      }
      case PrinterPanelControlType.Telemetry: {
        return 'Telemetry';
      }
      case PrinterPanelControlType.Terminal: {
        return 'Terminal';
      }
    }
  }

  static GetReadableTaxRateType(type: TaxType) {
    switch (type) {
      case TaxType.SalesTax: {
        return 'Sales tax';
      }
      case TaxType.VAT: {
        return 'VAT';
      }
    }
  }
  static IsCountryInEurope(country: Countries) {
    if (
      country == Countries.Germany ||
      country == Countries.France ||
      country == Countries.Italy ||
      country == Countries.Spain ||
      country == Countries.Poland ||
      country == Countries.Romania ||
      country == Countries.Netherlands ||
      country == Countries.Belgium ||
      country == Countries.CzechRepublic ||
      country == Countries.Greece ||
      country == Countries.Portugal ||
      country == Countries.Sweden ||
      country == Countries.Hungary ||
      country == Countries.Austria ||
      country == Countries.Bulgaria ||
      country == Countries.Denmark ||
      country == Countries.Finland ||
      country == Countries.Slovakia ||
      country == Countries.Ireland ||
      country == Countries.Croatia ||
      country == Countries.Lithuania ||
      country == Countries.Slovenia ||
      country == Countries.Latvia ||
      country == Countries.Estonia ||
      country == Countries.Cyprus ||
      country == Countries.Luxembourg ||
      country == Countries.Malta
    ) {
      return true;
    }

    return false;
  }
  static StatesForCountry(country: Countries) {
    const countryCode = en.CountryCodes[country];
    const states: States[] = [];

    for (const entry of getAllEnumEntries(States)) {
      const stateCountryCode = entry[0].split('_')[0];
      if (stateCountryCode == countryCode) {
        states.push(entry[1]);
      }
    }

    return states;
  }
  static GetReadableCompanyViewTab(tab: CompanyRouteTab) {
    switch (tab) {
      case CompanyRouteTab.BILLING: {
        return en.billingTabCaption;
      }
      case CompanyRouteTab.INFO: {
        return en.infoTabCaption;
      }
      case CompanyRouteTab.PRODUCTS: {
        return en.productsTabCaption;
      }
      case CompanyRouteTab.STORE: {
        return en.shopTabCaption;
      }
    }
  }
  static GetReadableJobsViewTab(tab: JobsRouteTab) {
    switch (tab) {
      case JobsRouteTab.CURRENT: {
        return en.current;
      }
      case JobsRouteTab.ARCHIVE: {
        return en.archieve;
      }
    }
  }
  static GetReadablePurchaseStatus(status: PurchaseStatus) {
    switch (status) {
      case PurchaseStatus.Active: {
        return en.active.titleCase();
      }
      case PurchaseStatus.Canceled: {
        return en.cancelled.titleCase();
      }
      case PurchaseStatus.Incomplete: {
        return en.incomplete.titleCase();
      }
      case PurchaseStatus.IncompleteExpired: {
        return en.incompleteExpired.titleCase();
      }
      case PurchaseStatus.PastDue: {
        return en.pastDue.titleCase();
      }
      case PurchaseStatus.Unpaid: {
        return en.unpaid.titleCase();
      }
    }
  }
  static GetReadableEndStatus(endStatus: EndStatus) {
    switch (endStatus) {
      case EndStatus.SUCCESS:
        return en.success.growFirst();
      case EndStatus.CANCELLED:
        return en.cancel.growFirst();
      case EndStatus.UNKNOWN:
        return en.unknown.growFirst();
    }
  }
  static GetReadableRecurringInterval(kind: RecurringIntervalKind) {
    switch (kind) {
      case RecurringIntervalKind.Day: {
        return en.daily.titleCase();
      }
      case RecurringIntervalKind.Week: {
        return en.weekly.titleCase();
      }
      case RecurringIntervalKind.Month: {
        return en.monthly.titleCase();
      }
      case RecurringIntervalKind.Year: {
        return en.yearly.titleCase();
      }
    }
  }
  static GetReadableRecurringIntervalNonPlural(kind: RecurringIntervalKind) {
    switch (kind) {
      case RecurringIntervalKind.Day: {
        return en.day.titleCase();
      }
      case RecurringIntervalKind.Week: {
        return en.week.titleCase();
      }
      case RecurringIntervalKind.Month: {
        return en.month.titleCase();
      }
      case RecurringIntervalKind.Year: {
        return en.year.titleCase();
      }
    }
  }
  static GetReadableDateTime(date: Date, onlyDate: boolean = false, withSeconds: boolean = false): string {
    let format = 'HH:mm';

    if (withSeconds) {
      format = 'HH:mm:ss';
    }

    switch (TypeHelper.GetTimeStatus(date)) {
      case TimeStatus.NOW: {
        if (onlyDate) {
          return `${en.today} ${en.at} ${moment(date).format(format)}`;
        }
        return en.now;
      }
      case TimeStatus.TODAY: {
        return `${en.today} ${en.at} ${moment(date).format(format)}`;
      }
      default: {
        return `${date.toLocaleDateString().replace(/\//g, '.')} ${en.at} ${moment(date).format(format)}`;
      }
    }
  }
  static GetDateTime(date: Date): string {
    switch (TypeHelper.GetTimeStatus(date)) {
      case TimeStatus.NOW:
      case TimeStatus.TODAY: {
        return `${en.today} ${en.at} ${moment(date).format('HH:mm')}`;
      }
      default: {
        return `${date.toLocaleDateString()}`;
      }
    }
  }
  static GetFullname(user: User): string {
    let fnPart = '';
    let sdPart = '';
    if (user.FirstName && user.FirstName.length > 0)
      fnPart = `${user.FirstName[0].toUpperCase()}${user.FirstName.substr(1).toLowerCase()}`;
    if (user.SecondName && user.SecondName.length > 0)
      sdPart = `${user.SecondName[0].toUpperCase()}${user.SecondName.substr(1).toLowerCase()}`;
    if (fnPart.length > 0 || sdPart.length > 0) return `${fnPart} ${sdPart}`;
    return user.Email;
  }
  static GetSimpleDate(date: Date): string {
    switch (TypeHelper.GetTimeStatus(date)) {
      case TimeStatus.NOW: {
        return en.online;
      }
      case TimeStatus.TODAY: {
        return en.today;
      }
      case TimeStatus.WEEK: {
        return en.week;
      }
      case TimeStatus.LONG_AGO: {
        return en.longAgo;
      }
    }
  }
  static GetReadablePaymentMethodKind(type: PaymentMethodKind): string {
    switch (type) {
      case PaymentMethodKind.CardAmex: {
        return 'Amex';
      }
      case PaymentMethodKind.CardDiners: {
        return 'Diners';
      }
      case PaymentMethodKind.CardDiscover: {
        return 'Discover';
      }
      case PaymentMethodKind.CardJCB: {
        return 'JCB';
      }
      case PaymentMethodKind.CardMastercard: {
        return 'MAST';
      }
      case PaymentMethodKind.CardUnionpay: {
        return 'Unionpay';
      }
      case PaymentMethodKind.CardVisa: {
        return 'VISA';
      }
    }

    return 'Unknown';
  }
  static ReadableInvoiceStatus(inv: Invoice): string {
    switch (inv.status) {
      case InvoiceStatus.Draft: {
        return en.invoiceDraft;
      }
      case InvoiceStatus.Open: {
        return en.invoiceOpen;
      }
      case InvoiceStatus.Paid: {
        return en.invoiceFinished;
      }
      case InvoiceStatus.Uncollectible: {
        return en.invoiceCancelled;
      }
      case InvoiceStatus.Void: {
        return en.invoiceVoided;
      }
    }
  }
  static cardIconName(pm: PaymentMethod): string {
    switch (pm.methodType) {
      case PaymentMethodKind.CardAmex: {
        return 'fa-cc-amex';
      }
      case PaymentMethodKind.CardDiners: {
        return 'fa-cc-diners-club';
      }
      case PaymentMethodKind.CardDiscover: {
        return 'fa-cc-discover';
      }
      case PaymentMethodKind.CardJCB: {
        return 'fa-cc-jcb';
      }
      case PaymentMethodKind.CardMastercard: {
        return 'fa-cc-mastercard';
      }
      case PaymentMethodKind.CardUnionpay: {
        return 'fa-cc-stripe'; // may be misleading
      }
      case PaymentMethodKind.CardVisa: {
        return 'fa-cc-visa';
      }
    }

    return '';
  }
  static GetReadablePrintJobStatus(printJob: UserJob): string {
    const getTextForPercent = function (): string {
      return printJob.Progress == null ? '' : ` (${printJob.Progress}%)`;
    };
    switch (printJob.Status) {
      case JobState.NOT_STARTED:
        return en.notStarted;
      case JobState.START: {
        return en.inProcess;
      }
      case JobState.PAUSE: {
        return `${en.paused}${getTextForPercent()}`;
      }
      case JobState.CANCEL: {
        return `${en.cancelled}${getTextForPercent()}`;
      }
      case JobState.RESUME: {
        return en.resuming;
      }
      case JobState.FINISH: {
        return `${en.finished}${getTextForPercent()}`;
      }
    }
  }

  static GetWidthClass(width?: number | 'grow-1' | 'grow-2' | 'grow-3' | 'grow-4'): string {
    if (width && typeof width === 'number') {
      return `w-${width}px`;
    } else if (width && typeof width !== 'number') {
      return `flex-${width} no-flex-basis`;
    }
    return 'flex-grow-1 no-flex-basis';
  }
  static Delay(ms: number): Promise<unknown> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  static GetH(s: number): number {
    return ComponentHelper.Round(s / 3600, 2);
  }
  static Round(value: number, precision: number): number {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }
}

export class TextHider {
  private text: string = '';
  private readonly delay: number;
  constructor(delay: number = 8000) {
    this.delay = delay;
  }
  public get Text(): string {
    return this.text;
  }
  public get HasError(): boolean {
    return this.Text.length > 0;
  }
  public Show(value: string) {
    this.text = value;
    UtilModule.SHW.AddTimeoutAction(
      window,
      () => {
        this.text = '';
      },
      this.delay,
    );
  }
  public Unshow() {
    this.text = '';
  }
}
export enum LocalState {
  CHANGING,
  SAVING,
}
export enum FinishResult {
  SUCCESS,
  ERROR,
  CANCEL,
}
export type Sizes = 'sm' | 'md' | 'lg' | 'xl';

export interface StatItemModel {
  value: number;
  dimension: string;
  status: string;
}

export interface ComponentAction {
  caption: string;
  onClick: () => void | Promise<void>;
}

export enum SortMode {
  ASC,
  DESC,
}
export interface HeaderItem {
  caption: string;
  itemClass: string;
  isSortable?: boolean;
  width?: number;
}
export class ItemData {
  name: string;
  content: string;
  width: number | undefined | 'grow-1' | 'grow-2' | 'grow-3' | 'grow-4';
  specialText: string | undefined;
  iconName: string | undefined;
  iconType: 'far' | 'fas' | 'fab' | undefined;
  iconClicked: (() => void) | undefined;
  iconShowAlways: boolean | undefined;
  iconToolTip: string | undefined;
  iconFontSize: number | undefined;
  iconColor: string | undefined;
  constructor(
    name: string,
    content: string,
    width: number | undefined | 'grow-1' | 'grow-2' | 'grow-3' | 'grow-4',
    specialText: string | undefined = undefined,
    iconName: string | undefined = undefined,
    iconType: 'far' | 'fas' | 'fab' | undefined = 'far',
    iconClicked: (() => void) | undefined = undefined,
    iconShowAlways: boolean | undefined = false,
    iconToolTip: string | undefined = undefined,
    iconFontSize: number = 13,
    iconColor: string | undefined = undefined,
  ) {
    this.name = name;
    this.content = content;
    this.width = width;
    this.specialText = specialText;
    this.iconName = iconName;
    this.iconType = iconType;
    this.iconClicked = iconClicked;
    this.iconShowAlways = iconShowAlways;
    this.iconToolTip = iconToolTip;
    this.iconFontSize = iconFontSize;
    this.iconColor = iconColor;
  }

  public GetWidthClass(): string {
    return ComponentHelper.GetWidthClass(this.width);
  }
  public GetMaxWidthStyle(): string {
    if (this.width == undefined) return '';
    if (typeof this.width === 'number') return `min-width: ${this.width}px`;
    return '';
  }
}

export interface DisplayKeyValue {
  display: string;
  value: any;
}
export type StateObj = {
  f: number;
};
