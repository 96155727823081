<template>
  <div class="add-new-machine" data-testid="addNewMachine">
    <EditorHeader>
      <template slot="header">
        <span>
          {{ AddNewMachineCaption }}
        </span>
      </template>
      <template slot="buttons">
        <HeaderIconButton test-id="addNewMachine.closeButton" iconName="fa-times" @clicked="CloseButtonClicked" />
      </template>
    </EditorHeader>

    <EditorContent>
      <text-input
        class="mb-2"
        :label="MachineNameCaption"
        :value.sync="machineName"
        :labelWidth="labelWidth"
        :invalid="machineNameValidationFailed"
        :disabled="isLoading"
        :readonly="readonly"
        placeholder="Enter machine name..."
        test-id="addNewMachine.machineNameInput"
      />

      <text-input
        class="mb-2"
        :label="MachineIdCaption"
        :value.sync="machineId"
        :labelWidth="labelWidth"
        :invalid="machineIdValidationFailed"
        :disabled="isLoading"
        :readonly="readonly"
        placeholder="Enter machine ID..."
        test-id="addNewMachine.machineIdInput"
      />

      <select-input
        :label="ModelCaption"
        :options.sync="PrinterModels"
        :disabled="isLoading"
        :labelWidth="labelWidth"
        :readonly="readonly"
        :selected-option.sync="selectedModel"
        :GetId="a => a.Id.toString()"
        :GetName="a => a.Name"
        test-id="addNewMachine.modelSelect"
      >
      </select-input>

      <fade-transition :maxHeight="28">
        <div v-if="validationErrors.length != 0" class="errors-container">
          <span class="mt-2">{{ validationErrors[0] }}</span>
        </div>
      </fade-transition>

      <div :class="['connection-code-panel-container', showConnectionCode ? 'shown' : '']">
        <div :class="['connection-code-panel', !showConnectionCode ? 'hidden' : '']">
          <div class="code">
            <span data-testid="addNewMachine.connectionCode" class="mr-3">{{ connectionCode }}</span>
            <span data-testid="addNewMachine.copyConnectionCodeButton" @click="copyConnectionCodeToClipboard">
              <i class="far fa-clone copy-icon"></i>
            </span>
          </div>
          <div class="separator my-2"></div>
          <div class="hint mt-3 mx-2">
            <span> Use this code to connect to this printer </span>
          </div>
        </div>
      </div>

      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </EditorContent>

    <EditorFooter>
      <template slot="buttons">
        <IconButton
          v-if="!readonly"
          :disabled="isLoading"
          iconName="fa-check"
          :text="AddCaption"
          test-id="addNewMachine.addButton"
          @clicked="AddButtonClicked"
        />
        <IconButton
          v-else
          test-id="addNewMachine.okButton"
          :disabled="isLoading"
          iconName="fa-check"
          :text="OkCaption"
          @clicked="OkButtonClicked"
        />
      </template>
    </EditorFooter>
  </div>
</template>

<script lang="ts">
import en from '@/localization/en';
import { toast } from '@/main';
import { PrinterModel, ConnectionTypeNames, Printer, PrinterState } from '@/models/Entities';
import { ConnectorModule } from '@/store/modules/connectorModule';
import { PrinterModule } from '@/store/modules/printerModule';
import { sleep } from '@/util/TypeHelper';
import { Component, Vue, Emit } from 'vue-property-decorator';
import { POSITION } from 'vue-toastification';
import FadeTransition from '../presentation/FadeTransition.vue';
import EditorContent from '@/components/forms/base/EditorContent.vue';
import EditorFooter from '@/components/forms/base/EditorFooter.vue';
import EditorHeader from '@/components/forms/base/EditorHeader.vue';
import IconButton from '../buttons/IconButton.vue';
import SelectInput from '@/components/inputs/SelectInput.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import HeaderIconButton from '@/components/buttons/HeaderIconButton.vue';

@Component({
  components: {
    HeaderIconButton,
    FadeTransition: FadeTransition,
    IconButton: IconButton,
    EditorHeader: EditorHeader,
    EditorContent: EditorContent,
    EditorFooter: EditorFooter,
    SelectInput: SelectInput,
    TextInput: TextInput,
  },
  name: 'add-new-machine',
})
export default class AddNewMachine extends Vue {
  labelWidth: number = 100;

  //#region STATE
  private isLoading: boolean = false;

  private readonly: boolean = false;

  private machineName: string = '';
  private machineId: string = '';
  private selectedModel: PrinterModel | null = null;

  private validationTimeoutHandle: number = -1;
  private machineNameValidationFailed: boolean = false;
  private machineIdValidationFailed: boolean = false;
  private validationErrors: string[] = [];

  private connectionCode: string = '';
  private showConnectionCode: boolean = false;

  private createdMachine: Printer | null = null;

  private get PrinterModels() {
    return PrinterModule.PrinterModels;
  }
  //#endregion

  //#region LOGIC
  private async LoadPrinterModels() {
    await PrinterModule.LoadAllPrinterModels();
  }

  private async LoadConnectionTypes() {
    await PrinterModule.ReadAllConnectionTypes();
  }

  private clearValidationErrors() {
    if (this.validationTimeoutHandle !== -1) {
      clearTimeout(this.validationTimeoutHandle);
    }

    this.validationErrors = [];
    this.machineNameValidationFailed = false;
    this.machineIdValidationFailed = false;
    this.validationTimeoutHandle = -1;
  }

  private validate() {
    this.clearValidationErrors();

    if (this.machineName == null || this.machineName.length == 0) {
      this.validationErrors.push('Enter machine name');
      this.machineNameValidationFailed = true;
    } else if (this.machineId == null || this.machineId.length == 0) {
      this.validationErrors.push('Enter machine ID');
      this.machineIdValidationFailed = true;
    }

    const failed = this.validationErrors.length != 0;

    if (failed) {
      this.validationTimeoutHandle = window.setTimeout(() => {
        this.clearValidationErrors();
      }, 3500);
    }

    return !failed;
  }

  async AddButtonClicked() {
    if (!this.validate()) {
      return;
    }

    this.isLoading = true;

    let sameClientIdExists = await ConnectorModule.ReadByClientId(this.machineId);

    if (sameClientIdExists) {
      toast.error(en.clientIdAlreadyExists.growFirst(), {
        position: POSITION.BOTTOM_RIGHT,
      });

      this.isLoading = false;
      return;
    }

    let printer = await PrinterModule.AddNewPrinter([this.selectedModel!.Id, this.machineName]);

    if (printer == null) {
      toast.error('Could not create printer :(', {
        position: POSITION.BOTTOM_RIGHT,
      });

      this.isLoading = false;
      return;
    }

    this.createdMachine = printer;

    await sleep(1000);

    const connectionTypeMqtt = PrinterModule.ConnectionTypes.firstOrDefault(a => a.Name == ConnectionTypeNames.MQTT);

    let resConnection = await PrinterModule.CreateConnection([connectionTypeMqtt!.Id, printer.Id, this.machineId]);

    if (resConnection != null) {
      this.connectionCode = resConnection;
      this.showConnectionCode = true;
      this.ConnectionCodeReceived(resConnection);
    } else {
      // Todo: possibly inform user somehow
    }

    this.readonly = true;

    this.isLoading = false;
  }

  CloseConnectionCodePanel() {
    this.showConnectionCode = false;
  }

  ClearState() {
    this.machineName = '';
    this.machineId = '';
    this.selectedModel = this.PrinterModels[0];
    this.readonly = false;
    this.createdMachine = null;
  }

  copyConnectionCodeToClipboard() {
    navigator.clipboard.writeText(this.connectionCode);

    toast.info('Code copied to clipboard', {
      position: POSITION.BOTTOM_RIGHT,
    });
  }

  async HandlePrinterStatusUpdated(printer: Printer) {
    if (this.createdMachine == null) {
      return;
    }

    if (this.createdMachine.Id.toString() != printer.Id.toString()) {
      return;
    }

    if (printer.PrinterState != PrinterState.DISCONNECTED) {
      this.CloseButtonClicked();
      toast.success(`Printer ${printer.Name} connected successfuly`, {
        position: POSITION.BOTTOM_RIGHT,
      });
    }
  }
  //#endregion

  //#region HOOKS
  async created() {
    await this.LoadPrinterModels();
    await this.LoadConnectionTypes();

    this.selectedModel = this.PrinterModels[0];
  }

  mounted() {
    PrinterModule.OnPrinterStatusUpdated.subscribe(this.HandlePrinterStatusUpdated);
  }

  beforeDestroy() {
    PrinterModule.OnPrinterStatusUpdated.unsubscribe(this.HandlePrinterStatusUpdated);
  }
  //#endregion

  //#region EVENTS
  @Emit('close-clicked')
  async CloseButtonClicked() {
    await sleep(300);
    this.CloseConnectionCodePanel();
    this.ClearState();
  }

  OkButtonClicked() {
    this.CloseButtonClicked();
  }

  @Emit('connection-code-received')
  ConnectionCodeReceived(code: string) {
    return code;
  }
  //#endregion

  //#region TRANSLATIONS
  private get CancelCaption() {
    return en.cancel.growFirst();
  }

  private get AddCaption() {
    return en.add.growFirst();
  }

  private get OkCaption() {
    return en.ok.growFirst();
  }

  private get AddNewMachineCaption() {
    return en.addNewMachine.growFirst();
  }

  private get MachineIdCaption() {
    return en.machineId.growFirst();
  }

  private get MachineNameCaption() {
    return en.nameCaption.growFirst();
  }

  private get ModelCaption() {
    return en.model.growFirst();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.add-new-machine {
  background: var(--editor-background);
  border-radius: 6px;
  position: relative;

  .errors-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      color: var(--editor-error-text);
      font-size: 14px;
    }
  }

  .connection-code-panel-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    pointer-events: none;

    .close-icon {
      color: #cecece;
      position: absolute;
      top: 0;
      right: 0;
      margin: 1rem;
      margin-top: 0.75rem;
    }

    transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);

    max-height: 0px;

    &.shown {
      max-height: 125px;
    }

    .connection-code-panel {
      margin: 1rem;
      margin-bottom: 0.75rem;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);

      pointer-events: all;

      &.hidden {
        pointer-events: none;
        transform: translateY(calc(100% + 2rem));
      }

      .code {
        color: #cecece;
        font-size: 36px;
        display: flex;
        align-items: center;
        justify-content: center;

        .copy-icon {
          font-size: 27px;
          color: #cecece;
        }
      }

      .separator {
        height: 1px;
        width: 100%;
        background-image: linear-gradient(to right, gray 15%, rgba(129, 129, 129, 0) 0%);
        background-position: top;
        background-size: 6px 1px;
        background-repeat: repeat-x;
      }

      .hint {
        font-size: 12px;
        color: #c7c7c7;
        text-align: left;
      }
    }

    i {
      cursor: pointer;

      &:hover {
        opacity: 0.75;
      }
    }
  }
}

.editor-content {
  background: var(--editor-background);
  overflow: hidden;
}

.editor-header {
  position: relative;
}

.editor-footer {
  position: relative;
}
</style>
