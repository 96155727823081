import {
  Account,
  AccountAdmin,
  Invoice,
  PaymentMethod,
  PendingSubscriptionUpdate,
  PreviewSubscriptionChanges,
  Product,
  ProductPrice,
  PurchasedItem,
  PurchasedSubscription,
  TaxRate,
} from '../Entities';

import {
  ResponseAccount,
  ResponseAccountAdmin,
  ResponseInvoice,
  ResponsePaymentMethod,
  ResponsePendingSubscriptionUpdate,
  ResponseProduct,
  ResponseProductPrice,
  ResponsePurchasedItem,
  ResponsePurchasedSubscription,
  ResponsePreviewSubscriptionChanges,
  ResponseTaxRate,
} from './ResponseBilling';

import { TypeHelper } from '@/util/TypeHelper';
import { Guid } from 'guid-typescript';

declare module './ResponseBilling' {
  interface ResponseAccount {
    Map(): Account;
  }
  interface ResponseAccountAdmin {
    Map(): AccountAdmin;
  }
  interface ResponseInvoice {
    Map(): Invoice;
  }
  interface ResponsePaymentMethod {
    Map(): PaymentMethod;
  }
  interface ResponsePendingSubscriptionUpdate {
    Map(): PendingSubscriptionUpdate;
  }
  interface ResponseProduct {
    Map(): Product;
  }
  interface ResponseProductPrice {
    Map(): ProductPrice;
  }
  interface ResponsePurchasedItem {
    Map(): PurchasedItem;
  }
  interface ResponsePurchasedSubscription {
    Map(): PurchasedSubscription;
  }
  interface ResponsePreviewSubscriptionChanges {
    Map(): PreviewSubscriptionChanges;
  }
  interface ResponseTaxRate {
    Map(): TaxRate;
  }
}

ResponseAccount.prototype.Map = function (): Account {
  const account = new Account();
  account.address = this.address;
  account.city = this.city;
  account.country = this.country;
  account.email = this.email;
  account.id = this.id;
  account.name = this.name;
  account.postalCode = this.postalCode;
  account.state = this.state;
  account.stripeBalance = this.stripeBalance;
  account.activated = this.activated;
  account.taxId = this.taxId;
  account.taxIdType = this.taxIdType;
  return account;
};

ResponseAccountAdmin.prototype.Map = function (): AccountAdmin {
  const accountAdmin = new AccountAdmin();
  accountAdmin.accountId = this.accountId;
  accountAdmin.id = this.id;
  accountAdmin.isOwner = this.isOwner;
  return accountAdmin;
};

ResponseInvoice.prototype.Map = function (): Invoice {
  const invoice = new Invoice();
  invoice.accountId = this.accountId;
  invoice.createdDate = TypeHelper.StringToDate(this.createdDate);
  invoice.currency = this.currency;
  invoice.id = this.id;
  invoice.invoiceNumber = this.invoiceNumber;
  invoice.invoicePdfUrl = this.invoicePdfUrl;
  invoice.status = this.status;
  invoice.subscriptionId = this.subscriptionId;
  invoice.totalAmount = this.totalAmount;
  invoice.stripePaymentIntentId = this.stripePaymentIntentId;
  invoice.paymentState = this.paymentState;
  return invoice;
};

ResponsePaymentMethod.prototype.Map = function (): PaymentMethod {
  const pm = new PaymentMethod();
  pm.accountId = this.accountId;
  pm.stripeId = this.stripeId;
  pm.expirationMonth = this.expirationMonth;
  pm.expirationYear = this.expirationYear;
  pm.id = this.id;
  pm.isDefault = this.isDefault;
  pm.last4Digits = this.last4Digits;
  pm.methodType = this.methodType;
  return pm;
};

ResponsePendingSubscriptionUpdate.prototype.Map = function (): PendingSubscriptionUpdate {
  const psu = new PendingSubscriptionUpdate();
  psu.id = this.id;
  psu.newProductPriceId = this.newProductPriceId;
  psu.newQuantity = this.newQuantity;
  psu.subscriptionId = this.subscriptionId;
  psu.stripeSubscriptionScheduleId = this.stripeSubscriptionScheduleId;
  psu.invoiceId = this.invoiceId == null ? null : Guid.parse(this.invoiceId);
  psu.applyDate = this.applyDate == null ? null : TypeHelper.StringToDate(this.applyDate);
  return psu;
};

ResponseProduct.prototype.Map = function (): Product {
  const product = new Product();
  product.baseProductId = this.baseProductId;
  product.description = this.description;
  product.id = this.id;
  product.isPublic = this.isPublic;
  product.licenseType = this.licenseType;
  product.name = this.name;
  product.outdated = this.outdated;
  product.shortName = this.shortName;
  return product;
};

ResponseProductPrice.prototype.Map = function (): ProductPrice {
  const productPrice = new ProductPrice();
  productPrice.currency = this.currency;
  productPrice.id = this.id;
  productPrice.isPublic = this.isPublic;
  productPrice.name = this.name;
  productPrice.outdated = this.outdated;
  productPrice.paymentType = this.paymentType;
  productPrice.price = this.price;
  productPrice.productId = this.productId;
  productPrice.recurringInterval = this.recurringInterval;
  productPrice.recurringIntervalCount = this.recurringIntervalCount;
  productPrice.metadata = this.metadata;
  productPrice.recurringLevel = this.recurringLevel;
  return productPrice;
};

ResponsePurchasedItem.prototype.Map = function (): PurchasedItem {
  const item = new PurchasedItem();
  item.accountId = this.accountId;
  item.activated = this.activated;
  item.baseItemId = this.baseItemId;
  item.cryptlexId = this.cryptlexId;
  item.cryptlexKey = this.cryptlexKey;
  item.deviceName = this.deviceName;
  item.id = this.id;
  item.invoiceId = this.invoiceId;
  item.productPriceId = this.productPriceId;
  item.purchaseDate = TypeHelper.StringToDate(this.purchaseDate);
  item.purchasedSubscriptionId = this.purchasedSubscriptionId;
  item.status = this.status;
  item.deleted = this.deleted;
  item.offlineActivationResponse = this.offlineActivationResponse;
  item.offlineActivationResponseValidBefore =
    this.offlineActivationResponseValidBefore == null
      ? null
      : TypeHelper.StringToDate(this.offlineActivationResponseValidBefore);
  return item;
};

ResponsePurchasedSubscription.prototype.Map = function (): PurchasedSubscription {
  const subscription = new PurchasedSubscription();
  subscription.accoundId = this.accountId;
  if (this.currentPeriodEnd !== null) {
    subscription.currentPeriodEnd = TypeHelper.StringToDate(this.currentPeriodEnd!);
  }
  if (this.currentPeriodStart !== null) {
    subscription.currentPeriodStart = TypeHelper.StringToDate(this.currentPeriodStart!);
  }
  subscription.id = this.id;
  subscription.status = this.status;
  subscription.subscriptionStart = TypeHelper.StringToDate(this.subscriptionStart);
  subscription.cancelAtPeriodEnd = this.cancelAtPeriodEnd;
  return subscription;
};

ResponsePreviewSubscriptionChanges.prototype.Map = function (): PreviewSubscriptionChanges {
  const changes = new PreviewSubscriptionChanges();

  changes.currentTotal = this.currentTotal;
  changes.chargeNow = this.chargeNow;
  changes.previewTotal = this.previewTotal;

  changes.currency = this.currency;
  changes.prorationDate = TypeHelper.StringToDate(this.prorationDate);
  if (this.nextAttempt !== null) {
    changes.nextAttempt = TypeHelper.StringToDate(this.nextAttempt);
  }

  changes.currentTotalTax = this.currentTotalTax;
  changes.chargeNowTax = this.chargeNowTax;
  changes.previewTotalTax = this.previewTotalTax;
  return changes;
};

ResponseTaxRate.prototype.Map = function (): TaxRate {
  const rate = new TaxRate();

  rate.country = this.country;
  rate.id = this.id;
  rate.inclusive = this.inclusive;
  rate.name = this.name;
  rate.percentage = this.percentage;
  rate.state = this.state;
  rate.type = this.type;

  return rate;
};
