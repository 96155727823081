<template>
  <AuraDialogWrapper
    ref="dialogWrapper"
    :headerText="Header"
    @closed="DialogWrapperClosed"
    @dismissed="DialogWrapperClosed"
  >
    <div class="content">
      <div v-if="!DownloadStep" class="main mt-2 mb-4">
        <div class="no-step">
          <span class="mb-1">{{ MainText }}</span>

          <div class="request-file">
            <file-input
              v-if="!Activated"
              class="w-100 mb-2"
              :disabled="isLoading"
              :readonly="false"
              :downloadable="false"
              extension=".arf"
              :file-name.sync="arfName"
              @file-changed="OnARFChanged"
              @reset-file="ResetARF"
            />

            <file-input
              v-else
              class="w-100 mb-2"
              :disabled="isLoading"
              :readonly="false"
              :downloadable="false"
              extension=".drf"
              :file-name.sync="drfName"
              @file-changed="OnDRFChanged"
              @reset-file="ResetDRF"
            />
          </div>
        </div>
      </div>

      <div v-if="!DownloadStep" class="buttons w-100 mb-2">
        <regular-button
          v-if="Activated"
          class="w-100"
          :disabled="!HasDRF || isLoading"
          :label="DeactivateCaption"
          @clicked="DeactivateOffline"
        />

        <regular-button
          v-else
          class="w-100"
          :disabled="!HasARF || isLoading"
          :label="ActivateCaption"
          @clicked="ActivateOffline"
        />
      </div>

      <div
        v-if="DownloadStep || OfflineResponseAvailable"
        :class="[
          'download-step',
          !DownloadStep ? 'mt-3' : '',
          OfflineResponseAvailable && !DownloadStep ? 'merged' : '',
        ]"
      >
        <span class="mb-1">{{ OfflineResponseText }}</span>
        <regular-button class="w-100 mt-1 mb-2" :label="'Download'" @click="DownloadOfflineResponseFile" />
      </div>

      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </div>
  </AuraDialogWrapper>
</template>

<script lang="ts">
import { Component, Prop, Ref } from 'vue-property-decorator';
import { DialogComponent } from 'vue-modal-dialogs';
import en from '../../localization/en';
import { PurchasedItemFull, PurchasedItemModule } from '@/store/modules/billing/purchasedItemModule';
import { toast } from '@/main';
import { POSITION } from 'vue-toastification';
import AuraDialogWrapper from '@/views/dialogs/base/AuraDialogWrapper.vue';
import FileInput from '@/components/inputs/FileInput.vue';
import RegularButton from '@/components/buttons/RegularButton.vue';

const ARF_FORM_KEY = 'ARF';
const DRF_FORM_KEY = 'DRF';
const PURCHASED_ITEM_FORM_KEY = 'itemId';

@Component({
  components: {
    AuraDialogWrapper: AuraDialogWrapper,
    FileInput: FileInput,
    RegularButton: RegularButton,
  },
})
export default class OfflineActivationDialog extends DialogComponent<boolean> {
  @Prop() private purchasedItem!: PurchasedItemFull;
  @Ref() dialogWrapper!: AuraDialogWrapper;

  //#region STATE
  private formData: FormData = new FormData();
  private downloadStep: boolean = false;

  private arfName: string = '';
  private drfName: string = '';

  private isLoading: boolean = false;

  private get DownloadStep() {
    return this.downloadStep;
  }

  private get HasARF() {
    return this.arfName != '';
  }

  private get HasDRF() {
    return this.drfName != '';
  }

  private get Activated() {
    return this.purchasedItem.item.activated;
  }

  private get Header() {
    return 'Offline activation';
  }

  private get MainText() {
    if (this.Activated) {
      return 'Select a .drf on your PC in order to perform offline deactivation';
    }

    return 'Select an .arf on your PC in order to perform offline activation';
  }

  private get OfflineResponseText() {
    return 'Offline activation response file is available for download';
  }

  private get OfflineResponseAvailable() {
    return (
      this.purchasedItem.item.offlineActivationResponse != null &&
      this.purchasedItem.item.offlineActivationResponseValidBefore != null &&
      this.purchasedItem.item.offlineActivationResponseValidBefore!.getDate() > new Date().getDate()
    );
  }
  //#endregion

  //#region LOGIC
  private async ResetARF() {
    let entry = this.formData.get(ARF_FORM_KEY);
    if (entry != null) {
      this.formData.delete(ARF_FORM_KEY);
      this.arfName = '';
    }
  }

  private async ResetDRF() {
    let entry = this.formData.get(DRF_FORM_KEY);
    if (entry != null) {
      this.formData.delete(DRF_FORM_KEY);
      this.drfName = '';
    }
  }

  private UploadARF() {
    (this.$refs.inputARF as HTMLElement).click();
  }

  private UploadDRF() {
    (this.$refs.inputDRF as HTMLElement).click();
  }

  private OnARFChanged(files: File[]) {
    const file = files[0];
    this.arfName = file.name;
    this.ChangeFormFile(file, ARF_FORM_KEY);
  }

  private OnDRFChanged(files: File[]) {
    const file = files[0];
    this.drfName = file.name;
    this.ChangeFormFile(file, DRF_FORM_KEY);
  }

  private ChangeFormFile(file: File, key: string) {
    let entry = this.formData.get(key);
    if (entry != null) this.formData.delete(key);
    this.formData.append(key, file, file.name);
  }

  private async ActivateOffline() {
    this.formData.append(PURCHASED_ITEM_FORM_KEY, this.purchasedItem.item.id.toString());

    this.isLoading = true;

    const { result, message } = await PurchasedItemModule.ActivateOffline(this.formData);

    this.isLoading = false;

    if (result) {
      toast.success(`Successfully activated key ${this.purchasedItem.item.cryptlexKey}`, {
        position: POSITION.BOTTOM_RIGHT,
      });

      this.downloadStep = true;
    } else {
      toast.error(`Could not activate key. ${message}`, {
        position: POSITION.BOTTOM_RIGHT,
      });
    }
  }

  private async DeactivateOffline() {
    this.formData.append(PURCHASED_ITEM_FORM_KEY, this.purchasedItem.item.id.toString());

    this.isLoading = true;

    const { result, message } = await PurchasedItemModule.DeactivateOffline(this.formData);

    this.isLoading = false;

    if (result) {
      toast.success(`Successfully deactivated key ${this.purchasedItem.item.cryptlexKey}`, {
        position: POSITION.BOTTOM_RIGHT,
      });

      this.dialogWrapper.CloseDialog();
      this.$close(true);
    } else {
      toast.error(`Could not deactivated key. ${message}`, {
        position: POSITION.BOTTOM_RIGHT,
      });
    }
  }

  private async DownloadOfflineResponseFile() {
    await PurchasedItemModule.DownloadOfflineActivationResponseFile(this.purchasedItem.item);
  }

  private PurchasedItemUpdated() {}

  private DialogWrapperClosed() {
    this.$close(false);
  }
  //#endregion

  //#region HOOKS
  private async created() {
    PurchasedItemModule.OnPurchasedItemUpdated.subscribe(this.PurchasedItemUpdated);
  }

  private async beforeDestroy() {
    PurchasedItemModule.OnPurchasedItemUpdated.unsubscribe(this.PurchasedItemUpdated);
  }
  //#endregion

  //#region TRANSLATIONS
  private get DeactivateCaption() {
    return en.deactivate.growFirst();
  }

  private get ActivateCaption() {
    return en.activate.growFirst();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.dialog {
  background: var(--dialog-background);
  border-radius: 3px;
  overflow: auto;
  z-index: 2;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.35);
  animation: scaleIn 150ms ease-in;
  animation-fill-mode: forwards;

  &.closing {
    animation: scaleOut 150ms ease-in;
  }
}

.dialog-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--main-text);

  i {
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  }
}

.dialog-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1061;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.35);

  color: var(--editor-field-value);

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    min-width: 450px;
    max-width: 450px;

    .main {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      span {
        font-size: 14px;
      }
    }

    .download-step,
    .no-step {
      font-size: 14px;
      display: flex;
      flex-direction: column;
    }

    .download-step {
      &.merged {
        border-top: 1px #ffffff45 solid;
        padding-top: 8px;
      }
    }
    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    button {
      height: 28px;
    }
  }

  animation: fadeIn 150ms ease-in;
  animation-fill-mode: forwards;

  &.closing {
    animation: fadeOut 150ms ease-in;
  }

  input {
    background: none;
    border-radius: 6px;
    border: 1px solid var(--editor-field-border);
    height: 28px;
    color: var(--editor-field-value);
    font-size: 14px;
    padding: 0 10px;
  }

  .input-group {
    background: none;
    border-radius: 6px;
    border: 1px solid var(--editor-field-border);
    height: 28px;
    color: var(--editor-field-value);
    font-size: 14px;
    padding: 0 10px;
    align-items: center;

    input {
      border: none;
      padding: 0;
      display: flex;
      flex: 1;
      transform: translateY(-1px);
    }
  }

  .request-file {
    display: flex;
    align-items: center;
  }

  .offline-response {
    display: flex;
    align-items: center;
  }

  i {
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  }
}

.dialog-dismiss-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
</style>
