<template>
  <div class="job-editor">
    <EditorHeader>
      <template slot="header">
        <span>
          {{ Source }}
        </span>

        <Badge class="ml-3 status" :text="Status" />
      </template>

      <template slot="buttons">
        <HeaderIconButton
          v-if="!IsStarted && CanStart"
          test-id="jobEditor.startButton"
          iconName="fa-play"
          @clicked="StartButtonClicked"
        />
        <HeaderIconButton
          v-if="IsPaused && CanResume"
          test-id="jobEditor.resumeButton"
          iconName="fa-play"
          @clicked="ResumeButtonClicked"
        />
        <HeaderIconButton
          v-if="(IsStarted || IsResumed) && CanPause"
          test-id="jobEditor.pauseButton"
          iconName="fa-pause"
          @clicked="PauseButtonsClicked"
        />
        <HeaderIconButton
          v-if="(IsStarted || IsPaused || IsResumed) && CanStop"
          test-id="jobEditor.stopButton"
          iconName="fa-stop"
          @clicked="StopButtonClicked"
        />
        <HeaderIconButton
          v-if="!(IsStarted || IsPaused || IsResumed)"
          test-id="jobEditor.deleteButton"
          iconName="fa-trash"
          @clicked="DeleteButtonClicked"
        />
        <header-icon-button iconName="fa-times" @clicked="CloseClicked" />
      </template>
    </EditorHeader>

    <EditorContent>
      <div class="job-editor-content-container">
        <div class="fields">
          <FileInput
            class="mb-2"
            :downloadable="true"
            :fileName="Source"
            :label="SourceCaption"
            :readonly="true"
            :labelWidth="labelWidth"
            extension=".gcode"
            test-id="jobEditor.sourceField"
            @download-file="SourceAvailable ? DownloadGCode() : undefined"
            @clicked="SourceAvailable ? SourceClicked() : undefined"
          />

          <TextInput
            class="mb-2"
            :label="PinterCaption"
            :labelWidth="labelWidth"
            :readonly="true"
            :value="Printer"
            :fillWidth="false"
            test-id="jobEditor.printerField"
            @clicked="PrinterClicked"
          />

          <TextInput
            v-if="OwnerAvailable"
            class="mb-2"
            :label="OwnerCaption"
            :labelWidth="labelWidth"
            :readonly="true"
            :value="Owner"
            :fillWidth="false"
            test-id="jobEditor.ownerField"
            @clicked="OwnerClicked"
          />

          <TextInput
            class="mb-2"
            :label="CreatedAtCaption"
            :labelWidth="labelWidth"
            :readonly="true"
            :value="CreatedAt"
            test-id="jobEditor.createdAtField"
            :fillWidth="false"
          />

          <!-- <text-input
            class="mb-2"
            :label="OrderCaption"
            :labelWidth="labelWidth"
            :readonly="true"
            :value="Order"
          /> -->

          <TextInput
            v-if="IsStarted"
            class="mb-2"
            :label="StartedCaption"
            :labelWidth="labelWidth"
            :readonly="true"
            :value="Started"
            test-id="jobEditor.startedAtField"
          />

          <TextInput
            v-if="IsStarted"
            :label="EstimatedCaption"
            :labelWidth="labelWidth"
            :readonly="true"
            :value="Estimated"
            test-id="jobEditor.estimatedField"
          />

          <div v-if="!IsStarted" class="job-order-control mb-1">
            <div
              class="label mr-2"
              :style="{
                'min-width': labelWidth + 'px',
                'margin-top': OderLabelOffset,
              }"
            >
              <span>Order</span>
            </div>
            <div class="job-short-list">
              <div
                v-for="job in OrderJobs"
                :key="job.job.Id.toString()"
                :class="['job-short', IsSelected(job) ? 'selected' : '']"
              >
                <span class="order">{{ job.job.Order }}</span>
                <span class="mx-1">-</span>
                <span class="source">{{ job.source.CodeFileName }}</span>
                <div v-if="IsSelected(job)" class="controls ml-2">
                  <div
                    :class="['control mr-1', OrderFirst || isLoadingAdjacentJobs || isLoading ? 'disabled' : '']"
                    @click="ChangeOrderLower"
                  >
                    <i class="far fa-arrow-up"></i>
                  </div>

                  <div
                    :class="['control', OrderLast || isLoadingAdjacentJobs || isLoading ? 'disabled' : '']"
                    @click="ChangeOrderHigher"
                  >
                    <i class="far fa-arrow-down"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <action-card
            v-if="MaterialConsumptions.length > 0"
            :headerText="MaterialConsumptionsCaption"
            class="material-consumptions mt-3 mb-2"
          >
            <sortable-header :header-items="MaterialConsumptionsHeader" />
            <div class="thin-scroll overflow-auto">
              <list-item-v-3
                v-for="item in MaterialConsumptions"
                :id="item.Id"
                :key="item.Id.toString()"
                :items="MaterialConsumptionsItems(item)"
                :headerIconType="MaterialConsumptionIconType(item)"
                headerIconName="fa-circle"
                :headerIconFontSize="14"
              />
            </div>
          </action-card>
        </div>

        <div class="preview">
          <!-- <img src="some" alt="Preview" /> -->
        </div>
      </div>

      <div :class="['spinner', isLoading || isLoadingAdjacentJobs ? '' : 'hidden']"></div>
    </EditorContent>
  </div>
</template>

<script lang="ts">
import en from '@/localization/en';
import { FullJob } from '@/models/CompositeEntities';
import { JobState, MaterialConsumption, MaterialType, PrinterState } from '@/models/Entities';
import { JobModule } from '@/store/modules/jobModule';
import { SourceModule } from '@/store/modules/sourceModule';
import ComponentHelper, { HeaderItem, ItemData } from '@/util/ComponentHelper';
import moment from 'moment';
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import EditorContent from '@/components/forms/base/EditorContent.vue';
import EditorHeader from '@/components/forms/base/EditorHeader.vue';
import FileInput from '@/components/inputs/FileInput.vue';
import TextInput from '@/components/inputs/TextInput.vue';

import AuraMessageBoxDialog, { Result } from '@/views/dialogs/AuraMessageBoxDialog.vue';
import { create } from 'vue-modal-dialogs';
import Badge from '@/components/presentation/Badge.vue';
import ListItemV3 from '@/components/presentation/ListItemV3.vue';
import ActionCard from '../presentation/ActionCard.vue';
import SortableHeader from '@/components/presentation/SortableHeader.vue';
import { UserModule } from '@/store/modules/userModule';
import HeaderIconButton from '@/components/buttons/HeaderIconButton.vue';

const DeleteJobSure = create<Result, String, String, Result>(AuraMessageBoxDialog, 'results', 'header', 'mainText');

const StopJobSure = create<Result, String, String, Result>(AuraMessageBoxDialog, 'results', 'header', 'mainText');

@Component({
  components: {
    HeaderIconButton,
    EditorHeader: EditorHeader,
    EditorContent: EditorContent,
    TextInput: TextInput,
    FileInput: FileInput,
    Badge: Badge,
    ListItemV3: ListItemV3,
    ActionCard: ActionCard,
    SortableHeader: SortableHeader,
  },
})
export default class JobEditor extends Vue {
  labelWidth: number = 85;

  @Prop() fullJob!: FullJob;

  @Watch('fullJob')
  async OnFullJobChanged(newValue: FullJob, oldValue: FullJob) {
    if (!oldValue || !newValue) {
      return;
    }

    if (newValue.job.Id.toString() != oldValue.job.Id.toString()) {
      await this.FetchAdjacentJobs();
    }
  }

  @Watch('Order', { immediate: true })
  async OnOrderChanged() {
    await this.FetchAdjacentJobs();
  }

  //#region STATE
  isLoading: boolean = false;
  isLoadingAdjacentJobs: boolean = false;

  private prevOrderOffset: number = 0;
  private adjacentJobs: FullJob[] = [];

  private get OrderJobs(): FullJob[] {
    let result: FullJob[] = [];

    for (const job of this.adjacentJobs) {
      result.push(job);
    }

    result.push(this.fullJob);

    result.sort((a, b) => {
      var orderA = a.job.Order == null ? -1 : a.job.Order;
      var orderB = b.job.Order == null ? -1 : b.job.Order;

      return orderA > orderB ? 1 : -1;
    });

    result = result.filter(a => a.job.Order != null);

    return result;
  }

  private get SourceAvailable() {
    return this.fullJob.source != null;
  }

  private get OwnerAvailable() {
    return this.fullJob.owner != null;
  }

  private get IsStarted() {
    return this.fullJob.job.ActualStartTime != null;
  }

  private get IsResumed() {
    return this.fullJob.job.JobState == JobState.RESUME;
  }

  private get IsPaused() {
    return this.fullJob.job.JobState == JobState.PAUSE;
  }

  private get CanStart() {
    return this.fullJob.printer.PrinterState == PrinterState.IDLE;
  }

  private get CanResume() {
    return this.fullJob.printer.PrinterState == PrinterState.PAUSED;
  }

  private get CanPause() {
    return this.fullJob.printer.PrinterState == PrinterState.PRINTING;
  }

  private get CanStop() {
    return (
      this.fullJob.printer.PrinterState == PrinterState.PRINTING ||
      this.fullJob.printer.PrinterState == PrinterState.PAUSED
    );
  }

  private get IsInProgress() {
    return this.fullJob.job.Order == null;
  }

  private get Source() {
    if (this.fullJob.job.LocalFilename != null) {
      return this.fullJob.job.LocalFilename;
    }

    if (!this.SourceAvailable) {
      return '—';
    }

    return this.fullJob.source!.CodeFileName;
  }

  private get Printer() {
    return this.fullJob.printer.Name;
  }

  private get Owner() {
    if (!this.OwnerAvailable) {
      return '—';
    }

    return ComponentHelper.GetFullname(this.fullJob.owner!);
  }

  private get CreatedAt() {
    return ComponentHelper.GetReadableDateTime(this.fullJob.job.CreationDateTime);
  }

  private get Order() {
    if (this.fullJob.job.Order == null) {
      return 'Current';
    }

    return this.fullJob.job.Order.toString();
  }

  private get IsTransferingFile() {
    return (
      this.fullJob.printer.PrinterState == PrinterState.BUSY &&
      this.fullJob.job.StartFileTransferProgress > 0 &&
      this.fullJob.job.StartFileTransferProgress < 1
    );
  }

  private get Status() {
    if (this.IsTransferingFile) {
      return `Uploading file (${(this.fullJob.job.StartFileTransferProgress * 100).toFixed(2)}%)`;
    }

    switch (this.fullJob.job.JobState) {
      case JobState.NOT_STARTED:
        return this.NotStartedCaption;
      case JobState.START:
      case JobState.RESUME:
        return `${this.PrintingCaption.growFirst()} (${this.fullJob.job.Progress?.toFixed(2)}%)`;
      case JobState.CANCEL:
        return `${this.CancelledCaption.growFirst()} (${this.fullJob.job.Progress?.toFixed(2)}%)`;
      case JobState.PAUSE:
        return `${this.PausedCaption.growFirst()} (${this.fullJob.job.Progress?.toFixed(2)}%)`;
      case JobState.FINISH:
        return `${this.FinishedCaption.growFirst()} (100%)`;
      default:
        return this.NotStartedCaption;
    }
  }

  private get Started() {
    if (!this.IsStarted) {
      return '—';
    }

    return ComponentHelper.GetReadableDateTime(this.fullJob.job.ActualStartTime!, true, true);
  }

  private get Estimated() {
    if (!this.IsStarted || !this.SourceAvailable || this.fullJob.source?.PrintDuration == null) {
      return '—';
    }

    const startTime = this.fullJob.job.ActualStartTime;
    let endDate = moment(startTime).add(this.fullJob.source.PrintDuration).toDate();

    return ComponentHelper.GetReadableDateTime(endDate, true, true);
  }

  private get OrderFirst() {
    return this.fullJob.job.Order == 0;
  }

  private get OrderLast() {
    const orderJobs = this.OrderJobs;
    return this.OrderJobs.indexOf(this.fullJob) == orderJobs.length - 1;
  }

  private get OderLabelOffset() {
    const index = this.OrderJobs.indexOf(this.fullJob);
    return 4 + index * 21 + 'px';
  }

  private IsSelected(fullJob: FullJob) {
    return this.fullJob.job.Id.toString() == fullJob.job.Id.toString();
  }

  //#region MATERIAL CONSUMPTIONS
  private get MaterialConsumptions(): MaterialConsumption[] {
    if (this.fullJob.source == null) return [];

    return this.fullJob.source.MaterialConsumptions;
  }

  //#region HEADERS & ITEMS
  private get MaterialConsumptionsHeader(): HeaderItem[] {
    let result: HeaderItem[] = [];

    result.push({
      caption: 'Name',
      itemClass: ComponentHelper.GetWidthClass(),
    });

    result.push({
      caption: 'Count',
      itemClass: ComponentHelper.GetWidthClass(125),
    });

    return result;
  }

  private MaterialConsumptionsItems(value: MaterialConsumption): ItemData[] {
    let result: ItemData[] = [];

    let itemName: ItemData = new ItemData('Name', value.MaterialName, 'grow-1');
    let itemCount: ItemData = new ItemData('Count', value.Count.toFixed(1) + ' ' + this.GetDimention(value), 125);

    result.push(itemName);
    result.push(itemCount);

    return result;
  }

  private MaterialConsumptionIconType(value: MaterialConsumption): string {
    if (this.IsPlastic(value)) {
      // empty circle
      return 'far';
    }

    // filled circle
    return 'fas';
  }

  private GetDimention(item: MaterialConsumption): string {
    if (this.IsPlastic(item)) return this.GCaption;
    return this.MCaption;
  }

  private IsPlastic(item: MaterialConsumption): boolean {
    return item.MaterialType === MaterialType.PLASTIC;
  }
  //#endregion
  //#endregion

  //#region LOGIC
  private async DownloadGCode() {
    if (!this.SourceAvailable) return;

    SourceModule.downloadGCodeFile(this.fullJob.source!);
  }

  private async StartButtonClicked() {
    this.isLoading = true;

    await JobModule.ForceStart(this.fullJob.job.Id);

    this.isLoading = false;
  }

  private async ResumeButtonClicked() {
    this.isLoading = true;

    await JobModule.UpdateJobStatus([this.fullJob.job, JobState.RESUME]);

    this.isLoading = false;
  }

  private async PauseButtonsClicked() {
    this.isLoading = true;

    await JobModule.UpdateJobStatus([this.fullJob.job, JobState.PAUSE]);

    this.isLoading = false;
  }

  private async StopButtonClicked() {
    const userConfirmation = await StopJobSure(
      Result.Yes | Result.No,
      'Cancel job',
      'Are you sure you want to cancel this job?',
    );

    if (userConfirmation == Result.No || userConfirmation == Result.Cancel) {
      return;
    }

    this.isLoading = true;

    await JobModule.UpdateJobStatus([this.fullJob.job, JobState.CANCEL]);

    this.isLoading = false;
  }

  private async ChangeOrderHigher() {
    if (this.fullJob.job.Order == null) {
      return;
    }

    this.isLoading = true;

    await JobModule.UpdateJobOrder([this.fullJob.job, this.fullJob.job.Order + 1]);

    this.isLoading = false;
  }

  private async ChangeOrderLower() {
    if (this.fullJob.job.Order == null) {
      return;
    }

    this.isLoading = true;

    await JobModule.UpdateJobOrder([this.fullJob.job, this.fullJob.job.Order - 1]);

    this.isLoading = false;
  }

  private async DeleteButtonClicked() {
    const userConfirmation = await DeleteJobSure(
      Result.Yes | Result.No,
      'Delete job',
      'Are you sure you want to delete this job?',
    );

    if (userConfirmation == Result.No || userConfirmation == Result.Cancel) {
      return;
    }

    this.isLoading = true;

    await JobModule.DeleteJob(this.fullJob.job);

    this.isLoading = false;
  }

  private async FetchAdjacentJobs() {
    this.adjacentJobs = [];

    if (this.IsInProgress) return;

    this.isLoadingAdjacentJobs = true;

    const result = await JobModule.FetchAdjacent([this.fullJob.job.Id, 1]);

    this.isLoadingAdjacentJobs = false;

    if (result == null) return;

    this.adjacentJobs = [];

    for (const job of result) {
      const printer = this.fullJob.printer;
      const owner = UserModule.Users.firstOrDefault(a => a.Id.toString() == job.UserId?.toString());
      const source = SourceModule.Sources.firstOrDefault(a => a.Id.toString() == job.SourceId?.toString());

      this.adjacentJobs.push({
        job: job,
        printer: printer,
        source: source,
        owner: owner,
      });
    }
  }
  //#endregion

  //#region EVENTS
  @Emit('close-clicked')
  private async CloseClicked() {}

  @Emit('printer-clicked')
  private async PrinterClicked() {
    return this.fullJob.printer;
  }

  @Emit('owner-clicked')
  private async OwnerClicked() {
    return this.fullJob.owner;
  }

  @Emit('source-clicked')
  private async SourceClicked() {
    return this.fullJob.source;
  }
  //#endregion

  //#region HOOKS
  //#endregion

  //#region TRANSLATIONS
  private get SourceCaption() {
    return en.source.growFirst();
  }
  private get PinterCaption() {
    return en.printer.growFirst();
  }
  private get OwnerCaption() {
    return en.owner.growFirst();
  }
  private get CreatedAtCaption() {
    return en.createdAt.growFirst();
  }
  private get OrderCaption() {
    return en.order.growFirst();
  }
  private get StartedCaption() {
    return en.started.growFirst();
  }
  private get EstimatedCaption() {
    return en.estimated.growFirst();
  }
  private get StatusCaption(): string {
    return en.status;
  }
  private get NotStartedCaption(): string {
    return en.notStarted.growFirst();
  }
  private get PrintingCaption(): string {
    return en.printing.growFirst();
  }
  private get CancelledCaption(): string {
    return en.cancelled.growFirst();
  }
  private get PausedCaption(): string {
    return en.paused.growFirst();
  }
  private get FinishedCaption(): string {
    return en.finished.growFirst();
  }
  private get UnknownCaption(): string {
    return en.unknown.growFirst();
  }
  private get SuccessCaption(): string {
    return en.success.growFirst();
  }
  private get MCaption(): string {
    return en.meter;
  }
  private get GCaption(): string {
    return en.gr;
  }

  private get MaterialConsumptionsCaption(): string {
    return en.materialConsumptions.growFirst();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.job-editor {
  background: var(--editor-background);
  border-radius: 6px;

  .material-consumptions {
    background: none;
  }

  .job-editor-content-container {
    display: flex;

    .fields {
      flex: 1;
      display: flex;
      flex-direction: column;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }

    .preview {
      display: none;
      flex: 1;
      // min-height: 250px;
      min-width: 250px;
      color: #e1e1e1;
      font-size: 14px;

      justify-content: space-evenly;
      align-items: center;
    }
  }

  .job-order-control {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;

    .label {
      line-height: 16px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      white-space: nowrap;
      color: var(--editor-field-name);
      align-self: flex-start;
      height: 21px;
      display: flex;
      align-items: center;
      margin: auto 0;
      font-size: 14px;
      transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);
    }

    .job-short-list {
      color: #928d8d;
      margin-left: 11px;

      .job-short {
        display: flex;

        &.selected {
          color: var(--editor-field-value);
          margin: 4px 0;
          font-weight: 700;
        }

        .source {
          text-overflow: ellipsis;
          display: block;
          white-space: nowrap;
          overflow: hidden;
        }

        .controls {
          display: flex;
          align-items: center;

          .control {
            height: 20px;
            width: 20px;
            padding-top: 2px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;

            // border: solid 1px #aeaeae;

            cursor: pointer;
            &:hover {
              opacity: 0.75;
            }

            div {
              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
</style>
