<template>
  <unauthorized-page>
    <template slot="content">
      <div class="error">
        <span class="reason">Not found</span>
        <span class="number">404</span>
      </div>

      <div class="description">
        <div class="header mb-3">Page is missing</div>
        <div class="summary">
          <span>The page or resource you requested could not be found</span>
          <br />
          <span>Please go back to homepage</span>
        </div>
      </div>

      <div class="nav">
        <transparent-button class="mt-4" text="Home" @click="GoHome" />
      </div>
    </template>
  </unauthorized-page>
</template>

<script lang="ts">
import TransparentButton from '@/components/buttons/TransparentButton.vue';
import UnauthorizedPage from '@/components/presentation/UnauthorizedPage.vue';
import { Routes } from '@/router/routes';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'not-found',
  components: {
    TransparentButton: TransparentButton,
    UnauthorizedPage: UnauthorizedPage,
  },
})
export default class NotFound extends Vue {
  //#region LOGIC
  async GoHome() {
    this.$router.replace({ name: Routes.MY_ACCOUNT });
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.error {
  flex: 2;
  display: flex;
  flex-direction: column;
  line-height: 1;
  justify-content: flex-end;
  align-items: flex-start;
  color: rgb(213, 213, 213);
  .reason {
    font-size: 64px;
  }
  .number {
    font-size: 166px;
    font-weight: 300;
  }
}

.description {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: start;
  align-items: flex-start;
  justify-content: center;

  .header {
    font-size: 24px;
    color: #d6d6d6;
  }

  .summary {
    font-size: 14px;
    color: #b6b6b6;
  }
}

.nav {
  flex: 1;
  display: flex;
  align-content: flex-start;
  justify-content: center;
  padding: 0 4rem;
}
</style>
