<template>
  <AuraDialogWrapper
    ref="dialogWrapper"
    :headerText="header"
    @closed="DialogWrapperClosed"
    @dismissed="DialogWrapperClosed"
  >
    <div class="content">
      <div class="message mb-4">
        {{ mainText }}
      </div>

      <div class="buttons w-100 mb-2">
        <button v-if="ShowOk" class="ml-3" @click="onClickOk">
          {{ OkCaption }}
        </button>
        <button v-if="ShowYes" class="ml-3" @click="onClickYes">
          {{ YesCaption }}
        </button>
        <button v-if="ShowNo" class="ml-3" @click="onClickNo">
          {{ NoCaption }}
        </button>
        <button v-if="ShowCancel" @click="onClickCancel">
          {{ CancelCaption }}
        </button>
      </div>
    </div>
  </AuraDialogWrapper>
</template>

<script lang="ts">
//#region IMPORT

import { Component, Prop, Ref } from 'vue-property-decorator';
import { DialogComponent } from 'vue-modal-dialogs';
import en from '../../localization/en';
import AuraDialogWrapper from '@/views/dialogs/base/AuraDialogWrapper.vue';

//#endregion

export enum Result {
  OK = 1 << 0,
  Cancel = 1 << 1,
  Yes = 1 << 2,
  No = 1 << 3,
}

@Component({
  components: {
    AuraDialogWrapper: AuraDialogWrapper,
  },
})
export default class AuraMessageBoxDialog extends DialogComponent<Result> {
  @Prop() private results!: Result;
  @Prop() private header!: string;
  @Prop() private mainText!: string;
  @Ref() dialogWrapper!: AuraDialogWrapper;

  private get ShowOk(): boolean {
    return this.Check(Result.OK);
  }
  private get ShowCancel(): boolean {
    return this.Check(Result.Cancel);
  }
  private get ShowYes(): boolean {
    return this.Check(Result.Yes);
  }
  private get ShowNo(): boolean {
    return this.Check(Result.No);
  }

  private onClickOk() {
    this.dialogWrapper.CloseDialog();
    this.$close(Result.OK);
  }
  private onClickCancel() {
    this.dialogWrapper.CloseDialog();
    this.$close(Result.Cancel);
  }
  private onClickYes() {
    this.dialogWrapper.CloseDialog();
    this.$close(Result.Yes);
  }
  private onClickNo() {
    this.dialogWrapper.CloseDialog();
    this.$close(Result.No);
  }

  private Check(value: Result): boolean {
    return value === (this.results & value);
  }

  private DialogWrapperClosed() {
    this.dialogWrapper.CloseDialog();
    this.$close(Result.Cancel);
  }

  private get OkCaption(): string {
    return 'OK';
  }
  private get CancelCaption(): string {
    return en.cancel;
  }
  private get YesCaption(): string {
    return en.yes;
  }
  private get NoCaption(): string {
    return en.no;
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  text-align: left;
  min-width: 450px;
  max-width: 450px;
  min-height: 150px;

  .message {
    font-size: 13px;
    flex-grow: 1;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      border: 1px solid var(--editor-field-value);
      border-radius: 6px;
      font-size: 13px;
      color: var(--editor-field-value);
      background: transparent;
      height: 26px;
      padding: 0 8px;
      text-transform: capitalize;
      width: 100px;
    }
  }
}
</style>
