<template>
  <div class="printer-summary-container">
    <div :class="['printer-summary', isLoadingCurrentJob || isLoadingCurrentArchive ? 'disabled' : '']">
      <div class="header mb-2">
        <div
          v-if="IsSummaryTypeErrors"
          class="clickable text-elipsis"
          data-testid="printerSummary.name"
          @click="OnPrinterNameClicked"
        >
          {{ printer.printer.Name }}
        </div>
        <div
          v-else-if="IsSummaryTypeFinished"
          class="clickable text-elipsis"
          data-testid="printerSummary.lastArchiveC"
          @click="OnPrinterLastArchiveClicked"
        >
          <span> {{ LastArchiveGCodeName }} </span>
        </div>
        <div
          v-if="IsSummaryTypeInProgress"
          class="clickable text-elipsis"
          data-testid="printerSummary.currentJob"
          @click="OnPrinterCurrentJobClicked"
        >
          <span> {{ CurrentJobGCodeName }} </span>
        </div>
        <div v-if="IsSummaryTypeInProgress && lastTelemetry != null" class="ml-2 last-telemetry">
          <span v-tooltip="LastToolName" :style="{ 'user-select': 'none' }">{{ LastToolShortName }}</span>
          <span class="mx-2" style="color: #d8d8d8"> - </span>
          <span>{{ LastToolTemperature }}</span>
        </div>
      </div>
      <div class="info">
        <text-input
          v-if="IsSummaryTypeErrors"
          class="info-item"
          label="Last"
          :value="LastPrinterError"
          :readonly="true"
          :fontSize="12"
          :height="24"
          :labelWidth="75"
          labelColor="#949494"
          valueColor="#CECECE"
        />

        <text-input
          v-if="IsSummaryTypeErrors"
          class="info-item"
          label="Total"
          :value="PrinterErrorCount"
          :readonly="true"
          :fontSize="12"
          :height="24"
          :labelWidth="75"
          labelColor="#949494"
          :valueColor="PrinterErrorCount == 0 ? '#CECECE' : '#F63E00'"
        />

        <text-input
          v-if="IsSummaryTypeFinished || IsSummaryTypeInProgress"
          class="info-item"
          label="Printer"
          :value="PrinterName"
          :readonly="true"
          :fontSize="12"
          :height="24"
          :labelWidth="75"
          labelColor="#949494"
          @clicked="FinishedSummaryPrinterNameClicked"
        />

        <text-input
          v-if="IsSummaryTypeFinished"
          class="info-item"
          label="Time"
          :value="FinishedAt"
          :readonly="true"
          :fontSize="12"
          :height="24"
          :labelWidth="75"
          labelColor="#949494"
          valueColor="#CECECE"
        />

        <text-input
          v-if="IsSummaryTypeFinished"
          class="info-item"
          label="Errors"
          :value="ArchiveErrorCount"
          :readonly="true"
          :fontSize="12"
          :height="24"
          :labelWidth="75"
          labelColor="#949494"
          :valueColor="ArchiveErrorCount == 0 ? '#CECECE' : '#F63E00'"
        />

        <text-input
          v-if="IsSummaryTypeInProgress"
          class="info-item"
          label="Progress"
          :value="Progress"
          :readonly="true"
          :fontSize="12"
          :height="24"
          :labelWidth="75"
          labelColor="#949494"
          valueColor="#CECECE"
        />

        <text-input
          v-if="IsSummaryTypeInProgress"
          class="info-item"
          label="Estimated"
          :value="Estimated"
          :readonly="true"
          :fontSize="12"
          :height="24"
          :labelWidth="75"
          labelColor="#949494"
          valueColor="#CECECE"
        />
      </div>
    </div>

    <div :class="['spinner', isLoadingCurrentJob || isLoadingCurrentArchive ? '' : 'hidden']"></div>
  </div>
</template>

<script lang="ts">
import { FullPrinter } from '@/models/CompositeEntities';
import { Job, JobArchieve, PrinterState, Source } from '@/models/Entities';
import { ResponseTelemetryFiltered, ResponseTelemetryItemFiltered } from '@/models/responses/ResponseConnector';
import { Routes } from '@/router/routes';
import { ConnectorModule } from '@/store/modules/connectorModule';
import { JobModule } from '@/store/modules/jobModule';
import { SourceModule } from '@/store/modules/sourceModule';
import ComponentHelper from '@/util/ComponentHelper';
import { Guid } from 'guid-typescript';
import moment from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TextInput from '@/components/inputs/TextInput.vue';

export enum SummaryType {
  Finished,
  InProgress,
  Errors,
}

@Component({
  components: {
    TextInput: TextInput,
  },
})
export default class PrinterSummary extends Vue {
  @Prop() printer!: FullPrinter;
  @Prop({ default: SummaryType.Errors }) type!: SummaryType;

  @Watch('LastArchiveId', { immediate: true })
  async OnLastArchiveIdChanged(newVal: Guid | null, oldValue: Guid | null) {
    const sameId = newVal != null && oldValue != null && newVal.toString() == oldValue.toString();

    if (sameId) return;

    await this.LoadLastArchive();

    if (this.currentJob != null) {
      await this.UnsubscribeFromTelemetry(this.printer.printer.Id);
    }
  }

  @Watch('CurrentJobId', { immediate: true })
  async OnCurrentJobIdChanged(newVal: Guid | null, oldValue: Guid | null) {
    const sameId = newVal != null && oldValue != null && newVal.toString() == oldValue.toString();

    if (sameId) return;

    await this.LoadCurrentJob();

    await this.SubscribeToTelemetry(this.printer.printer.Id);
  }

  //#region STATE
  componentId!: Guid;

  private lastTelemetry: ResponseTelemetryItemFiltered | null = null;
  private subscribedToTelemetry: boolean = false;

  private isLoadingCurrentJob = false;
  private currentJob: Job | null = null;
  private currentJobSource: Source | null = null;

  private isLoadingCurrentArchive = false;
  private currentJobArchive: JobArchieve | null = null;
  private currentJobArchiveSource: Source | null = null;

  private get IsSummaryTypeFinished() {
    return this.type == SummaryType.Finished;
  }

  private get IsSummaryTypeInProgress() {
    return this.type == SummaryType.InProgress;
  }

  private get IsSummaryTypeErrors() {
    return this.type == SummaryType.Errors;
  }

  private get CurrentJobId() {
    return this.printer.printer.CurrentJobId;
  }

  private get LastArchiveId() {
    return this.printer.printer.LastArchiveId;
  }

  private get IsFinished() {
    return this.printer.printer.PrinterState == PrinterState.PRINT_FINISHED;
  }

  private get IsInProgress() {
    return this.printer.printer.PrinterState == PrinterState.PRINTING;
  }

  private get PrinterName() {
    return this.printer.printer.Name;
  }

  private get CurrentJobGCodeName() {
    if (this.currentJob != null && this.currentJob.LocalFilename != null) {
      return this.currentJob.LocalFilename;
    }

    if (this.currentJobSource == null) return '—';

    return this.currentJobSource.CodeFileName;
  }

  private get Progress() {
    if (this.currentJob == null || this.currentJob.Progress == null) return '—';

    return this.currentJob.Progress.toFixed(0) + '%';
  }

  private get Estimated() {
    if (this.currentJob == null || this.currentJobSource == null || this.currentJobSource.PrintDuration == null)
      return '—';

    const startTime = this.currentJob.ActualStartTime;
    let endDate = moment(startTime).add(this.currentJobSource.PrintDuration).toDate();

    return ComponentHelper.GetDateTime(endDate);
  }

  private get FinishedAt() {
    if (this.currentJobArchive == null || this.currentJobArchive.EndTime == null) return '—';

    return ComponentHelper.GetDateTime(this.currentJobArchive.EndTime);
  }

  private get LastArchiveGCodeName() {
    if (this.currentJobArchive != null && this.currentJobArchive.LocalFilename != null) {
      return this.currentJobArchive.LocalFilename;
    }

    if (this.currentJobArchiveSource == null) return '—';

    return this.currentJobArchiveSource.CodeFileName;
  }

  private get PrinterErrorCount() {
    return this.printer.printer.ErrorCount.toString();
  }

  private get LastPrinterError() {
    if (this.printer.printer.LastError == null) return '—';
    return this.printer.printer.LastError;
  }

  private get ArchiveErrorCount() {
    if (this.currentJobArchive == null) return '—';
    return this.currentJobArchive.ErrorCount.toString();
  }

  private get LastToolShortName() {
    if (this.lastTelemetry == null || this.lastTelemetry.currentTool == null) return '—';

    return 'T' + this.lastTelemetry.currentTool;
  }

  private get LastToolName() {
    if (this.lastTelemetry == null || this.lastTelemetry.currentTool == null) return '—';

    return this.printer.connectorModel.PHNames[this.lastTelemetry.currentTool];
  }

  private get LastToolTemperature() {
    if (
      this.lastTelemetry == null ||
      this.lastTelemetry.currentTool == null ||
      this.lastTelemetry.printHeadTemperatures == null
    )
      return '—';

    const data = this.lastTelemetry.printHeadTemperatures[this.lastTelemetry.currentTool].data;
    const firstKey = Object.keys(data)[0];

    return (
      this.lastTelemetry.printHeadTemperatures[this.lastTelemetry.currentTool].data[firstKey].current.toFixed(0) + '°C'
    );
  }
  //#endregion

  //#region LOGIC
  async FinishedSummaryPrinterNameClicked() {
    this.$router.push({
      name: Routes.MACHINES,
      query: {
        machineId: this.printer.printer.Id.toString(),
      },
    });
  }

  async LoadCurrentJob() {
    if (this.type != SummaryType.InProgress) return;

    this.isLoadingCurrentJob = true;

    const jobId = this.printer.printer.CurrentJobId;

    console.log('LOADING CURRENT JOB, JOB ID IS ' + jobId?.toString());

    if (jobId != null) {
      this.currentJob = await JobModule.GetJob(jobId);

      if (this.currentJob != null && this.currentJob.SourceId != null) {
        this.currentJobSource = await SourceModule.GetSource(this.currentJob.SourceId);
      }
    }

    this.isLoadingCurrentJob = false;
  }

  async LoadLastArchive() {
    if (this.type != SummaryType.Finished) return;

    this.isLoadingCurrentArchive = true;

    const archiveId = this.printer.printer.LastArchiveId;

    console.log('LOADING CURRENT ARCHIVE, JOB ID IS ' + archiveId?.toString());

    if (archiveId != null) {
      this.currentJobArchive = await JobModule.GetJobArchieve(archiveId);

      if (this.currentJobArchive != null && this.currentJobArchive.SourceId != null) {
        this.currentJobArchiveSource = await SourceModule.GetSource(this.currentJobArchive.SourceId);
      }
    }

    this.isLoadingCurrentArchive = false;
  }

  TelemetriesReceived(tel: ResponseTelemetryFiltered) {
    if (tel.printerId.toString() != this.printer.printer.Id.toString()) {
      return;
    }

    const sortedTelemetries = tel.items.sort((a, b) => (a.time < b.time ? 1 : -1));

    if (sortedTelemetries.length == 0) return;

    this.lastTelemetry = sortedTelemetries[0];
  }

  async SubscribeToTelemetry(printerId: Guid) {
    await ConnectorModule.SubscribeToTelemetry(printerId);
    ConnectorModule.OnTelemetryReceived.subscribe(this.TelemetriesReceived);
    this.subscribedToTelemetry = true;
  }

  async UnsubscribeFromTelemetry(printerId: Guid) {
    await ConnectorModule.UnsubscribeFromTelemetry(printerId);
    ConnectorModule.OnTelemetryReceived.unsubscribe(this.TelemetriesReceived);
    this.subscribedToTelemetry = false;
  }

  JobUpdated(job: Job) {
    console.log(job);

    if (this.currentJob?.Id.toString() != job.Id.toString()) return;

    this.currentJob = job;
  }

  OnPrinterNameClicked() {
    this.$router.push({
      name: Routes.MACHINES,
      query: {
        machineId: this.printer.printer.Id.toString(),
      },
    });
  }

  OnPrinterLastArchiveClicked() {
    if (this.LastArchiveId == null) return;

    this.$router.push({
      name: Routes.JOBS,
      query: {
        tab: 'archive',
        archiveId: this.LastArchiveId.toString(),
      },
    });
  }

  OnPrinterCurrentJobClicked() {
    if (this.CurrentJobId == null) return;

    this.$router.push({
      name: Routes.JOBS,
      query: {
        tab: 'current',
        jobId: this.CurrentJobId.toString(),
      },
    });
  }

  //#endregion

  //#region HOOKS
  beforeCreate() {
    this.componentId = Guid.create();
  }

  mounted() {
    JobModule.OnJobUpdated.subscribe(this.JobUpdated);
  }

  async beforeDestroy() {
    if (this.subscribedToTelemetry) {
      await this.UnsubscribeFromTelemetry(this.printer.printer.Id);
    }

    JobModule.OnJobUpdated.unsubscribe(this.JobUpdated);

    if (this.currentJob != null) {
      JobModule.CollectJobs();
    }
  }
  //#endregion

  //#region TRANSLATIONS
  //#endregion
}
</script>

<style lang="scss" scoped>
.printer-summary-container {
  position: relative;
}

.printer-summary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--printer-summary-background);
  border: var(--printer-summary-border);
  border-radius: 6px;
  color: var(--printer-summary-text);
  padding: 10px 16px;
  min-height: 128px;

  .header {
    font-size: 17px;
    width: 100%;
    color: var(--printer-summary-text);
    display: flex;
    justify-content: space-between;

    .clickable {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .last-telemetry {
      display: flex;
      color: var(--special-text);
      align-items: center;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }
}
</style>
