import store from '@/store';
import { HubConnection } from '@microsoft/signalr';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import * as ips from '@/util/api/ips';
import * as routes from '@/util/api/routes';
import * as hubActions from '@/util/api/hubActions';
import { TypeHelper } from '@/util/TypeHelper';
import { UtilModule } from '../utilModule';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { ProductPrice } from '@/models/Entities';
import { RequestReadAllProductPrice } from '@/models/requests/RequestsBilling';
import { ResponseProductPrice, ResponseProductPrices } from '@/models/responses/ResponseBilling';
import { LoginModule } from '../loginModule';

@Module({ dynamic: true, name: 'product', store: store })
export default class productPriceModule extends VuexModule {
  private productPrices: ProductPrice[] = [];

  get ProductPrices(): ProductPrice[] {
    let result = this.productPrices;

    if (!LoginModule.IsSuperAdmin) {
      result = result.filter(a => a.isPublic);
    }

    return result;
  }

  @Mutation
  AddProductPrice(val: ProductPrice) {
    this.productPrices.push(val);
  }

  @Mutation
  UpdateProductPrice(val: ProductPrice) {
    this.productPrices = this.productPrices.map(price => {
      if (val.id.toString() === price.id.toString()) {
        price.isPublic = val.isPublic;
        price.name = val.name;
        price.metadata = val.metadata;
        price.outdated = val.outdated;
      }

      return price;
    });
  }

  @Mutation
  ClearProductPrices() {
    this.productPrices = [];
  }

  @Action({ rawError: true })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ProductPriceSubscribe(hubConnection: HubConnection) {}

  @Action({ rawError: true })
  async LoadProductPrices() {
    this.ClearProductPrices();

    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PRODUCT_PRICE,
      routes.UriProductPrice.READ_ALL,
    );

    const request = new RequestReadAllProductPrice();

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = axiosResponse.data.response as ResponseProductPrices;
      const prices = TypeHelper.DeepCopyFrom(response, ResponseProductPrices).prices;

      for (const res of prices) {
        const price = TypeHelper.DeepCopyFrom(res, ResponseProductPrice);
        this.AddProductPrice(price.Map());
      }
    }
  }

  @Action({ rawError: true })
  async AddToProductPriceGroup() {
    if (LoginModule.BillingConnection == null) return;
    await LoginModule.BillingConnection.send(hubActions.ADD_TO_PRODUCT_PRICE_GROUP);
    LoginModule.AddBillingConnectionGroup({
      func: this.AddToProductPriceGroup,
    });
  }

  @Action({ rawError: true })
  async DeleteFromProductPriceGroup() {
    if (LoginModule.BillingConnection == null) return;
    await LoginModule.BillingConnection.send(hubActions.DELETE_FROM_PRODUCT_PRICE_GROUP);
    LoginModule.DeleteBillingConnectionGroup({
      func: this.AddToProductPriceGroup,
    });
  }
}

export const ProductPriceModule = getModule(productPriceModule);
