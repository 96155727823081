<template>
  <AuraDialogWrapper
    ref="dialogWrapper"
    :headerText="SubscriptionProductName + '. ' + ChangePlanCaption"
    @closed="DialogWrapperClosed"
    @dismissed="DialogWrapperClosed"
  >
    <div :class="['content thin-scroll mb-1', isLoading ? 'disabled' : '']" style="max-height: 50vh; overflow: auto">
      <div class="available-plans mb-3">
        <subscription-plan-card
          v-for="item of AvailablePlans"
          :key="item.id.toString()"
          :price="item"
          :isCurrent="IsCurrentPlan(item)"
          :selected="IsSelectedPlan(item)"
          @click.native="SelectNewPlan(item)"
        >
        </subscription-plan-card>
      </div>

      <div>
        <list-item-v-3
          v-if="DefaultPaymentMethod != null"
          class="mb-2"
          :items="PaymentMethodItems(DefaultPaymentMethod)"
          footerIconName="fa-pen"
          footerIconType="far"
          :footerIconFontSize="14"
          :bigMargin="false"
          @footer-icon-clicked="ChangeDefaultPaymentMethod"
        />
      </div>

      <div v-if="lastChanges !== null" class="next-charge-label mt-2 mx-1">
        <span v-if="lastChanges.chargeNow >= 0">
          You will be charged {{ ChargeAmount
          }}<span v-if="TaxRateAvailable">, including tax {{ ChargeAmountTax }}</span>
          {{ ChargeDate }}
        </span>
        <span v-else>
          {{ ChargeAmount.replace('-', '') }} will be added to your credit<span v-if="TaxRateAvailable"
            >, including tax {{ ChargeAmountTax }}</span
          >
          {{ ChargeDate }}
        </span>
      </div>

      <button :class="['mt-3', IsSamePrice ? 'disabled' : '']" @click="UpdateSubscription">Submit</button>

      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </div>
  </AuraDialogWrapper>
</template>

<script lang="ts">
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { create, DialogComponent } from 'vue-modal-dialogs';
import { PaymentMethod, PreviewSubscriptionChanges, Product, ProductPrice, PurchasedItem } from '@/models/Entities';

import SortableHeader from '@/components/presentation/SortableHeader.vue';
import ListItemV3 from '@/components/presentation/ListItemV3.vue';
import ComponentHelper, { ItemData } from '@/util/ComponentHelper';
import { PaymentMethodModule } from '@/store/modules/billing/paymentMethodModule';
import SelectDefaultPaymentMethodDialog from '../SelectDefaultPaymentMethodDialog.vue';
import { PurchasedSubscriptionModule } from '@/store/modules/billing/purchasedSubscriptionModule';
import { AccountModule } from '@/store/modules/billing/accountModule';
import en from '@/localization/en';
import dateFormat from 'dateformat';
import { toast } from '@/main';
import { POSITION } from 'vue-toastification';
import { debounce } from 'lodash';
import { ProductPriceModule } from '@/store/modules/billing/productPriceModule';
import { ProductModule } from '@/store/modules/billing/productModule';
import {
  PurchasedItemFull,
  PurchasedItemFullGroup,
  PurchasedItemModule,
} from '@/store/modules/billing/purchasedItemModule';
import SubscriptionPlanCard from '@/components/presentation/SubscriptionPlanCard.vue';
import AuraDialogWrapper from '@/views/dialogs/base/AuraDialogWrapper.vue';

const SelectDefaultPaymentMethod = create<PaymentMethod[] | null, PaymentMethod | null>(
  SelectDefaultPaymentMethodDialog,
  'paymentMethods',
);

@Component({
  components: {
    SortableHeader: SortableHeader,
    ListItemV3: ListItemV3,
    SubscriptionPlanCard: SubscriptionPlanCard,
    AuraDialogWrapper: AuraDialogWrapper,
  },
})
export default class ChangeSubscriptionPlanDialog extends DialogComponent<boolean | null> {
  @Prop() private subscriptionItemsGroup!: PurchasedItemFullGroup;
  @Ref() dialogWrapper!: AuraDialogWrapper;

  private newProductPrice: ProductPrice | null = null;
  private isLoading: boolean = false;
  private lastChanges: PreviewSubscriptionChanges | null = null;
  private debounceLoadChanges: any;

  private get ChargeImmediately(): boolean {
    if (this.newProductPrice == null) return false;

    let recurringLevelUpgrade: boolean | null = null;
    let planChange: boolean = false;

    if (this.CurrentPrice.recurringLevel !== null && this.newProductPrice.recurringLevel !== null) {
      recurringLevelUpgrade = this.CurrentPrice.recurringLevel < this.newProductPrice.recurringLevel;
    }

    planChange =
      this.CurrentPrice.recurringInterval != this.newProductPrice.recurringInterval &&
      this.CurrentPrice.recurringIntervalCount != this.newProductPrice.recurringIntervalCount;

    return !(recurringLevelUpgrade == null || recurringLevelUpgrade) && !planChange;
  }

  private get ChargeDate(): string {
    if (this.ChargeImmediately) {
      return en.today;
    }

    return en.on + ' ' + this.NextInvoiceDate;
  }

  async created() {
    // await this.LoadChanges();

    this.debounceLoadChanges = debounce(async () => {
      await this.LoadChanges();
    }, 0);
  }

  @Watch('subscriptionItemsGroup', { immediate: true })
  private async OnSubscriptionItemsGroupChanges(newVal: PurchasedItemFullGroup) {
    this.newProductPrice = newVal.group[0].price;
  }

  private get TaxRateAvailable() {
    return AccountModule.AccountTaxRate != null;
  }

  private get CurrentSubscriptionFull(): PurchasedItemFull {
    return this.subscriptionItemsGroup.group[0];
  }

  private get CurrentPrice(): ProductPrice {
    return this.CurrentSubscriptionFull.price;
  }

  private get IsSamePrice(): boolean {
    return this.newProductPrice?.id!.toString() === this.CurrentPrice.id.toString();
  }

  private get ProductPrices(): ProductPrice[] {
    return ProductPriceModule.ProductPrices;
  }

  private get Products(): Product[] {
    return ProductModule.Products;
  }

  private get PurchasedItems(): PurchasedItem[] {
    return PurchasedItemModule.PurchasedItems;
  }

  private get AvailablePlans(): ProductPrice[] {
    const result: ProductPrice[] = [];

    for (const price of this.ProductPrices) {
      if (
        price.productId.toString() === this.CurrentSubscriptionFull.product.id.toString() &&
        price.currency === this.CurrentSubscriptionFull.price.currency
      ) {
        result.push(price);
      }
    }

    return result;
  }

  private get DefaultPaymentMethod(): PaymentMethod | null {
    return PaymentMethodModule.PaymentMethods.firstOrDefault(a => a.isDefault);
  }

  private get SubscriptionProductName() {
    return this.CurrentSubscriptionFull.product.name;
  }

  private get ChargeAmount() {
    if (this.lastChanges == null) {
      return 'N/A';
    }

    const currSymbol = en.CurrencySymbols[this.lastChanges.currency.toUpperCase()];

    return currSymbol + ((this.lastChanges.chargeNow + this.lastChanges.chargeNowTax) / 100).toFixed(2);
  }

  private get ChargeAmountTax() {
    if (this.lastChanges == null) {
      return 'N/A';
    }

    const currSymbol = en.CurrencySymbols[this.lastChanges.currency.toUpperCase()];

    return currSymbol + (this.lastChanges.chargeNowTax / 100).toFixed(2);
  }

  private get NextInvoiceSum() {
    if (this.lastChanges == null) {
      return 'N/A';
    }

    const currSymbol = en.CurrencySymbols[this.lastChanges.currency.toUpperCase()];

    const previewTotalLimit =
      this.lastChanges.previewTotal < 0 ? 0 : this.lastChanges.previewTotal + this.lastChanges.previewTotalTax;

    return currSymbol + (previewTotalLimit / 100).toFixed(2);
  }
  private get NextInvoiceDate() {
    if (this.lastChanges == null || this.lastChanges.nextAttempt == null) {
      return 'N/A';
    }

    return dateFormat(this.lastChanges.nextAttempt!, 'mmmm dS yyyy');
  }

  private PaymentMethodItems(value: PaymentMethod): ItemData[] {
    let captionItem: ItemData = new ItemData('Caption', 'Payment method:', 'grow-1');
    let last4DigitsItem: ItemData = new ItemData(
      'Last4Digits',
      ComponentHelper.GetReadablePaymentMethodKind(value.methodType) + '*' + value.last4Digits,
      'grow-1',
    );
    let editItem: ItemData = new ItemData('Edit', '', 'grow-1');

    let items: ItemData[] = [];
    items.push(captionItem);
    items.push(last4DigitsItem);
    items.push(editItem);

    return items;
  }

  private async ChangeDefaultPaymentMethod() {
    const result = await SelectDefaultPaymentMethod(PaymentMethodModule.PaymentMethods.filter(a => !a.isDefault));

    if (result === null) {
      return;
    }

    this.isLoading = true;

    await PaymentMethodModule.MakeDefault(result.id);

    this.isLoading = false;
  }

  private async LoadChanges() {
    this.isLoading = true;

    if (AccountModule.Account === null) {
      // No billing account... probably inform user?
      this.isLoading = false;
      return;
    }

    const res = await PurchasedSubscriptionModule.PreviewPriceChanges({
      accountId: AccountModule.Account.id,
      subId: this.subscriptionItemsGroup.id,
      updateData: {
        newProductPriceId: this.newProductPrice!.id!,
        newQuantity: this.subscriptionItemsGroup.group.length,
      },
      prorationDate: null,
    });

    if (res !== null) {
      this.lastChanges = res;
    }

    this.isLoading = false;
  }

  private async UpdateSubscription() {
    this.isLoading = true;

    if (AccountModule.Account === null) {
      // No billing account... probably inform user?
      this.isLoading = false;
      return;
    }

    const res = await PurchasedSubscriptionModule.Update({
      accountId: AccountModule.Account.id,
      subId: this.subscriptionItemsGroup.id,
      updateData: {
        newProductPriceId: this.newProductPrice!.id!,
        newQuantity: null,
      },
      prorationDate: this.lastChanges == null ? null : this.lastChanges!.prorationDate,
    });

    if (res) {
      toast.success('Changed subscription successfully', {
        position: POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error('Could not change subscription', {
        position: POSITION.BOTTOM_RIGHT,
      });
    }

    this.dialogWrapper.CloseDialog();
    this.$close(res);

    this.isLoading = false;
  }

  private IsCurrentPlan(price: ProductPrice) {
    return this.CurrentSubscriptionFull.price.id.toString() === price.id.toString();
  }

  private IsSelectedPlan(price: ProductPrice) {
    return this.newProductPrice!.id.toString() === price.id.toString();
  }

  private async SelectNewPlan(price: ProductPrice) {
    if (this.CurrentPrice.id.toString() == price.id.toString()) {
      return;
    }

    this.newProductPrice = price;

    await this.debounceLoadChanges();
  }

  private DialogWrapperClosed() {
    this.$close(null);
  }

  private get ChangePlanCaption() {
    return en.changePlan.titleCase();
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 400px;

  button {
    border: none;
    background-color: var(--account-view-border);
    color: black;
    border-radius: 6px;
    height: 32px;
    font-size: 16px;
    transition: opacity 150ms ease-out;
    font-size: 13px;
  }

  .available-plans {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
}

.current-seat-label,
.next-charge-label {
  font-size: 12px;
}
</style>
