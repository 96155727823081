import { Guid } from 'guid-typescript';
import { JobState, PrinterState, MaterialType, EndStatus } from '../Entities';
import { IPlainResponse } from './BaseResponse';

export class ResponseJob implements IPlainResponse {
  id!: Guid;
  sourceId!: Guid | null;
  localFilename!: string | null;
  printerId!: Guid;
  jobState!: JobState;
  userId!: Guid | null;
  companyId!: Guid;
  progress!: number | null;
  order!: number | null;
  actualStartTime!: string | null;
  creationDateTime!: string;
  isInvoked!: boolean;
  errorCount!: number;
  lastError!: string | null;
}
export class ResponseJobs implements IPlainResponse {
  printJobs!: ResponseJob[];
}
export class ResponseJobArchieve implements IPlainResponse {
  id!: Guid;
  companyId!: Guid;
  sourceId!: Guid | null;
  userId!: Guid | null;
  localFilename!: string | null;
  printerId!: Guid;
  creationDateTime!: string;
  actualStartTime!: string;
  endTime!: string | null;
  endStatus!: EndStatus;
  errorCount!: number;
}
export class ResponseJobArchieves implements IPlainResponse {
  jobArchieves!: ResponseJobArchieve[];
}
export class ResponseJobArchieveCount implements IPlainResponse {
  userId!: Guid;
  count!: number;
}
export class ResponseJobArchieveCounts implements IPlainResponse {
  responses!: ResponseJobArchieveCount[];
}
export class ResponseJobProgress implements IPlainResponse {
  jobId!: Guid;
  progress!: number;
}
export class ResponseJobStartFileTransferProgress implements IPlainResponse {
  jobId!: Guid;
  printerId!: Guid;
  progress!: number;
}
export class ResponseJobStatus implements IPlainResponse {
  jobId!: Guid;
  jobState!: JobState;
}
export class ResponseJobUpdateErrorCount implements IPlainResponse {
  id!: Guid;
  count!: number;
}
export class ResponseJobUpdateLastError implements IPlainResponse {
  id!: Guid;
  lastError!: string;
}
export class ResponseJobCount implements IPlainResponse {
  userId!: Guid;
  count!: number;
}
export class ResponseJobCounts implements IPlainResponse {
  responses!: ResponseJobCount[];
}
export class ResponseJobActive implements IPlainResponse {
  hasActiveJob!: boolean;
}
export class ResponseJobInvoked implements IPlainResponse {
  id!: Guid;
  printerId!: Guid;
}
export class ResponsePrinter implements IPlainResponse {
  id!: Guid;
  creatorId!: Guid;
  companyId!: Guid;
  printerModelId!: Guid;
  name!: string;
  description!: string | null;
  serialNumber!: string | null;
  hardwareVersion!: string | null;
  firmwareVersion!: string | null;
  firmwareType!: string | null;
  printerState!: PrinterState;
  creationDate!: string;
  jobCount!: number;
  queuedJobCount!: number;
  errorCount!: number;
  lastError!: string | null;
  lastArchiveId!: Guid | null;
  currentJobId!: Guid | null;
  logsReady!: boolean;
}
export class ResponsePrinterId implements IPlainResponse {
  id!: Guid;
}
export class ResponsePrinterState implements IPlainResponse {
  id!: Guid;
  printerState!: PrinterState;
}
export class ResponsePrinterUpdateErrorCount implements IPlainResponse {
  id!: Guid;
  count!: number;
}
export class ResponsePrinterUpdateLastError implements IPlainResponse {
  id!: Guid;
  lastError!: string;
}
export class ResponsePrinterUpdateLastArchive implements IPlainResponse {
  id!: Guid;
  archiveId!: Guid;
}
export class ResponsePrinterUpdateCurrentJob implements IPlainResponse {
  id!: Guid;
  currentJobId!: Guid;
}
export class ResponsePrinters implements IPlainResponse {
  printers!: ResponsePrinter[];
}
export class ResponsePrinterModel implements IPlainResponse {
  id!: Guid;
  name!: string;
}
export class ResponsePrinterModels implements IPlainResponse {
  printerModels!: ResponsePrinterModel[];
}
export class ResponseSource implements IPlainResponse {
  id!: Guid;
  companyId!: Guid;
  groupId!: Guid | null;
  userId!: Guid;
  codeFileName!: string;
  codePath!: string;
  codeFileSize!: number;
  projectPath!: string | null;
  projectFileName!: string | null;
  projectFileSize!: number | null;
  printDuration!: string | null;
  loadTime!: string;
  materialConsumptions!: ResponseMaterialConsumption[];
  printerModel!: string | null;
  lastPrint!: string | null;
  printCount!: number;
}
export class ResponseSourceFile implements IPlainResponse {
  bytes!: Uint8Array;
  name!: string;
  contentType!: string;
}
export class ResponseSourceGetSizeLimit implements IPlainResponse {
  sizeLimitInBytes!: number;
}
export class ResponseSourceGetTotalSize implements IPlainResponse {
  totalSizeInBytes!: number;
}
export class ResponseSources implements IPlainResponse {
  jobSources!: ResponseSource[];
}
export class ResponseMaterialConsumption implements IPlainResponse {
  id!: Guid;
  materialName!: string;
  extruderIndex!: string;
  count!: number;
  materialType!: MaterialType;
}
export class ResponseSourceGroup implements IPlainResponse {
  id!: Guid;
  companyId!: Guid;
  userId!: Guid | null;
  name!: string;
  description!: string | null;
  lastUpdated!: string | null;
  fileCount!: number;
  isVoid!: boolean;
}
export class ResponseSourceGroups implements IPlainResponse {
  groups!: ResponseSourceGroup[];
}
export class ResponseUserStat implements IPlainResponse {
  id!: Guid;
  userId!: Guid;
  jobCountWeek!: number;
  jobCountMonth!: number;
  jobCountYear!: number;
  jobCountWhole!: number;
  sourceCount!: number;
  printTimeWeekS!: number;
  printTimeMonthS!: number;
  printTimeYearS!: number;
  printTimeWholeS!: number;
  plasticConsumedGr!: number;
  compositeConsumedM!: number;
}
export class ResponseUserStats implements IPlainResponse {
  stats!: ResponseUserStat[];
}
export class ResponseCompanyStat implements IPlainResponse {
  id!: Guid;
  companyId!: Guid;
  jobCountWeek!: number;
  jobCountMonth!: number;
  jobCountYear!: number;
  jobCountWhole!: number;
  sourceCount!: number;
  printTimeWeekS!: number;
  printTimeMonthS!: number;
  printTimeYearS!: number;
  printTimeWholeS!: number;
  plasticConsumedGr!: number;
  compositeConsumedM!: number;
  workloadAvg!: number;
  usersCount!: number;
  printersCount!: number;
}
export class ResponsePrinterStat implements IPlainResponse {
  id!: Guid;
  printerId!: Guid;
  companyId!: Guid;
  jobCountWeek!: number;
  jobCountMonth!: number;
  jobCountYear!: number;
  jobCountWhole!: number;
  printTimeWeekS!: number;
  printTimeMonthS!: number;
  printTimeYearS!: number;
  printTimeWholeS!: number;
  plasticConsumedGr!: number;
  compositeConsumedM!: number;
  creationDate!: string;
  workloadAvg!: number;
}
export class ResponseMaterialConsumptionPrinterStat implements IPlainResponse {
  id!: Guid;
  printerId!: Guid;
  companyId!: Guid;
  materialName!: string;
  materialType!: MaterialType;
  consumedWeek!: number;
  consumedMonth!: number;
  consumedYear!: number;
  consumedWhole!: number;
}
export class ResponseMaterialConsumptionPrinterStats implements IPlainResponse {
  stats!: ResponseMaterialConsumptionPrinterStat[];
}
export class ResponseMaterialConsumptionCompanyStat implements IPlainResponse {
  id!: Guid;
  companyId!: Guid;
  materialName!: string;
  materialType!: MaterialType;
  consumedWeek!: number;
  consumedMonth!: number;
  consumedYear!: number;
  consumedWhole!: number;
}
export class ResponseMaterialConsumptionCompanyStats implements IPlainResponse {
  stats!: ResponseMaterialConsumptionCompanyStat[];
}
export class ResponseTotalPrintTimeByWeekDayCompanyStat implements IPlainResponse {
  id!: Guid;
  companyId!: Guid;
  mondayTimeS!: number;
  tuesdayTimeS!: number;
  wednesdayTimeS!: number;
  thursdayTimeS!: number;
  fridayTimeS!: number;
  saturdayTimeS!: number;
  sundayTimeS!: number;
}

export class ResponseMaterialConsumptionArchive implements IPlainResponse {
  materialName!: string;
  materialType!: MaterialType;
  consumed!: number;
}

export class ResponseMaterialConsumptionArchives implements IPlainResponse {
  materialConsumptionArchives!: ResponseMaterialConsumptionArchive[];
}

export class ResponseGlobalNotification implements IPlainResponse {
  id!: Guid;
  message!: string;
  userId!: Guid;
  startShowing!: string;
  endShowing!: string;
}

export class ResponseGlobalNotifications implements IPlainResponse {
  globalNotifications!: ResponseGlobalNotification[];
}

export class ResponseMonolithHelper {
  static createRealJob(from: ResponseJob): ResponseJob {
    const r = new ResponseJob();
    r.id = Guid.parse(from.id.toString());
    r.sourceId = from.sourceId == null ? null : Guid.parse(from.sourceId.toString());
    r.localFilename = from.localFilename;
    r.printerId = Guid.parse(from.printerId.toString());
    r.jobState = from.jobState;
    r.userId = from.userId == null ? null : Guid.parse(from.userId.toString());
    r.companyId = Guid.parse(from.companyId.toString());
    r.progress = from.progress;
    r.order = from.order;
    r.actualStartTime = from.actualStartTime;
    r.creationDateTime = from.creationDateTime;
    r.isInvoked = from.isInvoked;
    r.errorCount = from.errorCount;
    r.lastError = from.lastError;
    return r;
  }
  static createRealJobs(from: ResponseJobs): ResponseJobs {
    const r = new ResponseJobs();
    r.printJobs = [];
    for (const item of from.printJobs) {
      r.printJobs.push(this.createRealJob(item));
    }
    return r;
  }
  static createRealJobArchieve(from: ResponseJobArchieve): ResponseJobArchieve {
    const r = new ResponseJobArchieve();
    r.id = Guid.parse(from.id.toString());
    r.companyId = Guid.parse(from.companyId.toString());
    r.sourceId = from.sourceId == null ? null : Guid.parse(from.sourceId.toString());
    r.userId = from.userId == null ? null : Guid.parse(from.userId.toString());
    r.localFilename = from.localFilename;
    r.printerId = Guid.parse(from.printerId.toString());
    r.creationDateTime = from.creationDateTime;
    r.actualStartTime = from.actualStartTime;
    r.endTime = from.endTime;
    r.endStatus = from.endStatus;
    r.errorCount = from.errorCount;
    return r;
  }
  static createRealJobArchieves(from: ResponseJobArchieves): ResponseJobArchieves {
    const r = new ResponseJobArchieves();
    r.jobArchieves = [];
    for (const item of from.jobArchieves) {
      r.jobArchieves.push(this.createRealJobArchieve(item));
    }
    return r;
  }
  static createRealJobStatus(from: ResponseJobStatus): ResponseJobStatus {
    const r = new ResponseJobStatus();
    r.jobId = Guid.parse(from.jobId.toString());
    r.jobState = from.jobState;
    return r;
  }
  static createRealJobProgress(from: ResponseJobProgress): ResponseJobProgress {
    const r = new ResponseJobProgress();
    r.jobId = Guid.parse(from.jobId.toString());
    r.progress = from.progress;
    return r;
  }
  static createRealJobStartFileTransferProgress(
    from: ResponseJobStartFileTransferProgress,
  ): ResponseJobStartFileTransferProgress {
    const r = new ResponseJobStartFileTransferProgress();
    r.jobId = Guid.parse(from.jobId.toString());
    r.printerId = Guid.parse(from.printerId.toString());
    r.progress = from.progress;
    return r;
  }
  static createRealJobCount(from: ResponseJobCount): ResponseJobCount {
    const r = new ResponseJobCount();
    r.userId = Guid.parse(from.userId.toString());
    r.count = from.count;
    return r;
  }
  static createRealJobCounts(from: ResponseJobCounts): ResponseJobCounts {
    const r = new ResponseJobCounts();
    r.responses = [];
    for (const item of from.responses) {
      r.responses.push(this.createRealJobCount(item));
    }
    return r;
  }
  static createRealJobActive(from: ResponseJobActive): ResponseJobActive {
    const r = new ResponseJobActive();
    r.hasActiveJob = from.hasActiveJob;
    return r;
  }
  static createRealJobInvoked(from: ResponseJobInvoked): ResponseJobInvoked {
    const r = new ResponseJobInvoked();
    r.id = Guid.parse(from.id.toString());
    r.printerId = Guid.parse(from.printerId.toString());
    return r;
  }
  static createRealJobArchieveCount(from: ResponseJobArchieveCount): ResponseJobArchieveCount {
    const r = new ResponseJobArchieveCount();
    r.userId = Guid.parse(from.userId.toString());
    r.count = from.count;
    return r;
  }
  static createRealJobArchieveCounts(from: ResponseJobArchieveCounts): ResponseJobArchieveCounts {
    const r = new ResponseJobArchieveCounts();
    r.responses = [];
    for (const item of from.responses) {
      r.responses.push(this.createRealJobArchieveCount(item));
    }
    return r;
  }
  static createRealPrinter(from: ResponsePrinter): ResponsePrinter {
    const r = new ResponsePrinter();
    r.id = Guid.parse(from.id.toString());
    r.creatorId = Guid.parse(from.creatorId.toString());
    r.companyId = Guid.parse(from.companyId.toString());
    r.printerModelId = Guid.parse(from.printerModelId.toString());
    r.name = from.name;
    r.description = from.description;
    r.serialNumber = from.serialNumber;
    r.hardwareVersion = from.hardwareVersion;
    r.firmwareVersion = from.firmwareVersion;
    r.firmwareType = from.firmwareType;
    r.printerState = from.printerState;
    r.creationDate = from.creationDate;
    r.jobCount = from.jobCount;
    r.queuedJobCount = from.queuedJobCount;
    r.errorCount = from.errorCount;
    r.lastError = from.lastError;
    r.currentJobId = from.currentJobId;
    r.lastArchiveId = from.lastArchiveId;
    r.logsReady = from.logsReady;
    return r;
  }
  static createRealPrinterId(from: ResponsePrinterId): ResponsePrinterId {
    const r = new ResponsePrinterId();
    r.id = Guid.parse(from.id.toString());
    return r;
  }
  static createRealPrinterModel(from: ResponsePrinterModel): ResponsePrinterModel {
    const r = new ResponsePrinterModel();
    r.id = Guid.parse(from.id.toString());
    r.name = from.name;
    return r;
  }
  static createRealPrinterModels(from: ResponsePrinterModels): ResponsePrinterModels {
    const r = new ResponsePrinterModels();
    r.printerModels = [];
    for (const item of from.printerModels) {
      r.printerModels.push(this.createRealPrinterModel(item));
    }
    return r;
  }
  static createRealPrinterState(from: ResponsePrinterState): ResponsePrinterState {
    const r = new ResponsePrinterState();
    r.id = from.id;
    r.printerState = from.printerState;
    return r;
  }
  static createRealPrinters(from: ResponsePrinters): ResponsePrinters {
    const r = new ResponsePrinters();
    r.printers = [];
    for (const item of from.printers) {
      r.printers.push(this.createRealPrinter(item));
    }
    return r;
  }
  static createRealSource(from: ResponseSource): ResponseSource {
    const r = new ResponseSource();
    r.id = Guid.parse(from.id.toString());
    r.companyId = Guid.parse(from.companyId.toString());
    r.groupId = from.groupId != null ? Guid.parse(from.groupId.toString()) : null;
    r.userId = Guid.parse(from.userId.toString());
    r.codeFileName = from.codeFileName;
    r.codePath = from.codePath;
    r.codeFileSize = from.codeFileSize;
    r.projectPath = from.projectPath;
    r.projectFileName = from.projectFileName;
    r.projectFileSize = from.projectFileSize;
    r.printDuration = from.printDuration;
    r.loadTime = from.loadTime;
    r.materialConsumptions = [];
    for (const item of from.materialConsumptions) {
      r.materialConsumptions.push(this.createRealMaterialConsumption(item));
    }
    r.printerModel = from.printerModel;
    r.printCount = from.printCount;
    r.lastPrint = from.lastPrint;
    return r;
  }
  static createRealSizeLimit(from: ResponseSourceGetSizeLimit): ResponseSourceGetSizeLimit {
    const r = new ResponseSourceGetSizeLimit();

    r.sizeLimitInBytes = from.sizeLimitInBytes;

    return r;
  }
  static createRealTotalSize(from: ResponseSourceGetTotalSize): ResponseSourceGetTotalSize {
    const r = new ResponseSourceGetTotalSize();

    r.totalSizeInBytes = from.totalSizeInBytes;

    return r;
  }
  static createRealSources(from: ResponseSources): ResponseSources {
    const r = new ResponseSources();
    r.jobSources = [];
    for (const item of from.jobSources) {
      r.jobSources.push(this.createRealSource(item));
    }
    return r;
  }
  static createRealMaterialConsumption(from: ResponseMaterialConsumption): ResponseMaterialConsumption {
    const r = new ResponseMaterialConsumption();
    r.materialName = from.materialName;
    r.extruderIndex = from.extruderIndex;
    r.count = from.count;
    r.materialType = from.materialType;
    r.id = Guid.parse(from.id.toString());
    return r;
  }
  static createRealSourceGroup(from: ResponseSourceGroup): ResponseSourceGroup {
    const r = new ResponseSourceGroup();
    r.id = Guid.parse(from.id.toString());
    r.companyId = Guid.parse(from.companyId.toString());
    r.userId = from.userId == null ? null : Guid.parse(from.userId.toString());
    r.name = from.name;
    r.description = from.description;
    r.fileCount = from.fileCount;
    r.lastUpdated = from.lastUpdated;
    r.isVoid = from.isVoid;
    return r;
  }
  static createRealSourceGroups(from: ResponseSourceGroups): ResponseSourceGroups {
    const r = new ResponseSourceGroups();
    r.groups = [];
    for (const item of from.groups) {
      r.groups.push(this.createRealSourceGroup(item));
    }
    return r;
  }
  static createRealUserStat(from: ResponseUserStat): ResponseUserStat {
    const r = new ResponseUserStat();
    r.id = Guid.parse(from.id.toString());
    r.userId = Guid.parse(from.userId.toString());
    r.jobCountWeek = from.jobCountWeek;
    r.jobCountMonth = from.jobCountMonth;
    r.jobCountYear = from.jobCountYear;
    r.jobCountWhole = from.jobCountWhole;
    r.sourceCount = from.sourceCount;
    r.printTimeWeekS = from.printTimeWeekS;
    r.printTimeMonthS = from.printTimeMonthS;
    r.printTimeYearS = from.printTimeYearS;
    r.printTimeWholeS = from.printTimeWholeS;
    r.plasticConsumedGr = from.plasticConsumedGr;
    r.compositeConsumedM = from.compositeConsumedM;
    return r;
  }
  static createRealUserStats(from: ResponseUserStats): ResponseUserStats {
    const r = new ResponseUserStats();
    r.stats = [];
    for (const stat of from.stats) {
      r.stats.push(this.createRealUserStat(stat));
    }
    return r;
  }
  static createRealCompanyStat(from: ResponseCompanyStat): ResponseCompanyStat {
    const r = new ResponseCompanyStat();
    r.id = Guid.parse(from.id.toString());
    r.companyId = Guid.parse(from.companyId.toString());
    r.jobCountWeek = from.jobCountWeek;
    r.jobCountMonth = from.jobCountMonth;
    r.jobCountYear = from.jobCountYear;
    r.jobCountWhole = from.jobCountWhole;
    r.sourceCount = from.sourceCount;
    r.printTimeWeekS = from.printTimeWeekS;
    r.printTimeMonthS = from.printTimeMonthS;
    r.printTimeYearS = from.printTimeYearS;
    r.printTimeWholeS = from.printTimeWholeS;
    r.plasticConsumedGr = from.plasticConsumedGr;
    r.compositeConsumedM = from.compositeConsumedM;
    r.workloadAvg = from.workloadAvg;
    r.usersCount = from.usersCount;
    r.printersCount = from.printersCount;
    return r;
  }
  static createRealPrinterStat(from: ResponsePrinterStat): ResponsePrinterStat {
    const r = new ResponsePrinterStat();
    r.id = Guid.parse(from.id.toString());
    r.printerId = Guid.parse(from.printerId.toString());
    r.companyId = Guid.parse(from.companyId.toString());
    r.jobCountWeek = from.jobCountWeek;
    r.jobCountMonth = from.jobCountMonth;
    r.jobCountYear = from.jobCountYear;
    r.jobCountWhole = from.jobCountWhole;
    r.printTimeWeekS = from.printTimeWeekS;
    r.printTimeMonthS = from.printTimeMonthS;
    r.printTimeYearS = from.printTimeYearS;
    r.printTimeWholeS = from.printTimeWholeS;
    r.plasticConsumedGr = from.plasticConsumedGr;
    r.compositeConsumedM = from.compositeConsumedM;
    r.creationDate = from.creationDate;
    r.workloadAvg = from.workloadAvg;
    return r;
  }
  static createRealTotalPrintTimeByWeekDayCompanyStat(
    from: ResponseTotalPrintTimeByWeekDayCompanyStat,
  ): ResponseTotalPrintTimeByWeekDayCompanyStat {
    const r = new ResponseTotalPrintTimeByWeekDayCompanyStat();
    r.id = Guid.parse(from.id.toString());
    r.companyId = Guid.parse(from.companyId.toString());
    r.mondayTimeS = from.mondayTimeS;
    r.tuesdayTimeS = from.tuesdayTimeS;
    r.wednesdayTimeS = from.wednesdayTimeS;
    r.thursdayTimeS = from.thursdayTimeS;
    r.fridayTimeS = from.fridayTimeS;
    r.saturdayTimeS = from.saturdayTimeS;
    r.sundayTimeS = from.sundayTimeS;
    return r;
  }
  static createRealMaterialConsumptionPrinterStat(
    from: ResponseMaterialConsumptionPrinterStat,
  ): ResponseMaterialConsumptionPrinterStat {
    const r = new ResponseMaterialConsumptionPrinterStat();
    r.id = Guid.parse(from.id.toString());
    r.printerId = Guid.parse(from.printerId.toString());
    r.companyId = Guid.parse(from.companyId.toString());
    r.materialName = from.materialName;
    r.materialType = from.materialType;
    r.consumedWeek = from.consumedWeek;
    r.consumedMonth = from.consumedMonth;
    r.consumedYear = from.consumedYear;
    r.consumedWhole = from.consumedWhole;
    return r;
  }
  static createRealMaterialConsumptionPrinterStats(
    from: ResponseMaterialConsumptionPrinterStats,
  ): ResponseMaterialConsumptionPrinterStats {
    const r = new ResponseMaterialConsumptionPrinterStats();
    r.stats = [];
    for (const stat of from.stats) {
      r.stats.push(this.createRealMaterialConsumptionPrinterStat(stat));
    }
    return r;
  }
  static createRealMaterialConsumptionCompanyStat(
    from: ResponseMaterialConsumptionCompanyStat,
  ): ResponseMaterialConsumptionCompanyStat {
    const r = new ResponseMaterialConsumptionCompanyStat();
    r.id = Guid.parse(from.id.toString());
    r.companyId = Guid.parse(from.companyId.toString());
    r.materialName = from.materialName;
    r.materialType = from.materialType;
    r.consumedWeek = from.consumedWeek;
    r.consumedMonth = from.consumedMonth;
    r.consumedYear = from.consumedYear;
    r.consumedWhole = from.consumedWhole;
    return r;
  }
  static createRealMaterialConsumptionCompanyStats(
    from: ResponseMaterialConsumptionCompanyStats,
  ): ResponseMaterialConsumptionCompanyStats {
    const r = new ResponseMaterialConsumptionCompanyStats();
    r.stats = [];
    for (const stat of from.stats) {
      r.stats.push(this.createRealMaterialConsumptionCompanyStat(stat));
    }
    return r;
  }
  static createRealGlobalNotification(from: ResponseGlobalNotification) {
    const r = new ResponseGlobalNotification();
    r.id = Guid.parse(from.id.toString());
    r.message = from.message;
    r.endShowing = from.endShowing;
    r.startShowing = from.startShowing;
    r.userId = Guid.parse(from.userId.toString());
    return r;
  }
  static createRealGlobalNotifications(from: ResponseGlobalNotifications) {
    const r = new ResponseGlobalNotifications();
    r.globalNotifications = [];
    for (const item of from.globalNotifications) {
      r.globalNotifications.push(this.createRealGlobalNotification(item));
    }
    return r;
  }
  static createRealPrinterUpdateErrorCount(from: ResponsePrinterUpdateErrorCount): ResponsePrinterUpdateErrorCount {
    const r = new ResponsePrinterUpdateErrorCount();
    r.count = from.count;
    r.id = Guid.parse(from.id.toString());
    return r;
  }
  static createRealPrinterUpdateLastError(from: ResponsePrinterUpdateLastError): ResponsePrinterUpdateLastError {
    const r = new ResponsePrinterUpdateLastError();
    r.lastError = from.lastError;
    r.id = Guid.parse(from.id.toString());
    return r;
  }
  static createRealPrinterUpdateLastArchive(from: ResponsePrinterUpdateLastArchive): ResponsePrinterUpdateLastArchive {
    const r = new ResponsePrinterUpdateLastArchive();
    r.id = Guid.parse(from.id.toString());
    r.archiveId = Guid.parse(from.archiveId.toString());
    return r;
  }
  static createRealPrinterUpdateCurrentJob(from: ResponsePrinterUpdateCurrentJob): ResponsePrinterUpdateCurrentJob {
    const r = new ResponsePrinterUpdateCurrentJob();
    r.id = Guid.parse(from.id.toString());
    r.currentJobId = Guid.parse(from.currentJobId.toString());
    return r;
  }
  static createRealJobUpdateErrorCount(from: ResponseJobUpdateErrorCount): ResponseJobUpdateErrorCount {
    const r = new ResponseJobUpdateErrorCount();
    r.count = from.count;
    r.id = Guid.parse(from.id.toString());
    return r;
  }
  static createRealJobUpdateLastError(from: ResponseJobUpdateLastError): ResponseJobUpdateLastError {
    const r = new ResponseJobUpdateLastError();
    r.lastError = from.lastError;
    r.id = Guid.parse(from.id.toString());
    return r;
  }
  static createRealMaterialConsumptionArchive(
    from: ResponseMaterialConsumptionArchive,
  ): ResponseMaterialConsumptionArchive {
    const r = new ResponseMaterialConsumptionArchive();
    r.consumed = from.consumed;
    r.materialName = from.materialName;
    r.materialType = from.materialType;
    return r;
  }
  static createRealMaterialConsumptionArchives(
    from: ResponseMaterialConsumptionArchives,
  ): ResponseMaterialConsumptionArchives {
    const r = new ResponseMaterialConsumptionArchives();
    r.materialConsumptionArchives = [];

    for (const item of from.materialConsumptionArchives) {
      r.materialConsumptionArchives.push(this.createRealMaterialConsumptionArchive(item));
    }

    return r;
  }
}
