<template>
  <button
    :class="['regular-button', disabled ? 'disabled' : '']"
    :data-testid="testId"
    @click="disabled ? undefined : Clicked()"
  >
    {{ label }}
  </button>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

@Component
export default class RegularButton extends Vue {
  @Prop({ default: '' }) label!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: undefined }) testId?: string;

  @Emit('clicked')
  Clicked() {}
}
</script>

<style lang="scss" scoped>
.regular-button {
  border: var(--regular-button-border);
  border-radius: 6px;
  font-size: 13px;
  color: var(--regular-button-text);
  background: var(--regular-button-background);
  padding: 3px 13px;

  &.disabled {
    opacity: 0.5;
    cursor: unset;
  }

  &:hover:not(.disabled) {
    color: var(--regular-button-text-hover);
  }
}
</style>
