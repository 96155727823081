import { Guid } from 'guid-typescript';
import { TimeSpan } from '@/util/TimeSpan';
import { Countries, States } from './responses/Countries';
import { InvoiceStatus } from './enums/InvoiceStatus';
import { PaymentMethodKind } from './enums/PaymentMethodKind';
import { LicenseType } from './enums/LicenseType';
import { PaymentType } from './enums/PaymentType';
import { RecurringIntervalKind } from './enums/RecurringIntervalKind';
import { PurchaseStatus } from './enums/PurchaseStatus';
import { PaymentState } from './enums/PaymentState';
import { TaxIdType } from './enums/TaxIdType';
import { TaxType } from './enums/TaxType';

export class Printer {
  Id!: Guid;
  CompanyId!: Guid;
  UserId!: Guid;
  PrinterModelId!: Guid;
  PrinterState!: PrinterState;
  Name!: string;
  Description!: string | null;
  SerialNumber!: string | null;
  HardwareVersion!: string | null;
  FirmwareVersion!: string | null;
  FirmwareType!: string | null;
  CreationDate!: Date;
  JobCount!: number;
  QueuedJobCount!: number;
  RawEnabled: boolean = false;
  ErrorCount!: number;
  LastError!: string | null;
  LastArchiveId: Guid | null = null;
  CurrentJobId: Guid | null = null;
  LogsReady!: boolean;
}

export enum PrinterState {
  UNKNOWN,
  IDLE,
  BUSY,
  PRINTING,
  PAUSING,
  PAUSED,
  RESUMING,
  PRINT_FINISHED,
  HALTED,
  UPDATING_FIRMWARE,
  DISCONNECTED,
}

export class PrinterModel {
  Id!: Guid;
  Name!: string;
}

export class ConnectorPrinterModel {
  Id!: Guid;
  BPSensorNames!: string[];
  CHSensorNames!: string[];
  PHNames!: string[];
  PHSensorNames!: string[][];
  FanNames!: string[];
  ExtruderNames!: string[];
}

export class Job {
  Id!: Guid;
  CompanyId!: Guid;
  SourceId!: Guid | null;
  UserId!: Guid | null;
  LocalFilename!: string | null;
  PrinterId!: Guid;
  CreationDateTime!: Date;
  Order!: number | null;
  JobState!: JobState;
  Progress!: number | null;
  StartFileTransferProgress: number = 0; // Прогресс передачи файла
  ActualStartTime!: Date | null;
  IsInvoked!: boolean;
  ErrorCount!: number;
  LastError!: string | null;
}

export enum EndStatus {
  SUCCESS,
  CANCELLED,
  UNKNOWN,
}

export class JobArchieve {
  Id!: Guid;
  CompanyId!: Guid;
  SourceId!: Guid | null;
  UserId!: Guid | null;
  PrinterId!: Guid;
  LocalFilename!: string | null;
  CreationDateTime!: Date;
  ActualStartTime!: Date | null;
  EndTime!: Date | null;
  EndStatus!: EndStatus;
  ErrorCount!: number;
}

// Todo: split this class because it's used in Machines view where there is no source
export class UserJob {
  Id!: Guid;
  StartTime!: Date | null;
  Printer!: Printer;
  //если есть пользователь - то есть и источник печати
  //для локальных Job нет ни пользователя, ни файла-источника
  Source!: Source | null;
  Status!: JobState;
  Progress!: number | null;
  Job!: Job;
  Author!: User | null;
}

export class CompanyUser {
  Id!: Guid;
  Email!: string;
  FullName!: string;
  TotalPrints!: number;
  LastSeen!: Date;
  Roles!: Role[];
}

export class Source {
  Id!: Guid;
  CompanyId!: Guid;
  GroupId!: Guid | null;
  UserId!: Guid;
  CreationDateTime!: Date;
  CodePath!: string;
  CodeFileName!: string;
  CodeFileSize!: number;
  ProjectPath!: string | null;
  ProjectFileName!: string | null;
  ProjectFileSize!: number | null;
  PrintDuration!: TimeSpan | null;
  MaterialConsumptions!: MaterialConsumption[];
  PrinterModel!: string | null;
  PrintCount!: number;
  LastPrint!: Date | null;
}

export class SourceGroup {
  Id!: Guid;
  CompanyId!: Guid;
  UserId!: Guid | null;
  CreationDateTime!: Date;
  Name!: string;
  Description!: string | null;
  LastUpdated!: Date | null;
  FileCount!: number;
  IsVoid!: boolean;
}

export class MaterialConsumption {
  Id!: Guid;
  MaterialName!: string;
  ExtruderIndex!: string;
  Count!: number;
  MaterialType!: MaterialType;
  Equals(other: MaterialConsumption): boolean {
    return (
      this.Id === other.Id &&
      this.MaterialName === other.MaterialName &&
      this.ExtruderIndex === other.ExtruderIndex &&
      this.Count === other.Count &&
      this.MaterialType === other.MaterialType
    );
  }
}

export class MaterialConsumptionArchive {
  MaterialName!: string;
  MaterialType!: MaterialType;
  Consumed!: number;
}

export enum MaterialType {
  PLASTIC,
  FIBER,
}

export enum JobState {
  NOT_STARTED,
  START,
  PAUSE,
  CANCEL,
  RESUME,
  FINISH,
}

export class Role {
  Id!: Guid;
  Name!: string;
  IsAdmin!: boolean;
  IsSuperAdmin!: boolean;
  IsBillingAccountAdmin!: boolean;
  IsBillingAccountOwner!: boolean;
  IsConnectUser!: boolean;
}

export class LoginSession {
  Id!: Guid;
  UserId!: Guid;
  IP!: string;
  LastSeen!: Date;
}

export class User {
  Id!: Guid;
  CompanyId!: Guid;
  Roles!: Role[];
  Email!: string;
  FirstName!: string;
  SecondName!: string;
  Phone!: string;
}

export class Company {
  Id!: Guid;
  Name!: string;
  Description!: string;
  Country!: Countries;
  Address!: string;
  Email!: string;
}

export enum ConnectionTypeNames {
  MQTT,
}

export class ConnectionType {
  Id!: Guid;
  Name!: ConnectionTypeNames;
}

export class Connection {
  Id!: Guid;
  CompanyId!: Guid;
  CompanyAdminId!: Guid;
  PrinterId!: Guid;
  ClientId!: string;
  ConnectionTypeId!: Guid;
  CreationDate!: Date;
  LastConnectTime!: Date;
  ShortTimeHashed!: string;
  Salt!: string;
  LongTimeHashed!: string;
}

export enum ErrorType {
  INFO = 0,
  WARNING = 1,
  ERROR = 2,
}

export class PrintHead {
  extruderPositions!: number[] | null;
  xPosition!: number;
  yPosition!: number;
  zPosition!: number;
}

export class Sensor {
  name!: string;
  value!: number;
}

export class Temperature {
  currents!: Sensor[];
  target!: number;
  index!: string;
}

export class Temperatures {
  buildplate!: Temperature;
  chamber!: Temperature;
  printheads!: Temperature[];
}

export class TelemetryItem {
  Time!: Date;
  State!: PrinterState;
  PrintHead!: PrintHead;
  CurrentTool!: string;
  Temperatures!: Temperatures;
  FanSpeeds!: number[];
  CurrentLayer!: number;
  PrintProgress!: number;
}

export class Telemetry {
  PrinterId!: Guid;
  CompanyId!: Guid;
  Items!: TelemetryItem[];
}

export class PrinterError {
  Id!: Guid;
  Time!: Date;
  ErrorType!: ErrorType;
  Message!: string;
  Acknowledged!: boolean;
}

export class PrinterErrors {
  PrinterId!: Guid;
  CompanyId!: Guid;
  Errors!: PrinterError[];
}

export class UserStat {
  id!: Guid;
  userId!: Guid;
  jobCountWeek!: number;
  jobCountMonth!: number;
  jobCountYear!: number;
  jobCountWhole!: number;
  sourceCount!: number;
  printTimeWeekS!: number;
  printTimeMonthS!: number;
  printTimeYearS!: number;
  printTimeWholeS!: number;
  plasticConsumedGr!: number;
  compositeConsumedM!: number;
}

export class CompanyStat {
  id!: Guid;
  companyId!: Guid;
  jobCountWeek!: number;
  jobCountMonth!: number;
  jobCountYear!: number;
  jobCountWhole!: number;
  sourceCount!: number;
  printTimeWeekS!: number;
  printTimeMonthS!: number;
  printTimeYearS!: number;
  printTimeWholeS!: number;
  plasticConsumedGr!: number;
  compositeConsumedM!: number;
  workloadAvg!: number;
  usersCount!: number;
  printersCount!: number;
}

export class PrinterStat {
  id!: Guid;
  printerId!: Guid;
  companyId!: Guid;
  jobCountWeek!: number;
  jobCountMonth!: number;
  jobCountYear!: number;
  jobCountWhole!: number;
  printTimeWeekS!: number;
  printTimeMonthS!: number;
  printTimeYearS!: number;
  printTimeWholeS!: number;
  plasticConsumedGr!: number;
  compositeConsumedM!: number;
  creationDate!: Date;
  workloadAvg!: number;
}

export class MaterialConsumptionPrinterStat {
  Id!: Guid;
  PrinterId!: Guid;
  CompanyId!: Guid;
  MaterialName!: string;
  MaterialType!: MaterialType;
  ConsumedWeek!: number;
  ConsumedMonth!: number;
  ConsumedYear!: number;
  ConsumedWhole!: number;
}

export class MaterialConsumptionCompanyStat {
  Id!: Guid;
  CompanyId!: Guid;
  MaterialName!: string;
  MaterialType!: MaterialType;
  ConsumedWeek!: number;
  ConsumedMonth!: number;
  ConsumedYear!: number;
  ConsumedWhole!: number;
}

export class TotalPrintTimeByWeekDayCompanyStat {
  Id!: Guid;
  CompanyId!: Guid;
  MondayTimeS!: number;
  TuesdayTimeS!: number;
  WednesdayTimeS!: number;
  ThursdayTimeS!: number;
  FridayTimeS!: number;
  SaturdayTimeS!: number;
  SundayTimeS!: number;
}

export class GlobalNotification {
  id!: Guid;
  userId!: Guid;
  message!: string;
  startShowing!: string;
  endShowing!: string;
}

export class Account {
  id!: Guid;
  name!: string;
  address!: string;
  country!: Countries;
  city!: string;
  state!: States | null;
  postalCode!: string;
  email!: string;
  stripeBalance!: number;
  activated!: boolean;
  taxIdType!: TaxIdType | null;
  taxId!: string | null;
}

export class AccountAdmin {
  id!: Guid;
  accountId!: Guid;
  isOwner!: boolean;
}

export class Invoice {
  id!: Guid;
  accountId!: Guid;
  subscriptionId!: Guid | null;
  status!: InvoiceStatus;
  invoiceNumber!: string | null;
  createdDate!: Date;
  currency!: string;
  totalAmount!: number;
  invoicePdfUrl!: string | null;
  stripePaymentIntentId!: string | null;
  paymentState!: PaymentState;
}

export class PaymentMethod {
  id!: Guid;
  accountId!: Guid;
  stripeId!: string;
  methodType!: PaymentMethodKind;
  expirationMonth!: number;
  expirationYear!: number;
  last4Digits!: string;
  isDefault!: boolean;
}

export class PendingSubscriptionUpdate {
  id!: Guid;
  subscriptionId!: Guid;
  newQuantity!: number | null;
  newProductPriceId!: Guid | null;
  stripeSubscriptionScheduleId!: string | null;
  invoiceId!: Guid | null;
  applyDate!: Date | null;
}

export class Product {
  id!: Guid;
  licenseType!: LicenseType;
  name!: string;
  description!: string;
  outdated!: boolean;
  isPublic!: boolean;
  baseProductId!: Guid | null;
  shortName!: string;
}

export class ProductPrice {
  id!: Guid;
  productId!: Guid;
  name!: string;
  paymentType!: PaymentType;
  recurringInterval!: RecurringIntervalKind | null;
  recurringIntervalCount!: number | null;
  currency!: string;
  price!: number;
  outdated!: boolean;
  isPublic!: boolean;
  metadata!: string;
  recurringLevel!: number | null;
}

export class PurchasedItem {
  id!: Guid;
  productPriceId!: Guid;
  accountId!: Guid;
  purchaseDate!: Date;
  status!: PurchaseStatus;
  baseItemId!: Guid | null;
  cryptlexId!: string | null;
  cryptlexKey!: string | null;
  activated!: boolean;
  deviceName!: string | null;
  purchasedSubscriptionId!: Guid | null;
  invoiceId!: Guid | null;
  deleted!: boolean;
  offlineActivationResponse!: string | null;
  offlineActivationResponseValidBefore!: Date | null;
}

export class PurchasedSubscription {
  id!: Guid;
  accoundId!: Guid;
  status!: PurchaseStatus;
  subscriptionStart!: Date;
  currentPeriodStart!: Date | null;
  currentPeriodEnd!: Date | null;
  cancelAtPeriodEnd!: boolean;
}

export class PreviewSubscriptionChanges {
  currentTotal!: number;
  previewTotal!: number;
  chargeNow!: number;

  currency!: string;
  prorationDate!: Date;
  nextAttempt!: Date | null;

  currentTotalTax!: number;
  previewTotalTax!: number;
  chargeNowTax!: number;
}

export class TaxRate {
  id!: Guid;
  country!: Countries;
  name!: string;
  inclusive!: boolean;
  state!: States | null;
  percentage!: number;
  type!: TaxType;
}
