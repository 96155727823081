import { Invoice } from '@/models/Entities';

export const SortInvoiceByNumberDESC: (a: Invoice, b: Invoice) => 1 | -1 = (a: Invoice, b: Invoice) => {
  return a.invoiceNumber! > b.invoiceNumber! ? -1 : 1;
};
export const SortInvoiceByNumberASC: (a: Invoice, b: Invoice) => 1 | -1 = (a: Invoice, b: Invoice) => {
  return a.invoiceNumber! > b.invoiceNumber! ? 1 : -1;
};

export const SortInvoiceByDateDESC: (a: Invoice, b: Invoice) => 1 | -1 = (a: Invoice, b: Invoice) => {
  return a.createdDate > b.createdDate ? -1 : 1;
};
export const SortInvoiceByDateASC: (a: Invoice, b: Invoice) => 1 | -1 = (a: Invoice, b: Invoice) => {
  return a.createdDate > b.createdDate ? 1 : -1;
};

export const SortInvoiceByStatusDESC: (a: Invoice, b: Invoice) => 1 | -1 = (a: Invoice, b: Invoice) => {
  return a.status > b.status ? -1 : 1;
};
export const SortInvoiceByStatusASC: (a: Invoice, b: Invoice) => 1 | -1 = (a: Invoice, b: Invoice) => {
  return a.status > b.status ? 1 : -1;
};

export const SortInvoiceBySumDESC: (a: Invoice, b: Invoice) => 1 | -1 = (a: Invoice, b: Invoice) => {
  return a.totalAmount > b.totalAmount ? -1 : 1;
};
export const SortInvoiceBySumASC: (a: Invoice, b: Invoice) => 1 | -1 = (a: Invoice, b: Invoice) => {
  return a.totalAmount > b.totalAmount ? 1 : -1;
};

export const SortInvoiceByIdDESC: (a: Invoice, b: Invoice) => 1 | -1 = (a: Invoice, b: Invoice) => {
  return a.id.toString() > b.id.toString() ? -1 : 1;
};
export const SortInvoiceByIdASC: (a: Invoice, b: Invoice) => 1 | -1 = (a: Invoice, b: Invoice) => {
  return a.id.toString() > b.id.toString() ? 1 : -1;
};
