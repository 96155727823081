export enum Countries {
  Afghanistan,
  Albania,
  Algeria,
  Andorra,
  Angola,
  AntiguaAndDeps,
  Argentina,
  Armenia,
  Australia,
  Austria,
  Azerbaijan,
  Bahamas,
  Bahrain,
  Bangladesh,
  Barbados,
  Belarus,
  Belgium,
  Belize,
  Benin,
  Bhutan,
  Bolivia,
  BosniaHerzegovina,
  Botswana,
  Brazil,
  Brunei,
  Bulgaria,
  Burkina,
  Burundi,
  Cambodia,
  Cameroon,
  Canada,
  CapeVerde,
  CentralfricanRep,
  Chad,
  Chile,
  China,
  Colombia,
  Comoros,
  Congo,
  DemocraticRepCostaRica,
  Croatia,
  Cuba,
  Cyprus,
  CzechRepublic,
  Denmark,
  Djibouti,
  Dominica,
  DominicanRepublic,
  EastTimor,
  Ecuador,
  Egypt,
  ElSalvador,
  EquatorialGuinea,
  Eritrea,
  Estonia,
  Ethiopia,
  Fiji,
  Finland,
  France,
  Gabon,
  Gambia,
  Georgia,
  Germany,
  Ghana,
  Greece,
  Grenada,
  Guatemala,
  Guinea,
  GuineaBissau,
  Guyana,
  Haiti,
  Honduras,
  Hungary,
  Iceland,
  India,
  Indonesia,
  Iran,
  Iraq,
  Ireland,
  Israel,
  Italy,
  IvoryCoast,
  Jamaica,
  Japan,
  Jordan,
  Kazakhstan,
  Kenya,
  Kiribati,
  KoreaNorth,
  KoreaSouth,
  Kosovo,
  Kuwait,
  Kyrgyzstan,
  Laos,
  Latvia,
  Lebanon,
  Lesotho,
  Liberia,
  Libya,
  Liechtenstein,
  Lithuania,
  Luxembourg,
  Macedonia,
  Madagascar,
  Malawi,
  Malaysia,
  Maldives,
  Mali,
  Malta,
  MarshallIslands,
  Mauritania,
  Mauritius,
  Mexico,
  Micronesia,
  Moldova,
  Monaco,
  Mongolia,
  Montenegro,
  Morocco,
  Mozambique,
  Myanmar,
  Namibia,
  Nauru,
  Nepal,
  Netherlands,
  NewZealand,
  Nicaragua,
  Niger,
  Nigeria,
  Norway,
  Oman,
  Pakistan,
  Palau,
  Panama,
  PapuaNewGuinea,
  Paraguay,
  Peru,
  Philippines,
  Poland,
  Portugal,
  Qatar,
  Romania,
  RussianFederation,
  Rwanda,
  StKittsNevis,
  StLucia,
  SaintVincenttheGrenadines,
  Samoa,
  SanMarino,
  SaoTomePrincipe,
  SaudiArabia,
  Senegal,
  Serbia,
  Seychelles,
  SierraLeone,
  Singapore,
  Slovakia,
  Slovenia,
  SolomonIslands,
  Somalia,
  SouthAfrica,
  SouthSudan,
  Spain,
  SriLanka,
  Sudan,
  Suriname,
  Swaziland,
  Sweden,
  Switzerland,
  Syria,
  Taiwan,
  Tajikistan,
  Tanzania,
  Thailand,
  Togo,
  Tonga,
  TrinidadTobago,
  Tunisia,
  Turkey,
  Turkmenistan,
  Tuvalu,
  Uganda,
  Ukraine,
  UnitedArabEmirates,
  UnitedKingdom,
  UnitedStates,
  Uruguay,
  Uzbekistan,
  Vanuatu,
  VaticanCity,
  Venezuela,
  Vietnam,
  Yemen,
  Zambia,
  Zimbabwe,
}

export enum States {
  AD_07,
  AD_02,
  AD_03,
  AD_08,
  AD_04,
  AD_05,
  AD_06,
  AE_AJ,
  AE_AZ,
  AE_FU,
  AE_SH,
  AE_DU,
  AE_RK,
  AE_UQ,
  AF_BDG,
  AF_BGL,
  AF_BAL,
  AF_BAM,
  AF_DAY,
  AF_FRA,
  AF_FYB,
  AF_GHA,
  AF_GHO,
  AF_HEL,
  AF_HER,
  AF_JOW,
  AF_KAB,
  AF_KAN,
  AF_KHO,
  AF_KDZ,
  AF_LAG,
  AF_LOG,
  AF_NAN,
  AF_NIM,
  AF_PIA,
  AF_PAN,
  AF_PAR,
  AF_SAR,
  AF_TAK,
  AF_URU,
  AG_10,
  AG_11,
  AG_03,
  AG_04,
  AG_06,
  AG_07,
  AG_08,
  AL_01,
  AL_09,
  AL_02,
  AL_03,
  AL_04,
  AL_05,
  AL_06,
  AL_07,
  AL_08,
  AL_10,
  AL_11,
  AL_12,
  AM_AG,
  AM_AR,
  AM_AV,
  AM_ER,
  AM_GR,
  AM_KT,
  AM_LO,
  AM_SH,
  AM_SU,
  AM_TV,
  AM_VD,
  AO_BGO,
  AO_BGU,
  AO_BIE,
  AO_CAB,
  AO_CCU,
  AO_CNO,
  AO_CUS,
  AO_CNN,
  AO_HUA,
  AO_HUI,
  AO_LUA,
  AO_LNO,
  AO_LSU,
  AO_MAL,
  AO_MOX,
  AO_NAM,
  AO_UIG,
  AO_ZAI,
  AR_B,
  AR_K,
  AR_H,
  AR_U,
  AR_C,
  AR_X,
  AR_W,
  AR_E,
  AR_P,
  AR_Y,
  AR_L,
  AR_F,
  AR_M,
  AR_N,
  AR_Q,
  AR_R,
  AR_A,
  AR_J,
  AR_D,
  AR_Z,
  AR_S,
  AR_G,
  AR_V,
  AR_T,
  AT_1,
  AT_2,
  AT_3,
  AT_4,
  AT_5,
  AT_6,
  AT_7,
  AT_8,
  AT_9,
  AU_ACT,
  AU_NSW,
  AU_NT,
  AU_QLD,
  AU_SA,
  AU_TAS,
  AU_VIC,
  AU_WA,
  AZ_ABS,
  AZ_AGC,
  AZ_AGU,
  AZ_BA,
  AZ_BAL,
  AZ_BAR,
  AZ_BEY,
  AZ_BIL,
  AZ_CAL,
  AZ_FUZ,
  AZ_GAD,
  AZ_GA,
  AZ_GOR,
  AZ_GOY,
  AZ_GYG,
  AZ_IMI,
  AZ_ISM,
  AZ_KUR,
  AZ_LA,
  AZ_MAS,
  AZ_MI,
  AZ_NA,
  AZ_NX,
  AZ_NEF,
  AZ_OGU,
  AZ_QAB,
  AZ_QAX,
  AZ_QAZ,
  AZ_QBA,
  AZ_QUS,
  AZ_SAT,
  AZ_SAB,
  AZ_SAK,
  AZ_SAL,
  AZ_SMI,
  AZ_SKR,
  AZ_SMX,
  AZ_SR,
  AZ_SIY,
  AZ_SM,
  AZ_TAR,
  AZ_XAC,
  AZ_XVD,
  AZ_YAR,
  AZ_YEV,
  AZ_ZAQ,
  AZ_ZAR,
  BA_BRC,
  BA_BIH,
  BA_SRP,
  BB_01,
  BB_02,
  BB_03,
  BB_04,
  BB_05,
  BB_07,
  BB_08,
  BB_09,
  BB_10,
  BB_11,
  BD_A,
  BD_B,
  BD_C,
  BD_D,
  BD_E,
  BD_F,
  BD_G,
  BE_VAN,
  BE_WBR,
  BE_BRU,
  BE_WHT,
  BE_WLG,
  BE_VLI,
  BE_WLX,
  BE_WNA,
  BE_VOV,
  BE_VBR,
  BE_VWV,
  BF_BAM,
  BF_BAZ,
  BF_BLG,
  BF_BLK,
  BF_COM,
  BF_GAN,
  BF_GNA,
  BF_GOU,
  BF_HOU,
  BF_KAD,
  BF_KEN,
  BF_KMP,
  BF_KOS,
  BF_KOT,
  BF_KOW,
  BF_LER,
  BF_LOR,
  BF_MOU,
  BF_NAO,
  BF_NAM,
  BF_NAY,
  BF_OUB,
  BF_OUD,
  BF_PAS,
  BF_PON,
  BF_SNG,
  BF_SMT,
  BF_SEN,
  BF_SIS,
  BF_SOM,
  BF_SOR,
  BF_TAP,
  BF_TUI,
  BF_YAT,
  BF_ZIR,
  BF_ZON,
  BF_ZOU,
  BG_01,
  BG_02,
  BG_08,
  BG_07,
  BG_26,
  BG_09,
  BG_10,
  BG_11,
  BG_12,
  BG_13,
  BG_14,
  BG_15,
  BG_16,
  BG_17,
  BG_18,
  BG_27,
  BG_19,
  BG_20,
  BG_21,
  BG_23,
  BG_22,
  BG_24,
  BG_25,
  BG_03,
  BG_04,
  BG_05,
  BG_06,
  BG_28,
  BH_13,
  BH_14,
  BH_15,
  BH_17,
  BI_BM,
  BI_CI,
  BI_GI,
  BI_KI,
  BI_MY,
  BI_MW,
  BI_NG,
  BI_RM,
  BI_RT,
  BI_RY,
  BJ_AK,
  BJ_AQ,
  BJ_BO,
  BJ_CO,
  BJ_DO,
  BJ_LI,
  BJ_MO,
  BJ_OU,
  BJ_PL,
  BJ_ZO,
  BN_BE,
  BN_BM,
  BN_TE,
  BN_TU,
  BO_H,
  BO_C,
  BO_B,
  BO_L,
  BO_O,
  BO_N,
  BO_P,
  BO_S,
  BO_T,
  BQ_BO,
  BQ_SA,
  BQ_SE,
  BR_AC,
  BR_AL,
  BR_AP,
  BR_AM,
  BR_BA,
  BR_CE,
  BR_DF,
  BR_ES,
  BR_GO,
  BR_MA,
  BR_MT,
  BR_MS,
  BR_MG,
  BR_PA,
  BR_PB,
  BR_PR,
  BR_PE,
  BR_PI,
  BR_RN,
  BR_RS,
  BR_RJ,
  BR_RO,
  BR_RR,
  BR_SC,
  BR_SP,
  BR_SE,
  BR_TO,
  BS_BP,
  BS_CO,
  BS_FP,
  BS_EG,
  BS_HI,
  BS_LI,
  BS_NP,
  BS_NO,
  BS_NS,
  BS_NE,
  BS_SE,
  BS_WG,
  BT_33,
  BT_12,
  BT_22,
  BT_GA,
  BT_44,
  BT_42,
  BT_11,
  BT_43,
  BT_23,
  BT_45,
  BT_14,
  BT_31,
  BT_15,
  BT_41,
  BT_32,
  BT_21,
  BT_24,
  BW_CE,
  BW_CH,
  BW_GH,
  BW_KG,
  BW_KL,
  BW_KW,
  BW_NE,
  BW_NW,
  BW_SE,
  BW_SO,
  BY_BR,
  BY_HO,
  BY_HM,
  BY_HR,
  BY_MA,
  BY_MI,
  BY_VI,
  BZ_BZ,
  BZ_CY,
  BZ_CZL,
  BZ_OW,
  BZ_SC,
  BZ_TOL,
  CA_AB,
  CA_BC,
  CA_MB,
  CA_NB,
  CA_NL,
  CA_NT,
  CA_NS,
  CA_NU,
  CA_ON,
  CA_PE,
  CA_QC,
  CA_SK,
  CA_YT,
  CD_EQ,
  CD_HK,
  CD_HL,
  CD_IT,
  CD_KC,
  CD_KE,
  CD_KN,
  CD_BC,
  CD_KG,
  CD_KL,
  CD_LU,
  CD_NK,
  CD_SA,
  CD_SK,
  CD_TA,
  CD_TO,
  CD_TU,
  CF_BB,
  CF_BGF,
  CF_KB,
  CF_KG,
  CF_NM,
  CF_AC,
  CF_OP,
  CG_BZV,
  CG_8,
  CG_9,
  CG_16,
  CG_13,
  CH_AG,
  CH_AR,
  CH_AI,
  CH_BL,
  CH_BS,
  CH_BE,
  CH_FR,
  CH_GE,
  CH_GL,
  CH_GR,
  CH_JU,
  CH_LU,
  CH_NE,
  CH_NW,
  CH_OW,
  CH_SG,
  CH_SH,
  CH_SZ,
  CH_SO,
  CH_TG,
  CH_TI,
  CH_UR,
  CH_VS,
  CH_VD,
  CH_ZG,
  CH_ZH,
  CI_AB,
  CI_BS,
  CI_CM,
  CI_DN,
  CI_GD,
  CI_LC,
  CI_LG,
  CI_MG,
  CI_SM,
  CI_SV,
  CI_VB,
  CI_WR,
  CI_YM,
  CI_ZZ,
  CL_AI,
  CL_AN,
  CL_AP,
  CL_AT,
  CL_BI,
  CL_CO,
  CL_AR,
  CL_LI,
  CL_LL,
  CL_LR,
  CL_MA,
  CL_ML,
  CL_NB,
  CL_RM,
  CL_TA,
  CL_VS,
  CM_AD,
  CM_CE,
  CM_ES,
  CM_EN,
  CM_LT,
  CM_NO,
  CM_NW,
  CM_OU,
  CM_SU,
  CM_SW,
  CN_AH,
  CN_BJ,
  CN_CQ,
  CN_FJ,
  CN_GS,
  CN_GD,
  CN_GX,
  CN_GZ,
  CN_HI,
  CN_HE,
  CN_HL,
  CN_HA,
  CN_HB,
  CN_HN,
  CN_JS,
  CN_JX,
  CN_JL,
  CN_LN,
  CN_NM,
  CN_NX,
  CN_QH,
  CN_SN,
  CN_SD,
  CN_SH,
  CN_SX,
  CN_SC,
  CN_TJ,
  CN_XJ,
  CN_XZ,
  CN_YN,
  CN_ZJ,
  CO_AMA,
  CO_ANT,
  CO_ARA,
  CO_ATL,
  CO_BOL,
  CO_BOY,
  CO_CAL,
  CO_CAQ,
  CO_CAS,
  CO_CAU,
  CO_CES,
  CO_CHO,
  CO_COR,
  CO_CUN,
  CO_DC,
  CO_GUA,
  CO_GUV,
  CO_HUI,
  CO_LAG,
  CO_MAG,
  CO_MET,
  CO_NAR,
  CO_NSA,
  CO_PUT,
  CO_QUI,
  CO_RIS,
  CO_SAP,
  CO_SAN,
  CO_SUC,
  CO_TOL,
  CO_VAC,
  CO_VID,
  CR_A,
  CR_C,
  CR_G,
  CR_H,
  CR_L,
  CR_P,
  CR_SJ,
  CU_15,
  CU_09,
  CU_08,
  CU_06,
  CU_12,
  CU_14,
  CU_11,
  CU_03,
  CU_10,
  CU_04,
  CU_16,
  CU_01,
  CU_07,
  CU_13,
  CU_05,
  CV_BV,
  CV_BR,
  CV_MO,
  CV_PN,
  CV_PR,
  CV_RS,
  CV_SL,
  CV_CR,
  CV_SO,
  CV_SV,
  CV_TA,
  CV_TS,
  CY_04,
  CY_06,
  CY_03,
  CY_01,
  CY_02,
  CY_05,
  CZ_31,
  CZ_64,
  CZ_41,
  CZ_63,
  CZ_52,
  CZ_51,
  CZ_80,
  CZ_71,
  CZ_53,
  CZ_32,
  CZ_10,
  CZ_20,
  CZ_42,
  CZ_72,
  DE_BW,
  DE_BY,
  DE_BE,
  DE_BB,
  DE_HB,
  DE_HH,
  DE_HE,
  DE_MV,
  DE_NI,
  DE_NW,
  DE_RP,
  DE_SL,
  DE_SN,
  DE_ST,
  DE_SH,
  DE_TH,
  DJ_AR,
  DJ_DJ,
  DK_84,
  DK_82,
  DK_81,
  DK_85,
  DK_83,
  DM_02,
  DM_04,
  DM_05,
  DM_07,
  DM_09,
  DM_10,
  DO_02,
  DO_03,
  DO_04,
  DO_05,
  DO_01,
  DO_06,
  DO_08,
  DO_07,
  DO_09,
  DO_30,
  DO_19,
  DO_10,
  DO_11,
  DO_12,
  DO_13,
  DO_14,
  DO_28,
  DO_15,
  DO_29,
  DO_17,
  DO_18,
  DO_20,
  DO_21,
  DO_31,
  DO_22,
  DO_23,
  DO_24,
  DO_25,
  DO_26,
  DO_27,
  DZ_01,
  DZ_44,
  DZ_46,
  DZ_16,
  DZ_23,
  DZ_05,
  DZ_08,
  DZ_06,
  DZ_07,
  DZ_09,
  DZ_34,
  DZ_10,
  DZ_35,
  DZ_02,
  DZ_25,
  DZ_17,
  DZ_32,
  DZ_39,
  DZ_36,
  DZ_47,
  DZ_24,
  DZ_33,
  DZ_18,
  DZ_40,
  DZ_03,
  DZ_28,
  DZ_29,
  DZ_26,
  DZ_43,
  DZ_27,
  DZ_45,
  DZ_31,
  DZ_30,
  DZ_04,
  DZ_48,
  DZ_20,
  DZ_19,
  DZ_22,
  DZ_21,
  DZ_41,
  DZ_11,
  DZ_12,
  DZ_14,
  DZ_37,
  DZ_42,
  DZ_38,
  DZ_15,
  DZ_13,
  EC_A,
  EC_B,
  EC_F,
  EC_C,
  EC_H,
  EC_X,
  EC_O,
  EC_E,
  EC_W,
  EC_G,
  EC_I,
  EC_L,
  EC_R,
  EC_M,
  EC_S,
  EC_N,
  EC_D,
  EC_Y,
  EC_P,
  EC_SE,
  EC_SD,
  EC_U,
  EC_T,
  EC_Z,
  EE_37,
  EE_39,
  EE_45,
  EE_52,
  EE_50,
  EE_60,
  EE_56,
  EE_68,
  EE_64,
  EE_71,
  EE_74,
  EE_79,
  EE_81,
  EE_84,
  EE_87,
  EG_DK,
  EG_BA,
  EG_BH,
  EG_FYM,
  EG_GH,
  EG_ALX,
  EG_IS,
  EG_GZ,
  EG_MNF,
  EG_MN,
  EG_C,
  EG_KB,
  EG_LX,
  EG_WAD,
  EG_SUZ,
  EG_SHR,
  EG_ASN,
  EG_AST,
  EG_BNS,
  EG_PTS,
  EG_DT,
  EG_JS,
  EG_KFS,
  EG_MT,
  EG_KN,
  EG_SIN,
  EG_SHG,
  ER_MA,
  ER_DK,
  ES_AN,
  ES_AR,
  ES_AS,
  ES_CN,
  ES_CB,
  ES_CL,
  ES_CM,
  ES_CT,
  ES_CE,
  ES_EX,
  ES_GA,
  ES_IB,
  ES_RI,
  ES_MD,
  ES_ML,
  ES_MC,
  ES_NC,
  ES_PV,
  ES_VC,
  ET_AA,
  ET_AF,
  ET_AM,
  ET_BE,
  ET_DD,
  ET_GA,
  ET_HA,
  ET_OR,
  ET_SO,
  ET_TI,
  ET_SN,
  FI_02,
  FI_03,
  FI_04,
  FI_05,
  FI_06,
  FI_07,
  FI_08,
  FI_09,
  FI_10,
  FI_16,
  FI_11,
  FI_12,
  FI_13,
  FI_14,
  FI_15,
  FI_17,
  FI_18,
  FI_19,
  FJ_C,
  FJ_E,
  FJ_N,
  FJ_R,
  FJ_W,
  FM_TRK,
  FM_KSA,
  FM_PNI,
  FM_YAP,
  FR_ARA,
  FR_BFC,
  FR_BRE,
  FR_CVL,
  FR_20R,
  FR_GES,
  FR_HDF,
  FR_IDF,
  FR_NOR,
  FR_NAQ,
  FR_OCC,
  FR_PDL,
  FR_PAC,
  GA_1,
  GA_2,
  GA_4,
  GA_8,
  GA_9,
  GB_ENG,
  GB_NIR,
  GB_SCT,
  GB_WLS,
  GD_01,
  GD_02,
  GD_03,
  GD_04,
  GD_05,
  GD_06,
  GD_10,
  GE_AB,
  GE_AJ,
  GE_GU,
  GE_IM,
  GE_KA,
  GE_KK,
  GE_MM,
  GE_RL,
  GE_SZ,
  GE_SJ,
  GE_SK,
  GE_TB,
  GH_AF,
  GH_AH,
  GH_BO,
  GH_BE,
  GH_CP,
  GH_EP,
  GH_AA,
  GH_NP,
  GH_UE,
  GH_UW,
  GH_TV,
  GH_WP,
  GL_AV,
  GL_KU,
  GL_QT,
  GL_SM,
  GL_QE,
  GM_B,
  GM_M,
  GM_L,
  GM_N,
  GM_U,
  GM_W,
  GN_BF,
  GN_B,
  GN_C,
  GN_CO,
  GN_DB,
  GN_DU,
  GN_K,
  GN_L,
  GN_LA,
  GN_MC,
  GN_N,
  GN_SI,
  GQ_BN,
  GQ_KN,
  GQ_LI,
  GQ_WN,
  GR_A,
  GR_I,
  GR_G,
  GR_C,
  GR_F,
  GR_D,
  GR_B,
  GR_M,
  GR_L,
  GR_J,
  GR_H,
  GR_E,
  GR_K,
  GT_16,
  GT_15,
  GT_04,
  GT_20,
  GT_02,
  GT_05,
  GT_01,
  GT_13,
  GT_18,
  GT_21,
  GT_22,
  GT_17,
  GT_09,
  GT_14,
  GT_11,
  GT_03,
  GT_12,
  GT_06,
  GT_07,
  GT_10,
  GT_08,
  GT_19,
  GW_BS,
  GW_GA,
  GY_BA,
  GY_CU,
  GY_DE,
  GY_EB,
  GY_ES,
  GY_MA,
  GY_PT,
  GY_UD,
  HN_AT,
  HN_CH,
  HN_CL,
  HN_CM,
  HN_CP,
  HN_CR,
  HN_EP,
  HN_FM,
  HN_GD,
  HN_IN,
  HN_IB,
  HN_LP,
  HN_LE,
  HN_OC,
  HN_OL,
  HN_SB,
  HN_VA,
  HN_YO,
  HR_07,
  HR_12,
  HR_19,
  HR_21,
  HR_18,
  HR_04,
  HR_06,
  HR_02,
  HR_09,
  HR_20,
  HR_14,
  HR_11,
  HR_08,
  HR_15,
  HR_03,
  HR_17,
  HR_05,
  HR_10,
  HR_16,
  HR_13,
  HR_01,
  HT_AR,
  HT_CE,
  HT_GA,
  HT_NI,
  HT_ND,
  HT_NE,
  HT_OU,
  HT_SD,
  HT_SE,
  HU_BK,
  HU_BA,
  HU_BE,
  HU_BZ,
  HU_BU,
  HU_CS,
  HU_FE,
  HU_GS,
  HU_HB,
  HU_HE,
  HU_JN,
  HU_KE,
  HU_NO,
  HU_PE,
  HU_SO,
  HU_SZ,
  HU_TO,
  HU_VA,
  HU_VE,
  HU_ZA,
  ID_AC,
  ID_BA,
  ID_BT,
  ID_BE,
  ID_GO,
  ID_JK,
  ID_JA,
  ID_JB,
  ID_JT,
  ID_JI,
  ID_KB,
  ID_KS,
  ID_KT,
  ID_KI,
  ID_KU,
  ID_BB,
  ID_KR,
  ID_LA,
  ID_ML,
  ID_MU,
  ID_NB,
  ID_NT,
  ID_PP,
  ID_PB,
  ID_RI,
  ID_SR,
  ID_SN,
  ID_ST,
  ID_SG,
  ID_SA,
  ID_SB,
  ID_SS,
  ID_SU,
  ID_YO,
  IE_CW,
  IE_CN,
  IE_CE,
  IE_CO,
  IE_DL,
  IE_D,
  IE_G,
  IE_KY,
  IE_KE,
  IE_KK,
  IE_LS,
  IE_LM,
  IE_LK,
  IE_LD,
  IE_LH,
  IE_MO,
  IE_MH,
  IE_MN,
  IE_OY,
  IE_RN,
  IE_SO,
  IE_TA,
  IE_WD,
  IE_WH,
  IE_WX,
  IE_WW,
  IL_D,
  IL_M,
  IL_Z,
  IL_HA,
  IL_TA,
  IL_JM,
  IN_AN,
  IN_AP,
  IN_AR,
  IN_AS,
  IN_BR,
  IN_CH,
  IN_CT,
  IN_DN,
  IN_DH,
  IN_DL,
  IN_GA,
  IN_GJ,
  IN_HR,
  IN_HP,
  IN_JK,
  IN_JH,
  IN_KA,
  IN_KL,
  IN_LD,
  IN_MP,
  IN_MH,
  IN_MN,
  IN_ML,
  IN_MZ,
  IN_NL,
  IN_OR,
  IN_PY,
  IN_PB,
  IN_RJ,
  IN_SK,
  IN_TN,
  IN_TG,
  IN_TR,
  IN_UP,
  IN_UT,
  IN_WB,
  IQ_AN,
  IQ_BA,
  IQ_MU,
  IQ_QA,
  IQ_NA,
  IQ_AR,
  IQ_SU,
  IQ_BB,
  IQ_BG,
  IQ_DA,
  IQ_DQ,
  IQ_DI,
  IQ_KA,
  IQ_KI,
  IQ_MA,
  IQ_NI,
  IQ_SD,
  IQ_WA,
  IR_30,
  IR_24,
  IR_04,
  IR_03,
  IR_18,
  IR_14,
  IR_10,
  IR_07,
  IR_01,
  IR_27,
  IR_13,
  IR_22,
  IR_16,
  IR_08,
  IR_05,
  IR_29,
  IR_09,
  IR_28,
  IR_06,
  IR_17,
  IR_12,
  IR_15,
  IR_00,
  IR_02,
  IR_26,
  IR_25,
  IR_20,
  IR_11,
  IR_23,
  IR_21,
  IR_19,
  IS_7,
  IS_1,
  IS_6,
  IS_5,
  IS_8,
  IS_2,
  IS_4,
  IS_3,
  IT_65,
  IT_77,
  IT_78,
  IT_72,
  IT_45,
  IT_36,
  IT_62,
  IT_42,
  IT_25,
  IT_57,
  IT_67,
  IT_21,
  IT_75,
  IT_88,
  IT_82,
  IT_52,
  IT_32,
  IT_55,
  IT_23,
  IT_34,
  JM_13,
  JM_09,
  JM_01,
  JM_12,
  JM_04,
  JM_02,
  JM_06,
  JM_14,
  JM_11,
  JM_08,
  JM_05,
  JM_03,
  JM_07,
  JM_10,
  JO_AJ,
  JO_AQ,
  JO_AM,
  JO_BA,
  JO_KA,
  JO_MA,
  JO_AT,
  JO_AZ,
  JO_IR,
  JO_JA,
  JO_MN,
  JO_MD,
  JP_23,
  JP_05,
  JP_02,
  JP_12,
  JP_38,
  JP_18,
  JP_40,
  JP_07,
  JP_21,
  JP_10,
  JP_34,
  JP_01,
  JP_28,
  JP_08,
  JP_17,
  JP_03,
  JP_37,
  JP_46,
  JP_14,
  JP_39,
  JP_43,
  JP_26,
  JP_24,
  JP_04,
  JP_45,
  JP_20,
  JP_42,
  JP_29,
  JP_15,
  JP_44,
  JP_33,
  JP_47,
  JP_27,
  JP_41,
  JP_11,
  JP_25,
  JP_32,
  JP_22,
  JP_09,
  JP_36,
  JP_13,
  JP_31,
  JP_16,
  JP_30,
  JP_06,
  JP_35,
  JP_19,
  KE_01,
  KE_02,
  KE_03,
  KE_04,
  KE_05,
  KE_06,
  KE_07,
  KE_08,
  KE_09,
  KE_10,
  KE_11,
  KE_12,
  KE_13,
  KE_14,
  KE_15,
  KE_16,
  KE_17,
  KE_18,
  KE_19,
  KE_20,
  KE_21,
  KE_22,
  KE_23,
  KE_24,
  KE_25,
  KE_26,
  KE_27,
  KE_28,
  KE_29,
  KE_30,
  KE_31,
  KE_32,
  KE_33,
  KE_34,
  KE_35,
  KE_36,
  KE_37,
  KE_38,
  KE_39,
  KE_40,
  KE_41,
  KE_42,
  KE_43,
  KE_44,
  KE_45,
  KE_46,
  KE_47,
  KG_B,
  KG_GB,
  KG_C,
  KG_J,
  KG_N,
  KG_GO,
  KG_T,
  KG_Y,
  KH_2,
  KH_1,
  KH_23,
  KH_3,
  KH_4,
  KH_5,
  KH_6,
  KH_7,
  KH_8,
  KH_10,
  KH_11,
  KH_24,
  KH_12,
  KH_15,
  KH_18,
  KH_14,
  KH_16,
  KH_17,
  KH_19,
  KH_20,
  KH_21,
  KI_G,
  KM_G,
  KN_01,
  KN_02,
  KN_03,
  KN_05,
  KN_06,
  KN_07,
  KN_08,
  KN_09,
  KN_10,
  KN_11,
  KN_12,
  KN_13,
  KN_15,
  KP_01,
  KR_26,
  KR_43,
  KR_44,
  KR_27,
  KR_30,
  KR_42,
  KR_29,
  KR_41,
  KR_47,
  KR_48,
  KR_28,
  KR_49,
  KR_45,
  KR_46,
  KR_11,
  KR_31,
  KW_KU,
  KW_AH,
  KW_FA,
  KW_JA,
  KW_HA,
  KW_MU,
  KZ_ALA,
  KZ_ALM,
  KZ_AKM,
  KZ_AKT,
  KZ_ATY,
  KZ_ZAP,
  KZ_MAN,
  KZ_AST,
  KZ_YUZ,
  KZ_PAV,
  KZ_KAR,
  KZ_KUS,
  KZ_KZY,
  KZ_VOS,
  KZ_SHY,
  KZ_SEV,
  KZ_ZHA,
  LA_BL,
  LA_CH,
  LA_KH,
  LA_OU,
  LA_PH,
  LA_SV,
  LA_VI,
  LA_XA,
  LA_XE,
  LA_XI,
  LB_AK,
  LB_BH,
  LB_BI,
  LB_BA,
  LB_AS,
  LB_JA,
  LB_JL,
  LB_NA,
  LC_01,
  LC_02,
  LC_03,
  LC_05,
  LC_06,
  LC_07,
  LC_08,
  LC_10,
  LC_11,
  LI_01,
  LI_02,
  LI_03,
  LI_04,
  LI_05,
  LI_06,
  LI_07,
  LI_09,
  LI_10,
  LI_11,
  LK_2,
  LK_5,
  LK_7,
  LK_6,
  LK_4,
  LK_9,
  LK_3,
  LK_8,
  LK_1,
  LR_BM,
  LR_GB,
  LR_GG,
  LR_MG,
  LR_MO,
  LR_SI,
  LS_D,
  LS_B,
  LS_C,
  LS_E,
  LS_A,
  LS_F,
  LS_J,
  LS_H,
  LS_G,
  LS_K,
  LT_AL,
  LT_KU,
  LT_KL,
  LT_MR,
  LT_PN,
  LT_SA,
  LT_TA,
  LT_TE,
  LT_UT,
  LT_VL,
  LU_CA,
  LU_CL,
  LU_DI,
  LU_EC,
  LU_ES,
  LU_GR,
  LU_LU,
  LU_ME,
  LU_RD,
  LU_RM,
  LU_VD,
  LU_WI,
  LV_011,
  LV_002,
  LV_007,
  LV_111,
  LV_015,
  LV_016,
  LV_022,
  LV_DGV,
  LV_112,
  LV_026,
  LV_033,
  LV_042,
  LV_JEL,
  LV_041,
  LV_JUR,
  LV_052,
  LV_047,
  LV_050,
  LV_LPX,
  LV_054,
  LV_056,
  LV_058,
  LV_059,
  LV_062,
  LV_067,
  LV_068,
  LV_073,
  LV_077,
  LV_RIX,
  LV_080,
  LV_087,
  LV_088,
  LV_089,
  LV_091,
  LV_094,
  LV_097,
  LV_099,
  LV_101,
  LV_113,
  LV_102,
  LV_106,
  LY_BU,
  LY_JA,
  LY_JG,
  LY_JI,
  LY_JU,
  LY_KF,
  LY_MJ,
  LY_MB,
  LY_WA,
  LY_NQ,
  LY_ZA,
  LY_BA,
  LY_DR,
  LY_MI,
  LY_NL,
  LY_SB,
  LY_SR,
  LY_TB,
  LY_WS,
  MA_05,
  MA_06,
  MA_08,
  MA_03,
  MA_10,
  MA_02,
  MA_11,
  MA_07,
  MA_04,
  MA_09,
  MA_01,
  MC_FO,
  MC_CO,
  MC_MO,
  MC_MC,
  MC_SR,
  MD_AN,
  MD_BA,
  MD_BS,
  MD_BD,
  MD_BR,
  MD_CA,
  MD_CL,
  MD_CT,
  MD_CS,
  MD_CU,
  MD_CM,
  MD_CR,
  MD_DO,
  MD_DR,
  MD_DU,
  MD_ED,
  MD_FA,
  MD_FL,
  MD_GA,
  MD_GL,
  MD_HI,
  MD_IA,
  MD_LE,
  MD_NI,
  MD_OC,
  MD_OR,
  MD_RE,
  MD_RI,
  MD_SI,
  MD_SD,
  MD_SO,
  MD_SV,
  MD_SN,
  MD_ST,
  MD_TA,
  MD_TE,
  MD_UN,
  ME_01,
  ME_02,
  ME_03,
  ME_04,
  ME_05,
  ME_06,
  ME_07,
  ME_08,
  ME_10,
  ME_12,
  ME_13,
  ME_14,
  ME_15,
  ME_16,
  ME_17,
  ME_19,
  ME_24,
  ME_20,
  ME_21,
  MG_T,
  MG_D,
  MG_F,
  MG_M,
  MG_A,
  MG_U,
  MH_KIL,
  MH_KWA,
  MH_MAJ,
  MK_802,
  MK_201,
  MK_501,
  MK_401,
  MK_601,
  MK_402,
  MK_602,
  MK_803,
  MK_109,
  MK_814,
  MK_210,
  MK_816,
  MK_303,
  MK_203,
  MK_502,
  MK_406,
  MK_503,
  MK_804,
  MK_405,
  MK_604,
  MK_102,
  MK_807,
  MK_606,
  MK_205,
  MK_104,
  MK_307,
  MK_809,
  MK_206,
  MK_701,
  MK_702,
  MK_505,
  MK_703,
  MK_704,
  MK_105,
  MK_207,
  MK_308,
  MK_607,
  MK_506,
  MK_106,
  MK_507,
  MK_408,
  MK_310,
  MK_208,
  MK_810,
  MK_311,
  MK_508,
  MK_209,
  MK_409,
  MK_705,
  MK_509,
  MK_107,
  MK_811,
  MK_812,
  MK_211,
  MK_312,
  MK_410,
  MK_813,
  MK_108,
  MK_608,
  MK_609,
  MK_403,
  MK_404,
  MK_101,
  MK_301,
  MK_202,
  MK_603,
  MK_806,
  MK_605,
  ML_BKO,
  ML_7,
  ML_1,
  ML_8,
  ML_2,
  ML_5,
  ML_4,
  ML_3,
  ML_6,
  MM_07,
  MM_02,
  MM_14,
  MM_11,
  MM_12,
  MM_03,
  MM_04,
  MM_15,
  MM_18,
  MM_16,
  MM_01,
  MM_17,
  MM_05,
  MM_06,
  MN_037,
  MN_061,
  MN_063,
  MN_065,
  MN_064,
  MN_043,
  MN_035,
  MN_055,
  MN_049,
  MN_047,
  MN_1,
  MR_07,
  MR_03,
  MR_05,
  MR_08,
  MR_04,
  MR_01,
  MR_02,
  MR_13,
  MR_09,
  MR_11,
  MR_06,
  MT_01,
  MT_02,
  MT_03,
  MT_04,
  MT_05,
  MT_06,
  MT_07,
  MT_08,
  MT_09,
  MT_10,
  MT_14,
  MT_15,
  MT_16,
  MT_17,
  MT_11,
  MT_12,
  MT_18,
  MT_19,
  MT_20,
  MT_21,
  MT_22,
  MT_23,
  MT_24,
  MT_25,
  MT_26,
  MT_27,
  MT_28,
  MT_29,
  MT_30,
  MT_31,
  MT_32,
  MT_33,
  MT_34,
  MT_35,
  MT_36,
  MT_37,
  MT_38,
  MT_39,
  MT_40,
  MT_41,
  MT_42,
  MT_43,
  MT_45,
  MT_46,
  MT_49,
  MT_48,
  MT_53,
  MT_51,
  MT_52,
  MT_54,
  MT_55,
  MT_56,
  MT_57,
  MT_58,
  MT_59,
  MT_60,
  MT_61,
  MT_62,
  MT_63,
  MT_64,
  MT_65,
  MT_67,
  MT_68,
  MU_BL,
  MU_FL,
  MU_GP,
  MU_MO,
  MU_PA,
  MU_PW,
  MU_PL,
  MU_RR,
  MU_RO,
  MU_SA,
  MV_01,
  MV_03,
  MV_04,
  MV_05,
  MV_MLE,
  MV_12,
  MV_13,
  MV_14,
  MV_00,
  MV_28,
  MV_20,
  MV_25,
  MV_17,
  MV_23,
  MW_BA,
  MW_BL,
  MW_CK,
  MW_CR,
  MW_DE,
  MW_DO,
  MW_KR,
  MW_LI,
  MW_MH,
  MW_MG,
  MW_MW,
  MW_MZ,
  MW_NE,
  MW_NK,
  MW_SA,
  MW_TH,
  MW_ZO,
  MX_AGU,
  MX_BCN,
  MX_BCS,
  MX_CAM,
  MX_CHP,
  MX_CHH,
  MX_CMX,
  MX_COA,
  MX_COL,
  MX_DUR,
  MX_GUA,
  MX_GRO,
  MX_HID,
  MX_JAL,
  MX_MEX,
  MX_MIC,
  MX_MOR,
  MX_NAY,
  MX_NLE,
  MX_OAX,
  MX_PUE,
  MX_QUE,
  MX_ROO,
  MX_SLP,
  MX_SIN,
  MX_SON,
  MX_TAB,
  MX_TAM,
  MX_TLA,
  MX_VER,
  MX_YUC,
  MX_ZAC,
  MY_01,
  MY_02,
  MY_03,
  MY_04,
  MY_05,
  MY_06,
  MY_08,
  MY_09,
  MY_07,
  MY_12,
  MY_13,
  MY_10,
  MY_11,
  MY_14,
  MY_15,
  MY_16,
  MZ_P,
  MZ_G,
  MZ_I,
  MZ_B,
  MZ_L,
  MZ_N,
  MZ_A,
  MZ_S,
  MZ_T,
  MZ_Q,
  NA_ER,
  NA_HA,
  NA_KA,
  NA_KE,
  NA_KH,
  NA_KU,
  NA_OW,
  NA_OH,
  NA_OS,
  NA_ON,
  NA_OT,
  NA_OD,
  NA_CA,
  NE_1,
  NE_2,
  NE_3,
  NE_4,
  NE_8,
  NE_5,
  NE_6,
  NE_7,
  NG_AB,
  NG_FC,
  NG_AD,
  NG_AK,
  NG_AN,
  NG_BA,
  NG_BY,
  NG_BE,
  NG_BO,
  NG_CR,
  NG_DE,
  NG_EB,
  NG_ED,
  NG_EK,
  NG_EN,
  NG_GO,
  NG_IM,
  NG_JI,
  NG_KD,
  NG_KN,
  NG_KT,
  NG_KE,
  NG_KO,
  NG_KW,
  NG_LA,
  NG_NA,
  NG_NI,
  NG_OG,
  NG_ON,
  NG_OS,
  NG_OY,
  NG_PL,
  NG_RI,
  NG_SO,
  NG_TA,
  NG_YO,
  NG_ZA,
  NI_BO,
  NI_CA,
  NI_CI,
  NI_CO,
  NI_AN,
  NI_AS,
  NI_ES,
  NI_GR,
  NI_JI,
  NI_LE,
  NI_MD,
  NI_MN,
  NI_MS,
  NI_MT,
  NI_NS,
  NI_SJ,
  NI_RI,
  NL_DR,
  NL_FL,
  NL_FR,
  NL_GE,
  NL_GR,
  NL_LI,
  NL_NB,
  NL_NH,
  NL_OV,
  NL_UT,
  NL_ZE,
  NL_ZH,
  NO_42,
  NO_34,
  NO_15,
  NO_18,
  NO_03,
  NO_11,
  NO_54,
  NO_50,
  NO_38,
  NO_46,
  NO_30,
  NP_BA,
  NP_BH,
  NP_DH,
  NP_GA,
  NP_JA,
  NP_KA,
  NP_KO,
  NP_LU,
  NP_MA,
  NP_ME,
  NP_NA,
  NP_RA,
  NP_SA,
  NP_SE,
  NR_01,
  NR_03,
  NR_14,
  NZ_AUK,
  NZ_BOP,
  NZ_CAN,
  NZ_CIT,
  NZ_GIS,
  NZ_HKB,
  NZ_MWT,
  NZ_MBH,
  NZ_NSN,
  NZ_NTL,
  NZ_OTA,
  NZ_STL,
  NZ_TKI,
  NZ_TAS,
  NZ_WKO,
  NZ_WGN,
  NZ_WTC,
  OM_DA,
  OM_BU,
  OM_WU,
  OM_ZA,
  OM_BJ,
  OM_SJ,
  OM_MA,
  OM_MU,
  OM_BS,
  OM_SS,
  OM_ZU,
  PA_1,
  PA_4,
  PA_2,
  PA_3,
  PA_5,
  PA_6,
  PA_7,
  PA_NB,
  PA_8,
  PA_9,
  PE_AMA,
  PE_ANC,
  PE_APU,
  PE_ARE,
  PE_AYA,
  PE_CAJ,
  PE_CUS,
  PE_CAL,
  PE_HUV,
  PE_HUC,
  PE_ICA,
  PE_JUN,
  PE_LAL,
  PE_LAM,
  PE_LIM,
  PE_LOR,
  PE_MDD,
  PE_MOQ,
  PE_PAS,
  PE_PIU,
  PE_PUN,
  PE_SAM,
  PE_TAC,
  PE_TUM,
  PE_UCA,
  PG_NSB,
  PG_CPM,
  PG_EBR,
  PG_ESW,
  PG_EHG,
  PG_MPM,
  PG_MRL,
  PG_MBA,
  PG_MPL,
  PG_NCD,
  PG_SHM,
  PG_WBK,
  PG_SAN,
  PG_WPD,
  PG_WHM,
  PH_ABR,
  PH_AGN,
  PH_AGS,
  PH_AKL,
  PH_ALB,
  PH_ANT,
  PH_APA,
  PH_AUR,
  PH_BAS,
  PH_BAN,
  PH_BTN,
  PH_BTG,
  PH_BEN,
  PH_BIL,
  PH_BOH,
  PH_BUK,
  PH_BUL,
  PH_CAG,
  PH_CAN,
  PH_CAS,
  PH_CAM,
  PH_CAP,
  PH_CAT,
  PH_CAV,
  PH_CEB,
  PH_NCO,
  PH_DAO,
  PH_COM,
  PH_DAV,
  PH_DAS,
  PH_DIN,
  PH_EAS,
  PH_GUI,
  PH_IFU,
  PH_ILN,
  PH_ILS,
  PH_ILI,
  PH_ISA,
  PH_KAL,
  PH_LUN,
  PH_LAG,
  PH_LAN,
  PH_LAS,
  PH_LEY,
  PH_MAG,
  PH_MAD,
  PH_MAS,
  PH_MDC,
  PH_MDR,
  PH_MSC,
  PH_MSR,
  PH_MOU,
  PH_00,
  PH_NEC,
  PH_NER,
  PH_NSA,
  PH_NUE,
  PH_NUV,
  PH_PLW,
  PH_PAM,
  PH_PAN,
  PH_QUE,
  PH_QUI,
  PH_RIZ,
  PH_ROM,
  PH_WSA,
  PH_SAR,
  PH_SIG,
  PH_SOR,
  PH_SCO,
  PH_SLE,
  PH_SUK,
  PH_SLU,
  PH_SUN,
  PH_SUR,
  PH_TAR,
  PH_TAW,
  PH_ZMB,
  PH_ZSI,
  PH_ZAN,
  PH_ZAS,
  PK_JK,
  PK_BA,
  PK_GB,
  PK_IS,
  PK_KP,
  PK_PB,
  PK_SD,
  PL_02,
  PL_04,
  PL_10,
  PL_06,
  PL_08,
  PL_12,
  PL_14,
  PL_16,
  PL_18,
  PL_20,
  PL_22,
  PL_24,
  PL_26,
  PL_28,
  PL_30,
  PL_32,
  PS_BTH,
  PS_DEB,
  PS_GZA,
  PS_HBN,
  PS_JEN,
  PS_JRH,
  PS_JEM,
  PS_KYS,
  PS_NBS,
  PS_QQA,
  PS_RFH,
  PS_RBH,
  PS_SLT,
  PS_TBS,
  PS_TKM,
  PT_01,
  PT_02,
  PT_03,
  PT_04,
  PT_05,
  PT_06,
  PT_07,
  PT_08,
  PT_09,
  PT_10,
  PT_11,
  PT_12,
  PT_13,
  PT_30,
  PT_20,
  PT_14,
  PT_15,
  PT_16,
  PT_17,
  PT_18,
  PW_004,
  PW_100,
  PW_150,
  PW_212,
  PW_214,
  PW_222,
  PY_10,
  PY_13,
  PY_ASU,
  PY_19,
  PY_5,
  PY_6,
  PY_14,
  PY_11,
  PY_1,
  PY_3,
  PY_4,
  PY_7,
  PY_8,
  PY_12,
  PY_9,
  PY_15,
  PY_2,
  QA_DA,
  QA_KH,
  QA_WA,
  QA_RA,
  QA_MS,
  QA_ZA,
  QA_US,
  RO_AB,
  RO_AR,
  RO_AG,
  RO_BC,
  RO_BH,
  RO_BN,
  RO_BT,
  RO_BR,
  RO_BV,
  RO_B,
  RO_BZ,
  RO_CL,
  RO_CS,
  RO_CJ,
  RO_CT,
  RO_CV,
  RO_DB,
  RO_DJ,
  RO_GL,
  RO_GR,
  RO_GJ,
  RO_HR,
  RO_HD,
  RO_IL,
  RO_IS,
  RO_IF,
  RO_MM,
  RO_MH,
  RO_MS,
  RO_NT,
  RO_OT,
  RO_PH,
  RO_SJ,
  RO_SM,
  RO_SB,
  RO_SV,
  RO_TR,
  RO_TM,
  RO_TL,
  RO_VL,
  RO_VS,
  RO_VN,
  RS_00,
  RS_14,
  RS_11,
  RS_23,
  RS_06,
  RS_04,
  RS_09,
  RS_28,
  RS_08,
  RS_17,
  RS_20,
  RS_24,
  RS_26,
  RS_22,
  RS_10,
  RS_13,
  RS_27,
  RS_19,
  RS_18,
  RS_01,
  RS_03,
  RS_02,
  RS_07,
  RS_12,
  RS_21,
  RS_15,
  RS_05,
  RS_16,
  RU_AD,
  RU_AL,
  RU_ALT,
  RU_AMU,
  RU_ARK,
  RU_AST,
  RU_BA,
  RU_BEL,
  RU_BRY,
  RU_BU,
  RU_CE,
  RU_CHE,
  RU_CHU,
  RU_CU,
  RU_DA,
  RU_IN,
  RU_IRK,
  RU_IVA,
  RU_KB,
  RU_KGD,
  RU_KL,
  RU_KLU,
  RU_KAM,
  RU_KC,
  RU_KR,
  RU_KEM,
  RU_KHA,
  RU_KK,
  RU_KHM,
  RU_KIR,
  RU_KO,
  RU_KOS,
  RU_KDA,
  RU_KYA,
  RU_KGN,
  RU_KRS,
  RU_LEN,
  RU_LIP,
  RU_MAG,
  RU_ME,
  RU_MO,
  RU_MOS,
  RU_MOW,
  RU_MUR,
  RU_NEN,
  RU_NIZ,
  RU_NGR,
  RU_NVS,
  RU_OMS,
  RU_ORE,
  RU_ORL,
  RU_PNZ,
  RU_PER,
  RU_PRI,
  RU_PSK,
  RU_ROS,
  RU_RYA,
  RU_SA,
  RU_SAK,
  RU_SAM,
  RU_SPE,
  RU_SAR,
  RU_SE,
  RU_SMO,
  RU_STA,
  RU_SVE,
  RU_TAM,
  RU_TA,
  RU_TOM,
  RU_TUL,
  RU_TVE,
  RU_TYU,
  RU_TY,
  RU_UD,
  RU_ULY,
  RU_VLA,
  RU_VGG,
  RU_VLG,
  RU_VOR,
  RU_YAN,
  RU_YAR,
  RU_YEV,
  RU_ZAB,
  RW_02,
  RW_03,
  RW_04,
  RW_05,
  RW_01,
  SA_14,
  SA_11,
  SA_08,
  SA_12,
  SA_03,
  SA_05,
  SA_01,
  SA_04,
  SA_06,
  SA_09,
  SA_02,
  SA_10,
  SA_07,
  SB_CH,
  SB_GU,
  SB_WE,
  SC_02,
  SC_05,
  SC_01,
  SC_06,
  SC_07,
  SC_08,
  SC_10,
  SC_11,
  SC_16,
  SC_13,
  SC_14,
  SC_15,
  SC_20,
  SC_23,
  SD_NB,
  SD_DC,
  SD_GD,
  SD_GZ,
  SD_KA,
  SD_KH,
  SD_DN,
  SD_KN,
  SD_NO,
  SD_RS,
  SD_NR,
  SD_SI,
  SD_DS,
  SD_KS,
  SD_DW,
  SD_GK,
  SD_NW,
  SE_K,
  SE_W,
  SE_X,
  SE_I,
  SE_N,
  SE_Z,
  SE_F,
  SE_H,
  SE_G,
  SE_BD,
  SE_T,
  SE_E,
  SE_M,
  SE_D,
  SE_AB,
  SE_C,
  SE_S,
  SE_AC,
  SE_Y,
  SE_U,
  SE_O,
  SH_HL,
  SI_001,
  SI_213,
  SI_195,
  SI_002,
  SI_148,
  SI_149,
  SI_003,
  SI_150,
  SI_004,
  SI_005,
  SI_006,
  SI_151,
  SI_007,
  SI_009,
  SI_008,
  SI_152,
  SI_011,
  SI_012,
  SI_013,
  SI_014,
  SI_196,
  SI_015,
  SI_017,
  SI_018,
  SI_019,
  SI_154,
  SI_020,
  SI_155,
  SI_021,
  SI_156,
  SI_023,
  SI_024,
  SI_025,
  SI_026,
  SI_207,
  SI_029,
  SI_031,
  SI_158,
  SI_032,
  SI_159,
  SI_160,
  SI_161,
  SI_162,
  SI_034,
  SI_035,
  SI_036,
  SI_037,
  SI_038,
  SI_039,
  SI_040,
  SI_041,
  SI_042,
  SI_043,
  SI_044,
  SI_045,
  SI_046,
  SI_047,
  SI_048,
  SI_049,
  SI_164,
  SI_050,
  SI_197,
  SI_165,
  SI_052,
  SI_053,
  SI_166,
  SI_054,
  SI_055,
  SI_056,
  SI_057,
  SI_058,
  SI_059,
  SI_060,
  SI_061,
  SI_063,
  SI_208,
  SI_064,
  SI_065,
  SI_066,
  SI_167,
  SI_067,
  SI_068,
  SI_069,
  SI_198,
  SI_070,
  SI_168,
  SI_071,
  SI_072,
  SI_073,
  SI_074,
  SI_169,
  SI_075,
  SI_212,
  SI_170,
  SI_076,
  SI_199,
  SI_077,
  SI_079,
  SI_080,
  SI_081,
  SI_082,
  SI_083,
  SI_084,
  SI_085,
  SI_086,
  SI_171,
  SI_087,
  SI_090,
  SI_091,
  SI_092,
  SI_172,
  SI_200,
  SI_173,
  SI_094,
  SI_174,
  SI_095,
  SI_175,
  SI_096,
  SI_097,
  SI_098,
  SI_099,
  SI_100,
  SI_101,
  SI_102,
  SI_103,
  SI_176,
  SI_209,
  SI_201,
  SI_104,
  SI_106,
  SI_105,
  SI_108,
  SI_033,
  SI_109,
  SI_183,
  SI_117,
  SI_118,
  SI_119,
  SI_120,
  SI_211,
  SI_110,
  SI_111,
  SI_121,
  SI_122,
  SI_123,
  SI_112,
  SI_113,
  SI_114,
  SI_124,
  SI_206,
  SI_125,
  SI_194,
  SI_179,
  SI_180,
  SI_126,
  SI_115,
  SI_127,
  SI_203,
  SI_204,
  SI_182,
  SI_116,
  SI_210,
  SI_205,
  SI_184,
  SI_010,
  SI_128,
  SI_129,
  SI_130,
  SI_185,
  SI_131,
  SI_186,
  SI_132,
  SI_133,
  SI_187,
  SI_134,
  SI_188,
  SI_135,
  SI_136,
  SI_137,
  SI_138,
  SI_139,
  SI_189,
  SI_140,
  SI_141,
  SI_142,
  SI_190,
  SI_143,
  SI_146,
  SI_191,
  SI_147,
  SI_144,
  SI_193,
  SK_BC,
  SK_BL,
  SK_KI,
  SK_NI,
  SK_PV,
  SK_TC,
  SK_TA,
  SK_ZI,
  SL_E,
  SL_N,
  SL_S,
  SL_W,
  SM_07,
  SM_03,
  SM_04,
  SM_09,
  SN_DK,
  SN_DB,
  SN_FK,
  SN_KA,
  SN_KL,
  SN_KD,
  SN_LG,
  SN_MT,
  SN_SL,
  SN_SE,
  SN_TC,
  SN_TH,
  SN_ZG,
  SO_AW,
  SO_BN,
  SO_BR,
  SO_HI,
  SO_JH,
  SO_MU,
  SO_NU,
  SO_SH,
  SO_TO,
  SO_WO,
  SR_BR,
  SR_CM,
  SR_NI,
  SR_PR,
  SR_PM,
  SR_SI,
  SR_WA,
  SS_EC,
  SS_EE,
  SS_JG,
  SS_LK,
  SS_BN,
  SS_NU,
  SS_EW,
  ST_01,
  SV_AH,
  SV_CA,
  SV_CH,
  SV_CU,
  SV_LI,
  SV_PA,
  SV_UN,
  SV_MO,
  SV_SM,
  SV_SS,
  SV_SV,
  SV_SA,
  SV_SO,
  SV_US,
  SY_HA,
  SY_LA,
  SY_QU,
  SY_RA,
  SY_SU,
  SY_DR,
  SY_DY,
  SY_DI,
  SY_HL,
  SY_HM,
  SY_HI,
  SY_ID,
  SY_RD,
  SY_TA,
  SZ_HH,
  SZ_LU,
  SZ_MA,
  TD_EE,
  TD_GR,
  TD_LO,
  TD_ME,
  TD_OD,
  TD_ND,
  TG_C,
  TG_K,
  TG_M,
  TG_P,
  TG_S,
  TH_37,
  TH_15,
  TH_38,
  TH_31,
  TH_24,
  TH_18,
  TH_36,
  TH_22,
  TH_50,
  TH_57,
  TH_20,
  TH_86,
  TH_46,
  TH_62,
  TH_71,
  TH_40,
  TH_81,
  TH_10,
  TH_52,
  TH_51,
  TH_42,
  TH_16,
  TH_58,
  TH_44,
  TH_49,
  TH_26,
  TH_73,
  TH_48,
  TH_30,
  TH_60,
  TH_80,
  TH_55,
  TH_96,
  TH_39,
  TH_43,
  TH_12,
  TH_13,
  TH_94,
  TH_82,
  TH_93,
  TH_56,
  TH_67,
  TH_76,
  TH_66,
  TH_65,
  TH_14,
  TH_54,
  TH_83,
  TH_25,
  TH_77,
  TH_85,
  TH_70,
  TH_21,
  TH_45,
  TH_27,
  TH_47,
  TH_11,
  TH_74,
  TH_75,
  TH_19,
  TH_91,
  TH_33,
  TH_17,
  TH_90,
  TH_64,
  TH_72,
  TH_84,
  TH_32,
  TH_63,
  TH_92,
  TH_23,
  TH_34,
  TH_41,
  TH_61,
  TH_53,
  TH_95,
  TH_35,
  TJ_DU,
  TJ_KT,
  TJ_GB,
  TJ_RA,
  TJ_SU,
  TL_AN,
  TL_BO,
  TL_CO,
  TL_DI,
  TM_A,
  TM_B,
  TM_D,
  TM_L,
  TM_M,
  TN_31,
  TN_13,
  TN_23,
  TN_81,
  TN_71,
  TN_32,
  TN_41,
  TN_42,
  TN_73,
  TN_12,
  TN_14,
  TN_33,
  TN_53,
  TN_82,
  TN_52,
  TN_21,
  TN_61,
  TN_43,
  TN_34,
  TN_51,
  TN_83,
  TN_72,
  TN_11,
  TN_22,
  TO_02,
  TO_03,
  TO_04,
  TR_01,
  TR_02,
  TR_03,
  TR_04,
  TR_68,
  TR_05,
  TR_06,
  TR_07,
  TR_75,
  TR_08,
  TR_09,
  TR_10,
  TR_74,
  TR_72,
  TR_69,
  TR_11,
  TR_12,
  TR_13,
  TR_14,
  TR_15,
  TR_16,
  TR_17,
  TR_18,
  TR_19,
  TR_20,
  TR_21,
  TR_81,
  TR_22,
  TR_23,
  TR_24,
  TR_25,
  TR_26,
  TR_27,
  TR_28,
  TR_29,
  TR_30,
  TR_31,
  TR_76,
  TR_32,
  TR_34,
  TR_35,
  TR_46,
  TR_78,
  TR_70,
  TR_36,
  TR_37,
  TR_38,
  TR_79,
  TR_71,
  TR_39,
  TR_40,
  TR_41,
  TR_42,
  TR_43,
  TR_44,
  TR_45,
  TR_47,
  TR_33,
  TR_48,
  TR_49,
  TR_50,
  TR_51,
  TR_52,
  TR_80,
  TR_53,
  TR_54,
  TR_55,
  TR_63,
  TR_56,
  TR_57,
  TR_73,
  TR_58,
  TR_59,
  TR_60,
  TR_61,
  TR_62,
  TR_64,
  TR_65,
  TR_77,
  TR_66,
  TR_67,
  TT_ARI,
  TT_CHA,
  TT_CTT,
  TT_DMN,
  TT_MRC,
  TT_PED,
  TT_PTF,
  TT_POS,
  TT_PRT,
  TT_SFO,
  TT_SJL,
  TT_SGE,
  TT_SIP,
  TT_TOB,
  TT_TUP,
  TV_FUN,
  TW_CHA,
  TW_CYQ,
  TW_HSQ,
  TW_HUA,
  TW_KHH,
  TW_KEE,
  TW_KIN,
  TW_LIE,
  TW_MIA,
  TW_NAN,
  TW_NWT,
  TW_PEN,
  TW_PIF,
  TW_TXG,
  TW_TNN,
  TW_TPE,
  TW_TTT,
  TW_TAO,
  TW_ILA,
  TW_YUN,
  TZ_01,
  TZ_02,
  TZ_03,
  TZ_27,
  TZ_04,
  TZ_05,
  TZ_06,
  TZ_07,
  TZ_28,
  TZ_08,
  TZ_09,
  TZ_11,
  TZ_12,
  TZ_26,
  TZ_13,
  TZ_14,
  TZ_15,
  TZ_16,
  TZ_17,
  TZ_18,
  TZ_29,
  TZ_19,
  TZ_21,
  TZ_22,
  TZ_30,
  TZ_23,
  TZ_31,
  TZ_24,
  TZ_25,
  UA_43,
  UA_71,
  UA_74,
  UA_77,
  UA_12,
  UA_14,
  UA_26,
  UA_63,
  UA_65,
  UA_68,
  UA_35,
  UA_30,
  UA_32,
  UA_09,
  UA_46,
  UA_48,
  UA_51,
  UA_53,
  UA_56,
  UA_40,
  UA_59,
  UA_61,
  UA_05,
  UA_07,
  UA_21,
  UA_23,
  UA_18,
  UG_314,
  UG_301,
  UG_323,
  UG_315,
  UG_324,
  UG_216,
  UG_316,
  UG_302,
  UG_303,
  UG_217,
  UG_218,
  UG_201,
  UG_420,
  UG_117,
  UG_219,
  UG_118,
  UG_220,
  UG_225,
  UG_401,
  UG_402,
  UG_202,
  UG_221,
  UG_120,
  UG_226,
  UG_317,
  UG_121,
  UG_304,
  UG_403,
  UG_417,
  UG_203,
  UG_418,
  UG_204,
  UG_318,
  UG_404,
  UG_405,
  UG_213,
  UG_101,
  UG_222,
  UG_122,
  UG_102,
  UG_205,
  UG_413,
  UG_206,
  UG_406,
  UG_207,
  UG_112,
  UG_407,
  UG_103,
  UG_227,
  UG_419,
  UG_421,
  UG_408,
  UG_305,
  UG_319,
  UG_306,
  UG_208,
  UG_228,
  UG_123,
  UG_422,
  UG_415,
  UG_326,
  UG_307,
  UG_229,
  UG_104,
  UG_124,
  UG_114,
  UG_223,
  UG_105,
  UG_409,
  UG_214,
  UG_209,
  UG_410,
  UG_423,
  UG_115,
  UG_308,
  UG_309,
  UG_106,
  UG_107,
  UG_108,
  UG_311,
  UG_116,
  UG_109,
  UG_230,
  UG_224,
  UG_327,
  UG_310,
  UG_231,
  UG_411,
  UG_328,
  UG_321,
  UG_312,
  UG_210,
  UG_110,
  UG_425,
  UG_412,
  UG_111,
  UG_232,
  UG_426,
  UG_215,
  UG_211,
  UG_212,
  UG_113,
  UG_313,
  UG_330,
  UM_95,
  US_AL,
  US_AK,
  US_AZ,
  US_AR,
  US_CA,
  US_CO,
  US_CT,
  US_DE,
  US_DC,
  US_FL,
  US_GA,
  US_HI,
  US_ID,
  US_IL,
  US_IN,
  US_IA,
  US_KS,
  US_KY,
  US_LA,
  US_ME,
  US_MD,
  US_MA,
  US_MI,
  US_MN,
  US_MS,
  US_MO,
  US_MT,
  US_NE,
  US_NV,
  US_NH,
  US_NJ,
  US_NM,
  US_NY,
  US_NC,
  US_ND,
  US_OH,
  US_OK,
  US_OR,
  US_PA,
  US_RI,
  US_SC,
  US_SD,
  US_TN,
  US_TX,
  US_UT,
  US_VT,
  US_VA,
  US_WA,
  US_WV,
  US_WI,
  US_WY,
  UY_AR,
  UY_CA,
  UY_CL,
  UY_CO,
  UY_DU,
  UY_FS,
  UY_FD,
  UY_LA,
  UY_MA,
  UY_MO,
  UY_PA,
  UY_RN,
  UY_RV,
  UY_RO,
  UY_SA,
  UY_SJ,
  UY_SO,
  UY_TA,
  UY_TT,
  UZ_AN,
  UZ_BU,
  UZ_FA,
  UZ_JI,
  UZ_NG,
  UZ_NW,
  UZ_QA,
  UZ_QR,
  UZ_SA,
  UZ_SI,
  UZ_SU,
  UZ_TK,
  UZ_XO,
  VC_01,
  VC_06,
  VC_04,
  VC_05,
  VE_Z,
  VE_B,
  VE_C,
  VE_D,
  VE_E,
  VE_F,
  VE_G,
  VE_H,
  VE_Y,
  VE_A,
  VE_I,
  VE_J,
  VE_X,
  VE_K,
  VE_L,
  VE_M,
  VE_N,
  VE_O,
  VE_P,
  VE_R,
  VE_S,
  VE_T,
  VE_U,
  VE_V,
  VN_44,
  VN_43,
  VN_54,
  VN_53,
  VN_55,
  VN_56,
  VN_50,
  VN_31,
  VN_57,
  VN_58,
  VN_40,
  VN_59,
  VN_CT,
  VN_04,
  VN_DN,
  VN_33,
  VN_72,
  VN_71,
  VN_39,
  VN_45,
  VN_30,
  VN_03,
  VN_63,
  VN_HN,
  VN_23,
  VN_61,
  VN_HP,
  VN_73,
  VN_SG,
  VN_14,
  VN_66,
  VN_34,
  VN_47,
  VN_28,
  VN_01,
  VN_35,
  VN_09,
  VN_02,
  VN_41,
  VN_67,
  VN_22,
  VN_18,
  VN_36,
  VN_68,
  VN_32,
  VN_24,
  VN_27,
  VN_29,
  VN_13,
  VN_25,
  VN_52,
  VN_05,
  VN_37,
  VN_20,
  VN_69,
  VN_21,
  VN_26,
  VN_46,
  VN_51,
  VN_07,
  VN_49,
  VN_70,
  VN_06,
  VU_SEE,
  VU_TAE,
  VU_TOB,
  WF_SG,
  WF_UV,
  WS_AT,
  WS_FA,
  WS_TU,
  YE_AD,
  YE_AM,
  YE_AB,
  YE_DA,
  YE_BA,
  YE_HU,
  YE_JA,
  YE_MW,
  YE_SA,
  YE_DH,
  YE_HD,
  YE_HJ,
  YE_IB,
  YE_MA,
  YE_SD,
  YE_SN,
  YE_SH,
  YE_TA,
  ZA_EC,
  ZA_FS,
  ZA_GP,
  ZA_KZN,
  ZA_LP,
  ZA_MP,
  ZA_NW,
  ZA_NC,
  ZA_WC,
  ZM_02,
  ZM_08,
  ZM_03,
  ZM_04,
  ZM_09,
  ZM_10,
  ZM_06,
  ZM_05,
  ZM_07,
  ZM_01,
  ZW_BU,
  ZW_HA,
  ZW_MA,
  ZW_MC,
  ZW_ME,
  ZW_MW,
  ZW_MV,
  ZW_MN,
  ZW_MS,
  ZW_MI,
}

export interface CountryWithName {
  country: Countries;
  name: string;
}

export interface StateWithName {
  state: States;
  name: string;
}
