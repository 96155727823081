<template>
  <div class="unauthorized-page">
    <div class="page-header">
      <div class="logo-container" data-testid="unauthorizedPage.logo" @click="goToHome">
        <img class="logo" src="@/assets/svg/aura-connect-logo.svg" alt="aura-connect-logo" />
        <span>aura</span>
      </div>
      <div class="buttons">
        <a href="https://support.anisoprint.com" target="_blank" class="mr-4" data-testid="unauthorizedPage.supportLink"
          >Support</a
        >
        <a
          href="https://support.anisoprint.com/ticket"
          class="mr-2"
          target="_blank"
          data-testid="unauthorizedPage.reportBugLink"
          >Report a bug</a
        >
      </div>
    </div>
    <div class="main-container">
      <div class="bg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 650">
          <defs>
            <linearGradient id="grad1" x1="0%" y1="0%" x2="1%" y2="80%">
              <stop offset="0%" class="grad-st1" />
              <stop offset="100%" class="grad-st2" />
            </linearGradient>
          </defs>
          <polyline points="0 0 0 800 600 793 600 639 244 0 0 0" class="bcg-grad" fill="url(#grad1)" />
        </svg>
        <div class="logo-container">
          <img class="aura-logo" src="@/assets/images/logo-md-gray.png" alt="aura-connect-logo" />
          <span class="deploy-type">connect</span>
        </div>
      </div>

      <div class="content">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Routes } from '@/router/routes';

@Component({
  name: 'unauthorized-page',
  components: {},
})
export default class UnauthorizedPage extends Vue {
  async goToHome() {
    return this.$router.replace({ name: Routes.HOME });
  }
}
</script>

<style lang="scss" scoped>
.unauthorized-page {
  background: #333232;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 860px;
  text-align: center;
}

.page-header {
  min-height: 30px;
  background: #1d1c1c;
  display: flex;
  padding: 0 14px;
  align-items: center;

  justify-content: space-between;

  .logo-container {
    display: flex;
    align-items: center;
    cursor: pointer;

    .logo {
      height: 16px;
      margin-right: 8px;
    }

    span {
      color: #cecece;
      font-size: 18px;
      user-select: none;
    }
  }

  .buttons {
    display: flex;
    align-items: center;

    a {
      border: none;
      background: transparent;
      color: #e9e9e9;
      font-size: 14px;
      opacity: 0.8;
      user-select: none;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.content {
  z-index: 10;
  align-self: stretch;
  max-width: 600px;
  min-width: 600px;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 0 3.25rem;
}

.main-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;

  .bg {
    max-width: 600px;
    min-width: 600px;
    position: absolute;
    height: 100%;
    background-color: var(--dark-gray);

    .logo-container {
      position: absolute;
      left: 0;
      top: 0;
      margin: 2.5rem;
      margin-top: 3rem;
      display: flex;
      align-items: center;

      .aura-logo {
        width: 30%;
        image-rendering: crisp-edges;
      }

      .deploy-type {
        color: #928581;
        font-size: 20px;
        margin-left: 2px;
        user-select: none;
      }
    }
  }
}

stop.grad-st1 {
  stop-color: var(--dark-red);
  stop-opacity: 0.56;
}

stop.grad-st2 {
  stop-color: var(--dark-red);
  stop-opacity: 0;
}
</style>
