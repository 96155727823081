import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';
import * as ips from '../../util/api/ips';
import * as routes from '../../util/api/routes';
import * as hubActions from '../../util/api/hubActions';
import { ReconnectType } from '../util/storeHelpers';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { Guid } from 'guid-typescript';
import { UtilModule } from './utilModule';
import store from '@/store';
import * as signalR from '@microsoft/signalr';
import {
  ResponseIdentityHelper,
  ResponseToken,
  ResponseUpdateToken,
  ResponseUser,
} from '../../models/responses/ResponseIdentity';
import {
  RequestForgotPassword,
  RequestForgotPasswordCheckCode,
  RequestForgotPasswordNew,
  RequestLogout,
  RequestSignIn,
  RequestUpdateToken,
} from '../../models/requests/RequestIdentity';
import { Company, Role, User } from '../../models/Entities';
import { UserModule } from './userModule';
import { ErrorModule } from '@/store/modules/errorsModule';
import { CompanyModule } from '@/store/modules/companyModule';
import { RoleModule } from '@/store/modules/roleModule';
import { GlobalNotificationsModule } from '@/store/modules/globalNotificationsModule';
import { AccountModule } from './billing/accountModule';
import { ProductPriceModule } from './billing/productPriceModule';
import { ProductModule } from './billing/productModule';
import { InvoiceModule } from './billing/invoiceModule';
import { ISimpleEvent, SimpleEventDispatcher } from 'strongly-typed-events';
import { sleep } from '@/util/TypeHelper';
import { isBullingEnabled, isDevMode } from '@/util/env';

const SECONDS_OFFSET = 30;
export const USER_TOKEN_FIELD = 'user-token';
export const USER_ACCESS_TOKEN_FIELD = 'user-access-token';
export const USER_TOKEN_LAST_UPDATED = 'user-token-last-updated';
export const USER_ID_FIELD = 'user-id';
export const IS_CONNECTING_FIELD = 'is-connecting';

export enum LoginStatus {
  NotAuthorized,
  Authorized,
}

export interface SignalRGroupSubscription {
  func: (args: any | undefined) => Promise<void>;
  args?: any | undefined;
}

@Module({ dynamic: true, name: 'login', store: store })
export default class loginModule extends VuexModule {
  private validBeforeToken: Date = new Date(0);
  private validBeforeRefreshToken: Date = new Date(0);
  private status: LoginStatus = LoginStatus.NotAuthorized;
  private reconnectAttempt: ReconnectType = '0';
  private loginSessionId: Guid = Guid.createEmpty();
  private me: User | undefined = undefined;
  private company: Company | null = null;
  private currentTimerId: number = -1;
  private reconnectPenaltyValues: Record<ReconnectType, number> = {
    '0': 0,
    '1': 4 * 1000,
    '2': 5 * 1000,
    '3': 6 * 1000,
    more: 7 * 1000,
  };

  // Todo: !!!!!!!!  MAKE CONNECTION GROUPS FOR ALL CONNECTIONS   !!!!!!!!
  private identityConnection: signalR.HubConnection | null = null;

  private monolithConnectionGroups: SignalRGroupSubscription[] = [];
  private monolithConnection: signalR.HubConnection | null = null;

  private connectorConnectionGroups: SignalRGroupSubscription[] = [];
  private connectorConnection: signalR.HubConnection | null = null;

  private billingConnectionGroups: SignalRGroupSubscription[] = [];
  private billingConnection: signalR.HubConnection | null = null;

  @Mutation
  public AddBillingConnectionGroup(group: SignalRGroupSubscription) {
    const local = this.billingConnectionGroups.firstOrDefault(a => a.func == group.func && a.args == a.args);

    if (local === null) {
      this.billingConnectionGroups.push(group);
    }
  }

  @Mutation
  public DeleteBillingConnectionGroup(group: SignalRGroupSubscription) {
    const local = this.billingConnectionGroups.firstOrDefault(a => a.func == group.func && a.args == a.args);

    if (local !== null) {
      this.billingConnectionGroups.delete(local);
    }
  }

  @Mutation
  public AddMonolithConnectionGroup(group: SignalRGroupSubscription) {
    const local = this.monolithConnectionGroups.firstOrDefault(a => a.func == group.func && a.args == a.args);

    if (local === null) {
      this.monolithConnectionGroups.push(group);
    }
  }

  @Mutation
  public DeleteMonolithConnectionGroup(group: SignalRGroupSubscription) {
    const local = this.monolithConnectionGroups.firstOrDefault(a => a.func == group.func && a.args == a.args);

    if (local !== null) {
      this.monolithConnectionGroups.delete(local);
    }
  }

  @Mutation
  public AddConnectorConnectionGroup(group: SignalRGroupSubscription) {
    this.connectorConnectionGroups.push(group);
  }

  @Mutation
  public DeleteConnectorConnectionGroup(group: SignalRGroupSubscription) {
    this.connectorConnectionGroups.delete(group);
  }

  private onBillingConnectionReconnected = new SimpleEventDispatcher<signalR.HubConnection>();
  public get OnBillingConnectionReconnected(): ISimpleEvent<signalR.HubConnection> {
    return this.onBillingConnectionReconnected.asEvent();
  }

  private initialized: boolean = false;

  get Initialized() {
    return this.initialized;
  }
  get Me(): User | undefined {
    return this.me;
  }
  get IsAuthorized(): boolean {
    return this.status === LoginStatus.Authorized;
  }
  get Status(): LoginStatus {
    return this.status;
  }
  get UserId(): Guid {
    if (!this.Me) return Guid.parse(localStorage.getItem(USER_ID_FIELD) || Guid.createEmpty().toString());
    return this.Me.Id;
  }
  get IsAdmin(): boolean {
    if (this.me?.Roles.empty() || !this.me?.Roles) return false;
    return this.me?.Roles.filter(a => a.IsAdmin).length > 0;
  }
  get IsSuperAdmin(): boolean {
    if (this.me?.Roles.empty()) return false;
    return this.me?.Roles.singleOrDefault(a => a.IsSuperAdmin) != null;
  }

  get IsBillingAccountAdmin() {
    if (!isBullingEnabled()) {
      return false;
    }

    if (this.me?.Roles.empty()) return false;
    return this.me?.Roles.singleOrDefault(a => a.IsBillingAccountAdmin) != null;
  }

  get IsBillingAccountOwner(): boolean {
    if (!isBullingEnabled()) {
      return false;
    }

    if (this.me?.Roles.empty()) return false;
    return this.me?.Roles.singleOrDefault(a => a.IsBillingAccountOwner) != null;
  }

  get IsConnectUser(): boolean {
    if (this.me?.Roles.empty()) return false;
    return this.me?.Roles.singleOrDefault(a => a.IsConnectUser) != null;
  }

  get ValidBeforeToken(): Date {
    return this.validBeforeToken;
  }
  get ReconnectAttempt(): ReconnectType {
    return this.reconnectAttempt;
  }
  get ReconnectPenaltyValues(): Record<ReconnectType, number> {
    return this.reconnectPenaltyValues;
  }
  get CurrentTimerId(): number {
    return this.currentTimerId;
  }
  get IdentityConnection(): signalR.HubConnection | null {
    return this.identityConnection;
  }
  get MonolithConnection(): signalR.HubConnection | null {
    return this.monolithConnection;
  }
  get ConnectorConnection(): signalR.HubConnection | null {
    return this.connectorConnection;
  }
  get BillingConnection(): signalR.HubConnection | null {
    return this.billingConnection;
  }
  get BillingConnectionGroups(): SignalRGroupSubscription[] {
    return this.billingConnectionGroups;
  }
  get MonolithConnectionGroups(): SignalRGroupSubscription[] {
    return this.monolithConnectionGroups;
  }
  get ConnectorConnectionGroups(): SignalRGroupSubscription[] {
    return this.connectorConnectionGroups;
  }
  get LoginSessionId(): Guid {
    return this.loginSessionId;
  }
  get CompanyId(): Guid | null {
    if (!this.Me) return null;
    return this.Me.CompanyId;
  }
  get Company(): Company | null {
    if (!this.Me) return null;
    return this.company;
  }
  @Mutation
  SetInitialized(val: boolean) {
    this.initialized = val;
  }
  @Mutation
  SetMe(value: User | undefined) {
    this.me = value;
    if (localStorage && value) localStorage.setItem(USER_ID_FIELD, value.Id.toString());
  }
  @Mutation
  SetCompany(value: Company | null) {
    this.company = value;
  }
  @Mutation
  SetValidBeforeToken(value: string) {
    this.validBeforeToken = Number.isNaN(Date.parse(value)) ? new Date(0) : new Date(value);
  }
  @Mutation
  SetValidBeforeRefreshToken(value: string) {
    this.validBeforeRefreshToken = Number.isNaN(Date.parse(value)) ? new Date(0) : new Date(value);
  }
  @Mutation
  SetNotAuthorized() {
    this.status = LoginStatus.NotAuthorized;
    this.identityConnection = null;
    this.monolithConnection = null;
    this.connectorConnection = null;
    // this.reconnectAttempt = '0'; // TODO Shouldn't reset after each attempt
    this.loginSessionId = Guid.createEmpty();
    this.me = undefined;
  }
  @Mutation
  SetAuthorized() {
    this.status = LoginStatus.Authorized;
  }
  @Mutation
  SetReconnectAttemptToZero() {
    this.reconnectAttempt = '0';
  }
  @Mutation
  IncreaseReconnectAttempt() {
    if (this.reconnectAttempt === 'more') return;
    if (this.reconnectAttempt === '0') {
      this.reconnectAttempt = '1';
      return;
    }
    if (this.reconnectAttempt === '1') {
      this.reconnectAttempt = '2';
      return;
    }
    if (this.reconnectAttempt === '2') {
      this.reconnectAttempt = '3';
      return;
    }
    if (this.reconnectAttempt === '3') {
      this.reconnectAttempt = 'more';
      return;
    }
  }
  @Mutation
  SetLoginSessionId(value: Guid) {
    this.loginSessionId = value;
  }
  @Mutation
  AddMyRole(value: Role) {
    if (this.me?.Roles.find(a => a.Id.equals(value.Id))) return;
    if (this.me) this.me.Roles.push(value);
  }
  @Mutation
  DeleteMyRole(value: Role) {
    if (!this.me?.Roles.find(a => a.Id.equals(value.Id))) return;
    if (this.me) this.me.Roles.delete(value);
  }
  @Mutation
  SetCurrentTimerId(value: number) {
    this.currentTimerId = value;
  }
  @Mutation
  SetIdentityConnection(connection: signalR.HubConnection | null) {
    this.identityConnection?.stop();
    this.identityConnection = connection;
  }
  @Mutation
  SetMonolithConnection(connection: signalR.HubConnection | null) {
    this.monolithConnection?.stop();
    this.monolithConnection = connection;
  }
  @Mutation
  SetConnectorConnection(connection: signalR.HubConnection | null) {
    this.connectorConnection?.stop();
    this.connectorConnection = connection;
  }
  @Mutation
  SetBillingConnection(connection: signalR.HubConnection | null) {
    this.billingConnection?.stop();
    this.billingConnection = connection;
  }

  @Action({ rawError: true })
  SetRefreshToken(value: string) {
    localStorage.setItem(USER_TOKEN_FIELD, value);
  }

  @Action({ rawError: true })
  SetAccessToken(value: string) {
    localStorage.setItem(USER_ACCESS_TOKEN_FIELD, value);
  }

  @Action({ rawError: true })
  SetRefreshTokenLastUpdated(val: Date | null) {
    localStorage.setItem(USER_TOKEN_LAST_UPDATED, val == null ? '' : val.getTime().toString());
  }

  @Action({ rawError: true })
  async getCurrentUserId(): Promise<string> {
    return localStorage.getItem(USER_ID_FIELD) || '';
  }

  @Action({ rawError: true })
  async getRefreshToken(): Promise<string> {
    return localStorage.getItem(USER_TOKEN_FIELD) || '';
  }

  @Action({ rawError: true })
  async GetAccessToken(): Promise<string> {
    return localStorage.getItem(USER_ACCESS_TOKEN_FIELD) || '';
  }

  @Action({ rawError: true })
  async GetRefreshTokenLastUpdated(): Promise<Date | null> {
    const valStr = localStorage.getItem(USER_TOKEN_LAST_UPDATED);

    if (valStr == null || valStr == '') return null;

    return new Date(parseInt(valStr));
  }

  @Action({ rawError: true })
  async GetIsConnecting() {
    const fromLS = localStorage.getItem(IS_CONNECTING_FIELD);

    if (fromLS == null) return false;

    return fromLS == 'true';
  }

  @Action({ rawError: true })
  async SetIsConnecting(val: boolean) {
    localStorage.setItem(IS_CONNECTING_FIELD, val.toString());
  }
  @Action({ rawError: true })
  async TrySetCompany(companyId: Guid | null | undefined) {
    if (companyId) {
      this.SetCompany(await CompanyModule.GetCompany(companyId));
    }
  }
  @Action({ rawError: true })
  async LoadBillingData() {
    // console.log("Loading billing data from Login Module");

    if (this.BillingConnection?.state !== signalR.HubConnectionState.Connected) {
      const token = (axios.defaults.headers.common['Authorization'] as string).split(' ')[1];
      await this.BillingConnect(token);
      this.BillingConnection?.send(hubActions.UPDATE_TOKEN, token);
    }

    await AccountModule.LoadMyAccount();
    await ProductPriceModule.LoadProductPrices();
    await ProductModule.LoadProducts();
  }
  @Action({ rawError: true })
  async AuthorizeRequest([username, password]: [string, string]): Promise<[RequestStatus, string]> {
    let deschedule = false;
    UtilModule.SHW.ClearTimeout(window, this.CurrentTimerId);
    const request = new RequestSignIn();
    request.email = username;
    request.password = password;
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.LOGIN,
    );
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null && axiosResponse.data.success) {
      const response = ResponseIdentityHelper.createRealToken(axiosResponse.data.response as ResponseToken);
      await this.SetRefreshToken(response.refreshToken);
      this.SetValidBeforeRefreshToken(response.validBeforeRefreshToken);
      await this.SetRefreshTokenLastUpdated(new Date());
      this.SetLoginSessionId(response.loginSessionId);
      await this.SetAccessToken(response.token);
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.token;
      this.SetValidBeforeToken(response.validBeforeToken);
      await UtilModule.LoadGlobals();
      this.SetMe(response.responseUser.Map(RoleModule.Roles));
      await this.TrySetCompany(this.Me?.CompanyId);
      await this.RefreshSignalRConnection(response.token);
      await GlobalNotificationsModule.ReadGlobalNotifications();
      if (this.IsBillingAccountAdmin) {
        await this.LoadBillingData();
      }
      this.SetAuthorized();
    } else {
      deschedule = true;
      delete axios.defaults.headers.common['Authorization'];
      await this.SetRefreshToken('');
      this.SetLoginSessionId(Guid.createEmpty());
      this.SetValidBeforeRefreshToken('');
      this.SetValidBeforeToken('');
      await this.SetAccessToken('');
      await this.SetRefreshTokenLastUpdated(null);
      this.SetNotAuthorized();
    }
    if (deschedule) await this.DescheduleReconnect();
    else await this.ScheduleReconnect();
    return [result, message];
  }
  @Action({ rawError: true })
  async ForgotPasswordRequest(username: string): Promise<void> {
    const request = new RequestForgotPassword();
    request.email = username;
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.FORGOT_PASSWORD,
    );
    const { result, message } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result !== RequestStatus.OK) ErrorModule.ShowError(message);
    delete axios.defaults.headers.common['Authorization'];
    await this.SetRefreshToken('');
    this.SetValidBeforeRefreshToken('');
    this.SetValidBeforeToken('');
    await this.SetAccessToken('');
    await this.SetRefreshTokenLastUpdated(null);
    this.SetNotAuthorized();
    await this.DescheduleReconnect();
  }
  @Action({ rawError: true })
  async ForgotPasswordCheckCode([username, code]: [string, string]): Promise<[RequestStatus, string]> {
    const request = new RequestForgotPasswordCheckCode();
    request.email = username;
    request.code = code;
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.FORGOT_PASSWORD_CHECK,
    );
    const { result, message } = await UtilModule.SHW.ProcessPost(uri, request);
    return [result, message];
  }
  @Action({ rawError: true })
  async ForgotPasswordNewRequest([username, code, password]: [string, string, string]): Promise<
    [RequestStatus, string]
  > {
    const request = new RequestForgotPasswordNew();
    request.email = username;
    request.code = code;
    request.newPassword = password;
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.FORGOT_PASSWORD_NEW,
    );
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseIdentityHelper.createRealToken(axiosResponse.data.response as ResponseToken);
      await this.SetRefreshToken(response.refreshToken);
      this.SetValidBeforeRefreshToken(response.validBeforeRefreshToken);
      await this.SetRefreshTokenLastUpdated(new Date());
      await this.SetAccessToken(response.token);
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.token;
      this.SetValidBeforeToken(response.validBeforeToken);
      this.SetLoginSessionId(response.loginSessionId);
      await UtilModule.LoadGlobals();
      this.SetMe(response.responseUser.Map(RoleModule.Roles));
      await this.TrySetCompany(this.Me?.CompanyId);
      await this.RefreshSignalRConnection(response.token);
      this.SetReconnectAttemptToZero();
      await GlobalNotificationsModule.ReadGlobalNotifications();
      if (this.IsBillingAccountAdmin) {
        await this.LoadBillingData();
      }
      this.SetAuthorized();
    } else {
      delete axios.defaults.headers.common['Authorization'];
      await this.SetRefreshToken('');
      this.SetValidBeforeRefreshToken('');
      this.SetLoginSessionId(Guid.createEmpty());
      this.SetValidBeforeToken('');
      await this.SetAccessToken('');
      await this.SetRefreshTokenLastUpdated(null);
      this.SetNotAuthorized();
      this.IncreaseReconnectAttempt();
    }
    return [result, message];
  }

  @Action({ rawError: true })
  private async WaitConnecting() {
    let timeoutReached = false;
    const timeout = async () => {
      console.log('Starting IsConnecting timeout for 60s');

      await sleep(60000);
      timeoutReached = true;

      console.log('IsConnecting timeout reached!');
    };
    const checkIsConnecting = async () => {
      console.log('Waiting is connecting...');

      let res = await this.GetIsConnecting();
      while (res && !timeoutReached) {
        await sleep(10);
        res = await this.GetIsConnecting();
      }

      console.log('Wait for IsConnecting finished. Value: ' + res);
    };
    await Promise.race([checkIsConnecting(), timeout()]);
  }

  @Action({ rawError: true })
  async Connect() {
    // TODO Maybe this is a reason for hanging on login
    if (await this.GetIsConnecting()) {
      await this.WaitConnecting();
    }

    await this.SetIsConnecting(true);
    console.log('Logging in...');

    const refreshToken = await this.getRefreshToken();
    const refreshTokenLastUpdated = await this.GetRefreshTokenLastUpdated();

    if (refreshToken.length !== 0 && !Guid.createEmpty().equals(this.UserId)) {
      const request = new RequestUpdateToken();
      request.userId = this.UserId.toString();
      request.currentRefreshToken = refreshToken;
      const uri = UtilModule.SHW.GetUri(
        ips.PROTOCOL(),
        ips.IP(),
        ips.PORT(),
        routes.UriServices.IDENTITY,
        routes.UriController.USER,
        routes.UriUser.UPDATE_TOKEN,
      );
      const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
      if (result === RequestStatus.OK && axiosResponse != null) {
        const response = ResponseIdentityHelper.createRealUpdateToken(
          axiosResponse.data.response as ResponseUpdateToken,
        );
        await this.SetRefreshToken(response.refreshToken);
        this.SetValidBeforeRefreshToken(response.validBeforeRefreshToken);
        await this.SetRefreshTokenLastUpdated(new Date());
        await this.SetAccessToken(response.token);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.token;
        this.SetValidBeforeToken(response.validBeforeToken);
        this.SetLoginSessionId(response.loginSessionId);
        await this.RefreshSignalRConnection(response.token);
        await UtilModule.LoadGlobals();
        if (!this.Me) {
          const meAndSessions = await UserModule.JustLoadUsers([this.UserId]);
          if (meAndSessions.any()) {
            const [me] = meAndSessions[0];
            this.SetMe(me);
            await this.TrySetCompany(me.CompanyId);
          }
        }
        if (this.IsBillingAccountAdmin && AccountModule.Account === null) {
          await this.LoadBillingData();
        }
        this.SetAuthorized();
      } else {
        let tokenNew = await this.GetAccessToken();
        let updatedInOtherTab = false;

        for (let i = 0; i < 5; ++i) {
          const lastUpdatedNew = await this.GetRefreshTokenLastUpdated();

          console.log(
            `Cheking last updated. Current: ${refreshTokenLastUpdated?.toTimeString()}; New: ${lastUpdatedNew?.toTimeString()}`,
          );

          if (refreshTokenLastUpdated != null && lastUpdatedNew != null && lastUpdatedNew > refreshTokenLastUpdated) {
            updatedInOtherTab = true;
            break;
          }

          await sleep(1000);
        }

        // Todo: this should probably also handle logic session id / signalr etc??
        if (updatedInOtherTab) {
          console.log('Token was refreshed somewhere else');
          tokenNew = await this.GetAccessToken();

          axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenNew;

          await this.RefreshSignalRConnection(tokenNew);
          await UtilModule.LoadGlobals();
        } else {
          console.log(message);

          ErrorModule.ShowError(message);
          this.SetNotAuthorized();
        }
      }
      await this.ScheduleReconnect();
    } else this.SetNotAuthorized();

    if (!this.initialized) {
      this.SetInitialized(true);
    }
    console.log('Logging in finished');
    await this.SetIsConnecting(false);
  }
  @Action({ rawError: true })
  async ScheduleReconnect() {
    let timeout: number;
    if (this.IsAuthorized) {
      this.SetReconnectAttemptToZero();
      timeout = UtilModule.SHW.GetTimeoutToUpdateToken(this.ValidBeforeToken, SECONDS_OFFSET);
    } else {
      delete axios.defaults.headers.common['Authorization'];
      this.IncreaseReconnectAttempt();
      timeout = UtilModule.SHW.GetTimeoutToReconnect(this.ReconnectPenaltyValues, this.ReconnectAttempt);
    }
    const timerId = UtilModule.SHW.AddTimeoutAction(window, this.Connect, timeout);
    this.SetCurrentTimerId(timerId);
  }
  @Action({ rawError: true })
  async DescheduleReconnect() {
    if (this.CurrentTimerId === -1) return;
    UtilModule.SHW.ClearTimeout(window, this.CurrentTimerId);
    this.SetCurrentTimerId(-1);
  }
  @Action({ rawError: true })
  async RefreshSignalRConnection(token: string) {
    const identityConnect = async () => {
      if (
        this.IdentityConnection == null ||
        this.IdentityConnection.state === signalR.HubConnectionState.Disconnected
      ) {
        const identityHubUri = UtilModule.SHW.GetUri(
          ips.PROTOCOL(),
          ips.IP(),
          ips.PORT(),
          routes.UriHubs.HUBS,
          routes.UriHubs.IDENTITY_HUB,
        );
        const identityConnection = UtilModule.SHW.CreateConnection(identityHubUri, token);
        await identityConnection?.start();
        this.SetIdentityConnection(identityConnection);
        UtilModule.SubscribeIdentity(identityConnection);
        this.LoginSubscribe();
        await CompanyModule.SubscribeToCompanyGroup();
      } else if (this.IdentityConnection.state === signalR.HubConnectionState.Connected) {
        this.IdentityConnection?.send(hubActions.UPDATE_TOKEN, token);
      }
    };
    const monolithConnect = async () => {
      if (
        this.MonolithConnection == null ||
        this.MonolithConnection.state === signalR.HubConnectionState.Disconnected
      ) {
        const monolithHubUri = UtilModule.SHW.GetUri(
          ips.PROTOCOL(),
          ips.IP(),
          ips.PORT(),
          routes.UriHubs.HUBS,
          routes.UriHubs.MONOLITH_HUB,
        );
        const monolithConnection = UtilModule.SHW.CreateConnection(monolithHubUri, token);
        await monolithConnection?.start();
        this.SetMonolithConnection(monolithConnection);
        await UtilModule.SubscribeMonolith(monolithConnection);
      } else if (this.MonolithConnection.state === signalR.HubConnectionState.Connected) {
        this.monolithConnection?.send(hubActions.UPDATE_TOKEN, token);
      }
    };
    const connectorConnect = async () => {
      if (
        this.ConnectorConnection == null ||
        this.ConnectorConnection.state === signalR.HubConnectionState.Disconnected
      ) {
        const connectorHubUri = UtilModule.SHW.GetUri(
          ips.PROTOCOL(),
          ips.IP(),
          ips.PORT(),
          routes.UriHubs.HUBS,
          routes.UriHubs.CONNECTOR_HUB,
        );
        const connectorConnection = UtilModule.SHW.CreateConnection(connectorHubUri, token);
        await connectorConnection?.start();
        this.SetConnectorConnection(connectorConnection);
        await UtilModule.SubscribeConnector();
      } else if (this.ConnectorConnection.state === signalR.HubConnectionState.Connected) {
        this.ConnectorConnection?.send(hubActions.UPDATE_TOKEN, token);
      }
    };
    await identityConnect();
    await monolithConnect();
    await connectorConnect();
    await this.BillingConnect(token);
    //~ ]);
  }
  @Action({ rawError: true })
  async BillingConnect(token: string) {
    if (!this.IsBillingAccountAdmin) return;

    if (this.BillingConnection == null || this.BillingConnection.state === signalR.HubConnectionState.Disconnected) {
      const billingHubUri = UtilModule.SHW.GetUri(
        ips.PROTOCOL(),
        ips.IP(),
        ips.PORT(),
        routes.UriHubs.HUBS,
        routes.UriHubs.BILLING_HUB,
      );
      const billingConnection = UtilModule.SHW.CreateConnection(billingHubUri, token, signalR.LogLevel.Error);
      await billingConnection?.start();
      this.SetBillingConnection(billingConnection);
      await UtilModule.SubscribeBilling(billingConnection);
      await this.ResubscribeToBillingGroups();
      billingConnection?.onreconnected(async () => {
        await this.ResubscribeToBillingGroups();
        this.onBillingConnectionReconnected.dispatch(billingConnection);
      });
    } else if (this.BillingConnection.state === signalR.HubConnectionState.Connected) {
      this.BillingConnection?.send(hubActions.UPDATE_TOKEN, token);
    }
  }

  @Action({ rawError: true })
  async ResubscribeToBillingGroups() {
    // console.log("Resubscribe to billing groups");

    for (const sub of this.billingConnectionGroups) {
      if (sub.func.constructor.name == 'AsyncFunction') {
        await sub.func(sub.args);
      } else {
        sub.func(sub.args);
      }
    }
  }

  @Action({ rawError: true })
  LoginSubscribe() {
    if (this.IdentityConnection) {
      this.IdentityConnection.on(hubActions.RECEIVE_DISCONNECT_USER, async (response: ResponseUser) => {
        const realResponse = ResponseIdentityHelper.createRealUser(response);
        if (this.UserId.equals(realResponse.id)) await this.LogoutFromClient();
      });
    }
  }

  @Action({ rawError: true })
  async Logout(): Promise<void> {
    const request = new RequestLogout();
    request.refreshToken = await this.getRefreshToken();
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.LOGOUT,
    );
    await this.LogoutFromClient();
    const { result, message } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result !== RequestStatus.OK) ErrorModule.ShowError(message);
  }

  @Action({ rawError: true })
  async LogoutFromClient() {
    // console.log("logout");
    await CompanyModule.DeleteFromCompanyGroup();
    await this.DescheduleReconnect();
    this.SetValidBeforeToken('');
    this.SetValidBeforeRefreshToken('');
    this.SetNotAuthorized();
    await this.SetRefreshToken('');
    await this.SetAccessToken('');
    await this.SetRefreshTokenLastUpdated(null);
    InvoiceModule.ClearInvoices();
    UserModule.ClearUsers();
  }

  @Action({ rawError: true })
  async getAuthQueryParams() {
    if (!isDevMode()) {
      return '';
    }

    const userId = await this.getCurrentUserId();
    const refreshToken = await this.getRefreshToken();
    return `?userId=${encodeURIComponent(userId)}&refreshToken=${encodeURIComponent(refreshToken)}`;
  }
}

export const LoginModule = getModule(loginModule);
