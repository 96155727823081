<template>
  <unauthorized-page>
    <template slot="content">
      <component
        :is="innerComponent"
        class="h-100"
        @success="OnSuccess"
        @forgot="OnForgotPassword"
        @signin="OnSignIn"
      />
    </template>
  </unauthorized-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import LoginPassForm from '../components/forms/LoginPassForm.vue';
import ForgotPasswordForm from '../components/forms/ForgotPasswordForm.vue';
import globalDataModule, { DeliveryVersions } from '../store/modules/globalDataModule';
import en from '../localization/en';
import UnauthorizedPage from '@/components/presentation/UnauthorizedPage.vue';

enum ChildComponentType {
  LOGIN,
  FORGOT,
}
type ChildComponent = Record<ChildComponentType, string>;

@Component({
  components: {
    loginPassForm: LoginPassForm,
    forgotPasswordForm: ForgotPasswordForm,
    UnauthorizedPage: UnauthorizedPage,
  },
})
export default class MainView extends Vue {
  private globalDataModule!: globalDataModule;
  private innerComponents: ChildComponent = {
    [ChildComponentType.LOGIN]: 'login-pass-form',
    [ChildComponentType.FORGOT]: 'forgot-password-form',
  };
  private innerComponent = this.innerComponents[ChildComponentType.LOGIN];

  created() {
    this.globalDataModule = getModule(globalDataModule, this.$store);
  }
  private get DeliveryVersion(): string {
    switch (this.globalDataModule.DeliveryVersion) {
      case DeliveryVersions.Local: {
        return en.local;
      }
      case DeliveryVersions.Cloud: {
        return en.cloud;
      }
      default:
        return en.local;
    }
  }
  private OnForgotPassword() {
    this.innerComponent = this.innerComponents[ChildComponentType.FORGOT];
  }
  private OnSignIn() {
    this.innerComponent = this.innerComponents[ChildComponentType.LOGIN];
  }
  private OnSuccess() {}
  private get versionNumber(): string {
    return this.globalDataModule.Version;
  }
  private get versionCaption(): string {
    return en.version;
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono');
@import url('https://fonts.googleapis.com/css?family=Roboto');

stop.grad-st1 {
  stop-color: var(--dark-red);
  stop-opacity: 0.56;
}
stop.grad-st2 {
  stop-color: var(--dark-red);
  stop-opacity: 0;
}
.bcg {
  background-color: var(--bckg-dark-gray);
  min-height: 600px;
}
div.logo-md {
  background-image: url('~@/assets/images/logo-md-gray.png');
  background-size: 170px 103px;
  opacity: 0.8;
  width: 170px;
  height: 103px;
}
div#delivery-md-container {
  position: absolute;
  left: 40px;
  top: 80px;
}
div#delivery {
  color: var(--grayed-out-text);
  font-size: 22px;
  font-family: 'Roboto', sans-serif;
}
div#fields {
  position: absolute;
  top: 360px;
}
hr {
  max-width: 480px;
  border-top: 1px solid var(--gray-no-highligh);
}
.ft {
  background-color: var(--dark-gray);
  max-width: 600px;
  min-width: 200px;
}
main#main-container {
  background-color: var(--dark-gray);
  max-width: 600px;
  min-width: 200px;
  position: relative;
}
div#connect-to-cloud-container {
  text-align: right;
}
hr {
  max-width: 480px;
  border-top: 1px solid var(--gray-no-highligh);
}
#version {
  color: var(--light-gray);
  font-size: 10px;
  font-family: 'Roboto Mono', sans-serif;
  text-transform: capitalize;
  opacity: 0.8;
}
</style>
