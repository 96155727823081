import Vue from 'vue';
import { ISimpleEvent, SimpleEventDispatcher } from 'strongly-typed-events';
import { HubConnection } from '@microsoft/signalr';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Guid } from 'guid-typescript';

import store from '@/store';
import * as ips from '../../util/api/ips';
import * as routes from '../../util/api/routes';
import * as hubActions from '../../util/api/hubActions';
import { Company } from '../../models/Entities';
import GC from '../util/GC';
import { Countries } from '../../models/responses/Countries';
import { UtilModule } from './utilModule';
import { RequestStatus } from '@/models/enums/RequestStatus';
import {
  ResponseCompanies,
  ResponseCompany,
  ResponseCompanyUserCounts,
  ResponseIdentityHelper,
  ResponseNewCompany,
} from '../../models/responses/ResponseIdentity';
import {
  CompanySortBy,
  RequestCreateCompany,
  RequestCreateUser,
  RequestDeleteCompany,
  RequestReadCountsForCompanies,
  RequestReadMoreCompanies,
  RequestUpdateCompany,
} from '../../models/requests/RequestIdentity';
import { GuidHelper } from '@/util/GuidHelper';
import { ErrorModule } from '@/store/modules/errorsModule';
import { SortMode } from '@/models/requests/Datas';
import { UserModule } from '@/store/modules/userModule';
import { LoginModule } from '@/store/modules/loginModule';
import en from '@/localization/en';
import { toast } from '@/main';

export type CompanyUserCounts = { id: Guid; count: number };

@Module({ dynamic: true, name: 'companies', store: store })
export default class companyModule extends VuexModule {
  private companies: Company[] = [];
  private _gc = new GC();

  get Companies() {
    return this.companies;
  }

  private onCompanyAdded = new SimpleEventDispatcher<Company>();
  private onCompanyDeleted = new SimpleEventDispatcher<Company>();
  private onCompanyUpdated = new SimpleEventDispatcher<Company>();

  public get OnCompanyAdded(): ISimpleEvent<Company> {
    return this.onCompanyAdded.asEvent();
  }
  public get OnCompanyDeleted(): ISimpleEvent<Company> {
    return this.onCompanyDeleted.asEvent();
  }
  public get OnCompanyUpdated(): ISimpleEvent<Company> {
    return this.onCompanyUpdated.asEvent();
  }

  @Mutation
  AddCompanyToModule(value: Company) {
    if (this.companies.find(a => a.Id.equals(value.Id))) return;
    this._gc.UpdateSubscription(value.Id);
    this.companies.push(value);
  }
  @Mutation
  DeleteCompanyFromModule(value: Company) {
    this._gc.DeleteSubscription(value.Id);
    this.companies.delete(value);
  }
  @Mutation
  UpdateCompanyName([company, name]: [Company, string]) {
    company.Name = name;
  }
  @Mutation
  UpdateCompanyDescription([company, description]: [Company, string]) {
    company.Description = description;
  }
  @Mutation
  UpdateCompanyCountry([company, country]: [Company, Countries]) {
    company.Country = country;
  }
  @Mutation
  UpdateCompanyAddress([company, address]: [Company, string]) {
    company.Address = address;
  }
  @Mutation
  UpdateCompanyEmail([company, email]: [Company, string]) {
    company.Email = email;
  }

  @Action({ rawError: true })
  async GetCompany(id: Guid): Promise<Company | null> {
    const inLst = this.Companies.find(a => a.Id.equals(id));
    if (inLst) return inLst;
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.COMPANY,
      routes.UriCompany.READ_MY_COMPANY_BY_ID_NOT_DEEP,
    );
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, null);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseIdentityHelper.createRealCompany(axiosResponse.data.response as ResponseCompany);
      const company = response.Map();
      this.AddCompanyToModule(company);
      return company;
    }
    ErrorModule.ShowError(message);
    return null;
  }

  @Action({ rawError: true })
  async ReadMore(additions: CompanyQueryAdditions): Promise<Company[]> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.COMPANY,
      routes.UriCompany.READ_ALL,
    );
    const request = new RequestReadMoreCompanies();
    request.count = additions.count;
    request.from = additions.from;
    request.sortBy = additions.sortBy;
    request.sortMode = additions.sortMode;
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    const companies: Company[] = [];
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseIdentityHelper.createReaCompanies(axiosResponse.data.response as ResponseCompanies);
      for (const item of response.companies) {
        const company = item.Map();
        companies.push(company);
        this.AddCompanyToModule(company);
      }
    }
    ErrorModule.ShowError(message);
    return companies;
  }

  @Action({ rawError: true })
  async ReadCounts(ids: Guid[]): Promise<CompanyUserCounts[]> {
    if (ids.length === 0) return [];
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.COMPANY,
      routes.UriCompany.READ_COUNTS,
    );
    const request = new RequestReadCountsForCompanies();
    request.ids = ids.map(a => a.toString());
    const companyUserCounts: CompanyUserCounts[] = [];
    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseIdentityHelper.createRealCompanyUserCounts(
        axiosResponse.data.response as ResponseCompanyUserCounts,
      );
      for (const item of response.companies) {
        const companyUserCount: CompanyUserCounts = { id: item.id, count: item.count };
        companyUserCounts.push(companyUserCount);
      }
    }
    return companyUserCounts;
  }

  @Action({ rawError: true })
  async CreateCompany(newCompanyData: INewCompanyData): Promise<{ status: RequestStatus; message: string }> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.COMPANY,
      routes.UriCompany.CREATE_NEW_COMPANY,
    );
    const request = new RequestCreateCompany();
    request.name = newCompanyData.name;
    request.description = newCompanyData.description;
    request.country = newCompanyData.country;
    request.address = newCompanyData.address;
    request.email = newCompanyData.email;
    const requestAdmin = new RequestCreateUser();
    requestAdmin.firstName = newCompanyData.adminFirstName;
    requestAdmin.secondName = newCompanyData.adminSecondName;
    requestAdmin.email = newCompanyData.adminEmail;
    requestAdmin.password = newCompanyData.adminPassword;
    request.user = requestAdmin;
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseIdentityHelper.createRealNewCompany(axiosResponse.data.response as ResponseNewCompany);
      const company = response.company.Map();
      this.AddCompanyToModule(company);
    }
    ErrorModule.ShowError(message);
    return { status: result, message: message };
  }

  @Action({ rawError: true })
  async DeleteCompany(id: Guid): Promise<Guid | string> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.COMPANY,
      routes.UriCompany.DELETE,
    );
    const request = new RequestDeleteCompany();
    request.id = id.toString();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseIdentityHelper.createRealCompany(axiosResponse.data.response as ResponseCompany);
      const company = response.Map();
      this.DeleteCompanyFromModule(company);
      return company.Id;
    }
    ErrorModule.ShowError(message);
    return message;
  }

  @Action({ rawError: true })
  CompaniesSync([companies, componentId]: [Company[], Guid]) {
    this._gc.Occupy(
      companies.map(a => a.Id),
      componentId,
    );
  }

  @Action({ rawError: true })
  async LooseCompanies([companyIds, componentId]: [Guid[], Guid]) {
    const toDeleteCompanyIds = await this._gc.Loose(companyIds, componentId);
    const companiesToDelete = this.Companies.filter(a => GuidHelper.includes(toDeleteCompanyIds, a.Id));
    for (const company of companiesToDelete) {
      this.DeleteCompanyFromModule(company);
    }
  }

  @Action({ rawError: true })
  async ChangeCompanyInfo(updateData: RequestUpdateCompany & { companyId: Guid }): Promise<[RequestStatus, string]> {
    const { companyId } = updateData;
    const company = this.Companies.single(a => a.Id.equals(companyId));

    const request: RequestUpdateCompany = {
      ...updateData,
      companyId: companyId.toString(),
    };

    const url = UtilModule.SHW.getUrl(
      routes.UriServices.IDENTITY,
      routes.UriController.COMPANY,
      routes.UriCompany.UPDATE_COMPANY,
    );
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(url, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseIdentityHelper.createRealCompany(axiosResponse.data.response as ResponseCompany);
      if (company.Name !== response.name) this.UpdateCompanyName([company, response.name]);
      if (company.Description !== response.description) this.UpdateCompanyDescription([company, response.description]);
      if (company.Country !== response.country) this.UpdateCompanyCountry([company, response.country]);
      if (company.Address !== response.address) this.UpdateCompanyAddress([company, response.address]);
      if (company.Email !== response.email) this.UpdateCompanyEmail([company, response.email]);
    } else {
      let errorMessage = en.unknownError;
      if (message === 'HasSameNameCompany') {
        errorMessage = en.companyNameAlreadyExists;
      }
      toast.error(errorMessage);
    }
    return [result, message];
  }

  @Action({ rawError: true })
  CompanySubscribe(hubConnection: HubConnection) {
    hubConnection.on(hubActions.RECEIVE_UPDATE_COMPANY, (response: ResponseCompany) => {
      const responseReal = ResponseIdentityHelper.createRealCompany(response);
      const company = this.Companies.find(a => a.Id.equals(responseReal.id));
      if (!company) return;
      if (company.Name !== responseReal.name) {
        this.UpdateCompanyName([company, responseReal.name]);
        Vue.set(company, 'Name', responseReal.name);
      }
      if (company.Description !== responseReal.description)
        this.UpdateCompanyDescription([company, responseReal.description]);
      if (company.Country !== responseReal.country) this.UpdateCompanyCountry([company, responseReal.country]);
      if (company.Address !== responseReal.address) this.UpdateCompanyAddress([company, responseReal.address]);

      this.onCompanyUpdated.dispatch(company);
    });
    hubConnection.on(hubActions.RECEIVE_DELETE_COMPANY, async (response: ResponseCompany) => {
      const responseReal = ResponseIdentityHelper.createRealCompany(response);
      const users = UserModule.Users.filter(a => a.Id.equals(responseReal.id));
      if (users.any()) {
        for (const user of users) {
          if (LoginModule.UserId.equals(user.Id)) await LoginModule.LogoutFromClient();
        }
      }
      const company = this.Companies.singleOrDefault(a => a.Id.equals(responseReal.id));
      if (company == null) return;
      else this.DeleteCompanyFromModule(company);

      this.onCompanyDeleted.dispatch(company);
    });
    hubConnection.on(hubActions.RECEIVE_CREATE_COMPANY, async (response: ResponseCompany) => {
      const responseReal = ResponseIdentityHelper.createRealCompany(response);
      const company = responseReal.Map();
      this.AddCompanyToModule(company);

      this.onCompanyAdded.dispatch(company);
    });
  }

  @Action({ rawError: true })
  async SubscribeToAuraAdminGroup() {
    if (LoginModule.IdentityConnection == null) return;
    await LoginModule.IdentityConnection.send(hubActions.ADD_TO_AURA_ADMIN_USER_GROUP);
    await LoginModule.MonolithConnection?.send(hubActions.ADD_TO_AURA_ADMIN_USER_GROUP);
  }

  @Action({ rawError: true })
  async DeleteFromAuraAdminGroup() {
    if (LoginModule.IdentityConnection == null) return;
    await LoginModule.IdentityConnection.send(hubActions.DELETE_FROM_AURA_ADMIN_USER_GROUP);
    await LoginModule.MonolithConnection?.send(hubActions.DELETE_FROM_AURA_ADMIN_USER_GROUP);
  }

  @Action({ rawError: true })
  async SubscribeToCompanyGroup() {
    if (LoginModule.IdentityConnection == null) return;
    await LoginModule.IdentityConnection.send(hubActions.ADD_TO_COMPANY_GROUP);
  }

  @Action({ rawError: true })
  async DeleteFromCompanyGroup() {
    if (LoginModule.IdentityConnection == null) return;
    await LoginModule.IdentityConnection.send(hubActions.DELETE_FROM_COMPANY_GROUP);
  }
}

export class CompanyQueryAdditions {
  from: number | null = null;
  count: number | null = null;
  sortBy: CompanySortBy | null = null;
  sortMode: SortMode | null = null;
}

export const CompanyModule = getModule(companyModule);

export interface INewCompanyData {
  name: string;
  description: string;
  country: Countries;
  address: string;
  email: string;
  adminFirstName: string;
  adminSecondName: string;
  adminPassword: string;
  adminEmail: string;
}
