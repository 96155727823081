import { JobState } from '@/models/Entities';
import { SortMode } from '@/models/requests/Datas';

//#region JOB

export enum JobSortBy {
  BY_AUTHOR,
  BY_ORDER,
  BY_DATE,
  BY_CREATION_DATE,
  BY_FILE,
  BY_PRINTER,
  BY_PROGRESS,
}

export enum ArchieveJobSortBy {
  BY_FILE,
  BY_PRINTER,
  BY_DATE_END,
  BY_STATUS,
}

export enum CompanyUserSortBy {
  BY_PRINTS,
}

export class RequestReadUserIdsByPrintsPaged {
  companyId!: string;
  sortMode!: SortMode;
  pageSize!: number;
  page!: number;
}

export class RequestReadMoreJobs {
  pageSize!: number;
  page!: number;
  sortBy!: JobSortBy | null;
  sortMode!: SortMode | null;
}
export class RequestReadAdjacentJobs {
  jobId!: string;
  count!: number;
}
export class RequestReadMoreJobsForUser {
  userId!: string;
  outer!: RequestReadMoreJobs;
}
export class RequestReadJobCountForUserIds {
  userIds!: string[];
}
export class RequestReadJobArchievesCount {
  ids!: string[];
}
export class RequestCreateJob {
  jobSourceId!: string;
  printerId!: string;
  scheduledTime!: string | null;
  order!: number | null;
}
export class RequestChangeJobState {
  jobId!: string | null;
  localJobId!: string | null;
  jobState!: JobState;
}
export class RequestUpdateJobOrder {
  jobId!: string;
  newOrder!: number;
}
export class RequestDeleteJob {
  id!: string;
}
export class RequestReadByIdSource {
  sourceId!: string;
}
export class RequestReadMoreForPrinter {
  printerId!: string;
  outer!: RequestReadMoreJobs;
}
export class RequestReadJobById {
  id!: string;
}
export class RequestReadMoreJobArchieves {
  pageSize!: number;
  page!: number;
  sortBy!: ArchieveJobSortBy | null;
  sortMode!: SortMode | null;
}
export class RequestReadJobArchievesByUserId {
  userId!: string;
  outer!: RequestReadMoreJobArchieves;
}
export class RequestReadJobArchievesBySourceId {
  sourceId!: string;
  outer!: RequestReadMoreJobArchieves;
}
export class RequestReadJobArchievesByPrinterId {
  printerId!: string;
  outer!: RequestReadMoreJobArchieves;
}
export class RequestReadJobArchieveById {
  id!: string;
}
export class RequestJobForceStart {
  id!: string;
}
export class RequestReadActiveJobsByPrinterId {
  printerId!: string;
}

//#endregion

//#region SOURCE

export class RequestReadSourceByIds {
  ids!: string[];
}
export enum SourceSortBy {
  BY_FILENAME,
  BY_AUTHOR,
  BY_GROUP,
  BY_DATE,
  BY_CODE_SIZE,
  BY_PRINT_COUNT,
  BY_CREATED_DATE,
}
export class RequestReadMoreSources {
  page!: number;
  pageSize!: number;
  sortBy!: SourceSortBy | null;
  sortMode!: SortMode | null;
  search!: string | null;
}
export class RequestReadMoreSourcesByGroupId {
  outer!: RequestReadMoreSources;
  groupId!: string | null;
}
export class RequestReadSourceById {
  id!: string;
}
export class RequestCreateSource {
  groupId!: string | null;
}
export class RequestDeleteSource {
  id!: string;
}
export class RequestUpdateSourceSourceGroup {
  id!: string;
  groupId!: string | null;
}

export class RequestDownloadSource {
  sourceId!: string;
}

export class RequestReadSourceGroupByIds {
  ids!: string[];
}
export class RequestReadSourceGroupById {
  id!: string;
}
export class RequestCreateSourceGroup {
  name!: string;
  description!: string;
}
export class RequestDeleteSourceGroup {
  id!: string;
}
export class RequestUpdateSourceGroup {
  id!: string;
  name!: string | null;
  description!: string | null;
}

//#endregion

//#region PRINTER

export class RequestReadPrintersByIds {
  ids!: string[];
}
export class RequestReadPrinterPage {
  companyId!: string;
  pageSize!: number;
  page!: number;
  sortBy!: PrinterSortBy | null;
  sortMode!: SortMode | null;
}
export class RequestReadPrinterById {
  id!: string;
}
export class RequestCreatePrinter {
  printerModelId!: string;
  name!: string;
  description!: string;
}
export class RequestDeletePrinter {
  id!: string;
}
export class RequestUpdatePrinterNaming {
  id!: string;
  printerModelId!: string | null;
  name!: string;
  description!: string;
}
export enum PrinterSortBy {
  BY_PRINTER_NAME,
  BY_PRINTER_MODEL,
  BY_STATUS,
  BY_JOB_COUNT,
  BY_QUEUED_JOB_COUNT,
}

//#endregion

//#region USER STAT

export class RequestReadUserStatByUserId {
  userId!: string;
}

export class RequestReadUserStatByUserIds {
  ids!: string[];
}

//#endregion

//#region GLOBAL NOTIFICATIONS

export class RequestCreateGlobalNotification {
  message!: string;
  startShowing!: string; // Date from which the message will be shown (Date.toJSON())
  endShowing!: string; // Date at which the message will be removed (Date.toJSON())
}

export class RequestDeleteGlobalNotification {
  id!: string;
}

export class RequestUpdateGlobalNotification {
  id!: string;
  newMessage!: string;
  newStartShowing!: string;
  newEndShowing!: string;
}

//#endregion
//#endregion

//#region COMPANY STAT

export class RequestReadCompanyStatById {
  companyId!: string;
}

//#endregion

//#region PRINTER STAT

export class RequestReadPrinterStatById {
  printerId!: string;
}

//#endregion

//#region TOTAL PRINT TIME BY WEEKDAY STAT

export class RequestReadTotalPrintTimeByWeekDayCompanyStatByCompanyId {
  companyId!: string;
}

//#endregion

//#region MATERIAL CONSUMPTION COMPANY STAT

export class RequestReadMaterialConsumptionCompanyStatByCompanyId {
  companyId!: string;
}

//#endregion

//#region MATERIAL CONSUMPTION PRINTER STAT

export class RequestReadMaterialConsumptionPrinterStatByPrinterId {
  printerId!: string;
}

//#endregion

//#region MATERIAL CONSUMPTION ARCHIVE

export class RequestReadMaterialConsumptionsByArchiveId {
  archiveId!: string;
}

//#endregion
