<template>
  <AuraDialogWrapper
    ref="dialogWrapper"
    :headerText="ChangeDefaultPaymentMethodCaption"
    @closed="DialogWrapperClosed"
    @dismissed="DialogWrapperClosed"
  >
    <div class="thin-scroll mb-2" style="max-height: 50vh; overflow: auto">
      <sortable-header :header-items="PaymentMethodHeader" />
      <div class="thin-scroll">
        <list-item-v-3
          v-for="item in paymentMethods"
          :id="item.id"
          :key="item.id.toString()"
          mainClass="cur-pointer"
          :items="PaymentMethodListItems(item)"
          :headerIconName="cardIconName(item)"
          :headerText="item.isDefault ? 'default' : null"
          headerIconType="fab"
          :headerIconScale="2"
          @openItem="DefaultPaymentMethodSelected(item)"
        />
      </div>
    </div>
  </AuraDialogWrapper>
</template>

<script lang="ts">
import { Component, Prop, Ref } from 'vue-property-decorator';
import { DialogComponent } from 'vue-modal-dialogs';
import { PaymentMethod } from '@/models/Entities';

import SortableHeader from '@/components/presentation/SortableHeader.vue';
import ListItemV3 from '@/components/presentation/ListItemV3.vue';
import ComponentHelper, { HeaderItem, ItemData } from '@/util/ComponentHelper';
import en from '@/localization/en';
import AuraDialogWrapper from '@/views/dialogs/base/AuraDialogWrapper.vue';

const VALID_BEFORE_WIDTH = 125;

@Component({
  components: {
    SortableHeader: SortableHeader,
    ListItemV3: ListItemV3,
    AuraDialogWrapper: AuraDialogWrapper,
  },
})
export default class SelectDefaultPaymentMethodDialog extends DialogComponent<PaymentMethod | null> {
  @Prop() private paymentMethods!: PaymentMethod[];
  @Ref() dialogWrapper!: AuraDialogWrapper;

  private cardIconName(pm: PaymentMethod): string {
    return ComponentHelper.cardIconName(pm);
  }

  private get PaymentMethodHeader(): HeaderItem[] {
    let header1: HeaderItem = {
      caption: '#',
      itemClass: ComponentHelper.GetWidthClass(350),
    };
    let header2: HeaderItem = {
      caption: en.validBefore.titleCase(),
      itemClass: ComponentHelper.GetWidthClass(VALID_BEFORE_WIDTH),
    };
    let headerItems: HeaderItem[] = [];
    headerItems.push(header1);
    headerItems.push(header2);
    return headerItems;
  }

  private PaymentMethodListItems(value: PaymentMethod): ItemData[] {
    let items: ItemData[] = [];
    let itemInfo: ItemData = new ItemData(
      'Info',
      ComponentHelper.GetReadablePaymentMethodKind(value.methodType) + '*' + value.last4Digits,
      350,
    );

    let validBefore: ItemData = new ItemData(
      'ValidBefore',
      value.expirationMonth + '/' + value.expirationYear,
      VALID_BEFORE_WIDTH,
    );
    items.push(itemInfo);
    items.push(validBefore);
    return items;
  }

  private DefaultPaymentMethodSelected(pm: PaymentMethod) {
    this.dialogWrapper.CloseDialog();
    this.$close(pm);
  }

  private DialogWrapperClosed() {
    this.$close(null);
  }

  private get ChangeDefaultPaymentMethodCaption(): string {
    return en.selectDefaultPaymentMethod.titleCase();
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  background: var(--dialog-background);
  border-radius: 3px;
  overflow: auto;
  z-index: 2;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.35);
  animation: scaleIn 150ms ease-in;
  animation-fill-mode: forwards;

  &.closing {
    animation: scaleOut 150ms ease-in;
  }
}

.dialog-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--main-text);

  i {
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  }
}

.dialog-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1061;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.35);
  animation: fadeIn 150ms ease-in;
  animation-fill-mode: forwards;

  &.closing {
    animation: fadeOut 150ms ease-in;
  }
}

.dialog-dismiss-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
</style>
